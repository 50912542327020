import { VersionInfo } from '@citrite/workspace-ui-platform';

export enum MonitoringData {
	NOT_AVAILABLE = 'NOT_AVAILABLE',
	NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export type MoreInfo = {
	[key: string]: string | MonitoringData;
};

export interface Monitoring {
	initialize(appName: string, versionInfo: VersionInfo): void;
	setSessionAttribute(name: string, value: string | number | boolean): void;
	setCurrentRouteName(name: string): void;
	addMeasure(name: string, start: number, end: number, moreInfo?: MoreInfo): void;
}
