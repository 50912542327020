import * as React from 'react';
import { tryExtractTransactionId } from '@citrite/http';
import { t } from '@citrite/translate';
import { notifyError, notifySuccess } from '@citrite/web-ui-component';
import { activityEventPublisher } from '@citrite/workspace-iws-ui';
import { microAppIntegration } from '@citrite/workspace-ui-microapps';
import {
	EncryptedCacheBucket,
	FeedCardDetailsCapability,
	FeedCardProps,
	IntegrationCapability,
	IntegrationContext,
	LoadableIntegration,
} from '@citrite/workspace-ui-platform';
import { trackEvent } from 'analytics';
import { History, Location } from 'history';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { logError } from 'remoteLogging';
import URI from 'urijs';
import { performance } from 'Components/performance';
import { setRefreshFeedFunction } from 'Environment/callbacks';
import { isNativeMobileClient } from 'Environment/launchResource/device';
import { useIntegrations } from 'Integrations/useIntegrations';
import { detectLanguage } from 'javascript/sf/detectLanguage';
import { makeGuid } from 'javascript/sf/guid';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import {
	PushEvent,
	registerOnClose,
	registerOnPush,
	registerOnStart,
} from 'pushEventService';
import { homePagePath } from 'Router/homePageRoute';
import { ContextState, NotificationContext } from 'userNotifications/Context';
import { BulkDismissType, FeedCard, feedSort } from 'userNotifications/FeedCard';
import { useNotificationService } from 'userNotifications/hooks/useNotificationService';
import { notificationIntegration } from 'userNotifications/manifest';
import { createFeedCard } from 'userNotifications/service/createFeedCard';
import { FeedCardDto } from 'userNotifications/service/GetFeedResponse';
import {
	GetFeedParams,
	NotificationService,
} from 'userNotifications/service/NotificationService';
import { isExtensionForFeedCard } from 'userNotifications/utils/isExtensionForFeedCard';
import { useCasTelemetryAdapter } from 'Workspace/CAS/useCasTelemetryAdapter';
import { createAccessFeedCardDetailEvent } from 'Workspace/TelemetryEvents/activityFeed';
import {
	createLoadNewFeedCardsEvent,
	createPageLoadEvent,
	createScrollLoadEvent,
	createSortOrderChangeEvent,
} from 'Workspace/TelemetryEvents/notificationFeed';
import { TelemetryEventPublisher } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { useNotificationCache } from './useNotificationCache';

const localStorageFeedSortKey = 'notification-feed-sort';
export const feedCardLimit = 20;
const shortInterval = 15 * 1000;
const longInterval = 60 * 1000;
const cacheReconciliationTimeout = 3 * 1000;
const baseFavicon = require('../favicon.png');
const notificationsFavicon = require('../favicon-notification.png');

interface ProviderProps extends TelemetryEventPublisher {
	notificationService: LoadableIntegration<NotificationService>;
	notificationCache: EncryptedCacheBucket;
	location: Location;
	history: History;
	integrations: IntegrationContext;
}

type ErrorToast = {
	id: string;
	handleRemoveNotification: NotificationHandler;
};

type NotificationHandler = () => void;

type GetFeedOptions = { fetchFromCache: boolean };

interface State extends ContextState {
	feedCards: FeedCardDto<any>[];
	newFeedCards: FeedCardDto<any>[];
	archivedFeedCards: FeedCardDto<any>[];
	continuationToken?: string;
	polledFeedCards?: FeedCardDto<any>[];
	errorToasts: ErrorToast[];
	integrationsLoading: boolean;
}

class _NotificationProvider extends React.Component<ProviderProps, State> {
	private favicon: any;
	private pushEventServiceConnected = false;
	private feedPolling: NodeJS.Timer;
	private skipDataFetch = false;
	private accessFeedEventPublisher: ReturnType<typeof activityEventPublisher>;
	private dismissCardEventPublisher: ReturnType<typeof activityEventPublisher>;

	private unregisterOnStart = () => {};

	private unregisterOnClose = () => {};

	private unregisterOnPush = () => {};

	public constructor(props: ProviderProps) {
		super(props);
		this.state = {
			feedCards: [],
			sort:
				getFromLocalStorage(localStorageFeedSortKey) ||
				(isNativeMobileClient() ? feedSort.createdDate : feedSort.relevance),
			feedLoading: true,
			pageLoading: false,
			hasNewFeedCards: false,
			newFeedCards: [],
			hasError: false,
			polledFeedCards: [],
			archivedFeedCards: [],
			errorToasts: [],
			integrationsLoading: true,
		};

		this.favicon = document.querySelector("link[rel*='icon']");

		if (!this.favicon) {
			this.favicon = document.createElement('link');
			this.favicon['rel'] = 'shortcut icon';
			this.favicon['type'] = 'image/png';
			this.favicon.href = baseFavicon;
			document.getElementsByTagName('head')[0].appendChild(this.favicon);
		}

		this.accessFeedEventPublisher = activityEventPublisher(
			this.props.publishEvent,
			'ACCESS',
			'FEED'
		);

		this.dismissCardEventPublisher = activityEventPublisher(
			this.props.publishEvent,
			'DISMISS',
			'CARD'
		);
	}

	public render() {
		return (
			<NotificationContext.Provider
				value={{
					feedCards: this.getMappedFeedCards(this.state.feedCards),
					sort: this.state.sort,
					feedLoading: this.state.feedLoading || this.state.integrationsLoading,
					pageLoading: this.state.pageLoading,
					hasNewFeedCards: this.state.hasNewFeedCards,
					newFeedCards: this.getMappedFeedCards(this.state.newFeedCards),
					hasError: this.state.hasError,
					selectedFeedCardId: this.parseFeedCardIdFromLocation(this.props.location),
					refreshFeed: this.refreshFeed,
					getNextPage: this.getNextPage,
					changeSort: this.changeSort,
					dismissFeedCard: this.dismissFeedCard,
					bulkDismissFeedCards: this.bulkDismissFeedCards,
					removeFeedCard: this.removeFeedCard,
					restoreFeedCard: this.restoreFeedCard,
					showNewCards: this.showNewCards,
					clearHighlightedCards: this.clearHighlightedCards,
				}}
			>
				{this.props.children}
			</NotificationContext.Provider>
		);
	}

	public componentDidMount() {
		this.loadFeedCardIntegrations();
		if (this.props.notificationService.loading) {
			return;
		}
		this.startListening();
	}

	public componentDidUpdate(prevProps: ProviderProps, prevState: State) {
		if (
			!this.props.notificationService.loading &&
			prevProps.notificationService.loading
		) {
			this.startListening();
			return;
		}

		if (
			!this.areFeedCardSetsEqual(
				prevState.feedCards.slice(0, feedCardLimit),
				this.state.feedCards.slice(0, feedCardLimit)
			)
		) {
			this.writeFeedCardsToCache(this.state.feedCards.slice(0, feedCardLimit));
		}

		const newFeedCardId = this.parseFeedCardIdFromLocation(this.props.location);
		const oldFeedCardId = this.parseFeedCardIdFromLocation(prevProps.location);
		if (
			newFeedCardId &&
			((newFeedCardId !== oldFeedCardId && !this.state.feedLoading) ||
				(prevState.feedLoading && !this.state.feedLoading))
		) {
			this.openDeepLink(newFeedCardId);
		}
	}

	public componentWillUnmount() {
		this.unregisterOnStart();
		this.unregisterOnClose();
		this.unregisterOnPush();
		this.stopPolling();
		this.favicon.href = baseFavicon;
	}

	private loadFeedCardIntegrations() {
		const resolvers = this.props.integrations
			.resolveByCapability(IntegrationCapability.feedCardExtensions.viewDetails)
			.map(service => service.resolver.catch<null>(() => null));
		if (!resolvers.length) {
			this.setState({ integrationsLoading: false });
		}
		Promise.all(resolvers).then(() => this.setState({ integrationsLoading: false }));
	}

	private startListening() {
		this.unregisterOnStart = registerOnStart(this.onSignalRStart);
		this.unregisterOnClose = registerOnClose(this.onSignalRStop);
		this.unregisterOnPush = registerOnPush(
			notificationIntegration.requiredEndpointServiceName,
			this.onPushEvent
		);
		this.getFeedCards({ fetchFromCache: true });
		setRefreshFeedFunction(this.refreshFeed);
	}

	private onSignalRStart = () => {
		this.pushEventServiceConnected = true;
	};

	private onSignalRStop = () => {
		this.pushEventServiceConnected = false;
		this.skipDataFetch = false;
	};

	private onPushEvent = (event: PushEvent) => {
		this.skipDataFetch = event.serviceId !== 'notifications';
	};

	private openDeepLink(feedCardId: string) {
		if (this.state.polledFeedCards.length) {
			this.showNewCards();
		}
		if (
			!this.hasCard(feedCardId, this.state.feedCards) &&
			!this.hasCard(feedCardId, this.state.polledFeedCards)
		) {
			this.fetchAndShowFeedCard(feedCardId);
		}
	}

	private mapFeedCardDtoToFeedCard = (feedCardDto: FeedCardDto) =>
		createFeedCard(feedCardDto);

	private getMappedFeedCards = (feedCardDtos: FeedCardDto[]) =>
		feedCardDtos.map(this.mapFeedCardDtoToFeedCard);

	private fetchAndShowFeedCard(feedCardId: string) {
		this.props.notificationService.value
			.getCard(feedCardId)
			.then(async fetchedFeedCardData => {
				if (!fetchedFeedCardData) {
					notifyError(t('javascript:generic_error'));
					return;
				}
				await this.openBlade(fetchedFeedCardData);
			})
			.catch(() => {
				return notifyError(t('javascript:generic_error'));
			});
	}

	private async openBlade(feedCardData: FeedCard) {
		const bladeProps: FeedCardProps = {
			onDismiss: () => this.props.history.replace(homePagePath),
			detail: feedCardData.detail,
			source: feedCardData.source,
			iconUrl: feedCardData.iconUrl,
			title: feedCardData.getTitle(),
			appTitle: feedCardData.source.integration.getTitle(),
			displayTime: moment(feedCardData.createdAt).locale(detectLanguage()).from(moment()),
			cardId: feedCardData.id,
			messageUuid: feedCardData.messageUuid,
			trackDisplayBladeEvent: () =>
				this.props.publishEvent(
					createAccessFeedCardDetailEvent(
						feedCardData.source.integration,
						feedCardData.id,
						feedCardData.messageUuid,
						feedCardData.createdAt
					)
				),
		};

		const feedCardIntegration = this.getDetailsIntegrationOrDefault(feedCardData);

		await feedCardIntegration.resolver.then(integration =>
			integration.onCardClick(bladeProps)
		);
	}

	private getDetailsIntegrationOrDefault(feedCardData: FeedCard) {
		let supportedIntegration: LoadableIntegration<FeedCardDetailsCapability> =
			this.props.integrations
				.resolveByCapability(IntegrationCapability.feedCardExtensions.viewDetails)
				.find(integration =>
					isExtensionForFeedCard(
						integration.registration.metadata,
						feedCardData.source?.origin,
						!!feedCardData.detail && 'type' in feedCardData.detail
							? feedCardData.detail.type
							: undefined,
						feedCardData.detail?.rendererParameters?.mfeRenderer
					)
				);

		if (!supportedIntegration) {
			trackEvent('DefaultFeedCardFallbackToMicroapps', {
				cardDetailType: feedCardData.detail?.type,
				cardSourceOrigin: feedCardData.source?.origin,
			});

			supportedIntegration = this.props.integrations.resolveById(
				microAppIntegration.id,
				'feedCard'
			);
		}

		return supportedIntegration;
	}

	private hasCard(feedCardId: string, feedCards: FeedCardDto[]) {
		return feedCards.find(card => card.id === feedCardId) !== undefined;
	}

	private parseFeedCardIdFromLocation(location: Location) {
		const { search } = location;
		const actions = URI.parseQuery(search) as { [action: string]: string };
		return actions?.feedCardId;
	}

	private getFeedCards = (
		{ fetchFromCache }: GetFeedOptions = { fetchFromCache: false }
	) => {
		this.stopPolling();
		this.accessFeedEventPublisher.startTimer();

		if (this.props.notificationService.error) {
			this.setState({
				feedLoading: false,
				pageLoading: false,
				hasError: true,
			});
			return Promise.resolve();
		}

		this.setState({
			feedLoading: true,
			pageLoading: false,
			continuationToken: null,
			polledFeedCards: [],
			hasNewFeedCards: false,
		});

		const feedCardFetchHandler = fetchFromCache
			? this.tryGetFeedCardsFromCache
			: this.getFeedCardsFromNetwork;

		performance.mark(performance.events.EnhancedCache_StartFeedRequest);
		return feedCardFetchHandler({ sort: this.state.sort })
			.then(items => {
				performance.mark(performance.events.EnhancedCache_FinishFeedRequest);
				this.setState({
					feedCards: items,
					feedLoading: false,
					pageLoading: false,
					hasError: false,
				});
				this.props.publishEvent(createPageLoadEvent(this.state.sort));
				this.accessFeedEventPublisher.publishEvent('SUCCESS', Date.now());
				if (this.pushEventServiceConnected) {
					this.skipDataFetch = true;
				}
				this.pollFeed();
			})
			.catch(err => {
				this.accessFeedEventPublisher.publishEvent(
					'FAILURE',
					Date.now(),
					err.status,
					tryExtractTransactionId(err)
				);

				this.setState({
					feedCards: [],
					feedLoading: false,
					pageLoading: false,
					continuationToken: null,
					hasError: true,
				});
			});
	};

	private withNetworkReconciliation = (cachedFeedCards: FeedCardDto[]) => {
		const pendingNetworkResponse = this.getFeedCardsFromNetwork({
			sort: this.state.sort,
			limit: Math.min(cachedFeedCards.length, feedCardLimit),
		});

		const showReconciledFeedCards = async () => {
			const items = await pendingNetworkResponse;
			if (!this.areFeedCardSetsEqual(cachedFeedCards, items)) {
				this.writeFeedCardsToCache(items);
				this.setState({
					polledFeedCards: items,
					newFeedCards: this.filterCards(items, cachedFeedCards),
					hasNewFeedCards: true,
				});
				this.showNotificationFavicon();
			}
		};

		setTimeout(showReconciledFeedCards, cacheReconciliationTimeout);
		return cachedFeedCards;
	};

	private tryGetFeedCardsFromCache = async (
		params: GetFeedParams,
		ignoreAuthChallenge = false
	) => {
		const cacheResult = await this.props.notificationCache.getEncrypted<FeedCardDto[]>(
			this.getCacheKey()
		);

		this.accessFeedEventPublisher.setFromCache(cacheResult?.length > 0);

		return cacheResult?.length > 0
			? this.withNetworkReconciliation(cacheResult)
			: this.getFeedCardsFromNetwork(params, ignoreAuthChallenge);
	};

	private getFeedCardsFromNetwork = (
		params: GetFeedParams,
		ignoreAuthChallenge = false
	) =>
		this.props.notificationService.value
			.getFeed(params, ignoreAuthChallenge)
			.then(({ items, continuationToken }) => {
				this.setState({
					continuationToken,
				});
				return items;
			});

	private writeFeedCardsToCache = (feedCards: FeedCardDto[]) =>
		this.props.notificationCache.setEncrypted(this.getCacheKey(), feedCards);

	private getNextPage = () => {
		if (!this.state.continuationToken) {
			return;
		}
		this.setState({ pageLoading: true });
		this.getFeedCardsFromNetwork({
			sort: this.state.sort,
			continuationToken: this.state.continuationToken,
		})
			.then(items => {
				const feedCards = this.uniqueCards([...this.state.feedCards, ...items]);
				this.setState({
					feedCards,
					pageLoading: false,
				});
				this.props.publishEvent(createScrollLoadEvent());
			})
			.catch(() => {
				this.setState({ pageLoading: false });
			});
	};

	private refreshFeed = () => {
		this.showDefaultFavicon();
		if (!this.state.hasNewFeedCards) {
			this.clearHighlightedCards();
		}
		if (this.props.notificationService.error) {
			this.props.notificationService.reload();
			return Promise.resolve();
		}
		return this.getFeedCards();
	};

	private uniqueCards(collection: FeedCardDto<any>[]) {
		return collection.filter(
			(card, index, self) => index === self.findIndex(c => c.id === card.id)
		);
	}

	private pollFeed = () => {
		const interval = this.pushEventServiceConnected ? shortInterval : longInterval;
		this.feedPolling = setTimeout(this.pollFeedLoop, interval);
	};

	private pollFeedLoop = () => {
		if (this.skipDataFetch) {
			this.pollFeed();
			return;
		}

		this.getFeedCardsFromNetwork(
			{
				sort: this.state.sort,
				limit:
					!!this.state.feedCards.length && this.state.continuationToken
						? Math.min(this.state.feedCards.length, feedCardLimit)
						: feedCardLimit,
			},
			true
		)
			.then(items => {
				if (this.pushEventServiceConnected) {
					this.skipDataFetch = true;
				}
				if (!this.state.feedCards.length) {
					this.setState({ feedCards: items });
					return;
				}

				const topFeedCards = this.state.feedCards.slice(0, feedCardLimit);
				const newFeedCards = this.filterCards(items, topFeedCards);
				const existingFeedCards = this.filterCards(topFeedCards, items);

				if (newFeedCards.length || existingFeedCards.length) {
					this.setState({
						polledFeedCards: items,
						newFeedCards,
						hasNewFeedCards: true,
					});
					this.showNotificationFavicon();
				}
			})
			.then(this.pollFeed)
			.catch(() => {});
	};

	private stopPolling() {
		clearTimeout(this.feedPolling);
	}

	private filterCards(
		targetCollection: FeedCardDto<any>[],
		comparedCollection?: FeedCardDto<any>[]
	) {
		return targetCollection.filter(
			target => !comparedCollection.find(compared => compared.id === target.id)
		);
	}

	private areFeedCardSetsEqual(initialCards: FeedCardDto[], targetCards: FeedCardDto[]) {
		if (initialCards.length !== targetCards.length) {
			return false;
		}

		return initialCards.every((card, idx) => card.id === targetCards[idx].id);
	}

	private showErrorToast(
		feedCard: FeedCard<any>,
		customMessage?: string,
		tryAgainCallback?: () => void
	) {
		this.removePreviousToast(feedCard);
		this.setState(state => ({
			errorToasts: [
				...state.errorToasts.filter(f => f.id !== feedCard.id),
				{
					id: feedCard.id,
					handleRemoveNotification: this.generateToastAndReturnHandler(
						feedCard,
						customMessage,
						tryAgainCallback
					),
				},
			],
		}));
	}

	private removePreviousToast(feedCard: FeedCard<any>) {
		const errorToast = this.state.errorToasts.find(f => f.id === feedCard.id);
		if (errorToast) {
			errorToast.handleRemoveNotification();
		}
	}

	private generateToastAndReturnHandler(
		feedCard: FeedCard<any>,
		customMessage?: string,
		tryAgainCallback?: () => void
	): NotificationHandler {
		return notifyError(
			customMessage ||
				t('Workspace:notification_stream.action.error', {
					toolName: feedCard.source.integration.getTitle(),
				}),
			tryAgainCallback && {
				action: {
					onClick: tryAgainCallback,
					children: t('Workspace:notification_stream.try_again'),
				},
			}
		);
	}

	private dismissFeedCard = (feedCardId: string) => {
		this.dismissCardEventPublisher.startTimer();
		const removedCard = this.removeFeedCard(feedCardId);
		if (removedCard) {
			const removedFeedCard = this.mapFeedCardDtoToFeedCard(removedCard);
			this.props.notificationService.value
				.archiveCard(feedCardId)
				.then(() => {
					this.dismissCardEventPublisher.publishEvent('SUCCESS', Date.now());
					notifySuccess(
						t('Workspace:notification_stream.dismiss_success', {
							toolName: removedFeedCard.source.integration.getTitle(),
						}),
						{
							action: {
								children: t('Workspace:notification_stream.undo'),
								onClick: () => {
									this.props.notificationService.value
										.unarchiveCard(feedCardId)
										.then(() => {
											this.restoreFeedCard(removedFeedCard);
										})
										.catch(() => {
											this.showErrorToast(removedFeedCard);
										});
								},
							},
							timeout: 4000,
						}
					);
				})
				.catch(err => {
					this.dismissCardEventPublisher.publishEvent(
						'FAILURE',
						Date.now(),
						err.status,
						tryExtractTransactionId(err)
					);
					this.restoreFeedCard(removedFeedCard);
					this.showErrorToast(removedFeedCard);
				});
		}
	};

	private bulkDismissFeedCards = async (type: BulkDismissType, feedCard: FeedCard) => {
		const unArchiveCode = makeGuid();
		const integrationName = feedCard.source.integration.getTitle();
		const notificationName = feedCard.source.notification.getLabel();
		let successMessage = '';
		let errorMessage = '';
		let requestPromise: null | Promise<void> = null;

		switch (type) {
			case BulkDismissType.Integration: {
				successMessage = t(
					'Workspace:notification_stream.dismiss_all_integration_notifications_success',
					{ IntegrationName: <strong>{integrationName}</strong> }
				);
				errorMessage = t(
					'Workspace:notification_stream.dismiss_all_integration_notifications_error',
					{ IntegrationName: <strong>{integrationName}</strong> }
				);
				requestPromise = this.props.notificationService.value.archiveIntegration(
					feedCard.source.integration.id,
					unArchiveCode
				);
				break;
			}
			case BulkDismissType.Notification: {
				successMessage = t(
					'Workspace:notification_stream.dismiss_all_notifications_success',
					{ NotificationName: <strong>{notificationName}</strong> }
				);
				errorMessage = t(
					'Workspace:notification_stream.dismiss_all_notifications_error',
					{ NotificationName: <strong>{notificationName}</strong> }
				);
				requestPromise = this.props.notificationService.value.archiveNotification(
					feedCard.source.notification.id,
					unArchiveCode
				);
				break;
			}
			case BulkDismissType.RecordId: {
				successMessage = t(
					'Workspace:notification_stream.dismiss_all_related_notifications_success',
					{ IntegrationName: <strong>{integrationName}</strong> }
				);
				errorMessage = t(
					'Workspace:notification_stream.dismiss_all_related_notifications_error',
					{ IntegrationName: <strong>{integrationName}</strong> }
				);
				requestPromise = this.props.notificationService.value.archiveRecordId(
					feedCard.recordId,
					unArchiveCode
				);
				break;
			}
		}

		if (!requestPromise) {
			this.showErrorToast(feedCard, errorMessage);
			return;
		}

		return await requestPromise
			.then(() => {
				notifySuccess(successMessage, {
					action: {
						children: t('Workspace:notification_stream.undo'),
						onClick: () => {
							this.setState({ feedLoading: true });
							this.props.notificationService.value
								.unarchiveBulk(unArchiveCode)
								.then(() => {
									this.refreshFeed();
								})
								.catch(error => {
									logError(error, { tags: { feature: 'notification-feed' } });
									this.showErrorToast(
										feedCard,
										t('Workspace:notification_stream.undo_dismiss_all_error')
									);
								})
								.finally(() => {
									this.setState({ feedLoading: false });
								});
						},
					},
					timeout: 4000,
				});
				this.refreshFeed();
				trackEvent('WsuiCardDismissAllSuccess');
			})
			.catch(error => {
				logError(error, { tags: { feature: 'notification-feed' } });
				this.showErrorToast(feedCard, errorMessage, () =>
					this.bulkDismissFeedCards(type, feedCard)
				);
			});
	};

	/**
	 * @returns the card that was removed or null
	 */
	private removeFeedCard = (feedCardId: string) => {
		const matchedCard = this.state.feedCards.find(c => c.id === feedCardId);
		if (matchedCard) {
			this.setState(previousState => ({
				feedCards: previousState.feedCards.filter(c => c !== matchedCard),
				archivedFeedCards: [matchedCard, ...previousState.archivedFeedCards],
			}));
			return matchedCard;
		}
		return null;
	};

	private restoreFeedCard = (feedCard: FeedCard) => {
		this.setState(state => ({
			feedCards: [
				state.archivedFeedCards.find(c => c.id === feedCard.id),
				...state.feedCards,
			],
			archivedFeedCards: state.archivedFeedCards.filter(c => c.id !== feedCard.id),
		}));
	};

	private showNewCards = () => {
		this.showDefaultFavicon();
		this.setState(state => ({
			polledFeedCards: [],
			feedCards: state.polledFeedCards,
			hasNewFeedCards: false,
		}));
		this.props.publishEvent(createLoadNewFeedCardsEvent(this.state.sort));
	};

	private changeSort = (sort: feedSort) => {
		setInLocalStorage(localStorageFeedSortKey, sort);
		this.showDefaultFavicon();
		this.setState({ sort }, () => this.getFeedCards());
		this.props.publishEvent(createSortOrderChangeEvent(sort));
	};

	private showNotificationFavicon = () => {
		this.favicon.href = notificationsFavicon;
	};

	private showDefaultFavicon = () => {
		this.favicon.href = baseFavicon;
	};

	private clearHighlightedCards = () => {
		this.setState({ newFeedCards: [] });
	};

	private getCacheKey() {
		return `notifications-feed-${detectLanguage()}`;
	}
}

export const NotificationProvider: React.FC = props => {
	const { publishEvent } = useCasTelemetryAdapter();
	const notificationService = useNotificationService();
	const notificationCache = useNotificationCache();
	const location = useLocation();
	const history = useHistory();
	const integrations = useIntegrations();
	return (
		<_NotificationProvider
			{...props}
			publishEvent={publishEvent}
			notificationService={notificationService}
			notificationCache={notificationCache}
			location={location}
			history={history}
			integrations={integrations}
		/>
	);
};
