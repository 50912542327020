import * as React from 'react';
import { t } from '@citrite/translate';
import { DesktopsIcon, DesktopsSolidIcon } from '@citrite/web-ui-component';
import { LeftNavPositions } from '@citrite/workspace-ui-platform';
import { MainContent } from '@citrite/workspace-ui-platform-react';
import { NativeMobileDesktops } from 'App/Desktops/NativeMobileDesktops';
import { checkDesktopsAvailability } from 'App/Navigation/checkDesktopsAvailability';
import { Desktop } from 'App/Screen';
import { performance } from 'Components/performance';
import { isNativeMobileClient } from 'Environment/launchResource/device';
import { createLeftNavRoute, RoutedComponentProps } from 'Router/ReactRoute';
import { useUserContext } from 'Workspace/UserContext';
import { AllDesktops } from './allDesktopsRoute';

type Props = RoutedComponentProps;
export const desktopsPagePath = '/desktops';

function Desktops(props: Props) {
	const daasVisionEnabled = useUserContext().isDaasVisionEnabled;
	return (
		<>
			{daasVisionEnabled ? (
				<Desktop />
			) : (
				<MainContent>
					{isNativeMobileClient() ? (
						<NativeMobileDesktops {...props} />
					) : (
						<AllDesktops {...props} />
					)}
				</MainContent>
			)}
		</>
	);
}

export const desktopsRoute = createLeftNavRoute({
	component: Desktops,
	path: desktopsPagePath,
	isAvailable: props => checkDesktopsAvailability(props),
	isLoading: props => props.resources.loading,
	getNavLinkProps: () => ({
		icon: DesktopsIcon,
		selectedIcon: DesktopsSolidIcon,
		title: t('Workspace:desktops'),
		weight: LeftNavPositions.desktops,
		performanceMark: performance.events.MobileDesktopsLink_Click,
		analyticsID: 'desktops-nav-tab',
	}),
});
