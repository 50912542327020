import { useEffect } from 'react';
import { trackAnalyticsEvent } from 'analytics';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { PinnedLinksUserEventPayload } from 'Workspace/TelemetryEvents/pinnedLinks/createPinnedLinksCASReporter';

export function usePinnedLinksReporting() {
	const { workspaceConfiguration } = useConfigurationContext();

	useEffect(() => {
		const isPinnedLinksEnabled =
			workspaceConfiguration?.pinnedLinks?.pinnedLink?.length > 0;
		if (isPinnedLinksEnabled) {
			const { totalPinnedLinks, pinnedLinksWithDetails, pinnedLinksWithIcon } =
				(workspaceConfiguration?.pinnedLinks?.pinnedLink).reduce(
					(pinnedLinksStats, link) => {
						if (link?.toolTipText) {
							pinnedLinksStats.pinnedLinksWithDetails++;
						}
						if (link?.iconUrl) {
							pinnedLinksStats.pinnedLinksWithIcon++;
						}
						pinnedLinksStats.totalPinnedLinks++;
						return pinnedLinksStats;
					},
					{ totalPinnedLinks: 0, pinnedLinksWithDetails: 0, pinnedLinksWithIcon: 0 }
				);

			const pinnedLinkDetailsJson = JSON.stringify({
				totalPinnedLinks,
				pinnedLinksWithDetails,
				pinnedLinksWithIcon,
			});

			trackAnalyticsEvent(
				PinnedLinksUserEventPayload.pinnedLinkDetails(pinnedLinkDetailsJson)
			);
		}
	}, [workspaceConfiguration]);
}
