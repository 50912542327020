import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	breakpoints,
	ChevronDoubleIcon,
	fromThemeColors,
	layout,
	palette,
	styleUtils,
	Tooltip,
	typography,
} from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { FeatureFlag } from 'Environment/FeatureFlag';

export const StyledExpandCollapseIcon = styled(ChevronDoubleIcon)<{ collapsed: boolean }>`
	${props => props.collapsed && 'transform: scaleX(-1);'}
`;

export const StyledTooltip = styled(Tooltip)`
	background-color: ${palette.grey200};
	margin-left: ${layout.mediumLargeSpace};
	padding: 12px;
	width: 16px;
	height: 16px;
	border-radius: 8px;
	:hover {
		background-color: ${palette.grey300};
	}
`;

interface StyledCitrixLogoProps {
	deprecatedCollapsed: boolean;
}

export const StyledCitrixLogo = styled.div<StyledCitrixLogoProps>`
	transition: opacity 0.3s linear;
	transition-delay: 0.2s;
	${() =>
		!getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
			[breakpoints.onMobile]: {
				display: 'none',
			},
		}};
	margin: ${() =>
		getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? layout.mediumLargeSpace
			: layout.mediumSpace};
	padding: 0 12px;
	${props =>
		!getFeatureFlagValue(FeatureFlag.SimplifiedNav) &&
		props.deprecatedCollapsed &&
		'opacity: 0; transition: opacity 0.3s linear; transition-delay: 0s; width: 0;'}
`;

export const StyledLogoArea = styled('div')(props => ({
	[breakpoints.onMobile]: {
		minHeight: '64px',
		backgroundColor: props.theme.branding.styles.HeaderBackgroundColor,
	},
}));

export const StyledCustomLogo = styled('div', { shouldForwardProp: isPropValid })(
	props => ({
		backgroundImage: `url("${props.theme.branding.links.HeaderLogoUrl}")`,
		[breakpoints.onMobile]: {
			width: '170px',
			height: '40px',
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '0 center',
			margin: layout.mediumSpace,
		},
	})
);

export const StyledSvgLogo = styled.div`
	display: none;
	${breakpoints.onMobile} {
		display: flex;
		align-items: center;
		height: 40px;
		margin: ${layout.mediumSpace};
	}
`;

export const StyledLinkList = styled.ul`
	overflow-x: hidden;
	overflow-y: auto;
	margin: ${() =>
		getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? `${layout.mediumLargeSpace} 0 0 0`
			: 0};
	padding: 0;
	list-style-type: none;
	flex: 1;
`;

export const StyledUserSettingsAll = styled.div`
	display: none;
	${breakpoints.onMobile} {
		display: block;
	}
`;

export const StyledUserSettings = styled.div<{ hasTabs: boolean }>`
	border-top: 1px solid ${fromThemeColors('border')};
	width: 100%;
	align-items: center;
	padding: 15px;
	box-sizing: border-box;
	${props => props.hasTabs && 'margin-bottom: 50px'};
`;

export const StyledUserSettingsOptions = styled.div`
	flex-direction: column;
	padding-bottom: 15px;
`;

export const StyledUserNameBlock = styled.div`
	display: flex;
	flex-direction: row;
`;

export const StyledLogout = styled.div`
	flex-grow: 1;
	margin: 0 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const StyledMenuSettingsOptions = styled.div`
	padding: 10px 25px;
`;

export const StyledUsername = styled.div`
	${styleUtils.ellipsisCss};
	font-size: ${typography.sizes.base};
	max-width: 180px;
`;
