import 'core-js';
import 'css/styles.css';
import 'current-script-polyfill';
import 'Environment/setPublicPath';
import 'javascript/lib/directoryUpload';
import 'whatwg-fetch';
import { addRequestInterceptor } from '@citrite/http';
import { init, t } from '@citrite/translate';
import { resilientPromise, versionInfo } from '@citrite/workspace-ui-platform';
import axios from 'axios';
import { _aot, AotStrings } from 'App/AotTrace';
import { performance, PerformanceEvents } from 'Components/performance';
import { environment, originalHash } from 'Environment';
import { offlineParameter } from 'Environment/BrowserExtension/offlineParametersUtils';
import { initializeChromeApp } from 'Environment/launchResource/chromeApp';
import { addStartedAppPlatformDependencies } from 'IntegrationDependencies/setup';
import { addInteractiveTools } from 'javascript/interactiveTools';
import { interceptor } from 'javascript/interceptors';
import { javascriptNamespace } from 'javascript/locales';
import { detectLanguage, SUPPORTED_LANGUAGES } from 'javascript/sf/detectLanguage';
import { resumeFormsToken } from 'Login/constants';
import { monitoring } from 'MonitoringAnalytics';
import { renderWorkspace } from 'Workspace';
import { WorkspaceNamespace as loadWorkspaceLocales } from 'Workspace/locales';
import { prepareWorkspaceUIPlatform } from 'Workspace/PlatformManagement';
import { getExternalUserActivationUrl } from './externalUserActivation';

/* 
	This we have to do irrespective of the feature flags,
	since if there are people who install the new version of the extension
	but have not enabled the feature flag, they will get 404 errors from our React Router
	due to the #ctxOffline parameter in the URL.
*/
offlineParameter.extractOfflineParameterFromUrl();

monitoring.initialize('Workspace UI', versionInfo.get());
performance.markApplicationLoad(PerformanceEvents.WSUIInit);
addRequestInterceptor(interceptor);

const language = detectLanguage();

init({ lng: language, supportedLngs: SUPPORTED_LANGUAGES });

const activationUrl = getExternalUserActivationUrl();
if (activationUrl) {
	location.assign(activationUrl);
} else {
	startApp();
}

prepareWorkspaceUIPlatform();
monitoring.setSessionAttribute('wsuiVersion', versionInfo.get().release);
monitoring.setSessionAttribute('wsuiPreviousVersion', versionInfo.get().previousRelease);

function startApp() {
	Promise.all([
		resilientPromise(() => loadWorkspaceLocales(language)),
		resilientPromise(() => javascriptNamespace(language)),
		environment
			.build()
			.then(() => (axios.defaults.adapter = environment.ajax.bind(environment)))
			.then(() => {
				_aot.info(
					AotStrings.bridge.category,
					AotStrings.bridge.nativeBridgeInitComplete,
					environment.nativePlatform
				);
			}),
		initializeChromeApp(),
	]).then(() => {
		performance.markApplicationLoad(PerformanceEvents.WSUIEnvironmentSetup);
		addStartedAppPlatformDependencies(language);
		document.title = t('Workspace:document_title');

		if (originalHash.startsWith('#' + resumeFormsToken)) {
			location.hash = `#/login?${originalHash.substring(1)}`;
		} else if (environment.isNative) {
			location.hash = '#/';
		}

		renderWorkspace();
		addInteractiveTools();
	});
}
