import React from 'react';
import { t } from '@citrite/translate';
import {
	fromThemeColors,
	layout,
	ThemeColor,
	WorkspaceLogoIcon,
	WorkspaceTextIcon,
} from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { bannerContentHeight } from 'App/Header/util';

const StyledLink = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
`;

const StyledWorkspaceTextIcon = styled(WorkspaceTextIcon)`
	margin: 0 ${layout.smallSpace};
`;

export const Title = () => {
	const theme = useTheme();
	const logoURL = theme?.branding?.links?.HeaderLogoUrl;
	return (
		<StyledLink data-testid="workspaceLogo" to="/home">
			{logoURL ? (
				<img
					src={logoURL}
					alt={t('Workspace:document_title')}
					height={bannerContentHeight}
					width="auto"
				/>
			) : (
				<>
					<WorkspaceLogoIcon
						size={bannerContentHeight}
						color={fromThemeColors(ThemeColor.primary)({ theme })}
					/>
					<StyledWorkspaceTextIcon
						width={196}
						color={fromThemeColors(ThemeColor.primary)({ theme })}
					/>
				</>
			)}
		</StyledLink>
	);
};
