import { StyleSheet } from 'aphrodite';

const shiftDown = {
	'0%': {
		transform: 'translateY(-80px)',
	},
	'15%': {
		transform: 'translateY(-80px)',
	},
	'85%': {
		transform: 'translateY(0px)',
	},
	'100%': {
		transform: 'translateY(0px)',
	},
};
const shiftAndFadeIn = {
	'0%': {
		transform: 'translateY(-80px)',
		opacity: 0,
	},
	'50%': {
		transform: 'translateY(-80px)',
		opacity: 0,
	},
	'60%': {
		opacity: 0,
	},
	'100%': {
		transform: 'translateY(0px)',
		opacity: 1,
	},
};
const shiftAndFadeOut = {
	'0%': {
		transform: 'translateY(-80px)',
		opacity: 1,
	},
	'25%': {
		opacity: 0,
	},
	'50%': {
		transform: 'translateY(0px)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateY(0px)',
		opacity: 0,
	},
};

const feedItemAnimationLength = '1.5s';

export const styles = StyleSheet.create({
	feedItem: {
		display: 'flex',
		margin: '16px',
		alignItems: 'center',
	},
	feedLoading: {
		overflow: 'hidden',
		height: '240px',
	},
	feedLoadingItem: {
		animationName: [shiftDown] as any,
		animationDuration: feedItemAnimationLength,
		animationIterationCount: 'infinite',
		':first-child': {
			animationName: [shiftAndFadeIn] as any,
			animationDuration: feedItemAnimationLength,
			animationIterationCount: 'infinite',
		},
		':last-child': {
			animationName: [shiftAndFadeOut] as any,
			animationDuration: feedItemAnimationLength,
			animationIterationCount: 'infinite',
		},
	},
	threeBarItem: {
		display: 'flex',
		margin: '16px',
	},
});
