import * as React from 'react';
import { context } from './Context';

/*
	Limitation of this hook is in cases where OS won't allow browsers to detect network changes this hook will not work. In same case tested, Gmail Web app also falied to detect connection status.
*/
export function useConnectionDetailsContext() {
	const { isOnline } = React.useContext(context);
	return isOnline;
}
