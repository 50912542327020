import * as React from 'react';
import { palette } from '@citrite/web-ui-component';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import {
	activeClassName,
	StyledAnchor,
	StyledChildMenuList,
	StyledExpandIcon,
	StyledListItem,
	StyledNavLink,
	StyledSubMenu,
} from 'App/LeftNavigation/SecondaryNavigation/Link.styled';
import { useBubbleUpChildLinks } from 'App/LeftNavigation/useLeftNav';
import { ComponentHeight } from 'Components/ComponentHeight';
import { ChildLink } from './ChildLink';

export interface Props {
	link: LeftNavLink;
	expanded?: boolean;
	currentPath?: string;
	onLinkClick(e: React.MouseEvent, link: LeftNavLink): void;
}

export const Link: React.FC<Props> = props => {
	const link = useBubbleUpChildLinks(props.link);

	const onLinkClick = (e: React.MouseEvent) => {
		props.onLinkClick(e, link);
	};
	return (
		<StyledListItem>
			{link.href ? (
				<StyledNavLink
					to={link.href}
					onClick={onLinkClick}
					aria-expanded={!!link.childLinks.length ? props.expanded : null}
					activeClassName={activeClassName}
				>
					{link.title}
				</StyledNavLink>
			) : (
				<StyledAnchor href="#" onClick={onLinkClick}>
					{link.title}
				</StyledAnchor>
			)}
			{!!link.childLinks.length && (
				<ComponentHeight isOpen={props.expanded}>
					<StyledSubMenu>
						<StyledExpandIcon
							onClick={onLinkClick}
							expanded={props.expanded}
							size={14}
							color={palette.grey600}
						/>
						<StyledChildMenuList>
							{link.childLinks.map((childLink, i) => (
								<ChildLink
									key={`${i}${childLink.href}`}
									link={childLink}
									focusable={props.expanded}
									onLinkClick={e => props.onLinkClick(e, childLink)}
								/>
							))}
						</StyledChildMenuList>
					</StyledSubMenu>
				</ComponentHeight>
			)}
		</StyledListItem>
	);
};
