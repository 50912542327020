import * as React from 'react';
import {
	Button,
	fromThemeColors,
	layout,
	styleUtils,
	ThemeColor,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { ContextMenuItem } from '@citrite/workspace-ui-platform-react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useInScrolledView } from 'App/Screen/useInScrolledView';
import { moreOptionsBuilder } from 'App/Tile/contextMenuBuilder';
import { FavoriteIconWithRef } from 'App/Tile/FavoriteIcon';
import { ResourceDetailsWithFavoriteRef } from 'App/Tile/ResourceDetails';
import { ResourceMenu } from 'App/Tile/ResourceMenu';
import { useBrowserExtensionPrompt } from 'App/Tile/useBrowserExtensionPrompt';
import {
	getUserProfileIdentity,
	launchGenericResource,
	shouldPromptLaunchInCEBPreference,
} from 'App/Tile/utils';
import { performance, PerformanceEvents } from 'Components/performance';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { showInstallExtensionModal } from 'Workspace/BrowserExtension/InstallBrowserExtensionModal';
import {
	LaunchInCEBMode,
	launchSaaSApp,
	SaaSAppLaunchInCEBParams,
} from 'Workspace/CitrixEnterpriseBrowser';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { isSaasApp, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { useUserContext } from 'Workspace/UserContext';

export const largeFormButtonWrapperStyle = {
	display: 'inline-block',
};

export const smallFormButtonWrapperStyle = {
	display: 'block',
};

export const StyledDiv = styled.div<{
	isSmallFormFactor: boolean;
	isResourceAvailable: boolean;
	isOffWhiteBackground: boolean;
}>`
	position: relative;
	border: 1px solid ${fromThemeColors(ThemeColor.border)};
	min-width: 160px;
	height: 168px;
	box-sizing: border-box;
	background-color: ${fromThemeColors('primaryContainerBackground')};
	padding: ${layout.smallSpace};
	border-radius: ${layout.tinySpace};
	box-shadow: ${props =>
		props.isOffWhiteBackground && !props.isSmallFormFactor
			? styleUtils.shadow1.boxShadow
			: 'none'};
	cursor: pointer;
	&:hover {
		transition: box-shadow 0.1s linear;
		box-shadow: ${styleUtils.shadow2.boxShadow};
	}
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		css`
			display: flex;
			height: 56px;
			padding: ${layout.smallSpace} 0;
			width: auto;
			flex-direction: row;
			border: none;
			&:hover {
				box-shadow: none;
			}
		`}

	${({ isResourceAvailable }) =>
		!isResourceAvailable &&
		css`
			&& {
				opacity: 0.5;
				cursor: not-allowed;
			}
		`}
`;
interface Props extends WithModalsProps {
	resource: Resource;
	isResourceAvailable: boolean;
}

export const DivWrapper = (props: { children: React.ReactNode }) => (
	<div {...props}>{props.children}</div>
);

function __GenericTile({ resource, isResourceAvailable, showModal }: Props) {
	const { value: resourceContext } = useLoadableResourceContext();
	const { workspaceConfiguration } = useConfigurationContext();
	const { userDetails } = useUserContext();
	const hasSubscriptionsEnabled = resourceContext.subscriptionsEnabled();
	// We need to use the Favorite component's reference here
	// since GenericTile uses 3rd party Dropdown component for
	// showing dropdown menu. Favorite Component can't be used in
	// 3rd party Dropdown component. Therefore we are using refs
	// to achieve the adding/removing from Favorites functionality.
	// This is an old practice and it is only being done here because
	// custom components can't be used in Dropdown component.
	const favoriteRef = React.useRef(null);
	const { isLargeFormFactor, isSmallFormFactor } = useFormFactor();
	const { elementRef, inScrolledView } = useInScrolledView();

	const { workspaceWebExtensionPrompt } = workspaceConfiguration?.pluginAssistant?.html5;
	const isOffWhiteBackground = useFeatureCanary(FeatureFlag.EnableOffWhiteBackground);
	const resumeResourceLaunch = () =>
		launchGenericResource({ resource, resourceContext, workspaceConfiguration });
	const canShowBrowserExtensionPrompt = useBrowserExtensionPrompt();

	const onClick = () => {
		const isSaaSAppResource = isSaasApp(resource);
		const useCitrixEnterpriseBrowser = workspaceConfiguration?.pluginAssistant?.html5
			?.useCitrixEnterpriseBrowser as LaunchInCEBMode;
		switch (true) {
			case isSaaSAppResource &&
				shouldPromptLaunchInCEBPreference(useCitrixEnterpriseBrowser):
				const params: SaaSAppLaunchInCEBParams = {
					showModal,
					onFallback: resumeResourceLaunch,
					resourceId: resource.id,
					mode: useCitrixEnterpriseBrowser,
					...getUserProfileIdentity(userDetails),
					receiverConfigurationDownloadUrl:
						workspaceConfiguration?.userInterface?.receiverConfiguration?.downloadURL,
				};
				launchSaaSApp(params);
				break;
			case !isSaaSAppResource && canShowBrowserExtensionPrompt:
				showInstallExtensionModal(resumeResourceLaunch, workspaceWebExtensionPrompt);
				break;
			default:
				launchGenericResource({ resource, resourceContext, workspaceConfiguration });
				break;
		}
	};

	const contextMenuBuilder = (): ContextMenuItem[] =>
		moreOptionsBuilder({
			resource,
			resourceContext,
			workspaceConfiguration,
			hasSubscriptionsEnabled,
			favoriteRef,
			showModal,
		});

	const shouldWrapButtonComponent = !useFeatureCanary(
		FeatureFlag.DisableResourceButtonWrap
	);

	const Wrapper = shouldWrapButtonComponent ? Button.Wrapper : DivWrapper;

	React.useEffect(() => {
		performance.markApplicationLoad(PerformanceEvents.WSUIRendered);
	}, []);

	return (
		<Wrapper
			disabled={!isResourceAvailable}
			onClick={onClick}
			style={
				isLargeFormFactor ? largeFormButtonWrapperStyle : smallFormButtonWrapperStyle
			}
		>
			<StyledDiv
				isSmallFormFactor={isSmallFormFactor}
				isResourceAvailable={isResourceAvailable}
				isOffWhiteBackground={isOffWhiteBackground}
				data-analytics-name="resource-tile"
				data-testid={resource.name}
				ref={elementRef}
			>
				{inScrolledView && (
					<>
						{isLargeFormFactor && isResourceAvailable && hasSubscriptionsEnabled && (
							<FavoriteIconWithRef resource={resource} ref={favoriteRef} />
						)}

						<ResourceDetailsWithFavoriteRef
							resource={resource}
							ref={favoriteRef}
							isSmallFormFactor={isSmallFormFactor}
							isResourceAvailable={isResourceAvailable}
							hasSubscriptionsEnabled={hasSubscriptionsEnabled}
						/>

						{isResourceAvailable && (
							<ResourceMenu resource={resource} contextMenuBuilder={contextMenuBuilder} />
						)}
					</>
				)}
			</StyledDiv>
		</Wrapper>
	);
}

const _GenericTile = withModals(__GenericTile);

export const GenericTile = (props: any) => {
	return (
		<GenericErrorBoundary
			renderProp={() => <_GenericTile {...props} />}
			name="GenericTile"
		/>
	);
};
