import * as React from 'react';
import { t } from '@citrite/translate';
import {
	ActionsIcon,
	ActionsSolidIcon,
	LoadingOverlayPrimary,
	LoadingOverlaySecondary,
} from '@citrite/web-ui-component';
import { hasFeatureCanary, LeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { createLeftNavRoute } from 'Router/ReactRoute';
import { PerformanceEvents } from './performanceEvents';

const AllActionsPage = makeAsync(() => {
	return import(/* webpackChunkName: "Actions" */ './AllActionsPage').then(
		m => m.AllActionsPage
	);
}, <FlagSwitchedLoadingOverlay />);

function FlagSwitchedLoadingOverlay() {
	const { workspaceConfiguration } = useConfigurationContext();
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav) ? (
		<LoadingOverlayPrimary />
	) : (
		<LoadingOverlaySecondary />
	);
}

export const allActionsRoutePath = '/actions/all';

export const allActionsRoute = createLeftNavRoute({
	component: AllActionsPage,
	path: allActionsRoutePath,
	getNavLinkProps: () => ({
		title: t('Workspace:actions.actions_page_title'),
		weight: LeftNavPositions.actions,
		performanceMark: PerformanceEvents.ActionsLink_Click,
		icon: ActionsIcon,
		selectedIcon: ActionsSolidIcon,
	}),
	isAvailable: context => context.userContext.isIWSEnabled,
});
