import React, { useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import { layout, LightText, palette, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import {
	getDayTime,
	getGreetingRefreshTimeInMilliseconds,
	getTodayString,
} from 'App/Header/util';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useUserContext } from 'Workspace/UserContext';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px 64px 0 64px;
	background-color: inherit;
`;

const StyledMessageSpan = styled.span`
	font-size: ${typography.sizes.xl2};
	font-weight: ${typography.weights.semiBold};
	color: ${palette.black};
`;

const StyledLightText = styled(LightText)`
	font-weight: ${typography.weights.regular};
	margin-top: ${layout.tinySpace};
	color: ${palette.grey800};
`;

export const Greeting = () => {
	const [greetingDay, setGreetingDay] = useState<String>('');
	const [greetingMessage, setGreetingMessage] = useState<String>('');
	const [greetingRefreshTimeout, setGreetingRefreshTimeout] =
		useState<ReturnType<typeof setTimeout>>();
	const { userDetails } = useUserContext();
	const showUsername = useFeatureCanary(FeatureFlag.EnableUserDisplayName);
	const setGreeting = () => {
		const username =
			showUsername && userDetails?.isValidUserDisplayName && userDetails?.userDisplayName
				? `, ${userDetails.userDisplayName}`
				: '';
		setGreetingDay(getTodayString());
		setGreetingMessage(`${t(`Workspace:greeting.${getDayTime()}`)}${username}`);
		if (greetingRefreshTimeout) {
			clearTimeout(greetingRefreshTimeout);
		}
		const refreshTime = getGreetingRefreshTimeInMilliseconds();
		if (refreshTime > 0) {
			setGreetingRefreshTimeout(setTimeout(setGreeting, refreshTime));
		}
	};

	useEffect(() => {
		setGreeting();
		return () => {
			clearTimeout(greetingRefreshTimeout);
		};
	}, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<StyledDiv data-testid="application-greeting">
			<StyledMessageSpan data-testid="greeting-message">
				{greetingMessage}
			</StyledMessageSpan>
			<StyledLightText data-testid="greeting-day">{greetingDay}</StyledLightText>
		</StyledDiv>
	);
};
