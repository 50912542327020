import { StyleSheet } from 'aphrodite';
import { styleUtils, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	item: {
		display: 'flex',
		alignItems: 'center',
		padding: '10px',
		':hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
		},
	},
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		minWidth: 0,
	},
	itemSelection: {
		width: '100%',
		minWidth: 0,
	},
	icon: {
		margin: '0 16px 0 8px',
	},
	iconUrl: {
		height: '32px',
		width: '32px',
		minHeight: '32px',
		minWidth: '32px',
	},
	nameWrapper: {
		overflow: 'hidden',
	},
	name: {
		wordBreak: 'break-word',
	},
	subtitle: {
		...styleUtils.ellipsis,
		fontSize: typography.sizes.xsml2,
	},
});
