import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { sendCustomerConfiguration } from 'Environment/BrowserExtension/browserExtensionBridge';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType, setClientType } from 'Environment/getClientType';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { isPreviousLogoutExplicit } from 'Logout/previousLogoutConfig';
import { logBrowserExtensionError } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';
import { ExtensionConfigurationState } from 'Workspace/BrowserExtension/Context';
import { parseExtensionConfiguration } from 'Workspace/BrowserExtension/customerConfiguration';
import { lastUserDetailsKey } from 'Workspace/UserContext/constants';

export const sendCustomerConfigurationToBxtn = async (
	workspaceConfiguration: WorkspaceConfiguration
) => {
	const responseFromBxtn = await sendCustomerConfiguration({
		storeId: workspaceConfiguration.storeIdentifiers.storeGuid,
		customerId: workspaceConfiguration.storeIdentifiers.customerId,
		endpoints: workspaceConfiguration.endpointsServices.endpointsService,
		isPreviousLogoutExplicit: isPreviousLogoutExplicit(),
		hasLastUserDetails: !!getFromLocalStorage(lastUserDetailsKey),
		idpConfigId: workspaceConfiguration.authManager?.idpConfigId,
		isHtml5Enabled: getClientType() === clientType.html5,
		isModernOfflineFlowEnabledForStore: hasFeatureCanary(
			workspaceConfiguration,
			FeatureFlag.UseModernOfflineFlow
		),
	})
		.then(response => {
			if (!getClientType()) {
				setClientType(clientType.browserextension);
			}

			return {
				browserExtensionResponse: response,
				extensionConfig: parseExtensionConfiguration(response.result),
			};
		})
		.catch(error => {
			logBrowserExtensionError(error);
			const result: ExtensionConfigurationState = {
				isActive: false,
				allowAccessToOfflineResources: false,
				useAuthenticationTimer: false,
			};
			return {
				browserExtensionResponse: null,
				extensionConfig: result,
			};
		});
	return responseFromBxtn;
};
