import React from 'react';
import { t } from '@citrite/translate';

interface Props {
	title: string;
	className?: string;
}

export const ActivityTitle = (props: Props) => {
	return (
		<div
			className={props.className}
			aria-label={t('Workspace:activity_manager.activity') + ' ' + props.title}
			data-testid={'activityBodyTitle : ' + props.title}
		>
			{props.title}
		</div>
	);
};
