import { InMemoryStorage } from 'Environment/InMemoryStorage';
import { isNativeClient } from 'Environment/launchResource/device';
import { NativeStorage } from 'Environment/NativeStorage';
import { ProvidedNativeFunctions } from 'Environment/providedNativeFunctions';
import { defaultLocalStorageFunctions, StorageInterface } from 'javascript/sf/Storage';

export type FunctionFromNative = keyof ProvidedNativeFunctions;
export enum NativeStorageType {
	Sync = 'sync',
	Async = 'async',
	None = 'none',
}

// allow-unused-export: for unit tests
export function needsNativeStorage(): boolean {
	return IS_ON_PREM && isNativeClient();
}

// allow-unused-export: for unit tests
export function getNativeStorageType(
	functionsFromNative: FunctionFromNative[]
): NativeStorageType {
	if (!needsNativeStorage()) {
		return NativeStorageType.None;
	}

	if (functionsFromNative?.includes('sync_setItem')) {
		return NativeStorageType.Sync;
	}

	if (functionsFromNative?.includes('setItem')) {
		return NativeStorageType.Async;
	}

	return NativeStorageType.None;
}

export function getEnvironmentSpecificStorage(
	functionsFromNative: FunctionFromNative[]
): StorageInterface {
	const storageType = getNativeStorageType(functionsFromNative);

	if (storageType !== NativeStorageType.None) {
		return new InMemoryStorage(new NativeStorage(storageType));
	}

	return defaultLocalStorageFunctions;
}
