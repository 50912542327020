import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	breakpoints,
	fromThemeColors,
	layout,
	palette,
	ThemeColor,
	typography,
} from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { activeClassName } from 'App/LeftNavigation/PrimaryNavigation/Link.styled';
import { FeatureFlag } from 'Environment/FeatureFlag';

export const StyledLoading = styled.div`
	padding: 5px 0;
	margin-left: 40px;
`;

export const StyledListItem = styled.li`
	padding-left: ${layout.mediumLargeSpace};
	border: none;
	position: relative;
	width: 216px;

	${breakpoints.onDesktop} {
		:hover {
			background-color: ${fromThemeColors(ThemeColor.listItemHoverBackground)};
		}
	}

	${breakpoints.onMobile} {
		${() =>
			!getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
				width: '276px',
			}}
	}
`;

export const StyledDivider = styled.div`
	margin: 10px 30px 5px 36px;
	background: ${palette.grey300};
	height: 1px;
`;

const childLinkCss = css`
	text-decoration: none;
	display: inline-block;
	font-size: ${typography.sizes.sml};
	width: 100%;
	white-space: normal;
	line-height: 16px;

	${breakpoints.onMobile} {
		font-size: ${typography.sizes.base};
	}

	&.${activeClassName} {
		font-weight: ${typography.weights.semiBold};
	}
`;

export const StyledNavLink = styled(NavLink)`
	${childLinkCss};
`;

export const StyledAnchor = styled.a`
	${childLinkCss};
`;

export const StyledLinkBody = styled.span`
	display: flex;
	align-items: center;
	padding: ${layout.mediumSpace} 0;
`;

export const StyledIcon = styled.span`
	flex: 0 0 16px;
	height: 16px;
	display: inline-block;
	margin: 0 12px 0 8px;
`;

export const StyledLink = styled.span`
	transition: opacity 0.5s;
	padding-right: 5px;
	display: flex;
	align-items: center;
`;

export const StyledSecondaryIcon = styled.span`
	margin-left: 10px;
`;

export const StyledLinkText = styled.span<{ withIcon: boolean }>`
	${props => props.withIcon && textWithIconStyle}
`;

const textWithIconStyle = css`
	max-width: 135px;
	${breakpoints.onMobile} {
		max-width: 190px;
	}
`;
