import * as React from 'react';
import { media, useBreakpoints } from '@citrite/web-ui-component';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { StyledIcon, StyledIconsRow } from 'App/Masthead/IconsRow.styled';
import { UserMenu } from 'App/Masthead/UserMenu';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';

const ItemSearch = makeAsync(
	() =>
		import(/* webpackChunkName: "ItemSearch" */ 'Components/ItemSearch').then(
			m => m.ItemSearch
		),
	null,
	() => null
);

export interface Props {
	hasSearch: boolean;
	hasUserMenu: boolean;
}

export const IconsRow: React.FC<Props> = ({ hasSearch, hasUserMenu }) => {
	const breakpoints = useBreakpoints();
	const searchIconSize = breakpoints.screenSize.onMobile ? 21 : 26;
	const { workspaceConfiguration } = useConfigurationContext();
	const icons = [
		hasSearch &&
			(breakpoints.screenSize.onMobile ||
				(hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav) &&
					breakpoints.screenSize.media !== media.none)) && {
				key: 'search',
				element: <ItemSearch size={searchIconSize} />,
			},
		hasUserMenu &&
			(!breakpoints.screenSize.onMobile ||
				hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav)) && {
				key: 'usermenu',
				element: <UserMenu />,
			},
	].filter(Boolean);

	return icons.length === 0 ? null : (
		<>
			<StyledIconsRow>
				{icons.map(icon => (
					<StyledIcon key={icon.key}>{icon.element}</StyledIcon>
				))}
			</StyledIconsRow>
		</>
	);
};
