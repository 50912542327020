import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

const styles = StyleSheet.create({
	item: {
		display: 'flex',
		alignItems: 'center',
		padding: '12px',
		background: palette.white,
	},
});

export const ListItem: React.FC = ({ children }) => {
	return <div className={css(styles.item)}>{children}</div>;
};
