import { palette } from '@citrite/web-ui-component';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

export type TransitionState = 'entering' | 'exiting';

export interface StyledLoadingAnimationState {
	animationState: TransitionState;
}

const enteringMessageStyles = css`
	opacity: 1;
	height: 52px;
	transition: height 0.5s, opacity 0.5s 0.5s;
`;
const exitingMessageStyles = css`
	opacity: 0;
	height: 0px;
	transition: height 0.5s 0.5s, opacity 0.5s;
`;

function getMessageAnimationForState(animationState: TransitionState): SerializedStyles {
	return animationState === 'entering' ? enteringMessageStyles : exitingMessageStyles;
}

export const LoadingMessageText = styled.p`
	font-size: 16px;
	font-weight: 600;
	margin-top: 32px;
	margin-bottom: 0px;
	color: ${palette.grey900};
`;

export const LoadingMessageAnimation = styled.div<StyledLoadingAnimationState>`
	opacity: 0;
	${props => getMessageAnimationForState(props.animationState)}
`;
