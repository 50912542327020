import * as React from 'react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';
import { createAdminNotificationClient } from './createAdminNotificationClient';

export const ANS_SERVICE_NAME = 'adminNotification';

export const useAdminNotificationClient = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { cacheFactory } = useCacheContext();

	return React.useMemo(() => {
		const endpointService = getEndpointsService(workspaceConfiguration, ANS_SERVICE_NAME);
		if (!endpointService) {
			return null;
		}

		const endpointSupplier = createEndpointSupplier(endpointService.discoveryUrl);
		const ssoProxyClient = environment.createSSOProxyClient(
			workspaceConfiguration.authManager.proxyUrl
		);

		return createAdminNotificationClient(
			endpointSupplier,
			ssoProxyClient,
			cacheFactory.createEncryptedCacheBucket(BucketManifest.adminNotification),
			workspaceConfiguration.storeIdentifiers.customerId,
			workspaceConfiguration.storeIdentifiers.storeGuid
		);
	}, [workspaceConfiguration, cacheFactory]);
};
