import * as React from 'react';
import { MainContent } from '@citrite/workspace-ui-platform-react';
import { ComposableMobileMastheadTitle } from 'App/ComposableHome/ComposableMobileMastheadTitle';
import { ComposablePageRenderer } from 'App/ComposableHome/ComposablePage';
import { AllDesktops } from 'App/Desktops/allDesktopsRoute';
import { isDesktopOnlyHomepage } from 'App/isDesktopOnlyHomepage';
import { Home } from 'App/Screen';
import { RoutedComponentProps } from 'Router/ReactRoute';

export type ComposableHomeProps = RoutedComponentProps;

export const ComposableHome: React.FC<ComposableHomeProps> = props => {
	const daasVisionEnabled = props.userContext.isDaasVisionEnabled;
	if (daasVisionEnabled) {
		return <Home />;
	}
	if (
		isDesktopOnlyHomepage(
			props.workspaceConfiguration,
			props.userContext.isIWSEnabled,
			props.resourceContext
		)
	) {
		return (
			<MainContent>
				<AllDesktops {...props} />
			</MainContent>
		);
	}

	return (
		<>
			<ComposableMobileMastheadTitle {...props} />
			<ComposablePageRenderer />
		</>
	);
};
