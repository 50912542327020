import * as React from 'react';
import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ActivityTitle } from 'App/Activity/ActivityTitle/ActivityTitle';
import { clickableStyles } from 'App/Activity/components/commonStyles';
import {
	LaunchAction,
	ResourceDetails,
	Session,
	SessionType,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { canReconnect } from 'App/Activity/useDesktopActions';
import { getDefaultSessionIcon } from 'App/Activity/Utility';
import { useFormFactor } from 'App/Header/useFormFactor';
import { ResourceIcon } from 'Components/ResourceIcon';

interface DetailsProps {
	resourceDetails: ResourceDetails;
	session: Session;
	isDesktopApp?: boolean;
	children: React.ReactNode;
	styleProps?: SerializedStyles;
	hasPadding?: boolean;
}

interface StyledProps {
	styledProps: SerializedStyles;
	isClickable?: boolean;
}

const StyledActivityBodyContent = styled.div<StyledProps>`
	display: flex;
	flex-direction: row;
	padding: 0 0 0 ${layout.smallSpace};
	justify-content: space-between;
	position: relative;
	align-items: center;
	${props => props.styledProps};
	${props => props.isClickable && clickableStyles}
`;

const StyledTitle = styled(ActivityTitle)`
	flex-wrap: wrap;
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.regular};
	color: ${fromThemeColors('primaryText')};
	margin-left: ${layout.smallSpace};
	align-content: center;
	white-space: nowrap;
	display: inline;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledActivityBodySubContent = styled.div<{ hasPadding?: boolean }>`
	display: flex;
	align-items: center;
	${props =>
		props.hasPadding &&
		`
    padding: 11px 0;
  `}
	overflow: hidden;
	text-overflow: ellipsis;
`;

const smallFormIconSize = 24;
const largeFormIconSize = 16;

export const BaseDetails = ({
	resourceDetails,
	children,
	styleProps,
	hasPadding,
	session,
}: DetailsProps) => {
	const resource = resourceDetails?.resource;

	const { isSmallFormFactor } = useFormFactor();
	const iconSize = isSmallFormFactor ? smallFormIconSize : largeFormIconSize;
	const { onDesktopAction } = useResourceManagerContext();
	const canReconnectSession =
		canReconnect(session) && session.userSessionType === SessionType.DESKTOP;

	const handleClick = () => {
		if (canReconnectSession) {
			onDesktopAction(LaunchAction.RECONNECT, session);
		}
	};

	return (
		<StyledActivityBodyContent
			data-testid="activity-body-content"
			styledProps={styleProps}
			onClick={canReconnectSession ? handleClick : undefined}
			isClickable={canReconnectSession}
		>
			<StyledActivityBodySubContent hasPadding={hasPadding}>
				<ResourceIcon
					resource={resource}
					DefaultIcon={getDefaultSessionIcon(resource)}
					size={iconSize}
					resourceIconUrl={resource ? null : resourceDetails?.iconUrl}
				/>
				<StyledTitle title={resourceDetails?.name} />
			</StyledActivityBodySubContent>
			{children}
		</StyledActivityBodyContent>
	);
};
