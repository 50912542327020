import React from 'react';
import { t } from '@citrite/translate';
import {
	AppWindowLinkIcon,
	layout,
	OverflowNavigation,
	palette,
} from '@citrite/web-ui-component';
import { PinnedLink } from '@citrite/workspace-ui-platform';
import { css } from '@emotion/react';
import { trackAnalyticsEvent } from 'analytics';
import {
	StyledDiv,
	StyledImage,
	StyledLightText,
	StyledTile,
	StyledTileText,
	StyledTitleText,
} from 'App/CustomLinkBanner/CustomLink.styled';
import { CustomLinkItem } from 'App/CustomLinkBanner/CustomLinkItem';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { PinnedLinksUserEventPayload } from 'Workspace/TelemetryEvents/pinnedLinks/createPinnedLinksCASReporter';

export interface CustomLinkBannerProps {
	customLinkData: PinnedLink[];
	isSmallFormFactor: boolean;
}
const listStyle = css`
	display: flex;
	flex-direction: column;
	gap: ${layout.mediumSpace};
	background-color: ${palette.white};
`;
const menuStyle = css`
	max-height: 25vh;
	padding: ${layout.mediumLargeSpace};
	overflow-y: auto;
	background-color: ${palette.white};
`;

const listTextStyle = css`
	padding: 0;
	background-color: ${palette.white};
`;

const navigationStyle = css`
	background-color: ${palette.grey200};
`;

const listItemStyle = css`
	background-color: ${palette.white};
`;

const _CustomLinkBanner: React.FC<CustomLinkBannerProps> = (
	props: CustomLinkBannerProps
) => {
	return !props.isSmallFormFactor ? (
		<OverflowNavigation
			items={props.customLinkData}
			template={CustomLinkItem}
			stylesList={{ listStyle, menuStyle, listTextStyle, navigationStyle, listItemStyle }}
		/>
	) : (
		<StyledDiv data-testid="custom-links">
			{props.customLinkData.map((customLink, index) => (
				<StyledTile key={index}>
					{!customLink?.iconUrl ? (
						<AppWindowLinkIcon size={24} />
					) : (
						<StyledImage src={customLink.iconUrl} height={24} width={24} alt="Icon" />
					)}
					<StyledTileText
						href={customLink.hyperlink}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => {
							trackAnalyticsEvent(PinnedLinksUserEventPayload.pinnedLinkClicked());
						}}
					>
						<StyledTitleText>{customLink.displayText}</StyledTitleText>
						<StyledLightText>{t('Workspace:pinned_links.external_link')}</StyledLightText>
					</StyledTileText>
				</StyledTile>
			))}
		</StyledDiv>
	);
};

export const CustomLinkBanner: React.FC<CustomLinkBannerProps> = (
	props: CustomLinkBannerProps
) => {
	return (
		<GenericErrorBoundary
			renderProp={() => (
				<_CustomLinkBanner
					customLinkData={props.customLinkData}
					isSmallFormFactor={props.isSmallFormFactor}
				/>
			)}
			name="PinnedLinkError"
		/>
	);
};
