import * as React from 'react';
import { tryExtractTransactionId } from '@citrite/http';
import { activityEventPublisher } from '@citrite/workspace-iws-ui';
import {
	Action,
	ActionsCapability,
	EncryptedCacheBucket,
	getTelemetryClient,
	IntegrationCapability,
	LoadableCapability,
	LoadableIntegration,
	OnActionClickOptions,
} from '@citrite/workspace-ui-platform';
import { useEncryptedStorage } from 'App/Actions/useEncryptedStorage';
import { useIntegrations } from 'Integrations/useIntegrations';
import { ActionsProvider, ActionWithClickHandler } from '../actionTypes';
import {
	addActionToRecents,
	getRecentActions,
	recentActionsEnabled,
} from '../recentActions';

interface ActionProviderState {
	error?: any;
	results: Action[];
	onActionClick(action: Action, options?: OnActionClickOptions): void;
}

type ProviderCollection = { [key: string]: ActionProviderState };
type ActionProviderStateUpdate = {
	serviceId: string;
	nextState: ActionProviderState;
};

export interface ActionAggregatorProps {
	children: (context: ActionsProvider) => React.ReactElement<any>;
}

export interface InternalProps extends ActionAggregatorProps {
	actionProviders: LoadableCapability<ActionsCapability>[];
	storage: EncryptedCacheBucket;
}

interface State {
	loadingActions: boolean;
	providers: ProviderCollection;
	recentActionIds: string[];
}

class _ActionAggregator extends React.Component<InternalProps, State> {
	private accessActionsEventPublisher: ReturnType<typeof activityEventPublisher>;

	public constructor(props: InternalProps) {
		super(props);

		const { publishEvent } = getTelemetryClient();
		this.accessActionsEventPublisher = activityEventPublisher(
			publishEvent,
			'ACCESS',
			'ACTIONS'
		);
	}

	public state: State = {
		loadingActions: true,
		providers: this.props.actionProviders.reduce((collection, provider) => {
			collection[provider.moduleKey] = {
				error: provider.error,
				results: [],
				onActionClick: () => {},
			};
			return collection;
		}, {} as ProviderCollection),
		recentActionIds: recentActionsEnabled() ? null : [],
	};

	private activeActionResolvers: Map<string, Promise<ActionProviderStateUpdate>> =
		new Map<string, Promise<ActionProviderStateUpdate>>();

	public render() {
		const serviceResults = this.getProvidedServiceNames().map(name => {
			return this.state.providers[name]
				? this.state.providers[name].results.map(action => {
						const actionWithClickHandler: ActionWithClickHandler = {
							...action,
							onClick: options =>
								this.state.providers[name].onActionClick(action, options),
						};
						return actionWithClickHandler;
				  })
				: [];
		});
		const actions: ActionWithClickHandler[] = [].concat(...serviceResults);
		const sortedActions = sortActions(actions);
		const actionGroups = getActionGroups(sortedActions);
		return this.props.children({
			value: {
				actions,
				recentActionIds: this.state.recentActionIds,
				actionGroups,
				refreshActions: () => this.aggregateActions(),
				addActionToRecents: this.addActionToRecents,
			},
			loading: this.getLoading(),
			error: this.hasError(),
		});
	}

	public componentDidMount() {
		this.aggregateActions();
		this.getRecentActions();
	}

	public componentDidUpdate(prevProps: InternalProps) {
		const changedProviders = this.props.actionProviders.filter(provider => {
			const isReady = this.readyToLoad(provider);
			if (!isReady) {
				return false;
			}
			const previousProvider = prevProps.actionProviders.find(
				prevProvider => prevProvider.moduleKey === provider.moduleKey
			);
			return isReady && (!previousProvider || !this.readyToLoad(previousProvider));
		});

		if (changedProviders.length) {
			this.aggregateActions(changedProviders);
		}
	}

	private getProvidedServiceNames() {
		return Object.keys(this.state.providers);
	}

	private getLoading() {
		return (
			!!this.props.actionProviders.find(p => p.loading) ||
			this.state.loadingActions ||
			!this.state.recentActionIds
		);
	}

	private aggregateActions(
		providersToRun?: LoadableIntegration<ActionsCapability, any>[]
	) {
		providersToRun =
			providersToRun ||
			this.props.actionProviders.filter(provider => this.readyToLoad(provider));
		if (!providersToRun.length) {
			this.setState({ loadingActions: false });
			return;
		}
		this.accessActionsEventPublisher.startTimer();

		const batchChange = providersToRun.map(provider => {
			const promise = this.getAppActions(provider.moduleKey, provider.value).then(
				update => {
					this.activeActionResolvers.delete(provider.moduleKey);
					return update;
				}
			);
			this.activeActionResolvers.set(provider.moduleKey, promise);
			return promise;
		});

		Promise.all(batchChange).then(results => {
			const providerStateUpdates = results.reduce((collection, providerUpdate) => {
				collection[providerUpdate.serviceId] = providerUpdate.nextState;
				return collection;
			}, {} as ProviderCollection);

			let failedProviderError: any = null;
			const haveAllProvidersFailed = results.reduce((prev, current) => {
				if (current.nextState.error !== null) {
					failedProviderError = current.nextState.error;
				}
				return prev && current.nextState.error !== null;
			}, true);

			this.setState(state => ({
				loadingActions: this.activeActionResolvers.size > 0,
				providers: {
					...state.providers,
					...providerStateUpdates,
				},
			}));

			if (haveAllProvidersFailed) {
				this.accessActionsEventPublisher.publishEvent(
					'FAILURE',
					Date.now(),
					failedProviderError!.status,
					tryExtractTransactionId(failedProviderError)
				);
			} else {
				this.accessActionsEventPublisher.publishEvent('SUCCESS', Date.now());
			}
		});
	}

	private readyToLoad = (loadableProvider: LoadableIntegration<ActionsCapability, any>) =>
		loadableProvider.value && !loadableProvider.loading && !loadableProvider.error;

	private getAppActions(
		serviceId: string,
		service: ActionsCapability
	): Promise<ActionProviderStateUpdate> {
		return service
			.getActionApps()
			.then((actions: Action[]) => {
				return {
					serviceId,
					nextState: {
						loadingResults: false,
						results: actions,
						error: null,
						onActionClick: service.onActionClick,
					},
				};
			})
			.catch((error: any) => {
				return {
					serviceId,
					nextState: {
						loadingResults: false,
						results: [],
						error,
						onActionClick: service.onActionClick,
					},
				};
			});
	}

	private hasError() {
		const hasCtxMFEs = !!this.props.actionProviders.length;
		if (!hasCtxMFEs) {
			return false;
		}

		const noCtxMFEsLoaded =
			hasCtxMFEs &&
			this.props.actionProviders.every(loadableProvider => loadableProvider.error);

		const noResultsCouldLoad = this.getProvidedServiceNames().every(
			name => this.state.providers[name].error
		);
		return noCtxMFEsLoaded || noResultsCouldLoad;
	}

	private getRecentActions() {
		getRecentActions(this.props.storage).then(recentActionIds =>
			this.setState({ recentActionIds })
		);
	}

	private addActionToRecents = async (actionId: string) => {
		await addActionToRecents(actionId, this.props.storage);
		this.getRecentActions();
	};
}

function getActionGroups(actions: Pick<ActionWithClickHandler, 'serviceName'>[]) {
	const groupNames = new Set(actions.map(a => a.serviceName));
	return Array.from(groupNames).sort((a, b) => {
		return a.trim() > b.trim() ? 1 : -1;
	});
}

function sortActions(actions: ActionWithClickHandler[]) {
	return actions.sort((a, b) => {
		return a.title.trim() > b.title.trim() ? 1 : -1;
	});
}

export function ActionAggregator(props: ActionAggregatorProps) {
	const integrations = useIntegrations();
	const actionProviders = integrations.resolveByCapability(
		IntegrationCapability.actionsProvider
	);
	const storage = useEncryptedStorage();
	return (
		<_ActionAggregator storage={storage} actionProviders={actionProviders} {...props} />
	);
}
