import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
	details: {
		display: 'flex',
		flexBasis: '100%',
		marginTop: '20px',
	},
	emptyDetails: {
		alignSelf: 'center',
	},
});
