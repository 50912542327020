import { getFeatureFlagValue } from '@citrite/feature-flags';
import { BannerRegion, breakpoints, layout, palette } from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	mastheadHeight,
	mastheadHeightMobile,
	mastheadLogoHeight,
} from 'Workspace/ThemeProvider';

interface StyledBannerRegionProps {
	fullPageRoute: boolean;
	collapseOnMobile: boolean;
}

export const StyledBannerRegion = styled(BannerRegion, {
	shouldForwardProp: isPropValid,
})<StyledBannerRegionProps>(props => ({
	height: mastheadHeight,
	flexShrink: 0,
	backgroundColor: props.theme.branding.styles.HeaderBackgroundColor,
	color: props.theme.branding.styles.HeaderTextColor,
	borderBottom: `1px solid ${palette.grey300}`,
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	position: props.fullPageRoute ? 'relative' : 'fixed',
	boxSizing: 'border-box',
	top: `${props.theme.mastheadOffset.informationalBannerOffset}px`,
	'@media print': {
		display: 'none',
	},
	zIndex: 2,
	[breakpoints.onMobile]: props.collapseOnMobile && {
		borderBottom: '0',
		height: mastheadHeightMobile,
		boxShadow: '0 3px 10px -2px rgba(0, 0, 0, 0.16)',
		flexDirection: 'row-reverse',
	},
	[breakpoints.onSmallScreen]: getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
		flexDirection: 'row-reverse',
	},
}));

const customLogoStyles = (props: { theme: Theme }) => css`
	background-image: url('${props.theme.branding.links.HeaderLogoUrl}');
	width: 170px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0 center;
	background-size: contain;
	> span {
		display: none;
	}
`;

const citrixLogoStyles = css`
	display: flex;
	align-items: center;
	> span:nth-of-type(2) {
		margin-top: ${layout.tinySpace};
		margin-left: ${layout.smallSpace};
	}
`;

interface StyledLogoLinkProps {
	collapseOnMobile: boolean;
}
export const StyledLogoLink = styled(Link, {
	shouldForwardProp: isPropValid,
})<StyledLogoLinkProps>`
	${breakpoints.onMobile} {
		display: ${props => (props.collapseOnMobile ? 'none' : 'inline-block')};
	}

	${breakpoints.onSmallScreen} {
		display: none;
	}
	> div {
		${breakpoints.onMobile} {
			height: ${mastheadHeightMobile};
			${/* icon size (24px) + spec'd margin-left (32px) */ ''}
			margin-left: 56px;
			padding-left: ${layout.mediumLargeSpace};
		}
		height: ${mastheadLogoHeight};
		margin-left: ${layout.mediumSpace};
		${props =>
			props.theme.branding.links.HeaderLogoUrl
				? customLogoStyles(props)
				: citrixLogoStyles}
	}
	> h1 {
		display: none;
	}
`;
