import { StyleSheet } from 'aphrodite';
import { styleUtils } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	enabled: {
		right: '0',
		position: 'absolute',
		padding: '13px',
	},
	focusStyle: {
		':focus > :first-child': {
			boxShadow: 'none',
		},
		':focus > :first-child > :first-child': {
			...styleUtils.focusOutsetThick,
			borderRadius: '50%',
		},
	},
});
