import * as React from 'react';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { useHTMLElementProperties } from 'Components/AppLayout/useHTMLElementProperties';
import { StyledAppBody } from './styled';

export const AppBody: React.SFC = props => {
	const height = useClientHeight();
	const { elementRef, offsetTop } = useHTMLElementProperties();
	const { isSmallFormFactor } = useFormFactor();
	return (
		<StyledAppBody
			clientHeight={height}
			ref={elementRef}
			offsetTop={offsetTop}
			isSmallFormFactor={isSmallFormFactor}
		>
			{props.children}
		</StyledAppBody>
	);
};
