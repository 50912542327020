import { workspaceLocales } from '@citrite/workspace-ui-platform';
import * as Cookie from 'js-cookie';
import { getFromSessionStorage } from './Storage';

export const SUPPORTED_LANGUAGES = [
	'en',
	'de',
	'es',
	'fi',
	'fr',
	'it',
	'ja',
	'zh-CN',
	'nl',
	'pt-BR',
	'ko',
	'zh-TW',
];

export function detectLanguage(): workspaceLocales {
	const storedLanguage =
		getFromSessionStorage<workspaceLocales>('useLanguage') ||
		(Cookie.get('useLanguage') as workspaceLocales);
	if (storedLanguage) {
		return storedLanguage;
	}
	const languages = getPreferredLanguages();
	const supportedLanguageCode = languages
		.map(language => getSupportedLanguageCode(language))
		.find(languageCode => !!languageCode);
	return (supportedLanguageCode || workspaceLocales.Default) as workspaceLocales;
}

function getSupportedLanguageCode(languageCode: string) {
	return (
		SUPPORTED_LANGUAGES.find(language => {
			return language === languageCode;
		}) ||
		(SUPPORTED_LANGUAGES.find(language => {
			return language.substring(0, 2) === languageCode.substring(0, 2);
		}) as workspaceLocales)
	);
}

function getPreferredLanguages() {
	return navigator.languages && navigator.languages.length > 0
		? navigator.languages
		: [navigator.language];
}

//Created new function to send language string as required by pendo for initialization.
// Don't use it for any other scenario.
export function getLanguageStringForPendo() {
	const lang = detectLanguage();
	switch (lang) {
		case 'zh-CN':
			return 'zh_CN';
		case 'zh-TW':
			return 'zh_TW';
		case 'pt-BR':
			return 'pt';
		default:
			return lang;
	}
}
