import React, { useEffect, useState } from 'react';
import { palette, styleUtils } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ResourceAlert } from 'App/ProgressAlert/ResourceAlert';
import { WaitAlert } from 'App/ProgressAlert/WaitAlert';
import { LaunchStatus } from 'Environment/launchResource/launchResource';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 4;
	box-shadow: ${styleUtils.shadow3.boxShadow};
	background-color: ${palette.white};
	border-radius: 8px;
`;
export function LaunchProgressWindow() {
	const { value } = useLoadableResourceContext();
	const { resources, launchProgress, updateSession } = value;
	const [showWait, setShowWait] = useState(false);
	const [showAlert, setShowAlert] = useState(true);

	const avoidBrowserClose = (event: Event) => {
		setShowAlert(true);
		setShowWait(true);
		event.preventDefault();
		event.returnValue = true;
		return false;
	};

	useEffect(() => {
		setShowAlert(true);
		const disableBrowserClose = Object.keys(launchProgress).some(
			operationId => launchProgress[operationId].status !== LaunchStatus.Cancelling
		);
		if (!disableBrowserClose) {
			setShowWait(false);
		}
		disableBrowserClose && window.addEventListener('beforeunload', avoidBrowserClose);
		return () => {
			disableBrowserClose &&
				window.removeEventListener('beforeunload', avoidBrowserClose);
		};
	}, [launchProgress]);
	const handleCancel = (operationId: string) => {
		const launchDetails = launchProgress[operationId];
		launchDetails.onCancel();
		updateSession(state => ({
			launchProgress: {
				...state.launchProgress,
				[operationId]: {
					...state.launchProgress[operationId],
					status: LaunchStatus.Cancelling,
				},
			},
		}));
	};
	const closeAlertWindow = () => setShowAlert(false);
	return (
		<StyledDiv>
			{showAlert && (
				<>
					{Object.keys(launchProgress).map((operationId, index) => (
						<ResourceAlert
							key={operationId}
							isLast={index === Object.keys(launchProgress).length - 1}
							status={launchProgress[operationId].status}
							operationId={operationId}
							onCancel={handleCancel}
							resource={resources.find(
								({ id }) => id === launchProgress[operationId].resourceId
							)}
						/>
					))}
					{Object.keys(launchProgress).length > 0 && showWait && (
						<WaitAlert
							onClose={closeAlertWindow}
							activeLaunchsCount={Object.keys(launchProgress).length}
						/>
					)}
				</>
			)}
		</StyledDiv>
	);
}
