import {
	PlatformAccessor,
	platformGlobalKey,
	PlatformV1,
} from '@citrite/workspace-ui-platform';
import { container } from 'Workspace/DependencyManagement';

export function prepareWorkspaceUIPlatform(global = window) {
	if (global[platformGlobalKey]) {
		throw new Error('Workspace UI Platform preparation should be called only once');
	}

	const v1: PlatformV1 = {
		globalDependencyResolver: container.resolve,
	};

	const platformAccessor: PlatformAccessor = {
		v1: () => {
			const clone = { ...v1 };
			return Object.freeze(clone);
		},
	};
	Object.freeze(platformAccessor);

	Object.defineProperty(global, platformGlobalKey, {
		value: platformAccessor,
		writable: false,
		configurable: false,
	});
}
