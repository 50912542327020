import { resilientPromise } from '@citrite/workspace-ui-platform';
import { v4 as uuid } from 'uuid';
import {
	BrowserExtensionErrorReason,
	BrowserExtensionMessage,
	BrowserExtensionMessageType,
	BrowserExtensionResponse,
	BrowserExtensionWrapperMessage,
	ExtensionCommunicationAdapter,
} from 'Environment/BrowserExtension/types';

function handleBrowserExtensionResponseForTimeout(
	adapter: ExtensionCommunicationAdapter,
	workload: any,
	type: BrowserExtensionMessageType,
	timeout: number
) {
	let browserExtensionResponseTimeout: ReturnType<typeof setTimeout> = undefined;
	function timeoutPromiseWrapper() {
		return new Promise<BrowserExtensionResponse>((_resolve, reject) => {
			browserExtensionResponseTimeout = setTimeout(() => {
				const error = new Error(`Browser Extension response timeout for ${type}`);
				error.name = BrowserExtensionErrorReason.TimeOut;
				reject(error);
			}, timeout);
		});
	}

	return Promise.race([adapter(workload, type), timeoutPromiseWrapper()]).finally(() => {
		clearTimeout(browserExtensionResponseTimeout);
	});
}

function sendMessageToBrowserExtensionWithTimeOut(
	adapter: ExtensionCommunicationAdapter,
	workload: any,
	type: BrowserExtensionMessageType,
	timeout: number
) {
	if (timeout) {
		return handleBrowserExtensionResponseForTimeout(adapter, workload, type, timeout);
	}

	return adapter(workload, type);
}

export function sendMessageToBrowserExtensionWrapper(
	adapter: ExtensionCommunicationAdapter,
	message: BrowserExtensionWrapperMessage
) {
	const { workload, type, timeout, retries = 0 } = message;
	return resilientPromise(
		() => sendMessageToBrowserExtensionWithTimeOut(adapter, workload, type, timeout),
		{ maxAttempts: retries + 1 }
	);
}

export function createBrowserExtensionMessage(
	workload: any,
	type: BrowserExtensionMessageType
) {
	const message: BrowserExtensionMessage = {
		header: {
			type,
		},
		payload: {
			...workload,
			correlationId: uuid(),
		},
	};
	return message;
}

export function isBrowserExtensionError(error: Error): boolean {
	return (
		error &&
		Object.values(BrowserExtensionErrorReason).includes(
			error.name as BrowserExtensionErrorReason
		)
	);
}
