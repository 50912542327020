import { trackAnalyticsEvent } from 'analytics';
import { environment } from 'Environment';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const terminateApplication = (applicationId: string) => {
	const eventName = {
		id: 'activities',
		type: 'action/miniui/running/resource/terminate',
		payload: { sessionid: applicationId },
	};
	environment.sendEventToNative(eventName);
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerTerminateEvent(false));
};
