import React from 'react';
import { css, OpenCSSProperties, StyleDeclaration, StyleSheet } from 'aphrodite';
import {
	FormContext,
	layout,
	LoadingGrey,
	LoadingPrimary,
	palette,
	typography,
} from '@citrite/web-ui-component';

const fontsize = 14;
const buttonHeight = 40;

const subtlePrimary = {
	button: {
		color: palette.blue800,
		':hover': { backgroundColor: palette.blue100 },
	},
	active: { backgroundColor: palette.blue100 },
};

const focusOutsetThick = {
	borderRadius: '2px',
	boxShadow: `0 0 1px 2px ${palette.black}`,
};

const disabled = {
	opacity: 0.4,
	cursor: 'default',
};

const subtlePrimaryStyles = createButtonStyles(subtlePrimary);
export const MobileSubtleButton = createButton(subtlePrimaryStyles, LoadingPrimary);

type ButtonType = 'submit' | 'button';
interface ButtonProps {
	type?: ButtonType;
	active?: boolean;
	disabled?: boolean;
	children?: React.ReactNode;
	onClick?(e?: React.MouseEvent<HTMLButtonElement>): void;
	buttonRef?(ref: any): void;
	style?: React.CSSProperties;
}

function createButton(
	styles: ButtonStyle,
	LoadingComponent: typeof LoadingPrimary | typeof LoadingGrey
) {
	return function Button(props) {
		const {
			buttonRef,
			children,
			// tslint:disable-next-line:no-shadowed-variable
			disabled,
			active,
			type = 'button',
			...htmlProps
		} = props;
		const formContext = React.useContext(FormContext);
		const activeOverride = active || (type === 'submit' && formContext.active);
		const isDisabled = !!activeOverride || !!disabled;
		return (
			<button
				{...htmlProps}
				type={type}
				className={css(
					styles.buttonWrapper,
					activeOverride ? styles.activeButtonWrapper : disabled && styles.disabled
				)}
				disabled={isDisabled}
				ref={buttonRef}
			>
				<div
					tabIndex={-1}
					className={css(styles.button, activeOverride && styles.active)}
				>
					{activeOverride && (
						<div className={css(styles.loading)}>
							<LoadingComponent size={16} initialHeight="100%" position="absolute" />
						</div>
					)}
					{children}
				</div>
			</button>
		);
	} as React.SFC<ButtonProps>;
}

interface ButtonStyle extends StyleDeclaration {
	buttonWrapper: OpenCSSProperties;
	activeButtonWrapper: OpenCSSProperties;
	button: OpenCSSProperties;
	active: OpenCSSProperties;
	disabled: OpenCSSProperties;
	loading: OpenCSSProperties;
}

function createButtonStyles(overrides: Partial<ButtonStyle>): ButtonStyle {
	// eslint-disable-next-line @citrite/max-inline-aphrodite-length
	return StyleSheet.create({
		buttonWrapper: {
			border: 'none',
			outline: 'none',
			cursor: 'pointer',
			background: 'none',
			padding: 0,
			[':focus > :first-child']: {
				...focusOutsetThick,
				borderRadius: '20px',
			},
			...overrides.buttonWrapper,
		},
		activeButtonWrapper: {
			cursor: 'default',
			pointerEvents: 'none',
		},
		button: {
			outline: 'none',
			borderRadius: '20px',
			fontSize: fontsize,
			fontWeight: typography.weights.medium,
			lineHeight: buttonHeight + 'px',
			minWidth: '96px',
			maxWidth: '100%',
			verticalAlign: 'middle',
			padding: `0 ${layout.mediumSpace}`,
			textAlign: 'center',
			transition: '200ms cubic-bezier(.42,0,.58,1)',
			whiteSpace: 'normal',
			...overrides.button,
		},
		active: {
			position: 'relative',
			color: 'rgba(0, 0, 0, 0)',
			...overrides.active,
		},
		disabled: {
			pointerEvents: 'none',
			...disabled,
			...overrides.disabled,
		},
		loading: {
			position: 'absolute',
			width: '100%',
			marginLeft: `-${layout.mediumSpace}`,
			height: buttonHeight,
			...overrides.loading,
		},
	});
}
