import { StyleSheet } from 'aphrodite';
import { breakpoints } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexWrap: 'wrap',
		border: 'none',
		paddingBottom: 0,
		[breakpoints.onSmallScreen]: {
			paddingLeft: '56px',
		},
	},
	titleContainer: {
		display: 'flex',
		width: '100%',
	},
	icon: {
		marginRight: '10px',
	},
	title: {
		overflow: 'hidden',
		flex: 1,
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		marginRight: '25px',
	},
	favoriteAction: {
		alignItems: 'center',
		display: 'flex',
		height: '36px',
	},
	addToSiriAction: {
		width: '100%',
	},
});
