import React, { useCallback } from 'react';
import { t } from '@citrite/translate';
import { Button, layout, MoreActionIcon, typography } from '@citrite/web-ui-component';
import { AdaptiveDropdown, ContextMenuItem } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { ActionStatus } from 'App/Activity/components/ActionStatus';
import { handleIconClick } from 'App/Activity/components/Utility';
import {
	Session,
	UNKNOWN_STATE,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { useDesktopActions } from 'App/Activity/useDesktopActions';
import { useTransitioningStatus } from 'App/Activity/useTransitioningStatus';
import { useFormFactor } from 'App/Header/useFormFactor';
import { isNativeWidget } from 'Environment/launchResource/device';

const StyledLabel = styled.label`
	font-size: ${layout.mediumSpace};
	font-weight: ${typography.weights.medium};
`;

interface Props {
	session: Session;
}

export const MoreAction = ({ session }: Props) => {
	const { onDesktopAction } = useResourceManagerContext();
	const actions = useDesktopActions({ session, machine: session?.machineData });
	const status = useTransitioningStatus(session);
	const { isSmallFormFactor } = useFormFactor();

	const sessionActionsMenuBuilder = useCallback(
		(): ContextMenuItem[] =>
			actions.map(action => ({
				children: <StyledLabel>{t(`Workspace:activity_manager.${action}`)}</StyledLabel>,
				onClick: () => onDesktopAction(action, session),
			})),
		[actions, onDesktopAction, session]
	);

	return status !== UNKNOWN_STATE ? (
		<ActionStatus status={status} />
	) : (
		<Button.Wrapper onClick={handleIconClick} data-testid="more-action">
			<AdaptiveDropdown
				name={session.applications[0]?.name}
				data={session}
				contextMenuBuilder={sessionActionsMenuBuilder}
				showMobileActionSheet={isSmallFormFactor && !isNativeWidget()}
				icon={() => <MoreActionIcon style={{ padding: 0 }} />}
			/>
		</Button.Wrapper>
	);
};
