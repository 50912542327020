import { StyleSheet } from 'aphrodite';
import { styleUtils } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	loading: {
		padding: '10px',
		display: 'inline-block',
		cursor: 'default',
	},
	icon: {
		cursor: 'pointer',
		padding: '10px',
		display: 'inline-block',
		':focus': {
			outline: 'none',
		},
	},
	iconHover: {
		':hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			borderRadius: '50%',
		},
	},
	input: {
		position: 'absolute',
		left: '-10000px',
		top: 'auto',
		width: 1,
		height: 1,
		overflow: 'hidden',
		':focus + span': styleUtils.focusInset,
	},
	label: {
		width: '100%',
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		userSelect: 'none',
	},
	statusOnly: {
		cursor: 'default',
	},
	disabled: {
		...styleUtils.disabled,
	},
});
