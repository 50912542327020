import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { ChromeAppErrorReason } from 'Environment/ChromeApp/types';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	clientType,
	getPreviousClientType,
	setClientType,
} from 'Environment/getClientType';
import { isCitrixChromeApp } from 'Environment/launchResource/device';

export function logChromeAppError(error: any) {
	if (error.name !== ChromeAppErrorReason.Unavailable) {
		logError(error, {
			tags: {
				feature: 'chromeapp',
				status: error.status,
			},
		});
	}
}

export function isChromeAppCompatible(workspaceConfiguration: WorkspaceConfiguration) {
	return (
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.ChromeAppShield) &&
		isCitrixChromeApp()
	);
}

export function fallbackToPreviousClientType() {
	const previousClientType = getPreviousClientType();
	if (previousClientType) {
		setClientType(previousClientType);
		return true;
	}
	setClientType(clientType.native);
	return false;
}
