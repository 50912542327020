import { useLocation } from 'react-router-dom';
import { checkAppsAvailability } from 'App/Navigation/checkAppsAvailability';
import { checkDesktopsAvailability } from 'App/Navigation/checkDesktopsAvailability';
import { checkHomeAvailability } from 'App/Navigation/checkHomeAvailability';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { IsAvailable, LeftNavRoute } from 'Router/ReactRoute';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

export enum Route {
	Home = 'Home',
	App = 'App',
	Desktop = 'Desktop',
}

const availability = {
	[Route.Home]: checkHomeAvailability,
	[Route.App]: checkAppsAvailability,
	[Route.Desktop]: checkDesktopsAvailability,
};

export const useRouteAvailability = (route: LeftNavRoute | Route): boolean => {
	const location = useLocation();
	const { workspaceConfiguration } = useConfigurationContext();
	const userContext = useUserContext();
	const resources = useLoadableResourceContext();
	const routeAvailabilityProps: IsAvailable<{}> = {
		location,
		workspaceConfiguration,
		userContext,
		resourceContext: resources.value,
	};

	if (route && typeof route !== 'string' && !!route.isAvailable) {
		return route.isAvailable(routeAvailabilityProps);
	} else if (Object.values(Route).includes(route as unknown as Route)) {
		return availability[route as unknown as Route](routeAvailabilityProps);
	} else {
		return false;
	}
};
