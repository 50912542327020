import { getFeatureFlagValue } from '@citrite/feature-flags';
import { breakpoints } from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Swipe } from 'Components/Swipe';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { fromThemeOffset } from 'Workspace/ThemeProvider';

export const StyledLeftNav = styled(Swipe, { shouldForwardProp: isPropValid })<{
	expandMobileMenu: boolean;
}>`
	display: flex;
	z-index: 2;
	flex-shrink: 0;
	flex-grow: 0;
	min-height: ${props =>
		`calc(100vh - ${fromThemeOffset('mastheadOffset')(props)}px  - ${fromThemeOffset(
			'bottomBannerOffset'
		)(props)}px)`};
	transition: 0.5s ease-in-out;
	top: 0;
	@supports (position: sticky) {
		min-height: inherit;
		position: sticky;
	}
	@media print {
		display: none;
	}
	${breakpoints.onMobile} {
		height: ${props =>
			getFeatureFlagValue(FeatureFlag.SimplifiedNav)
				? `calc(100% - ${fromThemeOffset('mastheadOffsetMobile')(
						props
				  )}px - ${fromThemeOffset('bottomBannerOffset')(props)}px)`
				: '100%'};
		position: fixed;
		left: 0;
		margin-left: -100%;
		z-index: 2;
		${props => (props.expandMobileMenu ? 'margin-left: 0' : '')};
		${props =>
			getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
				top: fromThemeOffset('mastheadOffsetMobile')(props) + 'px',
			}}
	}
`;

export const StyledContentOverlay = styled.div`
	${breakpoints.onMobile} {
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		z-index: -1;
	}
`;
