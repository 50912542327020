import { DependencyFromToken, PlatformDependencySet } from './dependencies';

/** @internal */
export const platformGlobalKey = '__WORKSPACE_UI_PLATFORM__';

/** @internal */
export interface PlatformV1 {
	globalDependencyResolver: <T extends PlatformDependencySet>(
		token: T | string
	) => DependencyFromToken<T>;
}

/** @internal */
export interface PlatformAccessor {
	v1(): PlatformV1;
}

const getPlatformAccessor = (): PlatformAccessor => (window as any)[platformGlobalKey];

export const getPlatform = () => getPlatformAccessor().v1();
