import { environment } from 'Environment';
import { ResourceContext } from 'Workspace/ResourceProvider';
import {
	InstalledResource,
	MAMPropertyKeys,
	Resource,
} from 'Workspace/ResourceProvider/resourceTypes';

export interface Params {
	resource: Resource;
	updateSession: ResourceContext['updateSession'];
}

export function installResource(options: Params) {
	const { resource, updateSession } = options;
	return environment.installApp(resource).then<void>(status => {
		if (status === 'failed') {
			return Promise.reject(status);
		} else if (status === 'success') {
			const newAppInfo: InstalledResource = {
				appId: resource.id,
			};

			const resourceVersion =
				resource.properties &&
				resource.properties.find(res => res.name === MAMPropertyKeys.MamAppVersion);

			const resourcePackageId =
				resource.properties &&
				resource.properties.find(res => res.name === MAMPropertyKeys.MamPackageID);

			if (resourceVersion) {
				newAppInfo.version = resourceVersion.values[0];
			}

			if (resourcePackageId) {
				newAppInfo.packageId = resourcePackageId.values[0];
			}
			updateSession(state => ({
				installedResources: [...state.installedResources, { ...newAppInfo }],
			}));
		}

		return Promise.resolve();
	});
}
