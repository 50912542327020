import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isBrowserExtensionCompatible } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';

export const shouldDetectClientBeforeAuth = (
	workspaceConfiguration: WorkspaceConfiguration
) => {
	const { showAfterLogin, html5 } = workspaceConfiguration?.pluginAssistant;
	const html5EnabledAlways = html5.enabled === 'Always';
	const detectionBeforeAuthFF = hasFeatureCanary(
		workspaceConfiguration,
		FeatureFlag.DetectClientBeforeAuthentication
	);

	return (
		IS_ON_PREM &&
		detectionBeforeAuthFF &&
		!JSON.parse(showAfterLogin) &&
		!html5EnabledAlways &&
		!isBrowserExtensionCompatible(workspaceConfiguration)
	);
};
