import { UserAccountData } from '../workspaceConfiguration';
import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

export interface WorkspaceUser {
	userDetails: UserDetails;
	userPreferences: UserPreferences;
	isIWSEnabled: boolean;
	isDaasVisionEnabled: boolean;
	isActivityManagerEnabled: boolean;
}

export type UserDetails = Pick<UserAccountData, 'userId' | 'userDisplayName'>;

export interface UserPreferences {
	dataFormats: DataFormatPreferences;
	profilePicture: ProfilePictures;
}

export interface DataFormatPreferences {
	dateFormat?: string;
	timeFormat?: string;
	decimalSeparator?: string;
	thousandSeparator?: string;
}

export interface ProfilePicturesByType {
	Default: ProfilePicture;
}

export interface ProfilePicture {
	url: string;
	source: ProfilePictureSource;
}

export interface ProfilePicturePayload {
	dataUri: string;
}

export enum ProfilePictureSource {
	Manual,
}

export interface ProfilePictures {
	items: ProfilePicturesByType;
}

export interface ProfilePictureLimitations {
	limitations: ProfilePictureLimitationsByEndpoint;
}

export interface ProfilePictureLimitationsByEndpoint {
	POST: ProfilePicturePostLimitations;
}

export interface ProfilePicturePostLimitations {
	MaxImageSizeBytes: number;
	ImageMimeAllowList: [];
	ImageAllowList: [];
	MaxImageWidth: number;
	MaxImageHeight: number;
}

/**
 * @category Platform Dependency
 */
export interface WorkspaceUserAccessor {
	get(): WorkspaceUser;
}

/**
 * @category Platform Dependency
 */
export const workspaceUser: WorkspaceUserAccessor = {
	get: () => resolveFromGlobal(PlatformDependencies.WorkspaceUser).get(),
};
