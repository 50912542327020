import { createCapability } from '../capability';

export interface Action {
	id: string;
	title: string;
	iconUrl: string;
	serviceName: string;
	serviceIconUrl: string;
	homePageUrl: string;
	integrationId?: string;
}

export interface OnActionClickOptions {
	allowClose?: boolean;
	onActionOverlayClose?: () => void;
}

/**
 * Adds a new data source for Workspace action apps.
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.actionsProvider}
 *
 * @category Integration Capability
 */
export interface ActionsCapability {
	getActionApps(): Promise<Action[]>;
	onActionClick(action: Action, options?: OnActionClickOptions): void;
}

export const ActionsCapabilityToken = createCapability<ActionsCapability>(
	'wsui_actions_capability'
);
