import * as React from 'react';
import { t } from '@citrite/translate';
import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { logError } from 'remoteLogging';
import { WidgetCatalogItem } from 'App/Widgets/useWidgetCatalog';
import { ErrorLevel } from 'Loggers/LoggingProvider';

interface Props {
	widget: WidgetCatalogItem;
}

interface State {
	hasError?: boolean;
}

export class WidgetWrapper extends React.Component<Props, State> {
	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	public state: State = {};

	public componentDidCatch(error: unknown, errorInfo: object) {
		const { widget } = this.props;
		const usefulWidgetMeta = {
			id: widget.id,
			title: widget.title(),
			source: widget.source,
		};

		logError(error, {
			additionalContext: {
				errorInfo,
				widgetMeta: usefulWidgetMeta,
				level: ErrorLevel.CRITICAL,
			},
		});
	}

	public render() {
		return (
			<StyledWidgetWrapper data-testid={this.props['data-testid']}>
				{this.state.hasError
					? t('Workspace:widget_generic_error', { widgetName: this.props.widget.title() })
					: this.props.children}
			</StyledWidgetWrapper>
		);
	}
}

const StyledWidgetWrapper = styled.div`
	margin-bottom: ${layout.largeSpace};
`;
