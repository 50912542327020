import {
	Performance as PerformanceMethods,
	PlatformDependencies,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { first } from 'lodash';
import { v4 } from 'uuid';
import { EventType, PerformanceMarkEvent } from 'Components/EventBus';
import { environment } from 'Environment';
import {
	monitoring,
	MonitoringData,
	MoreInfo as MonitoringInfo,
} from 'MonitoringAnalytics';
import { container } from 'Workspace/DependencyManagement';

export enum PerformanceEvents {
	AllAppsPage_StartRender = 'AllAppsPage_StartRender',
	AllAppsPage_Rendered = 'AllAppsPage_Rendered',
	AllDesktopsPage_StartRender = 'AllDesktopsPage_StartRender',
	AllDesktopsPage_Rendered = 'AllDesktopsPage_Rendered',
	EnhancedCache_StartFeedRequest = 'EnhancedCache_StartFeedRequest',
	EnhancedCache_FinishFeedRequest = 'EnhancedCache_FinishFeedRequest',
	FilesPersonalFoldersPage_StartRender = 'FilesPersonalFoldersPage_StartRender',
	FilesPersonalFoldersPage_Rendered = 'FilesPersonalFoldersPage_Rendered',
	FilesSharedFoldersPage_StartRender = 'FilesSharedFoldersPage_StartRender',
	FilesSharedFoldersPage_Rendered = 'FilesSharedFoldersPage_Rendered',
	HomePage_OnlineReady = 'HomePage_OnlineReady',
	HomePage_Rendered = 'HomePage_Rendered',
	HomePage_SomethingAvailable = 'HomePage_SomethingAvailable',
	HomePage_StartRender = 'HomePage_StartRender',
	IWS_FRS_Start = 'IWS_FRS_Start',
	IWS_FRS_Finish = 'IWS_FRS_Finish',
	EntryHtml_Start = 'EntryHtml_Start',
	LoadingPage_WSUI_Start = 'LoadingPage_WSUI_Start',
	AuthStart_SelfHosted = 'AuthStart_SelfHosted',
	WSUI_AuthComplete = 'WSUI_AuthComplete',
	FeedCardActionExecutor_Rendered = 'FeedCardActionExecutor_Rendered',
	FeedCardActionExecutor_Registered = 'FeedCardActionExecutor_Registered',
	// per-area events
	resourcesWidget = 'HomePage_ResourcesRendered',
	filesWidget = 'HomePage_FilesRendered',
	feed = 'HomePage_FeedRendered',
	leftNav = 'HomePage_LeftNavRendered',
	masthead = 'HomePage_MastheadRendered',
	// mobile-only events
	MobileAppsPage_Rendered = 'MobileAppsPage_Rendered',
	MobileAppsLink_Click = 'MobileAppsLink_Click',
	MobileDesktopsPage_Rendered = 'MobileDesktopsPage_Rendered',
	MobileDesktopsLink_Click = 'MobileDesktopsLink_Click',
	MobileFilesPage_Rendered = 'MobileFilesPage_Rendered',
	MobileFilesLink_Click = 'MobileFilesLink_Click',
	// complete application load
	CompleteApplicationLoad = 'CompleteApplicationLoad',
	WSUILoad = 'WSUILoad',
	NativeLoad = 'NativeLoad',
	WSUIUserDetailsFetch = 'WSUIUserDetailsFetch',
	WSUIInit = 'WSUIInit',
	WSUIEnvironmentSetup = 'WSUIEnvironmentSetup',
	WSUIRendered = 'WSUIRendered',
	// script load events
	WSUIWorkspaceScriptLoad = 'WSUIWorkspaceScriptLoad',
	WSUIWorkspaceScriptResponseStatus = 'WSUIWorkspaceScriptResponseStatus',
}
enum InitiatorTypes {
	Fetch = 'fetch',
	XMLHttpRequest = 'xmlhttprequest',
	Script = 'script',
	Link = 'link',
	Img = 'img',
	Css = 'css',
}
interface ResourceTimingConfig {
	initiatorType: InitiatorTypes;
	resourceName: string;
}

interface ResourcePerformanceTiming {
	duration: number;
	responseStatus: number;
}

class Performance implements PerformanceMethods {
	private measuredHomePageRendered = false;
	private measuredSomethingAvailable = false;
	private measuredStartRender = false;
	private navigationAreas: string[] = [
		PerformanceEvents.leftNav,
		PerformanceEvents.masthead,
	];
	private dashboardAreasLoaded = [...this.navigationAreas].reduce(
		(obj, current) => ({ ...obj, [current]: false }),
		{}
	);
	/**
	 * Map of measured name and epoch time of recording measure
	 */
	private performanceEventMeasured = new Map<string, number>();
	private dashboardWidgetsRegistered = false;
	private performanceSpanMeasured: string[] = [];
	private applicationLoadMarks: Map<string, number> = new Map();
	public events = PerformanceEvents;

	public constructor() {
		_performance = this;
	}

	public loggedIn() {
		monitoring.setSessionAttribute('requiredAuthentication', true);
		this.mark(this.events.WSUI_AuthComplete);
		this.measure(
			this.events.WSUI_AuthComplete,
			this.events.EntryHtml_Start,
			this.events.WSUI_AuthComplete
		);
		this.measure(
			'Complete_Authentication_Self_Hosted',
			this.events.AuthStart_SelfHosted,
			this.events.WSUI_AuthComplete
		);
		this.mark(this.events.LoadingPage_WSUI_Start);
	}

	public homePageReady() {
		this.mark(this.events.HomePage_OnlineReady);
		this.measure(
			this.events.HomePage_OnlineReady,
			this.events.LoadingPage_WSUI_Start,
			this.events.HomePage_OnlineReady
		);
	}

	public registerDashboardWidget(markName: string) {
		if (!this.dashboardAreasLoaded[markName]) {
			this.dashboardAreasLoaded[markName] = false;
		}
	}

	public dashboardWidgetRegistrationDone() {
		this.dashboardWidgetsRegistered = true;
	}

	private dashboardMark(area: string) {
		if (!this.measuredStartRender && this.navigationAreas.includes(area)) {
			// left nav or masthead is available
			this.measuredStartRender = true;
			this.nativeMark(this.events.HomePage_StartRender);
		}

		if (!this.measuredSomethingAvailable && !this.navigationAreas.includes(area)) {
			// any widget is available
			this.measuredSomethingAvailable = true;
			this.nativeMark(this.events.HomePage_SomethingAvailable);
		}

		this.nativeMark(area);
		this.measure(area, this.events.HomePage_OnlineReady, area);

		this.dashboardAreasLoaded[area] = true;
		if (
			!this.measuredHomePageRendered &&
			this.dashboardWidgetsRegistered &&
			Object.keys(this.dashboardAreasLoaded).every(k => this.dashboardAreasLoaded[k])
		) {
			this.measuredHomePageRendered = true;
			this.nativeMark(this.events.HomePage_Rendered);
			this.measure(
				this.events.HomePage_Rendered,
				this.events.LoadingPage_WSUI_Start,
				this.events.HomePage_Rendered
			);
			// a convenience measure for page reload since 'HomePage_Rendered' is measured
			// from 'LoadingPage_WSUI_Start' which is marked only on login
			this.measure(
				'HomePage_OnlineReadyToRendered',
				this.events.HomePage_OnlineReady,
				this.events.HomePage_Rendered
			);
		}
	}

	public mark(markName: string) {
		try {
			if (!this.performanceEventMeasured.has(markName)) {
				this.performanceEventMeasured.set(markName, Date.now());
				if (this.dashboardAreasLoaded[markName] !== undefined) {
					this.dashboardMark(markName);
					return;
				}
				this.nativeMark(markName);
			}
		} catch {}
	}

	/**
	 * Capture the duration of a span between two marks.
	 *
	 * @param spanName
	 * @param startMark
	 * @param endMark if omitted the current time is used
	 */
	public measure(spanName: string, startMark: string, endMark?: string) {
		try {
			// EntryHtml_Start is special because to set it we cannot use the module
			const isEntryStartMark = startMark === this.events.EntryHtml_Start;
			const shouldPerformMeasure =
				!this.performanceSpanMeasured.includes(spanName) &&
				(this.performanceEventMeasured.has(startMark) || isEntryStartMark);
			if (!shouldPerformMeasure) {
				return;
			}

			window.performance.measure(spanName, startMark, endMark);

			const startTime = isEntryStartMark
				? this.getEntryHTMLEpochTimestamp()
				: this.performanceEventMeasured.get(startMark);
			const endTime = this.performanceEventMeasured.get(endMark) || Date.now();

			monitoring.addMeasure(spanName, startTime, endTime);

			this.performanceSpanMeasured.push(spanName);
		} catch {}
	}

	private nativeMark(markName: string) {
		if (window.performance && window.performance.mark) {
			window.performance.mark(markName);
		}
		const currentTime = new Date().getTime();
		environment.writeTraceMessage(markName, currentTime, 0, -2);
		const event: PerformanceMarkEvent = {
			id: v4(),
			type: EventType.PERFORMANCE_MARK,
			payload: { name: markName, time: currentTime },
		};
		environment.sendEventToNative(event);
	}

	private getEntryHTMLEpochTimestamp() {
		const epochTimeOffest = Date.now() - window.performance.now();
		const [entry] = window.performance.getEntriesByName(this.events.EntryHtml_Start);
		return entry ? epochTimeOffest + entry.startTime : epochTimeOffest;
	}

	public markApplicationLoad(mark: string) {
		if (this.applicationLoadMarks.has(mark) || !window.performance.now) {
			return;
		}
		this.applicationLoadMarks.set(mark, window.performance.now());
		if (mark === PerformanceEvents.WSUIRendered) {
			this.captureApplicationLoad();
		}
	}

	private getWSUILoadStats() {
		const wsuiInit = this.applicationLoadMarks.get(PerformanceEvents.WSUIInit);
		const wsuiEnvironmentSetup =
			this.applicationLoadMarks.get(PerformanceEvents.WSUIEnvironmentSetup) -
			this.applicationLoadMarks.get(PerformanceEvents.WSUIInit);
		const wsuiRendered =
			this.applicationLoadMarks.get(PerformanceEvents.WSUIRendered) -
			this.applicationLoadMarks.get(PerformanceEvents.WSUIEnvironmentSetup);
		const wsuiLoad = this.applicationLoadMarks.get(PerformanceEvents.WSUIRendered);

		return [wsuiInit, wsuiEnvironmentSetup, wsuiRendered, wsuiLoad];
	}

	private async captureApplicationLoad() {
		const configuration = container
			.resolve(PlatformDependencies.WorkspaceConfiguration)
			.get();
		const nativeLoadMonitoringStats =
			(environment.nativeCapabilities?.loadMonitoringStats as MonitoringInfo) || {};
		const [wsuiInit, wsuiEnvironmentSetup, wsuiRendered, wsuiLoad] =
			this.getWSUILoadStats();
		const nativeLoad =
			Number(nativeLoadMonitoringStats[PerformanceEvents.NativeLoad]) || 0;

		const applicationLoadStart = 0;
		const applicationLoadEnd = wsuiLoad + nativeLoad;

		const userDetailsFetch = this.getResourceLoadTime({
			initiatorType: InitiatorTypes.XMLHttpRequest,
			resourceName: this.getRequestURIName(configuration, 'userDetails'),
		});

		const workspaceScriptLoad = this.getResourceLoadTime({
			initiatorType: InitiatorTypes.Script,
			resourceName: 'workspace.',
		});

		const moreInfo: Record<string, string> = {
			[PerformanceEvents.WSUIInit]: `${wsuiInit}ms`,
			[PerformanceEvents.WSUIEnvironmentSetup]: `${wsuiEnvironmentSetup}ms`,
			[PerformanceEvents.WSUIRendered]: `${wsuiRendered}ms`,
			[PerformanceEvents.WSUILoad]: `${wsuiLoad}ms`,
			...nativeLoadMonitoringStats,
		};

		if (userDetailsFetch !== MonitoringData.NOT_AVAILABLE) {
			const userDetailsTiming = userDetailsFetch as ResourcePerformanceTiming;
			moreInfo[
				PerformanceEvents.WSUIUserDetailsFetch
			] = `${userDetailsTiming.duration}ms`;
		}

		if (workspaceScriptLoad !== MonitoringData.NOT_AVAILABLE) {
			const workspaceScriptTiming = workspaceScriptLoad as ResourcePerformanceTiming;
			moreInfo[
				PerformanceEvents.WSUIWorkspaceScriptLoad
			] = `${workspaceScriptTiming.duration}ms`;
			moreInfo[
				PerformanceEvents.WSUIWorkspaceScriptResponseStatus
			] = `${workspaceScriptTiming.responseStatus}`;
		}

		monitoring.addMeasure(
			PerformanceEvents.CompleteApplicationLoad,
			applicationLoadStart,
			applicationLoadEnd,
			moreInfo
		);
	}

	private getRequestURIName = (
		configuration: WorkspaceConfiguration,
		serviceKey: string
	) => {
		const uriMap: { [key: string]: string } = {
			userDetails: configuration.authManager.getUserDetailsURL,
		};

		return uriMap[serviceKey];
	};

	private getResourceLoadTime = ({
		initiatorType,
		resourceName,
	}: ResourceTimingConfig):
		| ResourcePerformanceTiming
		| typeof MonitoringData.NOT_AVAILABLE => {
		const fetchEvent =
			window.performance?.getEntries &&
			first(
				window.performance
					.getEntries()
					.filter(
						entry =>
							entry instanceof PerformanceResourceTiming &&
							entry.initiatorType === initiatorType &&
							entry.name.includes(resourceName)
					)
			);

		if (fetchEvent) {
			return {
				duration: fetchEvent.duration,
				responseStatus:
					'responseStatus' in fetchEvent ? (fetchEvent as any).responseStatus : 0,
			};
		}
		return MonitoringData.NOT_AVAILABLE;
	};
}

let _performance: Performance;

export const performance = _performance || new Performance();
