import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { editProfileRoute } from 'App/Settings/EditProfile';
import { securityAndSignInRoute } from 'App/Settings/SecurityAndSignIn';
import { toolsRoute } from 'App/Settings/Tools';
import { advancedRoute } from 'App/Settings/Workspace/Advanced';
import { regionalSettingsRoute } from 'App/Settings/Workspace/RegionalSettings';
import { isNativeClient } from 'Environment/launchResource/device';
import { LeftNavRoute } from 'Router/ReactRoute';

const childRoutesCloud: LeftNavRoute[] = [
	editProfileRoute,
	regionalSettingsRoute,
	securityAndSignInRoute,
	toolsRoute,
	advancedRoute,
];

const childRoutesOnPrem: LeftNavRoute[] = [advancedRoute];

export const getSupportedChildRoutes = (): LeftNavRoute[] => {
	return IS_ON_PREM ? childRoutesOnPrem : childRoutesCloud;
};

export const shouldShowAccountSettings = (
	workspaceConfiguration: WorkspaceConfiguration
) => {
	return !(
		IS_ON_PREM &&
		(isNativeClient() ||
			workspaceConfiguration.pluginAssistant.html5.enabled === 'Always')
	);
};
