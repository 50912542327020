import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, layout, Link } from '@citrite/web-ui-component';
import {
	FallbackConfiguration,
	FallbackConfigurationAddressAndTitle,
	hasFeatureCanary,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { logInfo } from 'remoteLogging';
import { FullScreenError } from 'Components/FullScreenError';
import { getEmbeddedConfiguration } from 'Configuration/Provider';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	StyledErrorTitle,
	StyledExtraMessaging,
	StyledPage,
	StyledStoreFrontFallbackContainer,
	StyledStoreTitle,
} from 'ErrorPage/StoreFrontFallback.styled';

export function isStoreFrontFallbackEnabled(configuration?: WorkspaceConfiguration) {
	configuration = configuration || getEmbeddedConfiguration();
	return (
		!environment.isNative &&
		hasFeatureCanary(configuration, FeatureFlag.UseStoreFrontFallbackUrl) &&
		!!configuration.userInterface.fallbackConfiguration?.address
	);
}

export function normalizeStoreFrontFallbackStores(
	fallbackConfiguration: FallbackConfiguration
) {
	const { altStore, title, address } = fallbackConfiguration;
	const validAltStores = altStore?.filter(store => !!store.address) || [];
	const normalizedStoreFrontFallbackStores: FallbackConfigurationAddressAndTitle[] = [
		{ title, address },
		...validAltStores,
	];

	return normalizedStoreFrontFallbackStores;
}

export enum ErrorHints {
	AuthenticationFailure = 'AuthenticationFailure',
	UnknownAuthFailure = 'UnknownAuthFailure',
	CvadLaunchFailure = 'CvadLaunchFailure',
}

interface Props {
	hint?: ErrorHints;
}

interface UnknownAuthFailureProps {
	normalizedStoreFrontFallbackStores: FallbackConfigurationAddressAndTitle[];
	children: React.ReactNode;
}

const UnknownAuthFailure = (props: UnknownAuthFailureProps) => {
	return (
		<StyledPage>
			<StyledErrorTitle>
				{t('Workspace:unexpected_error.unknown_auth_error_title')}
			</StyledErrorTitle>
			<div style={{ marginTop: layout.largeSpace }}>
				<Button.Primary
					onClick={() => {
						logStoreFrontInfo(ErrorHints.UnknownAuthFailure);
						location.assign('/');
					}}
				>
					{t('Workspace:unexpected_error.retry_button')}
				</Button.Primary>
			</div>
			<StyledExtraMessaging>
				<p>{t('Workspace:unexpected_error.unknown_auth_error_text')}</p>
				{props.normalizedStoreFrontFallbackStores.map((fallbackStore, i) => (
					<StyledStoreFrontFallbackContainer key={fallbackStore.address}>
						<StyledStoreTitle>{fallbackStore.title || 'StoreFront'}</StyledStoreTitle>
						<Link.Primary
							href={fallbackStore.address}
							target="_blank"
							data-testid={`storeFrontFallbackLink-${i}`}
							rel="noopener"
							onClick={() => logStoreFrontInfo(ErrorHints.UnknownAuthFailure)}
						>
							{fallbackStore.address}
						</Link.Primary>
					</StyledStoreFrontFallbackContainer>
				))}
			</StyledExtraMessaging>
		</StyledPage>
	);
};

export const logStoreFrontInfo = (hint: ErrorHints) => {
	logInfo(`${hint}: Navigated to StoreFront Fallback address`);
};

export const StoreFrontFallbackErrorPage: React.FC<Props> = ({ hint, children }) => {
	const { fallbackConfiguration } = getEmbeddedConfiguration().userInterface;
	const normalizedStoreFrontFallbackStores =
		normalizeStoreFrontFallbackStores(fallbackConfiguration);

	if (hint === ErrorHints.UnknownAuthFailure) {
		return (
			<UnknownAuthFailure
				normalizedStoreFrontFallbackStores={normalizedStoreFrontFallbackStores}
				children={children}
			/>
		);
	}
	const errorText =
		hint === ErrorHints.AuthenticationFailure
			? {
					errorTitle: t('Workspace:storefront_fallback.authentication_error'),
					errorMessage: t('Workspace:storefront_fallback.login_attempt_text'),
			  }
			: {
					errorTitle: t('Workspace:unexpected_error.generic_title'),
					errorMessage: t('Workspace:storefront_fallback.text'),
			  };

	return (
		<FullScreenError
			title={errorText.errorTitle}
			subtitle={t('Workspace:unexpected_error.helper_text')}
		>
			<p>{errorText.errorMessage}</p>
			{normalizedStoreFrontFallbackStores.map((fallbackStore, i) => (
				<StyledStoreFrontFallbackContainer key={fallbackStore.address}>
					<StyledStoreTitle>{fallbackStore.title || 'StoreFront'}</StyledStoreTitle>
					<Link.Primary
						href={fallbackStore.address}
						target="_blank"
						data-testid={`storeFrontFallbackLink-${i}`}
						rel="noopener"
						onClick={() => logStoreFrontInfo(hint)}
					>
						{fallbackStore.address}
					</Link.Primary>
				</StyledStoreFrontFallbackContainer>
			))}
			{children}
		</FullScreenError>
	);
};
