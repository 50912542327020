import { useEffect, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { debounce } from 'lodash';
import { isSimpleViewInDaasVision } from 'App/Navigation/utils';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

export interface DaasUIMetadata {
	branding: {
		headerBackgroundColor: string;
		headerTextColor: string;
		accentColor: string;
		palette: string;
		headerLogoURL?: string;
		loginLogoURL?: string;
	};
	layout: {
		daasUIEnabled: boolean;
		activityManagerEnabled: boolean;
		simpleViewEnabled: boolean;
		homeTabEnabled: boolean;
	};
	username?: string;
}

export function useSendDaasUIMetadataToNative() {
	const theme = useTheme();
	const resourceContext = useLoadableResourceContext();
	const { workspaceConfiguration } = useConfigurationContext();
	const userContext = useUserContext();
	const isActivityManagerEnabled = userContext.isActivityManagerEnabled;

	const sendDaasUIMetadata = useMemo(
		() =>
			debounce(metadata => {
				environment.setDaasUIMetadata(metadata);
			}, 20000),
		[]
	);

	useEffect(() => {
		if (!environment.nativeCapabilities?.functions?.includes('setDaasUIMetadata')) {
			return;
		}

		const resources = resourceContext?.value?.resources || [];
		const isHomeTabEnabled =
			workspaceConfiguration?.userInterface?.enableWorkspaceHome === 'true';
		const styles = theme.branding.styles;
		const username = userContext.userDetails?.userDisplayName;
		const headerLogoURL = theme.branding.links.HeaderLogoUrl;
		const loginLogoURL = theme.branding.links.LoginLogoUrl;
		const data: DaasUIMetadata = {
			branding: {
				headerBackgroundColor: styles.HeaderBackgroundColor,
				headerTextColor: styles.HeaderTextColor,
				accentColor: styles.AccentColor,
				palette: theme.branding.paletteName,
				...(headerLogoURL && { headerLogoURL }),
				...(loginLogoURL && { loginLogoURL }),
			},
			layout: {
				daasUIEnabled: userContext.isDaasVisionEnabled === true,
				activityManagerEnabled: isActivityManagerEnabled,
				simpleViewEnabled: isSimpleViewInDaasVision(resources, userContext),
				homeTabEnabled: isHomeTabEnabled,
			},
			...(username && { username }),
		};

		sendDaasUIMetadata(data);
	}, [
		isActivityManagerEnabled,
		resourceContext?.value?.resources,
		sendDaasUIMetadata,
		theme.branding.links.HeaderLogoUrl,
		theme.branding.links.LoginLogoUrl,
		theme.branding.paletteName,
		theme.branding.styles,
		userContext,
		userContext.isDaasVisionEnabled,
		workspaceConfiguration?.userInterface?.enableWorkspaceHome,
	]);
}
