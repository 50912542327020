import { Nullable } from '@citrite/utility-types';
import {
	CacheBucketSettings,
	CacheFactory,
	PlatformDependencies,
	ScopedCacheFactory,
} from '@citrite/workspace-ui-platform';
import { InternalPlatformDependencies } from 'IntegrationDependencies/InternalDependencies';
import { buildIntegrationCacheBucketId, createCacheFactory } from 'Workspace/Cache';
import { PlatformContainer } from 'Workspace/DependencyManagement';
export function scopedCacheFactory(
	container: PlatformContainer
): Nullable<ScopedCacheFactory> {
	const integration = container.resolve(InternalPlatformDependencies.ScopedIntegration);
	if (!integration.cacheVersion) {
		console.warn('You are attempting to create a cache bucket without a cache version.');
		return null;
	}

	const bucketId = buildIntegrationCacheBucketId(integration);

	let factory: CacheFactory;
	const getFactory = () => {
		factory =
			factory ??
			createCacheFactory(
				container.resolve(PlatformDependencies.WorkspaceConfiguration).get(),
				container.resolve(InternalPlatformDependencies.UserDetails).get
			);

		return factory;
	};

	// The cache factory is retrieved lazily to ensure that its dependencies can be resolved from the container.
	return {
		createEncryptedCacheBucket: (settings?: CacheBucketSettings) =>
			getFactory().createEncryptedCacheBucket(bucketId, settings),
		createUnencryptedCacheBucket: (settings?: CacheBucketSettings) =>
			getFactory().createUnencryptedCacheBucket(bucketId, settings),
	};
}
