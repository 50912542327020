import * as React from 'react';
import { t } from '@citrite/translate';
import { GetItemPropsOptions } from 'downshift';
import { ResourceResults } from 'Components/ItemSearch/ResourceResults';
import { Result } from 'Components/ItemSearch/Result';
import { searchResourcesByNameAndDescription } from 'Components/ItemSearch/searchResourcesByNameAndDescription';
import {
	WithWorkspaceConfiguration,
	withWorkspaceConfiguration,
} from 'Configuration/withWorkspaceConfiguration';
import { launchResource } from 'Environment/launchResource';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export interface Props extends WithResourceContextProps, WithWorkspaceConfiguration {
	getItemProps(props: GetItemPropsOptions<any>): React.HTMLAttributes<HTMLLIElement>;
	highlightedIndex: number;
	input: string;
	resources: (Result & Resource)[];
	setResources(resources: (Result & Resource)[]): void;
	shouldSearchApps: boolean;
	shouldSearchDesktops: boolean;
}

class _ResourceSearch extends React.Component<Props> {
	public render() {
		const apps = this.props.resources.filter(r => !r.isdesktop);
		const desktops = this.props.resources.filter(r => r.isdesktop);
		return (
			<>
				{this.props.shouldSearchApps &&
					this.props.resourceContext.resources.some(r => !r.isdesktop) && (
						<ResourceResults
							header={t('Workspace:applications')}
							notFound={t('javascript:search.no_applications_found')}
							resources={apps}
							getItemProps={this.props.getItemProps}
							highlightedIndex={this.props.highlightedIndex}
							isInProgress={this.props.resourceContext.isInProgress}
							baseIndex={0}
						/>
					)}
				{this.props.shouldSearchDesktops &&
					this.props.resourceContext.resources.some(r => r.isdesktop) && (
						<ResourceResults
							header={t('Workspace:desktops')}
							notFound={t('javascript:search.no_desktops_found')}
							resources={desktops}
							getItemProps={this.props.getItemProps}
							highlightedIndex={this.props.highlightedIndex}
							isInProgress={this.props.resourceContext.isInProgress}
							baseIndex={apps.length}
						/>
					)}
			</>
		);
	}

	public componentDidMount() {
		this.filterResources(this.props.input);
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.input !== prevProps.input) {
			this.filterResources(this.props.input);
		}
	}

	private filterResources(input: string) {
		const resources = searchResourcesByNameAndDescription(
			this.props.resourceContext.resources,
			input
		);
		const resourcesWithClick = resources.map(resource => ({
			...resource,
			onClick: () => this.launchResource(resource),
		}));
		this.props.setResources(resourcesWithClick);
	}

	private launchResource = (resource: Resource) => {
		launchResource({
			resource,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
		});
	};
}

export const ResourceSearch = withWorkspaceConfiguration(
	withResourceContext(_ResourceSearch)
);
