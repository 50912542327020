import {
	getFromSessionStorage,
	removeFromSessionStorage,
	setInSessionStorage,
} from 'javascript/sf/Storage';

const activeKey = 'INTERACTIVE_ACTIVE_PERFORMANCE_SESSION';

function getEventNamesKey() {
	const experimentName = getFromSessionStorage<string>(activeKey);
	return `PerformanceAnalysis.${experimentName}.watchingPerformanceNames`;
}

function getPeformanceEventNames() {
	const key = getEventNamesKey();
	return getFromSessionStorage<string[]>(key) || [];
}

function getRecordedStatsKey() {
	const experimentName = getFromSessionStorage<string>(activeKey);
	return `PerformanceAnalysis.${experimentName}.recordedStats`;
}

function getRecordedStats() {
	const key = getRecordedStatsKey();
	return getFromSessionStorage<PerformanceEntryList>(key) || [];
}

function hasActiveSession() {
	return !!getFromSessionStorage(activeKey);
}

function hasStats() {
	return getRecordedStats().length > 0;
}

function aggregateAllStats() {
	const eventNames = getPeformanceEventNames();
	const stats = eventNames.map(markName => {
		return [
			...performance.getEntriesByName(markName, 'mark'),
			...performance.getEntriesByName(markName, 'measure'),
		];
	});
	const flattenedStats = stats.reduce((flattened, list) => {
		return flattened.concat(list);
	}, []);
	return getRecordedStats().concat(flattenedStats);
}

export function continuePerformanceSampling() {
	if (getFromSessionStorage(activeKey)) {
		const recordedStats = getRecordedStats();
		// eslint-disable-next-line no-console
		console.log(
			`🧪 Gathered ${
				recordedStats.length
			} stats in performance data sampling for '${getFromSessionStorage<string>(
				activeKey
			)}'`
		);
		window.addEventListener('unload', () => {
			const stats = aggregateAllStats();
			setInSessionStorage(getRecordedStatsKey(), stats);
		});
	}
}

export function startSamplingPerformanceData(experimentName: string) {
	if (hasActiveSession()) {
		throw new Error('Performance session already started.');
	}

	setInSessionStorage(activeKey, experimentName);

	window.addEventListener('unload', () => {
		if (!getPeformanceEventNames().length) {
			removeFromSessionStorage(activeKey);
		}
		const stats = aggregateAllStats();
		setInSessionStorage(getRecordedStatsKey(), stats);
	});

	return (
		'🧪 Run interactive.samplePerformanceEvents(<name>, [... <name>]) ' +
		'to aggregate performance data across page loads for use in ' +
		'statistical analysis. Supports both mark and measure events.'
	);
}

export function stopSamplingPerformanceData() {
	if (!hasActiveSession()) {
		throw new Error(
			'✋ No active session in progress. Run interactive.startSamplingPerformanceData(<experimentName>) to start a new session.'
		);
	}

	const stats = aggregateAllStats();
	// eslint-disable-next-line no-console
	console.log(
		`🧪 Gathered ${
			stats.length
		} stats in performance data collection for '${getFromSessionStorage<string>(
			activeKey
		)}'`
	);

	const grouped = stats.reduce((grouping, stat) => {
		if (!grouping[stat.name]) {
			grouping[stat.name] = [];
		}
		grouping[stat.name].push(stat);
		return grouping;
	}, {});

	Object.keys(grouped).forEach(groupName => {
		// eslint-disable-next-line no-console
		console.log(
			`%c🧪 ${getFromSessionStorage<string>(activeKey)} - ${groupName}`,
			'color: #0045DB; font-weight: bold; font-size:14px'
		);
		// eslint-disable-next-line no-console
		console.table(grouped[groupName]);
		// eslint-disable-next-line no-console
		console.log(JSON.stringify(grouped[groupName]));
	});

	removeFromSessionStorage(getRecordedStatsKey());
	removeFromSessionStorage(getEventNamesKey());
	removeFromSessionStorage(activeKey);
}

export function samplePerformanceEvents(...performanceEventNames: string[]) {
	if (!hasActiveSession()) {
		throw new Error(
			'✋ No active session in progress. Run interactive.startSamplingPerformanceData(<experimentName>) to start a new session.'
		);
	}
	if (hasStats()) {
		throw new Error(
			'🔒 Already recording stats. You may end your testing session by running interactive.stopSamplingPerformanceData.'
		);
	}
	const marks = getPeformanceEventNames();
	performanceEventNames.forEach(eventName => {
		if (marks.indexOf(eventName) === -1) {
			marks.push(eventName);
		}
	});
	setInSessionStorage(getEventNamesKey(), marks);

	return 'Continue adding more marks or measures; or you may end your testing session by running interactive.stopSamplingPerformanceData.';
}
