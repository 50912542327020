import * as React from 'react';
import { css } from 'aphrodite';
import {
	BreakpointsProps,
	media,
	ModalHeader,
	ModalProps,
	withBreakpoints,
} from '@citrite/web-ui-component';
import { ResourceIcon } from 'Components/ResourceIcon';
import { shouldEnableSiri } from 'Environment/utils';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { Favorite } from '../Favorite';
import { Siri } from '../Siri';
import { StyledHeader } from './Header.styled';
import { styles } from './Header.styles';

export interface Props extends ModalProps, BreakpointsProps {
	resource: Resource;
}

class _Header extends React.Component<Props> {
	public render() {
		return (
			<ModalHeader onDismiss={this.props.onCloseSuccess} className={css(styles.header)}>
				<div className={css(styles.titleContainer)}>
					{this.props.resource.iconurl && (
						<div className={css(styles.icon)}>
							<ResourceIcon resource={this.props.resource} size={48} />
						</div>
					)}
					<div className={css(styles.title)}>
						<StyledHeader>{this.props.resource.name}</StyledHeader>
						{this.props.screenSize.media !== media.small && this.favoriteAction()}
						{shouldEnableSiri() &&
							this.props.screenSize.media !== media.small &&
							this.addToSiriAction()}
					</div>
				</div>
				{this.props.screenSize.media === media.small && this.favoriteAction()}
				{shouldEnableSiri() &&
					this.props.screenSize.media === media.small &&
					this.addToSiriAction()}
			</ModalHeader>
		);
	}

	private favoriteAction() {
		if (!!this.props.resource.subscriptionurl) {
			return (
				<div className={css(styles.favoriteAction)}>
					<Favorite {...this.props.resource} showLabel />
				</div>
			);
		}

		return undefined;
	}

	private addToSiriAction() {
		return (
			<div className={css(styles.addToSiriAction)}>
				<Siri {...this.props.resource} />
			</div>
		);
	}
}

export const Header = withBreakpoints(_Header);
