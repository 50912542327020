                   
import { CaptureConsole } from '@sentry/integrations';
import { createGrafanaLogger } from 'Loggers/GrafanaLogger';
          
import type {
	AddBreadcrumb,
	LogError,
	LoggingProvider,
	LogInfo,
	SetLoggingTags,
	SetUserId,
} from 'Loggers/LoggingProvider';
import { MultiLogger } from 'Loggers/MultiLogger';
                   
import { createSentryLogger, filterOutCedexis } from 'Loggers/SentryLogger';
          
import { traceManager } from 'Tracing';

let defaultLogger: LoggingProvider;
function getDefaultLogger() {
	defaultLogger ??= createLogger();

	return defaultLogger;
}
export const logError: LogError = (...args) => getDefaultLogger().logError(...args);
export const logInfo: LogInfo = (...args) => getDefaultLogger().logInfo(...args);
export const addBreadcrumb: AddBreadcrumb = (...args) =>
	getDefaultLogger().addBreadcrumb(...args);
export const setLoggingTags: SetLoggingTags = (...args) =>
	getDefaultLogger().setLoggingTags(...args);
export const setUserId: SetUserId = id => getDefaultLogger().setUserId(id);
export { normalizeMessageFromError } from 'Loggers/ErrorHelpers';

export function createLogger() {
	//prevents logging PII for users who have saved the html locally
	const localLoggers = [traceManager];
	const loggers =
		location.protocol === 'https:' && !IS_ON_PREM
			? [
					createGrafanaLogger(),
					createSentryLogger({
						integrations: [new CaptureConsole({ levels: ['warn', 'error'] })],
						beforeBreadcrumb: filterOutCedexis,
					}),
					...localLoggers,
			  ]
			: localLoggers;
	return new MultiLogger(...loggers);
}
