import { palette } from '@citrite/web-ui-component';
import {
	continuePerformanceSampling,
	samplePerformanceEvents,
	startSamplingPerformanceData,
	stopSamplingPerformanceData,
} from 'javascript/interactivePerformanceDataCollection';
import {
	getFromLocalStorage,
	getFromSessionStorage,
	setInLocalStorage,
	setInSessionStorage,
} from 'javascript/sf/Storage';

const instance = {};
const interactivePersistence = 'interactivePersistence';

export function addInteractiveTools() {
	window['interactive'] = window['interactive'] || instance;
	installToolFunction('setPersistence', (value: 'session' | 'local') => {
		setInLocalStorage(interactivePersistence, value);
	});
	if (!IS_RELEASE) {
		// Statistical performance sampling
		continuePerformanceSampling();
		installToolFunction('startSamplingPerformanceData', startSamplingPerformanceData);
		installToolFunction('stopSamplingPerformanceData', stopSamplingPerformanceData);
		installToolFunction('samplePerformanceEvents', samplePerformanceEvents);
	}

	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.groupCollapsed(
			'%cWorkspace Interactive Tools',
			`background: ${palette.blue900}; color: #fff; padding: 3px 6px; border-radius: 2px`
		);
		// eslint-disable-next-line no-console
		console.info(
			'Helpful tools are available in the console via %cwindow.interactive %cor ',
			`color: ${palette.red800}`,
			'color: inherit'
		);
		// eslint-disable-next-line no-console
		console.info('%cinteractive', `color: ${palette.red800}`, window['interactive']);
		// eslint-disable-next-line no-console
		console.groupEnd();
	}
}

export function installToolFunction(name: string, method: Function) {
	if (!IS_RELEASE) {
		instance[name] = method;
	}
}

export function installLazyGetter(name: string, getter: () => any) {
	Object.defineProperty(instance, name, {
		configurable: true,
		get: () => (IS_RELEASE ? JSON.parse(JSON.stringify(getter())) : getter()),
	});
}

export function getFromInteractiveStorage<T>(key: string) {
	if (isLocalStorage()) {
		return getFromLocalStorage<T>(key);
	}
	return getFromSessionStorage<T>(key);
}

export function setInInteractiveStorage(key: string, data: any) {
	if (isLocalStorage()) {
		return setInLocalStorage(key, data);
	}
	return setInSessionStorage(key, data);
}

function isLocalStorage() {
	return getFromLocalStorage(interactivePersistence) === 'local';
}
