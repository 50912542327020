export enum BackoffStrategy {
	LINEAR = 'linear',
	EXPONENTIAL = 'exponential',
	CONSTANT = 'constant',
}
interface ResilientPromiseConfiguration {
	maxAttempts?: number;
	delayMS?: number;
	retryPredicate?: (error: Error) => boolean | Promise<boolean>;
	repeatPredicate?: (result: any) => boolean | Promise<boolean>;
	backoffStrategy?: BackoffStrategy;
	keepRetrying?: boolean;
	maxDelayMS?: number;
}

type AsyncFunction<T> = () => Promise<T>;

export function resilientPromise<T>(
	asyncFunction: AsyncFunction<T>,
	options?: ResilientPromiseConfiguration
): Promise<T> {
	const rp = new ResilientPromise(options);
	return rp.run(asyncFunction);
}

const noMaxDelaySet = -1;
class ResilientPromise {
	private readonly config: Required<ResilientPromiseConfiguration> = {
		maxAttempts: 2,
		delayMS: 1000,
		retryPredicate: () => Promise.resolve(true),
		repeatPredicate: () => Promise.resolve(false),
		backoffStrategy: BackoffStrategy.EXPONENTIAL,
		keepRetrying: false,
		// value -1 means there is no explicit maximum delay provided
		maxDelayMS: noMaxDelaySet,
	};

	private attempts = 0;

	public constructor(options?: ResilientPromiseConfiguration) {
		this.config = {
			...this.config,
			...options,
		};
	}

	public run<T>(asyncFunction: AsyncFunction<T>): Promise<T> {
		this.attempts++;
		const { retryPredicate, repeatPredicate, maxAttempts, delayMS } = this.config;
		return asyncFunction()
			.then(async result => {
				if (await repeatPredicate(result)) {
					throw new Error(
						'Repeating the async function as per the repeatPredicate condition.'
					);
				}
				return result;
			})
			.catch(async e => {
				if (!this.config.keepRetrying && this.attempts >= maxAttempts) {
					throw e;
				}
				if (!(await retryPredicate(e))) {
					throw e;
				}
				if (delayMS <= 0) {
					return this.run(asyncFunction);
				}

				return new Promise<T>((resolve, reject) => {
					const delay = this.calculateStrategyBasedDelay(delayMS);
					const maxDelay = this.config.maxDelayMS > 0 ? this.config.maxDelayMS : Infinity;
					setTimeout(
						() => this.run(asyncFunction).then(resolve).catch(reject),
						Math.min(delay, maxDelay)
					);
				});
			});
	}

	private calculateStrategyBasedDelay(delayMS: number): number {
		switch (this.config.backoffStrategy) {
			case BackoffStrategy.LINEAR:
				return delayMS * this.attempts;
			case BackoffStrategy.EXPONENTIAL:
				return delayMS * Math.pow(2, this.attempts - 1);
			case BackoffStrategy.CONSTANT:
			default:
				return delayMS;
		}
	}
}
