/* eslint-disable simple-import-sort/imports */
import * as React from 'react';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { addChromeAppListener } from 'Environment/launchResource/chromeApp';
import { BrowserExtensionContext } from 'Workspace/BrowserExtension/Context';
import { useBrowserExtension } from 'Workspace/BrowserExtension/useBrowserExtension';
import { useChromeApp } from 'Workspace/ChromeApp/useChromeApp';
import { addResourceProviderInteractiveTools } from 'Workspace/ResourceProvider/ResourceProvider.devtools';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';
import { LoadableResourceContext, ResourceContextProvider } from './Context';
import { upgradeAvailable } from './upgradeCheck';
import { ChromeAppContext } from 'Workspace/ChromeApp/ChromeAppContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';

interface Props {
	workspaceConfiguration: WorkspaceConfiguration;
	browserExtensionContext: BrowserExtensionContext;
	chromeAppContext: ChromeAppContext;
	featureFlags?: Record<string, boolean>;
}

interface State {
	loadableResourceContext: ResourceContextProvider;
	isTrusted: boolean;
}

type ResourceUpdate = (resources: Resource[]) => Resource[];

class _ResourceProvider extends React.Component<Props, State> {
	private resourceUpdates: ResourceUpdate[] = [];
	private recordingUpdates = false;

	public state: State = {
		loadableResourceContext: {
			loading: true,
			value: {
				tryLaunchViaBrowserExtension: this.props.browserExtensionContext.launch,
				tryLaunchViaChromeApp: this.props.chromeAppContext.launch,
				shouldBrowserExtensionLaunchResource:
					this.props.browserExtensionContext.shouldBrowserExtensionLaunchResource,
				shouldChromeAppLaunchResource:
					this.props.chromeAppContext.shouldChromeAppLaunchResource,
				resources: null,
				filesSSOUrl: null,
				isUnauthenticatedStore: false,
				installedResources: [],
				launchInProgressIds: [],
				launchProgress: {},
				desktopRestartInProgressIds: [],
				preferLeaseLaunchIds: [],
				setLoading: loading =>
					this.setState(state => ({
						loadableResourceContext: {
							...state.loadableResourceContext,
							loading,
						},
					})),
				updateSession: callbackOrData => {
					if (typeof callbackOrData === 'function') {
						this.setState(state => ({
							loadableResourceContext: {
								...state.loadableResourceContext,
								value: {
									...state.loadableResourceContext.value,
									...callbackOrData(state.loadableResourceContext.value),
								},
							},
						}));
						return;
					}
					this.setState(state => ({
						loadableResourceContext: {
							...state.loadableResourceContext,
							value: {
								...state.loadableResourceContext.value,
								...callbackOrData,
							},
						},
					}));
				},
				isInstalled: id =>
					this.state.loadableResourceContext.value.installedResources.some(
						resource => resource.appId === id
					),
				isInProgress: id =>
					this.state.loadableResourceContext.value.launchInProgressIds.includes(id),
				isUpgradeAvailable: id =>
					upgradeAvailable(
						id,
						this.state.loadableResourceContext.value.installedResources,
						this.state.loadableResourceContext.value.resources
					),
				subscriptionsEnabled: () => {
					if (this.props.featureFlags[FeatureFlag.UseIsSubscriptionEnabledFlag]) {
						return this.state.loadableResourceContext.value.isSubscriptionEnabled;
					}
					const resources = this.state.loadableResourceContext.value.resources || [];
					const allMandatory =
						resources.filter(r => !r.subscriptionurl).length === resources.length ||
						resources.every(r => r.mandatory);

					return resources.length > 0 && !allMandatory;
				},
				startRecordingResourceUpdates: () => (this.recordingUpdates = true),
				stopRecordingResourceUpdates: () => (this.recordingUpdates = false),
				applyRecordedResourceUpdates: this.applyRecordedResourceUpdates.bind(this),
				setSubscriptionStatus: this.setSubscriptionStatus.bind(this),
				setSiriRegistrationStatus: this.setSiriRegistrationStatus.bind(this),
				makeResourceMostRecent: this.makeResourceMostRecent.bind(this),
				setMultipleResourceSubscriptionStatus:
					this.setMultipleResourceSubscriptionStatus.bind(this),
				isSubscriptionEnabled: true,
			},
		},
		isTrusted: true,
	};

	private setSiriRegistrationStatus(resourceId: string, newStatus: boolean) {
		this.applyAndRecordResourceUpdate(resources =>
			resources.map<Resource>(resource => ({
				...resource,
				isResourceRegisteredToSiri:
					resource.id === resourceId ? newStatus : resource.isResourceRegisteredToSiri,
			}))
		);
	}

	private setSubscriptionStatus(resourceId: string, newStatus: subscriptionStatus) {
		this.applyAndRecordResourceUpdate(resources =>
			resources.map<Resource>(resource => ({
				...resource,
				subscriptionstatus:
					resource.id === resourceId ? newStatus : resource.subscriptionstatus,
			}))
		);
	}

	private makeResourceMostRecent(resourceId: string) {
		this.applyAndRecordResourceUpdate(resources => {
			const resourceIndex = resources.findIndex(resource => resource.id === resourceId);
			if (resourceIndex === -1) {
				return resources;
			}

			const updatedResources = [...resources];
			updatedResources.splice(resourceIndex, 1);
			const firstRecent = updatedResources.reduce((minimumPosition, current) => {
				if (!current.recentsposition) {
					return minimumPosition;
				}
				return Math.min(minimumPosition, current.recentsposition);
			}, 0);
			updatedResources.unshift({
				...resources[resourceIndex],
				recentsposition: firstRecent - 1,
			});
			return updatedResources;
		});
	}

	private applyAndRecordResourceUpdate(update: ResourceUpdate) {
		this.setState(state => ({
			loadableResourceContext: {
				...state.loadableResourceContext,
				value: {
					...state.loadableResourceContext.value,
					resources: update(state.loadableResourceContext.value.resources),
				},
			},
		}));

		if (this.recordingUpdates) {
			this.resourceUpdates.push(update);
		}
	}

	private applyRecordedResourceUpdates(resources: Resource[]) {
		let updatedResources = resources;
		this.resourceUpdates.forEach(localUpdate => {
			updatedResources = localUpdate(updatedResources);
		});

		this.resourceUpdates = [];
		return updatedResources;
	}

	private setMultipleResourceSubscriptionStatus(
		resourceIds: string[],
		newStatus: subscriptionStatus
	) {
		this.applyAndRecordResourceUpdate(resources =>
			resources.map<Resource>(resource => ({
				...resource,
				subscriptionstatus: resourceIds.includes(resource.id)
					? newStatus
					: resource.subscriptionstatus,
			}))
		);
	}

	public render() {
		return (
			<LoadableResourceContext.Provider value={this.state.loadableResourceContext}>
				{this.props.children}
			</LoadableResourceContext.Provider>
		);
	}

	public componentDidMount() {
		try {
			addChromeAppListener(
				() => this.props.workspaceConfiguration,
				() => this.state.loadableResourceContext.value
			);
		} catch {}

		addResourceProviderInteractiveTools(
			() => this.props.workspaceConfiguration,
			() => this.state.loadableResourceContext.value.resources
		);
	}
}

export const ResourceProvider: React.FC = props => {
	const context = useConfigurationContext();
	const { workspaceConfiguration } = context;
	const browserExtensionContext = useBrowserExtension();
	const chromeAppContext = useChromeApp();
	const featureFlags = {
		[FeatureFlag.UseIsSubscriptionEnabledFlag]: useFeatureCanary(
			FeatureFlag.UseIsSubscriptionEnabledFlag
		),
	};
	return (
		<_ResourceProvider
			workspaceConfiguration={workspaceConfiguration}
			browserExtensionContext={browserExtensionContext}
			chromeAppContext={chromeAppContext}
			featureFlags={featureFlags}
		>
			{props.children}
		</_ResourceProvider>
	);
};
