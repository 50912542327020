// workaround for issues with tests caused by mixing using babel in the app package (./src) and tsc compilation in integration packages (./packages) (WSUI-2697)
const URI: typeof import('urijs') = require('urijs');

export function getAssetBundleUrlFromRelativePath(bundlesRelativePath: string) {
	const hasLeadingSlash = bundlesRelativePath.startsWith('/');
	const appendPath = bundlesRelativePath.substring(hasLeadingSlash ? 1 : 0);
	const uri = new URI(window.location.href);
	const citrixStoreWeb = uri.segment().slice(0, 2).join('/');
	const htmlPath = 'assets/workspace/';
	return uri
		.path(`${citrixStoreWeb}/${htmlPath}${appendPath}`)
		.search('')
		.hash('')
		.toString();
}
