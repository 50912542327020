import React from 'react';
import { DesktopSessionDetails } from 'App/Activity/components/DesktopSessionDetails';
import { Session } from 'App/Activity/ResourceManagerContext';

export interface DesktopCardProps {
	session: Session;
}

export const DesktopCard = ({ session }: DesktopCardProps) => {
	return (
		<>
			<DesktopSessionDetails session={session} />
		</>
	);
};
