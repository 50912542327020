import { AnalyticsEvent, Metadata } from 'analytics';
import {
	AppsEventType,
	EventFilter,
	EventSite,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_APPS';

const preparePayload = (type: AppsEventType, metadata?: Metadata): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});

export const AppsUserEventPayload = {
	launch: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.Launch, { site, app }),
	launchFailure: (app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.Launch_Failure, { app }),
	favourite: (isFavorited: boolean, app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.Favourite, { isFavorited, app }),
	moreInfo: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.MoreInfo, { site, app }),
	filtering: (filter: EventFilter): AnalyticsEvent =>
		preparePayload(AppsEventType.Filtering, { filter }),
	sorting: (): AnalyticsEvent => preparePayload(AppsEventType.Sorting),
	install: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.Install, { site, app }),
	remove: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.Remove, { site, app }),
	update: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(AppsEventType.Update, { site, app }),
	doesnotexist: (): AnalyticsEvent => preparePayload(AppsEventType.DoesNotExist),
};
