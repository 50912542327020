import * as React from 'react';
import { createRoute, makeAsync } from '@citrite/workspace-ui-platform-react';
import { InitialLoading } from 'Workspace/InitialLoading';

const Login = makeAsync(
	() =>
		import(/* webpackChunkName: "Login", webpackPrefetch: true */ './Login').then(
			m => m.Login
		),
	<InitialLoading />
);

export const workspaceLoginRoute = createRoute({
	component: Login,
	path: '/login',
});
