import { useEffect, useState } from 'react';
import _ from 'lodash';
import { getTransitioningSessionStatus } from 'App/Activity/ResourceActionHandler';
import {
	Session,
	UNKNOWN_STATE,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';

export const useTransitioningStatus = (session: Session) => {
	const context = useResourceManagerContext();
	const [status, setStatus] = useState<string>(UNKNOWN_STATE);
	useEffect(() => {
		const activeAction =
			context.activeSessionActions.find(
				action => action.session.sessionId === session.sessionId
			) ||
			context.activeMachineActions.find(
				action => action.machine.machineId === session.machineData.machineId
			) ||
			context.activeLaunchActions.find(
				action => action.session.sessionId === session.sessionId
			);
		if (activeAction) {
			setStatus(getTransitioningSessionStatus(activeAction.action));
		} else {
			setStatus(UNKNOWN_STATE);
		}
	}, [
		context.activeSessionActions,
		context.activeMachineActions,
		session,
		context.activeLaunchActions,
	]);

	return status;
};
