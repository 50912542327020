import { StyleSheet } from 'aphrodite';
import { palette, styleUtils, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	nameWrapper: {
		overflow: 'hidden',
	},
	name: {
		...styleUtils.ellipsis,
		fontWeight: typography.weights.medium,
		fontSize: typography.sizes.base,
		color: palette.grey900,
	},
});
