import React, { useCallback, useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import {
	Button,
	InfoIcon,
	layout,
	Modal,
	ModalBody,
	ModalHeader,
	ModalProps,
	ModalTitle,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { getCurrentPlatform } from 'Environment/launchResource/device/currentPlatform';
import {
	openCEBDownloadPage,
	openCEBGuidePage,
} from 'Workspace/CitrixEnterpriseBrowser/utils';
import { openInCitrixBrowser } from 'Workspace/SchemeCallHandler/schemeCallHandler';
import { saasAppLaunchAnalyticsReporter } from 'Workspace/TelemetryEvents/saasLaunch/createSaasAppLaunchAnalyticsReporter';
import { trackNamAndCebAnalytics } from 'Workspace/trackNamAndCebAnalytics';

const StyledPrimaryTextButton = styled(Button.TextPrimary)`
	padding-left: 0;
	padding-right: 0;
`;

const StyledModalHeader = styled(ModalHeader)`
	border-bottom: none;
	padding-bottom: 0;
`;

const StyledModalBody = styled(ModalBody)`
	padding-top: 0;
`;

const StyledPrimaryButton = styled(Button.Primary)`
	margin-bottom: ${layout.mediumSpace};
`;

const StyledParagraph = styled.p`
	margin: ${layout.mediumSpace} 0;
`;

const StyledFlexSpan = styled.span`
	display: flex;
	align-items: center;
	padding: ${layout.tinySpace} 0;
	line-height: 1;
`;

const StyledSpan = styled.span`
	padding: 0 ${layout.smallSpace};
`;

type UserProfile = {
	userId?: string;
	authAlias?: string;
	sid?: string;
	userName?: string;
};

export enum LaunchInCEBMode {
	Always = 'Always',
	WithFallback = 'WithFallback',
	Never = 'Never',
}

export interface LaunchInCEBModalProps extends ModalProps, UserProfile {
	mode: LaunchInCEBMode;
	resourceId: string;
	onFallback?: () => void;
	receiverConfigurationDownloadUrl?: string;
}

export const LaunchInCEBModal = (props: LaunchInCEBModalProps) => {
	const { userId, sid, userName, resourceId, authAlias } = props;
	const [hasInitiatedDownload, setHasInitiatedDownload] = useState(false);
	const onFallback = () => {
		props.onFallback();
		props.onDismiss();
		trackNamAndCebAnalytics(
			saasAppLaunchAnalyticsReporter.getSaasLaunchOpenInNewTabEvent()
		);
	};

	const openInCEB = useCallback(() => {
		trackNamAndCebAnalytics(
			saasAppLaunchAnalyticsReporter.getSaasLaunchUsingCEBEvent(resourceId)
		);
		openInCitrixBrowser(
			{
				resourceId,
				userId,
				sid,
				userName,
				authAlias,
			},
			props.receiverConfigurationDownloadUrl
		);
	}, [
		userId,
		sid,
		userName,
		resourceId,
		authAlias,
		props.receiverConfigurationDownloadUrl,
	]);

	const downloadCEB = () => {
		setHasInitiatedDownload(true);
		openCEBDownloadPage(getCurrentPlatform());
		trackNamAndCebAnalytics(
			saasAppLaunchAnalyticsReporter.getSaasLaunchCebDownloadClickedEvent()
		);
	};

	const guideLaunchInCEB = () => {
		openCEBGuidePage(getCurrentPlatform());
		trackNamAndCebAnalytics(
			saasAppLaunchAnalyticsReporter.getSaasLaunchUserGuideOpenedEvent()
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(openInCEB, []);

	const getDescription = () =>
		props.mode === LaunchInCEBMode.Always
			? t('Workspace:citrix_enterprise_browser.saasAppLaunch.modal.mandate_description')
			: t('Workspace:citrix_enterprise_browser.saasAppLaunch.modal.optional_description');

	return (
		<Modal {...props}>
			<StyledModalHeader onDismiss={props.onDismiss}>
				<ModalTitle>
					{t('Workspace:citrix_enterprise_browser.saasAppLaunch.modal.title')}
				</ModalTitle>
			</StyledModalHeader>
			<StyledModalBody>
				<StyledParagraph dangerouslySetInnerHTML={{ __html: getDescription() }} />
				<StyledPrimaryButton onClick={openInCEB}>
					{t('Workspace:citrix_enterprise_browser.saasAppLaunch.modal.openCEB')}
				</StyledPrimaryButton>
				<hr />
				<StyledParagraph>
					{t(
						'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.doNotHaveCEB'
					)}
					<Button.TextPrimary onClick={downloadCEB}>
						{t(
							'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.download'
						)}
					</Button.TextPrimary>
					{hasInitiatedDownload && (
						<StyledFlexSpan>
							<InfoIcon size={16} />
							<StyledSpan
								data-testid="ceb-downloadAlert"
								dangerouslySetInnerHTML={{
									__html: t(
										'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.downloadAlert'
									),
								}}
							/>
						</StyledFlexSpan>
					)}
				</StyledParagraph>
				<StyledParagraph>
					{t(
						'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.havingIssue'
					)}
					<Button.TextPrimary onClick={guideLaunchInCEB}>
						{t(
							'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.installGuid'
						)}
					</Button.TextPrimary>
				</StyledParagraph>
				{props.mode === LaunchInCEBMode.WithFallback && props.onFallback && (
					<StyledParagraph>
						<StyledPrimaryTextButton onClick={onFallback}>
							{t(
								'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.openInNewTab'
							)}
						</StyledPrimaryTextButton>
					</StyledParagraph>
				)}
				{props.mode !== LaunchInCEBMode.WithFallback && (
					<StyledParagraph>
						{t(
							'Workspace:citrix_enterprise_browser.saasAppLaunch.modal.troubleShooting.contactAdmin'
						)}
					</StyledParagraph>
				)}
			</StyledModalBody>
		</Modal>
	);
};
