import * as React from 'react';
import { css } from 'aphrodite';
import { Widget } from './ItemList';
import { styles } from './ItemListSkeleton.styles';

export function ItemListSkeleton() {
	return (
		<div className={css(styles.widget)}>
			<div className={css(styles.titleBar)} />
			<Widget>
				<WidgetBar width={128} />
				<WidgetBar width={72} />
				<WidgetBar width={128} />
				<WidgetBar width={72} />
			</Widget>
		</div>
	);
}

function WidgetBar(props: { width: number }) {
	return (
		<div className={css(styles.row)}>
			<div className={css(styles.icon)} />
			<div className={css(styles.name)} style={{ width: `${props.width}px` }} />
		</div>
	);
}
