import { NativeStorage } from 'Environment/NativeStorage';
import { StorageInterface } from 'javascript/sf/Storage';

// allow-unused-export: for unit testing purposes
export const wsuiAllKeyNames = 'wsuiAllKeyNames';

export class InMemoryStorage implements StorageInterface {
	private memoryStore: { [key: string]: any } = {};
	private nativeStorage: NativeStorage;
	private currentKnownKeyNames: { [key: string]: string } = {};

	public constructor(nativeStorage: NativeStorage) {
		this.nativeStorage = nativeStorage;
	}

	private async storeKeyNameInAllKeys(key: string) {
		//Its already stored dont call native storage
		if (this.currentKnownKeyNames[key]) {
			return;
		}

		this.currentKnownKeyNames[key] = key;
		await this.nativeStorage.saveToNativeStorage(
			wsuiAllKeyNames,
			this.currentKnownKeyNames
		);
	}

	private async deleteKeyNameFromAllKeys(key: string) {
		//Its already deleted don't call native storage
		if (!this.currentKnownKeyNames[key]) {
			return;
		}

		delete this.currentKnownKeyNames[key];
		await this.nativeStorage.saveToNativeStorage(
			wsuiAllKeyNames,
			this.currentKnownKeyNames
		);
	}

	public set(key: string, value: any): void {
		// Set the value in memory
		this.memoryStore[key] = value;
		this.storeKeyNameInAllKeys(key);
		this.nativeStorage.saveToNativeStorage(key, value).catch(console.error);
	}

	public get(key: string): any {
		// Get the value from memory
		return this.memoryStore[key];
	}

	public remove(key: string) {
		// Remove the value from memory
		delete this.memoryStore[key];

		// Asynchronously remove from native storage
		this.nativeStorage.removeFromNativeStorage(key).catch(console.error);
		this.deleteKeyNameFromAllKeys(key);
	}

	public async preloadFromNative(): Promise<void> {
		// Get all keys from native storage
		const allKeys = await this.nativeStorage.getFromNativeStorage(wsuiAllKeyNames);
		if (!allKeys) {
			return;
		}

		// Get all values from native storage
		Object.keys(allKeys).forEach(async key => {
			const value = await this.nativeStorage.getFromNativeStorage(key);
			this.memoryStore[key] = value;
			this.currentKnownKeyNames[key] = key;
		});
	}
}
