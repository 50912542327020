import * as React from 'react';
import { CitrixUIProvider } from 'Components/CitrixUIProvider';
import { MandateNativeApp } from 'Components/MandateNativeAppSupport/MandateNativeApp';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { shouldNativeAppBeMandated } from 'Workspace/utils';
import { GlobalStyles } from './GlobalStyles';
import { ThemeProvider } from './ThemeProvider';

export const UIProviders = (props: { children?: React.ReactNode }) => {
	const { workspaceConfiguration } = useConfigurationContext();
	const shouldManadateNativeApp = shouldNativeAppBeMandated(workspaceConfiguration);

	return (
		<ThemeProvider>
			<CitrixUIProvider>
				<GlobalStyles />
				{shouldManadateNativeApp ? <MandateNativeApp /> : props.children}
			</CitrixUIProvider>
		</ThemeProvider>
	);
};
