import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { layout, LoadingIconPrimary } from '@citrite/web-ui-component';

const styles = StyleSheet.create({
	icon: {
		marginRight: layout.mediumLargeSpace,
	},
});

export interface Props {
	loading?: boolean;
	icon: React.ReactNode;
}

export const Icon: React.FC<Props> = ({ loading, icon }) => {
	if (loading) {
		return (
			<span className={css(styles.icon)}>
				<LoadingIconPrimary size={32} />
			</span>
		);
	}
	return <span className={css(styles.icon)}>{icon}</span>;
};
