import { trackAnalyticsEvent } from 'analytics';
import { environment } from 'Environment';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const disconnectLocally = (sessionId: string) => {
	const eventName = {
		id: 'activities',
		type: 'action/miniui/running/resource/disconnect',
		payload: { sessionid: sessionId },
	};
	environment.sendEventToNative(eventName);
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerDisconnectEvent(false));
};
