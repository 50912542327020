import React, { ReactNode } from 'react';
import { palette } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { bottomNavOffset } from 'App/BottomNav/dimensions';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { MobileFullScreenBanner } from 'App/Screen/MobileFullScreenBanner';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';

const StyledFullscreenDiv = styled.div<{ clientHeight: number }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: ${props => props.clientHeight + 'px'};
	background: ${palette.white};
	margin-bottom: 50px;
`;

export interface MobileFullScreenProps {
	title: string;
	onBackButtonClick: () => void;
	displayBottomNavigation?: boolean;
	children: ReactNode;
}

function _MobileFullScreen({
	title,
	onBackButtonClick,
	displayBottomNavigation = true,
	children,
}: MobileFullScreenProps) {
	const height = parseInt(useClientHeight(), 10);

	return (
		<StyledFullscreenDiv
			clientHeight={displayBottomNavigation ? height - bottomNavOffset : height}
			data-testid="mobile-full-screen-container-div"
		>
			<MobileFullScreenBanner
				subCategoryName={title}
				onBackButtonClick={onBackButtonClick}
			/>
			{children}
		</StyledFullscreenDiv>
	);
}

export const MobileFullScreen = (props: any) => {
	return (
		<GenericErrorBoundary
			renderProp={() => <_MobileFullScreen {...props} />}
			name="CategoryTile"
		/>
	);
};
