import { StyleSheet } from 'aphrodite';
import { palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	resultsHeader: {
		display: 'flex',
		alignItems: 'center',
		height: '30px',
		fontSize: '14px',
		backgroundColor: '#f5f5f5',
		border: '1px solid #e9e9e9',
		justifyContent: 'space-between',
		paddingLeft: '40px',
	},
	emptyState: {
		paddingTop: '20px',
	},
	emptyStateTitle: {
		fontSize: '24px',
		fontWeight: typography.weights.light,
	},
	status: {
		backgroundColor: 'white',
		height: '44px',
		lineHeight: '44px',
		paddingLeft: '40px',
	},
	statusEmpty: {
		fontStyle: 'italic',
		fontWeight: typography.weights.regular,
		color: palette.grey600,
	},
});
