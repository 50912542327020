import * as React from 'react';
import { StyledAppContent } from 'Components/AppLayout/styled';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { CONTENTID } from 'javascript/sf/constants';

export const AppContent: React.SFC = props => {
	const height = useClientHeight();
	return (
		<StyledAppContent id={CONTENTID} clientHeight={height}>
			{props.children}
		</StyledAppContent>
	);
};
