import * as React from 'react';
import {
	ActivityFeedIcon,
	ActivityFeedSolidIcon,
	DashboardIcon,
	DashboardSolidIcon,
	IconProps,
} from '@citrite/web-ui-component';
import { UserNotificationBadgeOffIfFeedInNav } from 'App/NotificationHome/UserNotificationBadge';
import { isNativeMobileClient } from 'Environment/launchResource/device';
import { useUserContext } from 'Workspace/UserContext';

export const ComposableIcon = (props: IconProps) => {
	const { isIWSEnabled } = useUserContext();

	return isIWSEnabled && isNativeMobileClient() ? (
		<ActivityFeedIcon {...props} />
	) : (
		<DashboardIcon {...props} />
	);
};

export const ComposableSelectedIcon = (props: IconProps) => {
	const { isIWSEnabled } = useUserContext();

	return isIWSEnabled ? (
		<ActivityFeedSolidIcon {...props} />
	) : (
		<DashboardSolidIcon {...props} />
	);
};

export const ComposableSecondaryIcon = () => {
	const { isIWSEnabled } = useUserContext();
	return isIWSEnabled ? <UserNotificationBadgeOffIfFeedInNav /> : null;
};
