import { get, set } from 'idb-keyval';
import { LocalAppResource } from 'Workspace/ResourceProvider/resourceTypes';

const importedLocalAppsKey = 'imported-local-apps';

export const updateLocalApps = async (localApps: LocalAppResource[]) => {
	await set(importedLocalAppsKey, localApps);
};

export const getLocalAppsFromStorage = async () => {
	const localApps = await get<LocalAppResource[]>(importedLocalAppsKey);
	return localApps ?? [];
};

export const modifyLocalApp = async (
	appId: string,
	changedProperties: Partial<LocalAppResource>
) => {
	const localAppsPreUpdate = await getLocalAppsFromStorage();
	const localAppsAfterUpdate = localAppsPreUpdate.map(app => {
		return app.id === appId ? { ...app, ...changedProperties } : app;
	});
	await updateLocalApps(localAppsAfterUpdate);
};

export const removeLocalApp = async (appId: string) => {
	const localAppsPreUpdate = await getLocalAppsFromStorage();
	const localAppsAfterUpdate = localAppsPreUpdate.filter(app => {
		return app.id !== appId;
	});
	await updateLocalApps(localAppsAfterUpdate);
};

export const updateLocalAppsWithWhitelistedApps = async (
	whitelistedLocalApps: LocalAppResource[]
) => {
	const storedLocalApps = await getLocalAppsFromStorage();
	const ids = new Set(whitelistedLocalApps.map(app => app.id));
	const filteredStorageApps = storedLocalApps.filter(app => {
		return !app.whitelisted && !ids.has(app.id);
	});

	const whitelistedAppsWithSubscription = whitelistedLocalApps.map(app => {
		const sAPP = storedLocalApps.find(sApp => sApp.id === app.id);
		return {
			...app,
			subscriptionstatus: sAPP ? sAPP.subscriptionstatus : app.subscriptionstatus,
		};
	});

	const updatedApps = [...whitelistedAppsWithSubscription, ...filteredStorageApps].sort(
		(a, b) => {
			if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
				return -1;
			}
			if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
				return 1;
			}
			return 0;
		}
	);
	await updateLocalApps(updatedApps);
};
