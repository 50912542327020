import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
	itemSelection: {
		':active': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
		},
		width: '100%',
		minWidth: 0,
	},
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		minWidth: 0,
	},
});
