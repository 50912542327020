import { StyleSheet } from 'aphrodite';
import { layout, palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	item: {
		display: 'flex',
		paddingBottom: layout.smallSpace,
		paddingTop: layout.smallSpace,
		background: palette.white,
		justifyContent: 'flex-end',
	},
	viewAllButton: {
		fontWeight: typography.weights.regular,
		fontSize: typography.sizes.xsml,
		color: palette.blue700,
	},
});
