import { AnalyticsEvent, Metadata } from 'analytics';
import { PinnedLinksEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_PINNED_LINKS';

const preparePayload = (
	type: PinnedLinksEventType,
	metadata?: Metadata
): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});

export const PinnedLinksUserEventPayload = {
	pinnedLinkDetails: (pinnedLinkDetails: string): AnalyticsEvent =>
		preparePayload(PinnedLinksEventType.pinnedLinkDetails, { pinnedLinkDetails }),
	pinnedLinkClicked: (): AnalyticsEvent =>
		preparePayload(PinnedLinksEventType.pinnedLinkClicked),
};
