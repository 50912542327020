import * as React from 'react';
import { t } from '@citrite/translate';
import {
	breakpoints,
	Checkbox,
	notifyError,
	palette,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ResponsiveResourceIcon } from 'Components/FTU/ResponsiveResourceIcon';
import { getResourceType, isMandatoryResource } from 'Components/FTU/Utils';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const paddingOuterContainer = 8;

const StyledOuterContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: ${paddingOuterContainer}px 8px;
	gap: 10px;
	width: 274px;
	background: ${palette.white};
	border: 1px solid ${palette.white};
	border-radius: 0px;
	${breakpoints.onMediumScreen} {
		width: calc(max(220px, 50%));
	}
`;

export const resourceContainerHeight = 35;

const StyledResourceDetailContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 185px;
	height: ${resourceContainerHeight}px;
	${breakpoints.onMediumScreen} {
		width: 150px;
		height: 26px;
	}
`;

const StyledResourceName = styled.div`
	width: 185px;
	height: 20px;
	font-family: 'PublicSans', Arial, sans-serif;
	font-style: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: ${typography.weights.semiBold};
	font-size: 14.5px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.842);
	${breakpoints.onMediumScreen} {
		width: 150px;
		height: 15px;
		font-size: 13.5px;
		line-height: 15px;
	}
`;

const StyledResourceType = styled.div`
	height: 15px;
	width: 185px;
	font-family: 'PublicSans', Arial, sans-serif;
	font-style: normal;
	font-weight: ${typography.weights.regular};
	font-size: 12px;
	line-height: 14px;
	color: rgba(0, 0, 0, 0.786);
	${breakpoints.onMediumScreen} {
		height: 11px;
		width: 150px;
		font-size: 11px;
		line-height: 11px;
	}
`;

interface Props {
	resource: Resource;
	appendSelectedItem?: (reosurce: Resource) => void;
	removeSelectedItem?: (resource: Resource) => void;
	isItemSelected?: (resource: Resource) => boolean;
}

export function ResourceTile(props: Props) {
	const [isChecked, setChecked] = React.useState<boolean>(
		props.isItemSelected(props.resource)
	);
	const isDisabled = isMandatoryResource(props.resource);
	if (!props.resource) {
		return null;
	}
	const onCheckBoxChange = () => {
		if (isDisabled) {
			notifyError(t('Workspace:ftu_ui.mandatory_app_click_error_message'));
			return;
		}
		isChecked
			? props.removeSelectedItem(props.resource)
			: props.appendSelectedItem(props.resource);
		setChecked(!isChecked);
	};
	return (
		<StyledOuterContainer
			data-testid={`outer-container-${props.resource.id}`}
			onClick={() => onCheckBoxChange()}
		>
			<Checkbox type="checkbox" checked={isChecked} disabled={isDisabled}></Checkbox>
			<ResponsiveResourceIcon resource={props.resource} />
			<StyledResourceDetailContainer>
				<StyledResourceName title={props.resource.name}>
					{props.resource.name}
				</StyledResourceName>
				<StyledResourceType>{getResourceType(props.resource)}</StyledResourceType>
			</StyledResourceDetailContainer>
		</StyledOuterContainer>
	);
}
