import * as React from 'react';
import { resilientPromise } from '@citrite/workspace-ui-platform';
import { LoadingOverlaySecondary } from '../Components/PlatformLoadingOverlay';

type AsyncComponentType<P> = React.ComponentType<P> | ((props?: P) => JSX.Element);

export function makeAsync<T extends AsyncComponentType<any>>(
	fetchComponent: () => Promise<T>,
	/** This is rendered while the asset is downloading. */
	loadingComponent = <LoadingOverlaySecondary />,
	/** This is rendered if the asset fails to download. */
	errorComponent?: React.ComponentType
) {
	const Component = React.lazy(() =>
		resilientPromise(fetchComponent)
			.catch(e => {
				if (errorComponent) {
					return errorComponent;
				}
				throw e;
			})
			.then(component => ({ default: component }))
	);

	const AsyncComponent = (props: any) => {
		return (
			<React.Suspense fallback={loadingComponent}>
				<Component {...props} />
			</React.Suspense>
		);
	};

	type Props = T extends AsyncComponentType<infer P> ? P : unknown;
	return AsyncComponent as React.ComponentType<Props>;
}
