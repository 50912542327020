import * as React from 'react';
import { t } from '@citrite/translate';
import {
	LoadingOverlayPrimary,
	LoadingOverlaySecondary,
} from '@citrite/web-ui-component';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { createLeftNavRoute } from 'Router/ReactRoute';

export const AllDesktops = makeAsync(() => {
	return import(
		/* webpackChunkName: "AppsAndDesktops" */ './DesktopsWithQuickFilter'
	).then(m => m.DesktopsWithQuickFilter);
}, <FlagSwitchedLoadingOverlay />);

function FlagSwitchedLoadingOverlay() {
	const { workspaceConfiguration } = useConfigurationContext();
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav) ? (
		<LoadingOverlayPrimary />
	) : (
		<LoadingOverlaySecondary />
	);
}

export const allDesktopsRoute = createLeftNavRoute({
	component: AllDesktops,
	path: '/desktops/all',
	getNavLinkProps: () => ({
		title: t('Workspace:all_desktops'),
		performanceMark: 'AllDesktopsLink_Click',
	}),
});
