import React from 'react';
import { t } from '@citrite/translate';
import {
	ArrowLeftIcon,
	Button,
	SearchIcon,
	SectionTitle,
	Theme,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { MobileSearchModal } from 'App/Search/MobileSearchModal';

const StyledDiv = styled.div<{
	theme: Theme;
}>`
	${props => `
		background-color: ${props.theme.branding.styles.HeaderBackgroundColor};
		color: ${props.theme.branding.styles.HeaderTextColor}; 
	`}
	padding: 4px 14px;
	align-items: center;
	height: 50px;
`;

const StyledBackNavButtonDiv = styled.div`
	margin-top: 15px;
	margin-right: 10px;
	margin-bottom: 10px;
	align-items: center;
`;

const StyledLeftContainerDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	float: left;
`;

const StyledSearchContainerDiv = styled.div`
	float: right;
	align-items: center;
	margin-top: 15px;
`;

interface MobileFullScreenBannerProps extends WithModalsProps {
	subCategoryName: string;
	onBackButtonClick: () => void;
}

const _MobileFullScreenBanner = ({
	subCategoryName,
	onBackButtonClick,
	showModal,
}: MobileFullScreenBannerProps) => {
	const theme = useTheme();
	const onSearchClick = () => {
		showModal(<MobileSearchModal />);
	};

	return (
		<StyledDiv theme={theme} data-testid="subcategory-banner">
			<StyledLeftContainerDiv>
				<StyledBackNavButtonDiv>
					<Button.Wrapper
						aria-label={t('javascript:back')}
						onClick={onBackButtonClick}
						title={t('javascript:back')}
						data-testid="subcategory-back-button"
					>
						<ArrowLeftIcon size={20} />
					</Button.Wrapper>
				</StyledBackNavButtonDiv>
				<SectionTitle>{subCategoryName}</SectionTitle>
			</StyledLeftContainerDiv>
			<StyledSearchContainerDiv>
				<Button.Wrapper
					aria-label={t('javascript:search.search_workspace')}
					onClick={onSearchClick}
					title={t('javascript:search.search_workspace')}
					data-testid="subcategory-search-button"
				>
					<SearchIcon size={20} />
				</Button.Wrapper>
			</StyledSearchContainerDiv>
		</StyledDiv>
	);
};

export const MobileFullScreenBanner = withModals(_MobileFullScreenBanner);
