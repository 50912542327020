import React from 'react';
import { t } from '@citrite/translate';
import { errorMessageModal, Link } from '@citrite/web-ui-component';
import {
	StyledAppProtectionContainer,
	StyledAppProtectionErrorMessaging,
} from 'Environment/launchResource/AppProtectionError.styled';

export interface AppProtectionProps {
	title: string;
	message: string;
	linkMessage: string;
	linkUrl: string;
}

export function showAppProtectionErrorModal(props: AppProtectionProps) {
	errorMessageModal({
		title: props.title,
		confirmButtonText: t('Workspace:close'),
		message: (
			<>
				<StyledAppProtectionErrorMessaging>
					{props.message}
				</StyledAppProtectionErrorMessaging>
				<StyledAppProtectionContainer>
					<Link.Primary href={props.linkUrl} target="_blank">
						{props.linkMessage}
					</Link.Primary>
				</StyledAppProtectionContainer>
			</>
		),
	});
}
