import { getWithFullResponse } from '@citrite/http';
import { trackAnalyticsEvent } from 'analytics';
import { AxiosRequestConfig } from 'axios';
import { logError } from 'remoteLogging';
import { authChallengeHeader } from 'Environment';
import { reconnect } from 'Environment/reconnect';
import {
	CITRIX_IGNORE_AUTH_CHALLENGE,
	X_CLOUD_OFFLINE_REQUEST,
} from 'javascript/interceptors/Headers';
import { ShieldHealthCheckPayload } from 'Workspace/TelemetryEvents/shieldHealthCheck/ShieldHealthCheckPayload';

const requestConfiguration: AxiosRequestConfig = {
	headers: {
		[X_CLOUD_OFFLINE_REQUEST]: true,
		[CITRIX_IGNORE_AUTH_CHALLENGE]: true,
	},
	passthroughDSAuthChallenge: true,
};

export async function shouldRetryServerConnection(
	validateConnectionURL?: string
): Promise<Boolean> {
	if (!validateConnectionURL) {
		return false;
	}
	try {
		const res = await getWithFullResponse(validateConnectionURL, requestConfiguration);
		if (Number(res.status) === 200) {
			if (!res.headers[authChallengeHeader]) {
				trackAnalyticsEvent(ShieldHealthCheckPayload.createHealthCheckSuccessPayload());
				reconnect('OfflineBanner');
			}
			trackAnalyticsEvent(
				ShieldHealthCheckPayload.createHealthCheckUnauthenticatedPayload()
			);
			return false;
		} else {
			throw new Error(`Health Check Failed with status ${res.status}!`);
		}
	} catch (error) {
		trackAnalyticsEvent(ShieldHealthCheckPayload.createHealthCheckRetryPayload());
		logError(error);
		throw error;
	}
}
