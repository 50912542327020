import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
	section: {
		overflowY: 'hidden',
		transition: 'all .3s ease-out',
	},
	open: {
		transition: 'all .3s ease-in',
	},
});
