import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { t } from '@citrite/translate';
import { Tooltip } from '@citrite/web-ui-component';
import { useFormFactor } from 'App/Header/useFormFactor';
import { GenericTile } from 'App/Tile/GenericTile';
import {
	isResourceAvailable as checkResourceAvailability,
	Resource,
} from 'Workspace/ResourceProvider/resourceTypes';

const styles = StyleSheet.create({
	tooltip: {
		display: 'inline-block',
	},
});

interface Props {
	resource: Resource;
}

export function ResourceTileRenderer({ resource }: Props) {
	const isResourceAvailable = checkResourceAvailability(resource);
	const { isLargeFormFactor } = useFormFactor();
	const genericTile = (
		<GenericTile resource={resource} isResourceAvailable={isResourceAvailable} />
	);

	return isResourceAvailable || !isLargeFormFactor ? (
		genericTile
	) : (
		<Tooltip
			hoverTrigger={genericTile}
			isDisabled={isResourceAvailable}
			className={css(styles.tooltip)}
		>
			{resource.isdesktop
				? t('Workspace:desktop_unavailable')
				: t('Workspace:app_unavailable')}
		</Tooltip>
	);
}
