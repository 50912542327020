import * as React from 'react';
import { LoadableResourceContext, ResourceContext } from './Context';

export const useLoadableResourceContext = () => React.useContext(LoadableResourceContext);

export interface WithResourceContextProps {
	resourceContext: ResourceContext;
}

export function withResourceContext<Props>(
	Component: React.ComponentType<Props & WithResourceContextProps>
) {
	return (props: Omit<Props, keyof WithResourceContextProps>) => {
		const resourceContext = useLoadableResourceContext().value;
		return <Component {...(props as Props)} resourceContext={resourceContext} />;
	};
}
