import React from 'react';
import { SearchContent, SearchFilterOption } from 'App/Search/SearchContent';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

export type Props = {
	search?: string;
	searchFilterOption?: SearchFilterOption | string;
};

export function SearchResultsProvider(props: Props) {
	const [searchState, setSearchState] = React.useState({
		searchFilterOption: props.searchFilterOption,
		search: props.search,
	});

	React.useEffect(() => {
		setSearchState({
			searchFilterOption: props.searchFilterOption,
			search: props.search,
		});
	}, [props.searchFilterOption, props.search]);

	const resourceContext = useLoadableResourceContext().value;

	return (
		<SearchContent
			resourceContext={resourceContext}
			search={searchState.search}
			searchFilterOption={searchState.searchFilterOption}
		/>
	);
}
