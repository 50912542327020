import {
	breakpoints,
	fromThemeColors,
	layout,
	styleUtils,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const contentPaddingTop = 32;
export const contentPadding = 40;

export const StyledMainContent = styled.div`
	padding: ${contentPaddingTop}px ${contentPadding}px ${contentPadding}px;
	background: ${fromThemeColors('primaryAppBackground')};
	box-sizing: border-box;
	min-height: 100%;
	outline: none;
	:focus {
		border-radius: ${styleUtils.focusInset.borderRadius};
		box-shadow: ${styleUtils.focusInset.boxShadow};
	}
	${breakpoints.onMobile} {
		padding: ${layout.smallSpace};
	}
`;
