import {
	arrayBufferToBase64String,
	arrayBufferToHexString,
	arrayBufferToString,
	base64StringToArrayBuffer,
	getEncryptionKeyData,
	getEncryptionParams,
	stringToArrayBuffer,
} from 'Workspace/Encryption/encryptionHelpers';
import {
	CryptoSupportedCheck,
	Decryptor,
	Digester,
	EncryptedData,
	Encryptor,
} from './types';

const getCryptoApi = () => window.crypto.subtle;
export const subtleCryptoAvailable: CryptoSupportedCheck = () => !!window.crypto?.subtle;

async function getEncryptionKey(
	api: SubtleCrypto,
	encryptionKey: string
): Promise<CryptoKey> {
	const keyData = getEncryptionKeyData(encryptionKey);
	return api.importKey('raw', keyData, 'AES-GCM', false, ['encrypt', 'decrypt']);
}

export const encryptData: Encryptor = async (key, dataToEncrypt) => {
	const api = getCryptoApi();
	const cryptoKey = await getEncryptionKey(api, key);
	const encryptionParams = getEncryptionParams(window.crypto);
	const stringifiedDataToEncrypt = JSON.stringify(dataToEncrypt);
	const arrayBufferDataToEncrypt = stringToArrayBuffer(stringifiedDataToEncrypt);
	const encryptedArrayBuffer = await api.encrypt(
		encryptionParams,
		cryptoKey,
		arrayBufferDataToEncrypt
	);
	const encryptedBase64String = arrayBufferToBase64String(encryptedArrayBuffer);
	const storageSafeCipherText: EncryptedData = {
		data: encryptedBase64String,
		initialVector: Array.from(encryptionParams.iv as Uint8Array),
	};
	return storageSafeCipherText;
};

export const decryptData: Decryptor = async <T>(
	key: string,
	parsedJSON: EncryptedData
) => {
	const api = getCryptoApi();
	const arrayBuffer = base64StringToArrayBuffer(parsedJSON.data);
	const cryptoKey = await getEncryptionKey(api, key);
	const encryptionParams = getEncryptionParams(
		window.crypto,
		new Uint8Array(parsedJSON.initialVector)
	);
	const decryptedArrayBuffer = await api.decrypt(
		encryptionParams,
		cryptoKey,
		arrayBuffer
	);
	const result = arrayBufferToString(decryptedArrayBuffer);
	return JSON.parse(result) as T;
};

export const digestString: Digester = async input => {
	const api = getCryptoApi();
	const encoder = new TextEncoder();
	const dataToDigest = encoder.encode(input);
	const digestBuffer = await api.digest('SHA-256', dataToDigest);
	return arrayBufferToHexString(digestBuffer);
};
