import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	Button,
	CogIcon,
	CogSolidIcon,
	MoreActionIcon,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { useLocation } from 'react-router-dom';
import { useLeftNav } from 'App/LeftNavigation/useLeftNav';
import { environment } from 'Environment';
import { useUserContext } from 'Workspace/UserContext';
import { BottomNavItem } from './BottomNavItem';
import { LinkIcon } from './LinkIcon';
import { OverflowMenu } from './OverflowMenu';
import { styles } from './styles';

const maxNoOfItemsInBottomNav = 5;
export interface BottomNavProps extends WithModalsProps {
	refreshPage: () => void;
}

function _BottomNavBar(props: BottomNavProps) {
	const location = useLocation();
	const links = [...useLeftNav().links.filter(c => c.title)];
	const isDaasVisionEnabled = useUserContext().isDaasVisionEnabled;

	if (!isDaasVisionEnabled) {
		links.push({
			title: t('Workspace:settings'),
			icon: CogIcon,
			selectedIcon: CogSolidIcon,
			onClick: showSettings,
		});
	}

	const visibleLinks = links.slice(
		0,
		links.length > maxNoOfItemsInBottomNav ? 4 : links.length
	);
	const overflowNavLinks = links.slice(maxNoOfItemsInBottomNav - 1);
	const isOverFlowMenuRequired = overflowNavLinks.length > 1;

	if (visibleLinks.length > 1) {
		return (
			<div className={css(styles.tabBar)}>
				{visibleLinks.map(
					(item, index) =>
						index < maxNoOfItemsInBottomNav && (
							<BottomNavItem
								key={`${index}${item.href}`}
								link={item}
								currentPath={location.pathname}
							/>
						)
				)}
				{isOverFlowMenuRequired && (
					<Button.Wrapper
						className={css(styles.linkActive)}
						onClick={() =>
							props.showModal(
								<OverflowMenu links={overflowNavLinks} closeOnOverlayClick />
							)
						}
					>
						<LinkIcon icon={MoreActionIcon} />
					</Button.Wrapper>
				)}
			</div>
		);
	} else {
		return null;
	}
}

function showSettings(e: React.MouseEvent<HTMLButtonElement>) {
	e.stopPropagation();
	const { left, right, top, bottom } = e.currentTarget.getBoundingClientRect();
	environment.showSettings(
		left,
		top,
		right,
		bottom,
		window.innerWidth,
		window.innerHeight
	);
}
export const BottomNavBar = withModals(_BottomNavBar);
