import { StyleSheet } from 'aphrodite';
import { layout, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	page: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		maxWidth: '480px',
		margin: '120px auto',
	},
	errorTitle: {
		fontSize: typography.sizes.xl,
		fontWeight: typography.weights.semiBold,
		marginBottom: layout.smallSpace,
		marginTop: layout.mediumSpace,
	},
	extraMessaging: {
		margin: layout.largeSpace,
	},
});
