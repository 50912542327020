type BaseEvent = {
	type: string;
	payload: unknown;
};

type SuccessEvent = {
	type: 'workspace/cvad/html5launch/icadownload/success';
	payload: {
		icaData: object;
	};
};

type ErrorEvent = {
	type: 'workspace/cvad/html5launch/icadownload/error';
	payload: {
		errorMessage: string;
	};
};

type RetryEvent = {
	type: 'workspace/cvad/html5launch/icadownload/retry';
	payload: null;
};

type InitializeEvent = {
	type: 'workspace/cvad/html5launch/icadownload/init';
	payload: {
		version: number;
	};
};

type SentEvents = SuccessEvent | ErrorEvent | RetryEvent;

export class HTML5Client {
	private usePostMessage = false;
	private errorId: string;

	public constructor(private readonly targetWindow: Window & Record<string, any>) {}

	public startListening() {
		window.addEventListener('message', this.onMessage);
	}

	public stopListening() {
		window.removeEventListener('message', this.onMessage);
	}

	public sendSuccess(icaData: object, launchId: number) {
		if (this.usePostMessage) {
			this.sendEvent({
				type: 'workspace/cvad/html5launch/icadownload/success',
				payload: { icaData },
			});
		} else {
			window.html5LaunchData ??= {};
			window.html5LaunchData[launchId] = icaData;
			if (this.targetWindow) {
				this.targetWindow.icaDwldComplete = true;
			}
		}
	}

	/**
	 * Errors can be triggered in a few different places in the launch flow, but an explicit
	 * error id should be captured as soon as it's known for maximum accuracy -- the error
	 * may be wrapped up and thrown later. The error id is passed to the html5 client so
	 * it can be surfaced in collected logs.
	 */
	public setErrorId(errorId: string) {
		this.errorId = errorId;
	}

	public sendError(error: unknown) {
		if (this.usePostMessage) {
			let errorMessage: string;
			if (this.errorId) {
				errorMessage = this.errorId;
			} else if (error instanceof Error) {
				errorMessage = `${error.name}: ${error.message}`;
			} else {
				errorMessage = 'ICADownloadError';
			}
			this.sendEvent({
				type: 'workspace/cvad/html5launch/icadownload/error',
				payload: { errorMessage },
			});
		} else {
			this.targetWindow?.close();
		}
	}

	/**
	 * With a retry event we can notify the HTML5 client that the cloud broker is online
	 * and responding, but that the VDA needs more time to spin up. The HTML5 client
	 * should then cancel its own launch flow timeout and delegate to the broker's
	 * timeout, which is configured by the customer's admin. This does not _completely_
	 * remove timeouts, as WSP still enforces an internal 60-second timeout for each
	 * outgoing call. After a retry, it's assumed an eventual error or success will
	 * follow
	 */
	public sendRetry() {
		if (this.usePostMessage) {
			this.sendEvent({
				type: 'workspace/cvad/html5launch/icadownload/retry',
				payload: null,
			});
		}
	}

	private onMessage = (message: unknown) => {
		if (this.isInitializeEventFromLaunchWindow(message)) {
			this.usePostMessage = true;
		}
	};

	private isInitializeEventFromLaunchWindow(event: unknown): event is InitializeEvent {
		if (event instanceof MessageEvent && event.origin === location.origin) {
			const { data } = event;
			return (
				data?.type === 'workspace/cvad/html5launch/icadownload/init' &&
				typeof data?.payload?.version === 'number'
			);
		}

		return false;
	}

	private sendEvent<E extends BaseEvent & SentEvents>(event: E) {
		this.targetWindow.postMessage(event, location.origin);
	}
}
