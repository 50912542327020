import { t } from '@citrite/translate';
import {
	plainConfirmModal,
	successConfirmModal,
	warningConfirmModal,
} from '@citrite/web-ui-component';
import {
	DesktopAction,
	LaunchAction,
	MachineAction,
	Session,
	SessionAction,
	SessionType,
} from 'App/Activity/ResourceManagerContext';

const localeActionNameMap = {
	[SessionAction.LOGOUT]: 'logout',
	[SessionAction.DISCONNECT]: 'disconnect',
	[MachineAction.SHUTDOWN]: 'shutdown',
	[MachineAction.HIBERNATE]: 'hibernate',
	[MachineAction.POWEROFF]: 'poweroff',
	[LaunchAction.TRANSFER]: 'transfer',
};

const localeActionStringsMap = {
	[SessionAction.LOGOUT]: 'Workspace:log_out_desktop_modal',
	[SessionAction.DISCONNECT]: 'Workspace:disconnect_desktop_modal',
	[MachineAction.SHUTDOWN]: 'Workspace:shutdown_desktop_modal',
	[MachineAction.HIBERNATE]: 'Workspace:hibernate_desktop_modal',
	[MachineAction.POWEROFF]: 'Workspace:poweroff_desktop_modal',
	[LaunchAction.TRANSFER]: 'Workspace:session_handoff_desktop_modal',
};

const getLocaleString = (
	action: DesktopAction,
	session: Session,
	type: 'title' | 'description'
) => {
	const desktopName = session.applications[0]?.name;
	const key = `${localeActionStringsMap[action]}.${type}`;
	const sessionName =
		session.userSessionType === SessionType.DESKTOP
			? { desktopName }
			: { desktopName: session?.machineData?.machineName };
	return t(key, sessionName);
};

const getLocaleText = (action: DesktopAction, session: Session) => {
	const confirmButtonText = t(
		`${localeActionStringsMap[action]}.${localeActionNameMap[action]}`
	);
	const description = getLocaleString(action, session, 'description');
	const note = t(`${localeActionStringsMap[action]}.note`);
	const title = getLocaleString(action, session, 'title');
	return { confirmButtonText, description, note, title };
};

export const getConfirmationDetails = (action: DesktopAction, session: Session) => {
	const localeTexts: { [key: string]: string } = {};
	let confirmModal = warningConfirmModal;

	switch (action) {
		case SessionAction.LOGOUT:
		case MachineAction.POWEROFF:
		case MachineAction.SHUTDOWN:
			break;
		case SessionAction.DISCONNECT:
			confirmModal = plainConfirmModal;
			break;
		case MachineAction.HIBERNATE:
			localeTexts.description = getLocaleString(action, session, 'description');
			confirmModal = successConfirmModal;
			break;
		case LaunchAction.TRANSFER:
			localeTexts.title = t('Workspace:session_handoff_desktop_modal.title');
			localeTexts.note = '';
			localeTexts.description = getLocaleString(action, session, 'description');
			break;
		default:
			return undefined;
	}
	return { confirmModal, ...getLocaleText(action, session), ...localeTexts };
};
