import { ResourceContext } from 'Workspace/ResourceProvider';
import {
	InstalledResourceData,
	localAppResourceType,
	Resource,
	SiriRegisteredResourceData,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

export function processInstalledApps(
	resources: Resource[],
	installedResourceData: InstalledResourceData
): Partial<ResourceContext> {
	return {
		resources: resources.map(r => ({
			...r,
			subscriptionstatus:
				r.type !== localAppResourceType &&
				installedResourceData.onlyInstalledAppsAreSubscribed &&
				(!installedResourceData.resources ||
					!installedResourceData.resources.some(resource => resource.appId === r.id))
					? subscriptionStatus.unsubscribed
					: r.subscriptionstatus,
		})),
		installedResources: installedResourceData.resources || [],
	};
}

export function processSiriConfiguredApps(
	resources: Resource[],
	installedResourceData: SiriRegisteredResourceData
): Partial<ResourceContext> {
	return {
		resources: resources.map(r => ({
			...r,
			isResourceRegisteredToSiri: !(
				!installedResourceData.resources ||
				!installedResourceData.resources.some(resource => resource.appId === r.id)
			),
		})),
		installedResources: installedResourceData.resources || [],
	};
}
