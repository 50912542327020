import {
	DependencyContainer,
	PlatformDependencySet,
} from '@citrite/workspace-ui-platform';
import { InternalPlatformDependencySet } from 'IntegrationDependencies/InternalDependencies';
import { Container } from 'Workspace/DependencyManagement/Container';

export type PlatformContainer = DependencyContainer<
	PlatformDependencySet | InternalPlatformDependencySet
>;

export const container: PlatformContainer = new Container();
