import { parse } from 'App/AotTrace/AotParser';
import { environment } from 'Environment';
import { traceManager } from 'Tracing';

export enum AotLogLevel {
	ERROR = 'error',
	WARN = 'warning',
	INFO = 'info',
	FATAL = 'fatal',
}

export type AotType = {
	category?: string;
	traceMessage: string;
	logLevel?: AotLogLevel;
};

function log(
	level: AotLogLevel,
	category: string,
	message: string,
	...params: (string | number)[]
): void {
	const _traceMessage = params?.length > 0 ? parse(message, ...params) : message;
	const payload: AotType = {
		category,
		traceMessage: _traceMessage,
		logLevel: level,
	};
	traceManager.logAOT(payload);
	environment.aotTraceMessage(payload);
}

export const _aot = {
	info: (category: string, message: string, ...params: (string | number)[]) =>
		log(AotLogLevel.INFO, category, message, ...params),
	warn: (category: string, message: string, ...params: (string | number)[]) =>
		log(AotLogLevel.WARN, category, message, ...params),
	error: (category: string, message: string, ...params: (string | number)[]) =>
		log(AotLogLevel.ERROR, category, message, ...params),
	fatal: (category: string, message: string, ...params: (string | number)[]) =>
		log(AotLogLevel.FATAL, category, message, ...params),
};
