import { RequestInterceptors } from '@citrite/http';
import { environment } from 'Environment';

export const interceptor: RequestInterceptors = {
	prepareRequest: config => {
		return {
			...config,
			adapter: environment.ajax.bind(environment),
		};
	},
};
