import * as React from 'react';
import { Configuration } from 'Configuration/Context';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';

export type WithWorkspaceConfiguration = {
	workspaceConfiguration: Configuration['workspaceConfiguration'];
};

export function withWorkspaceConfiguration<Props>(
	Component: React.ComponentType<Props & WithWorkspaceConfiguration>
) {
	return (props: Omit<Props, keyof WithWorkspaceConfiguration>) => {
		const configurationContext = useConfigurationContext();
		const { workspaceConfiguration } = configurationContext;
		return (
			<Component {...(props as Props)} workspaceConfiguration={workspaceConfiguration} />
		);
	};
}
