import { Bar, BarHeight, BarSpacing } from './Bar';
import { Circle } from './Circle';
import { Rectangle } from './Rectangle';

export const SkeletonLoaderShapes = {
	Bar,
	BarSpacing,
	BarHeight,
	Rectangle,
	Circle,
};
