import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, MainRegion } from '@citrite/web-ui-component';
import { getNativeClientName } from 'Components/Detection/nativeClientName';
import { StyledBlock, StyledBlockTrailingMargin, StyledH1 } from './Detection.styled';

interface Props {
	useHtml5(): void;
	showDetect(): void;
	isHtml5Enabled: boolean;
}

export function Welcome(props: Props) {
	return (
		<MainRegion>
			<StyledH1>{t('Workspace:welcome_to_workspace')}</StyledH1>
			<StyledBlockTrailingMargin>
				{t('Workspace:for_best_experience', {
					nativeClient: <strong>{getNativeClientName()}</strong>,
				})}
			</StyledBlockTrailingMargin>
			<Button.Primary
				data-testid="detection-detect_workspace"
				onClick={props.showDetect}
				style={{ width: '100%' }}
			>
				{t('Workspace:detect_workspace')}
			</Button.Primary>
			{props.isHtml5Enabled && (
				<>
					<StyledBlock>{t('Workspace:do_not_detect')}</StyledBlock>
					<Button.TextPrimary
						data-testid="detection-use_browser"
						onClick={props.useHtml5}
					>
						{t('Workspace:use_browser')}
					</Button.TextPrimary>
				</>
			)}
		</MainRegion>
	);
}
