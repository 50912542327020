import React from 'react';
import { t } from '@citrite/translate';
import { DisconnectedIcon, layout, Tooltip } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';

const smallFormDisconnectIconSize = 16;
const largeFormDisconnectIconSize = 16;

const StyledActivityIcon = styled.div`
	display: flex;
	align-items: center;
	margin-right: ${layout.mediumSpace};
	margin-left: ${layout.mediumSpace};
`;

const buttonStyle: React.CSSProperties = {
	lineHeight: 0,
	display: 'inline-block',
};

export const ActivityDisconnectedIcon = () => {
	const { isSmallFormFactor } = useFormFactor();
	const disconnectIconSize = isSmallFormFactor
		? smallFormDisconnectIconSize
		: largeFormDisconnectIconSize;
	return (
		<Tooltip
			hoverTrigger={
				<StyledActivityIcon>
					<DisconnectedIcon
						size={disconnectIconSize}
						style={buttonStyle}
						data-testid="disconnected-icon"
					/>
				</StyledActivityIcon>
			}
		>
			<>{t('Workspace:activity_manager.disconnected')}</>
		</Tooltip>
	);
};
