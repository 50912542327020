import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { EmptyDocumentIcon, EmptyState } from '@citrite/web-ui-component';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './styles';

export interface Props {
	resource: Resource;
}

export const AppDescription: React.SFC<Props> = props => {
	return props.resource.description ? (
		<div className={css(styles.details)}>{props.resource.description}</div>
	) : (
		<div className={css(styles.details, styles.emptyDetails)}>
			<EmptyState icon={EmptyDocumentIcon}>{t('Workspace:empty_app_details')}</EmptyState>
		</div>
	);
};
