import * as React from 'react';
import { css } from 'aphrodite';
import { MainRegion, Modal, ModalProps } from '@citrite/web-ui-component';
import { OpenAppButton } from 'Components/ResourceTile/ViewDetails/OpenAppButton';
import { RemoveAppButton } from 'Components/ResourceTile/ViewDetails/RemoveAppButton/RemoveAppButton';
import { UpdateAppButton } from 'Components/ResourceTile/ViewDetails/UpdateAppButton';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppDescription } from './AppDescription';
import { Header } from './Header';
import { styles } from './styles';

export interface Props extends ModalProps {
	resource: Resource;
}

enum tabs {
	recentFiles,
	details,
}

interface State {
	activeTab?: tabs;
}

export class ViewAppDetails extends React.Component<Props, State> {
	public state: State = {
		activeTab: tabs.recentFiles,
	};

	public render() {
		return (
			<Modal {...this.props}>
				<Header {...this.props} />
				<MainRegion className={css(styles.body)}>
					<div className={css(styles.navBar)}>
						<UpdateAppButton resource={this.props.resource} />
						<RemoveAppButton resource={this.props.resource} />
						<OpenAppButton resource={this.props.resource} />
					</div>
					<div className={css(styles.content)}>
						<AppDescription resource={this.props.resource} />
					</div>
				</MainRegion>
			</Modal>
		);
	}
}
