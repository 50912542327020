import { Layout } from './ComposablePage/Layout';

/**
 * Launcher layout for non-IWS users
 */
export const layout01: Layout = {
	id: 'layout01',
	grid: { rows: [{ columns: [{ columnSpan: 12 }] }] },
};

/**
 * Feed-centric layout for IWS users
 */
export const layout02: Layout = {
	id: 'layout02',
	grid: {
		rows: [
			{
				columns: [{ columnSpan: 8 }, { columnSpan: 4 }],
			},
		],
	},
};

//This layout is only used to display widgets on mobile.
export const mobileDefaultOneColumnLayout: Layout = {
	id: 'mobile',
	grid: {
		rows: [
			{
				columns: [{ columnSpan: 12 }],
			},
		],
	},
};
