import { createCapability } from '../capability';

export interface FeatureTitle {
	id: string;
	title: string;
}

/**
 * Adds a new data source for Workspace integration detail.
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.integrationDetailProvider}
 *
 * @category Integration Capability
 */
export interface IntegrationDetailCapability {
	getActionsByIntegrationId(integrationId: string): Promise<FeatureTitle[]>;
	getNotificationTemplatesByIntegrationId(integrationId: string): Promise<FeatureTitle[]>;
}

export const IntegrationDetailCapabilityToken =
	createCapability<IntegrationDetailCapability>('wsui_integration_detail_capability');
