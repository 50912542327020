import { trackAnalyticsEvent } from 'analytics';
import { isHomeScreen } from 'App/Screen/util';
import { launchResource } from 'Environment/launchResource';
import {
	isDesktopBrowser,
	isLinux,
	isMac,
	isWindowsPlatform,
} from 'Environment/launchResource/device';
import { LaunchParams } from 'Environment/launchResource/launchResource';
import { LaunchInCEBMode } from 'Workspace/CitrixEnterpriseBrowser';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { DesktopsUserEventPayload } from 'Workspace/TelemetryEvents/desktopsEvent/DesktopsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { UserDetails } from 'Workspace/UserContext';

const getEventSite = () => (isHomeScreen() ? EventSite.Recent : EventSite.Tile);

const captureAnalytics = (resource: Resource) => {
	const site = getEventSite();
	const eventPayload = resource.isdesktop
		? DesktopsUserEventPayload.launch(site, resource.type)
		: AppsUserEventPayload.launch(site, resource.type);
	trackAnalyticsEvent(eventPayload);
};

export const launchGenericResource = ({
	resource,
	resourceContext,
	workspaceConfiguration,
}: LaunchParams) => {
	captureAnalytics(resource);
	launchResource({
		resource,
		resourceContext,
		workspaceConfiguration,
	});
};

export const shouldPromptLaunchInCEBPreference = (
	useCitrixEnterpriseBrowser: LaunchInCEBMode
) => {
	const hasAdminConfiguredToLaunchInCEB =
		useCitrixEnterpriseBrowser === LaunchInCEBMode.Always ||
		useCitrixEnterpriseBrowser === LaunchInCEBMode.WithFallback;
	const isMacOrWindowsOrLinuxPlatform = isMac() || isWindowsPlatform() || isLinux();
	const isMacOrWindowsBrowser = isDesktopBrowser() && isMacOrWindowsOrLinuxPlatform;
	return hasAdminConfiguredToLaunchInCEB && isMacOrWindowsBrowser;
};

export const getUserProfileIdentity = ({
	userId,
	authAlias,
	userDisplayName,
}: UserDetails) => {
	switch (true) {
		case !!userId:
			return { userId };
		case !!authAlias:
			return { authAlias };
		case !!userDisplayName:
			return { userName: userDisplayName };
		default:
			return {};
	}
};
