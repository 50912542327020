import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType } from 'Environment/getClientType';
import {
	isChrome,
	isCitrixChromeApp,
	isDesktopBrowser,
	isEdge,
	isMac,
	isWindowsPlatform,
} from 'Environment/launchResource/device';
import { shouldSupportAppProtectionOnPrem } from 'Workspace/AppContext/onpremAppProtectionUtils';

/*
	https://issues.citrite.net/browse/WSUI-6909
	
	Browser Based Hybrid App Protection is current supported only 
	on : "Windows and Mac for app launched via BXTN only"  	

	On ChromeBook , the UserAgent for Citrix Worskpace Chrome App (from chrome store)
	this one CWA-ChromestoreApp: 
	https://chrome.google.com/webstore/detail/citrix-workspace/haiffjcadagjlijoggckpgfnoeiflnem?hl=en

	has 'Windows' instead of 'CrOS'

	Addtionally , even if we are on Windows we need to skip the CWA-ChromestoreApp  for the following reasons:
	App Protection is not supported by any launch other than BXTN. The Chrome App (on Chrome Store) does not use BXTN 
	for launch hybrid apps. So despite being running inside a chrome browser , we disable it from sending App protections.

*/
const isAppProtectionSupportedPlatform = () =>
	isDesktopBrowser() && (isWindowsPlatform() || isMac());
const isAppProtectionSupportedBrowser = () =>
	(isChrome() || isEdge()) && !isCitrixChromeApp();

export function clientHasAppProtectionCapability(
	workspaceConfiguration: WorkspaceConfiguration,
	isEnumeration = false
): boolean {
	//Our feature flag mechanism takes care of the onprem specific flag as well.
	if (!hasFeatureCanary(workspaceConfiguration, FeatureFlag.BrowserAppProtection)) {
		return false;
	}

	if (IS_ON_PREM) {
		return shouldSupportAppProtectionOnPrem(workspaceConfiguration);
	}

	if (isEnumeration) {
		return shouldSupportAppProtectionOnCloudForEnumerationAPI();
	}

	return shouldSupportAppProtectionOnCloud();
}

function shouldSupportAppProtectionOnCloud() {
	return getClientType() === clientType.browserextension;
}

function shouldSupportAppProtectionOnCloudForEnumerationAPI() {
	return isAppProtectionSupportedPlatform() && isAppProtectionSupportedBrowser();
}
