import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

/**
 * @category Platform Dependency
 */
export interface Performance {
	/**
	 * Creates a timestamp in the browser's performance entry buffer with the given name.
	 * Each entry will only be created once.
	 */
	mark(markName: string): void;
	/**
	 * Creates a measure in the browser's performance entry buffer with the given name
	 * and publishes it to the monitoring back end.
	 */
	measure(spanName: string, startMark: string, endMark?: string): void;
}

/**
 * @category Platform Dependency
 */
export const performance: Performance = {
	mark: (...args) => resolveFromGlobal(PlatformDependencies.Performance).mark(...args),
	measure: (...args) =>
		resolveFromGlobal(PlatformDependencies.Performance).measure(...args),
};
