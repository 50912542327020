/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { breakpoints, palette, styleUtils } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { minimumRecommendedResources } from 'Components/FTU/constants';
import { FilterContainer } from 'Components/FTU/FilterContainer';
import { baseTabs } from 'Components/FTU/Navigation';
import { SearchList } from 'Components/FTU/SearchList';
import {
	countRecommendedResources,
	isRecommendedFTUResource,
} from 'Components/FTU/Utils';
import { fuzzySearch } from 'Components/ItemSearch/fuzzySearch';
import { jaroWrinkerScoreThreshold } from 'Components/ItemSearch/searchResourcesByNameAndDescription';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const StyledPersonalizationContent = styled.div`
	flex-direction: column;
	display: flex;
	gap: 2px;
	background: ${palette.white};
	justify-content: space-between;
	border-radius: 16px;
	width: 580px;
	align-items: left;
	padding: 15px 15px 2px 15px;
	border: 1px solid ${palette.grey500};
	:hover {
		box-shadow: ${styleUtils.shadow2.boxShadow};
	}
	${breakpoints.onMediumScreen} {
		:hover {
			box-shadow: none;
		}
		width: 100%;
		align-items: center;
		border: 0px;
		gap: 0px;
	}
`;
interface Props {
	selectedItems: Resource[];
	setSelectedItems: (resources: Resource[]) => void;
}

export function PersonalizationContent(props: Props) {
	const resourceContext = useLoadableResourceContext();
	const { resources } = resourceContext.value;
	const [filteredItems, setFilteredItems] = useState<Resource[]>(resources);
	const [input, setInput] = useState<string>('');
	const shouldShowNavigation =
		countRecommendedResources(resources) >= minimumRecommendedResources;
	const [activeTab, setActiveTab] = useState(
		shouldShowNavigation ? baseTabs.Recommended : baseTabs.All
	);

	useEffect(() => {
		setFilteredItems(
			resources
				.filter(resource => {
					if (activeTab === baseTabs.Recommended) {
						return isRecommendedFTUResource(resource);
					} else {
						return true;
					}
				})
				.filter(resource => {
					if (input.length >= 1) {
						return (
							resource.name.toLowerCase().search(input.toLowerCase()) !== -1 ||
							fuzzySearch(resource.name.toLowerCase(), input.toLowerCase()) >
								jaroWrinkerScoreThreshold
						);
					} else {
						return true;
					}
				})
		);
	}, [input, activeTab]);

	return (
		<StyledPersonalizationContent>
			<FilterContainer
				input={input}
				setInput={setInput}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				shouldShowNavigation={shouldShowNavigation}
			/>
			<SearchList
				selectedResources={props.selectedItems}
				setSelectedResources={props.setSelectedItems}
				resourceList={filteredItems}
			/>
		</StyledPersonalizationContent>
	);
}
