import { t as translationFunction } from '@citrite/translate';
import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

/**
 * @category Platform Dependency
 */
export type TranslationFunction = typeof translationFunction;

/**
 * @category Platform Dependency
 */
export const t: TranslationFunction = (...args: Parameters<TranslationFunction>) =>
	resolveFromGlobal(PlatformDependencies.TranslationFunction)(...args);
