import * as React from 'react';
import { AccountBranding, createWorkspaceBranding } from 'Configuration/branding';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useUserContext } from '../Workspace/UserContext';

export const useBranding = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { userBranding } = useUserContext();
	return React.useMemo(
		() => createWorkspaceBranding(userBranding.value?.branding, workspaceConfiguration),
		[userBranding, workspaceConfiguration]
	);
};

export type WithBrandingProps = {
	branding: AccountBranding;
};

export function withBranding<Props extends WithBrandingProps>(
	Component: React.ComponentType<Props>
): React.FC<Omit<Props, keyof WithBrandingProps>> {
	return props => {
		const branding = useBranding();
		return <Component {...(props as Props)} branding={branding} />;
	};
}
