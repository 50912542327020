import { AnalyticsEvent, Metadata } from 'analytics';
import { GACSV2EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_GLOBAL_APP_CONFIG_V2';

const preparePayload = (type: GACSV2EventType, metadata?: Metadata): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});

export const GlobalAppConfigV2Payload = {
	isRequestSuccessful: (isSuccess: boolean): AnalyticsEvent =>
		preparePayload(GACSV2EventType.GACSV2IsSuccess, { isSuccess }),
	isGACSV2Enabled: (isEnabled: boolean): AnalyticsEvent =>
		preparePayload(GACSV2EventType.GACSV2Enabled, { isEnabled }),
	getLatency: (latency: number): AnalyticsEvent =>
		preparePayload(GACSV2EventType.GACSV2Latency, { latency }),
	isTimedOut: (isTimedOut: boolean): AnalyticsEvent =>
		preparePayload(GACSV2EventType.GACSV2Timeout, { isTimedOut }),
};
