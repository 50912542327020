import { createClient, RequestInterceptors } from '@citrite/http';
import URI from 'urijs';
import { interceptor as nativeXhrInterceptor } from 'javascript/interceptors/nativeXhr';
import { interceptor as workspacePlatformInterceptor } from 'javascript/interceptors/workspacePlatform';

export function createForNative() {
	const client = createClient();
	client.addRequestInterceptor(workspacePlatformInterceptor);
	client.addRequestInterceptor(nativeXhrInterceptor);
	return client;
}

export function createForBrowser(dsauthProxyUrl: string) {
	const interceptor = createInterceptor(dsauthProxyUrl);
	const client = createClient();
	client.addRequestInterceptor(workspacePlatformInterceptor);
	client.addRequestInterceptor(interceptor);
	return client;
}

function createInterceptor(dsauthProxyUrl: string): RequestInterceptors {
	return {
		prepareRequest: config => {
			const { url, headers, ...preserve } = config;
			const uri = new URI(dsauthProxyUrl);
			uri.addSearch({
				destination: url,
			});
			return {
				...preserve,
				url: uri.toString(),
				headers: {
					...headers,
					'Citrix-WSP-Proxy-URL': url,
				},
			};
		},
	};
}
