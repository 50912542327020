import { layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledPage = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 480px;
	margin: 120px auto;
`;

export const StyledErrorTitle = styled.div`
	font-size: ${typography.sizes.xl};
	font-weight: ${typography.weights.semiBold};
	margin-bottom: ${layout.smallSpace};
	margin-top: ${layout.mediumSpace};
`;

export const StyledExtraMessaging = styled.div`
	margin: ${layout.largeSpace};
	font-weight: ${typography.weights.medium};
`;

export const StyledStoreTitle = styled.div`
	font-weight: ${typography.weights.semiBold};
`;

export const StyledStoreFrontFallbackContainer = styled.div`
	margin-bottom: ${layout.mediumSpace};
`;

export const StyledStoreFrontFallbackErrorMessaging = styled.div`
	margin-bottom: ${layout.mediumSpace};
`;
