import { trackAnalyticsEvent } from 'analytics';
import { environment } from 'Environment';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const logoffLocally = (sessionId: string) => {
	const eventName = {
		id: 'activities',
		type: 'action/miniui/running/resource/logoff',
		payload: { sessionid: sessionId },
	};
	environment.sendEventToNative(eventName);
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerLogoutEvent(false));
};
