import { createCapability } from '../capability';

/**
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.feedCardExtensions.viewDetails}
 *
 * @category Integration Capability
 */
export interface FeedCardDetailsCapability {
	onCardClick(params: FeedCardProps): void;
	actionsComponent?: React.ComponentType<FeedCardActionsProps>;
}

type FeedCardActionType = 'OPEN_URL' | 'POST_CALLBACK' | 'MAS_CALLBACK';
export interface FeedCardAction {
	url: string;
	getLabel(): string;
	getType(): FeedCardActionType;
	postPayload?: string;
}

export interface FeedCardActionOutOfSyncValues {
	[componentId: string]: string | null;
}

export interface FeedCardActionsProps {
	actions: FeedCardAction[];
	appTitle: string;
	onActionExecuted: (shouldRemoveCard: boolean) => void;
	onActionOutOfSync: (outOfSyncValues: FeedCardActionOutOfSyncValues) => void;
	trackActionRunEvent: (
		actionName: string,
		startedAt: number,
		finishedAt: number,
		result: 'SUCCESS' | 'FAILURE'
	) => void;
}

export interface FeedCardProps<TDetails = any, TSource = any> {
	iconUrl: string;
	title: string;
	source?: TSource;
	detail?: TDetails;
	cardId?: string;
	messageUuid?: string;
	displayTime?: string;
	removeCard?: () => void;
	appTitle: string;
	onDismiss: () => void;
	trackDisplayBladeEvent: () => void;
}

export interface ExecuteFeedCardActionParams {
	feedCardId: string;
	sourceOrigin: string;
	action: {
		url: string;
	};
}

/**
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.feedCardExtensions.executeFeedCardAction}
 *
 * @category Integration Capability
 */
export interface ExecuteFeedCardActionCapability {
	executeFeedCardAction(params: ExecuteFeedCardActionParams): Promise<void>;
}

const feedCardExtensions = 'wsui_feed_card_extensions';

export type FeedCardExtensionCapabilityMetadata =
	| {
			feedCardSourceOrigin: string;
	  }
	| {
			feedCardDetailType: string;
			/**
			 * Appilcable only when feedCardDetailType === 'MFE', determines a specific MFE renderer to be used
			 *
			 * @example 'onedrive'
			 */
			mfeRenderer?: string;
	  };

export const FeedCardDetailsCapabilityToken = createCapability<
	FeedCardDetailsCapability,
	FeedCardExtensionCapabilityMetadata
>(`${feedCardExtensions}/details`);

export const ExecuteFeedCardActionCapabilityToken = createCapability<
	ExecuteFeedCardActionCapability,
	FeedCardExtensionCapabilityMetadata
>(`${feedCardExtensions}/executeFeedCardAction`);
