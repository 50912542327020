import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledAppProtectionContainer = styled.div`
	margin-bottom: ${layout.mediumSpace};
`;

export const StyledAppProtectionErrorMessaging = styled.div`
	margin-bottom: ${layout.mediumSpace};
`;
