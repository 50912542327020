import * as React from 'react';
import { ConnectionError } from 'Components/ConnectionError/ConnectionError';
import { Routes } from './Routes';
import { ScrollToTop } from './ScrollToTop';

export function Router() {
	const routes = (
		<ScrollToTop>
			<Routes />
		</ScrollToTop>
	);
	return <ConnectionError>{routes}</ConnectionError>;
}
