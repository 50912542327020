import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, Link, MainRegion } from '@citrite/web-ui-component';
import { browser } from '@citrite/workspace-ui-platform';
import {
	StyledBlock,
	StyledH1,
	StyledJustifiedBlock,
} from 'Components/Detection/Detection.styled';

interface Props {
	detect(): void;
	alreadyInstalled(): void;
	getDownloadUrl(): string;
	useHtml5(): void;
	isHtml5Enabled: boolean;
}

export class Validate extends React.Component<Props> {
	public render() {
		return (
			<MainRegion>
				<StyledH1>{t('Workspace:wait_while_confirm_install')}</StyledH1>
				<StyledBlock>
					<p>
						{browser.isFirefox()
							? t('Workspace:if_already_installed_firefox', {
									launcher: <strong>{t('Workspace:citrix_workspace_launcher')}</strong>,
									openLink: <strong>{t('Workspace:open_link')}</strong>,
							  })
							: t('Workspace:if_was_installed', {
									launchApplication: <strong>{t('Workspace:launch_application')}</strong>,
							  })}
					</p>
					<p>
						{browser.isFirefox()
							? t('Workspace:if_no_window_detect_again_firefox', {
									launcher: <strong>{t('Workspace:citrix_workspace_launcher')}</strong>,
									cancel: <strong>{t('Workspace:cancel')}</strong>,
									detectAgain: <strong>{t('Workspace:detect_again')}</strong>,
							  })
							: t('Workspace:if_no_window_detect_again', {
									detectAgain: <strong>{t('Workspace:detect_again')}</strong>,
							  })}
					</p>
				</StyledBlock>
				<StyledJustifiedBlock>
					<Button.TextPrimary onClick={this.props.detect}>
						{t('Workspace:detect_again')}
					</Button.TextPrimary>
					<Link.Primary href={this.props.getDownloadUrl()} target="_blank">
						{t('Workspace:download_again')}
					</Link.Primary>
					{this.props.isHtml5Enabled && (
						<Button.TextPrimary onClick={this.props.useHtml5}>
							{t('Workspace:use_browser')}
						</Button.TextPrimary>
					)}
					<Button.TextPrimary onClick={this.props.alreadyInstalled}>
						{t('Workspace:already_installed')}
					</Button.TextPrimary>
				</StyledJustifiedBlock>
			</MainRegion>
		);
	}

	public componentDidMount() {
		this.props.detect();
	}
}
