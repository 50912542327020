import React from 'react';
import { t } from '@citrite/translate';
import { notifyInfo } from '@citrite/web-ui-component';
import { trackEvent } from 'analytics';
import { useHistory, useLocation } from 'react-router-dom';
import { isDesktopOnlyHomepage } from 'App/isDesktopOnlyHomepage';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { homePagePath } from 'Router/homePageRoute';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

export const HomepageSwitchHandler: React.FC = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { isIWSEnabled } = useUserContext();
	const { value: resourceContext } = useLoadableResourceContext();
	const location = useLocation();
	const history = useHistory();
	const previousHomepageIsDesktopOnly = React.useRef<null | boolean>(null);

	React.useEffect(() => {
		const homepageIsDesktopOnly = isDesktopOnlyHomepage(
			workspaceConfiguration,
			isIWSEnabled,
			resourceContext
		);

		if (homepageIsDesktopOnly === null) {
			return;
		}

		if (previousHomepageIsDesktopOnly.current === null) {
			previousHomepageIsDesktopOnly.current = homepageIsDesktopOnly;
			return;
		}

		if (previousHomepageIsDesktopOnly.current !== homepageIsDesktopOnly) {
			previousHomepageIsDesktopOnly.current = homepageIsDesktopOnly;

			notifyInfo(t('Workspace:homepage_switch'), {
				timeout: 5000,
			});

			trackEvent(
				homepageIsDesktopOnly
					? 'HomepageSwitchToDesktopOnly'
					: 'HomepageSwitchFromDesktopOnly'
			);

			if (homepageIsDesktopOnly && location.pathname !== homePagePath) {
				history.push(homePagePath);
			}
		}
	}, [workspaceConfiguration, isIWSEnabled, resourceContext, history, location.pathname]);

	return null;
};
