import { Nullable } from '@citrite/utility-types';
import {
	CacheBucketSettings,
	EncryptedCacheBucket,
	UnencryptedCacheBucket,
} from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import {
	getFromLocalStorage,
	getFromSessionStorage,
	removeFromLocalStorage,
	removeFromSessionStorage,
	setInLocalStorage,
	setInSessionStorage,
} from 'javascript/sf/Storage';
import { UserDetails, UserDetailsLoader } from 'Workspace/UserContext';
import { lastUserDetailsKey } from 'Workspace/UserContext/constants';
import { createCryptoService, CryptoService, EncryptedData } from '../Encryption';
import { buildBucketCacheKey } from './BucketManifest';

export interface CacheBucketParams extends CacheBucketSettings {
	bucketId: string;
	getUserDetails?: () => [UserDetails, UserDetailsLoader];
}

interface UnencryptedCacheEntry {
	data: any;
	expiration: number;
}

export interface EncryptedCacheEntry extends EncryptedData {
	expiration?: number;
}

export class CacheBucket implements EncryptedCacheBucket, UnencryptedCacheBucket {
	private getUserDetails: () => [UserDetails, UserDetailsLoader];
	private bucketId: string;
	private crypto: CryptoService;
	private perSession: boolean;
	private perUser: boolean;

	public constructor(params: CacheBucketParams) {
		if (!params.bucketId) {
			throw new Error('bucket id not set');
		}
		this.bucketId = params.bucketId;
		this.getUserDetails = params.getUserDetails || (() => [null, null]);
		this.crypto = createCryptoService();
		this.perSession = params.perSession;
		this.perUser = params.perUser;
	}

	private get getFromStorage() {
		if (this.perSession) {
			return getFromSessionStorage;
		}
		return getFromLocalStorage;
	}

	private get setInStorage() {
		if (this.perSession) {
			return setInSessionStorage;
		}
		return setInLocalStorage;
	}

	private get removeFromStorage() {
		if (this.perSession) {
			return removeFromSessionStorage;
		}
		return removeFromLocalStorage;
	}

	private async getUserThumbprint() {
		const [, userDetailsLoader] = this.getUserDetails();
		await userDetailsLoader;
		const [userDetails] = this.getUserDetails();
		return userDetails?.userThumbprint;
	}

	public setEncrypted = async (cacheKey: string, data: any, maxAgeInDays?: number) => {
		const encryptionKey = await this.getUserThumbprint();

		if (!encryptionKey || !cacheKey || !this.crypto.isSupported()) {
			return;
		}

		try {
			const encryptedData = await this.crypto
				.encrypt(encryptionKey, data)
				.catch(logError);

			if (encryptedData) {
				const cacheEntry: EncryptedCacheEntry = maxAgeInDays
					? {
							...encryptedData,
							expiration: this.createExpirationDate(maxAgeInDays),
					  }
					: encryptedData;

				this.setInStorage(this.getLocalStorageKey(cacheKey), cacheEntry);
			}
		} catch (e) {
			this.removeFromStorage(this.getLocalStorageKey(cacheKey));

			logError(e, {
				customMessage: 'Failed to set data in cache bucket',
				tags: { bucket: buildBucketCacheKey(this.bucketId), feature: 'cache-buckets' },
			});
		}
	};

	public getEncrypted = async <T>(cacheKey: string): Promise<Nullable<T>> => {
		let out: T = null;
		try {
			out = await this.tryGetEncryptedEntry<T>(cacheKey);
		} catch (e) {
			logError(e);
		}
		return out;
	};

	public fetchEncryptedCacheFirst = async <T>(
		cacheKey: string,
		backgroundResolver: (cancelUpdateCallback: () => Nullable<T>) => Promise<T>
	): Promise<T> => {
		const pendingCacheValue = this.getEncrypted<T>(cacheKey);
		let persistUpdate = true;
		const cancelUpdateCallback = (): Nullable<T> => {
			persistUpdate = false;
			return null;
		};
		const pendingResolverValue = backgroundResolver(cancelUpdateCallback);
		pendingResolverValue.then(value => {
			if (persistUpdate) {
				this.setEncrypted(cacheKey, value);
			}
		});

		const cachedValue = await pendingCacheValue;
		if (cachedValue != null) {
			return cachedValue;
		}

		return pendingResolverValue;
	};

	public setUnencrypted = (cacheKey: string, data: any, maxAgeInDays?: number) => {
		if (!cacheKey) {
			return;
		}
		let expiration = 0;
		if (maxAgeInDays) {
			expiration = this.createExpirationDate(maxAgeInDays);
		}
		const cacheEntry: UnencryptedCacheEntry = {
			data,
			expiration,
		};

		try {
			this.setInStorage(this.getLocalStorageKey(cacheKey), cacheEntry);
		} catch (e) {
			this.removeFromStorage(this.getLocalStorageKey(cacheKey));

			logError(e, {
				customMessage: 'Failed to set data in cache bucket',
				tags: { bucket: buildBucketCacheKey(this.bucketId), feature: 'cache-buckets' },
			});
		}
	};

	public getUnencrypted = <T>(cacheKey: string): Nullable<T> => {
		if (!cacheKey) {
			return null;
		}

		const cacheEntry = this.getFromStorage<UnencryptedCacheEntry>(
			this.getLocalStorageKey(cacheKey)
		);

		if (!this.isValidCacheEntry(cacheEntry)) {
			return null;
		}

		return cacheEntry.data;
	};

	public removeCacheEntry = (cacheKey: string): void => {
		this.removeFromStorage(this.getLocalStorageKey(cacheKey));
	};

	private async tryGetEncryptedEntry<T>(cacheKey: string): Promise<Nullable<T>> {
		const encryptionKey = await this.getUserThumbprint();

		if (!encryptionKey || !cacheKey || !this.crypto.isSupported()) {
			return null;
		}

		const parsedJSONCacheEntry = this.getFromStorage<EncryptedCacheEntry>(
			this.getLocalStorageKey(cacheKey)
		);

		if (!this.isValidCacheEntry(parsedJSONCacheEntry)) {
			return null;
		}

		return await this.crypto.decrypt<T>(encryptionKey, parsedJSONCacheEntry);
	}

	private getLocalStorageKey(cacheKey: string) {
		return `${this.getStorageKeyPrefix()}${cacheKey}`;
	}

	private getStorageKeyPrefix() {
		const [userDetails] = this.getUserDetails();
		const userId =
			userDetails?.userId || getFromLocalStorage<UserDetails>(lastUserDetailsKey)?.userId;
		if (this.perUser && !userId) {
			throw new Error('Missing user id for per user cache.');
		}
		const userPrefix = this.perUser ? `${userId}-` : '';
		return `${buildBucketCacheKey(this.bucketId)}${userPrefix}`;
	}

	private createExpirationDate(maxAgeInDays: number) {
		const expirationDate = new Date();
		return expirationDate.setDate(expirationDate.getDate() + maxAgeInDays);
	}

	private isValidCacheEntry(entry?: EncryptedCacheEntry | UnencryptedCacheEntry) {
		if (!entry) {
			return false;
		}

		return !entry.expiration || entry.expiration > Date.now();
	}
}
