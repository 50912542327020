import * as React from 'react';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { InternalIntegrationContext } from 'Integrations/Context';
import { useIntegrations } from 'Integrations/useIntegrations';
import { getAllFromLocalStorage, removeFromLocalStorage } from 'javascript/sf/Storage';
import {
	BucketManifest,
	buildBucketCacheKey,
	buildIntegrationCacheBucketId,
	globalBucketPrefix,
} from 'Workspace/Cache/BucketManifest';
import { CacheContext } from 'Workspace/Cache/Context';
import { createCacheFactory } from 'Workspace/Cache/createCacheFactory';
import {
	isEnhancedCachingEnabled,
	KEY_PREFIX as ENHANCED_CACHE_PREFIX,
} from 'Workspace/Cache/withEnhancedCaching';
import { UserDetails, UserDetailsLoader, useUserContext } from 'Workspace/UserContext';

export interface Props {
	workspaceConfiguration: WorkspaceConfiguration;
	userDetails: UserDetails;
	userDetailsLoader: UserDetailsLoader;
	integrations: InternalIntegrationContext;
}

class _CacheProvider extends React.Component<Props, {}> {
	public componentDidMount() {
		this.purgeInvalidatedCacheBuckets();
	}

	public render() {
		return (
			<CacheContext.Provider
				value={{
					cacheFactory: createCacheFactory(this.props.workspaceConfiguration, () => [
						this.props.userDetails,
						this.props.userDetailsLoader,
					]),
				}}
			>
				{this.props.children}
			</CacheContext.Provider>
		);
	}

	private purgeInvalidatedCacheBuckets() {
		const integrationBuckets = this.props.integrations.allIntegrations
			.filter(i => !!i.cacheVersion)
			.map(integration => buildIntegrationCacheBucketId(integration));
		const localStorage = getAllFromLocalStorage();
		Object.keys(localStorage).forEach(key => {
			if (key.startsWith(globalBucketPrefix)) {
				const isPlatformCacheEntry = Object.keys(BucketManifest).some(manifestKey => {
					return key.startsWith(buildBucketCacheKey(BucketManifest[manifestKey]));
				});

				const isIntegrationCacheEntry = integrationBuckets.some(integrationCacheKey => {
					return key.startsWith(buildBucketCacheKey(integrationCacheKey));
				});

				const isInvalidEnhancedCacheEntry =
					this.props.workspaceConfiguration &&
					!isEnhancedCachingEnabled(this.props.workspaceConfiguration) &&
					key.includes(ENHANCED_CACHE_PREFIX);

				if (
					(!isPlatformCacheEntry && !isIntegrationCacheEntry) ||
					isInvalidEnhancedCacheEntry
				) {
					removeFromLocalStorage(key);
				}
			}
		});
	}
}

export const CacheProvider = (props: { children?: React.ReactNode }) => {
	const configuration = useConfigurationContext();
	const userContext = useUserContext();
	const integrations = useIntegrations();
	return (
		<_CacheProvider
			workspaceConfiguration={configuration.workspaceConfiguration}
			userDetails={userContext.userDetails}
			userDetailsLoader={userContext.userDetailsLoader}
			integrations={integrations}
		>
			{props.children}
		</_CacheProvider>
	);
};
