import { useEffect, useState } from 'react';
import { breakpoints, useBreakpoints } from '@citrite/web-ui-component';

export interface FormFactor {
	isSmallFormFactor: boolean;
	isLargeFormFactor: boolean;
}

export const useFormFactor = (): FormFactor => {
	const [isSmallFormFactor, setIsSmallFormFactor] = useState<boolean>(false);
	const [isLargeFormFactor, setIsLargeFormFactor] = useState<boolean>(true);
	const { screenSize } = useBreakpoints();

	useEffect(() => {
		const { onMobile } = screenSize;
		if (onMobile) {
			setIsSmallFormFactor(true);
			setIsLargeFormFactor(false);
		} else {
			setIsSmallFormFactor(false);
			setIsLargeFormFactor(true);
		}
	}, [screenSize]);

	return { isLargeFormFactor, isSmallFormFactor };
};

export const isSmallForm = (): boolean => {
	const { mobilePX, baseFontSize } = breakpoints;
	return window.matchMedia(`(max-width: ${mobilePX / baseFontSize}em)`).matches;
};
