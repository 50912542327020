import React from 'react';
import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ActivityManagerLauncher } from 'App/Activity/ActivityManagerLauncher/ActivityManagerLauncher';
import { Branch } from 'Components/MastheadBanner/Branch';
import { UserMenu } from '../Masthead/UserMenu';
import { useFormFactor } from './useFormFactor';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledActionContainer = styled.div`
	padding-left: ${layout.mediumSpace};
`;

export const Actions = () => {
	const { isLargeFormFactor } = useFormFactor();
	return (
		<StyledDiv data-testid="application-actions">
			{isLargeFormFactor && <ActivityManagerLauncher />}
			<StyledActionContainer>
				<UserMenu />
			</StyledActionContainer>
			<Branch />
		</StyledDiv>
	);
};
