import React from 'react';
import { Search, SearchResults } from '@citrite/search-module';
import { Item } from '@citrite/search-module/dist/typings/Components/Search';
import { t } from '@citrite/translate';
import { layout } from '@citrite/web-ui-component';
import { browser } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { debounce } from 'lodash';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { useFormFactor } from 'App/Header/useFormFactor';
import { numberOfRecentResources } from 'App/Screen/util';
import { mobileSearchBarHeight } from 'App/Search/MobileSearchModal';
import { ResourceIcon } from 'Components/ResourceIcon';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { launchResource } from 'Environment/launchResource';
import { getRecentResources } from 'Environment/recents';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { isResourceAvailable, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { searchCASReporter } from 'Workspace/TelemetryEvents/search/createSearchCASReporter';
import { getResourcePath } from './utils';

const searchBarWidth = 400;
const StyledSearchContainer = styled.div<{ isSmallFormFactor: boolean }>`
	${props =>
		props.isSmallFormFactor &&
		`
		margin: 5px;
		width: auto;
	`}
	${props =>
		!props.isSmallFormFactor &&
		`
		width: ${searchBarWidth}px;
		position: absolute;
		left: calc(50% - ${searchBarWidth / 2}px);
		margin-bottom: ${layout.tinySpace}
	`}
`;

export interface SearchModuleProps {
	displayResults?: boolean;
	onSearchResultsUpdation?(results: any): void;
}

function _SearchModule({
	displayResults = true,
	onSearchResultsUpdation = () => {},
}: SearchModuleProps) {
	const [selectedItem, setSelectedItem] = React.useState<Item>(null);
	const { value: resourceContext } = useLoadableResourceContext();
	const { workspaceConfiguration } = useConfigurationContext();

	const { isSmallFormFactor } = useFormFactor();

	const onSearchDismiss = () => {
		setSelectedItem(null);
	};

	const onSelect = (item: Item) => {
		setSelectedItem(item);
		const resource = resourceContext.resources.find(
			resourceItem => resourceItem.id === item.id
		);
		resource && launchResource({ resource, resourceContext, workspaceConfiguration });
		resource && trackAnalyticsEvent(searchCASReporter.getSearchSelectEvent());
	};

	const resourceWithIcon = (resource: Resource) => {
		return {
			name: resource.name,
			description: resource.description,
			icon: <ResourceIcon resource={resource} size={browser.isMobile() ? 32 : 22} />,
			id: resource.id,
			keywords: resource.keywords,
			launchurl: resource.launchurl,
			path: getResourcePath(resource?.path),
			type: resource.type,
			subscriptionstatus: resource.subscriptionstatus,
			category: resource.category,
			isAvailable: isResourceAvailable(resource),
		};
	};

	const resourceList = (itemList: Resource[]) => {
		return itemList?.map(resourceWithIcon);
	};

	const onSearchClick = () => {
		trackAnalyticsEvent(searchCASReporter.getSearchInitEvent());
	};

	const searchQueryInput = debounce(() => {
		trackAnalyticsEvent(searchCASReporter.getSearchKeyInEvent());
	}, 500);

	const onKeyIn = () => {
		searchQueryInput();
	};

	const onNoResultsFound = () => {
		trackAnalyticsEvent(searchCASReporter.getSearchNoResultsEvent());
	};

	const recentResources: Resource[] = resourceContext.resources
		? getRecentResources(resourceContext.resources)
		: [];

	return resourceContext.resources ? (
		<StyledSearchContainer
			data-testid="search-module"
			id="search-module"
			aria-label={t('javascript:search.search_workspace')}
			isSmallFormFactor={isSmallFormFactor}
		>
			<Search
				items={resourceList(resourceContext.resources)}
				selectedItem={selectedItem}
				onSelect={onSelect}
				placeholderText={t('javascript:search.search_workspace')}
				applicationHeaderText={t('Workspace:applications')}
				desktopHeaderText={t('Workspace:desktops')}
				webAppTitle={t('Workspace:ftu_ui.web_app')}
				virtualAppTitle={t('Workspace:ftu_ui.desktop_app')}
				desktopTitle={t('Workspace:ftu_ui.desktop')}
				suggestionsText={t('javascript:search.suggestions')}
				recentsText={t('Workspace:recently_viewed')}
				defaultItems={resourceList(recentResources?.slice(0, numberOfRecentResources))}
				onClick={onSearchClick}
				onKeyIn={onKeyIn}
				onBlur={onSearchDismiss}
				onNoResultsFound={onNoResultsFound}
				noResultsText={t('javascript:search.no_results_search')}
				shouldDisplayResults={displayResults}
				onSearchResultsUpdate={onSearchResultsUpdation}
			/>
		</StyledSearchContainer>
	) : null;
}

export const SearchModule = (props: SearchModuleProps) => {
	return (
		<GenericErrorBoundary
			renderProp={() => <_SearchModule {...props} />}
			name="SearchModule"
		/>
	);
};

interface ResultsProps {
	searchResults: any;
	onSelectItem?(item: Item): void;
}

export function Results({ searchResults, onSelectItem = () => {} }: ResultsProps) {
	const { value: resourceContext } = useLoadableResourceContext();
	const { workspaceConfiguration } = useConfigurationContext();
	const onSelect = (item: Item) => {
		const resource = resourceContext.resources.find(
			resourceItem => resourceItem.id === item.id
		);
		resource && launchResource({ resource, resourceContext, workspaceConfiguration });
		resource && trackAnalyticsEvent(searchCASReporter.getSearchSelectEvent());
		onSelectItem(item);
	};

	return (
		<SearchResults
			data-testid="search-module-search-results"
			data-analytics-id="search-module-search-results"
			aria-label={t('javascript:search.search_results')}
			onSelect={onSelect}
			searchResults={searchResults}
			webAppTitle={t('Workspace:ftu_ui.web_app')}
			virtualAppTitle={t('Workspace:ftu_ui.desktop_app')}
			desktopTitle={t('Workspace:ftu_ui.desktop')}
			resultHeight={window.innerHeight - mobileSearchBarHeight + 'px'}
			noResultsText={t('javascript:search.no_results_search')}
		/>
	);
}
