import * as React from 'react';
import { InfoBanner } from '@citrite/web-ui-component';
import { debounce } from 'lodash';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { isNativeWidget } from 'Environment/launchResource/device';
import {
	bannerHeight,
	informationalBannerHeight,
	MastheadOffset,
} from 'Workspace/ThemeProvider';
import {
	StyledContent,
	StyledDropdownIcon,
	StyledLineClamp,
	StyledMastheadInformationalBanner,
} from './MastheadInformationalBanner.styled';

interface State {
	expandedBanner: boolean;
	enabledLineClamp: boolean;
}

interface Props {
	Content: React.ComponentType;
	textAlign?: 'center' | 'left' | 'right';
	mastheadOffset?: MastheadOffset;
	bannerPosition?: BannerPosition;
}

export class MastheadInformationalBanner extends React.Component<Props, State> {
	public state: State = {
		expandedBanner: false,
		enabledLineClamp: false,
	};

	private lineClampRef: HTMLDivElement;

	private handleRef = (div: HTMLDivElement) => (this.lineClampRef = div);

	public componentDidMount() {
		this.checkTruncation();
		window.addEventListener('resize', this.resetAndCheckTruncation);
		window.addEventListener('click', this.onClickOutsideMasthead);
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.resetAndCheckTruncation);
		window.removeEventListener('click', this.onClickOutsideMasthead);
	}

	private onMastheadClick = (e: React.MouseEvent) => {
		this.state.enabledLineClamp && this.setExpandedBanner(!this.state.expandedBanner);
		e.stopPropagation();
	};

	private onClickOutsideMasthead = () => {
		this.state.enabledLineClamp && this.setExpandedBanner(false);
	};

	private setExpandedBanner(expand: boolean) {
		this.setState({
			expandedBanner: expand,
		});
	}

	private resetAndCheckTruncation = debounce(() => {
		this.setState(
			{
				expandedBanner: false,
				enabledLineClamp: false,
			},
			this.checkTruncation
		);
	}, 300);

	private checkTruncation() {
		if (this.props.bannerPosition) {
			this.props.mastheadOffset.setCustomBannerOffset(
				bannerHeight,
				this.props.bannerPosition
			);
		}
		if (this.isTruncated()) {
			// Disabling line clamp incase of widget and show full banner
			this.setState({
				expandedBanner: true,
				enabledLineClamp: !isNativeWidget(),
			});
		}
	}

	private isTruncated() {
		const allContent = this.lineClampRef.scrollWidth;
		const visibleContent = this.lineClampRef.clientWidth;
		return allContent > visibleContent;
	}

	public render() {
		const { Content } = this.props;
		return (
			<StyledMastheadInformationalBanner
				expandBanner={this.state.expandedBanner}
				bannerHeight={informationalBannerHeight}
				enabledLineClamp={this.state.enabledLineClamp}
				textAlign={this.props.textAlign || 'center'}
				top={
					this.props.bannerPosition === BannerPosition.Top
						? this.props.mastheadOffset.mastheadHeightWithCustomInfoBanner
						: '0px'
				}
				bannerPosition={this.props.bannerPosition}
			>
				<InfoBanner>
					<StyledLineClamp
						ref={this.handleRef}
						enabledLineClamp={this.state.enabledLineClamp}
						innerWidth={window.innerWidth}
						expandedBanner={this.state.expandedBanner}
						bannerPosition={this.props.bannerPosition}
						onClick={this.onMastheadClick}
					>
						<StyledContent
							bannerHeight={
								this.props.bannerPosition === BannerPosition.Top
									? informationalBannerHeight
									: '0px'
							}
						>
							<Content />
						</StyledContent>
					</StyledLineClamp>
					<StyledDropdownIcon
						role="button"
						tabIndex={0}
						enabledLineClamp={this.state.enabledLineClamp}
						expandedBanner={this.state.expandedBanner}
						bannerPosition={this.props.bannerPosition}
						onClick={this.onMastheadClick}
					/>
				</InfoBanner>
			</StyledMastheadInformationalBanner>
		);
	}
}
