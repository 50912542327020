import {
	arrayBufferToBase64String,
	arrayBufferToString,
	base64StringToArrayBuffer,
	getEncryptionKeyData,
	getEncryptionParams,
	stringToArrayBuffer,
} from 'Workspace/Encryption/encryptionHelpers';
import {
	CryptoSupportedCheck,
	Decryptor,
	Digester,
	EncryptedData,
	Encryptor,
} from './types';

export interface MSCrypto {
	subtle: MsSubtleCrypto;
	getRandomValues: Crypto['getRandomValues'];
}
const getMSCrypto = () => window['msCrypto'] as unknown as MSCrypto;
export const msCryptoAvailable: CryptoSupportedCheck = () => !!window['msCrypto'];

export type MsSubtleCrypto = Omit<
	SubtleCrypto,
	'importKey' | 'encrypt' | 'decrypt' | 'digest'
> & {
	importKey: (
		format: 'raw',
		keyData: ArrayBuffer,
		algorithm: string,
		extractable: boolean,
		keyUsages: string[]
	) => MSCryptoCallbacks<CryptoKey>;
	encrypt: (
		algorithm: AesGcmParams,
		key: CryptoKey,
		data: ArrayBuffer
	) => MSCryptoCallbacks<MsEncryptResult>;
	decrypt: (
		algorithm: MsAesGcmParamsWithTag,
		key: CryptoKey,
		data: ArrayBuffer
	) => MSCryptoCallbacks<ArrayBuffer>;
	digest: (algorithm: Algorithm, data: ArrayBuffer) => MSCryptoCallbacks<ArrayBuffer>;
};
type MsAesGcmParamsWithTag = AesGcmParams & {
	tag: ArrayBuffer;
};
export type MsEncryptResult = {
	ciphertext: ArrayBuffer;
	tag: ArrayBuffer;
};
export interface MSCryptoCallbacks<T> {
	onerror: (error: any) => void;
	oncomplete: (event: { target: { result: T } }) => void;
}

export const ieEncryptData: Encryptor = async (key, dataToEncrypt) => {
	const api = getMSCrypto().subtle;
	const stringifiedDataToEncrypt = JSON.stringify(dataToEncrypt);
	const keyData = getEncryptionKeyData(key);
	const arrayBufferDataToEncrypt = stringToArrayBuffer(stringifiedDataToEncrypt);
	const encryptionParams = getEncryptionParams(getMSCrypto());
	const cryptoKey = await msCryptoKeyPromise(api, keyData);
	const encryptedData = new Promise<EncryptedData>((resolve, reject) => {
		const encryptOp = api.encrypt(encryptionParams, cryptoKey, arrayBufferDataToEncrypt);

		encryptOp.onerror = () =>
			reject(new Error('MSSubtleEncryptionError: could not encrypt.'));

		encryptOp.oncomplete = function (e) {
			const encryptionResult = e.target.result;
			const tag = Array.from(new Uint8Array(encryptionResult.tag));
			const iv = Array.from(encryptionParams.iv as Uint8Array);
			const data = arrayBufferToBase64String(encryptionResult.ciphertext);
			resolve({
				data,
				initialVector: iv,
				tag,
			});
		};
	});
	return encryptedData;
};

export const ieDecryptData: Decryptor = async <T>(
	key: string,
	parsedJSON: EncryptedData
) => {
	const api = getMSCrypto().subtle;
	const keyData = getEncryptionKeyData(key);
	const cryptoKey = await msCryptoKeyPromise(api, keyData);
	const arrayBufferDataToBeDecrypted = base64StringToArrayBuffer(parsedJSON.data);
	const decryptOperationPromise = new Promise<T>((resolve, reject) => {
		const decryptOp = getMSCrypto().subtle.decrypt(
			{
				name: 'AES-GCM',
				iv: new Uint8Array(parsedJSON.initialVector),
				tag: new Uint8Array(parsedJSON.tag),
			},
			cryptoKey,
			arrayBufferDataToBeDecrypted
		);
		decryptOp.onerror = () =>
			reject(new Error('MSSubtleDecryptionError: could not decrypt.'));
		decryptOp.oncomplete = function (e) {
			const decryptedDataArrayBufferResult = e.target.result;

			const decryptedDataStringResult = arrayBufferToString(
				decryptedDataArrayBufferResult
			);

			resolve(JSON.parse(decryptedDataStringResult));
		};
	});
	return decryptOperationPromise;
};

export const ieDigestString: Digester = async (input: string) => {
	const api = getMSCrypto().subtle;
	const digestableBuffer = stringToArrayBuffer(input);
	return new Promise<string>((resolve, reject) => {
		const digestProcess = api.digest({ name: 'SHA-256' }, digestableBuffer);
		digestProcess.oncomplete = event =>
			resolve(arrayBufferToString(event?.target?.result));
		digestProcess.onerror = () =>
			reject(new Error('MSSubtleDecryptionError: could not form digest.'));
	});
};

function msCryptoKeyPromise(api: MsSubtleCrypto, keyData: ArrayBuffer) {
	const cryptoKeyPromise = new Promise<CryptoKey>((resolve, reject) => {
		const importCryptoKey = api.importKey('raw', keyData, 'AES-GCM', false, [
			'encrypt',
			'decrypt',
		]);
		importCryptoKey.onerror = () => {
			reject(new Error('MSSubtleImportKey: could not import key.'));
		};
		importCryptoKey.oncomplete = e => {
			resolve(e.target.result);
		};
	});
	return cryptoKeyPromise;
}
