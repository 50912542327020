import { RouteComponentProps } from 'react-router-dom';

type ParamModifier = 'required' | 'optional' | 'multimatch';

export interface BaseRoute<RouteParams, LocationState> {
	path: string;
	secondaryPaths?: string[];
	component?: React.ComponentType<RouteComponentProps<RouteParams, LocationState>>;
}

export interface RouteWithParams<RouteParams, LocationState>
	extends BaseRoute<RouteParams, LocationState> {
	/** mark each parameter as true (required) or false (optional) */
	pathParams: Record<keyof RouteParams, boolean | ParamModifier>;
	indexRouteParams?: { [P in keyof RouteParams]?: RouteParams[P] };
}

export type CreateRoute<RouteParams, LocationState> = RouteParams extends null
	? BaseRoute<RouteParams, LocationState>
	: RouteWithParams<RouteParams, LocationState>;

export type RouteGetUrl<RouteParams> = RouteParams extends null
	? () => string
	: (params: RouteParams) => string;

export interface ReturnRoute<RouteParams> {
	paths: string[];
	key: string;
	getUrl: RouteGetUrl<RouteParams>;
}

export type ReactRoute<RouteParams, LocationState> = Omit<
	CreateRoute<RouteParams, LocationState>,
	'path'
> &
	ReturnRoute<RouteParams>;

export interface RoutePathParams {
	[key: string]: string;
}

function buildReactRouterPath<RouteParams extends RoutePathParams>(
	rootPath: string,
	params: {
		sortedKeys: string[];
		pathParams: Record<keyof RouteParams, boolean | ParamModifier>;
	}
) {
	let path = rootPath;
	params.sortedKeys.forEach(paramKey => {
		let modifierSwitch = params.pathParams[paramKey];
		if (typeof modifierSwitch === 'boolean') {
			modifierSwitch = modifierSwitch ? 'required' : 'optional';
		}
		let modifier = '';
		switch (modifierSwitch) {
			case 'required':
				modifier = '';
				break;
			case 'optional':
				modifier = '?';
				break;
			case 'multimatch':
				modifier = '*';
				break;
		}
		path += `/:${paramKey}${modifier}`;
	});
	return path;
}

function buildUrl(
	rootPath: string,
	params: { sortedKeys: string[]; pathParams: RoutePathParams }
) {
	let path = rootPath;
	if (!params.pathParams) {
		return path;
	}
	params.sortedKeys.forEach(paramKey => {
		const value = params.pathParams[paramKey];
		if (value) {
			path += `/${value}`;
		}
	});
	return path;
}

function getSortedKeys<RouteParams extends RoutePathParams>(
	pathParams: Record<keyof RouteParams, boolean | ParamModifier>
) {
	if (!pathParams) {
		return [];
	}
	const requiredKeys: string[] = [];
	const singleMatchKeys: string[] = [];
	const multiMatchKeys: string[] = [];
	Object.keys(pathParams).forEach(paramKey => {
		let modifier = pathParams[paramKey];
		if (typeof modifier === 'boolean') {
			modifier = modifier ? 'required' : 'optional';
		}
		switch (modifier) {
			case 'required':
				requiredKeys.push(paramKey);
				break;
			case 'optional':
				singleMatchKeys.push(paramKey);
				break;
			case 'multimatch':
				multiMatchKeys.push(paramKey);
				break;
		}
	});
	return [...requiredKeys.sort(), ...singleMatchKeys.sort(), ...multiMatchKeys.sort()];
}

export function createRoute<RouteParams extends RoutePathParams, LocationState>(
	route: CreateRoute<RouteParams, LocationState>
) {
	let primaryBaseUrl = route.path;
	if (primaryBaseUrl && primaryBaseUrl.endsWith('/') && primaryBaseUrl.length > 1) {
		// removes trailing '/' from url
		primaryBaseUrl = primaryBaseUrl.substr(0, primaryBaseUrl.length - 1);
	}
	const sortedKeys = getSortedKeys(route.pathParams);
	let primaryReactRouterPath = primaryBaseUrl;
	if (route.pathParams) {
		primaryReactRouterPath = buildReactRouterPath(primaryReactRouterPath, {
			sortedKeys,
			pathParams: route.pathParams,
		});
	}
	const paths = [primaryReactRouterPath, ...(route.secondaryPaths || [])];
	const updatedRoute: ReactRoute<RouteParams, LocationState> = {
		indexRouteParams: {},
		...route,
		paths,
		getUrl: (pathParams =>
			buildUrl(primaryBaseUrl, { sortedKeys, pathParams })) as RouteGetUrl<RouteParams>,
		key: paths.join('.'),
	};
	return updatedRoute;
}
