import { PlatformDependencies, SsoProxyClient } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { PlatformContainer } from 'Workspace/DependencyManagement';
export function ssoProxyClientFactory(di: PlatformContainer) {
	const configuration = di.resolve(PlatformDependencies.WorkspaceConfiguration).get();
	const client: SsoProxyClient = environment.createSSOProxyClient(
		configuration?.authManager.proxyUrl
	);
	client._clientType = 'SsoProxyClient';
	return client;
}
