import * as React from 'react';
import { t } from '@citrite/translate';
import {
	Button,
	SearchIcon,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { browser } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { RouteComponentProps, useRouteMatch, withRouter } from 'react-router-dom';
import { useFormFactor } from 'App/Header/useFormFactor';
import {
	StyledDaasSearchBarDiv,
	StyledDaasSearchTextSpan,
	StyledSearchBarDiv,
	StyledSearchIconContainer,
	StyledSearchTextContainer,
	StyledSearchTextSpan,
} from 'App/Masthead/ItemSearchBar.styled';
import { SearchModule } from 'App/Masthead/SearchModule';
import { isSimpleView } from 'App/Navigation/utils';
import { searchRoute } from 'App/Search';
import { MobileSearchModal } from 'App/Search/MobileSearchModal';
import { ItemSearchModal } from 'Components/ItemSearch/ItemSearchModal';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { searchCASReporter } from 'Workspace/TelemetryEvents/search/createSearchCASReporter';
import { useUserContext } from 'Workspace/UserContext';

export type Props = RouteComponentProps & WithModalsProps;

function _ItemSearchBar(props: Props) {
	const match = useRouteMatch(searchRoute.paths[0]);
	const searchParams: { search?: string; searchFilterOption?: string } =
		match?.params || {};
	const searchTerm = searchParams.search
		? decodeURIComponent(searchParams.search)
		: t('javascript:search.search_workspace');
	const resources = useLoadableResourceContext();

	const daasVisionEnabled = useUserContext().isDaasVisionEnabled;
	const { isLargeFormFactor } = useFormFactor();

	function search() {
		trackAnalyticsEvent(searchCASReporter.getSearchInitEvent());
		const isMobile = browser.isMobile();
		daasVisionEnabled || isMobile
			? props.showModal(<MobileSearchModal />)
			: props.showModal(
					<ItemSearchModal
						onCloseSuccess={route => {
							route && props.history.push(route);
						}}
					/>
			  );
	}

	return daasVisionEnabled ? (
		isSimpleView(resources?.value?.resources) ? null : isLargeFormFactor ? (
			<SearchModule />
		) : (
			<StyledDaasSearchBarDiv
				onClick={search}
				data-testid="search-bar"
				aria-label={t('javascript:search.search_workspace')}
				data-analytics-id="mobile-masthead-search-button"
			>
				<StyledSearchIconContainer>
					<SearchIcon size={18} />
				</StyledSearchIconContainer>
				<StyledSearchTextContainer>
					<StyledDaasSearchTextSpan>{searchTerm}</StyledDaasSearchTextSpan>
				</StyledSearchTextContainer>
			</StyledDaasSearchBarDiv>
		)
	) : (
		<Button.Wrapper onClick={search} data-testid="search-bar">
			<StyledSearchBarDiv>
				<SearchIcon size={18} />
				<StyledSearchTextSpan>{searchTerm}</StyledSearchTextSpan>
			</StyledSearchBarDiv>
		</Button.Wrapper>
	);
}

export const ItemSearchBar = withRouter(withModals(_ItemSearchBar));
