import { postWithFullResponse } from '@citrite/http';
import {
	_UNSTABLE_EnsureInitializedSession,
	AuthChallengeHandler,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { useHistory, useLocation } from 'react-router-dom';
import URI from 'urijs';
import { authChallengeHeader } from 'Environment';
import { clearSessionStorage } from 'javascript/sf/Storage';
import { workspaceLoginRoute } from 'Login/index';
import { PendingLogin, setPendingLogin } from 'Login/PendingLogin';
import { setExternalUserCookieIfSpecified } from 'utils/externalUserCookie';

export function useAuthentication() {
	const location = useLocation();
	const history = useHistory();

	const handleAuthChallengeIfNecessary: AuthChallengeHandler = httpResponseHeaders => {
		let handlingAuthChallenge = false;
		if (httpResponseHeaders[authChallengeHeader]) {
			// Remove the 'client_user' query, since it may cause nav issue after login.
			const pendingLogin: PendingLogin = {
				challenge: httpResponseHeaders[authChallengeHeader],
				locationAfterLogin: {
					...location,
					search: removeUserTypeQueryFromSearch(location.search),
				},
			};
			clearSessionStorage();
			setExternalUserCookieIfSpecified();
			setPendingLogin(pendingLogin);
			history.push(workspaceLoginRoute.getUrl());
			handlingAuthChallenge = true;
		}

		return { handlingAuthChallenge };
	};

	return {
		_UNSTABLE_ensureInitializedSession: ensureInitializedSession,
		/**
		 * Checks for an authentication challenge from Workspace Platform in response
		 * headers. If one is encountered, navigation to the login page is performed.
		 * After login completes, the app is reloaded and brought back to the original
		 * route.
		 */
		handleAuthChallengeIfNecessary,
	};
}

function ensureInitializedSession(workspaceConfiguration: WorkspaceConfiguration) {
	// The side effects of this call are creation of WSP session and CSRF cookies
	// The result can also be passed to handleAuthChallengeIfNecessary for triggering an auth flow
	return postWithFullResponse<void>(
		workspaceConfiguration.authManager.getUserDetailsURL,
		null,
		{
			passthroughDSAuthChallenge: true,
		}
	).then(response => ({ headers: response.headers }));
}

function removeUserTypeQueryFromSearch(searchString: string) {
	if (searchString !== undefined && searchString.trim() !== '') {
		const uri = new URI(searchString);
		uri.removeQuery('client_user');
		return uri.href();
	}

	return searchString;
}
