/**
 * Define parameters type for function cwrap(prototype: cwrap(ident, returnType, argTypes, opts)).
 * For the detail, please check https://emscripten.org/docs/api_reference/preamble.js.html#cwrap
 * CwrapParamType[0](inent): The name of the C function to be called
 * CwrapParamType[1](returnType): The return type of the function
 * CwrapParamType[2](argTypes): An array of the types of arguments for the function
 * CwrapParamType[3](opts): An optional options object, indicate async is true or false
 */
type CwrapParamType = [string, string | null, string[], object];

//functions from kvlr-crypto wasm -- start
const initDbWrap: CwrapParamType = ['initDb', null, ['number'], { async: true }];
const generateKvlrCryptoKeypairWrap: CwrapParamType = [
	'generateKvlrCryptoKeypair',
	'number',
	['number'],
	{ async: true },
];
const saveFileWrap: CwrapParamType = [
	'saveFile',
	null,
	['string', 'string'],
	{ async: true },
];
const getFileDataWrap: CwrapParamType = [
	'getFileData',
	'number',
	['string', 'number'],
	{ async: true },
];
const getFileListWrap: CwrapParamType = [
	'getFileList',
	'number',
	['string', 'number'],
	{ async: true },
];
const deleteFileWrap: CwrapParamType = ['deleteFile', null, ['string'], { async: true }];
const mallocWrap = '_malloc';
const stringToUTF8Wrap = 'stringToUTF8';
const freeWrap = '_free';
const getValueWrap = 'getValue';
const UTF8ToStringWrap = 'UTF8ToString';
//functions from kvlr-crypto wasm -- end

//functions from clm wasm -- start
const launchSessionViaCLFuncWrap: CwrapParamType = [
	'launchSessionViaCL',
	'number',
	['string'],
	{ async: true },
];
const handleValidateSessionResultWrap: CwrapParamType = [
	'handleValidateSessionResult',
	null,
	['number', 'number', 'number'],
	{ async: true },
];
const mountIDBFSWrap = '_mountIDBFS';
const initCLMWrap = '_initCLM';
const reSyncIDBFSWrap = '_reSyncIDBFS';
const HEAPU8Wrap = 'HEAPU8';
const setWrap = 'set';
//functions from clm wasm -- end

interface CryptoFunction {
	initDbWrap: CwrapParamType;
	generateKvlrCryptoKeypairWrap: CwrapParamType;
	saveFileWrap: CwrapParamType;
	getFileDataWrap: CwrapParamType;
	getFileListWrap: CwrapParamType;
	deleteFileWrap: CwrapParamType;
	mallocWrap: string;
	stringToUTF8Wrap: string;
	freeWrap: string;
	getValueWrap: string;
	UTF8ToStringWrap: string;
}

interface ClmFunction {
	launchSessionViaCLFuncWrap: CwrapParamType;
	handleValidateSessionResultWrap: CwrapParamType;
	mountIDBFSWrap: string;
	initCLMWrap: string;
	reSyncIDBFSWrap: string;
	mallocWrap: string;
	freeWrap: string;
	HEAPU8Wrap: string;
	setWrap: string;
}

export const CryptoFunctionWrap: CryptoFunction = {
	initDbWrap,
	generateKvlrCryptoKeypairWrap,
	saveFileWrap,
	getFileDataWrap,
	getFileListWrap,
	deleteFileWrap,
	mallocWrap,
	stringToUTF8Wrap,
	freeWrap,
	getValueWrap,
	UTF8ToStringWrap,
};

export const ClmFunctionWrap: ClmFunction = {
	launchSessionViaCLFuncWrap,
	handleValidateSessionResultWrap,
	mountIDBFSWrap,
	initCLMWrap,
	reSyncIDBFSWrap,
	mallocWrap,
	freeWrap,
	HEAPU8Wrap,
	setWrap,
};
