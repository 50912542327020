import * as React from 'react';
import { EventBus, EventBusProvider as Provider } from 'Components/EventBus';
import { NativeEventBusPipe } from 'Environment/NativeEventBus/NativeEventBusPipe';
import { logEventSubscriberExceptionWithRaven } from './logEventSubscriberExceptionWithRaven';

type Props = { children?: React.ReactNode };

export class EventBusProvider extends React.Component<Props> {
	private bus: EventBus;

	public constructor(props: Props) {
		super(props);
		this.bus = new EventBus(logEventSubscriberExceptionWithRaven);
	}

	public render() {
		return (
			<Provider value={this.bus}>
				<NativeEventBusPipe />
				{this.props.children}
			</Provider>
		);
	}
}
