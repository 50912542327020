import * as React from 'react';
import { IconProps } from '@citrite/web-ui-component';
import {
	IntegrationContext,
	RouteContext,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import {
	BaseRoute,
	createRoute,
	ReturnRoute,
	RoutePathParams,
	RouteWithParams,
} from '@citrite/workspace-ui-platform-react';
import { History, Location } from 'history';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import { WithWorkspaceConfiguration } from 'Configuration/withWorkspaceConfiguration';
import { useIntegrations } from 'Integrations/useIntegrations';
import {
	ResourceContextProvider,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { UserContext, WithUserContextProps } from 'Workspace/UserContext';

export interface RoutedComponentProps<RouteParams = null, LocationState = {}>
	extends WithResourceContextProps,
		WithUserContextProps,
		WithWorkspaceConfiguration,
		LeftNavCallbacks,
		RouteContext {
	location: Location<LocationState>;
	history: History;
	match: match<RouteParams>;
}

export interface MastheadState {
	mobileMastheadTitle: React.ReactNode;
}

export type SetRefreshPageFn = (refreshPage: () => void) => void;

export interface LeftNavCallbacks {
	setRefreshPageFn: SetRefreshPageFn;
	clearRefreshPageFn(): void;
}

export interface NavLinkProps {
	isDivider?: boolean;
	icon?: React.FC<IconProps>;
	selectedIcon?: React.FC<IconProps>;
	secondaryIcon?: React.FC<IconProps>;
	onClick?(e: React.MouseEvent): void;
	title?: string;
	/** Used to control the order of top-level links in the left nav */
	weight?: number;
	/** Used to mark a link click event in the browser's performance entry buffer */
	performanceMark?: string;
	analyticsID?: string;
}

export type IsAvailable<LocationState> = {
	location: Location<LocationState>;
} & WithResourceContextProps &
	WithUserContextProps &
	WithWorkspaceConfiguration;

export interface IsLoading {
	resources: ResourceContextProvider;
	integrations: IntegrationContext;
	userContext: UserContext;
}

export type GetChildRoutes = (props: {
	integrations: IntegrationContext;
}) => LeftNavRoute<any, any>[];

interface BaseLeftNavRoute<RouteParams, LocationState> {
	isAvailable?(props: IsAvailable<LocationState>): boolean;
	isLoading?(props: IsLoading): boolean;
	component?: React.ComponentType<RoutedComponentProps<RouteParams, LocationState>>;
	getNavLinkProps?(workspaceConfiguration: WorkspaceConfiguration): NavLinkProps;
	childRoutes?: LeftNavRoute<any, any>[];
	getChildRoutes?: GetChildRoutes;
	isDivider?: boolean;
	hasSecondaryNav?: boolean;
}

export type CreateLeftNavRoute<RouteParams, LocationState> = RouteParams extends null
	? Omit<BaseRoute<RouteParams, LocationState>, 'component'> &
			BaseLeftNavRoute<RouteParams, LocationState>
	: Omit<RouteWithParams<RouteParams, LocationState>, 'component'> &
			BaseLeftNavRoute<RouteParams, LocationState>;

export type LeftNavRoute<RouteParams = any, LocationState = any> = Omit<
	CreateLeftNavRoute<RouteParams, LocationState>,
	'path'
> &
	ReturnRoute<RouteParams>;

export function createLeftNavRoute<RouteParams extends RoutePathParams, LocationState>(
	route: CreateLeftNavRoute<RouteParams, LocationState>
) {
	const reactRoute = createRoute(route) as LeftNavRoute<RouteParams, LocationState>;
	const updatedRoute: LeftNavRoute<RouteParams, LocationState> = {
		...reactRoute,
		isAvailable: reactRoute.isAvailable || (() => true),
		isLoading: reactRoute.isLoading || (() => false),
	};
	if (!route.getChildRoutes) {
		updatedRoute.getChildRoutes = () => route.childRoutes || [];
	}
	return updatedRoute;
}

export function createChildRoutes(
	getChildRoutes: LeftNavRoute<any, any>[] | GetChildRoutes,
	indexRedirect = true
) {
	return function ChildRoutes(props: RoutedComponentProps) {
		const integrations = useIntegrations();

		const routes = Array.isArray(getChildRoutes)
			? getChildRoutes
			: getChildRoutes({
					integrations,
			  });
		const availableRoutes = routes.filter(r => r.component && r.isAvailable(props));
		if (!availableRoutes.length) {
			return null;
		}
		const indexRoute = availableRoutes[0] as LeftNavRoute<{}>;
		const indexRender = () =>
			indexRedirect && <Redirect to={indexRoute.getUrl(indexRoute.indexRouteParams)} />;
		return (
			<Switch>
				{availableRoutes.map(route => {
					return (
						<Route
							key={route.key}
							path={route.paths}
							render={routeProps => <route.component {...props} {...routeProps} />}
						/>
					);
				})}
				<Route render={indexRender} />
			</Switch>
		);
	};
}
