import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, notifyError } from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import { isMobileApp } from 'Environment/deviceSupportsResource';
import { installResource } from 'Environment/installResource';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export interface Props extends WithResourceContextProps {
	resource: Resource;
}

interface State {
	upgradeInProgress?: boolean;
}

class _UpdateAppButton extends React.Component<Props, State> {
	public state: State = {};

	public render() {
		const isInstalled = this.props.resourceContext.isInstalled(this.props.resource.id);

		if (
			isMobileApp(this.props.resource) &&
			isInstalled &&
			this.props.resourceContext.isUpgradeAvailable(this.props.resource.id)
		) {
			return (
				<>
					{this.state.upgradeInProgress ? (
						t('Workspace:installing_app')
					) : (
						<Button.TextPrimary onClick={this.upgradeApp}>
							{t('Workspace:update_app')}
						</Button.TextPrimary>
					)}
				</>
			);
		}
		return null;
	}

	private upgradeApp = () => {
		trackAnalyticsEvent(
			AppsUserEventPayload.update(EventSite.MoreInfo, this.props.resource.type)
		);
		this.setState({ upgradeInProgress: true });
		installResource({
			resource: this.props.resource,
			updateSession: this.props.resourceContext.updateSession,
		})
			.catch(() => notifyError(t('Workspace:application_update_failed')))
			.then(() => {
				this.setState({ upgradeInProgress: false });
			});
	};
}

export const UpdateAppButton = withResourceContext(_UpdateAppButton);
