import { WorkspaceConfiguration } from '../workspaceConfiguration';
import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

/**
 * @category Platform Dependency
 */
export interface WorkspaceConfigurationAccessor {
	get(): WorkspaceConfiguration;
	refresh(): Promise<void>;
	hasUIProperty(property: string): boolean;
	hasFeatureCanary(property: string): boolean;
	hasEndpointService(serviceName: string): boolean;
}

/**
 * @category Platform Dependency
 */
export const workspaceConfiguration: WorkspaceConfigurationAccessor = {
	get: () => resolveFromGlobal(PlatformDependencies.WorkspaceConfiguration).get(),
	refresh: () => resolveFromGlobal(PlatformDependencies.WorkspaceConfiguration).refresh(),
	hasUIProperty: (...args) =>
		resolveFromGlobal(PlatformDependencies.WorkspaceConfiguration).hasUIProperty(...args),
	hasFeatureCanary: (...args) =>
		resolveFromGlobal(PlatformDependencies.WorkspaceConfiguration).hasFeatureCanary(
			...args
		),
	hasEndpointService: (...args) =>
		resolveFromGlobal(PlatformDependencies.WorkspaceConfiguration).hasEndpointService(
			...args
		),
};
