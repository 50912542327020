import {
	browser,
	hasFeatureCanary,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	clientType,
	getClientType,
	getClientVersion,
	getSaasLaunchCapability,
} from 'Environment/getClientType';
import { getFromSessionStorage } from 'javascript/sf/Storage';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { launchMethod } from './constants';
import {
	isChromeOS,
	isCitrixChromeApp,
	isLinux,
	isMac,
	isMobileOrTabletDevice,
	isProtocolHandlerSupported,
	isUserAgentMatchedByRegex,
	isWindowsPlatform,
} from './device';

export function getLaunchMethod(config: WorkspaceConfiguration): launchMethod {
	if (isCurrentClient(config)) {
		return launchMethod.html5;
	} else if (isNativeClientDetected(config)) {
		// The protocol handler is used for launch only when it has successfully detected the client.
		// When the user has indicated a client is installed (but none is detected) then ICA file download is used.
		return launchMethod.protocolHandler;
	}

	return launchMethod.icaFile;
}

function isNativeClientDetected(config: WorkspaceConfiguration) {
	if (isMobileOrTabletDevice()) {
		return false;
	} else if (isProtocolHandlerSupported(config)) {
		return isProtocolHandlerEnabled(config) && !!getClientVersion();
	} else if (isWindowsPlatform() || isChromeOS() || isLinux()) {
		// MS Edge - Windows platform with no support for browser plugins or protocol handler
		// Assume native client is not installed - user can change current client via menu option if needed

		// ChromeOS - supports ICA file download as well as HTML5 client
		// Assume native client is not installed, so HTML5 client is used by default (if available)

		// Linux - Supports ICA file download, but not Protocol Handler
		// Force download via ICA file for user to download and launch themselves
		return false;
	} else {
		return true;
	}
}

function isProtocolHandlerEnabled(config: WorkspaceConfiguration) {
	return config.pluginAssistant.protocolHandler.enabled === 'true';
}

function isCurrentClient(config: WorkspaceConfiguration) {
	if (isHTML5Mandatory(config)) {
		return true;
	}

	if (!isHTML5Available(config)) {
		return false;
	}

	return (
		isHTML5ClientPreferred() ||
		(!isNativeClientDetected(config) && !isNativeClientPreferred())
	);
}

export function isHTML5Mandatory(config: WorkspaceConfiguration) {
	const forcedByConfig = getSupportedPlatforms(config) === 'Force';
	const useHtml5ClientAlways =
		getEnabled(config) === 'Always' && isSupportedPlatform(config);

	return forcedByConfig || useHtml5ClientAlways || isCitrixChromeApp();
}

export function isHTML5Available(config: WorkspaceConfiguration) {
	return (
		isHTML5Mandatory(config) ||
		(getEnabled(config) === 'Fallback' && isSupportedPlatform(config))
	);
}

function getSupportedPlatforms(config: WorkspaceConfiguration) {
	return config.pluginAssistant.html5.platforms || '';
}

function getEnabled(config: WorkspaceConfiguration) {
	return config.pluginAssistant.html5.enabled;
}

function isSupportedPlatform(config: WorkspaceConfiguration) {
	const supportedPlatforms = getSupportedPlatforms(config);
	return !supportedPlatforms || isUserAgentMatchedByRegex(supportedPlatforms);
}

function isHTML5ClientPreferred() {
	return getClientType() === clientType.html5;
}

function isNativeClientPreferred() {
	return getClientType() === clientType.native;
}

export function canUseProtocolHandler(config: WorkspaceConfiguration) {
	return isProtocolHandlerEnabled(config) && isProtocolHandlerSupported(config);
}

export function canDownloadWorkspace(config: WorkspaceConfiguration) {
	return config.pluginAssistant.enabled !== 'False' && (isWindowsPlatform() || isMac());
}

export const autoLaunchedDoneKey = 'autoLaunchedDoneKey';

export function getLaunchSingleDesktop(
	config: WorkspaceConfiguration,
	resources: Resource[]
) {
	const autoLaunchEnabledInConfig = config.userInterface.autoLaunchDesktop === 'true';
	const autoLaunchPermittedByBrowser = !usingNativeClientInInternetZone(config);
	const enabledDesktops = resources.filter(r => r.isdesktop && !r.disabled);
	const singleDesktopAvailable = enabledDesktops.length === 1;

	const canAutoLaunchSingleDesktop =
		autoLaunchEnabledInConfig && autoLaunchPermittedByBrowser && singleDesktopAvailable;
	const hasAlreadyLaunched = getFromSessionStorage<boolean>(autoLaunchedDoneKey);

	if (!environment.isNative && canAutoLaunchSingleDesktop && !hasAlreadyLaunched) {
		return enabledDesktops[0];
	}

	return undefined;
}

export function usingNativeClientInInternetZone(config: WorkspaceConfiguration) {
	return browser.isInternetExplorer() && getLaunchMethod(config) === launchMethod.icaFile;
}

export function shouldUseNativeForSaasLaunch(config: WorkspaceConfiguration) {
	return (
		hasFeatureCanary(config, FeatureFlag.SaasAppLaunchUsingNative) &&
		(isWindowsPlatform() || isMac()) &&
		!isHTML5Mandatory(config) &&
		(getEnabled(config) === 'Off' || isNativeClientPreferred())
	);
}

export function doesNativeSupportSaasLaunch() {
	return getSaasLaunchCapability() != null;
}
