import { isDesktopOnlyHomepage } from 'App/isDesktopOnlyHomepage';
import { shouldDisplayDesktopSection } from 'App/Navigation/shouldDisplayDesktopSection';
import { isSimpleView } from 'App/Navigation/utils';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { IsAvailable } from 'Router/ReactRoute';

/*
	WSHELP-417: Customers are seeing 404 always when they have the following combination:
	1) Users have desktops only as part of their resources (no apps)
	2) They have less than 20 Desktops (causing the app to try to show a simple view)
	3) HomeTab is disabled
	4) SimpleView is disabled
*/
export function checkDesktopsAvailabilityForDaasVision(props: IsAvailable<{}>): boolean {
	const { workspaceConfiguration, userContext, resourceContext } = props;
	const shouldDisplayDesktop =
		hasUIProperty(workspaceConfiguration, UIProperty.Desktops) &&
		shouldDisplayDesktopSection(resourceContext.resources, workspaceConfiguration);

	// Make this a function to delay the evaluation of isDesktopOnlyHomepage
	const _isDesktopOnlyHomepage = () =>
		isDesktopOnlyHomepage(
			workspaceConfiguration,
			userContext.isIWSEnabled,
			resourceContext
		);

	return (
		shouldDisplayDesktop &&
		(userContext.isDaasVisionEnabled
			? !isSimpleView(resourceContext.resources) //No need to check for isDesktopOnlyHomepage
			: !_isDesktopOnlyHomepage())
	);
}
