import {
	fromThemeColors,
	styleUtils,
	ThemeColor,
	typography,
} from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const ellipsisCss = css({ ...styleUtils.ellipsis });

export const StyledHeader = styled.h3`
	width: 100%;
	margin: 0 0 0 10px;
	font-size: 24px;
	font-weight: ${typography.weights.light};
	color: ${fromThemeColors(ThemeColor.primaryText)};
	${ellipsisCss};
`;
