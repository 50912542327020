import { AnalyticsEvent, Metadata, trackAnalyticsEvent } from 'analytics';
import { clientType, getClientType } from 'Environment/getClientType';
import { BrowserExtensionEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
const code = 'WSUI_BROWSER_EXTENSION';

const preparePayload = (
	type: BrowserExtensionEventType,
	metadata?: Metadata
): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});
const createBrowserExtensionAnalyticsReporter = () => {
	return {
		getBrowserExtensionAppLaunchEvent: () =>
			preparePayload(BrowserExtensionEventType.BrowserExtensionAppLaunch),
		getBrowserExtensionAppLaunchErrorEvent: (
			errorName: string,
			errorMessage: string,
			client: clientType
		): AnalyticsEvent =>
			preparePayload(BrowserExtensionEventType.BrowserExtensionAppLaunchError, {
				errorName,
				errorMessage,
				client,
			}),
		getIcaLaunchEvent: () =>
			preparePayload(BrowserExtensionEventType.BrowserExtensionIcaLaunch),
		getLeaseLaunchEvent: () =>
			preparePayload(BrowserExtensionEventType.BrowserExtensionLeaseLaunch),
		getLeaseLaunchEventWhenLeaseFallbackToIca: () =>
			preparePayload(
				BrowserExtensionEventType.BrowserExtensionLeaseLaunchLeaseFallbackToIca
			),
		getLeaseLaunchEventWhenIcaFallbackToLease: () =>
			preparePayload(
				BrowserExtensionEventType.BrowserExtensionLeaseLaunchIcaFallbackToLease
			),
		getLeaseLaunchEventWhenCloudOffline: () =>
			preparePayload(BrowserExtensionEventType.BrowserExtensionLeaseLaunchCloudOffline),
		getCloudOfflineEventWhenGetUserDetailsFailed: () =>
			preparePayload(
				BrowserExtensionEventType.BrowserExtensionCloudOfflineGetUserDetailsFailed
			),
		getCloudOfflineEventWhenUserTriggered: () =>
			preparePayload(BrowserExtensionEventType.BrowserExtensionCloudOfflineUserTriggered),
	};
};

export function trackBrowserExtensionError(error: any) {
	trackAnalyticsEvent(
		browserExtensionAnalyticsReporter.getBrowserExtensionAppLaunchErrorEvent(
			error ? error.name : 'BE error',
			error ? error.message : 'Error occurred while launching app using BE',
			getClientType()
		)
	);
}

export const browserExtensionAnalyticsReporter =
	createBrowserExtensionAnalyticsReporter();
