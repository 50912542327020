import { Capability, CapabilityRegistration } from './capability';
import {
	PlatformDependencies,
	PlatformDependencySet,
	ScopedPlatformDependencySet,
} from './dependencies';
import { DependencyContainer } from './dependencies/container';
import { resolveFromGlobal } from './dependencies/resolveFromGlobal';

export interface IntegrationEnabledContext {
	hasEnabledIntegrationByCapability<T>(capability: Capability<T>): boolean;
}

export type ScopedContainer = DependencyContainer<
	PlatformDependencySet | ScopedPlatformDependencySet
>;

export interface IntegrationModuleContext extends IntegrationContext {
	/**
	 * A dependency container that has been scoped to the created integration.
	 * Includes access to all available {@link PlatformDependencies}.
	 */
	scopedContainer: ScopedContainer;
}

type LanguageModuleResolver = () => Promise<any>;
interface TranslationModules {
	de?: LanguageModuleResolver;
	en?: LanguageModuleResolver;
	es?: LanguageModuleResolver;
	fi?: LanguageModuleResolver;
	fr?: LanguageModuleResolver;
	it?: LanguageModuleResolver;
	ja?: LanguageModuleResolver;
	ko?: LanguageModuleResolver;
	nl?: LanguageModuleResolver;
	['zh-CN']?: LanguageModuleResolver;
	ru?: LanguageModuleResolver;
	['pt-BR']?: LanguageModuleResolver;
	['zh-TW']?: LanguageModuleResolver;
}

interface IntegrationDefinition<ModulesResolver extends ModuleMap>
	extends IntegrationManifest<ModulesResolver> {
	/**
	 * The private symbol provides privileged access to your integration's scoped
	 * container. Unlike the manifest, which represents your integration's public
	 * api, the private symbol should not be exported from your package. See
	 * {@link scopedContainer}
	 */
	privateSymbol?: Symbol;
}

/**
 * @example
 * const myIntegration = createIntegration({
 *   id: 'my-integration',
 *   modulesResolver: {
 *     myLeftNavLink: () => import('./MyLeftNavLink')
 *   }
 *   capabilities: [
 *     IntegrationCapability.leftNavigation.primaryNavLink.register('myLeftNavLink')
 *   ],
 * })
 */
export function createIntegration<ModulesResolver extends ModuleMap>(
	integrationDefinition: IntegrationDefinition<ModulesResolver>
): CreatedIntegration<ModulesResolver> {
	const { privateSymbol, ...manifest } = integrationDefinition;
	return {
		...manifest,
		register: () => {
			resolveFromGlobal(PlatformDependencies.RegisterIntegration)({
				integrationManifest: manifest,
				privateSymbol,
			});
		},
	};
}

type ModuleMap = Record<string, (context: IntegrationModuleContext) => any>;

export interface IntegrationManifest<ModulesResolver extends ModuleMap = ModuleMap> {
	id: string;
	/** The public discovery endpoint name from Workspace configuration. */
	requiredEndpointServiceName?: string;
	/**
	 * Registers a cache version for creating cache buckets scoped to your integration.
	 * All unregistered caches and previous cache versions are cleaned up by the platform on application load.
	 * Bump the cacheVersion value as necessary to handle breaking changes in models stored in the cache.
	 *
	 * @example cacheVersion: '1'
	 */
	cacheVersion?: string;
	/**
	 * A record of modules that can be used to register capabilities.
	 *
	 * @example modulesResolver: { someService: () => import('./someActionsService') } }
	 */
	modulesResolver: ModulesResolver;
	/**
	 * Registers a list of capabilities that can be fulfilled by a specified
	 * key in modulesResolver.
	 *
	 * @example capabilities: [ IntegrationCapability.route.register('someService') ]
	 */
	capabilities?: CapabilityRegistration<
		any,
		Extract<keyof ModulesResolver, string>,
		any
	>[];
	/**
	 * Enables or disables your integration.
	 *
	 * @default true
	 */
	isEnabled?(context: IntegrationEnabledContext): boolean;
	/**
	 * Register a map of locale json modules that will be loaded into @citrite/translate
	 * when the integration is resolved.
	 * Your integration id will be used for the i18n namespace.
	 *
	 * @example
	 * translationModules: {
	 *   en: () => import('./locales/en.json'),
	 *   es: () => import('./locales/es.json'),
	 *   fr: () => import('./locales/fr.json'),
	 * }
	 */
	translationModules?: TranslationModules;
}

export interface CreatedIntegration<ModulesResolver extends ModuleMap = ModuleMap>
	extends IntegrationManifest<ModulesResolver> {
	register: () => void;
}

export interface LoadableIntegration<T, E = any> {
	moduleKey: string;
	integrationId: string;
	reload(): void;
	resolver: Promise<T>;
	loading: boolean;
	error?: E;
	value?: T;
	enabled: boolean;
}

export type LoadableCapability<
	T,
	TError = any,
	TExpectedMetadata = never
> = LoadableIntegration<T, TError> & {
	registration: CapabilityRegistration<T, string, TExpectedMetadata>;
};

export interface IntegrationContext {
	resolveById<T = any>(integrationId: string, moduleId: string): LoadableIntegration<T>;
	resolveByCapability<T = any, TExpectedMetadata = never>(
		capability: Capability<T, TExpectedMetadata>
	): LoadableCapability<T, any, TExpectedMetadata>[];
	hasEnabledIntegrationByCapability(capability: Capability): boolean;
}

export const getIntegrationContext = () =>
	resolveFromGlobal(PlatformDependencies.Integrations);
