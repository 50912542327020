import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledIconsRow = styled.div`
	align-items: center;
	display: flex;
`;

export const StyledIcon = styled.div`
	display: flex;
	margin-left: ${layout.mediumLargeSpace};
`;
