import { LaunchResourceOptions } from 'Environment';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export type ExtensionCommunicationAdapter = (
	workload: any,
	type: ChromeAppMessageType
) => Promise<ChromeAppResponse>;

export interface ChromeAppMessage {
	header: {
		type: ChromeAppMessageType;
	};
	payload: {
		correlationId: string;
		[key: string]: any;
	};
}

export interface ChromeAppWrapperMessage {
	workload?: any;
	type: ChromeAppMessageType;
	timeout?: number;
	retries?: number;
}

export type ChromeAppMessageType =
	| 'icaLaunch'
	| 'leaseLaunch'
	| 'setCapabilities'
	| 'logOff'
	| 'initiateStore'
	| 'getStoreHealth'
	| 'getLeasingProperties'
	| 'notifyAuthenticationTimer'
	| 'sendEventHubData';

export type AuthenticationTimerOperationType = 'start' | 'stop';

export interface ChromeAppResponse {
	messageType: ChromeAppMessageType;
	status: string;
	errorCode?: any;
	errorReason?: ChromeAppErrorReason;
	result?: any;
	correlationId?: string;
}

export enum ChromeAppErrorReason {
	Unavailable = 'Unavailable',
	TimeOut = 'TimeOut',
	Unsuccessful = 'Unsuccessful',
	ChromeApp_PostMessage_Failure = 'ChromeApp_PostMessage_Failure',
	WrongTransactionId = 'Wrong_Transaction_Id',
}

export interface ChromeAppLaunchResourceOptions extends LaunchResourceOptions {
	onIcaDownload: (resource: Resource) => void;
}

export const ChromeAppMessageIdKey = 'ChromeAppMessageId';

export const chromeAppLocalLaunchPreference = 'chromeAppLocalLaunchPreference';

export const LeasePropertiesKey = 'LeasePropertiesKey';
