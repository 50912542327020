import {
	hasFeatureCanary,
	isVanityDomain,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { BrowserExtensionErrorReason } from 'Environment/BrowserExtension/types';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	isChrome,
	isChromeAppOrChromeOS,
	isDesktop,
	isDesktopBrowser,
	isEdge,
	isFirefox,
	isLinux,
	isMac,
	isNativeClient,
	isSafari,
	isWindowsPlatform,
} from 'Environment/launchResource/device';

export function logBrowserExtensionError(error: any) {
	if (error.name !== BrowserExtensionErrorReason.Unavailable) {
		logError(error, {
			tags: {
				feature: 'browserExtension',
				status: error.status,
			},
		});
	}
}

export function isBrowserExtensionCompatible(
	workspaceConfiguration: WorkspaceConfiguration
) {
	return (
		!isNativeClient() &&
		isDesktop() &&
		((isWindowsPlatform() &&
			(isChromeRuntimeAvailable() ||
				isFirefoxExtensionSupported(workspaceConfiguration))) ||
			(isMac() && isBrowserExtensionCompatibleOnMac()) ||
			(isLinux() && isBrowserExtensionCompatibleOnLinux(workspaceConfiguration)))
	);
}

function isBrowserExtensionCompatibleOnLinux(
	workspaceConfiguration: WorkspaceConfiguration
) {
	return (
		isChromeRuntimeAvailable() &&
		hasFeatureCanary(
			workspaceConfiguration,
			FeatureFlag.BrowserExtensionHybridLaunchLinux
		)
	);
}

function isBrowserExtensionCompatibleOnMac() {
	return isChromeRuntimeAvailable() || isSafari();
}

const isFirefoxExtensionSupported = (workspaceConfiguration: WorkspaceConfiguration) => {
	return (
		isFirefox() &&
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.FirefoxExtensionEnabled)
	);
};

export function isChromeRuntimeAvailable() {
	return isChrome() && !!window.chrome?.runtime?.sendMessage;
}

function isBrowserExtensionSupportedOnPlatform() {
	return (
		/**
		 * !isChromeAppOrChromeOS() is used here to block the usage and prompting the user to install the browser extension on ChromeOS.
		 * When any store is loaded in a browser on ChromeOS, the userAgent will contain 'CrOS' which is used to identify the platform.
		 * Whereas, the Citrix Workspace Chrome App (from the Chrome Store) has 'Windows' in the userAgent in the place of 'CrOS'
		 * and returns true for isDesktopBrowser(). This is the reason for the additional check for isChromeAppOrChromeOS().
		 */
		!isChromeAppOrChromeOS() &&
		isDesktopBrowser() &&
		(isWindowsPlatform() || isMac() || isLinux())
	);
}

function doesBrowserSupportExtensionDetection(): boolean {
	return isBrowserExtensionSupportedOnPlatform() && (isChrome() || isEdge());
}

export function shouldPromptUserToInstallBrowserExtension(
	workspaceConfiguration: WorkspaceConfiguration
): boolean {
	return (
		hasFeatureCanary(
			workspaceConfiguration,
			FeatureFlag.PromptUserToInstallBrowserExtension
		) &&
		doesBrowserSupportExtensionDetection() &&
		!isChromeRuntimeAvailable()
	);
}

/*
  This will return true based on the following combination:
  { FeatureFlag ,  OS, Browser }
*/
export function isHybridAADSSOSupported(
	workspaceConfiguration: WorkspaceConfiguration
): boolean {
	return (
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.EnableHybridAADSSO) &&
		isHybridSSOSupportedOnThisPlatform()
	);
}

/*
  Currently we are supporting only ( Windows ,  Chrome/Edge)
  We can update this check to include ( Mac\Linux , FireFox) 
  when they are ready and tested.
*/
function isHybridSSOSupportedOnThisPlatform() {
	return (
		!isNativeClient() && isDesktop() && isWindowsPlatform() && isChromeRuntimeAvailable()
	);
}

// allow-unused-export : This function will be used in a later PR
export function shouldEnableBrowserExtensionForVanityDomains(
	workspaceConfiguration: WorkspaceConfiguration
): boolean {
	const isBrowserExtensionSupportedForThisBrowser = () =>
		isChrome() || isEdge() || isSafari();

	return (
		hasFeatureCanary(
			workspaceConfiguration,
			FeatureFlag.EnableBrowserExtensionOnVanityDomains
		) &&
		isVanityDomain() &&
		isBrowserExtensionSupportedOnPlatform() &&
		isBrowserExtensionSupportedForThisBrowser()
	);
}

/*
  For Cloud store this will return the storeIdentifiers from the workspaceConfiguration.

  For ONPREM store this will return the customerId and storeGuid 
  based on the hostname and path in the url.

  Example: is the url is https://nilesh-stf.qablr.com/Citrix/Storeweb
		 {
			customerId : 'nilesh-stf.qablr.com'
			storeGuid : 'Store'
		}
*/
export function getStoreIdentifiers(workspaceConfiguration: WorkspaceConfiguration): {
	customerId: string;
	storeGuid: string;
} {
	// The legacy on-prem code is building CustomerId and StoreId based on the
	// hostname and /StoreWeb/ path in the url
	return IS_ON_PREM
		? {
				customerId: window.location.hostname,
				storeGuid: window.location.pathname
					.replace('/Citrix/', '')
					.replace('Web/', '')
					.replace('/', ''),
		  }
		: workspaceConfiguration.storeIdentifiers;
}
