import * as React from 'react';
import { css } from 'aphrodite';
import { styles } from './styles';

export interface Props {
	isOpen: boolean;
}

interface State {
	contentHeight?: number;
}

export class ComponentHeight extends React.Component<Props, State> {
	private bodyContent: HTMLElement;
	private mutationObserver: MutationObserver;
	public state: State = {};

	public render() {
		const contentHeight =
			this.state.contentHeight != null ? this.state.contentHeight + 'px' : 'auto';
		const displayHeight = this.props.isOpen ? contentHeight : '0px';

		return (
			<div
				ref={this.handleRef}
				className={css(styles.section, this.props.isOpen && styles.open)}
				style={{ maxHeight: displayHeight }}
			>
				{this.props.children}
			</div>
		);
	}

	private handleRef = (div: HTMLDivElement) => (this.bodyContent = div);

	private setHeight() {
		const contentHeight = Math.max(
			this.bodyContent.scrollHeight,
			this.bodyContent.offsetHeight,
			this.bodyContent.clientHeight
		);
		if (contentHeight !== this.state.contentHeight) {
			this.setState({ contentHeight });
		}
	}

	public componentDidMount() {
		this.mutationObserver = new MutationObserver(() => {
			this.setHeight();
		});
		this.mutationObserver.observe(this.bodyContent, {
			attributes: true,
			childList: true,
			characterData: true,
			subtree: true,
		});
		this.setHeight();
	}

	public componentWillUnmount() {
		this.mutationObserver.disconnect();
	}
}
