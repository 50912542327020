import {
	hasFeatureCanary,
	isVanityDomain,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { FeatureFlag } from './FeatureFlag';

/**
 * Check whether non-ica apps would be hidden in vanity domain
 * @param workspaceConfiguration workspace configuration
 * @returns Boolean indicating whether non-ica apps would be hidden in vanity domain
 */
export function onlyIcaAppsAllowedInVanityDomain(
	workspaceConfiguration: WorkspaceConfiguration
) {
	if (!isVanityDomain()) {
		return false;
	}
	return !hasFeatureCanary(workspaceConfiguration, FeatureFlag.NoAppFilterInVanityDomain);
}

/**
 * Check whether Files is enabled in vanity domain
 * @param workspaceConfiguration workspace configuration
 * @returns Boolean indicating whether Files is enabled in vanity domain
 */
export function isFilesEnabledInVanityDomain(
	workspaceConfiguration: WorkspaceConfiguration
) {
	if (!isVanityDomain()) {
		return true;
	}
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.EnableFilesInVanityDomain);
}

/**
 * Check whether Iws is enabled in vanity domain
 * @param workspaceConfiguration workspace configuration
 * @returns Boolean indicating whether Iws is enabled in vanity domain
 */
export function isIwsEnabledInVanityDomain(
	workspaceConfiguration: WorkspaceConfiguration
) {
	if (!isVanityDomain()) {
		return true;
	}
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.EnableIwsInVanityDomain);
}
