import { LayoutGridWidgetMapping, WidgetReference } from './ComposablePage';

export function getMappingWidgets(mapping: LayoutGridWidgetMapping): WidgetReference[] {
	const widgetList: WidgetReference[] = [];

	mapping.rows.forEach(row => {
		row.columns.forEach(column => {
			column.widgets.forEach(widgetRef => {
				widgetList.push(widgetRef);
			});
		});
	});

	return widgetList;
}
