import { allActionsRoute } from 'App/Actions/allActionsRoute';
import { appsRoute } from 'App/Apps';
import { composableHomeRoute } from 'App/ComposableHome';
import { desktopsRoute } from 'App/Desktops';
import { NativeWidgetRoute } from 'App/NativeWidget/NativeWidgetRoute';
import { LeftNavRoute } from 'Router/ReactRoute';
import { activityRoute } from './Activity';
import { customLinkBannerRoute } from './CustomLinkBanner';
import { searchRoute } from './Search';
import { settingsRoute } from './Settings';

export const appRoutes: LeftNavRoute[] = [
	composableHomeRoute,
	NativeWidgetRoute,
	allActionsRoute,
	appsRoute,
	desktopsRoute,
	activityRoute,
	settingsRoute,
	searchRoute,
	customLinkBannerRoute,
];

export const horizontalAppRoutes: LeftNavRoute[] = [
	composableHomeRoute,
	appsRoute,
	desktopsRoute,
	activityRoute,
	customLinkBannerRoute,
];
