export interface VersionInfo {
	environment: string;
	revision: string;
	release: string;
	previousRelease?: string;
	isProduction: () => boolean;
}

let cachedVersionInfo: VersionInfo;

const previousReleaseStorageKey = 'wsuiPreviousRelease';
const NOT_AVAILABLE = 'NOT_AVAILABLE';

function getVersionInfo(): VersionInfo | undefined {
	if (cachedVersionInfo) {
		return cachedVersionInfo;
	}
	const buildTagNode = Array.from(document.body.childNodes).find(node => {
		return node.nodeType === node.COMMENT_NODE && node.textContent?.includes('buildtag');
	});

	if (!buildTagNode) {
		return undefined;
	}

	const matchResult = /buildtag ([\s\S]+)/.exec(buildTagNode.textContent!);
	if (!matchResult) {
		return undefined;
	}

	const [, json] = matchResult;

	try {
		const { branch, version: revision, ...buildTag } = JSON.parse(json);
		let environment: string = branch;
		const release: string = branch?.replaceAll('/', '_');
		const currentReleaseWithBuildNo = release
			? release + '_' + buildTag.build
			: NOT_AVAILABLE;
		const previousRelease =
			localStorage.getItem(previousReleaseStorageKey) || NOT_AVAILABLE;
		localStorage.setItem(previousReleaseStorageKey, currentReleaseWithBuildNo);
		if (environment && (environment === 'master' || environment.startsWith('release'))) {
			environment = 'release';
		}

		const isProduction = () => branch.startsWith('release');
		cachedVersionInfo = {
			...buildTag,
			environment,
			revision,
			release: currentReleaseWithBuildNo,
			previousRelease,
			isProduction,
		};
		return cachedVersionInfo;
	} catch {
		return undefined;
	}
}

export const versionInfo = { get: getVersionInfo };
