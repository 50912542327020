import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	EmptyDocumentIcon,
	EmptyState,
	MainRegion,
	Modal,
	ModalProps,
} from '@citrite/web-ui-component';
import { OpenDesktopButton } from 'Components/ResourceTile/ViewDetails/OpenDesktopButton';
import { RestartDesktopButton } from 'Components/ResourceTile/ViewDetails/RestartDesktopButton';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { Header } from './Header';
import { styles } from './styles';

export interface Props extends ModalProps {
	resource: Resource;
}

export class ViewDesktopDetails extends React.Component<Props> {
	public render() {
		return (
			<Modal {...this.props}>
				<Header {...this.props} />
				<MainRegion className={css(styles.body)}>
					<div className={css(styles.navBar)}>
						{this.props.resource.poweroffurl && (
							<>
								<RestartDesktopButton resource={this.props.resource} />
								<span className={css(styles.divider)} />
							</>
						)}
						<OpenDesktopButton resource={this.props.resource} />
					</div>
					<div className={css(styles.content)}>
						{this.props.resource.description || this.props.resource.desktophostname ? (
							<div className={css(styles.details)}>
								{this.props.resource.desktophostname && (
									<div className={css(styles.machineName)}>
										<strong>{t('Workspace:machine_name')}</strong>{' '}
										{this.props.resource.desktophostname}
									</div>
								)}
								{this.props.resource.description}
							</div>
						) : (
							<EmptyState icon={EmptyDocumentIcon}>
								{t('Workspace:empty_desktop_details')}
							</EmptyState>
						)}
					</div>
				</MainRegion>
			</Modal>
		);
	}
}
