import { createCapability } from '../capability';

export interface RouteContext {
	setMobileMastheadTitle(mastheadTitle: React.ReactNode): void;
}

/**
 * Adds a new page to Workspace.
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.route}
 *
 * @example
 * {
 *   useIsAvailable: () => workspaceUser.get().isIWSEnabled,
 *   component: () => <AllActionsPage />
 * }
 * @category Integration Capability
 */
export interface RouteCapability {
	component: React.ComponentType<RouteContext>;
	/**
	 * Can be used to disable your route.
	 *
	 * This function is executed within a react function component
	 * which makes it capable of evaluating React hooks.
	 *
	 * @default true
	 */
	useIsAvailable?: () => boolean;
}

export interface RouteCapabilityMetadata {
	/**
	 * Any valid URL path or array of paths.
	 *
	 * @example ['/my/integration/route', '/myshorturl']
	 */
	path: string | string[];
}

export const RouteCapabilityToken = createCapability<
	RouteCapability,
	RouteCapabilityMetadata
>('wsui_route_capabilty');
