import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { container } from 'Workspace/DependencyManagement';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { UserContext } from 'Workspace/UserContext';

export const maximumResourceCountForSimpleView = 20;

export const isSimpleViewInDaasVision = (
	resources: Resource[],
	userContext: UserContext
) => userContext.isDaasVisionEnabled && isSimpleView(resources);

export const isSimpleView = (resources?: Resource[]): boolean => {
	return (
		resources?.length <= maximumResourceCountForSimpleView &&
		!isSimpleViewDisabled(resources)
	);
};

const isSimpleViewDisabled = (resources: Resource[]) => {
	return resources?.length > 0 && isSimpleViewDisabledInCloudConsole();
};

export const isSimpleViewDisabledInCloudConsole = (): boolean => {
	const workspaceConfiguration = container
		.resolve(PlatformDependencies.WorkspaceConfiguration)
		.get();
	return workspaceConfiguration?.userInterface?.enableSimpleView === 'false';
};
