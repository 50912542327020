import { NativePlatform } from '@citrite/workspace-ui-platform';
import URI from 'urijs';
import { installToolFunction } from 'javascript/interactiveTools';
import { removeFromSessionStorage } from 'javascript/sf/Storage';

export function installNativeSimulation(nativePlatformStorageKey: string) {
	installToolFunction('simulateNative', () => {
		removeFromSessionStorage(nativePlatformStorageKey);
		const uri = new URI(location.href).search({
			native: NativePlatform.Simulated,
		});
		location.assign(uri.toString());
	});

	installToolFunction('removeNativeSimulation', () => {
		removeFromSessionStorage(nativePlatformStorageKey);
		const uri = new URI(location.href).removeQuery('native');
		location.assign(uri.toString());
	});
}
