import * as React from 'react';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { createFeatureRolloutClient } from 'FeatureRolloutService/createFeatureRolloutClient';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';

export const useFeatureRolloutClient = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { cacheFactory } = useCacheContext();

	return React.useMemo(() => {
		const useFeedDefaultOn = hasFeatureCanary(
			workspaceConfiguration,
			FeatureFlag.FeedDefaultOn
		);

		const endpointService = getEndpointsService(workspaceConfiguration, 'featureRollout');

		if (!endpointService) {
			return null;
		}

		const endpointSupplier = createEndpointSupplier(endpointService.discoveryUrl);
		const ssoProxyClient = environment.createSSOProxyClient(
			workspaceConfiguration.authManager.proxyUrl
		);

		return createFeatureRolloutClient(
			endpointSupplier,
			ssoProxyClient,
			cacheFactory.createEncryptedCacheBucket(BucketManifest.featureRollout),
			cacheFactory.createUnencryptedCacheBucket(BucketManifest.featureFlagsCache, {
				perUser: true,
			}),
			workspaceConfiguration.storeIdentifiers.customerId,
			workspaceConfiguration.storeIdentifiers.storeGuid,
			useFeedDefaultOn
		);
	}, [workspaceConfiguration, cacheFactory]);
};
