import {
	EncryptedCacheBucket,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { nullCacheBucket } from 'Workspace/Cache/createCacheBucket';

const FEATURE_ENABLED = 'true';
export const KEY_PREFIX = 'enhanced-cache';

export const isEnhancedCachingEnabled = (config: WorkspaceConfiguration) =>
	config?.userInterface?.enableEnhancedCaching?.toLowerCase() === FEATURE_ENABLED;

export const withEnhancedCaching = (
	bucket: EncryptedCacheBucket,
	config: WorkspaceConfiguration
): EncryptedCacheBucket => {
	if (!isEnhancedCachingEnabled(config)) {
		return nullCacheBucket;
	}

	const prefixCacheKey = (cacheKey: string) => `${KEY_PREFIX}-${cacheKey}`;

	return {
		getEncrypted: cacheKey => bucket.getEncrypted(prefixCacheKey(cacheKey)),

		setEncrypted: (cacheKey, data, maxAgeInDays) =>
			bucket.setEncrypted(prefixCacheKey(cacheKey), data, maxAgeInDays),

		fetchEncryptedCacheFirst: (cacheKey, backgroundResolver) =>
			bucket.fetchEncryptedCacheFirst(prefixCacheKey(cacheKey), backgroundResolver),

		removeCacheEntry: (cacheKey: string) =>
			bucket.removeCacheEntry(prefixCacheKey(cacheKey)),
	};
};
