import React from 'react';
import { useTheme } from '@emotion/react';
import { isEmpty } from 'lodash';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { MandateNativeScreen } from 'Workspace/MandateNative/MandateNativeScreen';
import { getCurrentPlatformLinkData } from './utils';

export const MandateNativeApp = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const theme = useTheme();
	const { canDownload, downloadLink } =
		getCurrentPlatformLinkData(workspaceConfiguration);
	const downloadDetails = {
		canDownload,
		downloadLink,
	};

	return (
		<>
			{!isEmpty(workspaceConfiguration) && (
				<MandateNativeScreen
					backgroundColor={theme?.branding?.styles?.HeaderBackgroundColor}
					downloadDetails={downloadDetails}
					receiverConfigurationDownloadUrl={
						workspaceConfiguration?.userInterface?.receiverConfiguration?.downloadURL
					}
				/>
			)}
		</>
	);
};
