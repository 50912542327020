import { post } from '@citrite/http';
import {
	contentTypeJson,
	MachineOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { MachineOperationRequestData } from 'App/Activity/Network/MachineOperationData';

export const powerManagementOperationRemotely = async (
	endpoint: string,
	machineId: string
) => {
	const body: string = JSON.stringify(new MachineOperationRequestData(machineId));
	return post<MachineOperationResponse>(endpoint, body, contentTypeJson);
};
