import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { isDesktopOnlyHomepage } from 'App/isDesktopOnlyHomepage';
import { isSimpleViewInDaasVision } from 'App/Navigation/utils';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { IsAvailable } from 'Router/ReactRoute';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const shouldDisplayAppSection = (
	resources: Resource[],
	workspaceConfiguration: WorkspaceConfiguration
) => {
	if (
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.NoEmptyAppsOrEmptyDesktopSection)
	) {
		return resources?.some(({ isdesktop }) => !isdesktop);
	}
	return true;
};

export function checkAppsAvailability(props: IsAvailable<{}>): boolean {
	return (
		hasUIProperty(props.workspaceConfiguration, UIProperty.Apps) &&
		!isDesktopOnlyHomepage(
			props.workspaceConfiguration,
			props.userContext.isIWSEnabled,
			props.resourceContext
		) &&
		shouldDisplayAppSection(
			props.resourceContext.resources,
			props.workspaceConfiguration
		) &&
		!isSimpleViewInDaasVision(props.resourceContext.resources, props.userContext)
	);
}
