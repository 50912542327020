/**
 * First party left nav link positions.
 */
export enum LeftNavPositions {
	dashboard = 0,
	feed = 100,
	actions = 200,
	apps = 300,
	filesOnNativeMobile = 400,
	desktops = 500,
	filesOnDesktop = 600,
	settings = 700,
	activityManager = 800,
	customLinks = 900,
}

/**
 * First party settings nav link positions.
 */
export enum SettingsLeftNavPositions {
	editProfile = 0,
	regionalSettings = 200,
	filesSettings = 300,
	securityAndSignIn = 100,
	tools = 400,
	advanced = 500,
}
