import * as React from 'react';
import { Event, EventBus } from 'Workspace/EventBus';
import { WithEventBus } from './EventBusContext';

export interface SubscribeOnMountProps extends EventSubscriberProps {
	eventBus: EventBus;
}
class SubscribeOnMount extends React.Component<SubscribeOnMountProps> {
	private _unsubscribe: () => void;

	public componentDidMount() {
		this._unsubscribe = this.props.eventBus.subscribe(this.props.listener);
	}

	public componentWillUnmount() {
		this.subscribe();
	}

	public componentDidUpdate(prevProps: SubscribeOnMountProps) {
		if (
			this.props.listener !== prevProps.listener ||
			this.props.eventBus !== prevProps.eventBus
		) {
			this.subscribe();
		}
	}

	public render(): null {
		return null;
	}

	private unsubscribe() {
		if (this._unsubscribe) {
			this._unsubscribe();
		}
	}

	private subscribe() {
		this.unsubscribe();
		this._unsubscribe = this.props.eventBus.subscribe(this.props.listener);
	}
}

type EventBusProviderType = React.ComponentType<{
	children: (eventBus: EventBus) => React.ReactNode;
}>;

export interface EventSubscriberProps {
	listener: (event: Event<any>) => void;
	eventBusProvider?: EventBusProviderType;
}
export function EventSubscriber(props: EventSubscriberProps) {
	const EventBusProvider = props.eventBusProvider || WithEventBus;
	return (
		<EventBusProvider>
			{eventBus => <SubscribeOnMount {...props} eventBus={eventBus} />}
		</EventBusProvider>
	);
}
