import { v4 as uuid } from 'uuid';
import { leaseCallHomeInfo } from 'LeaseWorkflow/CallHomeService';

export function customHeadersForCallHome(url: string) {
	const wsuiConfig = leaseCallHomeInfo.objectsForShield.wsuiLastKnowConfig;
	return {
		...commonCustomHeaders(url),
		'Content-Type': 'application/json;charset=UTF-8',
		'X-XD-CustomerId':
			wsuiConfig[leaseCallHomeInfo.CONF_ITEM_STORE_IDENTIFIERS][
				leaseCallHomeInfo.CONF_ITEM_CUSTOMER_ID
			],
		'Citrix-TransactionId': uuid(),
	};
}

export function customHeadersForClsync(url: string, headers: string) {
	const headersForClync: { [key: string]: string } = { ...commonCustomHeaders(url) };
	const keyValPairs = headers['split']('\n');
	for (let i = 0; i < keyValPairs.length; i++) {
		const keyArray = keyValPairs[i]['split'](': ');
		headersForClync[keyArray[0]] = keyArray[1];
	}
	return headersForClync;
}

export function generateUrlForAxios(urlName: string) {
	return (
		globalThis['location']['origin'] +
		globalThis['location']['pathname'] +
		(globalThis['location']['pathname'].endsWith('/')
			? 'Sso/Proxy?destination='
			: '/Sso/Proxy?destination=') +
		encodeURI(urlName)
	);
}

function commonCustomHeaders(url: string) {
	const wsuiConfig = leaseCallHomeInfo.objectsForShield.wsuiLastKnowConfig;
	return {
		'X-Citrix-IsUsingHTTPS': 'Yes',
		'Citrix-CustomerId':
			wsuiConfig[leaseCallHomeInfo.CONF_ITEM_STORE_IDENTIFIERS][
				leaseCallHomeInfo.CONF_ITEM_CUSTOMER_ID
			],
		'Citrix-InstanceId':
			wsuiConfig[leaseCallHomeInfo.CONF_ITEM_STORE_IDENTIFIERS][
				leaseCallHomeInfo.CONF_ITEM_STORE_GUID
			],
		'Citrix-UserId': leaseCallHomeInfo.objectsForShield.lastUserDetails['userId'],
		'Citrix-WSP-Proxy-URL': url,
		'Csrf-Token': getCookie('CsrfToken'),
	};
}

function getCookie(name: string): string {
	/*
	 * let document.cookie = 'CsrfToken=ixPdqzsiqNo3kwq1kjnwQBfxTSBDDasNEen_SBuv0SQ-; _ga=GA1.2.1937462600.1679047432',
	 * when name = 'CsrfToken', parts will be ['', 'ixPdqzsiqNo3kwq1kjnwQBfxTSBDDasNEen_SBuv0SQ-; _ga=GA1.2.1937462600.1679047432'].
	 * At last, the returned result is the value of CsrfToken which is 'ixPdqzsiqNo3kwq1kjnwQBfxTSBDDasNEen_SBuv0SQ-'
	 */
	const value = `; ${document.cookie}`;
	const parts = value['split'](`; ${name}=`);
	return parts.length === 2 ? parts['pop']()['split'](';')['shift']() : null;
}
