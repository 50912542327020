import { fromThemeColors, layout, palette, typography } from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ActivityTitle } from 'App/Activity/ActivityTitle/ActivityTitle';
import { StyledActivityCardHeaderContainerProps } from 'App/Activity/components/ApplicationSectionHeader';

export const StyledActivityCardHeaderContainer = styled.div<StyledActivityCardHeaderContainerProps>`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	position: relative;
	${props => props.isClickable && clickableStyles}
`;

export const clickableStyles = css`
	cursor: pointer;
	transition: background-color 0.3s ease;
	&:hover {
		background-color: ${palette.grey200};
		border-radius: ${layout.tinySpace};
	}
`;

export const StyledTitle = styled(ActivityTitle)<{ isSmallFormFactor: boolean }>`
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.semiBold};
	width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
	color: ${fromThemeColors('primaryText')};
	padding: ${layout.smallSpace} 0px;
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		`
		width: 80%;
		padding: ${layout.mediumSpace} 0px;
	`}
`;

export const StyledActivityBodySubContent = styled.div`
	display: flex;
	align-items: center;
`;
