import { EventType, TelemetryEvent } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export enum SortOrder {
	Recency = 'recency',
	Recommended = 'recommended',
}

export interface SortOrderChangeEvent extends TelemetryEvent {
	type: EventType.NotificationFeed_SortOrderChange;
	newSortOrder: SortOrder;
}

export interface PageLoadEvent extends TelemetryEvent {
	type: EventType.NotificationFeed_PageLoad;
	sortOrder: SortOrder;
}

export interface LoadNewFeedCardsEvent extends TelemetryEvent {
	type: EventType.NotificationFeed_LoadNewFeedCards;
	sortOrder: SortOrder;
}

export interface ScrollLoadEvent extends TelemetryEvent {
	type: EventType.NotificationFeed_ScrollLoad;
}
