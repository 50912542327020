import { useEffect, useState } from 'react';
import { media, useBreakpoints } from '@citrite/web-ui-component';
import { UnencryptedCacheBucket } from '@citrite/workspace-ui-platform';
import { cacheKey } from 'Components/FTU/constants';
import {
	isAnyResourceManuallySubscribed,
	shouldDisplayFTUBasedOnResourceAttributes,
	useFTUShownCache,
} from 'Components/FTU/Utils';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

// this export is required for unit tests
// allow-unused-export
export function calculateIfShouldDisplayFTUModal(
	ftuDisplayedCacheFlag: boolean,
	resources: Resource[],
	subscriptionsEnabled: boolean,
	screenSize: media
): boolean {
	const ret =
		!ftuDisplayedCacheFlag &&
		resources &&
		shouldDisplayFTUBasedOnResourceAttributes(
			resources,
			subscriptionsEnabled,
			screenSize
		);

	return ret;
}

/**   
   When we avoid showing the FTU Modals, the cache flag key may 
   not get set since currently the only way to set this key is only
  if the user has explicitly dismissed the FTU.

   To handle the special case where we never showed the FTU to end user
   since he had manually subscribed resources, we explicitly set this key to true.
   
*/

// this export is required for unit tests
// allow-unused-export

export function suppressFTUForReturningUsers(
	resources: Resource[],
	ftuCache: UnencryptedCacheBucket
) {
	if (isAnyResourceManuallySubscribed(resources)) {
		ftuCache.setUnencrypted(cacheKey, true);
	}
}

export function useFTUModals() {
	const breakpoints = useBreakpoints();
	const { resources, subscriptionsEnabled } = useLoadableResourceContext().value;
	const ftuCache = useFTUShownCache();
	const [shouldDisplayFTUModal, setShouldDisplayFTUModal] = useState(false);
	const [ftuDisplayedCacheFlag, setFTUDisplayedCacheFlag] = useState(true); //init the flag to true while we read the cache

	const closeModal = () => {
		ftuCache.setUnencrypted(cacheKey, true);
		setFTUDisplayedCacheFlag(true);
	};

	useEffect(() => {
		function readFTUFlagFromCache() {
			const ftuDisplayedFlagFromCache = !!ftuCache.getUnencrypted(cacheKey);
			setFTUDisplayedCacheFlag(ftuDisplayedFlagFromCache);

			const shouldDisplayModal = calculateIfShouldDisplayFTUModal(
				ftuDisplayedCacheFlag,
				resources,
				subscriptionsEnabled(),
				breakpoints.screenSize.media
			);

			if (!shouldDisplayModal) {
				suppressFTUForReturningUsers(resources, ftuCache);
			}

			setShouldDisplayFTUModal(shouldDisplayModal);
		}

		readFTUFlagFromCache();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resources, ftuDisplayedCacheFlag]);

	return { shouldDisplayFTUModal, closeModal };
}
