import React, { Component, ErrorInfo, ReactNode } from 'react';
import { logError } from 'remoteLogging';
import { ErrorLevel } from 'Loggers/LoggingProvider';

interface State {
	hasError: boolean;
}
interface Props {
	renderProp?: () => ReactNode;
	fallback?: () => ReactNode;
	name?: string;
}

export class GenericErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		logError(error, {
			additionalContext: {
				name: this.props.name,
				level: ErrorLevel.CRITICAL,
				errorInfo,
			},
		});
		this.setState({ hasError: true });
	}

	public render() {
		if (this.state.hasError) {
			return (this.props.fallback && this.props.fallback()) || <></>;
		}
		return this.props.renderProp();
	}
}
