import React, { useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import { Breadcrumbs, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { first, last } from 'lodash';
import { useFormFactor } from 'App/Header/useFormFactor';
import { CategoryList } from 'App/Screen/CategoryList';
import { Container } from 'App/Screen/Container';
import { MobileFullScreen } from 'App/Screen/MobileFullScreen';
import { ResourceList } from 'App/Screen/ResourceList';
import { ResourceFilter } from 'App/Screen/util';

const StyledBreadcrumbContainer = styled.div`
	padding-top: 24px;
	padding-bottom: 16px;
	line-height: 0px;
`;

const StyledTitle = styled.div`
	font-size: ${typography.sizes.sml};
	margin: ${layout.tinySpace} 0;
`;

const StyledCategoryContainer = styled.div<{ isLargeFormFactor: boolean }>`
	${({ isLargeFormFactor }) =>
		isLargeFormFactor &&
		`
		max-width: 1060px;
		margin: 0 auto;
  `}
`;

export const Apps = () => {
	const [category, setCategory] = useState<string>();
	const [subCategoryPath, setSubCategoryPath] = useState([]);
	const [breadcrumbList, setBreadcrumbList] = useState([]);

	const { isSmallFormFactor, isLargeFormFactor } = useFormFactor();

	const onBackSubcategoryNavigationAction = () => {
		const tempStack = [...subCategoryPath];
		tempStack.pop();
		setSubCategoryPath(tempStack);
		if (tempStack && tempStack.length === 0) {
			setCategory(t('Workspace:all'));
		}
	};

	useEffect(() => {
		if (subCategoryPath.length > 0) {
			const subCategoriesList = subCategoryPath.map((subCategory, index) => {
				return {
					onClick: () => {
						setSubCategoryPath(subCategoryPath.slice(0, index + 1));
					},
					title: <StyledTitle>{subCategory}</StyledTitle>,
					id: index + subCategory,
				};
			});
			setBreadcrumbList(subCategoriesList);
		}
	}, [subCategoryPath]);

	const shouldDisplayMobileSubcategoryList = () => {
		return (
			isSmallFormFactor &&
			subCategoryPath.length > 0 &&
			first(subCategoryPath) !== t('Workspace:all')
		);
	};

	const resourceList = () => {
		return (
			<ResourceList
				filter={ResourceFilter.Applications}
				category={category}
				setSubCategoryPath={setSubCategoryPath}
				subCategoryPath={subCategoryPath}
			/>
		);
	};
	return (
		<Container id="apps-screen">
			{shouldDisplayMobileSubcategoryList() ? (
				<MobileFullScreen
					title={last(subCategoryPath)}
					onBackButtonClick={onBackSubcategoryNavigationAction}
				>
					<Container>{resourceList()}</Container>
				</MobileFullScreen>
			) : (
				<>
					<StyledCategoryContainer isLargeFormFactor={isLargeFormFactor}>
						<CategoryList
							chooseCategory={setCategory}
							selectedCategory={category}
							setSubCategoryPath={setSubCategoryPath}
						/>
						{isLargeFormFactor && breadcrumbList.length > 0 && (
							<StyledBreadcrumbContainer data-testid="category-path-container">
								<Breadcrumbs breadcrumbs={breadcrumbList} maxItems={3} />
							</StyledBreadcrumbContainer>
						)}
					</StyledCategoryContainer>

					{resourceList()}
				</>
			)}
		</Container>
	);
};
