import { AnalyticsEvent } from 'analytics';
import { AotType } from 'App/AotTrace';
import { LoggingProvider } from 'Loggers/LoggingProvider';

export enum TraceEvent {
	INFO = 'INFO',
	ERROR = 'ERROR',
	ANALYTICS = 'ANALYTICS',
	BRIDGE = 'BRIDGE',
	NETWORK = 'NETWORK',
	AOT = 'AOT',
}

export interface ITraceManager extends LoggingProvider {
	logAnalytics: (payload: AnalyticsEvent) => void;
	logAOT: (payload: AotType) => void;
}
