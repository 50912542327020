import * as React from 'react';
import { t } from '@citrite/translate';
import { ShowModal } from '@citrite/web-ui-component';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { ContextMenuItemBuilder } from '@citrite/workspace-ui-platform-react';
import { restartDesktop } from 'Components/ResourceTile/restartDesktop';
import { configureSiriForResource } from 'Components/ResourceTile/Siri/configureSiri';
import { ViewDesktopDetails } from 'Components/ResourceTile/ViewDetails';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { shouldEnableSiri } from 'Environment/utils';
import { ResourceContext, useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export interface DesktopContextMenuFactoryProps {
	showModal: ShowModal;
}

interface BuilderContext extends DesktopContextMenuFactoryProps {
	resourceContext: ResourceContext;
	workspaceConfiguration: WorkspaceConfiguration;
}

export const useDesktopContextMenuFactory = ({
	showModal,
}: DesktopContextMenuFactoryProps): ContextMenuItemBuilder<Resource> => {
	const { value: resourceContext } = useLoadableResourceContext();
	const { workspaceConfiguration } = useConfigurationContext();
	return app => getOptions(app, { resourceContext, showModal, workspaceConfiguration });
};

const getOptions = (
	desktop: Resource,
	{ showModal, resourceContext, workspaceConfiguration }: BuilderContext
) => {
	return [
		desktop.poweroffurl && {
			onClick: () => restartDesktop(desktop, { resourceContext }, workspaceConfiguration),
			children: t('Workspace:restart_desktop_modal.restart'),
		},
		{
			onClick: () => showModal(<ViewDesktopDetails resource={desktop} />),
			children: t('Workspace:view_details'),
		},
		shouldEnableSiri() && {
			onClick: () => configureSiriForResource(desktop, resourceContext),
			children: desktop.isResourceRegisteredToSiri
				? t('Workspace:registered_to_siri')
				: t('Workspace:not_registered_to_siri'),
		},
	].filter(d => !!d);
};
