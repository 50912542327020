import { AnalyticsEvent, Metadata } from 'analytics';
import {
	DesktopEventType,
	EventSite,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_DESKTOPS';

const preparePayload = (type: DesktopEventType, metadata?: Metadata): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});

export const DesktopsUserEventPayload = {
	doesnotexist: (): AnalyticsEvent => preparePayload(DesktopEventType.DoesNotExist),
	favourite: (isFavorited: boolean, app: string): AnalyticsEvent =>
		preparePayload(DesktopEventType.Favourite, { isFavorited, app }),
	launch: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(DesktopEventType.Launch, { site, app }),
	moreInfo: (site: EventSite, app: string): AnalyticsEvent =>
		preparePayload(DesktopEventType.MoreInfo, { site, app }),
};
