import { breakpoints, layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledDiv = styled.div({
	position: 'absolute',
	top: layout.tinySpace,
	right: '40px',
	opacity: '0.75',
	fontSize: '12px',
	[breakpoints.onMobile]: {
		top: `${layout.mediumSpace}`,
		right: '65px',
	},
});
