import { LaunchResourceOptions } from 'Environment';
import { LaunchStatus } from 'Environment/launchResource/launchResource';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { launchSessionViaCL } from 'LeaseWorkflow';
import { isPreviousLogoutExplicit } from 'Logout/previousLogoutConfig';
import { LaunchPreference, Resource } from 'Workspace/ResourceProvider/resourceTypes';

export function leaseLaunchForHTML5(launchResourceOptions: LaunchResourceOptions) {
	const { targetWindow, resource, workspaceConfiguration, launchProgressHandler } =
		launchResourceOptions;
	const { validateLaunchNextStep } = launchProgressHandler;
	//This is for launch progress alert window. validateLaunchNextStep is defined in launchResource.ts
	if (!!validateLaunchNextStep) {
		if (
			!validateLaunchNextStep(LaunchStatus.CheckingAvailability) ||
			!validateLaunchNextStep(LaunchStatus.PerformingLaunch)
		) {
			return Promise.resolve();
		}
	}
	return launchSessionViaCL(resource, workspaceConfiguration, targetWindow);
}

export function checkLaunchPreference(resource: Resource, preference: LaunchPreference) {
	return !!(
		resource.clmetadata?.leasesupported &&
		resource.clmetadata?.launchpreference === preference
	);
}

export function isOfflineResourceAvailableForHtml5Shield() {
	try {
		const leaseProperties = getFromLocalStorage('LeasePropertiesKey');
		return (
			!!leaseProperties['properties']['alwaysBypassAuthForCachedResources'] ||
			(!!leaseProperties['properties']['bypassAuthForCachedResources'] &&
				!isPreviousLogoutExplicit())
		);
	} catch (err) {
		// not allowed to enter into offline mode
		return false;
	}
}
