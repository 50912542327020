import * as React from 'react';
import { get } from '@citrite/http';
import { AppsIcon, DesktopsIcon, IconProps } from '@citrite/web-ui-component';
import { logInfo } from 'remoteLogging';
import { environment } from 'Environment';
import { isCitrixBrowserApp } from 'Workspace/AppContext/citrixBrowserResource';
import { localAppResourceType, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { StyledIcon } from './ResourceIcon.styled';

export interface Props {
	size: number;
	resource?: Resource;
	resourceIconUrl?: string;
	shouldForceSkipNativeFetch?: boolean;
	DefaultIcon?: React.FC<IconProps>;
}
interface State {
	url?: string;
	imageLoaded: boolean;
}

export class ResourceIcon extends React.Component<Props, State> {
	public state: State = { imageLoaded: false };

	public render() {
		const useStateUrl =
			environment.useNativeUrlsForResourceIcons &&
			!this.isLocalApp(this.props.resource) &&
			!this.props.shouldForceSkipNativeFetch;
		const waitingForUrl = useStateUrl && !this.state.url;

		if (waitingForUrl || !this.getResourceIconUrl()) {
			return this.renderDefaults();
		}

		const iconURL = useStateUrl ? this.state.url : this.getIconURL();
		return (
			<>
				{!this.state.imageLoaded && this.renderDefaults()}
				<StyledIcon
					size={this.props.size}
					imageLoaded={this.state.imageLoaded}
					src={iconURL}
					onLoad={this.handleImageLoad}
					onError={this.handleImageError}
					role="none"
				/>
			</>
		);
	}

	private renderDefaults() {
		return this.props.DefaultIcon ? (
			<this.props.DefaultIcon size={this.props.size} />
		) : this.props.resource?.isdesktop ? (
			<DesktopsIcon
				data-testid={`desktop-icon-${this.props.resource?.id}`}
				size={this.props.size}
			/>
		) : (
			<AppsIcon
				data-testid={`app-icon-${this.props.resource?.id}`}
				size={this.props.size}
			/>
		);
	}

	private handleImageLoad = () => {
		this.setState({
			imageLoaded: true,
		});
	};

	private handleImageError = (error: React.SyntheticEvent<HTMLImageElement, Event>) => {
		logInfo('ResourceIcon could not load the requested image', {
			tags: { feature: 'apps-and-desktops' },
			additionalContext: {
				error,
				resource: {
					iconurl: this.getResourceIconUrl(),
				},
			},
		});
	};

	private getResourceIconUrl() {
		return this.props.resourceIconUrl || this.props.resource?.iconurl;
	}

	private isLocalApp(resource: Resource) {
		return (
			resource?.type === localAppResourceType ||
			(resource && isCitrixBrowserApp(resource))
		);
	}

	private getIconURL() {
		const resource = this.props.resource;
		return this.isLocalApp(resource)
			? resource?.iconurl
			: this.props.resourceIconUrl
			? environment.store.baseUri + this.props.resourceIconUrl
			: environment.store.baseUri + resource?.iconurl;
	}

	public componentDidMount() {
		if (
			!environment.useNativeUrlsForResourceIcons ||
			this.isLocalApp(this.props.resource) ||
			this.props.shouldForceSkipNativeFetch
		) {
			return;
		}

		const iconUrl = this.getResourceIconUrl();
		const downloadId = getIconId(iconUrl);

		environment
			.getFileUri(downloadId)
			.then(cachedUrl => {
				if (cachedUrl) {
					return cachedUrl;
				}

				return (
					!this.props.resourceIconUrl &&
					get(iconUrl, { downloadId }).then(() => environment.getFileUri(downloadId))
				);
			})
			.then(pathToIconOnDiskOrDataUri => {
				this.setState({
					url: pathToIconOnDiskOrDataUri,
				});
			})
			.catch(() => ({}));
	}
}

function getIconId(iconurl: string) {
	if (!iconurl) {
		return 'none';
	}

	return iconurl.replace(/Resources\/Icon\/([A-Za-z0-9]*).*/, '$1');
}
