import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { leaseCallHomeInfo } from 'LeaseWorkflow/CallHomeService';
import { CryptoFunctionWrap } from 'LeaseWorkflow/WasmFunctionWrap';

const KVLR_WORKER = {
	DB_ROOT: '/CL_ROOT',
	CONNECTION_LEASE_DIR: 'ConnectionLeases/',
};

/**
 * This class is the wrapper of wasm kvlr-crypto.
 * It is  used to generate key pair and operate indexedDB
 */
class ShieldCryptoModule {
	private metadata: Object;
	private deviceId: string;
	private publicKey: string;
	private kvlrWorkerModule: Object;
	private initDb: Function;
	private generateKvlrCryptoKeypair: Function;
	private saveFile: Function;
	private getFileData: Function;
	private getFileList: Function;
	private deleteFile: Function;

	public constructor() {
		this.metadata = {};
	}

	public getDeviceId() {
		return this.deviceId;
	}

	public getPublicKey() {
		return this.publicKey;
	}

	/**
	 * This method is used to load and initailize kvlr-crypto wasm
	 * It also exposes some interfaces for operating indexed DB, like
	 * saveFile, getFileList, deleteFile...
	 * @param null
	 * @returns null
	 */
	public async loadShieldCryptoWasm() {
		const { default: kvlrCryptoH5Module } = await import('@shieldh5/kvlr-crypto');
		this.kvlrWorkerModule = await Promise.resolve(kvlrCryptoH5Module());
		this.initDb = this.kvlrWorkerModule['cwrap'](...CryptoFunctionWrap.initDbWrap);
		this.generateKvlrCryptoKeypair = this.kvlrWorkerModule['cwrap'](
			...CryptoFunctionWrap.generateKvlrCryptoKeypairWrap
		);
		this.saveFile = this.kvlrWorkerModule['cwrap'](...CryptoFunctionWrap.saveFileWrap);
		this.getFileData = this.kvlrWorkerModule['cwrap'](
			...CryptoFunctionWrap.getFileDataWrap
		);
		this.getFileList = this.kvlrWorkerModule['cwrap'](
			...CryptoFunctionWrap.getFileListWrap
		);
		this.deleteFile = this.kvlrWorkerModule['cwrap'](
			...CryptoFunctionWrap.deleteFileWrap
		);

		const DB_ROOT_PTR = this.kvlrWorkerModule[CryptoFunctionWrap.mallocWrap](
			(KVLR_WORKER.DB_ROOT.length + 1) * Uint8Array.BYTES_PER_ELEMENT
		);
		this.kvlrWorkerModule[CryptoFunctionWrap.stringToUTF8Wrap](
			KVLR_WORKER.DB_ROOT,
			DB_ROOT_PTR,
			KVLR_WORKER.DB_ROOT.length + 1
		);

		await this.initDb(DB_ROOT_PTR);
		this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](DB_ROOT_PTR);
	}

	/**
	 * This method is used to generate key pair used for call home.
	 * It will call the interface provided by kvlr-crypto wasm
	 * @param null
	 * @returns null
	 */
	public async generateKvlrCryptoKeypairForShield() {
		const ptr = this.kvlrWorkerModule[CryptoFunctionWrap.mallocWrap](
			2 * Uint32Array.BYTES_PER_ELEMENT
		);

		await this.generateKvlrCryptoKeypair(ptr);
		const ptr_pub_key = this.kvlrWorkerModule[CryptoFunctionWrap.getValueWrap](
			ptr,
			'i32'
		);
		const public_key =
			this.kvlrWorkerModule[CryptoFunctionWrap.UTF8ToStringWrap](ptr_pub_key);

		const ptr_device_id = this.kvlrWorkerModule[CryptoFunctionWrap.getValueWrap](
			ptr + 4,
			'i32'
		);
		const device_id =
			this.kvlrWorkerModule[CryptoFunctionWrap.UTF8ToStringWrap](ptr_device_id);

		this.deviceId = device_id;
		if (!getFromLocalStorage(leaseCallHomeInfo.ENDPOINT_DEVICE_CLIENT_NAME)) {
			const clientName =
				device_id.length >= 8
					? 'HTML-' + device_id.substr(0, 4) + '-' + device_id.substr(4, 4)
					: 'HTML-' + device_id;
			setInLocalStorage(leaseCallHomeInfo.ENDPOINT_DEVICE_CLIENT_NAME, clientName);
		}
		const public_key_format = JSON.parse(public_key);
		const callHomePublicKey = JSON.stringify({
			owningEntity: 'EndpointDevice',
			kty: public_key_format['kty'],
			n: public_key_format['n'],
			e: public_key_format['e'],
			alg: 'RS256',
			kid: 'CtxEndpointKey-' + device_id,
		});

		this.publicKey = callHomePublicKey;

		this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](ptr_pub_key);
		this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](ptr_device_id);
		this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](ptr);
	}

	// called before the clsync start
	public async shieldInit() {
		leaseCallHomeInfo.objectsForShield.wsuiLastKnowConfig = getFromLocalStorage(
			leaseCallHomeInfo.WSUI_LAST_KNOWN_CONFIGURATION
		);
		leaseCallHomeInfo.objectsForShield.lastUserDetails = getFromLocalStorage(
			leaseCallHomeInfo.LAST_USER_DETAILS
		);
		if (
			!leaseCallHomeInfo.objectsForShield.wsuiLastKnowConfig ||
			!leaseCallHomeInfo.objectsForShield.lastUserDetails
		) {
			return;
		}
		if (Object.keys(this.metadata).length === 0 && this.metadata.constructor === Object) {
			const wsuiConfig = leaseCallHomeInfo.objectsForShield.wsuiLastKnowConfig;
			const userDetail = leaseCallHomeInfo.objectsForShield.lastUserDetails;
			leaseCallHomeInfo.leaseFilePath =
				wsuiConfig[leaseCallHomeInfo.CONF_ITEM_STORE_IDENTIFIERS][
					leaseCallHomeInfo.CONF_ITEM_STORE_GUID
				] +
				'/' +
				userDetail['userId'] +
				'/';
			leaseCallHomeInfo.metadataPath = leaseCallHomeInfo.leaseFilePath + 'metadata';
			const ptr = this.kvlrWorkerModule[CryptoFunctionWrap.mallocWrap](
				Uint32Array.BYTES_PER_ELEMENT
			);
			const ret = await this.getFileData(
				KVLR_WORKER.CONNECTION_LEASE_DIR + leaseCallHomeInfo.metadataPath,
				ptr
			);
			if (ret === 1) {
				this.metadata = {};
			} else {
				const data = this.kvlrWorkerModule[CryptoFunctionWrap.getValueWrap](ptr, 'i32');
				this.metadata = JSON.parse(
					this.kvlrWorkerModule[CryptoFunctionWrap.UTF8ToStringWrap](data)
				);
				this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](data);
			}
			this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](ptr);
		}
	}
	// used during clsync process
	public shieldGetMetadata(key: string): any {
		return this.metadata[key];
	}

	public shieldSaveMetadata(key: string, value: string): void {
		const metadataPath = leaseCallHomeInfo.metadataPath;

		if (value !== this.metadata[key]) {
			this.metadata[key] = value;
			this.saveFile(
				KVLR_WORKER.CONNECTION_LEASE_DIR + metadataPath,
				JSON.stringify(this.metadata)
			);
		}
	}

	public shieldSaveFile(key: string, file: string): void {
		const keyWithPath = leaseCallHomeInfo.leaseFilePath + key;
		this.saveFile(
			KVLR_WORKER.CONNECTION_LEASE_DIR + keyWithPath,
			JSON.stringify(JSON.parse(file))
		);
	}

	public async shieldGetFile(key: string) {
		let retVal = null;
		const keyWithPath = leaseCallHomeInfo.leaseFilePath + key;
		const ptr = this.kvlrWorkerModule[CryptoFunctionWrap.mallocWrap](
			Uint32Array.BYTES_PER_ELEMENT
		);
		const ret = await this.getFileData(
			KVLR_WORKER.CONNECTION_LEASE_DIR + keyWithPath,
			ptr
		);
		if (ret === 1) {
			retVal = null;
		} else {
			const data = this.kvlrWorkerModule[CryptoFunctionWrap.getValueWrap](ptr, 'i32');
			const file_data = JSON.parse(
				this.kvlrWorkerModule[CryptoFunctionWrap.UTF8ToStringWrap](data)
			);
			this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](data);

			retVal = JSON.stringify(file_data);
		}
		this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](ptr);
		return retVal;
	}

	public shieldGetAllFiles(key: string, callback: Function) {
		let retVal = null;
		const keyWithPath = leaseCallHomeInfo.leaseFilePath + key;
		const ptr = this.kvlrWorkerModule[CryptoFunctionWrap.mallocWrap](
			Uint32Array.BYTES_PER_ELEMENT
		);
		this.getFileList(KVLR_WORKER.CONNECTION_LEASE_DIR + keyWithPath, ptr).then(
			(ret: number) => {
				if (ret === 1) {
					retVal = null;
				} else {
					const data = this.kvlrWorkerModule[CryptoFunctionWrap.getValueWrap](ptr, 'i32');
					const file_data =
						this.kvlrWorkerModule[CryptoFunctionWrap.UTF8ToStringWrap](data);
					this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](data);
					retVal = file_data;
				}
				this.kvlrWorkerModule[CryptoFunctionWrap.freeWrap](ptr);
				callback(retVal);
			}
		);
	}

	public shieldDeleteFile(key: string) {
		const keyWithPath = leaseCallHomeInfo.leaseFilePath + key;
		this.deleteFile(KVLR_WORKER.CONNECTION_LEASE_DIR + keyWithPath);
	}

	public shieldDeleteFolder(key: string) {
		const keyWithPath = leaseCallHomeInfo.leaseFilePath + key;
		this.deleteFile(KVLR_WORKER.CONNECTION_LEASE_DIR + keyWithPath);
		this.metadata = {};
	}

	public shieldDeleteMetaData(key: string) {
		const metadataPath = leaseCallHomeInfo.metadataPath;
		if (this.metadata[key]) {
			delete this.metadata[key];
			this.saveFile(
				KVLR_WORKER.CONNECTION_LEASE_DIR + metadataPath,
				JSON.stringify(this.metadata)
			);
		}
	}
}

export const shieldCryptoModuleInstance = new ShieldCryptoModule();
