import { StyleSheet } from 'aphrodite';
import { layout, styleUtils } from '@citrite/web-ui-component';
import { bottomNavBarHeight, bottomNavItemIconSize } from 'App/BottomNav/dimensions';

export const styles = StyleSheet.create({
	tabBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: bottomNavBarHeight,
		position: 'fixed',
		bottom: 0,
		zIndex: 2,
		backgroundColor: 'white',
		boxShadow: '0px -3px 10px -2px rgba(0,0,0,0.16)',
	},
	tabItem: {
		display: 'grid',
		justifyContent: 'center',
		flex: 1,
		alignItems: 'center',
	},
	icon: {
		margin: `${layout.tinySpace}`,
		width: bottomNavItemIconSize + 'px',
		height: bottomNavItemIconSize + 'px',
	},
	title: {
		...styleUtils.ellipsis,
		display: 'flex',
		fontSize: '10px',
	},
	linkActive: {
		flex: 1,
	},
});
