import { breakpoints, palette, typography } from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const StyledListItem = styled.li`
	padding: 10px 0 10px 20px;
	position: relative;
	line-height: 18px;
`;

export const activeClassName = 'active';

const childLinkCss = css`
	text-decoration: none;
	display: inline-block;
	font-size: ${typography.sizes.sml};
	white-space: normal;
	${breakpoints.onDesktop} {
		:hover {
			font-weight: ${typography.weights.semiBold};
			color: ${palette.grey900};
		}
	}
	${breakpoints.onMobile} {
		font-size: ${typography.sizes.base};
	}

	&.${activeClassName} {
		font-weight: ${typography.weights.semiBold};
		color: ${palette.grey900};
	}
`;
export const StyledNavLink = styled(NavLink)`
	${childLinkCss};
`;

export const StyledAnchor = styled.a`
	${childLinkCss};
`;
