import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, layout, typography } from '@citrite/web-ui-component';
import { EmptyState } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { appsRoute } from 'App/Apps';
import { desktopsRoute } from 'App/Desktops';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useRouteAvailability } from 'App/Navigation/useRouteAvailability';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const StyledContainerDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: auto;
`;

const StyledTitleDiv = styled.div`
	font-weight: ${typography.weights.semiBold};
	font-size: 23px;
	height: 27px;
	padding: ${layout.mediumSpace};
`;

const StyledDescriptionDiv = styled.div<{ isSmallFormFactor: boolean }>`
	font-weight: ${typography.weights.regular};
	font-size: 14px;
	max-width: 25%;
	margin: auto;
	${props =>
		props.isSmallFormFactor &&
		`
		max-width: 92%;
		padding: 0 ${layout.mediumSpace};
	`};
`;

const StyledButtonsDiv = styled.div<{ isSmallFormFactor: boolean }>`
	font-weight: ${typography.weights.regular};
	font-size: 14px;
	padding: ${layout.mediumSpace};
	${props =>
		props.isSmallFormFactor &&
		`
 		width: 100%;
 		left: auto;
 		flex-direction: column;
 		padding: 0;
 	`};
`;

const textPadding = { padding: `${layout.smallSpace}` };

const getEmptyHomeDescription = (resources: Resource[]): string => {
	if (resources.every(({ isdesktop }) => !isdesktop)) {
		return t('Workspace:home_empty.description_with_no_desktops');
	} else if (resources.every(({ isdesktop }) => isdesktop)) {
		return t('Workspace:home_empty.description_with_no_apps');
	} else {
		return t('Workspace:home_empty.description');
	}
};

export function EmptyHomePage() {
	const { isSmallFormFactor } = useFormFactor();
	const history = useHistory();
	const isAppsRouteAvailable = useRouteAvailability(appsRoute);
	const isDesktopsRouteAvailable = useRouteAvailability(desktopsRoute);
	const { value: resourceContext } = useLoadableResourceContext();
	return (
		<StyledContainerDiv>
			<EmptyState
				source="actions"
				imageUrl={require('images/empty-line-art.svg')}
				isLineArtImage={!isSmallFormFactor}
			/>
			<StyledTitleDiv>{t('Workspace:home_empty.title')}</StyledTitleDiv>
			<StyledDescriptionDiv isSmallFormFactor={isSmallFormFactor}>
				{getEmptyHomeDescription(resourceContext.resources)}
			</StyledDescriptionDiv>

			{
				<StyledButtonsDiv isSmallFormFactor={isSmallFormFactor}>
					{isAppsRouteAvailable && (
						<Button.TextPrimary
							data-testid="button-go-to-apps"
							onClick={() => history.push(appsRoute.getUrl())}
							style={textPadding}
						>
							{t('Workspace:home_empty.go_to_apps')}
						</Button.TextPrimary>
					)}
					{isDesktopsRouteAvailable && (
						<Button.TextPrimary
							data-testid="button-go-to-desktops"
							onClick={() => history.push(desktopsRoute.getUrl())}
							style={textPadding}
						>
							{t('Workspace:home_empty.go_to_desktops')}
						</Button.TextPrimary>
					)}
				</StyledButtonsDiv>
			}
		</StyledContainerDiv>
	);
}
