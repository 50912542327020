import React from 'react';
import { FolderOutlineIcon, layout, LoadingIconPrimary } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { ResourceIcon } from 'Components/ResourceIcon';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const largeFormIconSize = 48;
const smallFormIconSize = 40;

const StyledDiv = styled.div<{ isSmallFormFactor: boolean }>`
	display: flex;
	align-items: end;
	justify-content: center;
	padding-top: ${layout.mediumLargeSpace};
	${props =>
		props.isSmallFormFactor &&
		`
		padding: 0;
		align-item: center;
	`}
`;

interface Props {
	resource?: Resource;
	isSubcategory?: boolean;
}
export const TileIcon = ({ resource, isSubcategory }: Props) => {
	const { value: resourceContext } = useLoadableResourceContext();
	const { isSmallFormFactor } = useFormFactor();
	const iconSize = isSmallFormFactor ? smallFormIconSize : largeFormIconSize;
	if (isSubcategory) {
		return (
			<StyledDiv isSmallFormFactor={isSmallFormFactor}>
				<FolderOutlineIcon size={iconSize} data-testid="folder-outline-icon" />
			</StyledDiv>
		);
	} else {
		const isLoading = resourceContext.isInProgress(resource.id);
		return (
			<StyledDiv isSmallFormFactor={isSmallFormFactor}>
				{isLoading ? (
					<LoadingIconPrimary size={iconSize} />
				) : (
					<ResourceIcon resource={resource} size={iconSize} />
				)}
			</StyledDiv>
		);
	}
};
