import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isFilesEnabledInVanityDomain } from './vanityDomain';

export enum UIProperty {
	Files = 'FilesApiBaseUrl',
	Apps = 'AppFeedsEnabled',
	Desktops = 'DesktopFeedsEnabled',
	AdvancedWorkspaceResiliency = 'AdvancedWorkspaceResiliencyEnabled',
}
const featureProptiesOnPrem: string[] = [UIProperty.Desktops, UIProperty.Apps];

export function hasUIProperty(
	workspaceConfiguration: WorkspaceConfiguration,
	property: UIProperty | string
) {
	//This is for on prem.
	if (IS_ON_PREM) {
		return featureProptiesOnPrem.includes(property);
	}
	// Files is not supported in vanity domain when canary if off
	if (
		(!isFilesEnabledInVanityDomain(workspaceConfiguration) ||
			hasFeatureCanary(workspaceConfiguration, FeatureFlag.FilesIntegrationDisabled)) &&
		property === UIProperty.Files
	) {
		return false;
	}
	const uiProperties = workspaceConfiguration?.uiProperties?.property || [];
	return uiProperties.some(({ name }) => name === property);
}
