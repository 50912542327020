import * as React from 'react';
import { IntegrationContext, LoadableCapability } from '@citrite/workspace-ui-platform';
import {
	ReactCapability,
	ReactContextCapability,
	ReactContextCapabilityMetadata,
} from '@citrite/workspace-ui-platform-react';
import { useIntegrations } from 'Integrations/useIntegrations';

type Props = {
	children: React.ReactNode;
};

interface InternalProps extends Props {
	integrations: IntegrationContext;
}

type State = {
	valueProviderHooks: IntegrationContextHook[];
};

type ReactContextSupplier = LoadableCapability<
	ReactContextCapability<any>,
	any,
	ReactContextCapabilityMetadata
>;

type IntegrationContextHook = {
	sourceIntegration: ReactContextSupplier;
	useContextProviderValue: () => any;
};

type IntegrationContextProvider = {
	sourceIntegration: ReactContextSupplier;
	Provider: React.FC<IntegrationContextProviderProps>;
};

type IntegrationContextProviderProps = {
	useContextProviderValue: () => any;
};

class _IntegrationProvidedContexts extends React.Component<InternalProps, State> {
	private readonly contextSuppliers: ReactContextSupplier[];
	private readonly contextProviders: IntegrationContextProvider[];
	public state: State = { valueProviderHooks: [] };

	public constructor(props: InternalProps) {
		super(props);

		this.contextSuppliers = props.integrations
			.resolveByCapability(ReactCapability.contextProvider)
			.filter(int => int.registration.metadata?.context);
		this.contextProviders = this.createWrappedProviderComponents();
	}

	public render() {
		const topProviderElement = this.contextProviders.reduce(
			(lastElement, nextProvider) => {
				const matchedHook = this.state.valueProviderHooks.find(
					hook => hook.sourceIntegration === nextProvider.sourceIntegration
				);

				const useContextProviderValue = matchedHook?.useContextProviderValue;
				const ProviderComponent = nextProvider.Provider;
				return React.createElement(
					ProviderComponent,
					{ useContextProviderValue },
					lastElement
				);
			},
			this.props.children
		);

		return topProviderElement;
	}

	public componentDidMount() {
		this.lazilyLoadContextProviderHooks();
	}

	private createWrappedProviderComponents() {
		return this.contextSuppliers.map<IntegrationContextProvider>(integration => {
			const { defaultContextValue } = integration.registration.metadata;
			const ContextProvider = integration.registration.metadata.context.Provider;

			const WrappedProvider: React.FC<IntegrationContextProviderProps> = props => {
				const contextValue = props.useContextProviderValue?.() ?? defaultContextValue;
				return <ContextProvider value={contextValue}>{props.children}</ContextProvider>;
			};

			return {
				Provider: WrappedProvider,
				sourceIntegration: integration,
			};
		});
	}

	private lazilyLoadContextProviderHooks() {
		this.contextSuppliers.forEach(async integration => {
			const { useContextValueProvider } = await integration.resolver;

			this.setState(state => ({
				valueProviderHooks: [
					...state.valueProviderHooks,
					{
						useContextProviderValue: useContextValueProvider,
						sourceIntegration: integration,
					},
				],
			}));
		});
	}
}

export function IntegrationProvidedContexts(props: Props) {
	const integrations = useIntegrations();
	return (
		<_IntegrationProvidedContexts integrations={integrations}>
			{props.children}
		</_IntegrationProvidedContexts>
	);
}
