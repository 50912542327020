import React from 'react';
import { Button } from '@citrite/web-ui-component';
import { versionInfo } from '@citrite/workspace-ui-platform';
import { StyledDiv } from './Branch.styled';

export function Branch() {
	if (IS_RELEASE) {
		return null;
	}

	const testBranch = getTestBranch();
	const hasTestBranch = !!testBranch;

	if (!hasTestBranch) {
		return null;
	}

	const canReset = testBranch !== 'local';
	return canReset ? (
		<StyledDiv as={Button.Wrapper} onClick={reset}>
			{testBranch}
		</StyledDiv>
	) : (
		<StyledDiv>{testBranch}</StyledDiv>
	);
}

function getTestBranch() {
	const buildTag = versionInfo.get();
	if (!buildTag || buildTag.environment === 'release') {
		return null;
	}
	return buildTag.environment;
}

function reset() {
	const defaultBranch = encodeURIComponent('/');
	location.assign(`?branch=${defaultBranch}`);
}
