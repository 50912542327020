import React from 'react';
import { post } from '@citrite/http';
import { t } from '@citrite/translate';
import { notifyError, warningConfirmModal } from '@citrite/web-ui-component';
import {
	getAssetBundleUrlFromRelativePath,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { MessageComponent } from 'App/Activity/components/MessageComponent';
import { environment } from 'Environment';
import { launchResource } from 'Environment/launchResource';
import { getLaunchMethod } from 'Environment/launchResource/clientManager';
import { launchMethod } from 'Environment/launchResource/constants';
import { detectLanguage } from 'javascript/sf/detectLanguage';
import { ResourceContext, WithResourceContextProps } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

enum launchErrors {
	operationInProgress = 'operation-in-progress',
	noMachine = 'no-machine',
	inMaintenanceMode = 'in-maintenance-mode',
	requestFailed = 'request-failed',
}

type DesktopRestartResponse = {
	errorId?: launchErrors;
	status: 'failed' | 'failure' | 'silentfailure' | 'success';
};

export function restartDesktop(
	desktop: Resource,
	session: WithResourceContextProps,
	workspaceConfiguration: WorkspaceConfiguration
) {
	return new Promise<void>(resolve => {
		const description = t('Workspace:restart_desktop_modal.description');
		const note = t('Workspace:restart_desktop_modal.note');
		warningConfirmModal({
			confirmButtonText: t('Workspace:restart_desktop_modal.restart'),
			title: t('Workspace:restart_desktop_modal.title', { desktopName: desktop.name }),
			message: <MessageComponent description={description} note={note} />,
			onDismiss: resolve,
			onCloseSuccess: () =>
				restartDesktopOnConfirm(desktop, session, workspaceConfiguration).then(() =>
					resolve()
				),
		});
	});
}

function restartDesktopOnConfirm(
	desktop: Resource,
	session: WithResourceContextProps,
	workspaceConfiguration: WorkspaceConfiguration
) {
	if (session.resourceContext.desktopRestartInProgressIds.includes(desktop.id)) {
		notifyLaunchError(desktop, session, launchErrors.operationInProgress);
		return Promise.resolve();
	}

	session.resourceContext.updateSession({
		...session.resourceContext,
		desktopRestartInProgressIds: [
			...session.resourceContext.desktopRestartInProgressIds,
			desktop.id,
		],
	});

	const method = getLaunchMethod(workspaceConfiguration);
	let targetWindow: Window = null;
	if (method === launchMethod.html5 && !environment.isNative) {
		const restartDesktopPath = getAssetBundleUrlFromRelativePath(
			`html/${detectLanguage()}/restart_desktop.html`
		);
		targetWindow = window.open(restartDesktopPath);
	}

	return post<DesktopRestartResponse>(desktop.poweroffurl)
		.then(data => {
			if (data.status !== 'success') {
				notifyLaunchError(desktop, session, data.errorId);
				targetWindow?.close();
			}

			session.resourceContext.updateSession(
				removeDesktopFromSession(session.resourceContext, desktop.id)
			);

			if (data.status === 'success') {
				return launchResource({
					resource: desktop,
					resourceContext: session.resourceContext,
					workspaceConfiguration,
					targetWindow,
				});
			}
			return Promise.resolve();
		})
		.catch(() => {
			session.resourceContext.updateSession(
				removeDesktopFromSession(session.resourceContext, desktop.id)
			);
			notifyLaunchError(desktop, session, launchErrors.requestFailed);
			targetWindow?.close();
		});
}

const removeDesktopFromSession = (session: ResourceContext, desktopId: string) => {
	return {
		...session,
		desktopRestartInProgressIds: session.desktopRestartInProgressIds.filter(
			id => id !== desktopId
		),
	};
};

const notifyLaunchError = (
	desktop: Resource,
	session: WithResourceContextProps,
	errorId: launchErrors
) => {
	if (
		errorId === launchErrors.operationInProgress ||
		session.resourceContext.isInProgress(desktop.id)
	) {
		return;
	}

	let message = t('Workspace:desktop_cannot_restart', { name: desktop.name });
	if (errorId === launchErrors.inMaintenanceMode) {
		message = t('Workspace:desktop_maintenance', { name: desktop.name });
	} else if (errorId === launchErrors.noMachine) {
		message = t('Workspace:desktop_retry_restart', { name: desktop.name });
	}
	notifyError(message);
};
