import { t } from '@citrite/translate';
import { SettingsLeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { environment } from 'Environment';
import { isHTML5Mandatory } from 'Environment/launchResource/clientManager';
import { createLeftNavRoute } from 'Router/ReactRoute';
import { canHaveEmptyResources } from 'utils/emptyResourcesCheck';

const component = makeAsync(() => import('./Advanced').then(m => m.Advanced));

export const advancedRoute = createLeftNavRoute({
	component,
	path: '/settings/advanced',
	isAvailable: props =>
		!(environment.isNative || isHTML5Mandatory(props.workspaceConfiguration)) &&
		(!!props.resourceContext.resources.length ||
			canHaveEmptyResources(props.workspaceConfiguration)),
	getNavLinkProps: () => ({
		title: t('Workspace:advanced'),
		weight: SettingsLeftNavPositions.advanced,
	}),
});
