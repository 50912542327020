import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
	notTrusted: {
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
