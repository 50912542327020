import * as React from 'react';
import { SkeletonLoaderShapes } from '@citrite/workspace-ui-platform-react';
import { StyledSkeletonListItem } from './Link.styled';
import { StyledLinkList } from './PrimaryNavigation.styled';

const { Bar, BarHeight, BarSpacing } = SkeletonLoaderShapes;
export function PrimaryNavSkeleton() {
	return (
		<StyledLinkList>
			<StyledSkeletonListItem>
				<Bar width={60} spacing={BarSpacing.Large} height={BarHeight.Thin} />
				<Bar width={92} spacing={BarSpacing.Large} height={BarHeight.Thin} />
				<Bar width={60} spacing={BarSpacing.Large} height={BarHeight.Thin} />
			</StyledSkeletonListItem>
		</StyledLinkList>
	);
}
