import * as React from 'react';
import { css } from 'aphrodite';
import {
	ArrowDownIcon,
	Button,
	Dropdown,
	SectionTitle,
	useBreakpoints,
} from '@citrite/web-ui-component';
import { styles } from './FeedSelector.styles';
import { WidgetFeed, WidgetFeedIdentifier } from './ItemList';

export interface Props {
	feeds: WidgetFeed[];
	selectedFeed: WidgetFeedIdentifier;
	onFeedChange: React.Dispatch<React.SetStateAction<string | number>>;
	cannotChangeFeed?: boolean;
}

function transformFeedToOption(
	feeds: WidgetFeed[],
	onFeedChange: React.Dispatch<React.SetStateAction<string | number>>,
	selectedFeed: WidgetFeedIdentifier
) {
	return feeds.map(feed => ({
		children: (
			<span className={css(selectedFeed === feed.id && styles.selectedOption)}>
				{feed.title()}
			</span>
		),
		onClick: () => onFeedChange(feed.id),
	}));
}

function getTestId(title: string) {
	return `widget-title-${title.toLocaleLowerCase().replace(/\s/g, '-')}`;
}

function getTitle(props: Props) {
	return props.feeds.find(({ id }) => props.selectedFeed === id).title();
}

export function FeedSelector(props: Props) {
	const [hover, setHover] = React.useState<boolean>(false);
	const breakpoints = useBreakpoints();
	if (props.feeds.length === 1 || props.cannotChangeFeed) {
		return (
			<SectionTitle data-testid={getTestId(getTitle(props))}>
				{getTitle(props)}
			</SectionTitle>
		);
	}
	return (
		<Dropdown
			openOn="click"
			options={transformFeedToOption(props.feeds, props.onFeedChange, props.selectedFeed)}
		>
			{buttonProps => (
				<SectionTitle data-testid={getTestId(getTitle(props))}>
					<Button.Wrapper
						{...buttonProps}
						onMouseEnter={() => setHover(!breakpoints.screenSize.onMobile && true)}
						onMouseLeave={() => setHover(!breakpoints.screenSize.onMobile && false)}
					>
						<div className={css(styles.feedSelector)}>
							{getTitle(props)}
							<span
								className={css(styles.arrowDownIcon, hover && styles.arrowDownIconHover)}
							>
								<ArrowDownIcon size={16} />
							</span>
						</div>
					</Button.Wrapper>
				</SectionTitle>
			)}
		</Dropdown>
	);
}
