import { post, postWithFullResponse } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import $ from 'jquery';
import * as cookie from 'js-cookie';
import { getLaunchMethod } from 'Environment/launchResource/clientManager';
import { launchMethod } from 'Environment/launchResource/constants';
import {
	isChrome,
	isChromeOS,
	isCitrixChromeApp,
	isEdge,
	isFirefox,
	isSafari,
	isWindowsPlatform,
} from 'Environment/launchResource/device';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';
import { AuthenticationResult } from './formsAuthentication';

const COOKIE_PASSWORD_CHANGE_ALLOWED = 'CtxsPasswordChangeAllowed';
const COOKIE_BROWSER_CLOSE_TO_END_SESSION = 'CtxsBrowserCloseToEndSession';
const SESSION_IDP_LOGOFF_URL = 'SESSION_IDP_LOGOFF_URL';
const IS_WEBVIEW_LOGON = 'IS_WEBVIEW_LOGON';
const COOKIE_AUTH_METHOD = 'CtxsAuthMethod';
const COOKIE_SMARTCARD_AUTHENTICATED = 'CtxsSmartcardAuthenticated';
const COOKIE_GATEWAY_AUTHENTICATED = 'CtxsGatewayAuthenticated';
let authenticationMethods: any[] = [];
const SAVED_COOKIE_EXPIRATION_DAYS = 365;
export enum Method {
	EXPLICIT = 'ExplicitForms',
	GENERIC_FORMS = 'GenericForms',
	PASSTHROUGH = 'IntegratedWindows',
	GATEWAY = 'CitrixAGBasic',
	SMARTCARD = 'Certificate',
}

export function parseChallenge(challengeString = '') {
	const pairs = challengeString.split(',');
	const challenge: Record<string, string> = {};

	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].trim();
		const re = /(.*)\s*=\s*"(.*)"/;

		const matches = pair.match(re);

		if (matches && matches.length === 3) {
			const key = matches[1].trim();
			const value = matches[2].trim();
			challenge[key] = value;
		}
	}

	return challenge;
}

export function isWebViewLogon() {
	return !!cookie.get(IS_WEBVIEW_LOGON);
}

export function setWebViewLogon(val: boolean) {
	if (val) {
		cookie.set(IS_WEBVIEW_LOGON, 'true');
	} else {
		cookie.remove(IS_WEBVIEW_LOGON);
	}
}

let _allowReloginWithoutBrowserClose = false;
export function setAllowReloginWithoutBrowserClose(val: boolean) {
	_allowReloginWithoutBrowserClose = val;
}

export function allowReloginWithoutBrowserClose() {
	return _allowReloginWithoutBrowserClose;
}

export function isBrowserCloseRequiredToEndSession() {
	return !!cookie.get(COOKIE_BROWSER_CLOSE_TO_END_SESSION);
}

export function setBrowserCloseRequiredToEndSession(val: any) {
	if (val) {
		cookie.set(COOKIE_BROWSER_CLOSE_TO_END_SESSION, 'true');
	} else {
		cookie.remove(COOKIE_BROWSER_CLOSE_TO_END_SESSION);
	}
}

export function setPasswordChangeAllowed(val: any) {
	if (val) {
		cookie.set(COOKIE_PASSWORD_CHANGE_ALLOWED, 'true');
	} else {
		cookie.remove(COOKIE_PASSWORD_CHANGE_ALLOWED);
	}
}

export function setIdpLogoffUrl(url: string) {
	setInSessionStorage(SESSION_IDP_LOGOFF_URL, url);
}

export function getIdpLogoffUrl() {
	return getFromSessionStorage<string>(SESSION_IDP_LOGOFF_URL);
}

export function setSavedAuthenticationMethod(authMethod: { name: any }) {
	cookie.set(COOKIE_AUTH_METHOD, authMethod.name, {
		expires: SAVED_COOKIE_EXPIRATION_DAYS,
	});
}

export function getSavedAuthenticationMethod() {
	const authMethodName = cookie.get(COOKIE_AUTH_METHOD);

	if (!authMethodName || !authenticationMethods) {
		return null;
	}
	return authenticationMethods.find(method => method.name === authMethodName);
}

export function clearSavedAuthenticationMethod() {
	cookie.remove(COOKIE_AUTH_METHOD);
}

export function isGatewaySession() {
	return !!cookie.get(COOKIE_GATEWAY_AUTHENTICATED);
}

export function setGatewaySession(val: boolean) {
	if (val) {
		cookie.set(COOKIE_GATEWAY_AUTHENTICATED, 'true');
	} else {
		cookie.remove(COOKIE_GATEWAY_AUTHENTICATED);
	}
}
// allow-unused-export : we need this export for unit tests.
export function setSmartcardSession(val: boolean) {
	if (val) {
		cookie.set(COOKIE_SMARTCARD_AUTHENTICATED, 'true');
		// Smartcard sessions require the browser to be closed to end session.
		setBrowserCloseRequiredToEndSession(true);
	} else {
		cookie.remove(COOKIE_SMARTCARD_AUTHENTICATED);
	}
}

export function isSmartCardSession() {
	return !!cookie.get(COOKIE_SMARTCARD_AUTHENTICATED);
}

export function getAuthenticationMethods(
	authenticationUrl: string,
	workspaceConfiguration: WorkspaceConfiguration,
	successCallback: Function,
	failureCallback: Function
) {
	return post<string>(authenticationUrl)
		.then(xml => {
			const methods = buildPrioritizedAuthMethodsList(xml, workspaceConfiguration);
			successCallback(methods);
		})
		.catch(err => {
			failureCallback(err);
		});
}

const precedenceOrder = [
	Method.GATEWAY,
	Method.PASSTHROUGH,
	Method.SMARTCARD,
	Method.GENERIC_FORMS,
	Method.EXPLICIT,
];

function _sortAuthMethods(a: { name: any }, b: { name: any }) {
	const aIndex = $.inArray(a.name, precedenceOrder);
	const bIndex = $.inArray(b.name, precedenceOrder);
	return aIndex - bIndex;
}

function buildPrioritizedAuthMethodsList(
	xmlData: string,
	workspaceConfiguration: WorkspaceConfiguration
) {
	authenticationMethods = [];
	$(xmlData)
		.find('method')
		.each(function (_index, elt) {
			const name = $(elt).attr('name');
			const authUrl = $(elt).attr('url');
			const authMethodAllowed = isAuthMethodSupported(name, workspaceConfiguration);
			if (authMethodAllowed) {
				authenticationMethods.push({ name, url: authUrl });
			}
		});
	// Sort the available auth methods into precedence order
	authenticationMethods.sort(_sortAuthMethods);
	_restrictFormsBasedAuthMethods(authenticationMethods);

	//When Gateway auth is available, it trumps any local auth methods.
	if (
		authenticationMethods.length > 0 &&
		authenticationMethods[0].name === Method.GATEWAY
	) {
		const gatewayAuthenticationMethod = authenticationMethods[0];
		authenticationMethods = [gatewayAuthenticationMethod];
	}

	if (authenticationMethods.length === 1) {
		clearSavedAuthenticationMethod();
	}
	return authenticationMethods;
}

function _restrictFormsBasedAuthMethods(methods: any[]) {
	// Create a new array of just the auth method names, to make searching easier
	const authMethodNames = $.map(methods, function (method) {
		return method.name;
	});
	const genericFormsIndex = $.inArray(Method.GENERIC_FORMS, authMethodNames);
	const explicitFormsIndex = $.inArray(Method.EXPLICIT, authMethodNames);
	if (genericFormsIndex > -1 && explicitFormsIndex > -1) {
		// If the server supports both types of forms-based authentication method, remove the lowest priority
		// one since the UI does not currently handle two forms-based methods at once.
		// Note that when custom forms is enabled, it will be used regardless of which forms-based method is
		// requested from the server.
		methods.splice(Math.max(genericFormsIndex, explicitFormsIndex), 1);
	}
}

function isAuthMethodSupported(
	authMethodName: string,
	workspaceConfiguration: WorkspaceConfiguration
) {
	const launchMethodLocal = getLaunchMethod(workspaceConfiguration);

	if (
		authMethodName === Method.EXPLICIT ||
		authMethodName === Method.GENERIC_FORMS ||
		authMethodName === Method.GATEWAY
	) {
		return true;
	} else if (authMethodName === Method.PASSTHROUGH) {
		//Need to handle extra for browser Extension or not?
		return (
			isWindowsPlatform() &&
			(launchMethodLocal === launchMethod.protocolHandler ||
				launchMethodLocal === launchMethod.icaFile)
		);
	} else if (authMethodName === Method.SMARTCARD) {
		return (
			isChromeOS() ||
			isCitrixChromeApp() ||
			(launchMethodLocal !== launchMethod.html5 &&
				(isFirefox() || isChrome() || isSafari() || isEdge()))
		);
	} else {
		return false;
	}
}

export function authenticate(
	url: string,
	successCallback: (result: AuthenticationResult) => void,
	failureCallback: () => void
) {
	postWithFullResponse<any>(url)
		.then(response => {
			const result = $('Result', response.data).text();
			if (result !== 'success') {
				failureCallback();
				return;
			}
			const authenticationInfo = {
				authenticationType: $('AuthType', response.data).text(),
				isPasswordChangeAllowed:
					$('IsChangePasswordEnabled', response.data).text() === 'true',
			};
			const isSmartcardSessionEnabled =
				authenticationInfo.authenticationType === Method.SMARTCARD ||
				(!!response.headers &&
					response.headers['X-Citrix-GatewayLoginWithoutPassword'] === 'true'); //gitleaks:allow
			setSmartcardSession(isSmartcardSessionEnabled);
			setGatewaySession(authenticationInfo.authenticationType === Method.GATEWAY);
			successCallback(authenticationInfo);
		})
		.catch(() => {
			failureCallback();
		});
}
