import {
	EncryptedCacheBucket,
	UnencryptedCacheBucket,
} from '@citrite/workspace-ui-platform';
import { isLocalStorageAvailable } from 'javascript/sf/Storage';
import { CacheBucket, CacheBucketParams } from './CacheBucket';

type CreateCacheBucket = (
	params: CacheBucketParams
) => EncryptedCacheBucket & UnencryptedCacheBucket;

export const nullCacheBucket: EncryptedCacheBucket & UnencryptedCacheBucket = {
	setUnencrypted: () => null,
	getUnencrypted: () => null,
	setEncrypted: () => Promise.resolve(null),
	getEncrypted: () => Promise.resolve(null),
	fetchEncryptedCacheFirst: (_key, resolver) => resolver(),
	removeCacheEntry: () => {},
};

export const createCacheBucket: CreateCacheBucket = params => {
	if (!isLocalStorageAvailable()) {
		return nullCacheBucket;
	}

	const cacheBucket = new CacheBucket(params);
	return {
		setUnencrypted: cacheBucket.setUnencrypted,
		getUnencrypted: cacheBucket.getUnencrypted,
		setEncrypted: cacheBucket.setEncrypted,
		getEncrypted: cacheBucket.getEncrypted,
		fetchEncryptedCacheFirst: cacheBucket.fetchEncryptedCacheFirst,
		removeCacheEntry: cacheBucket.removeCacheEntry,
	};
};
