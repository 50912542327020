import { AnalyticsEvent } from 'analytics';
import { AADSsoEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_AAD_SSO';

const preparePayload = (type: AADSsoEventType): AnalyticsEvent => ({
	type,
	code,
});

export const AADSsoEventPayload = {
	tokenRequested: (): AnalyticsEvent => preparePayload(AADSsoEventType.TokenRequested),
	tokenError: (): AnalyticsEvent => preparePayload(AADSsoEventType.TokenError),
};
