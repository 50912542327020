import { EndpointsService, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { xml2js } from 'xml-js';
import { parseXMLWithJQuery } from 'Components/fallbackXmlParser';
import {
	ReceiverConfiguration,
	Service,
} from 'Workspace/SchemeCallHandler/ReceiverConfigurationType';

export function parseConfiguration(xml: string) {
	const data = parseXML<{ clientSettings: WorkspaceConfiguration; endpoints: any }>(xml);

	coerceToArray(data.clientSettings?.uiProperties, 'property');
	coerceToArray(data.clientSettings?.featureCanaries, 'canary');
	coerceToArray(data.clientSettings?.userInterface?.fallbackConfiguration, 'altStore');
	coerceToArray(data.clientSettings?.pinnedLinks, 'pinnedLink');
	coerceToArray(data.clientSettings?.plugins, 'plugin');

	if (data.clientSettings?.endpointsServices?.endpointsService) {
		coerceToArray(data.clientSettings.endpointsServices, 'endpointsService');
		data.clientSettings.endpointsServices.endpointsService =
			data.clientSettings.endpointsServices.endpointsService.map(
				({ _text, ...service }: EndpointsService & { _text: string }) => ({
					...service,
					discoveryUrl: _text,
				})
			);
	}

	if (data.clientSettings?.plugins) {
		data.clientSettings.plugins.plugin.forEach(plugin => {
			coerceToArray(plugin?.params, 'param');
		});
	}

	return data;
}

function coerceToArray<Obj extends object, Key extends keyof Obj>(object: Obj, key: Key) {
	if (object && object[key] && !Array.isArray(object[key])) {
		object[key] = [object[key]] as any;
	}
}

export function parseXML<T = object>(xml: string): T {
	try {
		let result = xml2js(xml, { compact: true });
		result = hoistAttributes(result);
		result = inlineText(result);
		return result as T;
	} catch (e) {
		return parseXMLWithJQuery(xml, $) as T;
	}
}

export function parseReceiverConfiguration(xml: string) {
	const data = parseXML<ReceiverConfiguration>(xml);
	coerceToArray(data.Services, 'Service');
	data.Services.Service.forEach((service: Service) => {
		coerceToArray(service?.Gateways, 'Gateway');
		coerceToArray(service?.Beacons?.External, 'Beacon');
		coerceToArray(service?.Beacons?.Internal, 'Beacon');
	});
	return data;
}

function hoistAttributes(obj: object, rootKey?: string) {
	const rootObj = rootKey ? obj[rootKey] : obj;
	if (typeof rootObj !== 'object') {
		return rootObj;
	}
	Object.keys(rootObj).forEach(key => {
		if (key === '_attributes') {
			Object.keys(rootObj[key]).forEach(att => {
				rootObj[att] = rootObj[key][att];
			});
			delete rootObj[key];
			return;
		}
		rootObj[key] = hoistAttributes(rootObj, key);
	});
	return rootObj;
}

function inlineText(obj: object, rootKey?: string) {
	const rootObj = rootKey ? obj[rootKey] : obj;
	Object.keys(rootObj).forEach(key => {
		const child = rootObj[key];
		const [childKey, ...rest] = Object.keys(child);
		if (childKey === '_text' && rest.length === 0) {
			rootObj[key] = child[childKey];
		} else if (typeof rootObj[key] === 'object') {
			rootObj[key] = inlineText(rootObj, key);
		}
	});
	return rootObj;
}
