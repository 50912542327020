import { getFeatureFlagValue } from '@citrite/feature-flags';
import { breakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { mobileMastheadHeightValue } from 'Workspace/ThemeProvider';

export const StyledMobileMenu = styled.div`
	display: none;
	${breakpoints.onMobile} {
		display: flex;
		flex-direction: column;
		height: ${mobileMastheadHeightValue}px;
		justify-content: center;
		left: ${() => (getFeatureFlagValue(FeatureFlag.SimplifiedNav) ? 32 : 15)}px;
		position: fixed;
		top: ${props => props.theme.mastheadOffset.informationalBannerOffset}px;
	}
	:hover {
		cursor: pointer;
	}
`;
