import { StyleSheet } from 'aphrodite';
import { styleUtils, typography } from '@citrite/web-ui-component';
import { isSmallForm } from 'App/Header/useFormFactor';
const leftPadding = isSmallForm() ? '36px' : '70px';

export const styles = StyleSheet.create({
	mobileTitle: {
		display: 'flex',
		alignItems: 'flex-end',
		fontWeight: typography.weights.medium,
		fontSize: '24px',
		margin: `0px 10px 25px ${leftPadding}`,
		...styleUtils.ellipsis,
	},
});
