import * as React from 'react';
import { css } from 'aphrodite';
import { NativeMobileViewItem as PlatformNativeMobileViewItem } from '@citrite/workspace-ui-platform-react';
import { WidgetListItem } from 'App/NativeWidget/NativeWidgetComponents/WidgetListItem';
import { WidgetResourceIcon } from 'App/NativeWidget/NativeWidgetComponents/WidgetResourceIcon';
import { WidgetTitle } from 'App/NativeWidget/NativeWidgetComponents/WidgetTitle';
import { environment } from 'Environment';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './styles';

const { PrimaryButton } = PlatformNativeMobileViewItem;

export interface NativeWidgetResourceItemProps {
	id: string;
	title: string;
	icon: React.ReactNode;
	onPrimaryAction: () => Promise<void>;
	data: Resource;
	isLoading: (data: Resource) => boolean;
}

interface State {
	loading: boolean;
}

export interface NativeWidgetResourceRowProps {
	item: NativeWidgetResourceItemProps;
}

export class NativeWidgetResourceRow extends React.Component<
	NativeWidgetResourceRowProps,
	State
> {
	public state: State = {
		loading: false,
	};

	private handleOnPrimaryAction = () => {
		if (this.state.loading) {
			return;
		}
		this.setState({ loading: true });
		this.props.item.onPrimaryAction().then(() => {
			this.setState({ loading: false });
		});
	};

	public render() {
		const { title, data, isLoading } = this.props.item;
		const shouldBeLoading = isLoading(data);
		const disabled =
			!environment.citrixCloudConnected &&
			!this.props.item.data.clmetadata?.leasesupported;
		return (
			<WidgetListItem>
				<div className={css(styles.flexContainer, disabled && styles.disabledRow)}>
					<PrimaryButton onClick={this.handleOnPrimaryAction}>
						<WidgetResourceIcon
							resource={data}
							loading={this.state.loading || shouldBeLoading}
						/>
						<WidgetTitle title={title} />
					</PrimaryButton>
				</div>
			</WidgetListItem>
		);
	}
}
