import { modifyLocalApp } from 'App/LocalSystemApps';
import { environment } from 'Environment';
import { isMobileOrTabletDevice } from 'Environment/launchResource/device';
import { WithResourceContextProps } from 'Workspace/ResourceProvider';
import {
	localAppResourceType,
	Resource,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

export async function addResource(props: Resource) {
	const { subscriptionstatus = subscriptionStatus.unsubscribed } = props;
	if (props.type === localAppResourceType) {
		await modifyLocalApp(props.id, {
			subscriptionstatus: subscriptionStatus.subscribed,
		});
		return;
	}
	if (
		(subscriptionstatus !== subscriptionStatus.approved &&
			subscriptionstatus !== subscriptionStatus.unsubscribed &&
			!props.requiresworkflow) ||
		!props.subscriptionurl
	) {
		return Promise.reject(new Error('Resource adding prerequisites not met'));
	}

	const isSubscriptionRequired =
		subscriptionstatus === subscriptionStatus.unsubscribed ||
		subscriptionstatus === subscriptionStatus.approved;

	if (!isSubscriptionRequired) {
		return completeResourceAdd(props);
	}

	return environment.subscribe(props).then(() => {
		return completeResourceAdd(props);
	});
}

export type NotifyProps = Resource & WithResourceContextProps;

export function addResourceAndUpdateStore(props: NotifyProps) {
	return addResource(props).then(() => {
		props.resourceContext.setSubscriptionStatus(props.id, subscriptionStatus.subscribed);
	});
}

function completeResourceAdd(resource: Resource) {
	if (isMobileOrTabletDevice()) {
		return Promise.resolve();
	}
	return environment.installApp(resource).then(() => {});
}
