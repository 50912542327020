import { StyleSheet } from 'aphrodite';
import { breakpoints, palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	container: {
		height: 0,
		position: 'relative',
		zIndex: 1,
	},
	banner: {
		background: palette.blue800,
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		padding: '13px 37px',
		[breakpoints.onMobile]: {
			padding: '13px 18px',
		},
	},
	icon: {
		paddingRight: '10px',
	},
	close: {
		paddingLeft: '10px',
		cursor: 'pointer',
	},
	text: {
		flexGrow: 1,
	},
});
