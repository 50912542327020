import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { getCurrentPlatform } from 'Environment/launchResource/device/currentPlatform';
import { platform } from '../constants';

const mobileSpecificFeatureFlagForProtocolHandler: Partial<Record<platform, string>> = {
	[platform.android]: FeatureFlag.SupportClientDetectionAndroid,
	[platform.androidTablet]: FeatureFlag.SupportClientDetectionAndroid,
	[platform.chromeOS]: FeatureFlag.SupportClientDetectionChromeOS,
	[platform.ios]: FeatureFlag.SupportClientDetectionIOS,
	[platform.ipadOS]: FeatureFlag.SupportClientDetectionIOS,
};

export const shouldUseServerConfigForProtocolHandler = (
	config: WorkspaceConfiguration
) => {
	const featureFlagName =
		mobileSpecificFeatureFlagForProtocolHandler[getCurrentPlatform()] ||
		FeatureFlag.UseServerConfigForProtocolHandler;
	return hasFeatureCanary(config, featureFlagName);
};
