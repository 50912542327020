import {
	DependencyContainer,
	DependencyFromToken,
	DependencyToken,
} from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';

enum RegistrationType {
	Builder,
	Singleton,
	Factory,
}

interface Registration<T = any> {
	type: RegistrationType;
	value: T;
}

type SingletonRegistration<T = any> = Registration<T> & {
	type: RegistrationType.Singleton;
};
function isSingletonRegistration(
	registration: Registration<any>
): registration is SingletonRegistration<any> {
	return registration.type === RegistrationType.Singleton;
}

type BuilderMethod<T = any> = (container: DependencyContainer) => T;
type BuilderRegistration<T = any> = Registration<BuilderMethod<T>>;
function isBuilderRegistration(
	registration: Registration<any>
): registration is BuilderRegistration<any> {
	return registration.type === RegistrationType.Builder;
}

type FactoryMethod<T = any> = (container: DependencyContainer) => T;
type FactoryRegistration<T = any> = Registration<FactoryMethod<T>>;
function isFactoryRegistration(
	registration: Registration<any>
): registration is FactoryRegistration {
	return registration.type === RegistrationType.Factory;
}

interface ContainerOptions<Tokens extends DependencyToken> {
	registry?: Map<DependencyToken<any>, Registration<any>>;
	parent?: Container<Tokens>;
	resolutionStack?: DependencyToken<any>[];
}

export class Container<RegisteredTokens extends DependencyToken = 'empty container'>
	implements DependencyContainer<RegisteredTokens>
{
	private registry = new Map<DependencyToken<any>, Registration<any>>();
	private resolutionStack: DependencyToken<any>[];

	public constructor(private options: ContainerOptions<RegisteredTokens> = {}) {
		this.resolutionStack = options.resolutionStack || [];
		if (options.registry) {
			this.registry = options.registry;
		}
	}

	public registerSingleton = <T>(token: DependencyToken<T>, singleton: T) => {
		this.checkForRegistration(token);
		const registration: SingletonRegistration<T> = {
			type: RegistrationType.Singleton,
			value: singleton,
		};
		this.registry.set(token, registration);
		return this.getRegistrationResult<T>();
	};

	public registerSingletonWithBuilder = <T>(
		token: DependencyToken<T>,
		builder: BuilderMethod<T>
	) => {
		this.checkForRegistration(token);
		const registration: BuilderRegistration<T> = {
			type: RegistrationType.Builder,
			value: builder,
		};
		this.registry.set(token, registration);
		return this.getRegistrationResult<T>();
	};

	public registerFactory = <T>(
		token: DependencyToken<T>,
		factory: (container: DependencyContainer<RegisteredTokens>) => T
	) => {
		this.checkForRegistration(token);
		const registration: FactoryRegistration = {
			type: RegistrationType.Factory,
			value: factory,
		};
		this.registry.set(token, registration);
		return this.getRegistrationResult<T>();
	};

	public unregister = <Token extends RegisteredTokens>(
		token: Token | string
	): DependencyContainer<Exclude<RegisteredTokens, Token>> => {
		this.registry.delete(token);
		return this;
	};

	private getRegistrationResult = <T>(): DependencyContainer<
		RegisteredTokens | DependencyToken<T>
	> => {
		return this as DependencyContainer<RegisteredTokens | DependencyToken<T>>;
	};

	public resolve = <T extends RegisteredTokens>(
		token: T | string,
		scope?: Container<RegisteredTokens>
	): DependencyFromToken<T> => {
		scope = scope || this;
		this.checkForCircularReferences(token);

		const inScope = this.registry.has(token);
		if (inScope) {
			const registration = this.registry.get(token);
			return this.activate(token, registration, scope);
		}

		if (!inScope && this.options.parent) {
			return this.options.parent.resolve(token, scope);
		}

		throw new Error(
			`Dependency has not been registered for ${token}. You may want to gate the resolution with isRegistered.`
		);
	};

	public isRegistered = <T>(token: DependencyToken<T>, recursive = true): boolean => {
		let found = this.registry.has(token);
		if (!found && this.options.parent && recursive) {
			found = this.options.parent?.isRegistered(token, recursive);
		}
		return found;
	};

	public reset = (): void => {
		this.registry = new Map<DependencyToken<any>, any>();
	};

	public createChildContainer = (): Container<RegisteredTokens> => {
		return new Container({
			parent: this,
		});
	};

	private activate(token: DependencyToken, registration: Registration, scope: Container) {
		if (isSingletonRegistration(registration)) {
			return registration.value;
		}

		if (isFactoryRegistration(registration)) {
			const container: DependencyContainer<RegisteredTokens> =
				new Container<RegisteredTokens>({
					parent: scope,
					resolutionStack: this.resolutionStack.concat(token),
					registry: this.registry,
				});
			return registration.value(container);
		}

		if (isBuilderRegistration(registration)) {
			const container: DependencyContainer<RegisteredTokens> =
				new Container<RegisteredTokens>({
					parent: scope,
					resolutionStack: this.resolutionStack.concat(token),
					registry: this.registry,
				});
			const singletonRegistry: SingletonRegistration = {
				type: RegistrationType.Singleton,
				value: registration.value(container),
			};
			this.registry.set(token, singletonRegistry);
			return singletonRegistry.value;
		}
	}

	private checkForRegistration(token: DependencyToken) {
		if (this.registry.has(token)) {
			const error = new Error(
				`Token ${token} has already been registered in this scope.`
			);
			error.name = 'TokenRegistrationError';
			logError(error);
		}
	}

	private checkForCircularReferences(token: DependencyToken) {
		if (this.resolutionStack.indexOf(token) > -1) {
			const error = new Error(
				`Circular reference detected when resolving ${this.resolutionStack[0]}`
			);
			error.name = 'CircularReferenceError';
			throw error;
		}
	}
}
