import { Breadcrumb } from '@sentry/types';
import { AnalyticsEvent } from 'analytics';
import { debounce } from 'lodash';
import { AotType } from 'App/AotTrace';
import {
	getFromLocalStorage,
	getFromSessionStorage,
	setInLocalStorage,
	setInSessionStorage,
} from 'javascript/sf/Storage';
import { BaseLogger } from 'Loggers/BaseLogger';
import { parseErrorForLogging } from 'Loggers/ErrorHelpers';
import { ExtraContext, LogErrorOptions } from 'Loggers/LoggingProvider';
import { ITraceManager, TraceEvent } from 'Tracing/types';
import { userCommandManager } from 'UserCommand';

class TraceManager extends BaseLogger implements ITraceManager {
	private static _traceManager: TraceManager;

	private constructor() {
		super();
		this.initialize();
	}

	public static getInstance = (): TraceManager => {
		if (!TraceManager._traceManager) {
			TraceManager._traceManager = new TraceManager();
		}

		return TraceManager._traceManager;
	};

	public addBreadcrumb = (payload: Breadcrumb) => {
		if (payload?.type === 'http') {
			this.recordEvent(TraceEvent.NETWORK, payload);
		}
	};

	public logInfo = (message: string, additionalContext?: ExtraContext) => {
		this.recordEvent(TraceEvent.INFO, { message, ...additionalContext });
	};

	public logAnalytics = (payload: AnalyticsEvent): void => {
		this.recordEvent(TraceEvent.ANALYTICS, payload);
	};

	public logError = (payload: any, options?: LogErrorOptions) => {
		try {
			if (!payload) {
				payload = new Error('An undefined or empty error payload was provided');
				payload.name = 'UnsupportedErrorPayloadException';
			}
			const { error, extractedContext } = parseErrorForLogging(
				payload,
				options?.customMessage
			);
			const context = {
				message: error?.message,
				stack: error?.stack,
				...extractedContext,
				...options,
			};
			this.recordEvent(TraceEvent.ERROR, context);
		} catch (error) {
			this.handleError(error);
		}
	};

	public logAOT = (payload: AotType) => {
		this.recordEvent(TraceEvent.AOT, payload);
	};

	private recordEvent = async (event: TraceEvent, payload: any) => {
		try {
			if (getFromLocalStorage<boolean>('isTracingEnabled')) {
				const timestamp = new Date().getTime().toString();
				const eventKey = this.generateEventKey(event);
				setInSessionStorage(eventKey, { event, timestamp, payload });
			}
		} catch (error) {
			this.handleError(error);
		}
	};

	private initialize = () => {
		this.registerTraceDownloadUserCommands();
		this.registerTraceToggleUserCommands();

		this.initializeGlobalErrorListener();
	};

	private registerTraceDownloadUserCommands = () => {
		userCommandManager.register('?', this.debouncedDownloadTrace);
		userCommandManager.register('/', this.debouncedDownloadTrace);
	};

	private registerTraceToggleUserCommands = () => {
		userCommandManager.register('l', this.toggleTracing);
		userCommandManager.register('L', this.toggleTracing);
	};

	private toggleTracing = () => {
		const isTracingEnabled = getFromLocalStorage<boolean>('isTracingEnabled') ?? false;
		setInLocalStorage('isTracingEnabled', !isTracingEnabled);
	};

	private downloadTrace = async () => {
		try {
			if (!getFromLocalStorage<boolean>('isTracingEnabled')) {
				return;
			}
			const traces = this.collectAllTraces();
			const traceString = JSON.stringify(traces, null, 2);
			const [tracesLogsUrl, tracesLogAnchor] = this.attachTracesToDom(traceString);
			tracesLogAnchor.click();
			this.cleanTracesFromDom(tracesLogsUrl, tracesLogAnchor);
		} catch (error) {
			this.handleError(error);
		}
	};

	private debouncedDownloadTrace = debounce(this.downloadTrace, 5000, { leading: true });

	private collectAllTraces = () => {
		const traces = [];
		for (let i = 0; i < sessionStorage.length; i++) {
			const key = sessionStorage.key(i);
			const trace = sessionStorage.getItem(key);
			try {
				if (trace) {
					traces.push(JSON.parse(trace));
				}
			} catch (e) {
				console.warn(`Error parsing trace for key ${key}:`, e);
			}
		}

		return traces;
	};

	private attachTracesToDom = (
		traceString: string
	): [tracesLogsUrl: string, tracesLogAnchor: HTMLAnchorElement] => {
		const blob = new Blob([traceString], { type: 'application/json' });
		const url = URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;
		a.download = `${location.host}_workspace_ui_logs.json`;
		document.body.appendChild(a);

		return [url, a];
	};

	private cleanTracesFromDom = (
		tracesLogsUrl: string,
		tracesLogAnchor: HTMLAnchorElement
	) => {
		document.body.removeChild(tracesLogAnchor);
		URL.revokeObjectURL(tracesLogsUrl);
	};

	private generateEventKey = (event: TraceEvent): string => {
		const maxEventTraceCount = this.getMaximumEventTraceCount(event);
		const nextTraceCount = this.getNextEventTraceCount(event, maxEventTraceCount);
		return `${event.toString()}_${nextTraceCount}`;
	};

	private getMaximumEventTraceCount = (event: TraceEvent): number => {
		let maxCount = 100;
		switch (event) {
			case TraceEvent.INFO:
				maxCount = 50;
				break;
			case TraceEvent.BRIDGE:
				maxCount = 200;
				break;
			default:
				break;
		}
		return maxCount;
	};

	private getNextEventTraceCount = (event: TraceEvent, maxCount: number) => {
		const eventCounterKey = `${event.toString()}_trace_event_count`;
		const currentCounter = getFromSessionStorage<number>(eventCounterKey) ?? -1;
		const nextTraceCount = (currentCounter + 1) % maxCount;
		setInSessionStorage(eventCounterKey, nextTraceCount);
		return nextTraceCount;
	};

	private initializeGlobalErrorListener = () => {
		window.addEventListener &&
			window.addEventListener('error', event => {
				this.logError(event);
			});

		window.addEventListener &&
			window.addEventListener('unhandledrejection', event => {
				this.logError(event);
			});
	};

	private handleError = (error: any) => {
		console.warn('Error occurred in TraceManager:', error);
	};
}

export const traceManager = TraceManager.getInstance();
