import { platform } from 'Environment/launchResource/constants';
import { getCurrentPlatform } from 'Environment/launchResource/device/currentPlatform';
import { receiverConfigurationReader } from 'Workspace/SchemeCallHandler/ReceiverConfigurationReader';
import {
	Gateway,
	ReceiverConfiguration,
	Service,
} from 'Workspace/SchemeCallHandler/ReceiverConfigurationType';

const CITRIX_APP_PROTOCOL = 'citrixapp';
const CITRIX_BROWSER_PROTOCOL = 'citrixenterprisebrowser';

const platformsWhichNeedWindowOpen = [platform.ios, platform.ipadOS];

interface ProtocolParams {
	action?: string;
	[key: string]: string;
}

export interface SaasLaunchParams extends ProtocolParams {
	source?: string;
	resourceId?: string;
	userId?: string;
	sid?: string;
	userName?: string;
}
class SchemeCaller {
	private static instance: SchemeCaller;
	private iframe: HTMLIFrameElement;

	private constructor() {
		this.iframe = document.createElement('iframe') as HTMLIFrameElement;
		this.iframe.style.display = 'none';
		this.iframe.id = 'protocolHandlerIframe';
		document.body.appendChild(this.iframe);
	}

	public static getInstance(): SchemeCaller {
		if (!SchemeCaller.instance) {
			SchemeCaller.instance = new SchemeCaller();
		}
		return SchemeCaller.instance;
	}

	public makeSchemeCall(launchUrl: string, params: string): void {
		const protocolHandlerUrl = launchUrl + '/' + urlSafeBase64Encode(params);

		if (platformsWhichNeedWindowOpen.includes(getCurrentPlatform())) {
			window.open(protocolHandlerUrl);
		} else {
			this.iframe.src = protocolHandlerUrl;
		}
	}
}

const getStoreAdditionParams = (params: ProtocolParams) => {
	return Object.entries(params)
		.filter(([_key, value]) => !!value)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');
};

export const getStoreUrl = () => {
	return `${location.origin}${location.pathname.replace(/Web\/?$/, '/discovery')}`;
};

const getGatewayPluginUrlParams = () => ({ gatewayUrl: `${location.origin}` });

const getStoreName = () => {
	const pathname = location.pathname;
	const match = pathname.match(/\/?Citrix\/([^\/]+)Web\/?/);
	return match ? match[1] : null;
};

const getGatewayAndStoreUrlsFromReceiverConfiguration = (
	receiverConfiguration: ReceiverConfiguration
) => {
	const storeName = getStoreName();
	const serviceConfig = receiverConfiguration?.Services?.Service?.find(
		(service: Service) => service.Name.toLowerCase() === storeName.toLowerCase()
	);
	const gatewayConfig =
		serviceConfig?.Gateways?.Gateway.find(
			(gateway: Gateway) => gateway.Default.toLowerCase() === 'true'
		) || serviceConfig?.Gateways?.Gateway[0];
	return {
		gatewayUrl: gatewayConfig?.Location,
		storeUrl: serviceConfig?.Address || getStoreUrl(),
	};
};

const getGatewaySessionUrlParams = async (receiverConfigurationDownloadUrl: string) => {
	const receiverConfiguration = await receiverConfigurationReader.read(
		receiverConfigurationDownloadUrl
	);
	return getGatewayAndStoreUrlsFromReceiverConfiguration(receiverConfiguration);
};

const getStoreUrlParam = () => ({ storeUrl: getStoreUrl() });

const getStoreConfigUrlParamsForCloud = () => getStoreUrlParam();

const getStoreConfigUrlParamsForOnPrem = async (
	receiverConfigurationDownloadUrl?: string
) => {
	if (receiverConfigurationDownloadUrl) {
		return await getGatewaySessionUrlParams(receiverConfigurationDownloadUrl);
	} else {
		return getStoreUrlParam();
	}
};

const getStoreConfigUrlParams = async (receiverConfigurationDownloadUrl?: string) => {
	if (IS_GATEWAY_PLUGIN_BUILD) {
		return getGatewayPluginUrlParams();
	} else if (IS_ON_PREM) {
		return getStoreConfigUrlParamsForOnPrem(receiverConfigurationDownloadUrl);
	} else {
		return getStoreConfigUrlParamsForCloud();
	}
};

const getCWAProtocolHandlerURL = (protocol: string) => {
	return `${protocol}://open`;
};

const urlSafeBase64Encode = (value: string) => {
	// Replace certain characters in the base64 alphabet with ones that are safe to use in URL paths
	return window.btoa(value).replace(/\+/g, '_').replace(/\//g, '!').replace(/=/g, '-');
};

const schemeCallHandler = (protocol: string, params: ProtocolParams) => {
	const protocolHandlerURL = getCWAProtocolHandlerURL(protocol);
	const storeAdditionParams = getStoreAdditionParams(params);
	SchemeCaller.getInstance().makeSchemeCall(protocolHandlerURL, storeAdditionParams);
};

export const openCitrixApp = async (receiverConfigurationDownloadUrl?: string) => {
	const params = {
		action: 'addStore',
		...(await getStoreConfigUrlParams(receiverConfigurationDownloadUrl)),
	};
	schemeCallHandler(CITRIX_APP_PROTOCOL, params);
};

export const openInCitrixBrowser = async (
	params: SaasLaunchParams,
	receiverConfigurationDownloadUrl?: string
) => {
	const storeUrlParams = await getStoreConfigUrlParams(receiverConfigurationDownloadUrl);
	schemeCallHandler(CITRIX_BROWSER_PROTOCOL, {
		...params,
		action: 'saaslaunch',
		source: 'browser',
		...storeUrlParams,
	});
};
