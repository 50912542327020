import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const CardActions = styled.ul`
	align-items: center;
	display: flex;
	height: 100%;
	margin: 0;
	padding: 0;
`;
export const CardAction = styled.li`
	display: inline-block;
	margin-left: ${layout.smallSpace};
	:first-of-type {
		margin-left: 0;
	}
`;
