import * as React from 'react';
import {
	Button,
	Dropdown,
	MobileActionSheet,
	MobileActionSheetItem,
	MoreActionIcon,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { t } from '../../utils/translate';
import { ContextMenuItem } from '../ItemList';

export type ContextMenuItemBuilder<T extends object> = (data: T) => ContextMenuItem[];
type VariationPlacement =
	| 'top-start'
	| 'top-end'
	| 'bottom-start'
	| 'bottom-end'
	| 'right-start'
	| 'right-end'
	| 'left-start'
	| 'left-end';

export interface Props<T extends object> extends WithModalsProps {
	data: T;
	contextMenuBuilder?: ContextMenuItemBuilder<T>;
	showMobileActionSheet: boolean;
	disabled?: boolean;
	/** Used for aria label */
	name?: string;
	placement?: VariationPlacement;
	size?: number;
	buttonStyle?: React.CSSProperties;
	icon?: React.FC;
}

function _AdaptiveDropdown<T extends object>({
	data,
	showModal,
	contextMenuBuilder,
	showMobileActionSheet,
	disabled,
	name,
	placement,
	size,
	buttonStyle,
	icon,
}: Props<T>) {
	if (!contextMenuBuilder) {
		return null;
	}
	const options = contextMenuBuilder(data);
	if (options.length === 0) {
		return null;
	}

	const ariaLabel = name
		? t('platform-react:more_options_aria_label', { name })
		: undefined;

	return showMobileActionSheet ? (
		<Button.IconOnly
			disabled={disabled}
			icon={icon || MoreActionIcon}
			title={t('platform-react:more_options')}
			aria-label={ariaLabel}
			style={buttonStyle}
			size={size || 24}
			onClick={() =>
				showModal(
					<MobileActionSheet
						menuItems={getMobileActionSheetMenuItems(options)}
						closeOnOverlayClick
					/>
				)
			}
		/>
	) : (
		<Dropdown options={options} placement={placement || 'bottom-end'}>
			{buttonProps => (
				<Button.IconOnly
					{...buttonProps}
					style={buttonStyle}
					icon={MoreActionIcon}
					aria-label={ariaLabel}
					title={t('platform-react:more_options')}
					size={size || 24}
				/>
			)}
		</Dropdown>
	);
}

function getMobileActionSheetMenuItems(options: ContextMenuItem[]) {
	return options.map<MobileActionSheetItem>(dropdownItem => {
		return {
			onClick: dropdownItem.onClick,
			title: dropdownItem.children,
		};
	});
}

/**
 * AdapativeDropdown renders a More Actions icon
 * button that triggers either an Action Sheet for mobile use cases or
 * a Dropdown Menu.
 */

export const AdaptiveDropdown = withModals(_AdaptiveDropdown);
