import { fromThemeColors } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const hexOpacityCode = 'B3';

export const StyledPlatformLoadingOverlay = styled.div<{ secondary?: boolean }>`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;
	left: 0px;
	position: absolute;
	background-color: ${props =>
		props.secondary
			? fromThemeColors('secondaryAppBackground')(props) + hexOpacityCode
			: fromThemeColors('primaryAppBackground')(props) + hexOpacityCode};
`;
