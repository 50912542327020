import React from 'react';
import { t } from '@citrite/translate';
import { CitrixLogoIcon, layout, palette, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledDiv = styled.div<{
	relativePosition: boolean;
}>`
	display: flex;
	flex-direction: row;
	padding-bottom: ${layout.mediumLargeSpace};
	padding-top: ${layout.largeSpace};
	margin-top: ${layout.largeSpace};
	position: absolute;
	bottom: 0px;
	${props =>
		props.relativePosition &&
		`
			position: relative;
			padding-bottom: 0;
		`}
`;

const StyledMessageSpan = styled.span`
	font-size: ${typography.sizes.xsml};
	font-weight: ${typography.weights.regular};
	color: ${palette.grey900};
	padding: 0 4px 0 0;
`;

interface BrandinProps {
	containerIsScrollable?: boolean;
}
export const CitrixBranding = ({ containerIsScrollable = false }: BrandinProps) => {
	return (
		<StyledDiv relativePosition={containerIsScrollable}>
			<StyledMessageSpan>{`${t('Workspace:powered_by')}`}</StyledMessageSpan>
			<CitrixLogoIcon height={12} width={35} />
		</StyledDiv>
	);
};
