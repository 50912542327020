import * as React from 'react';
import { StyledPrimaryNavigation } from 'Components/AppLayout/styled';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';

interface PrimaryNavigationProps extends React.HTMLAttributes<HTMLElement> {
	hasTabs?: boolean;
}

export function PrimaryNavigation(props: PrimaryNavigationProps) {
	const height = useClientHeight();
	return (
		<StyledPrimaryNavigation clientHeight={height} {...props}></StyledPrimaryNavigation>
	);
}
