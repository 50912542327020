import * as React from 'react';
import { css } from 'aphrodite';
import {
	Avatar as UIAvatar,
	AvatarSize,
	EmptyProfileIcon,
	ExclamationInTriangleIcon,
} from '@citrite/web-ui-component';
import { CtxStringsService } from '@csg/g11n-sdk-core';
import { head, last } from 'lodash';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useUserContext } from 'Workspace/UserContext';
import { avatarStyle } from './styles';

export interface Props {
	username: string;
	size: AvatarSize;
	color?: string;
	children?: React.ReactNode;
	imageLocation: string;
}

export function WorkspaceAvatar(props: Props) {
	const size = props.size || 32;
	const styles = avatarStyle(size);
	const { userDetails } = useUserContext();
	const username = userDetails?.isValidUserDisplayName ? props.username : '';
	return (
		<GenericErrorBoundary
			renderProp={() => <_WorkspaceAvatar {...props} username={username} />}
			fallback={() => (
				<div className={css(styles.avatar)}>
					<UIAvatar
						icon={<ExclamationInTriangleIcon size={size / 2} />}
						size={size}
						color={props.color}
						data-testid-suffix={props['data-testid-suffix']}
					/>
				</div>
			)}
			name="WorkspaceAvatar"
		/>
	);
}

function _WorkspaceAvatar(props: Props) {
	const size = props.size || 32;
	const styles = avatarStyle(size);
	const ShowFirstAndLastNameInitials = useFeatureCanary(
		FeatureFlag.ShowFirstAndLastNameInitials
	);
	return (
		<div className={css(styles.avatar)}>
			<UIAvatar
				src={props.imageLocation}
				label={
					!ShowFirstAndLastNameInitials
						? getFirstInitial(props.username)
						: getFirstAndLastInitial(props.username)
				}
				icon={<EmptyProfileIcon size={size / 2} />}
				size={size}
				color={props.color}
				data-testid-suffix={props['data-testid-suffix']}
			/>
			{props.children && <div className={css(styles.name)}>{props.children}</div>}
		</div>
	);
}

function getFirstInitial(username: string) {
	return CtxStringsService.charAt(username, 0).toUpperCase();
}

function getUppercaseLetter(Initials: string) {
	return !!Initials ? Initials.toUpperCase() : '';
}

function getFirstAndLastInitial(username: string) {
	if (!username) {
		return '';
	}

	const initials = username
		.trim()
		.split(/\s+/)
		.filter(value => !!value)
		.map(name => CtxStringsService.charAt(name, 0));
	let userInitials = '';
	if (initials.length === 1) {
		userInitials = getFirstInitial(head(initials));
	} else {
		userInitials = getUppercaseLetter(head(initials).concat(last(initials)));
	}
	return userInitials;
}
