import React from 'react';
import { t } from '@citrite/translate';
import { breakpoints, Tab, Tabs } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { minimumRecommendedResources } from 'Components/FTU/constants';
import { countRecommendedResources } from 'Components/FTU/Utils';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

const NavigationTabs = styled(Tabs)`
	div {
		border: none;
		${breakpoints.onMediumScreen} {
			font-size: 15.5px;
		}
	}
`;

export enum baseTabs {
	Recommended,
	All,
}

interface Props {
	activeTab: baseTabs;
	setActiveTab: (b: baseTabs) => void;
}

export const Navigation = (props: Props) => {
	const resourceContext = useLoadableResourceContext();
	const { resources } = resourceContext.value;
	return (
		<>
			{countRecommendedResources(resources) >= minimumRecommendedResources && (
				<NavigationTabs activeTab={props.activeTab} onChange={props.setActiveTab}>
					<Tab
						id="recommended-resources-tab"
						value={baseTabs.Recommended}
						label={t('Workspace:ftu_ui.recommended')}
					/>
					<Tab
						id="all-resources-tab"
						value={baseTabs.All}
						label={t('Workspace:ftu_ui.all')}
					/>
				</NavigationTabs>
			)}
		</>
	);
};
