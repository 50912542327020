import { createRoute, makeAsync } from '@citrite/workspace-ui-platform-react';
                  
                                                          
         
const Logout = makeAsync(() =>
	import(/* webpackChunkName: "Logout" */ './Logout').then(m => m.Logout)
);
          

let Component = null;
if (IS_ON_PREM) {
	Component = LogoutComponent;
} else {
	Component = Logout;
}

export const logoutRoute = createRoute({
	component: Component,
	path: '/logout',
});
