const RESOURCE_URN_REGEX = /^urn:citrix:workspace:([^:]+):([^:]+)(:([^:]+))?$/;

export function isValidResourceURN(urn: string) {
	return RESOURCE_URN_REGEX.test(urn);
}

/**
 * Parse a Workspace resource URN. Format either
 * `urn:citrix:workspace:<resource name>:<resource id>`, or
 * `urn:citrix:workspace:<resource name>:<resource provider>:<resource id>`.
 */
export function parseResourceURN(urn: string): {
	resourceName: string;
	provider?: string;
	id: string;
} {
	if (!isValidResourceURN(urn)) {
		throw new Error(`Invalid Citrix Workspace Resource URN '${urn}'`);
	}
	const [, firstSegment, secondSegment, , thirdSegment] = urn.match(RESOURCE_URN_REGEX);

	if (thirdSegment) {
		return {
			resourceName: firstSegment,
			provider: secondSegment,
			id: thirdSegment,
		};
	}

	return {
		resourceName: firstSegment,
		id: secondSegment,
	};
}
