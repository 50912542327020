import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	Button,
	Form,
	Select,
	SelectOption,
	TextInput,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import { Query } from 'App/Search/SearchContent';
import { searchCASReporter } from 'Workspace/TelemetryEvents/search/createSearchCASReporter';
import { SearchFilterOption } from '../SearchFilterOption';
import { styles } from './Criteria.styles';

export interface Props extends WithModalsProps {
	executeSearch(): void;
	query: Query;
	resetQuery(): void;
	searching: boolean;
	supportsApps: boolean;
	supportsDesktops: boolean;
	updateQuery(properties: Query): void;
	filterOption: SearchFilterOption | string;
	updateFilterOption(option: string): void;
}

interface State {}

class _Criteria extends React.Component<Props, State> {
	public state: State = {};

	public render() {
		const { query, searching } = this.props;
		const options = this.filterOptions();

		return (
			<div className={css(styles.criteriaContainer)}>
				<Form onSubmit={this.submit}>
					<div className={css(styles.searchTermContainer)}>
						<div className={css(styles.searchTerm)}>
							<TextInput
								autoComplete="off"
								autoCorrect="off"
								autoCapitalize="off"
								spellCheck={false}
								value={query.SearchQuery}
								onChange={this.changeSearchTerm}
								aria-label={t('App/Search:search_terms')}
							/>
						</div>
						<div className={css(styles.searchFilterBlock)}>
							{options.length > 1 && (
								<div className={css(styles.searchFilter)}>
									<Select
										width="100%"
										value={this.props.filterOption}
										onChange={this.props.updateFilterOption}
										options={options}
										aria-label={t('App/Search:item_type')}
									/>
								</div>
							)}
							<div className={css(styles.searchButton)}>
								<Button.Primary
									style={{ width: '100%' }}
									type="submit"
									active={searching}
									disabled={!this.isValidQuery() || searching}
								>
									{t('App/Search:search')}
								</Button.Primary>
							</div>
						</div>
					</div>
				</Form>
			</div>
		);
	}

	private filterOptions() {
		const filterOptions: SelectOption[] = [];
		if (this.props.supportsApps) {
			filterOptions.push({
				value: SearchFilterOption.Applications,
				label: t('App/Search:filter_applications'),
			});
		}
		if (this.props.supportsDesktops) {
			filterOptions.push({
				value: SearchFilterOption.Desktops,
				label: t('App/Search:filter_desktops'),
			});
		}

		return filterOptions.length > 1
			? [
					{ value: SearchFilterOption.All, label: t('App/Search:filter_all') },
					...filterOptions,
			  ]
			: filterOptions;
	}

	private changeSearchTerm = (searchTerm: string) => {
		this.props.updateQuery({ SearchQuery: searchTerm });
	};

	private submit = () => {
		trackAnalyticsEvent(searchCASReporter.getSearchKeyInEvent());
		this.props.executeSearch();
	};

	private isValidQuery() {
		if (!this.props.query.SearchQuery) {
			return false;
		}
		return true;
	}
}

export const Criteria = withModals(_Criteria);
