enum OperatingSystem {
	windows,
	mac,
	android,
	ios,
	linux,
	other,
}

interface OperatinSystemCheck {
	test: RegExp;
	operatingSystem: OperatingSystem;
}

const operatingSystemChecks: OperatinSystemCheck[] = [
	{ test: /linux/, operatingSystem: OperatingSystem.linux },
	{ test: /mac os x/, operatingSystem: OperatingSystem.mac },
	{ test: /os.*like mac os/, operatingSystem: OperatingSystem.ios },
	{ test: /android/, operatingSystem: OperatingSystem.android },
	{ test: /windows/, operatingSystem: OperatingSystem.windows },
];

function isOperatingSystem(os: OperatingSystem) {
	return (
		operatingSystemChecks
			.find(check => check.operatingSystem === os)
			?.test.test(navigator.userAgent.toLowerCase()) ?? false
	);
}

const isWindowsOS = () => isOperatingSystem(OperatingSystem.windows);
const isIOS = () => isOperatingSystem(OperatingSystem.ios);
const isAndroidOS = () => isOperatingSystem(OperatingSystem.android);
const isMobile = () =>
	isAndroidOS() ||
	isIOS() ||
	isBrowser('mobile safari') ||
	isBrowser('opera m') ||
	isBrowser('fennec') ||
	isBrowser('silk');

// Detect browser (see http://www.quirksmode.org/js/detect.html)
/**
 * Returns true if the user agent contains the string
 */
function isBrowser(userAgent: string) {
	return navigator.userAgent.toLowerCase().includes(userAgent);
}

function isInternetExplorer() {
	return !(window as any).ActiveXObject && 'ActiveXObject' in window;
}

function isEdge() {
	return isBrowser(' edge/');
}

function isFirefox() {
	return isBrowser(' firefox/');
}

export const browser = {
	isWindowsOS,
	isIOS,
	isMobile,
	isBrowser,
	isInternetExplorer,
	isEdge,
	isFirefox,
};
