import React from 'react';
import { breakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { baseTabs, Navigation } from 'Components/FTU/Navigation';
import { Search } from 'Components/FTU/Search';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	${breakpoints.onMediumScreen} {
		height: 5vh;
		width: 80%;
		min-height: 40px;
	}
`;

const StyledSearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	width: 100%;
	align-content: center;
	align-items: center;
	${breakpoints.onMediumScreen} {
		width: 100%;
	}
`;

interface Props {
	input: string;
	setInput: (s: string) => void;
	activeTab: baseTabs;
	setActiveTab: (b: baseTabs) => void;
	shouldShowNavigation: boolean;
}

export const FilterContainer = (props: Props) => {
	return (
		<>
			<StyledContainer>
				<StyledSearchContainer>
					<Search input={props.input} setInput={props.setInput} />
				</StyledSearchContainer>
			</StyledContainer>
			{props.shouldShowNavigation && (
				<StyledContainer>
					<Navigation activeTab={props.activeTab} setActiveTab={props.setActiveTab} />
				</StyledContainer>
			)}
		</>
	);
};
