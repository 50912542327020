import { modifyLocalApp } from 'App/LocalSystemApps';
import { environment } from 'Environment';
import { WithResourceContextProps } from 'Workspace/ResourceProvider';
import {
	localAppResourceType,
	Resource,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

export function removeResource(props: Resource) {
	if (props.type === localAppResourceType) {
		return modifyLocalApp(props.id, {
			subscriptionstatus: subscriptionStatus.unsubscribed,
		});
	}
	return environment
		.removeApp(props)
		.then(() => environment.unsubscribe(props))
		.then(() => {
			environment.uninstallApp(props);
		});
}

export type NotifyProps = Resource & WithResourceContextProps;

export function removeResourceAndUpdateStore(props: NotifyProps) {
	return removeResource(props).then(() => {
		props.resourceContext.setSubscriptionStatus(
			props.id,
			subscriptionStatus.unsubscribed
		);
	});
}
