import * as React from 'react';
import { css } from 'aphrodite';
import { Button, LoadingIconPrimary } from '@citrite/web-ui-component';
import { deviceInfo } from '@citrite/workspace-ui-platform';
import { AdaptiveDropdown } from '../AdaptiveDropdown';
import { FavoriteAction } from '../FavoriteAction';
import { styles } from './Item.styles';

export interface ItemType<T extends object> {
	id: string;
	title: string;
	subtitle?: string;
	icon: string | JSX.Element;
	data?: T;
	onClick: () => Promise<void>;
	isFavorited: boolean;
	showFavoriteToggle: boolean;
	toggleFavoritesDisabled?: boolean;
	shouldBeInLoadingState?: (data: T) => boolean;
}

export interface ContextMenuItem {
	onClick?(): void;
	// Allows this interface to fullfill the DropdownItem/MenuItem/ListItemProps
	// interface(s) from web-ui-component without a type mapping function.
	children?: React.ReactNode;
	items?: ContextMenuItem[];
	nestedLevel?: number;
	disabled?: boolean;
}

export type ContextMenuItemBuilder<T extends object> = (data: T) => ContextMenuItem[];

export interface Props<T extends object> extends ItemType<T> {
	onFavoriteChange?: () => Promise<void>;
	buildContextMenu?: ContextMenuItemBuilder<T>;
}

interface State {
	loading: boolean;
}

export class Item<T extends object> extends React.Component<Props<T>, State> {
	public state: State = {
		loading: false,
	};

	private handleOnClick = () => {
		if (this.state.loading) {
			return;
		}
		this.setState({ loading: true });
		this.props.onClick().then(() => {
			this.setState({ loading: false });
		});
	};

	public render() {
		const {
			icon,
			title,
			subtitle,
			buildContextMenu,
			showFavoriteToggle,
			onFavoriteChange,
			isFavorited,
			toggleFavoritesDisabled,
			shouldBeInLoadingState,
			data,
		} = this.props;
		const shouldBeLoading = shouldBeInLoadingState && shouldBeInLoadingState(data);
		return (
			<div className={css(styles.item)}>
				<div className={css(styles.flexContainer)}>
					{onFavoriteChange && (
						<FavoriteAction
							onChange={onFavoriteChange}
							isFavorited={isFavorited}
							disabled={toggleFavoritesDisabled}
							visible={showFavoriteToggle}
						/>
					)}
					<Button.Wrapper
						onClick={this.handleOnClick}
						className={css(styles.itemSelection)}
					>
						<div className={css(styles.flexContainer)}>
							{this.state.loading || shouldBeLoading ? (
								<span className={css(styles.icon)}>
									<LoadingIconPrimary size={32} />
								</span>
							) : typeof icon === 'string' ? (
								<img className={css(styles.icon, styles.iconUrl)} src={icon} alt="" />
							) : (
								<span className={css(styles.icon)}>{icon}</span>
							)}
							<div className={css(styles.nameWrapper)}>
								<div className={css(styles.name)} title={title}>
									{title}
								</div>
								{subtitle && (
									<div className={css(styles.subtitle)} title={subtitle}>
										{subtitle}
									</div>
								)}
							</div>
						</div>
					</Button.Wrapper>
				</div>
				<AdaptiveDropdown
					data={data}
					showMobileActionSheet={deviceInfo.isNativeMobileOrTabletClient()}
					contextMenuBuilder={buildContextMenu as ContextMenuItemBuilder<object>}
					name={title}
				/>
			</div>
		);
	}
}
