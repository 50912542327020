import * as React from 'react';
import { trackEvent } from 'analytics';
import { AnalyticsTrackingEvent, EventSubscriber, EventType } from 'Components/EventBus';
import { Event } from 'Workspace/EventBus';

function isAnalyticsTrackingEvent(event: Event<any>): event is AnalyticsTrackingEvent {
	return (
		event.type === EventType.ANALYTICS_TRACKING_EVENT &&
		event.payload &&
		event.payload.trackType
	);
}

function handleEvent(event: Event<any>) {
	if (isAnalyticsTrackingEvent(event)) {
		trackEvent(event.payload.trackType, event.payload.metadata);
	}
}

export function NativeAnalyticsTrackingEventSubscriber() {
	return <EventSubscriber listener={(event: Event<any>) => handleEvent(event)} />;
}
