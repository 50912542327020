import * as React from 'react';
import { Link, LinkProps } from '@citrite/web-ui-component';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface RouterLinkProps extends LinkProps {
	to: string;
}

export function RouterLink(props: RouterLinkProps) {
	return <Link.Secondary element={ReactRouterLink} {...props} />;
}

export function RouterBlueLink(props: RouterLinkProps) {
	return <Link.Primary element={ReactRouterLink} {...props} />;
}
