import React from 'react';
import { ApplicationDetails } from 'App/Activity/components/ApplicationDetails';
import { ApplicationSectionHeader } from 'App/Activity/components/ApplicationSectionHeader';
import { Session } from 'App/Activity/ResourceManagerContext';

export interface ApplicationCardProps {
	session: Session;
}

export const ApplicationCard = ({ session }: ApplicationCardProps) => {
	return (
		<>
			<ApplicationSectionHeader session={session} />
			{session.applications.map(application => (
				<ApplicationDetails
					key={application.id}
					application={application}
					session={session}
				/>
			))}
		</>
	);
};
