import * as React from 'react';
import { t } from '@citrite/translate';
import { StyledSkipNavLink } from './styled';

export function SkipNav() {
	const focusMainContent = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		const main = document.querySelector<HTMLElement>('[role="main"]');
		main.setAttribute('tabindex', '0');
		main.focus();
		window.scrollTo(0, 0);
	};
	return (
		<StyledSkipNavLink href="#" onClick={focusMainContent}>
			{t('Workspace:skip_to_main')}
		</StyledSkipNavLink>
	);
}
