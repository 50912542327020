import { environment } from 'Environment';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export function configureSiriForResource(
	resource: Resource,
	resourceContext: ResourceContext
) {
	const resolver = environment.configureSiri(resource);
	resolver
		.catch(() => {})
		.then(result => {
			resourceContext.setSiriRegistrationStatus(
				resource.id,
				result === 'registered_to_siri'
			);
		});
}
