import * as React from 'react';
import { Tooltip } from '@citrite/web-ui-component';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { matchPath } from 'react-router-dom';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { StyledLinkList } from 'App/LeftNavigation/PrimaryNavigation/PrimaryNavigation.styled';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { Link } from './Link';
export interface Props {
	links: LeftNavLink[];
	currentPath: string;
	collapsed: boolean;
	deprecatedCollapsed: boolean;
	onNavigate(refreshPage: boolean, performanceKey: string): void;
}

type InternalProps = Props & {
	workspaceConfiguration: WorkspaceConfiguration;
};

interface State {
	expandedSection?: string;
}

class _LinkList extends React.Component<InternalProps, State> {
	public state: State = { expandedSection: this.findExpandedSection() };

	public render() {
		const { links, collapsed, deprecatedCollapsed, currentPath, workspaceConfiguration } =
			this.props;
		const { expandedSection } = this.state;
		const hasSimplifiedNavCanary = hasFeatureCanary(
			workspaceConfiguration,
			FeatureFlag.SimplifiedNav
		);
		const shouldCollapse = hasSimplifiedNavCanary ? collapsed : deprecatedCollapsed;
		return (
			<StyledLinkList>
				{links.map((link, i) => {
					return hasSimplifiedNavCanary ? (
						<Tooltip
							placement="right"
							isDisabled={!shouldCollapse}
							hoverTrigger={
								<Link
									link={link}
									expanded={link.href === expandedSection}
									collapsed={shouldCollapse}
									onLinkClick={this.onExpandableLinkClick}
									currentPath={currentPath}
								/>
							}
							key={`${i}${link.href}`}
						>
							{link.title}
						</Tooltip>
					) : (
						<Link
							link={link}
							expanded={link.href === expandedSection}
							collapsed={shouldCollapse}
							onLinkClick={this.onExpandableLinkClick}
							currentPath={currentPath}
							key={`${i}${link.href}`}
						/>
					);
				})}
			</StyledLinkList>
		);
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.currentPath !== this.props.currentPath) {
			this.setState({ expandedSection: this.findExpandedSection() });
		}
	}

	private findExpandedSection() {
		for (let i = 0; i < this.props.links.length; i++) {
			const link = this.props.links[i];
			if (link.href && !!matchPath(this.props.currentPath, { path: link.href })) {
				return link.href;
			}
		}

		return undefined;
	}

	private onExpandableLinkClick = (e: React.MouseEvent, link: LeftNavLink) => {
		if (link.childLinks?.length && !link.hasSecondaryNav) {
			if (
				!hasFeatureCanary(
					this.props.workspaceConfiguration,
					FeatureFlag.WsuiFilesUIModernization
				)
			) {
				e.preventDefault();
				this.expand(link.href);
				return;
			} else {
				this.setState({
					expandedSection: link.href,
				});
			}
		}
		this.onLinkClick(e, link);
	};

	private onLinkClick = (e: React.MouseEvent, link: LeftNavLink) => {
		if (!link.href) {
			e.preventDefault();
			link.onClick(e);
		}
		this.props.onNavigate(link.href === this.props.currentPath, link.performanceMark);
	};

	private expand = (href: string) => {
		this.setState(state => ({
			expandedSection: state.expandedSection === href ? '' : href,
		}));
	};
}

export function LinkList(props: Props) {
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	return <_LinkList {...props} workspaceConfiguration={workspaceConfiguration} />;
}
