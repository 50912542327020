import { createCapability } from '../capability';

/**
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.logout}
 *
 * @category Integration Capability
 */
export interface LogoutCapability {
	/** Called when the user logs out of Workspace. */
	logout(): Promise<any>;
}

export const LogoutCapabilityToken = createCapability<LogoutCapability>(
	'wsui_logout_capability'
);
