import { feedSort } from 'userNotifications/FeedCard';
import { PageLoadEvent } from 'Workspace/TelemetryEvents/notificationFeed/events';
import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { SDKToSortOrderMap } from './sortOrderMap';

export function createPageLoadEvent(sort: feedSort): PageLoadEvent {
	return {
		type: EventType.NotificationFeed_PageLoad,
		sortOrder: SDKToSortOrderMap.get(sort),
	};
}
