import React from 'react';
import { createRoute, makeAsync } from '@citrite/workspace-ui-platform-react';
import { RouteComponentProps } from 'react-router-dom';
import { AppShell as SyncAppShell } from 'App/AppShell';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { InitialLoading } from 'Workspace/InitialLoading';

const AsyncAppShell = makeAsync(
	() => import(/* webpackChunkName: "AppShell" */ './AppShell').then(m => m.AppShell),
	<InitialLoading />
);

const AppShell = (props: RouteComponentProps<null>) => {
	const EnableSynchronousAppShell = useFeatureCanary(
		FeatureFlag.EnableSynchronousAppShell
	);
	return EnableSynchronousAppShell ? (
		<SyncAppShell {...props} />
	) : (
		<AsyncAppShell {...props} />
	);
};

export const appShellRoute = createRoute({
	component: AppShell,
	path: '/',
});
