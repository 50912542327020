import React from 'react';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { CustomLinkBanner } from 'App/CustomLinkBanner/CustomLinkBanner';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { useFormFactor } from 'App/Header/useFormFactor';
import {
	AdminNotificationBanner,
	DevicePostureBanner,
	useDevicePostureDetails,
} from 'Components/AppLayout';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isNativeWidget } from 'Environment/launchResource/device';
import { useFeatureCanary } from 'utils/useFeatureCanary';

const _AppHeader = () => {
	const { devicePosture, shouldShowDevicePostureBanner } = useDevicePostureDetails();
	const { isLargeFormFactor, isSmallFormFactor } = useFormFactor();
	const isCustomLinkEnabled = useFeatureCanary(FeatureFlag.EnableCustomLinks);
	const customLinkData =
		useConfigurationContext().workspaceConfiguration?.pinnedLinks?.pinnedLink || [];

	return (
		<>
			{isLargeFormFactor &&
				isCustomLinkEnabled &&
				!isNativeWidget() &&
				!!customLinkData.length && (
					<CustomLinkBanner
						customLinkData={customLinkData}
						isSmallFormFactor={isSmallFormFactor}
					/>
				)}
			{shouldShowDevicePostureBanner && (
				<DevicePostureBanner
					intendedPosition={BannerPosition.Top}
					transactionId={devicePosture?.scanTransactionId}
				/>
			)}
			{environment.citrixCloudConnected && !isNativeWidget() && (
				<AdminNotificationBanner intendedPosition={BannerPosition.Top} />
			)}
		</>
	);
};

export const AppHeader = () => {
	return <GenericErrorBoundary renderProp={() => <_AppHeader />} name="AppHeaderError" />;
};
