//We are using Jaro Wrinker alogrithm for Fuzzy Search.

export const fuzzySearch = (stringFirstInput: string, stringSecondInput: string) => {
	let matches = 0;

	//The algorithm returns 0 is there are no matches and returns 1 if there is a complete match.

	//Return 0, i.e., no match if either of the strings are undefined.
	if (stringFirstInput === undefined || stringSecondInput === undefined) {
		return 0.0;
	}

	// Return 0, i.e., no match if either of the strings are empty.
	if (stringFirstInput.length === 0 || stringSecondInput.length === 0) {
		return 0.0;
	}

	// Return 1, i.e., complete match if both the strings are equal.
	if (stringFirstInput === stringSecondInput) {
		return 1.0;
	}

	//The characters are said to be matching :
	//1. If they are the same.
	//2. Characters are not further than the range.

	//Range = (floor[max(strin1.length, string2.length) / 2 ) - 1
	//Transpositions are half the number of matching characters in both strings but in different order.

	const range =
		Math.floor(Math.max(stringFirstInput.length, stringSecondInput.length) / 2) - 1;

	// Hash for matches
	const hashStringFirstInput = Array(stringFirstInput.length).fill(0);
	const hashStringSecondInput = Array(stringSecondInput.length).fill(0);

	// Traverse through the first string
	for (let i = 0; i < stringFirstInput.length; i++) {
		// Check if there is any matches
		for (
			let j = Math.max(0, i - range);
			j < Math.min(stringSecondInput.length, i + range + 1);
			j++ // If there is a match
		) {
			if (
				stringFirstInput[i] === stringSecondInput[j] &&
				hashStringSecondInput[j] === 0
			) {
				hashStringFirstInput[i] = 1;
				hashStringSecondInput[j] = 1;
				matches++;
				break;
			}
		}
	}

	// If there is no match, return 0.0
	if (matches === 0) {
		return 0.0;
	}

	// Number of transpositions
	let transpositions = 0;

	let point = 0;

	// Count number of occurrences
	// where two characters match but
	// there is a third matched character
	// in between the indices
	for (let i = 0; i < stringFirstInput.length; i++) {
		if (hashStringFirstInput[i]) {
			// Find the next matched character
			// in second string
			while (hashStringSecondInput[point] === 0) {
				point++;
			}

			if (stringFirstInput[i] !== stringSecondInput[point++]) {
				transpositions++;
			}
		}
	}

	//Reduce the transpositions by half according to the alogrithm.
	transpositions /= 2;

	// Return the Jaro Similarity
	return (
		(matches / stringFirstInput.length +
			matches / stringSecondInput.length +
			(matches - transpositions) / matches) /
		3.0
	);
};
