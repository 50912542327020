import { StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	noItems: {
		display: 'flex',
		alignItems: 'center',
	},
	emptyIcon: {
		border: `1px solid ${palette.grey200}`,
		borderRadius: '3px',
		background: palette.grey200,
		height: '32px',
		width: '32px',
		margin: '12px 16px',
	},
	emptyText: {
		fontStyle: 'italic',
	},
});
