import React, { Fragment } from 'react';

export interface Props {
	flag: boolean;
	OnComponent: React.FC;
	OnComponentProps?: object;
	OffComponent?: React.FC;
	OffComponentProps?: object;
	children: React.ReactNode;
}
/**
 *
 * @param Props Containing a condition and wrapper components
 * @returns A Conditional Wrapper component which can be used to wrap a React component with another conditionally
 */
export const ConditionalRenderer = ({
	flag,
	OnComponent,
	OnComponentProps = null,
	OffComponent = Fragment,
	OffComponentProps = null,
	children,
}: Props) => (
	<>
		{flag ? (
			<OnComponent {...OnComponentProps}>{children}</OnComponent>
		) : (
			<OffComponent {...OffComponentProps}>{children}</OffComponent>
		)}
	</>
);
