import { t } from '@citrite/translate';
import { EndpointsService } from '@citrite/workspace-ui-platform';
import { sendMessageToChromeAppWrapper } from 'Environment/ChromeApp/ChromeAppMessageHelper';
import { sendMessageToChromeApp } from 'Environment/ChromeApp/ChromeAppTransport';
import {
	AuthenticationTimerOperationType,
	ChromeAppWrapperMessage,
} from 'Environment/ChromeApp/types';
import { errorWithCopyTransactionIdButtonforChromeAppLeaseLaunch } from 'Environment/launchResource/launchResourceErrors';

interface SendInitiateStoreRequest {
	isCloudOnline?: boolean;
	csrfToken?: string;
	userId?: string;
}

export interface SendLogoffMessage {
	isSessionExpired: boolean;
	isPasswordChanged: boolean;
}

export interface SendIcaLaunchMessage {
	resourceId: string;
	icaFile: string | Map<string, string>;
	iconUrl: string;
	UILocale: string;
}

interface SendLeaseLaunchMessage {
	resourceId: string;
	resourceKey: string;
	telemetryLaunch?: {
		telemetryData: {
			telemetryHeadlessLaunchEnabled: boolean;
			telemetryLaunchShadowDelayMins: number;
			telemetryLaunchMinTimeIntervalMins: number;
		};
	};
	resourceType: string;
	resourceName: string;
	launchPreference: string;
	iconUrl: string;
	UILocale: string;
}

export function sendCustomerConfiguration(workload: {
	storeId?: string;
	customerId?: string;
	endpoints?: EndpointsService[];
	isPreviousLogoutExplicit?: boolean;
	hasLastUserDetails?: boolean;
	idpConfigId?: string;
	isHtml5Enabled?: boolean;
}) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'setCapabilities',
		timeout: 3000,
	};

	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export function initiateStore(workload: SendInitiateStoreRequest) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'initiateStore',
		retries: 2,
		timeout: 3000,
	};
	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export function logOff(workload: SendLogoffMessage) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'logOff',
		timeout: 3000,
	};
	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export function icaLaunch(workload: SendIcaLaunchMessage) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'icaLaunch',
		timeout: 3000,
		retries: 2,
	};
	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export function getStoreHealth() {
	const message: ChromeAppWrapperMessage = {
		type: 'getStoreHealth',
		timeout: 3000,
		retries: null,
	};
	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export async function leaseLaunch(workload: SendLeaseLaunchMessage) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'leaseLaunch',
		timeout: 120000,
		retries: 1,
	};

	const result = await sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
	if (!workload.telemetryLaunch?.telemetryData) {
		// non telemetry launch, show error code and message
		if (result !== undefined && result.errorCode !== undefined) {
			const errorCodeStr = result.errorCode.toString();
			if (errorCodeStr !== '0') {
				let errorText = t(`Workspace:lease_launch_failures.${errorCodeStr}`);
				if (errorText === 'lease_launch_failures.' + errorCodeStr) {
					errorText = '';
				}
				errorWithCopyTransactionIdButtonforChromeAppLeaseLaunch(
					errorText,
					errorCodeStr,
					result.correlationId
				);
			}
		}
	}
	return result;
}

export function notifyAuthenticationTimer(workload: {
	storeId?: string;
	customerId?: string;
	timerOperation: AuthenticationTimerOperationType;
}) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'notifyAuthenticationTimer',
		timeout: 3000,
	};

	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export function sendEventHubData(workload: {
	customerId?: string;
	eventHubToken?: string;
	eventHubEndpoint?: string;
	expiry?: string;
}) {
	const message: ChromeAppWrapperMessage = {
		workload,
		type: 'sendEventHubData',
		timeout: 3000,
	};

	return sendMessageToChromeAppWrapper(sendMessageToChromeApp, message);
}

export function waitForIconImageLoaded(imgeUrl: string) {
	return new Promise(resolve => {
		const imgObj = new Image();
		imgObj.onload = function () {
			const drawingCanvas = document.createElement('canvas');
			const context = drawingCanvas.getContext('2d');
			drawingCanvas.height = imgObj.height;
			drawingCanvas.width = imgObj.width;
			context.drawImage(imgObj, 0, 0);
			const dataURL = drawingCanvas.toDataURL();
			resolve(dataURL);
		};
		imgObj.onerror = () => resolve('');
		imgObj.src = imgeUrl;
	});
}

function getChromeAppVersion() {
	const CWAVersionKey = 'endpointCWAVersion';
	return localStorage.getItem(CWAVersionKey);
}

function getChromeAppVersioningRegex() {
	return /\b\d+\.\d+\.\d+\.\d+\b/;
}

export function doesChromeAppShieldSupportNeedsEncodedIca() {
	// Shield support from chrome CWA is added from version >= 24.5.0.0 and it can handel the regular json ica
	// This is an exception check for one store in 2312 to have shield support
	const jsonIcaTypeSupportedVersion = '24.2.1.64';
	const CWAVersion = getChromeAppVersion();
	return (
		getChromeAppVersioningRegex().test(CWAVersion) &&
		CWAVersion.localeCompare(jsonIcaTypeSupportedVersion, undefined, {
			numeric: true,
		}) < 0
	);
}
