import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CONTENTID } from 'javascript/sf/constants';

class _ScrollToTop extends React.Component<RouteComponentProps<any>> {
	public componentDidUpdate(prevProps: RouteComponentProps<any>) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			const scrollableSection = document.getElementById(CONTENTID);
			if (scrollableSection) {
				scrollableSection.scrollTop = 0;
			}
		}
	}

	public render() {
		return this.props.children;
	}
}
export const ScrollToTop = withRouter(_ScrollToTop);
