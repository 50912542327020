import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, layout, LoadingPrimary } from '@citrite/web-ui-component';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Title } from 'App/Header/Title';
import { useFormFactor } from 'App/Header/useFormFactor';
import { platform } from 'Environment/launchResource/constants';
import {
	getCurrentPlatform,
	isMobileOrTabletDevice,
	isSafari,
} from 'Environment/launchResource/device/currentPlatform';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { openCitrixApp } from 'Workspace/SchemeCallHandler/schemeCallHandler';
import { mandateNativeAppAnalyticsReporter } from 'Workspace/TelemetryEvents/mandateNativeApp/createMandateNativeAppAnalyticsReporter';
import { trackNamAndCebAnalytics } from '../trackNamAndCebAnalytics';

const StyledSpan = styled.span<{ styles: SerializedStyles }>`
	${props => props.styles}
`;

const StyledButton = styled(Button.Primary)`
	padding: ${layout.mediumSpace} 0;
	width: 220px;
`;

const StyledButtonDiv = styled.div<{ smallFormFactor: boolean }>`
	display: flex;
	flex-direction: row;
	padding-right: ${layout.mediumSpace};
	${props =>
		props.smallFormFactor &&
		`
			justify-content: center;
			padding-right: 0;
		`}
`;

const StyledLoadingDiv = styled.span`
	display: flex;
	margin: ${layout.smallSpace};
	margin-left: ${layout.mediumSpace};
	align-items: center;
`;

const TitleContainer = styled.div`
	padding-bottom: 64px;
	display: flex;
	justify-content: center;
`;

const StyledHeader = styled.h2<{ styles: SerializedStyles }>`
	${props => props.styles}
`;

const StyledContainer = styled.div`
	padding-top: ${layout.smallSpace};
`;

const StyledTextButton = styled(Button.TextPrimary)<{ smallFormFactor: boolean }>`
	margin-left: ${layout.tinySpace};
	${props =>
		props.smallFormFactor &&
		`
		margin: ${layout.mediumSpace} 0;
	`}
`;

const getPlatformChangeText = (isUsingIpad: boolean) =>
	isUsingIpad
		? t('Workspace:mandate_native_app_screen.switch_to_macos')
		: t('Workspace:mandate_native_app_screen.i_am_using_ipad');

export interface MandateSuccessProps {
	counter: number;
	showOpenCitrixButton: boolean;
	receiverConfigurationDownloadUrl?: string;
}

export const MandateSuccess = (props: MandateSuccessProps) => {
	const { isSmallFormFactor } = useFormFactor();
	const { counter, showOpenCitrixButton } = props;
	const isMobileOrTablet = isMobileOrTabletDevice();
	const isUsingIpad = !!getFromLocalStorage('userIsUsingAnIpad');
	const platformChangeText = getPlatformChangeText(isUsingIpad);

	const serializedHeaderStyle = css`
		text-align: ${isSmallFormFactor ? 'center' : 'left'};
	`;
	const serializedSpanStyle = css`
		margin-bottom: ${layout.smallSpace};
		display: ${isSmallFormFactor ? 'flex' : 'block'};
		${isSmallFormFactor &&
		'justify-content: center; text-align: center; align-items: center;'}
	`;

	const openApp = () => {
		trackNamAndCebAnalytics(
			mandateNativeAppAnalyticsReporter.getMandateNativeAppOpenCitrixWorkspaceClickedEvent()
		);
		openCitrixApp(props.receiverConfigurationDownloadUrl);
	};

	const handlePlatformChange = () => {
		setInLocalStorage('userIsUsingAnIpad', !isUsingIpad);
		window.location.reload();
	};

	const shouldShowPlatformChangeOption = () =>
		isSafari() && (getCurrentPlatform() === platform.mac || isUsingIpad);

	return (
		<StyledContainer>
			{isSmallFormFactor && (
				<TitleContainer>
					<Title />
				</TitleContainer>
			)}
			<StyledHeader styles={serializedHeaderStyle}>
				{t('Workspace:mandate_native_app_screen.citrix_workspace_app_required')}
			</StyledHeader>
			<StyledSpan styles={serializedSpanStyle}>
				{t('Workspace:mandate_native_app_screen.admin_enforced_usage')}
			</StyledSpan>
			{showOpenCitrixButton && !isSmallFormFactor && (
				<StyledSpan
					styles={serializedSpanStyle}
					dangerouslySetInnerHTML={{
						__html: t('Workspace:mandate_native_app_screen.select_open_citrix'),
					}}
					data-testid="select-open-citrix"
				/>
			)}
			{showOpenCitrixButton && (
				<StyledButtonDiv smallFormFactor={isSmallFormFactor}>
					<StyledButton
						onClick={openApp}
						disabled={!isMobileOrTablet && counter > 0}
						data-testid="open_citrix_button"
					>
						{!isMobileOrTablet && counter > 0
							? t('Workspace:mandate_native_app_screen.open_citrix_loading')
							: t('Workspace:mandate_native_app_screen.open_citrix')}
					</StyledButton>
					{!isMobileOrTablet && counter > 0 && (
						<>
							<StyledLoadingDiv>
								<LoadingPrimary size={32} />
							</StyledLoadingDiv>
							<StyledLoadingDiv>
								{` ${counter} ${t('Workspace:mandate_native_app_screen.timer_text')}`}
							</StyledLoadingDiv>
						</>
					)}
				</StyledButtonDiv>
			)}
			{!counter && shouldShowPlatformChangeOption() && (
				<StyledSpan styles={serializedSpanStyle}>
					<StyledTextButton
						onClick={handlePlatformChange}
						smallFormFactor={isSmallFormFactor}
					>
						{platformChangeText}
					</StyledTextButton>
				</StyledSpan>
			)}
		</StyledContainer>
	);
};
