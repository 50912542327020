import * as React from 'react';
import {
	StyledMasthead,
	StyledMastheadSearchBar,
	StyledMastheadUserMenu,
} from 'Components/AppLayout/styled';

export const Masthead: React.SFC = props => {
	return <StyledMasthead data-testid="masthead">{props.children}</StyledMasthead>;
};

export const MastheadSearchBar: React.SFC = props => {
	return (
		<StyledMastheadSearchBar data-testid="mastheadSearchBar">
			{props.children}
		</StyledMastheadSearchBar>
	);
};

export const MastheadUserMenu: React.SFC = props => {
	return (
		<StyledMastheadUserMenu data-testid="mastheadUserMenu">
			{props.children}
		</StyledMastheadUserMenu>
	);
};
