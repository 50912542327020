import { AADAuthResult, AADErrorResult } from '@citrite/msal-browser';
import { sendAADSSOAccessToken } from 'Environment/BrowserExtension/browserExtensionBridge';

export type AADSSOEventNotification = {
	resourceId: string;
	correlationId: string;
	eventId: string;
	errorCode: number;
	errorMsg: string;
	azureDeviceId: string;
};

const EVENT_VDA_READY_FOR_AADSSO = 'AzureADEventReadyForSSOnInfo';

/*
  (150 retry count) * (300 millisec wait per loop) = 45 seconds max time 
  we will wait for the notification.

  This is empirical wait time. Native itself waits for 60 seconds.
*/
const MAX_RETRY_COUNT = 150;
const WAIT_FOR_EVENT_MILLISEC = 300;

class AADSSOEventHandler {
	//We receive empty azureDeviceID from CWA Native which will take the the native team time to fix.
	//Until that we will need to store the event based on resourceID which the accessToken
	//send code will lookup for to decide when to send the accessToken to native via the browser-extension
	//bridge.
	private readonly aadSSOEventMap = new Map<string, AADSSOEventNotification>();

	public clearEventForResource(resourceId: string): void {
		this.aadSSOEventMap.delete(resourceId);
	}

	public getEventForResource(resourceId: string): AADSSOEventNotification {
		return this.aadSSOEventMap.get(resourceId);
	}

	public setEventForResource(event: AADSSOEventNotification): void {
		if (event.eventId === EVENT_VDA_READY_FOR_AADSSO) {
			this.aadSSOEventMap.set(event.resourceId, event);
		}
	}

	private async waitForEventOrTimeout(resourceId: string): Promise<void> {
		let retryCount = 0;

		do {
			if (this.aadSSOEventMap.has(resourceId)) {
				break;
			}
			await new Promise(resolve => setTimeout(resolve, WAIT_FOR_EVENT_MILLISEC));
			retryCount++;
		} while (retryCount < MAX_RETRY_COUNT);
	}

	private popEventFromMap(resourceId: string): AADSSOEventNotification {
		const aadSSOEvent = this.aadSSOEventMap.get(resourceId);
		if (aadSSOEvent) {
			this.clearEventForResource(aadSSOEvent.resourceId);
		}
		return aadSSOEvent;
	}

	public async sendAccessTokenViaBrowserExtensionBridge(
		resourceId: string,
		azureDeviceId: string,
		authResult: AADAuthResult
	) {
		await this.waitForEventOrTimeout(resourceId);

		if (this.popEventFromMap(resourceId)?.errorCode === 0) {
			sendAADSSOAccessToken({
				resourceId,
				transactionId: authResult.correlationId,
				correlationId: authResult.correlationId,
				azureDeviceId,
				accessToken: authResult.accessToken,
				clientNonce: authResult.aadNonce,
			});
		}
	}

	public async sendTokenErrorViaBrowserExtensionBridge(
		resourceId: string,
		azureDeviceId: string,
		tokenError: AADErrorResult
	) {
		await this.waitForEventOrTimeout(resourceId);

		if (this.popEventFromMap(resourceId)?.errorCode === 0) {
			sendAADSSOAccessToken({
				resourceId,
				transactionId: tokenError.correlationId,
				correlationId: tokenError.correlationId,
				azureDeviceId,
				accessToken: tokenError.error,
				clientNonce: '',
			});
		}
	}
}

export const aadSSOEventHandler = new AADSSOEventHandler();
