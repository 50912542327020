import * as React from 'react';
import { BreakpointsProps, useBreakpoints } from '@citrite/web-ui-component';
import { History } from 'history';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { Menu, MobileMenu } from 'App/LeftNavigation/MobileMenu';
import { PrimaryNavigation } from 'App/LeftNavigation/PrimaryNavigation';
import { SecondaryNavigation } from 'App/LeftNavigation/SecondaryNavigation';
import { StyledContentOverlay, StyledLeftNav } from 'App/LeftNavigation/styled';
import { useLeftNav } from 'App/LeftNavigation/useLeftNav';
import { performance } from 'Components/performance';
import { CONTENTID } from 'javascript/sf/constants';
import { homePagePath } from 'Router/homePageRoute';

export interface LeftNavigationProps {
	refreshPage: () => void;
}

export interface InternalProps extends BreakpointsProps, LeftNavigationProps {
	currentPath: string;
	navLinks: LeftNavLink[];
	history: History;
}

interface State {
	isHover?: boolean;
	mobileMenu?: Menu;
}

export const navPrimaryFullWidth = 240;

class _LeftNavigation extends React.Component<InternalProps, State> {
	public state: State = {
		mobileMenu: Menu.closed,
		isHover: false,
	};

	public render() {
		const mobileExpanded = this.state.mobileMenu !== Menu.closed;

		return (
			<StyledLeftNav
				expandMobileMenu={mobileExpanded}
				onMouseLeave={!this.props.screenSize.onMobile ? this.onPrimaryBlur : null}
				onSwipeLeft={this.handleClose}
				onSwipeRight={this.openMobileMenu}
				data-testid="left-nav"
			>
				{mobileExpanded && (
					<StyledContentOverlay onClick={this.handleClose} role={undefined} />
				)}
				<MobileMenu
					menu={this.state.mobileMenu}
					open={this.openMobileMenu}
					close={this.handleClose}
				/>
				{!this.isPrimaryHidden() && (
					<PrimaryNavigation
						links={this.props.navLinks.filter(n => this.isPrimaryLink(n))}
						deprecatedCollapsed={this.isPrimaryCollapsed()}
						currentPath={this.props.currentPath}
						mobileMenu={this.state.mobileMenu}
						onFocus={this.onPrimaryFocus}
						hasTabs={this.hasSecondaryNavChildren()}
						onNavigate={this.onNavigate}
					/>
				)}
				<SecondaryNavigation
					navLink={this.getSecondaryNav()}
					currentPath={this.props.currentPath}
					mobileMenu={this.state.mobileMenu}
					toggleSecondaryTab={this.toggleSecondaryTab}
					onFocus={this.onPrimaryBlur}
					onNavigate={this.onNavigate}
				/>
			</StyledLeftNav>
		);
	}

	public componentDidMount() {
		window.addEventListener('popstate', this.handlePopState);
	}

	public componentWillUnmount() {
		window.removeEventListener('popstate', this.handlePopState);
	}

	private handlePopState = () => {
		this.onNavigate(false);
	};

	public componentDidUpdate(prevProps: InternalProps) {
		if (
			prevProps.currentPath !== this.props.currentPath &&
			this.state.mobileMenu !== Menu.closed
		) {
			this.onNavigate(false);
		}
	}

	public shouldComponentUpdate(_nextProps: InternalProps, nextState: State) {
		return this.state.isHover === nextState.isHover || !!this.getSecondaryNav();
	}

	private onPrimaryFocus = () => {
		this.setState({ isHover: true });
	};

	private onPrimaryBlur = () => {
		this.setState({ isHover: false });
	};

	private isPrimaryCollapsed() {
		return (
			!!this.getSecondaryNav() &&
			!this.state.isHover &&
			this.state.mobileMenu === Menu.closed
		);
	}

	private getSecondaryNav = (links: LeftNavLink[] = this.props.navLinks) => {
		let nav: LeftNavLink = null;
		links.forEach(link => {
			if (matchPath(this.props.currentPath, { path: link.href })) {
				if (link.hasSecondaryNav) {
					nav = link;
				} else if (link.childLinks) {
					nav = this.getSecondaryNav(link.childLinks);
				}
			}
		});
		return nav;
	};

	private hasSecondaryNavChildren() {
		const secondaryNav = this.getSecondaryNav();
		return secondaryNav && secondaryNav.childLinks && !!secondaryNav.childLinks.length;
	}

	private openMobileMenu = () => {
		if (this.state.mobileMenu === Menu.closed) {
			window.history.pushState({}, 'emptyState');
			this.setState({
				mobileMenu: this.hasSecondaryNavChildren() ? Menu.secondary : Menu.primary,
			});
		} else if (this.state.mobileMenu === Menu.secondary) {
			this.setState({ mobileMenu: Menu.primary });
		}
	};

	private handleClose = () => {
		if (this.state.mobileMenu === Menu.primary && this.hasSecondaryNavChildren()) {
			this.setState({ mobileMenu: Menu.secondary });
		} else {
			this.props.history.goBack();
		}
	};

	private onNavigate = (refreshPage: boolean, performanceKey?: string) => {
		if (performanceKey) {
			performance.mark(performanceKey);
		}
		if (this.state.mobileMenu !== Menu.closed) {
			this.setState({ mobileMenu: Menu.closed });
		}
		if (refreshPage) {
			const scrollableSection = document.getElementById(CONTENTID);
			if (scrollableSection) {
				scrollableSection.scrollTop = 0;
			}
			this.props.refreshPage();
		}
	};

	private toggleSecondaryTab = () => {
		const mobileMenu =
			this.state.mobileMenu === Menu.secondary ? Menu.primary : Menu.secondary;
		this.setState({ mobileMenu });
	};

	private isPrimaryHidden() {
		if (this.props.screenSize.onMobile) {
			return false;
		}

		const nonHomePrimaryNavigationLinks = this.props.navLinks.filter(
			link => this.isPrimaryLink(link) && link.href !== homePagePath
		);

		return !nonHomePrimaryNavigationLinks.length && !this.getSecondaryNav();
	}

	private isPrimaryLink(link: LeftNavLink) {
		return link.title || link.isDivider;
	}
}

export const LeftNavigation = (props: LeftNavigationProps) => {
	const breakpointProps = useBreakpoints();
	const history = useHistory();
	const location = useLocation();
	const leftNav = useLeftNav();
	return (
		<_LeftNavigation
			{...breakpointProps}
			{...props}
			currentPath={location.pathname}
			history={history}
			navLinks={leftNav.links}
		/>
	);
};
