import React from 'react';
import { t } from '@citrite/translate';
import { breakpoints, Button, palette } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledActionContainer = styled.div`
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: flex;
	width: 80%;
	min-height: 80px;
	${breakpoints.onMediumScreen} {
		height: 15vh;
		gap: 2px;
		width: 90%;
	}
`;

interface Props {
	onPrimaryActionClick(): void;
	primaryActionId?: string;
	onSecondaryActionClick(): void;
	secondaryActionId?: string;
	primaryButtonText: string;
}
export const Action = (props: Props) => {
	return (
		<StyledActionContainer>
			<Button.Primary
				onClick={props.onPrimaryActionClick}
				style={{ width: '80%' }}
				id={props.primaryActionId}
			>
				{props.primaryButtonText}
			</Button.Primary>
			<Button.TextSecondary
				onClick={props.onSecondaryActionClick}
				style={{ color: palette.black }}
				id={props.secondaryActionId}
			>
				{t('Workspace:ftu_ui.button_text_secondary')}
			</Button.TextSecondary>
		</StyledActionContainer>
	);
};
