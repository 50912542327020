import * as React from 'react';
import { t } from '@citrite/translate';
import {
	Button,
	CopyActionIcon,
	fromThemeColors,
	layout,
	NoncriticalBadge,
	notifyError,
	ThemeColor,
} from '@citrite/web-ui-component';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { mandateNativeAppAnalyticsReporter } from 'Workspace/TelemetryEvents/mandateNativeApp/createMandateNativeAppAnalyticsReporter';
import { trackNamAndCebAnalytics } from 'Workspace/trackNamAndCebAnalytics';

const COPY_CONFIRMATION_TIMEOUT = 2000;
const StyledContainer = styled.div<{ smallFormFactor: boolean }>`
	width: 80%;
	background-color: ${fromThemeColors(ThemeColor.secondaryContainerBackground)};
	display: block;
	padding: ${layout.smallSpace};
	margin: ${layout.mediumSpace} 0;
	${props =>
		props.smallFormFactor &&
		`
		width: 100%;
		justify-content: center;
		align-items: center;
		margin: ${layout.smallSpace} 0;
		padding: ${layout.tinySpace};
	`}
`;

const StyledSpan = styled.span<{ styles: SerializedStyles }>`
	${props => props.styles}
`;

const StyledCopyDiv = styled.div<{ smallFormFactor: boolean }>`
	background-color: ${fromThemeColors(ThemeColor.primaryContainerBackground)};
	padding: ${layout.tinySpace};
	width: 75%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	${props =>
		props.smallFormFactor &&
		`
		width: 100%;
		margin: ${layout.smallSpace};
	`}
`;

export const CopyStoreLink = () => {
	const url = location.origin;
	const [urlCopied, setUrlCopied] = React.useState(false);
	const { isSmallFormFactor } = useFormFactor();
	const serializedSpanStyle = css`
		margin: ${layout.mediumSpace};
		display: flex;
		align-items: center;
		${isSmallFormFactor &&
		'justify-content: center; text-align: left; flex-direction: column;'}
	`;

	const urlStyles = css`
		margin: ${layout.smallSpace};
		align-items: center;
	`;

	const copyButtonStyles = css`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	const copyIconStyles = {
		margin: '0 8px 0 12px',
	};

	React.useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		if (urlCopied) {
			timeoutId = setTimeout(() => {
				setUrlCopied(false);
			}, COPY_CONFIRMATION_TIMEOUT);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [urlCopied]);

	const copyStoreLink = async () => {
		try {
			await navigator.clipboard.writeText(url);
			setUrlCopied(true);
			trackNamAndCebAnalytics(
				mandateNativeAppAnalyticsReporter.getMandateNativeAppStoreUrlCopiedEvent()
			);
		} catch (error) {
			notifyError('Failed to copy store URL');
		}
	};

	return (
		<StyledContainer smallFormFactor={isSmallFormFactor}>
			<StyledSpan styles={serializedSpanStyle}>
				<b>{t('Workspace:mandate_native_app_screen.already_have_app')}</b>
			</StyledSpan>
			<StyledSpan styles={serializedSpanStyle}>
				{t('Workspace:mandate_native_app_screen.copy_and_paste_url')}
			</StyledSpan>
			<StyledSpan styles={serializedSpanStyle}>
				<StyledCopyDiv smallFormFactor={isSmallFormFactor}>
					<StyledSpan styles={urlStyles}>{url}</StyledSpan>
					<StyledSpan styles={copyButtonStyles}>
						{urlCopied ? (
							<NoncriticalBadge>
								{t('Workspace:mandate_native_app_screen.url_copied')}
							</NoncriticalBadge>
						) : (
							navigator?.clipboard?.writeText && (
								<>
									|
									<Button.Wrapper onClick={copyStoreLink}>
										<CopyActionIcon size={16} style={copyIconStyles} />
									</Button.Wrapper>
								</>
							)
						)}
					</StyledSpan>
				</StyledCopyDiv>
			</StyledSpan>
		</StyledContainer>
	);
};
