import { CSSProperties, StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

const fadeIn = {
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
};

const pulse = {
	'0%': {
		backgroundColor: palette.red700,
		width: '10px',
		height: '10px',
	},
	'80%': {
		backgroundColor: palette.red700,
		width: '10px',
		height: '10px',
	},
	'100%': {
		backgroundColor: palette.red100,
		width: '24px',
		height: '24px',
	},
};

const innerCircle: CSSProperties = {
	backgroundColor: palette.red700,
	borderRadius: '50%',
	width: '10px',
	height: '10px',
};

export const styles = StyleSheet.create({
	newMessagesIcon: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '24px',
		height: '24px',
	},
	fadeIn: {
		animationName: [fadeIn] as any,
		animationDuration: '750ms',
		animationIterationCount: '1',
	},
	outerCircle: {
		backgroundColor: palette.red100,
		borderRadius: '50%',
		width: '24px',
		height: '24px',
	},
	pulsingCircle: {
		...innerCircle,
		animationName: [pulse] as any,
		animationDuration: '4s',
		animationIterationCount: '2',
	},
	staticInnerCircle: {
		...innerCircle,
		position: 'absolute',
		top: 7,
		left: 7,
	},
});
