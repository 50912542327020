import React from 'react';
import { first } from 'lodash';
import { BaseDetails } from 'App/Activity/components/BaseDetails';
import { StyledActivityBodySubContent } from 'App/Activity/components/commonStyles';
import { MoreAction } from 'App/Activity/components/MoreAction';
import { MoreInfo } from 'App/Activity/components/MoreInfo';
import { ResourceDetails, Session } from 'App/Activity/ResourceManagerContext';

export interface DesktopSessionDetailsProps {
	session: Session;
}

export const DesktopSessionDetails = ({ session }: DesktopSessionDetailsProps) => {
	const desktop: ResourceDetails = first(session.applications);
	return (
		<BaseDetails resourceDetails={desktop} session={session} hasPadding>
			<StyledActivityBodySubContent>
				<MoreInfo session={session} />
				<MoreAction session={session} />
			</StyledActivityBodySubContent>
		</BaseDetails>
	);
};
