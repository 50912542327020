import { createContext, useContext } from 'react';
import { AdminNotification } from 'AdminNotificationService/createAdminNotificationClient';

interface NotificationContextType {
	adminNotification: AdminNotification | null;
}

export const AdminNotificationContext = createContext<NotificationContextType>(null);

export const useAdminNotification = (): NotificationContextType => {
	return useContext(AdminNotificationContext);
};
