import * as React from 'react';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useUserContext } from 'Workspace/UserContext';
import { StyledApplication, StyledScrollable } from './styled';

export const Application: React.SFC = props => {
	const height = useClientHeight();
	const daasVisionEnabled = useUserContext().isDaasVisionEnabled;
	const isOffWhiteBackground = useFeatureCanary(FeatureFlag.EnableOffWhiteBackground);
	return (
		<>
			{!daasVisionEnabled ? (
				<StyledScrollable clientHeight={height}>
					<StyledApplication daasVisionEnabled={daasVisionEnabled}>
						{props.children}
					</StyledApplication>
				</StyledScrollable>
			) : (
				<StyledApplication daasVisionEnabled isOffWhiteBackground={isOffWhiteBackground}>
					{props.children}
				</StyledApplication>
			)}
		</>
	);
};
