import {
	DependencySet,
	DependencyToken,
	IntegrationManifest,
} from '@citrite/workspace-ui-platform';
import { UserDetailsAccessor } from 'IntegrationDependencies/UserDetailsAccessor';

interface InternalDependenciesTokens {
	ScopedIntegration: DependencyToken<IntegrationManifest>;
	UserDetails: DependencyToken<UserDetailsAccessor>;
}

export type InternalPlatformDependencySet = DependencySet<InternalDependenciesTokens>;

export const InternalPlatformDependencies: InternalDependenciesTokens = {
	ScopedIntegration: 'wsui_internal_ScopedIntegration',
	UserDetails: 'wsui_internal_UserDetails',
};
