import { faro } from '@grafana/faro-web-sdk';
import { monitoring } from 'MonitoringAnalytics';
import { BaseLogger } from './BaseLogger';
import { getErrorGroupId, parseErrorForLogging } from './ErrorHelpers';
import {
	ExtraContext,
	LogError,
	LogInfo,
	SetLoggingTags,
	SetUserId,
} from './LoggingProvider';

// Keep this export for unit tests.
// allow-unused-export
export class GrafanaLogger extends BaseLogger {
	private scopedTags: Record<string, any> = {};

	public constructor() {
		super();
		monitoring.setSessionAttribute('ErrorGroup', getErrorGroupId());
	}

	public configureScope = (_callback: (scope: any) => void) => {
		// noop
	};

	public defineAttribute(attribute: string, value: string | number) {
		monitoring.setSessionAttribute(attribute, value);
	}

	public logInfo: LogInfo = (message, additionalContext = {}) => {
		const attributes: Record<string, string> = {};
		Object.entries({
			...this.scopedTags,
			...additionalContext,
		}).forEach(([key, value]) => {
			if (key === 'customMessage') {
				key = 'ExtendedMessage';
			}
			attributes[key] = value.toString();
		});

		faro.api.pushLog([message], {
			context: {
				...attributes,
			},
		});
	};

	public logError: LogError = (payload, options = {}, silent = false) => {
		if (!payload) {
			payload = new Error('An undefined or empty error payload was provided');
			payload.name = 'UnsupportedErrorPayloadException';
		}

		const { customMessage, tags, additionalContext } = options;
		const { error, extractedContext } = parseErrorForLogging(
			payload,
			customMessage,
			true
		);
		const enhancedInformation: ExtraContext = {
			...this.scopedTags,
			...tags,
			...extractedContext,
			...additionalContext,
		};

		faro.api.pushError(error, {
			context: enhancedInformation,
		});

		if (!silent) {
			// eslint-disable-next-line no-console
			console.info(`The following error has been remotely logged: ${error.message}`);
		}
	};

	public setLoggingTags: SetLoggingTags = (tags, options) => {
		if (options?.global) {
			Object.entries(tags).forEach(([key, value]) => {
				this.defineAttribute(key, value);
			});
		} else {
			Object.assign(this.scopedTags, tags);
		}
	};

	public setUserId: SetUserId = id => this.defineAttribute('UserId', id);
}

export function createGrafanaLogger() {
	return new GrafanaLogger();
}
