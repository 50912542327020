import * as React from 'react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { clientType, getClientType } from 'Environment/getClientType';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';
import { fallbackToPreviousClientType } from 'Workspace/BrowserExtension/fallbackToPreviousClientType';
import {
	AsyncResiliencyBrowserExtensionContextProvider,
	EmptyProvider,
} from 'Workspace/BrowserExtension/Provider';
import { shouldMountBrowserExtensionContextProvider } from 'Workspace/BrowserExtension/shouldMountBrowserExtensionProvider';
import { useUserContext } from 'Workspace/UserContext';

export const ResiliencyBrowserExtensionSupport: React.FC = props => {
	const { workspaceConfiguration } = useConfigurationContext();

	const userContext = useUserContext();
	if (shouldMountBrowserExtensionContextProvider(workspaceConfiguration)) {
		return (
			<AsyncResiliencyBrowserExtensionContextProvider
				userContext={userContext}
				workspaceConfiguration={workspaceConfiguration}
			>
				{props.children}
			</AsyncResiliencyBrowserExtensionContextProvider>
		);
	}

	if (getClientType() === clientType.browserextension) {
		fallbackToPreviousClientType();
	}

	return <EmptyProvider {...props} />;
};

// The exported function markToLoadInOfflineMode and shouldLoadInOfflineMode are used to determine
// if WSUI to be loaded in offline mode as initiated by BE.
// This is a temporary solution to handle the BE initiation from WSUI for offline mode
// This will be replaced with a better solution in the future along with WSUI-9433

const WSUI_BXTN_LOAD_IN_OFFLINE_KEY = 'wsui-bxtn-loadInOffline';
const WSUI_BXTN_LOAD_IN_OFFLINE_VALUE = 'loadInOfflineMode';
export const markToLoadInOfflineMode = () => {
	setInSessionStorage(WSUI_BXTN_LOAD_IN_OFFLINE_KEY, WSUI_BXTN_LOAD_IN_OFFLINE_VALUE);
};

export const shouldLoadInOfflineMode = () => {
	const loadInOfflineMode = getFromSessionStorage(WSUI_BXTN_LOAD_IN_OFFLINE_KEY, true);
	return loadInOfflineMode === WSUI_BXTN_LOAD_IN_OFFLINE_VALUE;
};
