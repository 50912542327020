import platform from 'platform';
import { environment } from 'Environment';
import { workspacePlatformHeaders } from 'javascript/interceptors/workspacePlatform';
import { detectLanguage } from 'javascript/sf/detectLanguage';

// https://code.citrite.net/projects/WS/repos/device-registration-service/browse/src/Citrix.Wsp.DeviceRegistration.Models
interface RegisteredDevice {
	application: {
		applicationIdentifier: string;
	};
	browser: {
		type: string;
		version: string;
	};
	deliveryType: 'RealTime' | 'Pns';
	name: string;
	id?: string; // Optional: used for device reconnection
	os: {
		type: OSType;
		version: string;
	};
	properties: {
		language: string;
	};
	token: string;
	type: string;
}

export interface DeviceRegistrationConfig {
	connectionId: string;
	registrationUrl: string;
	deviceId?: string;
	customerId: string;
	storeId: string;
	proxyUrl: string;
}

enum OSType {
	Android = 'Android',
	iOS = 'iOS',
	Linux = 'Linux',
	Mac = 'Mac',
	Windows = 'Windows',
}

function getFamily(family: string): OSType {
	for (const type in OSType) {
		if (family?.includes(type)) {
			return OSType[type];
		}
	}
	switch (family) {
		case 'OS X':
			return OSType.Mac;
		case 'Ubuntu':
		case 'Debian':
		case 'Fedora':
		case 'Red Hat':
			return OSType.Linux;
		default:
			return OSType.Windows;
	}
}

export const registerDevice = async (
	config: DeviceRegistrationConfig
): Promise<{ data?: { id?: string } }> => {
	const { connectionId, registrationUrl, proxyUrl, customerId, storeId, deviceId } =
		config;
	const client = environment.createSSOProxyClient(proxyUrl);
	const device: RegisteredDevice = {
		application: {
			applicationIdentifier: 'citrix.iws.workspace.web',
		},
		browser: {
			type: platform.name,
			version: platform.version,
		},
		deliveryType: 'RealTime',
		name: 'WSUIClient',
		id: deviceId || null,
		os: {
			type: getFamily(platform.os.family),
			version: platform.os.version,
		},
		properties: {
			language: detectLanguage(),
		},
		token: connectionId,
		type: 'Web',
	};

	return client.postWithFullResponse<any>(registrationUrl, device, {
		headers: {
			...workspacePlatformHeaders(),
			'Citrix-CustomerId': customerId,
			'Citrix-InstanceId': storeId,
		},
	});
};
