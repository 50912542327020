import * as React from 'react';
import { SkeletonLoaderShapes } from '@citrite/workspace-ui-platform-react';
import { matchPath } from 'react-router-dom';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import {
	StyledAnchor,
	StyledDivider,
	StyledListItem,
	StyledLoading,
	StyledNavLink,
} from 'App/LeftNavigation/PrimaryNavigation/ChildLink.styled';
import { activeClassName } from 'App/LeftNavigation/PrimaryNavigation/Link.styled';
import { ChildLinkBody } from './ChildLinkBody';

const { Bar, BarHeight } = SkeletonLoaderShapes;

export interface ChildLinkProps {
	index: number;
	focusable: boolean;
	link: LeftNavLink;
	onLinkClick(e: React.MouseEvent, link: LeftNavLink): void;
	currentPath: string;
}

export function ChildLink(props: ChildLinkProps) {
	const { link } = props;
	if (link.isLoading) {
		return (
			<StyledLoading>
				<Bar width={props.index % 2 ? 92 : 60} height={BarHeight.Thin} />
			</StyledLoading>
		);
	}
	const tabIndex = !props.focusable ? -1 : null;
	const active = !!matchPath(props.currentPath, { path: link.href });
	return (
		<StyledListItem
			data-testid={`list-item-${props.link.title
				?.toLocaleLowerCase()
				.replace(/\s/g, '-')}`}
		>
			{link.isDivider ? (
				<StyledDivider />
			) : (
				<>
					{link.href ? (
						<StyledNavLink
							to={link.href}
							tabIndex={tabIndex}
							activeClassName={activeClassName}
							onClick={e => props.onLinkClick(e, link)}
						>
							<ChildLinkBody {...link} active={active} />
						</StyledNavLink>
					) : (
						<StyledAnchor
							href="#"
							onClick={e => props.onLinkClick(e, link)}
							tabIndex={tabIndex}
						>
							<ChildLinkBody {...link} active={active} />
						</StyledAnchor>
					)}
				</>
			)}
		</StyledListItem>
	);
}
