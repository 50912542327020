import React from 'react';
import { breakpoints, media, palette, useBreakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ResourceTile } from 'Components/FTU/ResourceTile';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { paddingOuterContainer, resourceContainerHeight } from './ResourceTile';

const StyledListContainer = styled.div`
	height: 350px;
	overflow-y: auto;
	padding: 3px 3px;
	flex-direction: column;
	display: flex;
	${breakpoints.onMediumScreen} {
		height: 55vh;
		width: 80%;
	}
`;

const StyledListRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2px;
	padding: 0px 2px;
	min-height: calc(${paddingOuterContainer}px * 2 + ${resourceContainerHeight}px + 2px);
	background: ${palette.white};
`;

const StyledEmptyListRow = styled(StyledListRow)`
	min-height: 0px;
`;
interface Props {
	selectedResources: Resource[];
	setSelectedResources: (resources: Resource[]) => void;
	resourceList: Resource[];
}

export function SearchList(props: Props) {
	const breakpoint = useBreakpoints();
	const appendSelectedItem = (resource: Resource) => {
		props.selectedResources.push(resource);
		props.setSelectedResources(props.selectedResources);
	};
	const removeSelectedItem = (resource: Resource) => {
		const index = props.selectedResources.findIndex(value => value.id === resource.id);
		props.selectedResources.splice(index, 1);
		props.setSelectedResources(props.selectedResources);
	};
	const isItemSelected = (resource: Resource) =>
		props.selectedResources.findIndex(value => value.id === resource.id) >= 0;
	const isSmallScreen = () => breakpoint.screenSize.media === media.small;
	const isTwoItemRow = (idx: number, resourceArray: Resource[]) =>
		idx % 2 === 0 && resourceArray[idx + 1];
	const isSingleItemRow = (idx: number, resourceArray: Resource[]) =>
		idx % 2 === 0 && !resourceArray[idx + 1];

	return (
		<StyledListContainer>
			{props.resourceList &&
				props.resourceList.map((resource, idx, resourceArray) => {
					if (isSmallScreen() || isSingleItemRow(idx, resourceArray)) {
						return (
							<StyledListRow key={resource.id}>
								<ResourceTile
									resource={resource}
									appendSelectedItem={appendSelectedItem}
									removeSelectedItem={removeSelectedItem}
									isItemSelected={isItemSelected}
									key={resource.id}
								/>
							</StyledListRow>
						);
					} else if (isTwoItemRow(idx, resourceArray)) {
						return (
							<StyledListRow key={resource.id}>
								<ResourceTile
									resource={resource}
									appendSelectedItem={appendSelectedItem}
									removeSelectedItem={removeSelectedItem}
									isItemSelected={isItemSelected}
									key={resource.id}
								/>
								<ResourceTile
									resource={resourceArray[idx + 1]}
									appendSelectedItem={appendSelectedItem}
									removeSelectedItem={removeSelectedItem}
									isItemSelected={isItemSelected}
									key={resourceArray[idx + 1].id}
								/>
							</StyledListRow>
						);
					} else {
						return <StyledEmptyListRow key={resource.id}></StyledEmptyListRow>;
					}
				})}
		</StyledListContainer>
	);
}
