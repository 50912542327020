import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, EmptyConnectionErrorIcon } from '@citrite/web-ui-component';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { Banner } from 'App/Header/Banner';
import { Title } from 'App/Header/Title';
import { useFormFactor } from 'App/Header/useFormFactor';
import { bannerContentHeight } from 'App/Header/util';
import { FullScreenError } from 'Components/FullScreenError';
import { DefaultErrorPage } from 'ErrorPage/DefaultErrorPage';
import { useConnectionDetailsContext } from 'Workspace/ConnectionDetailsContext/Consumer';

interface Props {
	children: JSX.Element;
}

function _ConnectionError(props: Props) {
	const isOnline = useConnectionDetailsContext();
	const { isLargeFormFactor } = useFormFactor();

	if (isOnline) {
		return props.children;
	}

	return (
		<>
			<Banner>
				{isLargeFormFactor ? (
					<Title />
				) : (
					<div id="empty-header" style={{ height: `${bannerContentHeight}px` }} />
				)}
			</Banner>
			<FullScreenError
				title={t('Workspace:connection_error.generic_title')}
				subtitle={t('Workspace:connection_error.helper_text')}
				icon={<EmptyConnectionErrorIcon />}
			>
				<Button.Primary onClick={reloadWindow}>
					{t('Workspace:connection_error.retry_button')}
				</Button.Primary>
			</FullScreenError>
		</>
	);
}

export function ConnectionError(props: Props) {
	return (
		<GenericErrorBoundary
			renderProp={() => <_ConnectionError {...props} />}
			fallback={() => <DefaultErrorPage />}
			name="ConnectionError"
		/>
	);
}

function reloadWindow() {
	if (navigator.onLine) {
		location.reload();
	}
}
