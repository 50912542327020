import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { Button, LoadingIconPrimary } from '@citrite/web-ui-component';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { launchResource } from 'Environment/launchResource';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './OpenDesktopButton.styles';

export interface Props {
	resource: Resource;
}

export function OpenDesktopButton(props: Props) {
	const { workspaceConfiguration } = useConfigurationContext();
	const resourceContext = useLoadableResourceContext().value;
	const isInProgress = resourceContext.isInProgress(props.resource.id);

	return isInProgress ? (
		<div className={css(styles.loadingContainer)}>
			<div className={css(styles.loadingIcon)}>
				<LoadingIconPrimary size={18} />
			</div>
			{t('Workspace:open_in_progress')}
		</div>
	) : (
		<Button.TextPrimary
			onClick={() =>
				launchResource({
					resource: props.resource,
					resourceContext,
					workspaceConfiguration,
				})
			}
		>
			{t('Workspace:open_desktop')}
		</Button.TextPrimary>
	);
}
