import React from 'react';
import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { Favorite } from 'Components/ResourceTile/Favorite';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const StyledDiv = styled.div`
	position: absolute;
	top: ${layout.smallSpace};
	left: ${layout.smallSpace};
`;

interface Props {
	resource: Resource;
	inputRef?: React.RefObject<HTMLInputElement>;
}

const FavoriteIcon = ({ resource, inputRef }: Props) => {
	const onClick = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

	return (
		<StyledDiv onClick={onClick}>
			<Favorite {...resource} inputRef={inputRef} />
		</StyledDiv>
	);
};

export const FavoriteIconWithRef = React.forwardRef(
	(props: Props, ref: React.RefObject<HTMLInputElement>) => (
		<FavoriteIcon {...props} inputRef={ref} />
	)
);
