import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AdminNotificationProvider } from 'AdminNotificationService/AdminNotificationProvider';
import { NativeActionSyncAndLaunchEventHandlerWrapper } from 'App/NativeStartMenuActions/NativeActionSyncAndLaunchEventHandlerWrapper';
import { ConditionalRenderer } from 'Components/ConditionalRenderer';
import { Detection } from 'Components/Detection';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { isNativeWidget } from 'Environment/launchResource/device';
import { BladeAndModalHost } from 'IntegrationDependencies/BladeAndModalHost';
import { IntegrationProvidedContexts } from 'Integrations/IntegrationProvidedContexts';
import { NotificationProvider } from 'userNotifications';
import { AppContext } from 'Workspace/AppContext';
import { UserContextLoader } from 'Workspace/UserContext';
import { App } from './App';
import { shouldDetectClientBeforeAuth } from './DetectionFlowUtils';

export function AppShell(_props: RouteComponentProps<null>) {
	const { workspaceConfiguration } = useConfigurationContext();
	const detectClientBeforeAuthentication =
		shouldDetectClientBeforeAuth(workspaceConfiguration);
	const DetectionProps = {
		shouldDetectCWA: true,
		detectClientBeforeAuthentication,
	};

	return (
		<ConditionalRenderer
			flag={detectClientBeforeAuthentication}
			OnComponent={Detection}
			OnComponentProps={DetectionProps}
		>
			<UserContextLoader>
				<IntegrationProvidedContexts>
					<AppContext shouldDetectCWA={!detectClientBeforeAuthentication}>
						{refreshingResources =>
							!isNativeWidget() ? (
								<AdminNotificationProvider>
									<NotificationProvider>
										<BladeAndModalHost />
										<App refreshingResources={refreshingResources} />
									</NotificationProvider>
								</AdminNotificationProvider>
							) : (
								<App refreshingResources={refreshingResources} />
							)
						}
					</AppContext>
					<NativeActionSyncAndLaunchEventHandlerWrapper />
				</IntegrationProvidedContexts>
			</UserContextLoader>
		</ConditionalRenderer>
	);
}
