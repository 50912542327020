import { StyleSheet } from 'aphrodite';
import { palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	selectedOption: {
		fontWeight: typography.weights.semiBold,
	},
	feedSelector: {
		display: 'flex',
		alignItems: 'center',
	},
	arrowDownIcon: {
		marginLeft: '8px',
		padding: '8px',
		height: '16px',
		display: 'inline-flex',
		borderRadius: '50%',
	},
	arrowDownIconHover: {
		backgroundColor: palette.grey200,
	},
});
