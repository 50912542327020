import Cookies from 'js-cookie';
import URI from 'urijs';

export const getReceiverConfigurationUrl = (downloadURL: string) => {
	const uri = new URI(downloadURL);
	const token = Cookies.get('CsrfToken');
	if (token) {
		uri.addQuery({
			CsrfToken: token,
			IsUsingHttps: window.location.protocol === 'https:' ? 'Yes' : 'No',
		});
	}

	return uri.toString();
};
