import * as React from 'react';
import { Button } from '@citrite/web-ui-component';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useInScrolledView } from 'App/Screen/useInScrolledView';
import { CategoryDetails } from 'App/Tile/CategoryDetails';
import {
	DivWrapper,
	largeFormButtonWrapperStyle,
	smallFormButtonWrapperStyle,
	StyledDiv,
} from 'App/Tile/GenericTile';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';

interface Props {
	subCategory: string;
	setSubCategoryPath: React.Dispatch<React.SetStateAction<any[]>>;
	subCategoryPath: any[];
}

function _CategoryTile({ subCategory, setSubCategoryPath, subCategoryPath }: Props) {
	const { isLargeFormFactor, isSmallFormFactor } = useFormFactor();
	const { elementRef, inScrolledView } = useInScrolledView();
	const isOffWhiteBackground = useFeatureCanary(FeatureFlag.EnableOffWhiteBackground);

	const onClick = () => {
		setSubCategoryPath([...subCategoryPath, subCategory]);
	};

	const shouldWrapButtonComponent = !useFeatureCanary(
		FeatureFlag.DisableResourceButtonWrap
	);

	const Wrapper = shouldWrapButtonComponent ? Button.Wrapper : DivWrapper;

	return (
		<Wrapper
			onClick={onClick}
			style={
				isLargeFormFactor ? largeFormButtonWrapperStyle : smallFormButtonWrapperStyle
			}
		>
			<StyledDiv
				isSmallFormFactor={isSmallFormFactor}
				isOffWhiteBackground={isOffWhiteBackground}
				isResourceAvailable
				data-analytics-name="resource-tile"
				data-testid={subCategory}
				ref={elementRef}
			>
				{inScrolledView && (
					<>
						<CategoryDetails
							isSmallFormFactor={isSmallFormFactor}
							subCategory={subCategory}
						/>
					</>
				)}
			</StyledDiv>
		</Wrapper>
	);
}

export const CategoryTile = (props: any) => {
	return (
		<GenericErrorBoundary
			renderProp={() => <_CategoryTile {...props} />}
			name="CategoryTile"
		/>
	);
};
