import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { ExtensionConfigurationState } from 'Workspace/BrowserExtension/Context';

const offlineParameterName = '/ctxOffline';

export function shouldUseLegacyOfflineFlow(
	extensionConfiguration: ExtensionConfigurationState,
	workspaceConfiguration: WorkspaceConfiguration
) {
	return !(
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.UseModernOfflineFlow) &&
		extensionConfiguration.isModernOfflineFlowSupported
	);
}

class OfflineParameter {
	private offlineParameterFoundInUrl = false;
	private static instance: OfflineParameter;

	private constructor() {
		//Do nothing. Private constructor to prevent external instantiation.
	}

	public static getInstance(): OfflineParameter {
		if (!OfflineParameter.instance) {
			OfflineParameter.instance = new OfflineParameter();
		}
		return OfflineParameter.instance;
	}

	public isWsuiLaunchedInOfflineMode(): boolean {
		return this.offlineParameterFoundInUrl;
	}

	public extractOfflineParameterFromUrl(): void {
		if (window?.location?.hash?.includes(offlineParameterName)) {
			this.offlineParameterFoundInUrl = true;
			window.location.hash = '';
		} else {
			this.offlineParameterFoundInUrl = false;
		}
	}
}

export const offlineParameter = OfflineParameter.getInstance();
