type Tokenization<T> = (() => T) | string;

export type Capability<TCapabilityInterface = any, TExpectedMetadata = never> = {
	token: Tokenization<TCapabilityInterface>;
	register<TModuleKey extends string>(
		moduleKey: TModuleKey,
		metadata?: TExpectedMetadata
	): CapabilityRegistration<TCapabilityInterface, TModuleKey, TExpectedMetadata>;
};

export interface CapabilityRegistration<
	TCapabilityInterface,
	TModuleKey extends string,
	TMetadata
> {
	moduleKey: TModuleKey;
	token: Tokenization<TCapabilityInterface>;
	metadata?: TMetadata;
}

/** @internal */
export function createCapability<TCapabilityInterface, TExpectedMetadata = never>(
	token: string
): Capability<TCapabilityInterface, TExpectedMetadata> {
	return {
		token,
		register: (moduleKey, metadata) => {
			return {
				moduleKey,
				token,
				metadata,
			};
		},
	};
}
