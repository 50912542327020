import * as React from 'react';
import { ResourceManagerProvider } from 'App/Activity/ResourceManagerProvider';
import { ConfigurationProvider } from 'Configuration/Provider';
import { IntegrationProvider } from 'Integrations/IntegrationProvider';
import { ExecuteFeedCardActionEventIntegrationAdapter } from 'userNotifications/EventSubscribers/ExecuteFeedCardActionEventIntegrationAdapter';
import { FeedCardViewActionSubscriber } from 'userNotifications/EventSubscribers/FeedCardViewActionSubscriber';
import { ResiliencyBrowserExtensionSupport } from 'Workspace/BrowserExtension/ResiliencyBrowserExtensionSupport';
import { CacheProvider } from 'Workspace/Cache';
import { ChromeAppSupport } from 'Workspace/ChromeApp/ChromeAppSupport';
import { EventBusProvider } from 'Workspace/EventBus/EventBusProvider';
import { ResourceProvider } from 'Workspace/ResourceProvider';
                   
import { ServiceWorkerDetection } from 'Workspace/ServiceWorkerDetection';
          
import { UserProvider } from 'Workspace/UserContext';
import { ConnectionDetailsProvider } from './ConnectionDetailsContext/Provider';
import { UIProviders } from './UIProviders';

export const ApplicationProviders = (props: { children?: React.ReactNode }) => (
	<EventBusProvider>
		<ConfigurationProvider>
			<ConnectionDetailsProvider>
				<UserProvider>
					<UIProviders>
						<IntegrationProvider>
							<CacheProvider>
								<ResiliencyBrowserExtensionSupport>
									<ChromeAppSupport>
										<ResourceProvider>
											<ResourceManagerProvider>{props.children}</ResourceManagerProvider>
											<FeedCardViewActionSubscriber />
											<ExecuteFeedCardActionEventIntegrationAdapter />
										</ResourceProvider>
									</ChromeAppSupport>
								</ResiliencyBrowserExtensionSupport>
							</CacheProvider>
						</IntegrationProvider>
					</UIProviders>
				</UserProvider>
				{!IS_ON_PREM && <ServiceWorkerDetection />}
			</ConnectionDetailsProvider>
		</ConfigurationProvider>
	</EventBusProvider>
);
