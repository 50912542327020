import { useIntegrations } from 'Integrations/useIntegrations';
import { NotificationIntegrationId } from 'userNotifications/manifest';
import { NotificationService } from 'userNotifications/service/NotificationService';

export function useNotificationService() {
	return useIntegrations().resolveById<NotificationService>(
		NotificationIntegrationId,
		'service'
	);
}
