import {
	ieDecryptData,
	ieDigestString,
	ieEncryptData,
	msCryptoAvailable,
} from './msSubtleEncryption';
import {
	decryptData,
	digestString,
	encryptData,
	subtleCryptoAvailable,
} from './subtleEncryption';
import { CryptoService } from './types';

export const createCryptoService = (): CryptoService => ({
	encrypt: msCryptoAvailable() ? ieEncryptData : encryptData,
	decrypt: msCryptoAvailable() ? ieDecryptData : decryptData,
	digest: msCryptoAvailable() ? ieDigestString : digestString,
	isSupported: () => subtleCryptoAvailable() || msCryptoAvailable(),
});
