import { FeedCardExtensionCapabilityMetadata } from '@citrite/workspace-ui-platform';

const MFE_DETAIL_TYPE = 'MFE';

export const isExtensionForFeedCard = (
	metadata: FeedCardExtensionCapabilityMetadata,
	feedCardSourceOrigin?: string,
	feedCardDetailType?: string,
	mfeRenderer?: string
) => {
	if (
		'feedCardDetailType' in metadata &&
		metadata.feedCardDetailType === MFE_DETAIL_TYPE &&
		feedCardDetailType === MFE_DETAIL_TYPE
	) {
		return !!mfeRenderer && metadata.mfeRenderer === mfeRenderer;
	} else {
		return 'feedCardSourceOrigin' in metadata
			? metadata.feedCardSourceOrigin === feedCardSourceOrigin
			: metadata.feedCardDetailType === feedCardDetailType;
	}
};
