import React from 'react';
import { t } from '@citrite/translate';
import { palette, WorkspaceLogoIcon, XIcon } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import {
	CardDivStyle,
	StyledAlertAction,
	StyledAlertContent,
	StyledAlertSection,
	StyledStatus,
	StyledStatusText,
} from 'App/ProgressAlert/LaunchProgressAlert.styles';

const StyledCard = styled.div`
	${CardDivStyle}
	border-top: 1px solid ${palette.grey200};
	background-color: ${palette.grey100};
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

const StyledCloseAction = styled(StyledAlertAction)`
	margin: 0 16px;
	cursor: pointer;
`;
interface Props {
	onClose: () => void;
	activeLaunchsCount: number;
}
export function WaitAlert({ onClose, activeLaunchsCount }: Props) {
	return (
		<StyledCard data-testid="wait-alert">
			<StyledAlertContent>
				<WorkspaceLogoIcon data-testid="workspace-logo-icon" size={27} />
				<StyledAlertSection>
					<StyledStatus>
						<StyledStatusText data-testid="status-text">
							{activeLaunchsCount > 1
								? t('Workspace:wait_for_multiple_launches')
								: t('Workspace:wait_for_single_launch')}
						</StyledStatusText>
					</StyledStatus>
				</StyledAlertSection>
			</StyledAlertContent>
			<StyledCloseAction>
				<XIcon data-testid="xicon" size={12} onClick={onClose} />
			</StyledCloseAction>
		</StyledCard>
	);
}
