import { post } from '@citrite/http';
import { trackAnalyticsEvent } from 'analytics';
import {
	contentTypeJson,
	SessionOperationRequestData,
	SessionOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { activityMangerCASReporter } from 'Workspace/TelemetryEvents/activityManager/createActivityManagerCASReporter';

export const disconnectRemotely = async (endpoint: string, sessionId: string) => {
	trackAnalyticsEvent(activityMangerCASReporter.getActivityManagerDisconnectEvent(true));
	const body: string = JSON.stringify(new SessionOperationRequestData([sessionId]));
	return post<SessionOperationResponse>(endpoint, body, contentTypeJson);
};
