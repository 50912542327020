import * as React from 'react';
import { palette, useBreakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { BarHeight } from './Bar';
import { getBarHeight, linearGradientLightImage, shimmer } from './styles';

export enum BarSpacing {
	Small,
	Large,
}

export interface Props {
	width: string;
	height?: BarHeight;
	topMargin?: string;
}

interface StyledRectangleProps {
	width: string;
	height: string;
	topMargin?: string;
	smallFormFactor?: boolean;
}

const StyledBarDiv = styled.div<StyledRectangleProps>`
	width: ${props => props.width};
	height: ${props => props.height};
	background-color: ${palette.grey300};
	border-radius: 4px;
	background-image: ${linearGradientLightImage};
	background-repeat: no-repeat;
	display: inline-block;
	position: relative;
	margin-top: ${props => props.topMargin || '10px'};
	align-self: ${props => (props.smallFormFactor ? 'flex-start' : 'center')};
	animation: ${shimmer} 2s infinite forwards;
`;

export function LoadingBar(props: Props) {
	const breakpoints = useBreakpoints();
	return (
		<StyledBarDiv
			data-testid="loading-bar"
			width={props.width}
			height={getBarHeight(props.height)}
			smallFormFactor={breakpoints.screenSize.onMobile}
			topMargin={props.topMargin}
		/>
	);
}
