import { t } from '@citrite/translate';
import { notifyError } from '@citrite/web-ui-component';
import { trackEvent } from 'analytics';
import { environment } from 'Environment';

export async function reconnect(triggerType?: string) {
	try {
		await environment.goOnline();
		if (triggerType) {
			trackEvent(`${triggerType}ReconnectSuccess`);
		}
		location.reload();
	} catch (_) {
		notifyError(t('Workspace:cloud_reconnect_error'));
	}
}
