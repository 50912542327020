import { TelemetryClient } from '@citrite/workspace-ui-platform';
import {
	ActivityEventWithDetails,
	createActivityEventWithDetails,
} from './activityEvents';

export function activityEventPublisher(
	publishTelemetryEvent: TelemetryClient['publishEvent'],
	verb: string,
	noun: string
) {
	let activityEventPublished = false;
	let fromCache: boolean = undefined;
	let startTime: number;

	const publishEvent = (
		result: 'SUCCESS' | 'FAILURE',
		endTime: number,
		httpResponse?: number,
		transactionId?: string
	) => {
		if (activityEventPublished) {
			return;
		}

		const duration = endTime - startTime;
		let event: ActivityEventWithDetails;

		event = createActivityEventWithDetails(
			verb,
			noun,
			result,
			duration,
			httpResponse,
			transactionId,
			fromCache
		);
		publishTelemetryEvent(event);
		activityEventPublished = true;
	};

	return {
		startTimer: () => {
			startTime = Date.now();
		},
		setFromCache: (value: boolean) => {
			fromCache = value;
		},
		publishEvent,
	};
}
