import * as React from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import {
	EventSubscriber,
	EventType,
	isValidResourceURN,
	parseResourceURN,
	ViewResourceActionEvent,
} from 'Components/EventBus';
import { feedRoutePath } from 'userNotifications/createFeedPageLink';
import { Event } from 'Workspace/EventBus';

type FeedCardViewAction = ViewResourceActionEvent;

const FEED_CARD_RESOURCE_NAME = 'feedCard';

function isFeedCardURN(urn: string) {
	return (
		isValidResourceURN(urn) &&
		parseResourceURN(urn).resourceName === FEED_CARD_RESOURCE_NAME
	);
}
function isFeedCardViewEvent(event: Event<any>): event is FeedCardViewAction {
	return (
		event.type === EventType.VIEW_ACTION &&
		event.payload &&
		event.payload.urn &&
		isFeedCardURN(event.payload.urn)
	);
}

function handleFeedCardViewAction(history: History, event: FeedCardViewAction) {
	const { id: feedCardId } = parseResourceURN(event.payload.urn);
	const feedUrl = `${feedRoutePath}?feedCardId=${feedCardId}`;
	history.replace(feedUrl);
}

function handleEvent(history: History, event: Event<any>) {
	if (isFeedCardViewEvent(event)) {
		handleFeedCardViewAction(history, event);
	}
}

export function FeedCardViewActionSubscriber() {
	const history = useHistory();
	return (
		<EventSubscriber listener={(event: Event<any>) => handleEvent(history, event)} />
	);
}
