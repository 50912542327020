import { t } from '@citrite/translate';
import { ActivityManagerIcon, ActivityManagerSolidIcon } from '@citrite/web-ui-component';
import { LeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { isSmallForm } from 'App/Header/useFormFactor';
import { createLeftNavRoute } from 'Router/ReactRoute';

export const activityPagePath = '/activity';
const ActivityManagerRouteLauncher = makeAsync(() =>
	import(
		/* webpackChunkName: "ActivityManagerRouteLauncher" */ './ActivityManagerLauncher/ActivityManagerRouteLauncher'
	).then(m => m.ActivityManagerRouteLauncher)
);

//This will be taken care as a separate ticket.
function isActivityLoading() {
	return false;
}

export const activityRoute = createLeftNavRoute({
	component: ActivityManagerRouteLauncher,
	path: activityPagePath,
	childRoutes: null,
	isAvailable: props => props.userContext.isActivityManagerEnabled && isSmallForm(),
	isLoading: () => isActivityLoading(),
	getNavLinkProps: () => ({
		icon: ActivityManagerIcon,
		selectedIcon: ActivityManagerSolidIcon,
		title: t('Workspace:activity_manager.activity'),
		weight: LeftNavPositions.activityManager,
		performanceMark: null,
		analyticsID: 'activity-nav-tab',
	}),
});
