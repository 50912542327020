import React from 'react';
import { typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ResourceIcon } from 'Components/ResourceIcon';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const StyledOuterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 120px;
	height: 105px;
	align-items: center;
`;

const StyledInnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 64px;
	height: 50px;
	margin: 2px 43px 5px 31px;
`;

const StyledArrowIcon = styled.img`
	height: 14px;
	width: 14px;
	margin-top: 36px;
`;

const StyledResourceIconContainer = styled.div`
	width: 50px;
	height: 50px;
	mix-blend-mode: multiply;
`;

const StyledResourceName = styled.div`
	font-family: 'PublicSans', Arial, sans-serif;
	font-style: normal;
	font-weight: ${typography.weights.medium};
	font-size: 15px;
	text-align: center;
	color: rgba(0, 0, 0, 0.8956);
`;

const shortcutArrowImage = require('./images/shortcutArrow.svg');

interface Props {
	resource: Resource;
}

export const ShortcutTile = (props: Props) => {
	return (
		<StyledOuterContainer>
			<StyledInnerContainer>
				<StyledArrowIcon alt="arrow" src={shortcutArrowImage} />
				<StyledResourceIconContainer>
					<ResourceIcon resource={props.resource} size={50} />
				</StyledResourceIconContainer>
			</StyledInnerContainer>
			<StyledResourceName>{props.resource.name}</StyledResourceName>
		</StyledOuterContainer>
	);
};
