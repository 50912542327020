import {
	createIntegration,
	IntegrationCapability,
	isVanityDomain,
	performance,
	workspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { FeatureFlag } from './FeatureFlag';
import { PerformanceEvents } from './performanceEvents';
import { microAppsPrivateSymbol } from './privateSymbol';

const endpointServiceName = 'microApps';
const feedCardSourceOrigin = 'MICRO_APPS';

export const microAppIntegration = createIntegration({
	id: 'microApps',
	privateSymbol: microAppsPrivateSymbol,
	requiredEndpointServiceName: endpointServiceName,
	cacheVersion: '1',
	capabilities: [
		IntegrationCapability.actionsProvider.register('service'),
		IntegrationCapability.logout.register('service'),
		IntegrationCapability.feedCardExtensions.viewDetails.register('feedCard', {
			feedCardSourceOrigin,
		}),
		IntegrationCapability.feedCardExtensions.executeFeedCardAction.register(
			'feedCardActionExecutor',
			{
				feedCardSourceOrigin,
			}
		),
		IntegrationCapability.appStartup.register('service'),
	],
	modulesResolver: {
		service: ctx =>
			import(/* webpackChunkName: "MicroApps" */ './create').then(({ create }) =>
				create(ctx)
			),
		microAppClient: ctx =>
			import(/* webpackChunkName: "MicroApps" */ './configureMicroAppClient').then(
				({ configureMicroAppClient }) => configureMicroAppClient(ctx)
			),
		feedCard: () =>
			import(/* webpackChunkName: "MicroApps" */ './FeedCard').then(
				({ createFeedCardIntegration }) => createFeedCardIntegration()
			),
		feedCardActionExecutor: () =>
			import(/* webpackChunkName: "MicroApps" */ './createFeedCardActionExecutor').then(
				({ createFeedCardActionExecutor }) => {
					performance.mark(PerformanceEvents.FeedCardActionExecutor_Registered);
					performance.measure(
						PerformanceEvents.FeedCardActionExecutor_Registered,
						'EntryHtml_Start'
					);
					return createFeedCardActionExecutor();
				}
			),
	},
	isEnabled: () =>
		(!isVanityDomain() ||
			workspaceConfiguration.hasFeatureCanary(FeatureFlag.EnableIwsInVanityDomain)) &&
		workspaceConfiguration.hasEndpointService(endpointServiceName),
	translationModules: {
		de: () => import('./locales/de.json'),
		en: () => import('./locales/en.json'),
		es: () => import('./locales/es.json'),
		fi: () => import('./locales/fi.json'),
		fr: () => import('./locales/fr.json'),
		it: () => import('./locales/it.json'),
		ja: () => import('./locales/ja.json'),
		ko: () => import('./locales/ko.json'),
		nl: () => import('./locales/nl.json'),
		'pt-BR': () => import('./locales/pt-BR.json'),
		ru: () => import('./locales/ru.json'),
		'zh-CN': () => import('./locales/zh-CN.json'),
		'zh-TW': () => import('./locales/zh-TW.json'),
	},
});
