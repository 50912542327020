import * as React from 'react';
import { css } from 'aphrodite';
import { environment } from 'Environment';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './Siri.styles';

export interface Props extends Resource, WithResourceContextProps {}

interface State {
	isAdded?: boolean;
}

class _SIRI extends React.Component<Props, State> {
	public state: State = {
		isAdded: this.props.isResourceRegisteredToSiri,
	};

	public render() {
		return (
			<label className={css(styles.label)}>
				<div className={css(styles.button, this.state.isAdded && styles.statusOnly)}>
					{
						<>
							{
								<input
									type="checkbox"
									checked={this.state.isAdded}
									onChange={this.onChange}
									className={css(styles.input)}
									disabled={this.props.disabled}
								/>
							}
							<img
								src={
									this.state.isAdded
										? require('../ResourceTile/Siri/AddedtoSiri.svg')
										: require('../ResourceTile/Siri/AddtoSiri.svg')
								}
								className={css(styles.siriButton)}
								alt=""
							/>
						</>
					}
				</div>
			</label>
		);
	}

	private onChange = () => {
		const resolver = environment.configureSiri(this.props);
		resolver
			.catch(() => {})
			.then(result => {
				this.props.resourceContext.setSiriRegistrationStatus(
					this.props.id,
					result === 'registered_to_siri'
				);
				this.setState({
					isAdded: result === 'registered_to_siri',
				});
			});
	};
}

export const Siri = withResourceContext(_SIRI);
