import { StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	page: {
		background: palette.grey100,
		position: 'absolute',
		minHeight: 'calc(100% - 10px)',
		height: 'calc(100% - 10px)',
		minWidth: '100%',
		display: 'table',
		top: '10px',
	},
	innerPage: {
		display: 'table-cell',
		verticalAlign: 'middle',
	},
	content: {
		margin: 'auto',
		maxWidth: '560px',
	},
	container: {
		boxShadow: `0 2px 4px ${palette.grey300}`,
		border: `1px solid ${palette.grey300}`,
	},
	logoContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	logo: {
		height: '60px',
		maxWidth: '100%',
		padding: '20px 0',
		display: 'flex',
	},
	whiteBox: {
		padding: '40px',
		background: '#fff',
	},
	centered: {
		textAlign: 'center',
	},
	accentBar: {
		position: 'absolute',
		top: '0',
		height: '10px',
		width: '100%',
	},
	detailText: {
		margin: '10px 0',
	},
	footerImage: {
		display: 'block',
		margin: '0 auto',
		paddingTop: '40px',
		height: '25px',
		width: '68px',
	},
});
