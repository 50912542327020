import * as React from 'react';
import { css } from 'aphrodite';
import { Button } from '@citrite/web-ui-component';
import { styles } from './PrimaryButton.styles';

export interface PrimaryButtonProps {
	onClick: () => void;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({ children, onClick }) => {
	return (
		<Button.Wrapper onClick={onClick} className={css(styles.itemSelection)}>
			<div className={css(styles.flexContainer)}>{children}</div>
		</Button.Wrapper>
	);
};
