import { typography } from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardDivStyle = css(`
	padding: 12px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 398px;
`);
export const StyledAlertContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
export const StyledAlertSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 18px;
`;
export const StyledAlertAction = styled.div`
	display: flex;
	align-items: center;
`;
export const StyledStatus = styled.div`
	display: flex;
	flex-direction: row;
`;
export const StyledStatusText = styled.span`
	font-size: ${typography.sizes.sml};
	line-height: 16px;
	font-weight: ${typography.weights.regular};
`;
