import * as React from 'react';
import { LaunchResourceOptions } from 'Environment';
import { SendLogoffMessage } from 'Environment/ChromeApp/ChromeAppBridge';
import { AuthenticationTimerOperationType } from 'Environment/ChromeApp/types';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export interface ChromeAppConfigurationState {
	isActive: boolean;
	allowAccessToOfflineResources: boolean;
	useAuthenticationTimer: boolean;
}

export interface ChromeAppContext {
	isSupportedCustomerRuntime?: boolean;

	getChromeAppConfiguration(): Promise<ChromeAppConfigurationState>;

	shouldChromeAppLaunchResource(resource: Resource): Promise<boolean>;

	noteLogout(params: SendLogoffMessage): Promise<void>;

	launch(launchResourceOptions: LaunchResourceOptions): Promise<any>;

	notifyAuthenticationTimer(operation: AuthenticationTimerOperationType): Promise<void>;
}

export const defaultContext: ChromeAppContext = {
	isSupportedCustomerRuntime: false,
	getChromeAppConfiguration: () =>
		Promise.resolve({
			isActive: false,
			allowAccessToOfflineResources: false,
			useAuthenticationTimer: false,
		}),
	shouldChromeAppLaunchResource: () => Promise.resolve(false),
	launch: () =>
		Promise.reject(new Error('Use of chrome app context is improperly gated')),
	noteLogout: () => Promise.resolve(),
	notifyAuthenticationTimer: () => Promise.resolve(),
};
export const ResiliencyChromeAppContext =
	React.createContext<ChromeAppContext>(defaultContext);
