import * as React from 'react';
import { t } from '@citrite/translate';
import { NativeWidgets } from 'App/NativeWidget/NativeWidgets';
import { HomePage, homePageRouteResolver } from 'Router/homePageRoute';
import { createLeftNavRoute, RoutedComponentProps } from 'Router/ReactRoute';

type Props = RoutedComponentProps;

function Widgets(props: Props) {
	return <NativeWidgets {...props} />;
}

export const nativeWidgetHomePath = '/home/nativeWidget';

export const NativeWidgetRoute = createLeftNavRoute({
	component: Widgets,
	path: nativeWidgetHomePath,
	isAvailable: props =>
		homePageRouteResolver(props.userContext, props.workspaceConfiguration) ===
		HomePage.NativeWidget,
	isLoading: props => props.resources.loading,
	getNavLinkProps: () => ({
		title: t('Workspace:home'),
	}),
});
