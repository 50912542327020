import {
	nativeWorkspaceScheme,
	urlSafeBase64Encode,
} from 'Environment/launchResource/launchUtil';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export function launchSaasAppUsingNative(resource: Resource) {
	const action = 'saasLaunch';
	const transport = location.origin.indexOf('https') === 0 ? 'https' : 'http';
	const paramBlock =
		`action=${encodeURIComponent(action)}` +
		`&transport=${encodeURIComponent(transport)}` +
		`&resourceId=${encodeURIComponent(resource.id ?? '')}`;

	const protocolHandlerUrl =
		`${nativeWorkspaceScheme}//` +
		`${location.host}` +
		'/' +
		`${urlSafeBase64Encode(paramBlock)}`;

	launchUrlInIframe(protocolHandlerUrl);
}

let iframe: HTMLIFrameElement;
function launchUrlInIframe(url: string) {
	if (!iframe) {
		iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
	}
	iframe.src = url;
}
