const workspaceDomainList = [
	'.cloud.com',
	'.citrixcloud.jp',
	'.cloud.us',
	'.cloudburrito.com',
	'.citrixcloudstaging.jp',
	'.cloudstaging.us',
	'.local',
	'localhost',
];

declare var IS_ON_PREM: boolean;
/**
 * Check whether workspace is accessed via vanity domain
 * Todo: change logic to detect vanity domain specific header when it is ready: https://issues.citrite.net/browse/CC-34907
 */
export function isVanityDomain() {
	const hostName = window?.location?.hostname || '';
	if (!hostName || IS_ON_PREM) {
		return false;
	}
	const hostNameLowerCase = hostName.toLowerCase();
	return !workspaceDomainList.some(domain => hostNameLowerCase.endsWith(domain));
}
