import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { supportsIcaLaunch } from './IcaLaunch';

export function deviceSupportsResource(resource: Resource, clientType: string) {
	if (resource.clienttypes && resource.clienttypes.some(ct => ct === clientType)) {
		return true;
	}
	return supportsIcaLaunch(resource) || !!resource.content;
}

export function isMobileApp(resource: Resource) {
	return (
		resource.clienttypes && resource.clienttypes.some(ct => ct.startsWith('application/'))
	);
}

export function isWebApp(resource: Resource) {
	return (
		resource.clienttypes &&
		!isMobileApp(resource) &&
		resource.clienttypes.some(ct => ct.startsWith('content'))
	);
}

export function isDesktopApp(resource: Resource) {
	return (
		resource.clienttypes &&
		resource.clienttypes.some(ct => ct.startsWith('ica30') || ct.startsWith('rdp'))
	);
}
