import React, { useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import { typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { appRoutes } from 'App/appRoutes';
import { composableHomeRoute } from 'App/ComposableHome';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useUserContext } from 'Workspace/UserContext';

const StyledTitle = styled.h3`
	white-space: nowrap;
	font-size: ${typography.sizes.xl};
	font-weight: ${typography.weights.semiBold};
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const SmallFormTitle = () => {
	const [title, setTitle] = useState<string>('');
	const { workspaceConfiguration } = useConfigurationContext();
	const { userDetails } = useUserContext();
	const location = useLocation();
	const showUsername = useFeatureCanary(FeatureFlag.EnableUserDisplayName);

	useEffect(() => {
		const username =
			showUsername && userDetails?.isValidUserDisplayName && userDetails?.userDisplayName
				? `, ${userDetails.userDisplayName}`
				: '';
		const route = appRoutes.find(
			routeDetails =>
				location.pathname.startsWith(routeDetails.key) &&
				routeDetails.key !== composableHomeRoute.key
		);
		const defaultTitle = `${t('Workspace:greeting.hello')}${username}`;
		const routeTitle = route?.getNavLinkProps?.(workspaceConfiguration)?.title;

		setTitle(routeTitle || defaultTitle);
	}, [location.pathname, showUsername, userDetails, workspaceConfiguration]);

	return <StyledTitle data-testid="application-title">{title}</StyledTitle>;
};
