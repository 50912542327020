import { createCapability } from '../capability';

/**
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.logout}
 *
 * @category Integration Capability
 */
export interface AppStartupCapability {
	/** Called once when the application first loads. */
	onAppStart: () => void;
}

export const AppStartupCapabilityToken = createCapability<AppStartupCapability>(
	'wsui_app_startup_capability'
);
