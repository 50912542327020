import _, { cloneDeep } from 'lodash';
import { ActivityResource } from 'App/Activity/CommonResourceUtils';
import { sortSessionsByTypeAndTimestamp } from 'App/Activity/components/Utility';
import { getIntegrationType, IntegrationType } from 'App/Activity/ResourceActionHandler';
import {
	LocalSession,
	Session,
	SessionConnectionState,
	SessionType,
} from 'App/Activity/ResourceManagerContext';
import { environment } from 'Environment';
import { getCurrentPlatform } from 'Environment/launchResource/device';
import { ActivityCardResourceList } from 'Workspace/ResourceProvider/resourceTypes';

export const fetchAndUpdateNativeSessions = async (): Promise<Session[]> => {
	if (getIntegrationType() === IntegrationType.EXTENDED) {
		const { resources: nativeSessions }: ActivityCardResourceList =
			await environment.fetchActivityList();
		return updatedNativeSessions(nativeSessions);
	} else {
		return [];
	}
};

const canFetchNativeIcon = (session: LocalSession) =>
	session.supportedOperations.includes('fetchicon');

const getIconUrl = (session: LocalSession): string =>
	canFetchNativeIcon(session) ? `activityManager:${session.id}` : '';

const createSessionObject = (session: ActivityResource): Session => ({
	localSession: session as LocalSession,
	deviceId: environment.nativeCapabilities?.platform?.deviceId,
	deviceType: getCurrentPlatform(),
	userSessionType: session.isAppContainer ? SessionType.APPLICATION : SessionType.DESKTOP,
	sessionId: session.id,
	connectionState: SessionConnectionState.CONNECTED,
	sessionStartTime: session.startTimeStamp,
	machineData: { machineName: session.name },
	applications: session.isAppContainer
		? []
		: [
				{
					id: session.id,
					name: session.name,
					iconUrl: getIconUrl(session as LocalSession),
				},
		  ],
});

const parentSessionsKey = 'parent-session-key';

const groupSessionsByParentId = (sessions: ActivityResource[]) =>
	_.groupBy(sessions, session => session.parentId || parentSessionsKey);

const filterDesktopSession = (parentSessions: Session[]) =>
	parentSessions.filter(session => session.userSessionType === SessionType.DESKTOP);

const captureApplicationSessions = (
	sessionsGroup: { [parentKey: string]: ActivityResource[] },
	parentSessions: Session[],
	localSessions: Session[],
	capturedSession: Session[]
) => {
	Object.entries(sessionsGroup).forEach(([parentId, sessionsArray]) => {
		if (parentId !== parentSessionsKey) {
			captureApplicationSessionWithUpdatingLocalApplications(
				parentId,
				[...parentSessions, ...localSessions],
				sessionsArray,
				capturedSession
			);
		}
	});
};

const captureApplicationSessionWithUpdatingLocalApplications = (
	parentId: string,
	parentSessions: Session[],
	localApplications: ActivityResource[],
	capturedSessions: Session[]
) => {
	const parentSession = parentSessions.find(({ sessionId }) => sessionId === parentId);
	if (parentSession) {
		const clonedParentSession = cloneDeep(parentSession);
		localApplications.forEach(session =>
			clonedParentSession.applications.push({
				id: session.id,
				name: session.name,
				iconUrl: getIconUrl(session as LocalSession),
				localSession: session as LocalSession,
			})
		);
		capturedSessions.push(clonedParentSession);
	}
};
/**
 * There is difference in structure of response from native and local sessions
 * and we are trying to unify this structure here
 */
export const updatedNativeSessions = (
	sessions: ActivityResource[],
	localSessions: Session[] = []
): Session[] => {
	const sessionsGroup = groupSessionsByParentId(sessions);

	const parentSessions: Session[] =
		sessionsGroup[parentSessionsKey]?.map(createSessionObject) || [];

	const updatedSessions: Session[] = filterDesktopSession(parentSessions);

	captureApplicationSessions(
		sessionsGroup,
		parentSessions,
		localSessions,
		updatedSessions
	);

	return sortSessionsByTypeAndTimestamp(updatedSessions);
};
