import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	breakpoints,
	fromThemeColors,
	layout,
	MainRegion,
	palette,
	styleUtils,
} from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { bottomNavBarHeight } from 'App/BottomNav/dimensions';
import { isSmallForm } from 'App/Header/useFormFactor';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { fromThemeOffset } from 'Workspace/ThemeProvider';

const bottomMargin = isSmallForm() ? bottomNavBarHeight : '0px';
const maxViewWidth = '1920px';
const citrixBrandingHeight = '60px';
const containerPaddingHeight = `(2 * ${layout.mediumLargeSpace})`;

export const StyledScrollable = styled.div<{
	clientHeight: string;
}>`
	/*
		Set this element to not manage scrolling for clients that don't
		support sticky elements (IE11) so that the content area can be set
		to manage scrolling with the nav staying in place
	*/
	overflow-y: hidden;
	@supports (position: sticky) {
		overflow-y: auto;
	}
	${props =>
		`height: calc(${props.clientHeight} - ${props.theme.mastheadOffset.mastheadOffset}px - ${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	margin-top: ${props => `${props.theme.mastheadOffset.mastheadOffset}px`};
	${breakpoints.onMobile} {
		${props =>
			`height: calc(${props.clientHeight} - ${props.theme.mastheadOffset.mastheadOffsetMobile}px)`};
		margin-bottom: ${bottomMargin};
		margin-top: ${props => `${props.theme.mastheadOffset.mastheadOffsetMobile}px`};
		overflow-y: hidden;
	}
`;

export const StyledApplication = styled.div<{
	daasVisionEnabled: boolean;
	isOffWhiteBackground?: boolean;
}>`
	display: flex;
	justify-content: center;
	${props =>
		props.daasVisionEnabled ? 'flex-direction: column;' : 'flex-direction: row;'}
	background-color: ${props =>
		props.isOffWhiteBackground
			? palette.grey100
			: getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? fromThemeColors('primaryAppBackground')(props)
			: fromThemeColors('primaryContainerBackground')(props)};
`;

export const StyledAppBody = styled.div<{
	clientHeight: string;
	offsetTop: number;
	isSmallFormFactor: boolean;
}>`
	align-items: flex-start;
	display: flex;
	flex-grow: 1;
	min-width: 200px;
	background-color: ${props =>
		props.isSmallFormFactor && fromThemeColors('primaryContainerBackground')(props)};
	${() =>
		getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
			maxWidth: maxViewWidth,
			// IE11 needs a width to make sense of centered content
			width: '100vw',
		}};
	${breakpoints.onMobile} {
		/*
			Allowing this content to scroll on iOS hides the
			hamburger menu for the left nav
		*/
		overflow-y: initial;
		${props =>
			`height: calc(${props.clientHeight} - ${props.offsetTop}px - ${bottomMargin} - 
				${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	}
`;

export const StyledMasthead = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
`;

export const StyledMastheadSearchBar = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledMastheadUserMenu = styled.div`
	display: flex;
	margin-right: ${layout.largeSpace};
	align-items: center;
	${breakpoints.onMobile} {
		margin-right: 20px;
	}
`;

export const StyledPrimaryNavigation = styled.nav<{
	hasTabs?: boolean;
	clientHeight: string;
}>`
	${props =>
		getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? {
					backgroundColor: `${fromThemeColors('primaryAppBackground')(props)}`,
			  }
			: {
					backgroundColor: `${fromThemeColors('primaryContainerBackground')(props)}`,
					borderRight: `1px solid ${fromThemeColors('border')(props)}`,
			  }}
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	outline: none;
	transition: 0.5s ease-in-out;
	white-space: nowrap;
	z-index: 2;
	${props =>
		`height: calc(${props.clientHeight} - ${fromThemeOffset('mastheadOffset')(
			props
		)}px - ${fromThemeOffset('bottomBannerOffset')(props)}px)`};
	:focus {
		${styleUtils.focusInset}
	}
	${breakpoints.onMobile} {
		${props => `height: calc(100% - ${fromThemeOffset('bottomBannerOffset')(props)}px)`};
		${props => (props.hasTabs ? 'padding-bottom: 50px' : '')};
		${props =>
			getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
				backgroundColor: `${palette.white}`,
				borderRight: `1px solid ${fromThemeColors('border')(props)}`,
			}}
	}
`;

export const StyledAppContent = styled(MainRegion, { shouldForwardProp: isPropValid })<{
	clientHeight: string;
}>`
	background-color: inherit;
	flex-grow: 1;
	max-width: 100%;
	/* 
	Set overflow and fixed height for IE11 so that it will 
	scroll this content area while allowing for the left 
	nav to stick in place. This causes the nav to remain 
	alongside the content, even when it's centered
	in an ultrawide view.
	*/
	overflow-y: auto;
	${props =>
		`height: calc(${props.clientHeight} - ${props.theme.mastheadOffset.mastheadOffset}px - ${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	/* 
	Set back to default overflow and min-height for 
	sticky-supporting clients.
	Scrolling will be handled by StyledAppBody, which
	allows the scrollbar to be rendered out to the side 
	of the viewport to make it look better at ultra-wide.
	*/
	@supports (position: sticky) {
		height: 100%;
		overflow-y: visible;
		${props =>
			`min-height: calc(${props.clientHeight} - ${props.theme.mastheadOffset.mastheadOffset}px - ${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	}
	${breakpoints.onMobile} {
		overflow-x: hidden;
		width: 100%;
	}
`;

export const StyledSecondaryContent = styled.div<{
	clientHeight: string;
	isDaasVisionEnabled?: boolean;
}>`
	padding: 20px;
	box-sizing: border-box;
	width: inherit;
	background: ${fromThemeColors('secondaryAppBackground')};
	${
		({ clientHeight, isDaasVisionEnabled = false, theme }) =>
			isDaasVisionEnabled
				? `min-height: calc(
                ${clientHeight} - ${theme.mastheadOffset.mastheadOffset}px -
                ${theme.mastheadOffset.bottomBannerOffset}px - ${containerPaddingHeight} - ${citrixBrandingHeight} - ${layout.mediumLargeSpace} - ${layout.mediumLargeSpace});`
				: `height: calc(
                ${clientHeight} - ${theme.mastheadOffset.mastheadOffset}px -
                ${theme.mastheadOffset.bottomBannerOffset}px);`
		/**
		 * fixes the overflow bug in the settings screen
		 * and accomodates the Citrix Branding component for the DaasVision UI
		 * while not messing with the CSS of the Old UI
		 */
	};
`;
