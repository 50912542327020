import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { createLeftNavRoute } from 'Router/ReactRoute';

const component = makeAsync(() =>
	import('./SearchComponent').then(m => m.SearchComponent)
);

export const searchRoute = createLeftNavRoute({
	component,
	path: '/search',
	isLoading: props => props.resources.loading,
	pathParams: {
		search: true,
		searchFilterOption: true,
	},
});
