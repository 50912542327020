// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./fonts.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n\tbackground-position: top left;\n\tbackground-attachment: fixed;\n\tmargin: 0;\n\t-webkit-tap-highlight-color: rgba(0, 0, 0, 0.08);\n}\n\np {\n\tmargin: 10px 0;\n}\n\npre {\n\toverflow-x: auto;\n\tpadding: 10px;\n\twhite-space: pre-wrap;\n\tword-wrap: break-word;\n}\n\n@media print {\n\th1 {\n\t\tdisplay: none;\n\t}\n\thtml {\n\t\tbackground: none !important;\n\t\toverflow: visible !important;\n\t\tposition: static !important;\n\t}\n\tbody {\n\t\twidth: auto !important;\n\t\toverflow: visible !important;\n\t\tposition: static !important;\n\t\tbackground: none !important;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./css/styles.css"],"names":[],"mappings":"AACA;CACC,6BAA6B;CAC7B,4BAA4B;CAC5B,SAAS;CACT,gDAAgD;AACjD;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,aAAa;CACb,qBAAqB;CACrB,qBAAqB;AACtB;;AAEA;CACC;EACC,aAAa;CACd;CACA;EACC,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;CAC5B;CACA;EACC,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,2BAA2B;CAC5B;AACD","sourcesContent":["@import 'fonts.css';\nbody {\n\tbackground-position: top left;\n\tbackground-attachment: fixed;\n\tmargin: 0;\n\t-webkit-tap-highlight-color: rgba(0, 0, 0, 0.08);\n}\n\np {\n\tmargin: 10px 0;\n}\n\npre {\n\toverflow-x: auto;\n\tpadding: 10px;\n\twhite-space: pre-wrap;\n\tword-wrap: break-word;\n}\n\n@media print {\n\th1 {\n\t\tdisplay: none;\n\t}\n\thtml {\n\t\tbackground: none !important;\n\t\toverflow: visible !important;\n\t\tposition: static !important;\n\t}\n\tbody {\n\t\twidth: auto !important;\n\t\toverflow: visible !important;\n\t\tposition: static !important;\n\t\tbackground: none !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
