import URI from 'urijs';

export function getUrlParameter(name: string) {
	for (let search of [location.search, location.hash]) {
		if (!search) {
			continue;
		}

		while (['?', '#', '/'].includes(search[0])) {
			search = search.slice(1);
		}

		const value = new URI('?' + search).query(true)[name];
		if (value) {
			return value;
		}
	}
}
