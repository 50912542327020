import { t } from '@citrite/translate';
import { SettingsLeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { createLeftNavRoute } from 'Router/ReactRoute';

const component = makeAsync(() => import('./EditProfile').then(m => m.EditProfile));

export const editProfileRoute = createLeftNavRoute({
	component,
	path: '/settings/editprofile',
	getNavLinkProps: () => ({
		title: t('javascript:personal_settings.left_nav.edit_profile'),
		weight: SettingsLeftNavPositions.editProfile,
	}),
});
