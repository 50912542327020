import { Nullable } from '@citrite/utility-types';
import {
	EncryptedCacheBucket,
	UnencryptedCacheBucket,
} from '@citrite/workspace-ui-platform';
import { FeatureRolloutFeatures, logFeatureRolloutError } from 'FeatureRolloutService';

/**
 * Bugfix WSUI-7682: Migrating from encrypted to unencrypted cache for storing the Feature Rollout Service flags
 * as the encrypted cache can't be accessed in offline mode.
 * This method fetches the value from multiple sources - encrypted cache, unencrypted cache, and the resolver.
 * Unencrypted cache is given the priority over the encrypted one.
 * The cached value is returned immediately if present, otherwise the resolver is awaited, and
 * its value cached and returned.
 *
 * @remarks The resolver function is _always_ invoked, even for a cache hit.
 * The result is used to update the unencrypted cache in the background for constant
 * freshness. The fundamental tradeoff of client caching with this approach
 * is that data presented to the UI will likely always be one version behind.
 */

type FeatureFlags = FeatureRolloutFeatures[];
export const fetchFeatureFlagsCacheFirst = async (
	cacheKey: string,
	encryptedCache: EncryptedCacheBucket,
	unencryptedCache: UnencryptedCacheBucket,
	resolver: (cancelUpdateCallback: () => Nullable<FeatureFlags>) => Promise<FeatureFlags>
): Promise<FeatureFlags> => {
	let persistUpdate = true;
	const cancelUpdateCallback = (): Nullable<FeatureFlags> => {
		persistUpdate = false;
		return null;
	};

	const pendingResolver = resolver(cancelUpdateCallback);
	pendingResolver.then(value => {
		// FeatureRolloutService can return an empty value when there aren't any features enabled.
		// We can also get an empty value if the resolver throws an exception.
		// To distinguish these scenarios, we rely on an explicit 'persistUpdate' state rather than an empty array check.
		if (persistUpdate) {
			unencryptedCache.setUnencrypted(cacheKey, value);
		}
	});

	const cachedValue: Nullable<FeatureFlags> = await getFeatureFlagsFromCache(
		cacheKey,
		unencryptedCache,
		encryptedCache
	);
	return cachedValue ?? (await pendingResolver);
};

const getFeatureFlagsFromCache = async (
	cacheKey: string,
	unencryptedCache: UnencryptedCacheBucket,
	encryptedCache: EncryptedCacheBucket
): Promise<Nullable<FeatureFlags>> => {
	try {
		return (
			unencryptedCache.getUnencrypted<FeatureFlags>(cacheKey) ||
			(await encryptedCache.getEncrypted<FeatureFlags>(cacheKey))
		);
	} catch (error: unknown) {
		handleCacheError(error);
	}
	return null;
};

const handleCacheError = (error: unknown): void => {
	let message = 'Fetching feature flags from cache failed';
	if (error instanceof Error) {
		message = `${error.message} - ${message}`;
	}
	logFeatureRolloutError(new Error(message));
};
