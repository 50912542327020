import { RecentItems } from 'Environment';
import { getRecentResources } from 'Environment/recents/recents';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export function getRecentAppsAndDesktopsForNative(resources: Resource[]): RecentItems[] {
	if (!resources) {
		return [];
	}
	const recentResources = getRecentResources(resources);

	return recentResources.map(({ id, name, iconurl, isdesktop }) => ({
		category: isdesktop ? 'desktops' : 'apps',
		id,
		name,
		iconUrl: iconurl,
	}));
}
