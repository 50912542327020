import * as React from 'react';
import { SkeletonLoaderShapes } from '@citrite/workspace-ui-platform-react';

const { Circle } = SkeletonLoaderShapes;
export function UserMenuSkeleton() {
	return (
		<div>
			<div>
				<Circle diameter={40} />
			</div>
		</div>
	);
}
