import * as React from 'react';
import { Button, ButtonProps } from '@citrite/web-ui-component';
import { isNativeMobileOrTabletClient } from 'Environment/launchResource/device';

export const AdaptiveButton = {
	TextOrSubtleButton,
};

function TextOrSubtleButton(props: ButtonProps) {
	return isNativeMobileOrTabletClient() ? (
		<Button.SubtleSecondary {...props}>{props.children}</Button.SubtleSecondary>
	) : (
		<Button.TextSecondary {...props}>{props.children}</Button.TextSecondary>
	);
}
