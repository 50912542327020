import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		minWidth: 0,
	},

	disabledRow: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
});
