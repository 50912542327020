import * as React from 'react';
import { StyledPlatformLoadingOverlay } from './PlatformLoadingOverlay.styled';

/**
 * A platform-supplied loading overlay that uses preloaded
 * CSS to present a spinning loader icon. (provides
 * a slight performance/speed advantage compared to
 * LoadingOverlayPrimary in web-ui-component where the package must
 * be loaded)
 */
export function LoadingOverlayPrimary() {
	return (
		<StyledPlatformLoadingOverlay>
			<div className="loading-spinner" />
		</StyledPlatformLoadingOverlay>
	);
}

/**
 * A platform-supplied loading overlay that uses preloaded
 * CSS to present a spinning loader icon. (provides
 * a slight performance/speed advantage compared to
 * LoadingOverlaySecondary in web-ui-component where the package must
 * be loaded)
 */
export function LoadingOverlaySecondary() {
	return (
		<StyledPlatformLoadingOverlay secondary>
			<div className="loading-spinner" />
		</StyledPlatformLoadingOverlay>
	);
}
