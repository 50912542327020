import {
	// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
	workspaceConfiguration,
	workspaceUser,
} from '@citrite/workspace-ui-platform';
import { traceManager } from 'Tracing';
import { createCasPublisher } from 'Workspace/CAS/createCasPublisher';
import {
	TelemetryEvent,
	TelemetryEventPublisher,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export type Metadata = {
	[key: string]: string | number | boolean;
};

export interface AnalyticsEvent extends TelemetryEvent {
	[key: string]: string | number | boolean;
}

const component = 'WSUI';

const getGenericMetadata = () => ({
	time: new Date().toISOString(),
	component,
});

const trackPendoEvent = (trackType: string, metadata?: Metadata) => {
	try {
		window.pendo?.track(trackType, metadata);
	} catch {}
};

/**
 * @deprecated use 'trackAnalyticsEvent' instead
 * @param trackType
 * @param metadata
 */
export const trackEvent = (trackType: string, metadata?: Metadata) => {
	trackAnalyticsEvent({
		type: trackType,
		...metadata,
	});
};

export function trackPageLoad() {
	try {
		window.pendo?.pageLoad();
	} catch {}
}

let casEventPublisher: TelemetryEventPublisher;

const createCasEventPublisher = () => {
	const workspaceConfig = workspaceConfiguration.get();
	const userDetails = workspaceUser.get().userDetails;
	if (workspaceConfig && userDetails) {
		casEventPublisher = createCasPublisher(workspaceConfig, userDetails);
	}
	return casEventPublisher;
};

const publishCasEvent = (event: AnalyticsEvent) => {
	const { publishEvent } = casEventPublisher || createCasEventPublisher() || {};
	if (publishEvent) {
		publishEvent(event);
	}
};

export const trackAnalyticsEvent = (event: AnalyticsEvent) => {
	const extendedEvent: AnalyticsEvent = { ...event, ...getGenericMetadata() };

	// This we will capture to split the daas screen insights - would remove after complete rollout of daas vision feature
	if (workspaceUser.get().isDaasVisionEnabled) {
		extendedEvent['daasVisionEnabled'] = true;
	}

	const { type, ...justMetadata } = extendedEvent;
	trackPendoEvent(type, justMetadata);
	publishCasEvent(extendedEvent);
	traceManager.logAnalytics(extendedEvent);
};
