import { ClientInstance } from '@citrite/http';
import {
	EncryptedCacheBucket,
	EndpointSupplier,
	UnencryptedCacheBucket,
} from '@citrite/workspace-ui-platform';
import URI from 'urijs';
import { fetchFeatureFlagsCacheFirst } from 'FeatureRolloutService';
import { logFeatureRolloutError } from 'FeatureRolloutService/logFeatureRolloutError';

export enum FeatureRolloutFeatures {
	IWS = 'iws',
	DaasUI = 'daasui',
	ActivityManager = 'activityManager',
}

export interface FeatureResponse {
	items: FeatureRolloutFeatures[];
}

export interface FeatureRolloutClient {
	fetchFeatures(): Promise<FeatureRolloutFeatures[]>;
	fetchFeaturesCacheFirst(): Promise<FeatureRolloutFeatures[]>;
}

export const FRS_CACHE_KEY = 'features';

export const createFeatureRolloutClient = (
	endpointSupplier: EndpointSupplier,
	ssoProxyClient: ClientInstance,
	encryptedLegacyCache: EncryptedCacheBucket,
	unencryptedCache: UnencryptedCacheBucket,
	customerId: string,
	storeGuid: string,
	useFeedDefaultOn: boolean
): FeatureRolloutClient => {
	const fetchFeatures = async () => {
		const endpoint = await endpointSupplier.getEndpoint('FeatureRolloutService');
		const endpointUri = new URI(endpoint);

		if (useFeedDefaultOn) {
			endpointUri.addQuery({ feedDefaultOn: true });
		}

		// Temporary cache buster for IE until WSP-6099 is fixed
		endpointUri.addQuery({
			__: Date.now(),
		});

		const featureResponse = await ssoProxyClient.get<FeatureResponse>(
			endpointUri.href(),
			{
				headers: {
					'Citrix-InstanceId': storeGuid,
					'Citrix-CustomerId': customerId,
				},
			}
		);

		return featureResponse.items;
	};

	const fetchFeaturesCacheFirst = async () => {
		return fetchFeatureFlagsCacheFirst(
			FRS_CACHE_KEY,
			encryptedLegacyCache,
			unencryptedCache,
			async cancel => {
				try {
					return await fetchFeatures();
				} catch (e) {
					logFeatureRolloutError(e);
					cancel();
				}
				return [];
			}
		);
	};

	return { fetchFeatures, fetchFeaturesCacheFirst };
};
