import { AnalyticsEvent, Metadata } from 'analytics';
import { MandateNativeAppEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
const code = 'WSUI_MANDATE_NATIVE_APP';

const preparePayload = (
	type: MandateNativeAppEventType,
	metadata?: Metadata
): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});
const createMandateNativeAppAnalyticsReporter = () => {
	return {
		getMandateNativeAppOpenCitrixWorkspaceClickedEvent: (): AnalyticsEvent =>
			preparePayload(MandateNativeAppEventType.OpenCitrixWorkspaceClicked),
		getMandateNativeAppDownloadWorkspaceLinkClickedEvent: (): AnalyticsEvent =>
			preparePayload(MandateNativeAppEventType.DownloadWorkspaceLinkClicked),
		getMandateNativeAppStoreUrlCopiedEvent: (): AnalyticsEvent =>
			preparePayload(MandateNativeAppEventType.StoreUrlCopied),
		getMandateNativeAppScreenLoadedEvent: (): AnalyticsEvent =>
			preparePayload(MandateNativeAppEventType.ScreenLoaded),
		getMandateNativeAppUserGuideOpenedEvent: (): AnalyticsEvent =>
			preparePayload(MandateNativeAppEventType.UserGuideOpened),
	};
};

export const mandateNativeAppAnalyticsReporter =
	createMandateNativeAppAnalyticsReporter();
