import * as React from 'react';
import { Button } from '@citrite/web-ui-component';
import { BackoffStrategy, resilientPromise } from '@citrite/workspace-ui-platform';
import { t } from 'i18next';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { DaasNotificationBanner } from 'Components/AppLayout/DaasNotificationBanner';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { isNativeWidget } from 'Environment/launchResource/device';
import { reconnect } from 'Environment/reconnect';
import { useUserContext } from 'Workspace/UserContext';
import { MastheadInformationalBanner } from './MastheadInformationalBanner';
import { shouldRetryServerConnection } from './RetryServerConnection';

const secondInMillis = 1000;
const minute = 60 * secondInMillis;
export const OfflineBanner = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const isDaasVisionEnabled = useUserContext().isDaasVisionEnabled;

	React.useEffect(() => {
		const timeout = setTimeout(
			() =>
				resilientPromise(
					() =>
						shouldRetryServerConnection(
							workspaceConfiguration.storeProxy.validateConnectionURL
						),
					{
						keepRetrying: true,
						maxDelayMS: 30 * minute,
						delayMS: 5 * minute,
						backoffStrategy: BackoffStrategy.LINEAR,
					}
				),
			5 * minute
		);
		return () => timeout && clearTimeout(timeout);
	}, [workspaceConfiguration.storeProxy.validateConnectionURL]);

	const Content = () => (
		<span>
			{t('Workspace:cloud_offline_message')}
			<Button.TextPrimary onClick={() => reconnect('OfflineBanner')}>
				{t('Workspace:cloud_offline_reconnect')}
			</Button.TextPrimary>
		</span>
	);

	return isDaasVisionEnabled && !isNativeWidget() ? (
		<DaasNotificationBanner
			Content={Content}
			bannerPosition={BannerPosition.Top}
			textAlign="center"
		/>
	) : (
		<MastheadInformationalBanner Content={Content} />
	);
};
