type ExtraContext = { [key: string]: any };
type Tags = { [key: string]: string };

export enum Severity {
	Error = 'error',
	Info = 'info',
}

export interface Breadcrumb {
	type?: string;
	level?: Severity;
	event_id?: string;
	category?: string;
	message?: string;
	data?: {
		[key: string]: any;
	};
	timestamp?: number;
}

export interface LogErrorOptions {
	tags?: Tags;
	additionalContext?: ExtraContext;
	customMessage?: string;
}

export interface ClientLogger {
	/**
	 * Sends an info event to a Workspace front-end monitoring service. The service may vary;
	 * contact maintainers for access and details.
	 *
	 * @param message The message to send.
	 * @param additionalContext Additional metadata for the event.
	 */
	logInfo(message: string, additionalContext?: ExtraContext): void;
	/**
	 * Sends an error event to a Workspace front-end monitoring service. The service may vary;
	 * contact maintainers for access and details.
	 *
	 * @param payload The error to log. There is first-class support for Error instances, failed HTTP responses, and strings. Other objects are best-effort.
	 * @param options Slots for additional metadata on the event.
	 */
	logError(payload: any, options?: LogErrorOptions): void;
	/**
	 * Records an interaction event that is supplied with any following errors.
	 * May not be implemented by all logging providers.
	 */
	addBreadcrumb(breadcrumb: Breadcrumb): void;
	/**
	 * Configures the logger instance with additional tags that are applied to all events sent by it.
	 * Use this to add feature-specific context to all events sent by your integration.
	 */
	setLoggingTags(tags: Tags): void;
}
