import * as React from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const emotionCache = createCache({
	key: 'wsui',
	prepend: true,
});

export const EmotionCache: React.FC = props => {
	return <CacheProvider value={emotionCache}>{props.children}</CacheProvider>;
};
