import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	Button,
	CheckIcon,
	Dropdown,
	DropdownItem,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { NativeAccount, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { FormFactor, useFormFactor } from 'App/Header/useFormFactor';
import { bannerContentHeight } from 'App/Header/util';
import { LocalAppManageModal } from 'App/LocalSystemApps';
import { settingsRoute } from 'App/Settings';
import { shouldShowAccountSettings } from 'App/Settings/SettingsUtils';
import { WorkspaceAvatar } from 'Components/Avatar/WorkspaceAvatar';
import { performance } from 'Components/performance';
import { AccountBranding } from 'Configuration/branding';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useBranding } from 'Configuration/withBranding';
import { environment } from 'Environment';
import { refreshApp } from 'Environment/callbacks';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { isNativeMobileOrTabletClient } from 'Environment/launchResource/device';
import { UserContext, useUserContext } from 'Workspace/UserContext';
import { styles } from './UserMenu.styles';
import { UserMenuSkeleton } from './UserMenuSkeleton';
import { useWorkspaceAccounts } from './useWorkspaceAccounts';

export type UserMenuLoaderProps = WithModalsProps;

interface InternalProps extends UserMenuLoaderProps {
	userContext: UserContext;
	history: History;
	branding: AccountBranding;
	accounts: NativeAccount[];
	workspaceConfiguration?: WorkspaceConfiguration;
	formFactor: FormFactor;
}

class _UserMenu extends React.Component<InternalProps> {
	public state = { isDropdownOpen: false };

	private getDropdownOptions(): DropdownItem[] {
		return [
			this.getAccountSettingsOption(),
			this.getApplicationSettingsOption(),
			this.getRefreshOption(),
			this.getAboutOption(),
			this.getShowAccountsOption(),
			this.manageLocalAppOption(),
			this.getSwitchAccountsOption(),
			this.getLogoutOption(),
		].filter(o => !!o);
	}

	private getRefreshOption(): DropdownItem {
		if (environment.menuPreferences.refresh) {
			return {
				onClick: () => refreshApp(),
				children: t('Workspace:refresh'),
			};
		}

		return undefined;
	}

	private getAboutOption(): DropdownItem {
		if (environment.menuPreferences.about) {
			return {
				onClick: (e: React.MouseEvent<HTMLLIElement>) =>
					environment.aboutBox(e.currentTarget, this.props.showModal),
				children: t('Workspace:about_citrix_workspace'),
			};
		}

		return undefined;
	}

	private getLogoutOption(): DropdownItem {
		if (environment.menuPreferences.logoff) {
			return {
				onClick: () => this.props.userContext.logOff(),
				children: t('javascript:logout'),
			};
		}

		return undefined;
	}

	private manageLocalAppOption(): DropdownItem {
		if (
			hasUIProperty(this.props.workspaceConfiguration, UIProperty.Apps) &&
			environment.supportsUserManagedLocalApps
		) {
			return {
				onClick: () => this.props.showModal(<LocalAppManageModal />),
				children: t('Workspace:localapp_manage.menu_item'),
			};
		}

		return undefined;
	}

	private getAccountSettingsOption(): DropdownItem {
		let accountSettingsOption;
		if (shouldShowAccountSettings(this.props.workspaceConfiguration)) {
			accountSettingsOption = {
				onClick: () => this.props.history.push(settingsRoute.getUrl()),
				children: t('Workspace:account_settings'),
			};
		}
		return accountSettingsOption;
	}

	private getApplicationSettingsOption(): DropdownItem {
		if (environment.supportsNewSettings || environment.supportsLegacySettings) {
			return {
				onClick: (e: React.MouseEvent<HTMLLIElement>) => {
					const { left, right, top, bottom } = e.currentTarget.getBoundingClientRect();
					this.openSettings(left, right, top, bottom);
				},
				children: t('Workspace:application_settings'),
			};
		}

		return undefined;
	}

	private getShowAccountsOption(): DropdownItem {
		if (environment.menuPreferences.accounts) {
			return {
				onClick: () => environment.showAccounts(),
				children: t('Workspace:accounts'),
			};
		}

		return undefined;
	}

	private getSwitchAccountsOption(): DropdownItem {
		if (environment.menuPreferences.accountSwitch) {
			const accountsToSwitch = this.getAccountsToSwitch();
			const canSwitchAccounts = accountsToSwitch.length > 1;
			if (canSwitchAccounts) {
				return {
					items: accountsToSwitch,
					children: t('Workspace:account_switch'),
				};
			}
		}

		return undefined;
	}

	private getAccountsToSwitch() {
		const accounts =
			this.props.accounts?.length > 0
				? this.props.accounts
				: environment.getNativeAccounts().accounts;
		const output: DropdownItem[] = [];
		const allAccounts = accounts.find(a => a.allAccounts);
		accounts.forEach(account => {
			if (!account.allAccounts) {
				output.push({
					children: (
						<>
							{account.displayName}
							{account.enabled && (
								<CheckIcon className={css(styles.enabled)} color="#000" size={10} />
							)}
						</>
					),
					onClick: () => environment.sendAction(account.actionId),
				});
			}
		});

		const showAllAccounts = allAccounts !== undefined && output.length > 1;
		if (showAllAccounts) {
			output.push({
				children: (
					<>
						{allAccounts.displayName}
						{allAccounts.enabled && (
							<CheckIcon className={css(styles.enabled)} color="#000" />
						)}
					</>
				),
				onClick: () => environment.sendAction(allAccounts.actionId),
			});
		}
		return output;
	}

	private openSettings(left: number, right: number, top: number, bottom: number) {
		environment.showSettings(
			left,
			top,
			right,
			bottom,
			window.innerWidth,
			window.innerHeight
		);
	}

	private tryOpenSettings = (
		e: React.MouseEvent<HTMLButtonElement>,
		dropdownOnClick: (e?: React.MouseEvent<HTMLButtonElement>) => void
	) => {
		const daasVisionEnabled = this.props.userContext.isDaasVisionEnabled;
		if (
			(daasVisionEnabled &&
				isNativeMobileOrTabletClient() &&
				this.props.formFactor.isSmallFormFactor) ||
			environment.isNativeDropdownNeeded
		) {
			const { left, right, top, bottom } = e.currentTarget.getBoundingClientRect();
			this.openSettings(left, right, top, bottom);
			return;
		}
		dropdownOnClick(e);
	};

	public render() {
		const username = this.props.userContext.userDetails?.userDisplayName;
		const imageLocation =
			this.props.userContext.userPreferences?.profilePicture?.items?.Default?.url;

		return (
			<Dropdown options={this.getDropdownOptions()} placement="bottom-end" zIndex={4}>
				{buttonProps => (
					<Button.Wrapper
						{...buttonProps}
						aria-label={t('Workspace:user_menu')}
						onClick={e => this.tryOpenSettings(e, buttonProps.onClick)}
						title={t('javascript:masthead.usermenu.account')}
						className={css(styles.focusStyle)}
					>
						<span>
							{/* Span wrapper needed for Button.Wrapper to apply focus styling*/}
							<WorkspaceAvatar
								username={username}
								size={bannerContentHeight}
								color={this.props.branding.styles.HeaderTextColor}
								imageLocation={imageLocation}
							/>
						</span>
					</Button.Wrapper>
				)}
			</Dropdown>
		);
	}

	public componentDidMount() {
		performance.mark(performance.events.masthead);
	}
}

function UserMenuContext(props: UserMenuLoaderProps) {
	const userContext = useUserContext();
	const history = useHistory();
	const branding = useBranding();
	const { loading: accountsLoading, accounts } = useWorkspaceAccounts();
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	const formFactor = useFormFactor();
	return (
		<>
			{accountsLoading ? (
				<UserMenuSkeleton />
			) : (
				<_UserMenu
					{...props}
					accounts={accounts}
					userContext={userContext}
					history={history}
					branding={branding}
					workspaceConfiguration={workspaceConfiguration}
					formFactor={formFactor}
				/>
			)}
		</>
	);
}

export const UserMenu = withModals(UserMenuContext);
