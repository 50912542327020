import { t } from '@citrite/translate';
import { SettingsLeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { createLeftNavRoute } from 'Router/ReactRoute';

const component = makeAsync(() =>
	import('./RegionalSettings').then(m => m.RegionalSettings)
);

export const regionalSettingsRoute = createLeftNavRoute({
	component,
	path: '/settings/workspace/regionalsettings',
	getNavLinkProps: () => ({
		title: t('App/Settings:regional_settings.title'),
		weight: SettingsLeftNavPositions.regionalSettings,
	}),
});
