import * as React from 'react';
import { css } from 'aphrodite';
import { styles } from './NewMessagesIcon.styles';

interface Props {
	pulse?: boolean;
}

export function NewMessagesIcon(props: Props) {
	return (
		<div className={css(styles.newMessagesIcon, props.pulse && styles.fadeIn)}>
			<div className={css(props.pulse ? styles.pulsingCircle : styles.outerCircle)} />
			<div className={css(styles.staticInnerCircle)} />
		</div>
	);
}
