import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	Button,
	Dropdown,
	DropdownItem,
	LoadingIconPrimary,
	MobileActionSheet,
	MobileActionSheetItem,
	MoreActionIcon,
	notifyError,
	palette,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import { ResourceIcon } from 'Components/ResourceIcon';
import { Favorite } from 'Components/ResourceTile/Favorite';
import { restartDesktop } from 'Components/ResourceTile/restartDesktop';
import { ViewAppDetails, ViewDesktopDetails } from 'Components/ResourceTile/ViewDetails';
import {
	WithWorkspaceConfiguration,
	withWorkspaceConfiguration,
} from 'Configuration/withWorkspaceConfiguration';
import { addResourceAndUpdateStore } from 'Environment/addResource';
import { isMobileApp } from 'Environment/deviceSupportsResource';
import { isFavorite } from 'Environment/favorites';
import { installResource } from 'Environment/installResource';
import { launchResource } from 'Environment/launchResource';
import { isNativeMobileOrTabletClient } from 'Environment/launchResource/device';
import { removeResourceAndUpdateStore } from 'Environment/removeResource';
import { NotifyProps } from 'Environment/removeResource/removeResource';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';
import { searchCASReporter } from 'Workspace/TelemetryEvents/search/createSearchCASReporter';
import { iconSize, styles } from './ResourceResultItem.styles';

export interface Props extends WithResourceContextProps, WithWorkspaceConfiguration {
	result: Resource;
}

export interface State {
	isFavoriteInProgress?: boolean;
	isAppInstalling?: boolean;
}

class _ResourceResultItem extends React.Component<Props & WithModalsProps, State> {
	public state: State = {
		isFavoriteInProgress: false,
		isAppInstalling: false,
	};

	public render() {
		return (
			<div className={css(styles.row)} key={this.props.result.id}>
				<div className={css(styles.outerColumn)}>
					{this.props.resourceContext.subscriptionsEnabled() &&
						!!this.props.result.subscriptionurl && (
							<div>
								<Favorite
									{...this.props.result}
									isPending={this.state.isFavoriteInProgress}
								/>
							</div>
						)}
				</div>
				<Button.Wrapper
					onClick={this.launchResource}
					className={css(styles.innerColumn)}
					role="link"
				>
					<div className={css(styles.iconAndTitleContainer)}>
						<div className={css(styles.iconContainer)}>
							{this.props.resourceContext.isInProgress(this.props.result.id) ||
							this.state.isAppInstalling ? (
								<div className={css(styles.icon)}>
									<LoadingIconPrimary size={iconSize} />
								</div>
							) : (
								<ResourceIcon resource={this.props.result} size={iconSize} />
							)}
						</div>
						<span className={css(styles.title)}>{this.props.result.name}</span>
					</div>
				</Button.Wrapper>
				<div className={css(styles.outerColumn)}>
					{isNativeMobileOrTabletClient() ? (
						<div className={css(styles.moreActions)}>
							<MoreActionIcon
								size={22}
								title={t('Workspace:more_options')}
								hoverColor={palette.blue800}
								onClick={() =>
									this.props.showModal(
										<MobileActionSheet
											menuItems={this.getMobileActionSheetMenuItems(this.getOptions())}
											closeOnOverlayClick
										/>
									)
								}
							/>
						</div>
					) : (
						<Dropdown options={this.getOptions()} placement="bottom-end">
							{buttonProps => (
								<Button.Wrapper {...buttonProps}>
									<div className={css(styles.moreActions)}>
										<MoreActionIcon
											size={22}
											title={t('Workspace:more_options')}
											hoverColor={palette.blue800}
										/>
									</div>
								</Button.Wrapper>
							)}
						</Dropdown>
					)}
				</div>
			</div>
		);
	}

	private getMobileActionSheetMenuItems(options: DropdownItem[]) {
		return options.map<MobileActionSheetItem>(dropdownItem => {
			return {
				onClick: () => dropdownItem.onClick(null),
				title: dropdownItem.children,
			};
		});
	}

	private getOptions = () => {
		const options: DropdownItem[] = [
			this.getRestart(),
			this.getOpenApp(),
			this.getDetails(),
			this.getFavorite(),
		];

		return options.filter(d => !!d);
	};

	private getRestart = () => {
		if (this.isDesktop(this.props.result) && this.props.result.poweroffurl) {
			const option: DropdownItem = {
				onClick: this.restartDesktop,
				children: t('Workspace:restart_desktop_modal.restart'),
			};
			return option;
		}

		return undefined;
	};

	private getDetails = () => {
		return {
			onClick: this.viewDetails,
			children: t('Workspace:view_details'),
		};
	};

	private getOpenApp = (): DropdownItem => {
		if (this.isDesktop(this.props.result)) {
			return undefined;
		}

		const isAppInstalled = this.props.resourceContext.isInstalled(this.props.result.id);
		const isAppInProgress = this.props.resourceContext.isInProgress(this.props.result.id);
		let label;
		let onClick = null;

		if (isMobileApp(this.props.result) && !isAppInstalled) {
			if (this.state.isAppInstalling) {
				label = t('Workspace:installing_app');
			} else {
				label = t('Workspace:install_app');
				onClick = () => {
					this.installApp();
				};
			}
			return {
				children: label,
				onClick,
			};
		}

		if (isAppInProgress) {
			return { children: t('Workspace:open_in_progress') };
		}

		return {
			children: t('Workspace:open_app'),
			onClick: () => {
				this.openApp();
			},
		};
	};

	private installApp = () => {
		this.setState({ isAppInstalling: true });
		installResource({
			resource: this.props.result,
			updateSession: this.props.resourceContext.updateSession,
		})
			.catch(() => notifyError(t('Workspace:application_install_failed')))
			.then(() => {
				this.setState({ isAppInstalling: false });
			});
	};

	private openApp = () => {
		launchResource({
			resource: this.props.result,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
		});
	};

	private getFavorite = () => {
		const result = this.props.result;
		if (
			this.props.resourceContext.subscriptionsEnabled() &&
			!!result.subscriptionurl &&
			!result.mandatory
		) {
			const label = isFavorite(this.props.result)
				? t('App/Search:remove_favorite')
				: t('App/Search:add_favorite');
			return {
				children: label,
				onClick: () => this.favoriteToggle(),
			};
		}

		return undefined;
	};

	private favoriteToggle = () => {
		this.setState({
			isFavoriteInProgress: true,
		});

		const currentResourceProps: NotifyProps = {
			...this.props.result,
			resourceContext: this.props.resourceContext,
			subscriptionstatus: isFavorite(this.props.result)
				? subscriptionStatus.subscribed
				: subscriptionStatus.unsubscribed,
		};

		if (isFavorite(this.props.result)) {
			removeResourceAndUpdateStore(currentResourceProps)
				.catch(() => {})
				.then(() => this.setState({ isFavoriteInProgress: false }));
			return;
		}

		addResourceAndUpdateStore(currentResourceProps)
			.catch(() => {})
			.then(() => this.setState({ isFavoriteInProgress: false }));
	};

	private launchResource = () => {
		trackAnalyticsEvent(searchCASReporter.getSearchSelectEvent());
		if (
			isMobileApp(this.props.result) &&
			!this.props.resourceContext.isInstalled(this.props.result.id)
		) {
			if (
				!this.props.resourceContext.isInProgress(this.props.result.id) &&
				!this.state.isAppInstalling
			) {
				this.installApp();
			}
			return;
		}
		launchResource({
			resource: this.props.result,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
		});
	};

	private restartDesktop = () => {
		restartDesktop(this.props.result, this.props, this.props.workspaceConfiguration);
	};

	private isDesktop = (resource: Resource) =>
		resource.isdesktop ||
		(resource.keywords && resource.keywords.some(keyword => keyword === 'TreatAsApp'));

	private viewDetails = () => {
		if (this.isDesktop(this.props.result)) {
			this.props.showModal(<ViewDesktopDetails resource={this.props.result} />);
		} else {
			this.props.showModal(<ViewAppDetails resource={this.props.result} />);
		}
	};
}

export const ResourceResultItem = withWorkspaceConfiguration(
	withModals(withResourceContext(_ResourceResultItem))
);
