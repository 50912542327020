import * as React from 'react';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import {
	activeClassName,
	StyledAnchor,
	StyledListItem,
	StyledNavLink,
} from 'App/LeftNavigation/SecondaryNavigation/ChildLink.styled';

export interface ChildLinkProps {
	link: LeftNavLink;
	focusable: boolean;
	onLinkClick(e: React.MouseEvent, link: LeftNavLink): void;
}

export function ChildLink(props: ChildLinkProps) {
	const { link } = props;
	const tabIndex = !props.focusable ? -1 : null;
	return (
		<StyledListItem>
			{link.href ? (
				<StyledNavLink
					to={link.href}
					tabIndex={tabIndex}
					activeClassName={activeClassName}
					onClick={e => props.onLinkClick(e, link)}
				>
					{link.title}
				</StyledNavLink>
			) : (
				<StyledAnchor
					href="#"
					onClick={e => props.onLinkClick(e, link)}
					tabIndex={tabIndex}
				>
					{link.title}
				</StyledAnchor>
			)}
		</StyledListItem>
	);
}
