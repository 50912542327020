import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { LoadingIconPrimary, Tooltip } from '@citrite/web-ui-component';
import { GetItemPropsOptions } from 'downshift';
import { ResourceIcon } from 'Components/ResourceIcon';
import { environment } from 'Environment';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './Result.styles';

export interface Props {
	header: string;
	resources: Resource[];
	notFound: string;
	isInProgress(id: string): boolean;
	getItemProps(props: GetItemPropsOptions<any>): React.HTMLAttributes<HTMLLIElement>;
	highlightedIndex: number;
	baseIndex: number;
}

export function ResourceResults(props: Props) {
	return (
		<>
			{props.header && <li className={css(styles.resultsHeading)}>{props.header}</li>}
			{!props.resources.length && (
				<li className={css(styles.nonResult)}>{props.notFound}</li>
			)}
			{props.resources.map((resource, index) => {
				const shouldDisableOfflineTooltip = !!(
					environment.citrixCloudConnected || resource.clmetadata?.leasesupported
				);
				return (
					<Tooltip
						key={resource.id}
						isDisabled={shouldDisableOfflineTooltip}
						hoverTrigger={
							<li
								key={resource.id}
								{...props.getItemProps({
									item: resource,
									index: index + props.baseIndex,
									className: css(
										!environment.citrixCloudConnected &&
											!resource.clmetadata?.leasesupported
											? (styles.listItem, styles.listItemDisabled)
											: styles.listItem,
										props.highlightedIndex === index + props.baseIndex &&
											styles.listItemHighlight
									),
								})}
							>
								<div className={css(styles.itemTitle)}>
									{props.isInProgress(resource.id) ? (
										<LoadingIconPrimary size={24} />
									) : (
										<ResourceIcon resource={resource} size={24} />
									)}
									<div title={resource.name} className={css(styles.itemName)}>
										{resource.name}
									</div>
								</div>
							</li>
						}
					>
						{resource.isdesktop
							? t('Workspace:desktop_unavailable')
							: t('Workspace:app_unavailable')}
					</Tooltip>
				);
			})}
		</>
	);
}
