import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, notifyError } from '@citrite/web-ui-component';
import { browser } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';

export function errorWithCopyTransactionIdButton(
	errorText: React.ReactNode,
	transactionId: string
) {
	transactionId &&
		console.warn(`Resource launch failed. Transaction Id: ${transactionId}`);
	return notifyError(
		<>
			<StyledDiv>{errorText}</StyledDiv>
			{!browser.isInternetExplorer() && transactionId ? (
				<Button.TextSecondary
					onClick={() => navigator.clipboard.writeText(transactionId)}
				>
					{t('Workspace:desktop_launch_failures.click_to_copy')}
				</Button.TextSecondary>
			) : undefined}
		</>
	);
}

export function errorWithCopyTransactionIdButtonforHtml5LeaseLaunch(
	errorText: React.ReactNode,
	errorCode: string,
	transactionId: string
) {
	return errorWithCopyTransactionIdButtonforLeaseLaunch(
		errorText,
		errorCode,
		transactionId
	);
}

export function errorWithCopyTransactionIdButtonforChromeAppLeaseLaunch(
	errorText: React.ReactNode,
	errorCode: string,
	transactionId: string
) {
	return errorWithCopyTransactionIdButtonforLeaseLaunch(
		errorText,
		errorCode,
		transactionId
	);
}

function errorWithCopyTransactionIdButtonforLeaseLaunch(
	errorText: React.ReactNode,
	errorCode: string,
	transactionId: string
) {
	transactionId &&
		console.warn(`Resource lease launch failed. Transaction Id: ${transactionId}`);
	return notifyError(
		<>
			<StyledDivforLeaseError>
				{t('Workspace:lease_launch_errormsg') + '. ' + errorText}
			</StyledDivforLeaseError>
			<StyledDiv>{t('Workspace:lease_launch_errorcode', { code: errorCode })}</StyledDiv>
			{!browser.isInternetExplorer() && transactionId ? (
				<Button.TextSecondary
					onClick={() => navigator.clipboard.writeText(transactionId)}
				>
					{t('Workspace:desktop_launch_failures.click_to_copy')}
				</Button.TextSecondary>
			) : undefined}
			<StyledDiv>{t('Workspace:lease_launch_helpdesk')}</StyledDiv>
		</>
	);
}

const StyledDivforLeaseError = styled.div`
	padding: 5px 5px;
	font-weight: bold;
	width: 300px;
`;

const StyledDiv = styled.div`
	padding: 0px 5px;
`;
