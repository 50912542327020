import * as React from 'react';
import { Button, ButtonProps } from '@citrite/web-ui-component';
import { deviceInfo } from '@citrite/workspace-ui-platform';
import { ActionButton, ActionButtonProps } from './ActionButton';
import { CardAction, CardActions } from './ActionButtonsRenderer.styled';
import { ButtonStyle, ButtonStyleBaby } from './buttonStyles';
import { MobileSubtleButton } from './MobileSubtleButton';
import { Action } from './types';

export interface ActionButtonsRendererProps<T = any, R = any> {
	actions: Action<T, R>[];
	appTitle: string;
	handleResults?: ActionButtonProps<T, R>['handleResults'];
	formatErrorMessage: ActionButtonProps<T, R>['formatErrorMessage'];
}

const keyForAction = ({
	label,
	style,
}: {
	label?: string;
	style: ButtonStyle | ButtonStyleBaby;
}) => `${style}_${label}`;

const buttonForStyle = (
	style: ButtonStyle | ButtonStyleBaby
): React.ComponentType<ButtonProps> => {
	switch (style) {
		case ButtonStyle.PRIMARY:
			return Button.Primary;
		case ButtonStyle.SECONDARY:
			return Button.Secondary;
		case ButtonStyle.RED:
			return Button.Red;
		case ButtonStyleBaby.PRIMARY:
			return deviceInfo.isMobileOrTabletDevice() ? Button.Primary : Button.BabyPrimary;
		case ButtonStyleBaby.SECONDARY:
			return deviceInfo.isMobileOrTabletDevice()
				? MobileSubtleButton
				: Button.SubtlePrimary;
		case ButtonStyleBaby.RED:
			return Button.BabyRed;
	}
};

export function ActionButtonsRenderer<T, R>({
	actions,
	appTitle,
	handleResults,
	formatErrorMessage,
}: ActionButtonsRendererProps<T, R>) {
	const [actionKeyInProgress, setActionKeyInProgress] = React.useState<string>(null);

	if (!actions || actions.length === 0) {
		return null;
	}
	const handleButtonInProgress =
		(actionKey: string) =>
		(isActionStillInProgress = true) => {
			isActionStillInProgress
				? setActionKeyInProgress(actionKey)
				: setActionKeyInProgress(null);
		};
	function isButtonDisabled(actionKey: string, isActionDisabled: boolean) {
		return (actionKeyInProgress && actionKey !== actionKeyInProgress) || isActionDisabled;
	}
	function isButtonInProgress(actionKey: string) {
		return actionKeyInProgress && actionKey === actionKeyInProgress;
	}

	return (
		<CardActions>
			{actions.map(action => {
				const actionKey = keyForAction(action);
				return (
					<CardAction key={actionKey}>
						<ActionButton
							action={action}
							appTitle={appTitle}
							handleResults={handleResults}
							formatErrorMessage={formatErrorMessage}
							handleButtonInProgress={handleButtonInProgress(actionKey)}
							ButtonType={buttonForStyle(action.style)}
							disabled={isButtonDisabled(actionKey, action.disabled)}
							inProgress={isButtonInProgress(actionKey)}
						/>
					</CardAction>
				);
			})}
		</CardActions>
	);
}
