import * as React from 'react';
import { fromThemeColors } from '@citrite/web-ui-component';
import { css, Global } from '@emotion/react';

export function GlobalStyles() {
	return (
		<Global
			styles={theme => css`
				html {
					font-family: 'PublicSans', Arial, sans-serif;
					font-size: 14px;
					line-height: 1.25;
					color: ${fromThemeColors('primaryText')({ theme })};
					overflow: hidden;
				}
				input,
				textarea,
				keygen,
				select,
				button {
					font-family: inherit;
					font-weight: inherit;
				}
				a {
					color: #404041;
				}
				a strong {
					color: #6e6e71;
				}
			`}
		/>
	);
}
