import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { FeedSkeletonLoader, ShimmerLoading } from '@citrite/workspace-ui-platform-react';
import { useUserContext } from 'Workspace/UserContext';

const { FeedLoading } = FeedSkeletonLoader;
const { ShimmerLoader } = ShimmerLoading;

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		backgroundColor: 'inherit',
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
	},
});

export function AppLoader() {
	const isSkeletonLoaderEnabled = useUserContext().isDaasVisionEnabled;
	return (
		<div className={css(styles.container)}>
			{isSkeletonLoaderEnabled ? <ShimmerLoader /> : <FeedLoading />}
		</div>
	);
}
