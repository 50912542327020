import { StyleSheet } from 'aphrodite';
import { layout, palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	smartbanner: {
		alignItems: 'center',
		background: palette.blue800,
		bottom: '0',
		boxShadow: `0 2px 2px ${palette.black}`,
		display: 'flex',
		flexDirection: 'row',
		height: '72px',
		justifyContent: 'space-evenly',
		left: '0',
		overflow: 'hidden',
		position: 'fixed',
		width: '100%',
		zIndex: 4,
	},

	smartbannerText: {
		marginLeft: layout.mediumSpace,
		marginRight: layout.mediumSpace,
		color: palette.white,
		fontWeight: typography.weights.regular,
		fontSize: typography.sizes.sml,
	},

	smartbannerClose: {
		marginLeft: layout.mediumSpace,
	},

	smartbannerAction: {
		marginRight: layout.mediumSpace,
		color: palette.white,
		fontWeight: typography.weights.medium,
		fontSize: typography.sizes.sml,
		textDecoration: 'none',
	},
});
