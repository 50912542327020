import { globallyIgnoreCedexisBreadcrumbs } from 'Loggers/SentryLogger';

export function addCedexisAnalytics() {
	// Configure at global sentry instance level vs. App
	globallyIgnoreCedexisBreadcrumbs();

	const script = document.createElement('script');
	script.src = 'https://radar.cedexis.com/1/55156/radar.js';
	script.async = true;
	document.body.appendChild(script);
}

export function shouldAddCedexisAnalytics() {
	return !IS_ON_PREM;
}
