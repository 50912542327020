import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

export enum NativePlatform {
	Windows = 'windows',
	Mac = 'mac',
	Android = 'android',
	iOS = 'ios',
	Linux = 'linux',
	Simulated = 'simulated',
}

/**
 * @category Platform Dependency
 */
export interface DeviceInfo {
	isNativeClient: () => boolean;
	isNativeMobileClient: () => boolean;
	isMobileDevice: () => boolean;
	isMobileOrTabletDevice: () => boolean;
	isNativeMobileOrTabletClient: () => boolean;
	nativePlatform: () => NativePlatform;
}

/**
 * @category Platform Dependency
 */
export const deviceInfo: DeviceInfo = {
	isNativeClient: (...args) =>
		resolveFromGlobal(PlatformDependencies.DeviceInfo).isNativeClient(...args),
	isNativeMobileClient: (...args) =>
		resolveFromGlobal(PlatformDependencies.DeviceInfo).isNativeMobileClient(...args),
	isMobileDevice: (...args) =>
		resolveFromGlobal(PlatformDependencies.DeviceInfo).isMobileDevice(...args),
	isMobileOrTabletDevice: (...args) =>
		resolveFromGlobal(PlatformDependencies.DeviceInfo).isMobileOrTabletDevice(...args),
	isNativeMobileOrTabletClient: (...args) =>
		resolveFromGlobal(PlatformDependencies.DeviceInfo).isNativeMobileOrTabletClient(
			...args
		),
	nativePlatform: (...args) =>
		resolveFromGlobal(PlatformDependencies.DeviceInfo).nativePlatform(...args),
};
