const AADSSOLogonType = 'AzureAD';
const AADSSOFieldsInICA = ['SSOnLogonType', 'AzureDeviceId', 'InitialProgram'] as const;

export type AADSSOICAContents = {
	[K in typeof AADSSOFieldsInICA[number]]?: string;
} & { resourceId?: string };

export function parseResourceId(initialProgram: string): string {
	return initialProgram && initialProgram.length > 1 ? initialProgram.substring(1) : '';
}

export function doesResourceLaunchSupportAADSSO(
	aadSsoFieldsInICA: AADSSOICAContents
): boolean {
	return (
		typeof aadSsoFieldsInICA?.AzureDeviceId === 'string' &&
		aadSsoFieldsInICA?.SSOnLogonType === AADSSOLogonType &&
		aadSsoFieldsInICA.resourceId.length > 0
	);
}
