import { StyleSheet } from 'aphrodite';
import { styleUtils } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	input: {
		position: 'absolute',
		left: '-10000px',
		top: 'auto',
		width: 1,
		height: 1,
		overflow: 'hidden',
		':focus + span': styleUtils.focusInset,
	},
	label: {
		width: '100%',
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		userSelect: 'none',
	},
	siriButton: {
		paddingTop: '8px',
		paddingLeft: '10px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		userSelect: 'none',
	},
	statusOnly: {
		cursor: 'default',
	},
});
