import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	EmptyAppsIcon,
	EmptyComputerIcon,
	LoadingIconPrimary,
	Tab,
} from '@citrite/web-ui-component';
import { ActivityManager } from 'App/Activity/ActivityManager/ActivityManager';
import { WidgetTabContent } from 'App/NativeWidget/NativeWidgetContent';
import { WidgetsTabsContainer } from 'App/NativeWidget/WidgetTabs/WidgetTabContainer';
import { getSortedFavorites } from 'Environment/favorites';
import { getRecentResources } from 'Environment/recents';
import { RoutedComponentProps } from 'Router/ReactRoute';
import { isCitrixBrowserApp } from 'Workspace/AppContext/citrixBrowserResource';
import {
	ResourceContextProvider,
	useLoadableResourceContext,
} from 'Workspace/ResourceProvider';
import { styles } from './styles';

export enum NativeWidgetTabCategory {
	favourites,
	recents,
	activity,
}

export enum NativeWidgetContentTypeCategory {
	apps,
	desktops,
}

export type Props = RoutedComponentProps & {
	loadableResourceContext: ResourceContextProvider;
};
interface State {
	activeTab: NativeWidgetTabCategory;
}

const maxResourceCount = 5;

class _NativeWidgets extends React.Component<Props, State> {
	public state: State = {
		activeTab: NativeWidgetTabCategory.recents,
	};

	public render() {
		if (this.props.loadableResourceContext.loading) {
			return <LoadingIconPrimary data-testid="loadingIconPrimary" />;
		}

		const desktops = this.props.resourceContext.resources.filter(r => r.isdesktop);
		const apps = this.props.resourceContext.resources.filter(r => !r.isdesktop);
		const recentDesktops = getRecentResources(desktops).slice(0, maxResourceCount);
		const recentApps = getRecentResources(apps)
			.filter(
				// The native widget contains a dedicated icon for launching the Citrix Enterprise Browser app.
				// Hence, removing it from the recents list to avoid redundancy.
				resource => !isCitrixBrowserApp(resource)
			)
			.slice(0, maxResourceCount);
		const favouriteApps = getSortedFavorites(apps).slice(0, maxResourceCount);
		const favouriteDesktops = getSortedFavorites(desktops).slice(0, maxResourceCount);
		const launchSourceGroup =
			this.state.activeTab === NativeWidgetTabCategory.recents ? 'Recents' : 'Favourites';
		const allAppsRoute = 'allApps';
		const allDesktopsRoute = 'allDesktops';

		const isActivityManagerFeatureFlagEnabled =
			this.props.userContext.isActivityManagerEnabled;

		return (
			<WidgetsTabsContainer activeTab={this.state.activeTab} onChange={this.changeTab}>
				{[
					<Tab
						key={NativeWidgetTabCategory.recents}
						value={NativeWidgetTabCategory.recents}
						label={t('Workspace:recents')}
					>
						<WidgetTabContent
							{...this.props}
							resources={recentApps}
							viewAllString={t('Workspace:view_all_apps')}
							sectionTitle={t('Workspace:apps')}
							emptyText={t('Workspace:empty_recent_apps')}
							emptyIcon={EmptyAppsIcon}
							viewAllActionRoute={allAppsRoute}
							launchSourceGroup={launchSourceGroup}
						/>
						<span className={css(styles.divider)} />
						<WidgetTabContent
							{...this.props}
							resources={recentDesktops}
							viewAllString={t('Workspace:view_all_desktops')}
							sectionTitle={t('Workspace:desktops')}
							emptyText={t('Workspace:empty_recent_desktops')}
							emptyIcon={EmptyComputerIcon}
							viewAllActionRoute={allDesktopsRoute}
							launchSourceGroup={launchSourceGroup}
						/>
						<span className={css(styles.divider)} />
					</Tab>,
					<Tab
						key={NativeWidgetTabCategory.favourites}
						value={NativeWidgetTabCategory.favourites}
						label={t('Workspace:favorites')}
					>
						<WidgetTabContent
							{...this.props}
							resources={favouriteApps}
							viewAllString={t('Workspace:view_all_apps')}
							sectionTitle={t('Workspace:apps')}
							emptyText={t('Workspace:empty_favorite_apps')}
							emptyIcon={EmptyAppsIcon}
							viewAllActionRoute={allAppsRoute}
							launchSourceGroup={launchSourceGroup}
						/>
						<span className={css(styles.divider)} />
						<WidgetTabContent
							{...this.props}
							resources={favouriteDesktops}
							viewAllString={t('Workspace:view_all_desktops')}
							sectionTitle={t('Workspace:desktops')}
							emptyText={t('Workspace:empty_favorite_desktops')}
							emptyIcon={EmptyComputerIcon}
							viewAllActionRoute={allDesktopsRoute}
							launchSourceGroup={launchSourceGroup}
						/>
						<span className={css(styles.divider)} />
					</Tab>,
					isActivityManagerFeatureFlagEnabled && (
						<Tab
							className={css(styles.tabContainer)}
							key={NativeWidgetTabCategory.activity}
							value={NativeWidgetTabCategory.activity}
							label={t('Workspace:activity_manager.activity')}
						>
							<ActivityManager {...this.props} />
						</Tab>
					),
				]}
			</WidgetsTabsContainer>
		);
	}

	private changeTab = (activeTab: NativeWidgetTabCategory) => {
		this.setState({ activeTab });
	};
}

export const NativeWidgets = ({ ...routedComponentProps }: RoutedComponentProps) => {
	const loadableResourceContext = useLoadableResourceContext();
	return (
		<_NativeWidgets
			{...routedComponentProps}
			loadableResourceContext={loadableResourceContext}
		/>
	);
};
