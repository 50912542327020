import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import {
	FullPageRouteCapability,
	FullPageRouteCapabilityMetadata,
} from '@citrite/workspace-ui-platform';
import { MastheadBanner } from 'Components/MastheadBanner';
import { InitialLoading } from 'Workspace/InitialLoading';

const styles = StyleSheet.create({
	mastheadWrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		position: 'relative',
	},
});

interface FullPageRouteProps {
	metadata: FullPageRouteCapabilityMetadata;
	integration: FullPageRouteCapability;
}

export function FullPageRoute(props: FullPageRouteProps) {
	if (!props.integration?.component) {
		return <InitialLoading />;
	}

	if (props.metadata.showMasthead) {
		return (
			<div className={css(styles.mastheadWrapper)}>
				<MastheadBanner fullPageRoute />
				<props.integration.component />
			</div>
		);
	}

	return <props.integration.component />;
}
