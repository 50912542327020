import { NativeAccounts } from '@citrite/workspace-ui-platform';
import { Action, RecentItems, SupportedRouteIds } from 'Environment';
import { Event } from 'Workspace/EventBus';

export enum EventType {
	VIEW_ACTION = 'action/view',
	UPDATE_ACCOUNTS = 'action/platform/accounts/update',
	ANALYTICS_TRACKING_EVENT = 'action/analytics/trackevent',
	UPDATE_RECENTS = 'action/platform/recents/update',
	NAVIGATE = 'action/platform/navigate',
	UPDATE_ACTIONS = 'actions/update',
	ACTION_CLOSED = 'actions/action/closed',
	ACTIONS_HANDLER_STATE = 'actions/handler/statechange',
	PERFORMANCE_MARK = 'platform/performance/mark',
	ACTIVITY_ADD_RESOURCES = 'action/miniui/running/add/resources',
	ACTIVITY_REMOVE_RESOURCES = 'action/miniui/running/remove/resources',
}

/**
 * An event representing a user action that indicates a user's request to view a Workspace resource's details.
 * The event's 'urn' should be a valid Citrix Workspace resource URN, defined within the resourceURN module of this
 * package.
 */
export interface ViewResourceActionEvent extends Event<{ urn: string }> {
	type: EventType.VIEW_ACTION;
}

export interface UpdateAccountsEvent extends Event<NativeAccounts> {
	type: EventType.UPDATE_ACCOUNTS;
}

export interface NavigateEvent extends Event<{ routeId: SupportedRouteIds }> {
	type: EventType.NAVIGATE;
}

export interface AnalyticsTrackingEvent
	extends Event<{
		trackType: string;
		metadata: { [key: string]: string | number | boolean };
	}> {
	type: EventType.ANALYTICS_TRACKING_EVENT;
}

export interface UpdateRecentsEvent
	extends Event<{
		recents: RecentItems[];
		areAppsAvailable?: boolean;
		areDesktopsAvailable?: boolean;
	}> {
	type: EventType.UPDATE_RECENTS;
}

export interface UpdateActionsEvent extends Event<{ actions: Action[] }> {
	type: EventType.UPDATE_ACTIONS;
}
export interface ActionViewClosedEvent extends Event<{}> {
	type: EventType.ACTION_CLOSED;
}

export interface ActionsHandlerStateEvent extends Event<{ loading: boolean }> {
	type: EventType.ACTIONS_HANDLER_STATE;
}

export interface PerformanceMarkEvent extends Event<{ name: string; time: number }> {
	type: EventType.PERFORMANCE_MARK;
}
