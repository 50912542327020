import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { isBrowserExtensionCompatible } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';

export function shouldMountBrowserExtensionContextProvider(
	workspaceConfiguration: WorkspaceConfiguration
) {
	//For ONPREM we should not rely\ or mimic on the UIproperty.AdvancedWorkspaceResiliency
	// as it is not available. Also the new UI does too many things based
	// on the UIproperty.AdvancedWorkspaceResiliency which does not apply to ONPREM
	if (IS_ON_PREM) {
		return isBrowserExtensionCompatible(workspaceConfiguration);
	}

	//Keep the cloud based check as previous to avoid any regression
	const canPromptUserToInstallBrowserExtension = hasFeatureCanary(
		workspaceConfiguration,
		FeatureFlag.PromptUserToInstallBrowserExtension
	);

	return (
		hasUIProperty(workspaceConfiguration, UIProperty.AdvancedWorkspaceResiliency) &&
		(canPromptUserToInstallBrowserExtension ||
			isBrowserExtensionCompatible(workspaceConfiguration))
	);
}
