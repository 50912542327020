export function getLocaleDateFormat() {
	return (
		dataFormats[navigator.language.toLocaleLowerCase()]?.date ||
		dataFormats[navigator.language.toLocaleLowerCase().split('-')[0]]?.date ||
		'MM/dd/yyyy'
	);
}

export function getLocaleTimeFormat() {
	return (
		dataFormats[navigator.language.toLocaleLowerCase()]?.time ||
		dataFormats[navigator.language.toLocaleLowerCase().split('-')[0]]?.time ||
		'h:mm A'
	);
}

export function getLocaleThousandSeparator() {
	return (
		dataFormats[navigator.language.toLocaleLowerCase()]?.thousandSeparator ||
		dataFormats[navigator.language.toLocaleLowerCase().split('-')[0]]
			?.thousandSeparator ||
		','
	);
}

export function getLocaleDecimalSeparator() {
	return (
		dataFormats[navigator.language.toLocaleLowerCase()]?.decimalSeparator ||
		dataFormats[navigator.language.toLocaleLowerCase().split('-')[0]]?.decimalSeparator ||
		'.'
	);
}

// Copied from moment.js's locale data. Not using moment directly because its full locale data set is 500KB.
const dataFormats = {
	af: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'ar-ma': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	'ar-tn': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	az: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	be: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	bg: { date: 'd.MM.yyyy', time: 'H:mm', thousandSeparator: ' ', decimalSeparator: ',' },
	bm: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	bo: {
		date: 'dd/MM/yyyy',
		time: 'A h:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	br: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	bs: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ',', decimalSeparator: '.' },
	ca: { date: 'dd/MM/yyyy', time: 'H:mm', thousandSeparator: '.', decimalSeparator: ',' },
	cs: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ' ', decimalSeparator: ',' },
	cv: {
		date: 'dd-MM-yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	cy: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	da: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	'de-at': {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	'de-ch': {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	de: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	dv: { date: 'd/M/yyyy', time: 'HH:mm', thousandSeparator: ',', decimalSeparator: '.' },
	el: {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-au': {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-ca': {
		date: 'yyyy-MM-dd',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-gb': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-ie': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-il': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-in': {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-nz': {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'en-sg': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	en: {
		date: 'MM/dd/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	eo: {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'es-do': {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'es-mx': {
		date: 'dd/MM/yyyy',
		time: 'H:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'es-us': {
		date: 'MM/dd/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	es: { date: 'dd/MM/yyyy', time: 'H:mm', thousandSeparator: '.', decimalSeparator: ',' },
	et: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ' ', decimalSeparator: ',' },
	eu: {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	fi: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	fil: {
		date: 'MM/d/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	fo: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'fr-ca': {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	'fr-ch': {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	fr: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	fy: {
		date: 'dd-MM-yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ga: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	gd: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	gl: { date: 'dd/MM/yyyy', time: 'H:mm', thousandSeparator: ',', decimalSeparator: '.' },
	he: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	hr: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: '.', decimalSeparator: ',' },
	hu: { date: 'yyyy.MM.dd', time: 'H:mm', thousandSeparator: ' ', decimalSeparator: ',' },
	'hy-am': {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	id: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	is: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ',', decimalSeparator: '.' },
	'it-ch': {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	it: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	ja: {
		date: 'yyyy/MM/dd',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	jv: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ka: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	kk: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	km: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	kn: {
		date: 'dd/MM/yyyy',
		time: 'A h:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ko: {
		date: 'yyyy.MM.dd',
		time: 'A h:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ku: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ky: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	lo: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	lt: {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	lv: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	me: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ',', decimalSeparator: '.' },
	mi: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	mk: { date: 'd.MM.yyyy', time: 'H:mm', thousandSeparator: ',', decimalSeparator: '.' },
	mn: {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ms: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	mt: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	my: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	nb: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	'nl-be': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	nl: {
		date: 'dd-MM-yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	nn: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'oc-nlc': {
		date: 'dd/MM/yyyy',
		time: 'H:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	pl: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	pt: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	ro: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: '.', decimalSeparator: ',' },
	ru: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ' ', decimalSeparator: ',' },
	sd: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	se: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	sk: { date: 'dd.MM.yyyy', time: 'H:mm', thousandSeparator: ' ', decimalSeparator: ',' },
	sq: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	ss: {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	sv: {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	ta: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	te: {
		date: 'dd/MM/yyyy',
		time: 'A h:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	tet: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	tg: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	th: { date: 'dd/MM/yyyy', time: 'H:mm', thousandSeparator: ',', decimalSeparator: '.' },
	tk: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'tl-ph': {
		date: 'MM/d/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	tlh: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	tr: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	tzl: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	tzm: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'ug-cn': {
		date: 'yyyy-MM-dd',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	uk: {
		date: 'dd.MM.yyyy',
		time: 'HH:mm',
		thousandSeparator: ' ',
		decimalSeparator: ',',
	},
	ur: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	uz: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	vi: {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: '.',
		decimalSeparator: ',',
	},
	'x-pseudo': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	yo: {
		date: 'dd/MM/yyyy',
		time: 'h:mm A',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	zh: {
		date: 'yyyy/MM/dd',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
	'zh-mo': {
		date: 'dd/MM/yyyy',
		time: 'HH:mm',
		thousandSeparator: ',',
		decimalSeparator: '.',
	},
};
