import URI from 'urijs';
import { setExternalUserCookie } from 'utils/externalUserCookie';

const activationQueryKey = 'a';
const customerIdQueryKey = 'ccid';

export function getExternalUserActivationUrl() {
	const hashRoute = location.hash.slice(1);
	const hashUri = new URI(hashRoute);
	const queryParams = hashUri.query(true) as Record<string, string>;
	const activationToken = queryParams[activationQueryKey];
	const citrixCloudCustomerId = queryParams[customerIdQueryKey];

	if (!activationToken || !citrixCloudCustomerId) {
		return null;
	}

	hashUri.removeQuery([activationQueryKey, customerIdQueryKey]);
	const returnRoute = hashUri.toString();
	const destinationUri = new URI(location.origin + location.pathname).fragment(
		returnRoute
	);

	const activationUri = new URI(location.origin)
		.subdomain('files')
		.path('/activate/')
		.addQuery({
			token: activationToken,
			destination: destinationUri.toString(),
			ccid: citrixCloudCustomerId,
		});
	setExternalUserCookie();
	return activationUri.toString();
}
