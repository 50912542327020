const extensionInstalledInitEvent = 'extensionInstalledInit';
export const extensionInitCompleteEvent = 'extensionInitComplete';

export function sendEventOnInstall(): void {
	const messageToWSUI = new CustomEvent(extensionInstalledInitEvent, {
		detail: 'installSuccess',
	});
	document.dispatchEvent(messageToWSUI as CustomEvent);
}

export function onInstallEventListener(updateContextProviderResolver: Function) {
	document.removeEventListener(extensionInstalledInitEvent, () => {});
	document.addEventListener(extensionInstalledInitEvent, async () => {
		const extensionState = await updateContextProviderResolver();
		if (extensionState.isActive) {
			const eventResponse = new CustomEvent(extensionInitCompleteEvent, {
				detail: extensionState,
			});
			document.dispatchEvent(eventResponse);
		}
	});
}
