import * as React from 'react';
import { css } from 'aphrodite';
import { RouteContext } from '@citrite/workspace-ui-platform';
import { styles } from './ContentTitle.styles';

export interface Props extends RouteContext {
	children?: React.ReactNode;
}

export class ContentTitle extends React.Component<Props> {
	public render() {
		return <h2 className={css(styles.title)}>{this.props.children}</h2>;
	}

	public componentDidMount() {
		this.props.setMobileMastheadTitle &&
			this.props.setMobileMastheadTitle(this.props.children);
	}

	public componentWillUnmount() {
		this.props.setMobileMastheadTitle && this.props.setMobileMastheadTitle('');
	}
}
