import { logError } from 'remoteLogging';

export const logFeatureRolloutError = (e: any) => {
	if (e?.status === 403) {
		// Do not log since a race condition with getUserDetails is allowed
		// and challenges are not automatically handled from SSO Proxy Client.
		return;
	}

	logError(e, { tags: { feature: 'frs' } });
};
