import * as React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { t } from '@citrite/translate';
import { HamburgerIcon, XIcon } from '@citrite/web-ui-component';
import { StyledMobileMenu } from 'App/LeftNavigation/MobileMenu/styled';
import { useBranding } from 'Configuration/withBranding';
import { FeatureFlag } from 'Environment/FeatureFlag';

export enum Menu {
	closed,
	primary,
	secondary,
}

export interface Props {
	menu: Menu;
	open(): void;
	close(): void;
}

export function MobileMenu(props: Props) {
	const branding = useBranding();
	const iconColor = branding.styles.HeaderTextColor;
	const iconSize = getFeatureFlagValue(FeatureFlag.SimplifiedNav) ? 24 : 20;

	if (props.menu !== Menu.closed) {
		return getFeatureFlagValue(FeatureFlag.SimplifiedNav) ? (
			<StyledMobileMenu>
				<XIcon
					aria-label={t('javascript:mobile_menu_close_aria_label')}
					size={iconSize}
					color={iconColor}
					onClick={props.close}
				/>
			</StyledMobileMenu>
		) : null;
	}

	return (
		<StyledMobileMenu>
			<HamburgerIcon
				aria-label={t('javascript:mobile_menu_aria_label')}
				size={iconSize}
				color={iconColor}
				onClick={props.open}
			/>
		</StyledMobileMenu>
	);
}
