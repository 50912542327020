import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	breakpoints,
	fromThemeColors,
	palette,
	ThemeColor,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { Menu } from 'App/LeftNavigation/MobileMenu';
import { FeatureFlag } from 'Environment/FeatureFlag';

export const StyledSecondaryNavigation = styled.nav`
	flex: 0 0 220px;
	display: flex;
	flex-direction: column;
	${props =>
		getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? {
					backgroundColor: `${fromThemeColors(ThemeColor.secondaryAppBackground)(props)}`,
					borderLeft: `1px solid ${fromThemeColors(ThemeColor.border)(props)}`,
			  }
			: {
					backgroundColor: `${fromThemeColors(ThemeColor.primaryContainerBackground)(
						props
					)}`,
			  }}
	${breakpoints.onMobile} {
		${() =>
			!getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
				paddingTop: '46px',
			}}
		padding-bottom: 50px;
		flex: 0 0 300px;
		box-sizing: border-box;
		transition: 0.5s ease-in-out;
		border-right: 1px solid ${palette.grey300};
		${() =>
			getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
				backgroundColor: `${palette.white}`,
			}}
	}
`;

export const StyledTabHeader = styled.div<{ mobileMenu: Menu; secondary: Menu }>`
	${() =>
		!getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
			position: 'absolute',
			marginTop: '-46px',
			width: '300px',
		}}
	cursor: pointer;
	line-height: 34px;
	align-content: center;
	box-sizing: border-box;
	font-size: ${typography.sizes.base};
	display: none;
	padding: 16px 0 0 20px;
	z-index: 1;
	${breakpoints.onMobile} {
		display: flex;
	}
`;

export const StyledNavTitle = styled.div`
	margin-left: 10px;
`;

export const StyledLists = styled.ul`
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0;
	padding: ${() => (getFeatureFlagValue(FeatureFlag.SimplifiedNav) ? 36 : 20)}px 0 0 0;
	list-style-type: none;
	box-sizing: border-box;
`;

export const StyledTabs = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 300px;
	height: 50px;
	display: none;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-top: 1px solid ${palette.grey300};
	box-sizing: border-box;
	z-index: 1;
	${breakpoints.onMobile} {
		display: flex;
	}
`;

export const StyledTabIndicator = styled.div<{ indicator: boolean; menuValue: Menu }>`
	background-color: ${palette.grey300};
	border-radius: 10px;
	display: inline-block;
	width: 10px;
	height: 10px;
	opacity: 0.6;
	margin: 0 5px;
	transition: 0.5s ease-in-out;
	${props => props.indicator && 'background-color: #000'};
`;
