import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '../../utils/translate';
import { styles } from './NoAvailableItems.styles';

export function NoAvailableItems({ title }: { title: string }) {
	return (
		<div className={css(styles.noItems)}>
			<div className={css(styles.title)}>{title}</div>
			{t('platform-react:no_resources_contact')}
		</div>
	);
}
