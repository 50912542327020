import React from 'react';
import { t } from 'i18next';
import {
	StyledActivityBodySubContent,
	StyledActivityCardHeaderContainer,
	StyledTitle,
} from 'App/Activity/components/commonStyles';
import { MoreAction } from 'App/Activity/components/MoreAction';
import { MoreInfo } from 'App/Activity/components/MoreInfo';
import {
	LaunchAction,
	Session,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { canReconnect } from 'App/Activity/useDesktopActions';
import { useFormFactor } from 'App/Header/useFormFactor';

export interface ApplicationSectionHeaderProps {
	session: Session;
}

export interface StyledActivityCardHeaderContainerProps {
	isClickable?: boolean;
}

export const ApplicationSectionHeader = ({ session }: ApplicationSectionHeaderProps) => {
	const { onDesktopAction } = useResourceManagerContext();
	const canReconnectSession = canReconnect(session);
	const handleClick = () => {
		if (canReconnectSession) {
			onDesktopAction(LaunchAction.RECONNECT, session);
		}
	};
	const { isSmallFormFactor } = useFormFactor();
	const title = `${t('Workspace:applications')} (${session?.machineData?.machineName})`;
	const ariaLabel =
		t('Workspace:activity_manager.activity') + ' ' + session?.machineData?.machineName;

	return (
		<StyledActivityCardHeaderContainer
			isClickable={canReconnectSession}
			onClick={handleClick}
			aria-label={ariaLabel}
		>
			<StyledTitle title={title} isSmallFormFactor={isSmallFormFactor} />
			<StyledActivityBodySubContent>
				<MoreInfo session={session} />
				<MoreAction session={session} />
			</StyledActivityBodySubContent>
		</StyledActivityCardHeaderContainer>
	);
};
