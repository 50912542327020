import * as React from 'react';
import {
	fromThemePalette,
	MoreActionIcon,
	palette,
	ThemeColor,
	useThemeColor,
} from '@citrite/web-ui-component';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { useTheme } from '@emotion/react';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import {
	StyledLinkBody,
	StyledLinkIcon,
	StyledLinkText,
	StyledLinkTextAndActions,
} from 'App/LeftNavigation/PrimaryNavigation/Link.styled';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useBranding } from 'Configuration/withBranding';
import { FeatureFlag } from 'Environment/FeatureFlag';

const activeLinkIconColor = fromThemePalette({
	citrixbrand: palette.teal900,
	neutral: palette.blue900,
});

export interface Props extends LeftNavLink {
	collapsed: boolean;
	active?: boolean;
}

export function LinkBody(props: Props) {
	const branding = useBranding();
	const inactiveIconColor = useThemeColor(ThemeColor.secondaryActionIcon);
	const theme = useTheme();
	const { workspaceConfiguration } = useConfigurationContext();

	return (
		<StyledLinkBody>
			<StyledLinkIcon>
				{props.icon && (
					<props.icon
						size={24}
						color={
							props.active
								? hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav)
									? activeLinkIconColor({ theme })
									: branding.styles.AccentColor
								: inactiveIconColor
						}
					/>
				)}
				{!props.icon && props.collapsed && props.active && <MoreActionIcon size={24} />}
			</StyledLinkIcon>
			<StyledLinkTextAndActions collapsed={props.collapsed}>
				<StyledLinkText
					data-testid={`link-text-${props.title
						?.toLocaleLowerCase()
						.replace(/\s/g, '-')}`}
				>
					{props.title}
				</StyledLinkText>
				{props.secondaryIcon && <props.secondaryIcon size={20} />}
			</StyledLinkTextAndActions>
		</StyledLinkBody>
	);
}
