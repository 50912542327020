import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { Button, Modal, ModalProps, SearchIcon } from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import Downshift from 'downshift';
import _ from 'lodash';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { searchRoute } from 'App/Search';
import { SearchFilterOption } from 'App/Search/SearchFilterOption';
import { ResourceSearch } from 'Components/ItemSearch/ResourceSearch';
import { Result } from 'Components/ItemSearch/Result';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { searchCASReporter } from 'Workspace/TelemetryEvents/search/createSearchCASReporter';
import { withUserContext, WithUserContextProps } from 'Workspace/UserContext';
import { styles } from './ItemSearch.styles';

export type Props = RouteComponentProps &
	WithResourceContextProps &
	ModalProps<string> &
	WithUserContextProps & {
		search?: string;
		searchFilterOption?: string;
	};

interface State {
	resources: Result[];
	input?: string;
	searchFilterOption?: SearchFilterOption | string;
}

class _ItemSearchModal extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			resources: [],
			input: this.getSearchTerm(),
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.location?.pathname !== prevProps.location?.pathname &&
			!this.isSearchRoute()
		) {
			this.props.onCloseSuccess(null); // no route change, just dismiss modal
		}
	}

	private isSearchRoute() {
		return !!matchPath(this.props.location?.pathname, searchRoute.paths[0]);
	}

	public render() {
		const isScopedTo = (option: SearchFilterOption | string) =>
			this.getSearchFilterOption() === option;
		const isScopedToAll =
			isScopedTo(SearchFilterOption.All) || !this.getSearchFilterOption();
		const isScopedToAllOr = (option: SearchFilterOption | string) =>
			isScopedToAll || isScopedTo(option);
		const shouldSearchApps =
			this.supportsApps() && isScopedToAllOr(SearchFilterOption.Applications);
		const shouldSearchDesktops =
			this.supportsDesktops() && isScopedToAllOr(SearchFilterOption.Desktops);
		return (
			<Modal
				{...this.props}
				className={css(styles.modal)}
				// Modal autofocus can be disabled to allow the input to be autofocused because the input is the modal's only content when it's opened.
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={false}
				overlayClassName={css(styles.whiteOverlay)}
				isRouted
			>
				<Downshift
					onSelect={this.onSelect}
					onInputValueChange={this.search}
					inputValue={this.state.input}
					itemToString={_item => this.state.input}
					defaultIsOpen
				>
					{({
						getInputProps,
						getItemProps,
						getMenuProps,
						getLabelProps,
						highlightedIndex,
						isOpen,
					}) => {
						const isReadyToSearch = isOpen && this.state.input.trim().length > 2;
						return (
							<div className={css(styles.container)}>
								<div className={css(styles.inputBox, styles.inputBoxOpen)}>
									{/* Downshift will assign the appropriate htmlFor label associated with the input below. */}
									{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
									<label {...getLabelProps({})}>
										<SearchIcon size={20} className={css(styles.searchIcon)} />
									</label>
									<input
										{...getInputProps({
											className: css(styles.textInput),
											autoFocus: true,
											onKeyDown: (e: React.KeyboardEvent<HTMLElement>) =>
												this.onInputKeyDown(e, highlightedIndex),
										})}
									/>
								</div>
								<div className={css(styles.results, styles.resultsOpen)}>
									<ul {...getMenuProps({ className: css(styles.ul) })}>
										{isReadyToSearch && (shouldSearchApps || shouldSearchDesktops) && (
											<ResourceSearch
												getItemProps={getItemProps}
												highlightedIndex={highlightedIndex}
												input={this.state.input}
												resources={this.state.resources}
												setResources={resources => this.setState({ resources })}
												shouldSearchApps={shouldSearchApps}
												shouldSearchDesktops={shouldSearchDesktops}
											/>
										)}
									</ul>
									{isReadyToSearch && (
										<Button.Wrapper
											onClick={() => this.seeMore(this.getSearchFilterOption())}
											className={css(styles.seeMoreButton)}
										>
											<div className={css(styles.seeMore)}>
												{t('javascript:search.see_more')}
											</div>
										</Button.Wrapper>
									)}
								</div>
							</div>
						);
					}}
				</Downshift>
			</Modal>
		);
	}

	private supportsApps() {
		return this.props.resourceContext.resources.some(r => !r.isdesktop);
	}

	private supportsDesktops() {
		return this.props.resourceContext.resources.some(r => r.isdesktop);
	}

	private onInputKeyDown = (
		e: React.KeyboardEvent<HTMLElement>,
		highlightedIndex: number
	) => {
		if (e.key === 'Enter' && highlightedIndex === null) {
			this.seeMore(this.getSearchFilterOption());
		}
	};

	private publishSearchKeyInCasEvent = () => {
		trackAnalyticsEvent(searchCASReporter.getSearchKeyInEvent());
	};

	private debouncedPublishSearchKeyInCasEvent = _.debounce(
		this.publishSearchKeyInCasEvent,
		500
	);

	private search = (input: string) => {
		if (input.length > 0 && this.state.input !== input) {
			this.debouncedPublishSearchKeyInCasEvent();
		}
		this.setState({
			input:
				scrubPercentSymbolsFromParametersThatWillBeEncodedBecauseHistoryLess(input) || '',
		});
	};

	private seeMore = (
		searchFilterOption: SearchFilterOption | string = SearchFilterOption.All
	) => {
		if (this.state.input) {
			this.executeASearch(this.state.input, searchFilterOption);
		}
	};

	// FIXME this is dangerous as a method. Should be a wrapper of some sort. we don't need access to this component's state so it shouldn't be in here. we already almost hit a bug with this.
	private executeASearch(query: string, scope: string) {
		const encodedQuery = encodeURIComponent(
			scrubPercentSymbolsFromParametersThatWillBeEncodedBecauseHistoryLess(query)
		);

		this.props.onCloseSuccess(
			searchRoute.getUrl({
				search: encodedQuery,
				searchFilterOption: scope,
			})
		);
	}

	private onSelect = (result?: Result) => {
		trackAnalyticsEvent(searchCASReporter.getSearchSelectEvent());
		result?.onClick();
	};

	private getSearchRouteParams() {
		const match = matchPath(this.props.location?.pathname, searchRoute.paths[0]);
		const searchParams: { search?: string; searchFilterOption?: string } =
			match?.params || {};
		return searchParams;
	}

	private getSearchFilterOption(): SearchFilterOption | string {
		return this.getSearchRouteParams()?.searchFilterOption;
	}

	private getSearchTerm(): string {
		const searchParams = this.getSearchRouteParams();
		const searchTerm = searchParams?.search
			? decodeURIComponent(searchParams?.search)
			: '';
		return scrubPercentSymbolsFromParametersThatWillBeEncodedBecauseHistoryLess(
			searchTerm
		);
	}
}

/**
 * A longstanding bug in react router's associated history library causes some weird encoding/decoding of percent signs.
 * We can't upgrade this at the moment so we're working around it by removing percent signs.
 * https://github.com/remix-run/history/issues/505
 * @param searchTerm The search term to scrub.
 * @returns The searchTerm with percent symbol scrubbed
 */
function scrubPercentSymbolsFromParametersThatWillBeEncodedBecauseHistoryLess(s: string) {
	return s?.replace?.call ? s.replace(/%/g, '') : s;
}

export const ItemSearchModal = withRouter(
	withUserContext(withResourceContext(_ItemSearchModal))
);
