import * as React from 'react';
import {
	isPrecacheValidationResult,
	MessageTypes,
	PrecacheValidationRequestMessage,
} from '@citrite/workspace-ui-serviceworker';
import { trackEvent } from 'analytics';

export function useServiceWorkerCacheValidation(
	enabled: boolean,
	expectedScriptURL: string,
	unregisterServiceWorker: () => void
) {
	const [controller, updateController] = React.useState<ServiceWorker>(
		navigator.serviceWorker?.controller
	);
	const [serviceWorkerState, updateServiceWorkerState] = React.useState<string>(
		navigator.serviceWorker?.controller?.state
	);

	React.useMemo(() => {
		if (!enabled || !controller) {
			return;
		}
		const shouldCheckCache =
			controller.scriptURL === expectedScriptURL && serviceWorkerState === 'activated';
		if (shouldCheckCache) {
			const message: PrecacheValidationRequestMessage = {
				type: MessageTypes.PrecacheValidationRequest,
			};
			navigator.serviceWorker.controller.postMessage(message);
		}
	}, [enabled, controller, expectedScriptURL, serviceWorkerState]);

	React.useEffect(() => {
		if (!enabled || !controller) {
			return () => {};
		}
		const onStateChange = (event: Event) => {
			updateServiceWorkerState((event.target as ServiceWorker).state);
		};
		controller.addEventListener('statechange', onStateChange);
		return () => controller.removeEventListener('statechange', onStateChange);
	});

	React.useEffect(() => {
		if (!enabled || !controller) {
			return () => {};
		}

		const onMessage = (event: MessageEvent<any>) => {
			if (controller.state !== 'activated') {
				return;
			}
			if (location.origin !== event.origin) {
				return;
			}
			if (!isPrecacheValidationResult(event)) {
				return;
			}

			if (event.data.valid) {
				trackEvent('ServiceWorker - cache validation succeeded');
			} else {
				trackEvent('ServiceWorker - cache validation failed', {
					missingUrlCount: event.data.missingUrls?.length,
					firstMissingUrls: event.data.missingUrls?.slice(0, 5).join(', '),
				});

				// Since the cache is in a bad state, unregister the service worker to
				// trigger a re-install and refresh of cache entries on the next page load
				unregisterServiceWorker();
			}
		};
		navigator.serviceWorker.addEventListener('message', onMessage);
		return () => navigator.serviceWorker.removeEventListener('message', onMessage);
	}, [enabled, controller, unregisterServiceWorker]);

	React.useEffect(() => {
		if (!enabled || !navigator.serviceWorker) {
			return () => {};
		}
		const onControllerUpdate = () => {
			updateController(navigator.serviceWorker.controller);
			updateServiceWorkerState(navigator.serviceWorker.controller?.state);
		};
		navigator.serviceWorker.addEventListener('controllerchange', onControllerUpdate);
		return () =>
			navigator.serviceWorker.removeEventListener('controllerchange', onControllerUpdate);
	}, [enabled]);
}
