import { NativePlatform } from '@citrite/workspace-ui-platform';
import { getUrlParameter } from 'Environment/setup/getUrlParameter';
import { installNativeSimulation as installNativeSimulationTools } from 'Environment/setup/nativeDetection.devtools';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';

interface DetectedPlatform {
	isNative: boolean;
	nativePlatform: NativePlatform;
}

const SESSION_PLATFORM_KEY = 'SESSION_PLATFORM_KEY';

function getPlatform(params: string): NativePlatform {
	if (!params) {
		return undefined;
	}

	if (params.includes(NativePlatform.Windows)) {
		return NativePlatform.Windows;
	}

	if (params.includes(NativePlatform.Android)) {
		return NativePlatform.Android;
	}

	if (params.includes(NativePlatform.iOS)) {
		return NativePlatform.iOS;
	}

	if (params.includes(NativePlatform.Linux)) {
		return NativePlatform.Linux;
	}

	if (params.includes(NativePlatform.Mac)) {
		return NativePlatform.Mac;
	}

	if (!IS_RELEASE && params.includes(NativePlatform.Simulated)) {
		return NativePlatform.Simulated;
	}

	return undefined;
}

export function detectNativePlatform() {
	// When window.open is used to go to another URL in the bundle (i.e. - edit file route)
	// then window.opener may be set and browsercontext / session storage is shared between windows.
	// However, CWA does not properly bind the bridge to other opened windows and environment.build
	// hangs indefinitely.
	const isAppEntryWindow = !window.opener;
	const sessionValue = getFromSessionStorage<DetectedPlatform>(SESSION_PLATFORM_KEY);
	if (isAppEntryWindow && sessionValue) {
		return sessionValue;
	}
	const nativeParams = getUrlParameter('native');
	const value: DetectedPlatform = {
		isNative: !!nativeParams,
		nativePlatform: getPlatform(nativeParams),
	};
	if (isAppEntryWindow) {
		setInSessionStorage(SESSION_PLATFORM_KEY, value);
	}
	return value;
}

if (!IS_RELEASE) {
	installNativeSimulationTools(SESSION_PLATFORM_KEY);
}
