import React from 'react';
import {
	Button,
	LoadingIconPrimary,
	palette,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { t } from 'i18next';
import {
	CardDivStyle,
	StyledAlertAction,
	StyledAlertContent,
	StyledAlertSection,
	StyledStatus,
	StyledStatusText,
} from 'App/ProgressAlert/LaunchProgressAlert.styles';
import { ResourceIcon } from 'Components/ResourceIcon';
import { LaunchStatus } from 'Environment/launchResource/launchResource';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

type CardProps = {
	isLast: Boolean;
};
const StyledCard = styled.div<CardProps>`
	${CardDivStyle}
	height: 64px;
	border-bottom: ${props => (props.isLast ? '' : `1px solid ${palette.grey200}`)};
`;
const StyledTitle = styled.span`
	font-size: ${typography.sizes.base};
	line-height: 18px;
	font-weight: ${typography.weights.medium};
`;
const StyledAlertStatus = styled(StyledStatus)`
	margin-top: 12px;
`;
const StyledLoadingIconPrimary = styled(LoadingIconPrimary)`
	margin-right: 9px;
`;
interface Props {
	status: LaunchStatus;
	isLast: Boolean;
	operationId: string;
	resource: Resource;
	onCancel: (operationId: string) => void;
}
export function ResourceAlert({
	status,
	isLast,
	operationId,
	onCancel,
	resource,
}: Props) {
	return (
		!!resource && (
			<StyledCard data-testid={'resource-alert' + resource.name} isLast={isLast}>
				<StyledAlertContent>
					<ResourceIcon resource={resource} size={27} />
					<StyledAlertSection>
						<StyledTitle>{resource.name}</StyledTitle>
						<StyledAlertStatus>
							<StyledLoadingIconPrimary size={15} />
							<StyledStatusText>
								{t(`Workspace:launch_status.${status}`)}
							</StyledStatusText>
						</StyledAlertStatus>
					</StyledAlertSection>
				</StyledAlertContent>
				<StyledAlertAction>
					<Button.SubtlePrimary
						disabled={
							status === LaunchStatus.PerformingLaunch ||
							status === LaunchStatus.Cancelling
						}
						onClick={() => onCancel(operationId)}
					>
						{t('Workspace:cancel')}
					</Button.SubtlePrimary>
				</StyledAlertAction>
			</StyledCard>
		)
	);
}
