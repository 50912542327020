import { t } from '@citrite/translate';
import { ModalAlertProps, warningConfirmModal } from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import { WorkspaceWebExtensionPromptValues } from 'App/Tile/useBrowserExtensionPrompt';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { browserExtensionInstallPromptAnalyticsReporter } from 'Workspace/TelemetryEvents/browserExtensionInstallPrompt/createBrowserExtensionInstallPromptAnalyticsReporter';
import { checkBrowserExtensionInstallation } from './browserExtensionPollHelper';

export const showInstallExtensionModal = (
	resumeResourceLaunch: Function,
	workspaceWebExtensionPrompt: string
) => {
	const modalProps: ModalAlertProps = getPropsForModal(
		resumeResourceLaunch,
		workspaceWebExtensionPrompt
	);
	return warningConfirmModal(modalProps);
};

// allow-unused-export : export needed for unit tests
export const getPropsForModal = (
	resumeResourceLaunch: Function,
	workspaceWebExtensionPrompt: string
): ModalAlertProps => {
	const { startCheck, endCheck } = checkBrowserExtensionInstallation();
	let extensionInstallPromiseResolver: Promise<boolean>;
	const modalProps: ModalAlertProps = {
		title: t('Workspace:browserExtensionInstallPrompt.title'),
		confirmButtonText: t('Workspace:browserExtensionInstallPrompt.installButton'),
		message: t('Workspace:browserExtensionInstallPrompt.message'),
		beforeCloseSuccess: () => {
			extensionInstallPromiseResolver = startCheck();
			return extensionInstallPromiseResolver;
		},
		onCloseSuccess: () => {
			trackInstallEvent();
			extensionInstallPromiseResolver.then(result => {
				endCheck();
				if (result) {
					resumeResourceLaunch();
				}
			});
		}, // also called when do not show again is checked
		onDismiss: endCheck,
		enableSecondaryButtonWhileLoading: true,
	};
	if (workspaceWebExtensionPrompt === WorkspaceWebExtensionPromptValues.optional) {
		// do not show again checkbox is only shown for Optional
		modalProps.onDismiss = () => {
			endCheck();
			trackCancelEvent();
			resumeResourceLaunch();
		}; // continues to launch the resource is the user clicks cancel in case of Optional
		modalProps.dontShowAgainKey = 'dontShowAgainBrowserExtensionInstallPrompt'; // sets this dont show again key in the local storage to true when the user checks the checkbox
	}
	return modalProps;
};

const shouldNotShowPromptAgain = () => {
	return !!getFromLocalStorage('dontShowAgainBrowserExtensionInstallPrompt');
};

const trackInstallEvent = () => {
	trackAnalyticsEvent(
		browserExtensionInstallPromptAnalyticsReporter.getBrowserExtensionInstallPromptInstallEvent(
			shouldNotShowPromptAgain()
		)
	);
};

const trackCancelEvent = () => {
	trackAnalyticsEvent(
		browserExtensionInstallPromptAnalyticsReporter.getBrowserExtensionInstallPromptCancelledEvent(
			shouldNotShowPromptAgain()
		)
	);
};
