import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { EventSubscriber, WithEventBus } from 'Components/EventBus';
import {
	ExecuteFeedCardActionEvent,
	FeedCardEventType,
} from 'userNotifications/EventSubscribers/EventTypes';
import { Event, EventBus } from 'Workspace/EventBus';

function isActionExecuteEventForDesiredSourceOrigin(
	event: Event<any>,
	sourceOrigin: string
): event is ExecuteFeedCardActionEvent {
	return (
		event.type === FeedCardEventType.EXECUTE_FEED_CARD_ACTION &&
		event.payload.sourceOrigin === sourceOrigin
	);
}

interface InternalProps extends Props {
	eventBus: EventBus;
}
class _ExecuteFeedCardActionEventSubscriber extends React.Component<InternalProps> {
	public render() {
		return (
			<EventSubscriber
				eventBusProvider={this.props.EventBusProvider}
				listener={this.handleEvent}
			/>
		);
	}

	private handleEvent = async (event: Event<any>) => {
		if (isActionExecuteEventForDesiredSourceOrigin(event, this.props.sourceOrigin)) {
			const success = await this.props
				.execute(event)
				.then(() => true)
				.catch(() => false);
			this.props.eventBus.dispatch({
				id: uuid(),
				payload: {
					sourceEvent: event,
					...(!success && {
						error: {
							code: 1,
						},
					}),
				},
				type: FeedCardEventType.FEED_CARD_ACTION_EXECUTED,
			});
		}
	};
}
type EventBusProviderType = React.ComponentType<{
	children: (eventBus: EventBus) => React.ReactNode;
}>;

export interface Props {
	sourceOrigin: string;
	execute: (event: ExecuteFeedCardActionEvent) => Promise<void>;
	EventBusProvider?: EventBusProviderType;
}
export function ExecuteFeedCardActionEventSubscriber({
	EventBusProvider,
	...otherProps
}: Props) {
	EventBusProvider = EventBusProvider || WithEventBus;
	return (
		<EventBusProvider>
			{eventBus => (
				<_ExecuteFeedCardActionEventSubscriber
					{...otherProps}
					eventBus={eventBus}
					EventBusProvider={EventBusProvider}
				/>
			)}
		</EventBusProvider>
	);
}
