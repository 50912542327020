import { AnalyticsEvent, Metadata } from 'analytics';
import { GeneralEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_GENERAL';

const preparePayload = (type: GeneralEventType, metadata?: Metadata): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});

export const GeneralUserEventPayload = {
	saveProfile: (): AnalyticsEvent => preparePayload(GeneralEventType.EditProfile),
	saveRegionalSettings: (): AnalyticsEvent =>
		preparePayload(GeneralEventType.RegionalSettings),
	screenSwitch: (from: string, to: string): AnalyticsEvent =>
		preparePayload(GeneralEventType.ScreenSwitch, { from, to }),
};
