import * as React from 'react';
import { BulkDismissType, FeedCard, feedSort } from 'userNotifications/FeedCard';

export interface ContextState {
	sort: feedSort;
	feedLoading: boolean;
	pageLoading: boolean;
	hasNewFeedCards: boolean;
	hasError: boolean;
}

export interface Context extends ContextState {
	selectedFeedCardId: string;
	feedCards: FeedCard<any>[];
	newFeedCards: FeedCard<any>[];
	refreshFeed(): Promise<void>;
	getNextPage(): void;
	changeSort(sort: feedSort): void;
	dismissFeedCard(id: string): void;
	bulkDismissFeedCards(type: BulkDismissType, feedCard: FeedCard): Promise<void>;
	removeFeedCard(id: string): void;
	restoreFeedCard(id: FeedCard): void;
	showNewCards(): void;
	clearHighlightedCards(): void;
}

export const NotificationContext = React.createContext<Context>(null);
