import { LocalApp } from 'Environment/nativeFunctions';
import {
	LocalAppResource,
	localAppResourceType,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

export function localAppToResource(
	localApp: LocalApp,
	isWhitelistedApp = false
): LocalAppResource {
	return {
		id: encodeURI(localApp.shortcutPath),
		iconurl: `data:image/jpeg;base64,${localApp.icon}`,
		name: localApp.name,
		launchurl: localApp.shortcutPath,
		type: localAppResourceType,
		subscriptionurl: 'LocalAppSubscribeURL',
		subscriptionstatus: subscriptionStatus.unsubscribed,
		disabled: false,
		clienttypes: [],
		whitelisted: isWhitelistedApp,
	};
}
