import { logError } from 'remoteLogging';
import { handleBrowserExtensionResponse } from 'Environment/BrowserExtension/BrowserExtensionChromeTransport';
import { createBrowserExtensionMessage } from 'Environment/BrowserExtension/browserExtensionMessageHelper';
import { ShieldBrowserExtensionMessageTransport } from 'Environment/BrowserExtension/ShieldBrowserExtensionMessageTransport';
import {
	BrowserExtensionMessage,
	BrowserExtensionMessageType,
	BrowserExtensionResponse,
} from 'Environment/BrowserExtension/types';
import { isFirefox } from 'Environment/launchResource/device';
import {
	aadSSOEventHandler,
	AADSSOEventNotification,
} from 'Environment/msal/aadEventNotificationHandler';
import { setAuthorizationCode } from 'Environment/msal/hybridMsalAuth';

const replyHandlers = {};

class BrowserExtensionContentScriptTransport
	implements ShieldBrowserExtensionMessageTransport
{
	public sendMessageToBrowserExtension = (
		workload: any,
		type: BrowserExtensionMessageType
	) => {
		const message: BrowserExtensionMessage = createBrowserExtensionMessage(
			workload,
			type
		);
		return new Promise<BrowserExtensionResponse>((resolve, reject) => {
			sendMessageToContentScript(message, async (payload: BrowserExtensionResponse) => {
				try {
					const response = await handleBrowserExtensionResponse(
						payload,
						message,
						this.sendMessageToBrowserExtension
					);
					resolve(response);
				} catch (error) {
					reject(error);
				}
			});
		});
	};
}

function sendMessageToContentScript(
	message: BrowserExtensionMessage,
	callback: (payload: BrowserExtensionResponse) => void
) {
	const { correlationId } = message.payload;
	if (!correlationId) {
		const error = new Error('No correlation id for content script reply handler.');
		logError(error, {
			additionalContext: {
				error,
			},
			tags: {
				feature: 'browserExtension',
			},
		});
		throw error;
	}
	replyHandlers[correlationId] = callback;
	const contentScriptEvent = new CustomEvent<any>('extensionCommunication', {
		detail: message,
	});
	document.dispatchEvent(contentScriptEvent);
}

export function configureContentScriptTransportListener() {
	document.addEventListener('workspaceCommunication', function (e: CustomEventInit) {
		const parsedObj = isFirefox() ? JSON.parse(e?.detail) : e?.detail;
		const correlationId = parsedObj?.correlationId;
		if (!correlationId || !replyHandlers[correlationId]) {
			const error = new Error('No correlation id received from content script message.');
			logError(error, {
				additionalContext: {
					error,
				},
				tags: {
					feature: 'browserExtension',
				},
			});
			return;
		}
		replyHandlers[correlationId](parsedObj);
		delete replyHandlers[correlationId];
	});
}

/*
	 The Native is sending the resource id with some prefix. we need to remove that
	 prefix before adding to the map. sample prefix.

	 resourceId: "c24eae41-14c3-48ff-aa35-8ec92ebe065f.daphnis3 $A4-4-2BDAFF84-0001"
*/
function extractResourceUUID(str: string): string {
	str = str || '';
	const dotIndex = str.indexOf('.');
	if (dotIndex !== -1) {
		return str.slice(dotIndex + 1);
	}

	return str;
}

export function subscribeToAADEventsFromBrowserExtension() {
	document.addEventListener(
		'authCodeFromBrowserExtension',
		function (e: CustomEventInit) {
			const authCode: string = e?.detail?.result;
			//TODO: Compare with the entire redirect uri prefix first.
			if (authCode?.includes('#code=') || authCode?.includes('#error=')) {
				setAuthorizationCode({ authcode: authCode });
			}
		}
	);

	document.addEventListener(
		'AzureADEventReadyForSSOnInfo',
		function (e: CustomEventInit) {
			const aadSSOEvent: AADSSOEventNotification = e?.detail?.result;
			aadSSOEvent.resourceId = extractResourceUUID(aadSSOEvent?.resourceId);
			aadSSOEventHandler.setEventForResource(aadSSOEvent);
		}
	);
}

export const sendMessageViaContentScript = new BrowserExtensionContentScriptTransport()
	.sendMessageToBrowserExtension;
