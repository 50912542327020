import * as React from 'react';
import { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { numberOfTruncatedResultsToShow } from 'App/Search/Results/constants';
import { ViewAllOrLessButton } from 'App/Search/Results/ViewAllOrLessButton';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { ResourceResultItem } from './ResourceResultItem';
import { styles as parentStyles } from './Results.styles';

const styles = StyleSheet.create({
	resultsContent: {
		backgroundColor: 'white',
		margin: 'auto',
		display: 'block',
	},
});

export interface Props {
	title: string;
	results: Resource[];
	noResultsText: string;
	showStatus: boolean;
	isUnderAllSection: boolean;
}

export function ResourceResults(props: Props) {
	const [showAllResults, setShowAllResults] = useState(false);
	const ViewAllResults = () => {
		setShowAllResults(!showAllResults);
	};

	return (
		<div className={css(styles.resultsContent)}>
			{props.showStatus && (
				<div className={css(parentStyles.resultsHeader)}>
					{props.title}
					{props.isUnderAllSection && (
						<ViewAllOrLessButton
							showAllResult={showAllResults}
							resultsCount={props.results.length}
							onclickEvent={ViewAllResults}
						/>
					)}
				</div>
			)}
			{props.showStatus && !props.results.length && (
				<div className={css(parentStyles.status, parentStyles.statusEmpty)}>
					{props.noResultsText}
				</div>
			)}
			{getResourceResults(props, showAllResults).map(result => (
				<ResourceResultItem key={result.id} result={result} />
			))}
		</div>
	);
}

function getResourceResults(props: Props, showAllResult: boolean) {
	let results = props.results;
	if (props.isUnderAllSection && !showAllResult) {
		results = results.slice(0, numberOfTruncatedResultsToShow);
	}
	return results;
}
