import { post } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { launchResource } from 'Environment/launchResource';
import { isCitrixChromeApp } from 'Environment/launchResource/device';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

let chromeAppWindow: Window = null;
let chromeAppOrigin: string = null;
const fullDetails = 'Full';
let shieldEnabled = false;

export function initializeChromeApp() {
	return new Promise<void>(resolve => {
		if (isCitrixChromeApp()) {
			window.addEventListener(
				'message',
				event => {
					if (event.data.data === 'offlineMode') {
						environment.setCitrixCloudConnectedStatus(false);
						return;
					}
					if (event.data.messageType === 'setCapabilities') {
						shieldEnabled = event.data.status === 'successful';
						return;
					}
					if (event.data !== 'ICA') {
						return;
					}
					chromeAppWindow = event.source as Window;
					chromeAppOrigin = event.origin;
					postMessageToChromeApp({ type: 'HideAccountSettings' });
					resolve();
				},
				false
			);
		} else {
			resolve();
		}
	});
}

export function shieldToggleFromChromeapp() {
	return shieldEnabled;
}

export function addChromeAppListener(
	getWorkspaceConfiguration: () => WorkspaceConfiguration,
	getResourceContext: () => ResourceContext
) {
	if (isCitrixChromeApp()) {
		window.addEventListener(
			'message',
			event => {
				const { data, origin } = event;
				const fromExpectedOrigin = chromeAppOrigin === origin;
				if (!fromExpectedOrigin || !data || !data.type) {
					return;
				}
				receiveMessageFromChromeApp(
					data,
					getWorkspaceConfiguration(),
					getResourceContext()
				);
			},
			false
		);
	}
}

export interface ChromeAppLaunchOptions {
	/** This will be set when WSUI is instructed by the Chrome App to launch a resource */
	uniqueIdentifier: string;
}

function receiveMessageFromChromeApp(
	data: any,
	workspaceConfiguration: WorkspaceConfiguration,
	resourceContext: ResourceContext
) {
	if (data.type === 'Settings') {
		postSettingsToChromeApp(workspaceConfiguration);
	} else if (data.type === 'LaunchApp') {
		const resource = resourceContext.resources.find(r => r.id === data.appID);
		const chromeAppOptions: ChromeAppLaunchOptions = {
			uniqueIdentifier: data.uniqueIdentifier,
		};
		launchResource({
			resource,
			workspaceConfiguration,
			resourceContext,
			launchOptions: {
				isAutoLaunch: false,
				chromeAppOptions,
			},
		});
	} else if (data.type === 'Resources') {
		chromeAppRequestResources(workspaceConfiguration.storeProxy.resourcesProxy.listURL);
	}
}

export function postICADataErrorToChromeApp(
	errorMsg: string,
	resource: Resource,
	uniqueIdentifier: any
) {
	postMessageToChromeApp({
		type: 'ICAData',
		appID: resource.id,
		uniqueIdentifier,
		error: errorMsg,
	});
}

export function postICADataToChromeApp(
	icaData: any,
	resource: Resource,
	chromeAppOptions: ChromeAppLaunchOptions
) {
	if (chromeAppOptions) {
		postMessageToChromeApp({
			type: 'ICAData',
			appID: resource.id,
			uniqueIdentifier: chromeAppOptions.uniqueIdentifier,
			ICAFile: icaData,
		});
	} else {
		postMessageToChromeApp(icaData);
	}
}

export function postMessageToChromeApp(data: any) {
	if (chromeAppWindow && chromeAppOrigin) {
		chromeAppWindow.postMessage(data, chromeAppOrigin);
	}
}

function postSettingsToChromeApp(workspaceConfiguration: WorkspaceConfiguration) {
	postMessageToChromeApp({
		type: 'ChromeAppPreferences',
		data: workspaceConfiguration.pluginAssistant.html5.chromeAppPreferences,
	});
}

function chromeAppRequestResources(url: string) {
	const resourcesData = {
		format: 'json',
		resourceDetails: fullDetails,
	};

	post<any>(url, resourcesData)
		.then(data => {
			postResourcesToChromeApp(data.resources);
		})
		.catch(() => {
			postResourcesErrorToChromeApp('ResourcesFailed');
		});
}

export function postResourcesToChromeApp(resources: any) {
	postMessageToChromeApp({ type: 'ResourceData', resources });
}

function postResourcesErrorToChromeApp(error: string) {
	postMessageToChromeApp({ type: 'ResourceData', error });
}

export function isTrusted(config: WorkspaceConfiguration) {
	if (!isCitrixChromeApp()) {
		return true;
	}
	const origins = (config.pluginAssistant.html5.chromeAppOrigins || '').split('|');
	return origins.includes(chromeAppOrigin);
}
