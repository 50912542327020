import * as React from 'react';
import { IntegrationCapability } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { addNativeLogoutCallback } from 'Environment/callbacks';
import { useIntegrations } from 'Integrations/useIntegrations';

let removeLastCallback: Function = null;
export function useNativeLogoutBehavior(): void {
	const integrations = useIntegrations();
	const runOnce = () => {
		if (removeLastCallback) {
			removeLastCallback();
		}
		removeLastCallback = addNativeLogoutCallback(async () => {
			const logoutCapabilities = integrations.resolveByCapability(
				IntegrationCapability.logout
			);
			const ctxMfeLogouts = logoutCapabilities.map(async ({ resolver }) => {
				try {
					const service = await resolver;
					await service.logout();
				} catch (e) {
					logError(e, {
						customMessage:
							'Could not complete a logout on native platform. Check for Service failures',
						additionalContext: {
							capabilities: logoutCapabilities.map(c => c.registration.moduleKey),
						},
					});
				}
			});
			await Promise.all(ctxMfeLogouts);
		});
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps -- this effect should only be run once and shouldn't respond to integration updates
	React.useEffect(runOnce, []);
}
