import { v4 as uuidV4 } from 'uuid';
const knownWindowsById: Record<string, Window> = {};

export interface OpenWindowOptions {
	width: number;
	height: number;
	scrollbars?: boolean;
}

export function trackBrowserWindow(window: Window): string {
	const id = uuidV4();
	knownWindowsById[id] = window;
	return id;
}

export function getBrowserWindowById(id: string): Window {
	return knownWindowsById[id];
}

export function formatPopupOptions(options: OpenWindowOptions) {
	const { width: windowWidth, height: windowHeight } = window.screen;
	const left = windowWidth / 2 - options.width / 2;
	const top = windowHeight / 2 - options.height / 2;
	return `width=${options.width}, height=${
		options.height
	}, left=${left}, top=${top}, scrollbars=${options.scrollbars ? 1 : 0}`;
}

export function defaultPopupOptions(): OpenWindowOptions {
	return {
		height: Math.round(window.innerHeight * 0.9),
		width: Math.round(window.innerWidth * 0.9),
	};
}

export function untrackBrowserWindowById(id: string): void {
	delete knownWindowsById[id];
}
