import * as React from 'react';
import { css } from 'aphrodite';
import { media, useBreakpoints } from '@citrite/web-ui-component';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { ActivityManagerLauncher } from 'App/Activity/ActivityManagerLauncher/ActivityManagerLauncher';
import { isSingleDesktopOnlyHomepage } from 'App/isDesktopOnlyHomepage';
import {
	Masthead as MastheadLayout,
	MastheadSearchBar,
	MastheadUserMenu,
	OfflineBanner,
} from 'Components/AppLayout';
import { MastheadBanner } from 'Components/MastheadBanner';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isNativeWidget } from 'Environment/launchResource/device';
import { MastheadState } from 'Router/ReactRoute';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';
import { IconsRow } from './IconsRow';
import { ItemSearchBar } from './ItemSearchBar';
import { styles } from './Masthead.styles';
import { SkipNav } from './SkipNav';

export type Props = MastheadState;

const StyledDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export function Masthead(props: Props) {
	const { workspaceConfiguration } = useConfigurationContext();

	const resourceContext = useLoadableResourceContext().value;
	const isOnlySingleDesktopAccount = isSingleDesktopOnlyHomepage(
		workspaceConfiguration,
		useUserContext().isIWSEnabled,
		resourceContext
	);
	const hasSearch = Boolean(
		resourceContext.resources && !!resourceContext.resources.length
	);
	const breakpoints = useBreakpoints();
	const hasSimplifiedNav = hasFeatureCanary(
		workspaceConfiguration,
		FeatureFlag.SimplifiedNav
	);
	const hasSearchBar =
		hasSearch &&
		!isOnlySingleDesktopAccount &&
		((!hasSimplifiedNav && !breakpoints.screenSize.onMobile) ||
			(hasSimplifiedNav && breakpoints.screenSize.media === media.none));

	return (
		<MastheadLayout>
			{!environment.citrixCloudConnected && <OfflineBanner />}
			{!environment.isNative && <SkipNav />}
			{!isNativeWidget() && (
				<MastheadBanner collapseOnMobile>
					{hasSearchBar && (
						<MastheadSearchBar>
							<ItemSearchBar />
						</MastheadSearchBar>
					)}
					<StyledDiv>
						<ActivityManagerLauncher />
						<MastheadUserMenu>
							<IconsRow
								hasSearch={hasSearch && !isOnlySingleDesktopAccount}
								hasUserMenu
							/>
						</MastheadUserMenu>
					</StyledDiv>
					{breakpoints.screenSize.onMobile && (
						<div className={css(styles.mobileTitle)}>
							<StyledSpan>{props.mobileMastheadTitle}</StyledSpan>
						</div>
					)}
				</MastheadBanner>
			)}
		</MastheadLayout>
	);
}

const StyledSpan = styled.span({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
