import React from 'react';
import { useBreakpoints } from '@citrite/web-ui-component';
import { ItemListSkeleton, MainContent } from '@citrite/workspace-ui-platform-react';
import {
	mobileHomePage,
	mobileInfiniteFeedPage,
	mobileLauncherPage,
} from 'App/ComposableHome/ComposablePage/defaultComposablePages';
import { useDefaultComposablePage } from 'App/ComposableHome/useDefaultComposablePage';
import { createWidgetReactKeyGenerator } from 'App/ComposableHome/utilities/widgetReactKeyGenerator';
import { useWidgetCatalog, WidgetCatalog } from 'App/Widgets/useWidgetCatalog';
import { WidgetWrapper } from 'App/Widgets/WidgetWrapper';
import { performance } from 'Components/performance';
import { environment } from 'Environment';
import { useUserContext } from 'Workspace/UserContext';
import { LayoutGridWidgetMapping, Row as WidgetMappingRow } from './ComposablePage';
import { getMappingWidgets } from './composablePageHelpers';
import {
	StyledColumn,
	StyledMainContainer,
	StyledMaxWidthContainer,
} from './ComposablePageRenderer.styled';
import { baseColumnCount, Row as LayoutRow } from './Layout';

export const ComposablePageRenderer: React.FC = () => {
	const { screenSize } = useBreakpoints();
	const widgetCatalog = useWidgetCatalog();
	const { isIWSEnabled } = useUserContext();
	const defaultComposablePage = useDefaultComposablePage();

	const mobilePage = isIWSEnabled
		? environment.isNative
			? mobileInfiniteFeedPage
			: mobileHomePage
		: mobileLauncherPage;
	const isMobile = screenSize?.onMobile;
	const grid = isMobile ? mobilePage.layout.grid : defaultComposablePage.layout.grid;
	const widgets = isMobile ? mobilePage.widgets : defaultComposablePage.widgets;

	registerPerformanceMarks(widgets, widgetCatalog);

	return (
		<MainContent>
			<StyledMainContainer>
				<StyledMaxWidthContainer>
					{grid.rows.map((row, idx) => (
						<ComposablePageRow
							row={row}
							widgets={widgets.rows[idx]}
							widgetCatalog={widgetCatalog}
							key={`idx-${idx}`}
							data-testid={`row-${idx}`}
						/>
					))}
				</StyledMaxWidthContainer>
			</StyledMainContainer>
		</MainContent>
	);
};

function registerPerformanceMarks(
	widgets: LayoutGridWidgetMapping,
	widgetCatalog: WidgetCatalog
) {
	if (widgetCatalog === null) {
		return;
	}

	getMappingWidgets(widgets).forEach(widgetRef => {
		const widgetResolver = widgetCatalog.getWidget(widgetRef.widgetId)?.loadableWidget;
		if (widgetResolver) {
			performance.registerDashboardWidget(
				widgetResolver.registration.metadata?.performanceKey
			);
		}
	});

	performance.dashboardWidgetRegistrationDone();
}

interface ComposablePageRowProps {
	row: LayoutRow;
	widgets: WidgetMappingRow;
	widgetCatalog: WidgetCatalog;
}

const ComposablePageRow: React.FC<ComposablePageRowProps> = props => {
	const columnWidthPercentages = props.row.columns.map(column =>
		((column.columnSpan / baseColumnCount) * 100).toFixed(2)
	);
	const reactKeyGenerator = createWidgetReactKeyGenerator();
	return (
		<>
			{columnWidthPercentages.map((widthPercentage, columnIndex) => (
				<StyledColumn
					data-testid={`column-${columnIndex}`}
					widthPercentage={widthPercentage}
					isLast={columnIndex === columnWidthPercentages.length - 1}
					key={columnIndex}
				>
					{props.widgets.columns[columnIndex].widgets.map((widgetRef, widgetIndex) => {
						const widget = props.widgetCatalog.getWidget(widgetRef.widgetId);
						if (!widget) {
							return null;
						}
						//multiple instances of the same widgetRef can be in a row, they cannot share the same key
						const key = reactKeyGenerator(widgetRef.widgetId);

						if (widget.loadableWidget?.loading) {
							return (
								<WidgetWrapper widget={widget} key={key}>
									<ItemListSkeleton />
								</WidgetWrapper>
							);
						}
						const WidgetComponent =
							widget.loadableWidget?.value?.Component || widget.Component;
						if (WidgetComponent) {
							return (
								<WidgetWrapper
									data-testid={`widget-${widgetRef.widgetId}-${columnIndex}-${widgetIndex}`}
									widget={widget}
									key={key}
								>
									<WidgetComponent />
								</WidgetWrapper>
							);
						}

						return null;
					})}
				</StyledColumn>
			))}
		</>
	);
};
