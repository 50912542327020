import { getReceiverConfigurationUrl } from 'App/Settings/Workspace/Advanced/receiverConfigurationUrl';
import { parseReceiverConfiguration } from 'Components/parseXML';
import { ReceiverConfiguration } from 'Workspace/SchemeCallHandler/ReceiverConfigurationType';

class ReceiverConfigurationReader {
	private static instance: ReceiverConfigurationReader;
	private configurationMap: Map<string, ReceiverConfiguration> = new Map();

	public constructor() {}

	public static getInstance(): ReceiverConfigurationReader {
		if (!ReceiverConfigurationReader.instance) {
			ReceiverConfigurationReader.instance = new ReceiverConfigurationReader();
		}
		return ReceiverConfigurationReader.instance;
	}

	private async fetchReceiverConfiguration(url: string): Promise<ReceiverConfiguration> {
		try {
			const receiverConfigurationUrl = getReceiverConfigurationUrl(url);
			// Why we have not use http.get() here?
			// This component would be needed in the case of purple UI plugin flow where we do not initiate http as it is unnecessary and it add up to the bundle size.
			const fetchRequest = await fetch(receiverConfigurationUrl);
			const receiverConfigurationString = fetchRequest.ok && (await fetchRequest.text());
			const receiverConfiguration =
				receiverConfigurationString &&
				parseReceiverConfiguration(receiverConfigurationString);
			receiverConfiguration && this.configurationMap.set(url, receiverConfiguration);
			return receiverConfiguration;
		} catch (error) {
			return null;
		}
	}

	public async read(url: string): Promise<ReceiverConfiguration> {
		if (!this.configurationMap.has(url)) {
			await this.fetchReceiverConfiguration(url);
		}
		return this.configurationMap.get(url);
	}
}

export const receiverConfigurationReader = ReceiverConfigurationReader.getInstance();
