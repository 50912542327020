import { isEdge } from 'Environment/launchResource/device';
import { getFromLocalStorage } from 'javascript/sf/Storage';

const browserExtensionIdKey = 'browserExtensionIdKey';
const edgeExtensionId = 'pmdpflpcmcomdkocbehamllbfkdgnalf';

// allow-unused-export : need this export for unit test
export class BrowserExtensionIDHelper {
	private cachedExtensionId: string | null = null;

	//This should NOT be called multiple times to avoid optimization issues
	private readExtensionID(): string {
		//Allow developers to override the extensionId during dev time.
		const developerOverRiddenId = getFromLocalStorage<string>(browserExtensionIdKey);
		if (developerOverRiddenId) {
			return developerOverRiddenId;
		}

		if (isEdge()) {
			return edgeExtensionId;
		}

		return IS_RELEASE
			? 'dbdlmgpfijccjgnnpacnamgdfmljoeee'
			: 'kbbhgenpmopkdoahphngmeemacpobpgf';
	}

	public getID(): string {
		if (!this.cachedExtensionId) {
			this.cachedExtensionId = this.readExtensionID();
		}

		return this.cachedExtensionId;
	}

	public getStoreUrl(): string {
		const extID = this.getID();
		if (isEdge()) {
			return `https://microsoftedge.microsoft.com/addons/detail/citrix-workspace-web-exte/${extID}`;
		}

		return `https://chrome.google.com/webstore/detail/${extID}`;
	}
}

export const bxtnIdHelper = new BrowserExtensionIDHelper();
