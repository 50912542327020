import React from 'react';
import { t } from '@citrite/translate';
import { breakpoints, palette, SearchInput, styleUtils } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledFilterBox = styled.div`
	width: 100%;
	height: 40px;
	${breakpoints.onMediumScreen} {
		width: 100%;
	}
`;

const StyledSearchContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	padding: 0px;
	gap: 8px;
	width: 80%;
	box-shadow: ${styleUtils.shadow2.boxShadow};
	${breakpoints.onMediumScreen} {
		width: 100%;
	}
	input {
		::placeholder {
			color: ${palette.grey700};
			font-family: 'PublicSans', Arial, sans-serif;
			font-style: normal;
			font-size: 14px;
			line-height: 14px;
		}
	}
`;

interface Props {
	input: string;
	setInput: (s: string) => void;
}

export const Search = (props: Props) => {
	return (
		<StyledFilterBox>
			<StyledSearchContent>
				<SearchInput
					placeholder={t('Workspace:ftu_ui.searchbox_placeholder')}
					onChange={props.setInput}
					value={props.input}
					width="100%"
				/>
			</StyledSearchContent>
		</StyledFilterBox>
	);
};
