import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { EventType, NavigateEvent, useEventBus } from 'Components/EventBus';
import { Event } from 'Workspace/EventBus';

export function useNativeNavigationEventHandler() {
	const eventBus = useEventBus();
	const history = useHistory();

	React.useEffect(() => {
		const unsubscribe = eventBus.subscribe((event: Event<any>) => {
			if (isNavigateEvent(event)) {
				history.push(`/nativedeeplink/${event.payload.routeId}`);
			}
		});
		return unsubscribe;
	}, [eventBus, history]);
}

function isNavigateEvent(event: Event<any>): event is NavigateEvent {
	return event.type === EventType.NAVIGATE;
}
