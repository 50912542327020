import React from 'react';
import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { first } from 'lodash';
import { ActivityTitle } from 'App/Activity/ActivityTitle/ActivityTitle';
import { DesktopSectionHeader } from 'App/Activity/components/DesktopSectionHeader';
import { SessionCard } from 'App/Activity/components/SessionCard';
import { Session, SessionType } from 'App/Activity/ResourceManagerContext';

export interface SessionSectionProps {
	name: string;
	sessions: Session[];
}

const StyledTitle = styled(ActivityTitle)`
	display: flex;
	font-size: ${typography.sizes.xsml};
	font-weight: ${typography.weights.semiBold};
	color: ${fromThemeColors('metadataText')};
	margin-top: ${layout.mediumSpace};
	margin-bottom: ${layout.smallSpace};
`;

export const SessionSection = ({ sessions, name }: SessionSectionProps) => {
	const hasSessions = sessions?.some(session => session.applications.length > 0);
	return (
		hasSessions && (
			<>
				<StyledTitle title={name} />
				{first(sessions)?.userSessionType === SessionType.DESKTOP && (
					<DesktopSectionHeader />
				)}
				{sessions.map((session: Session) => {
					return <SessionCard key={session.sessionId} session={session}></SessionCard>;
				})}
			</>
		)
	);
};
