import { resilientPromise } from '@citrite/workspace-ui-platform';
import { v4 as uuid } from 'uuid';
import {
	ChromeAppErrorReason,
	ChromeAppMessage,
	ChromeAppMessageType,
	ChromeAppResponse,
	ChromeAppWrapperMessage,
	ExtensionCommunicationAdapter,
} from 'Environment/ChromeApp/types';

function handleChromeAppResponseForTimeout(
	adapter: ExtensionCommunicationAdapter,
	workload: any,
	type: ChromeAppMessageType,
	timeout: number
) {
	let chromeAppResponseTimeout: ReturnType<typeof setTimeout> = undefined;
	function timeoutPromiseWrapper() {
		return new Promise<ChromeAppResponse>((_resolve, reject) => {
			chromeAppResponseTimeout = setTimeout(() => {
				const error = new Error(`ChromeApp response timeout for ${type}`);
				error.name = ChromeAppErrorReason.TimeOut;
				reject(error);
			}, timeout);
		});
	}

	return Promise.race([adapter(workload, type), timeoutPromiseWrapper()]).finally(() => {
		clearTimeout(chromeAppResponseTimeout);
	});
}

function sendMessageToChromeAppWithTimeOut(
	adapter: ExtensionCommunicationAdapter,
	workload: any,
	type: ChromeAppMessageType,
	timeout: number
) {
	if (timeout) {
		return handleChromeAppResponseForTimeout(adapter, workload, type, timeout);
	}

	return adapter(workload, type);
}

export function sendMessageToChromeAppWrapper(
	adapter: ExtensionCommunicationAdapter,
	message: ChromeAppWrapperMessage
) {
	const { workload, type, timeout, retries = 0 } = message;
	return resilientPromise(
		() => sendMessageToChromeAppWithTimeOut(adapter, workload, type, timeout),
		{ maxAttempts: retries + 1 }
	);
}

export function createChromeAppMessage(workload: any, type: ChromeAppMessageType) {
	const message: ChromeAppMessage = {
		header: {
			type,
		},
		payload: {
			...workload,
			correlationId: uuid(),
		},
	};
	return message;
}

export function isChromeAppError(error: Error): boolean {
	return (
		error &&
		Object.values(ChromeAppErrorReason).includes(error.name as ChromeAppErrorReason)
	);
}
