import * as React from 'react';
import { css } from 'aphrodite';
import { styles } from './EmptyState.styles';

interface Props extends React.HTMLAttributes<HTMLElement> {
	message: React.ReactNode;
}

export function EmptyState(props: Props) {
	return (
		<div className={css(styles.noItems)}>
			<div className={css(styles.emptyIcon)} />
			<div className={css(styles.emptyText)}>{props.message}</div>
		</div>
	);
}
