import { ActivityResource } from 'App/Activity/CommonResourceUtils';
import { environment } from 'Environment';
import { DevicePosture } from './Context';

export interface ListApiResult {
	unauthorized?: boolean;
	isFromCache?: boolean;
	isRecentsEnabled?: boolean;
	isSubscriptionEnabled?: boolean;
	isUnauthenticatedStore?: boolean;
	resources?: Resource[];
	feedInfo?: FeedInfo[];
	bundles?: Bundle[];
	enumerationResult?: 'Failed' | 'Partial' | 'Full';
	disallowICADownload?: boolean;
	devicePosture?: DevicePosture;
}

export interface FeedInfo {
	id: string;
	enumerationResult: 'Successful' | 'Failed';
	product?: string;
}

export interface Bundle {
	appnames?: string[];
	categories?: string[];
	keywords?: string[];
	description?: string;
	tileid?: string;
	title?: string;
}

export enum subscriptionStatus {
	pending = 'pending',
	approved = 'approved',
	denied = 'denied',
	subscribed = 'subscribed',
	unsubscribed = 'unsubscribed',
	silentfailure = 'silentfailure',
}

export enum DesktopAssignmentType {
	Shared = 'shared',
	Assigned = 'assigned',
	AssignOnFirstUse = 'assign-on-first-use',
}

export interface Resource {
	displayNameDesktopTitle?: string; // TODO Need to calculate this
	encodedName?: string; // TODO Need to calculate this
	accessssodata?: string;
	cancellaunch?: string;
	category?: string;
	clienttypes?: string[];
	content?: string;
	description?: string;
	desktopassignmenttype?: DesktopAssignmentType;
	desktophostname?: string;
	disabled?: boolean;
	iconurl?: string;
	id?: string;
	images?: any[];
	isdesktop?: boolean;
	isnewresource?: boolean;
	keywords?: string[];
	launchstatusurl?: string;
	launchurl?: string;
	mandatory?: boolean;
	recommended?: boolean;
	name?: string;
	notsupport?: boolean;
	path?: string;
	playsfiletypes?: {
		fileextensions?: string[];
		isdefault?: boolean;
		name?: string;
		mimetypes?: string[];
	}[];
	/** subscription/favorite position */
	position?: string;
	poweroffurl?: string;
	prelaunchurl?: string;
	prelaunchserviceurl?: string;
	recordlaunchurl?: string;
	properties?: PropertyNameValuePair[];
	requiresworkflow?: boolean;
	recentsposition?: number;
	shortcuturl?: string;
	shortcutvalidationurl?: string;
	sourceFeedIds?: string[];
	subscriptionproperties?: any[];
	subscriptionstatus?: subscriptionStatus;
	subscriptionurl?: string;
	supportsIcaLaunch?: boolean;
	type?: string;
	clmetadata?: ConnectionLeaseMetadata;
	isResourceRegisteredToSiri?: boolean;
	canQueryMachineState?: boolean;
}

export interface LocalAppResource extends Resource {
	whitelisted?: boolean;
}

export const localAppResourceType = 'Workspace.Local.App';

export function isLocalApp(resource: Resource): resource is LocalAppResource {
	return resource?.type === localAppResourceType;
}

export interface InstalledResourceData {
	resources?: InstalledResource[];
	onlyInstalledAppsAreSubscribed?: boolean;
}

export interface SiriRegisteredResourceData {
	resources?: SiriRegisteredResource[];
}

export interface ActivityCardResourceList {
	resources?: ActivityResource[];
}
export interface SiriRegisteredResource {
	appId?: string;
}

export interface InstalledResource {
	appId?: string;
	version?: string;
	packageId?: string;
}

export enum LaunchPreference {
	IcaFallbackToLease = 'IcaFallbackToLease',
	IcaOnly = 'IcaOnly',
	LeaseFallbackToIca = 'LeaseFallbackToIca',
	LeaseOnly = 'LeaseOnly',
}

export interface ConnectionLeaseMetadata {
	leasesupported: boolean;
	resourcekey?: string;
	leasetype?: string;
	launchpreference?: LaunchPreference;
	leaseversion?: string;
}

export enum MAMPropertyKeys {
	MamAppID = 'MamAppID',
	MamAppType = 'MamAppType',
	MamAppUniqueID = 'MamDownloadURL',
	MamAppVersion = 'MamAppVersion',
	MamBundleID = 'MamBundleID',
	MamDownloadURL = 'MamDownloadURL',
	MamExcludeDeviceTypes = 'MamExcludeDeviceTypes',
	MamMaxPlatform = 'MamMaxPlatform',
	MamMinPlatform = 'MamMinPlatform',
	MamPackageID = 'MamPackageID',
	MamPackageSize = 'MamPackageSize',
	MamPolicies = 'MamPolicies',
	MamPublicKey = 'MamPublicKey',
	MamToolsVersion = 'MamToolsVersion',
	MamWrappingTimestamp = 'MamWrappingTimestamp',
	MobileAppLinkURL = 'MobileAppLinkURL',
	MobileAppResourceID = 'MobileAppResourceID',
	mobileinstallurl = 'mobileinstallurl',
	FilesStorageDomains = 'FilesStorageDomains',
}

interface PropertyNameValuePair {
	name: MAMPropertyKeys;
	values: string[];
}

export function isSaasApp(resource: Resource) {
	return resource && !!resource.content;
}

export function isCVADApp(resource: Resource) {
	return resource?.type === 'Citrix.MPS.Application';
}

export function isDesktop(resource: Resource) {
	return resource?.isdesktop;
}

export function isResourceAvailable(resource: Resource) {
	return (
		resource && (environment.citrixCloudConnected || resource.clmetadata?.leasesupported)
	);
}
