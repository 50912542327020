import * as React from 'react';
import { getLanguage } from '@citrite/translate';
import {
	CitrixUIProvider as CitrixUI,
	palette,
	useBreakpoints,
} from '@citrite/web-ui-component';
import { ThemeColors } from '@citrite/web-ui-component/dist/typings/Theme/ThemeColors';
import { useTheme } from '@emotion/react';
import { useBranding } from 'Configuration/withBranding';
import { isNativeMobileClient } from 'Environment/launchResource/device';
import { getHistory } from 'Router/BrowserHistory';
import { useUserContext } from 'Workspace/UserContext';

export interface Props {
	children?: React.ReactNode;
}

export function CitrixUIProvider(props: Props) {
	const breakpoints = useBreakpoints();
	const branding = useBranding();
	const { mastheadOffset, mastheadOffsetMobile } = useTheme().mastheadOffset;

	const daasVisionEnabled = useUserContext().isDaasVisionEnabled;
	const daasVisionThemeOverrides: Partial<ThemeColors> = daasVisionEnabled
		? {
				primaryAppBackground: palette.white,
		  }
		: {};

	return (
		<CitrixUI
			configuration={{
				notificationContainerLocation:
					isNativeMobileClient() && breakpoints.screenSize.onMobile
						? { zIndex: 11, bottom: 50 }
						: { zIndex: 11 },
				theme: {
					paletteName: branding.paletteName,
					themeColorOverrides: {
						activeTab: branding.styles.AccentColor,
						...daasVisionThemeOverrides,
					},
				},
				scrollOffset: breakpoints.screenSize.onMobile
					? mastheadOffsetMobile
					: mastheadOffset,
				history: getHistory(),
				language: getLanguage(),
			}}
		>
			{props.children}
		</CitrixUI>
	);
}
