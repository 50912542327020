import * as React from 'react';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { isDesktopApp, isMobileApp, isWebApp } from 'Environment/deviceSupportsResource';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { citrixBrowserAnalyticsReporter } from 'Workspace/TelemetryEvents/citrixBrowser/createCitrixBrowserAnalyticsReporter';

export function useAppResourceReportingEffect() {
	const sentReportRef = React.useRef<boolean>(false);
	const resourcesContext = useLoadableResourceContext();

	React.useEffect(() => {
		const isValidated = resourcesContext.value?.isNetworkValidatedEnumeration;
		if (!isValidated || sentReportRef.current) {
			return;
		}

		const resourceCount = {
			mobileApps: 0,
			webApps: 0,
			desktopApps: 0,
			totalApps: 0,
		};

		const resources = resourcesContext.value?.resources || [];

		resources.forEach(resource => {
			if (isWebApp(resource)) {
				resourceCount.webApps++;
				resourceCount.totalApps++;
			} else if (isMobileApp(resource)) {
				resourceCount.mobileApps++;
				resourceCount.totalApps++;
			} else if (isDesktopApp(resource) && !resource.isdesktop) {
				resourceCount.desktopApps++;
				resourceCount.totalApps++;
			}
		});
		if (resourceCount.webApps) {
			//Needed for Pendo to show guide for new embedded browser experience CTXBR-1479
			trackEvent('userHasWebApps');
		}
		if (resourcesContext.value?.citrixBrowserResourceID) {
			// Along with analytics, this is also used for presenting Pendo onboarding for WSUI-5834.
			trackAnalyticsEvent(citrixBrowserAnalyticsReporter.getResourceAvailableEvent());
		}
		if (!resourceCount.totalApps) {
			trackAnalyticsEvent(AppsUserEventPayload.doesnotexist());
		}
		trackEvent('userAppsCount', resourceCount);

		sentReportRef.current = true;
	}, [resourcesContext]);
}
