import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
	loadingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	loadingIcon: {
		marginRight: '10px',
	},
});
