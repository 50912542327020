import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { clientType, getClientType } from 'Environment/getClientType';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { isHTML5ShieldEnabled } from 'LeaseWorkflow';
import { isBrowserExtensionCompatible } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';
import { isChromeAppCompatible } from 'Workspace/ChromeApp/ChromeAppHelperMethods';

export function isAdvancedWorkspaceResiliencyEnabled(
	workspaceConfiguration: WorkspaceConfiguration
) {
	const isBrowserExtensionAvailable =
		getClientType() === clientType.browserextension &&
		isBrowserExtensionCompatible(workspaceConfiguration);
	const isChromeAppAvailable =
		getClientType() === clientType.chromeapp &&
		isChromeAppCompatible(workspaceConfiguration);
	const isHtml5Available = isHTML5ShieldEnabled(workspaceConfiguration);
	return (
		(environment.isNative ||
			isBrowserExtensionAvailable ||
			isChromeAppAvailable ||
			isHtml5Available) &&
		hasUIProperty(workspaceConfiguration, UIProperty.AdvancedWorkspaceResiliency)
	);
}
