import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Tab as CitrixUiTab, TabProps } from '@citrite/web-ui-component';

const styles = StyleSheet.create({
	tabContent: {
		marginTop: '20px',
	},
});

export const MobileTab: React.FC<TabProps> = ({ children, ...tabProps }) => {
	return (
		<CitrixUiTab {...tabProps}>
			<div className={css(styles.tabContent)}>{children}</div>
		</CitrixUiTab>
	);
};
