import React from 'react';
import { t } from '@citrite/translate';
import {
	ArrowRightIcon,
	Button,
	fromThemeColors,
	layout,
	palette,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { homePagePath } from 'Router/homePageRoute';

const StyledSettingButton = styled(Button.TextSecondary)`
	div {
		text-decoration: none;
		color: ${fromThemeColors('primaryText')};
		font-weight: ${typography.weights.semiBold};
	}
`;

const StyledHomeButton = styled(Button.TextPrimary)`
	padding-left: 0;
	div {
		text-decoration: none;
		padding-left: 0;
	}
`;

const StyledSettingHeaderLinks = styled.div`
	display: flex;
	align-items: center;
	padding: ${layout.mediumSpace} 0;
`;

export const SettingsHeader = () => {
	const history = useHistory();
	const goBackToHome = () => history.push(homePagePath);

	const homeLink = (
		<StyledHomeButton title={t('Workspace:home')} onClick={goBackToHome}>
			{t('Workspace:home')}
		</StyledHomeButton>
	);

	const settings = (
		<StyledSettingButton title={t('Workspace:settings')}>
			{t('Workspace:settings')}
		</StyledSettingButton>
	);

	return (
		<StyledSettingHeaderLinks>
			{homeLink}
			{<ArrowRightIcon color={palette.grey700} />}
			{settings}
		</StyledSettingHeaderLinks>
	);
};
