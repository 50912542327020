import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

interface OpenWindowOptions {
	width: number;
	height: number;
	scrollbars?: boolean;
}

type WindowId = string;

/**
 * Provides cross-client support for managing new browser/web view
 * windows.
 * @category Platform Dependency
 */
export interface WindowManager {
	openWindow: (
		url: string,
		openInTabOnWeb?: boolean,
		options?: OpenWindowOptions
	) => Promise<WindowId>;
	closeWindow: (id: WindowId) => void;
	isWindowClosed: (id: WindowId) => Promise<boolean>;
	focusWindow: (id: WindowId) => void;
}

/**
 * @category Platform Dependency
 */
export const windowManager: WindowManager = {
	openWindow: (...args) =>
		resolveFromGlobal(PlatformDependencies.WindowManager).openWindow(...args),
	closeWindow: (...args) =>
		resolveFromGlobal(PlatformDependencies.WindowManager).closeWindow(...args),
	isWindowClosed: (...args) =>
		resolveFromGlobal(PlatformDependencies.WindowManager).isWindowClosed(...args),
	focusWindow: (...args) =>
		resolveFromGlobal(PlatformDependencies.WindowManager).focusWindow(...args),
};
