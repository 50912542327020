import {
	clientType,
	getPreviousClientType,
	setClientType,
} from 'Environment/getClientType';

export function fallbackToPreviousClientType() {
	const previousClientType = getPreviousClientType();
	if (previousClientType) {
		setClientType(previousClientType);
		return true;
	}
	setClientType(clientType.native);
	return false;
}
