import styled from '@emotion/styled';

export const StyledInlinedBanner = styled.div<{ showSingleLine: boolean }>`
	text-size-adjust: 100%;
	${props => (props.showSingleLine ? 'display: inline-flex' : '')}
`;

export const StyledBannerTitle = styled.div<{ isExpanded: boolean }>`
	${props =>
		!props.isExpanded &&
		'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'}
`;

export const StyledBannerText = styled.div<{ padding: number }>`
	text-size-adjust: 100%;
	padding-left: ${props => props.padding}px;
`;
