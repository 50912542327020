import { StyleSheet } from 'aphrodite';
import { breakpoints, layout, palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	whiteOverlay: {
		':not(foobar)': {
			backgroundColor: 'rgba(255, 255, 255, 0.8)',
		},
	},
	modal: {
		':not(foobar)': {
			border: 'none',
			height: '0px',
		},
	},
	container: {
		width: '600px',
		position: 'fixed',
		left: '0',
		right: '0',
		top: '150px',
		margin: 'auto',
		[breakpoints.onMediumScreen]: {
			width: 'auto',
			top: 0,
		},
		['@media only screen and (max-height: 700px)']: {
			top: 0,
		},
	},
	inputBox: {
		display: 'flex',
		border: `1px solid ${palette.grey600}`,
		boxSizing: 'border-box',
		backgroundColor: '#fff',
		height: '55px',
		alignItems: 'center',
	},
	inputBoxOpen: {
		borderBottom: `1px solid ${palette.grey300}`,
	},
	searchIcon: {
		padding: '16px 11px 16px 16px',
	},
	textInput: {
		display: 'inline-block',
		fontSize: '16px',
		flex: '1 1',
		margin: '0px',
		padding: '0 4px',
		boxSizing: 'border-box',
		border: 'none',
		outline: 'none',
		height: '40px',
		lineHeight: '40px',
		verticalAlign: 'middle',
		'-webkit-appearance': 'none',
		'::-webkit-search-decoration': {
			['-webkit-appearance' as any]: 'none',
		},
		'::-webkit-search-cancel-button': {
			['-webkit-appearance' as any]: 'none',
		},
		'::-ms-clear': {
			display: 'none',
		},
	},
	results: {
		position: 'absolute',
		background: '#fff',
		zIndex: 2,
		width: '100%',
		boxSizing: 'border-box',
	},
	resultsOpen: {
		border: `1px solid ${palette.grey600}`,
		borderTop: 'none',
	},
	ul: {
		padding: 0,
		margin: 0,
		maxHeight: '400px',
		overflow: 'auto',
	},
	seeMoreButton: {
		width: '100%',
	},
	seeMore: {
		borderTop: `1px solid ${palette.grey300}`,
		textAlign: 'center',
		padding: `${layout.smallSpace} 0`,
		lineHeight: layout.mediumLargeSpace,
		fontWeight: typography.weights.medium,
		color: palette.grey900,
		':hover': {
			backgroundColor: '#E8F0F9',
			border: `1px solid ${palette.blue800}`,
			color: palette.blue800,
			boxShadow: '0px -2px 2px 0 rgba(0, 0, 0, 0.1)',
		},
	},
	inNotifications: {
		fontWeight: typography.weights.semiBold,
	},
	notifications: {
		padding: '22px 16px',
	},
});
