export enum ButtonStyle {
	PRIMARY = 'PRIMARY',
	RED = 'RED',
	SECONDARY = 'SECONDARY',
}

export enum ButtonStyleBaby {
	PRIMARY = 'PRIMARY_BABY',
	RED = 'RED_BABY',
	SECONDARY = 'SECONDARY_BABY',
}
