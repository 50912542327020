import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { createCasPublisher } from 'Workspace/CAS/createCasPublisher';
import { PlatformContainer } from 'Workspace/DependencyManagement';
import { TelemetryEventPublisher } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export function telemetryClientFactory(
	container: PlatformContainer
): TelemetryEventPublisher {
	return {
		publishEvent(event) {
			const workspaceConfig = container.resolve(
				PlatformDependencies.WorkspaceConfiguration
			);
			const workspaceUser = container.resolve(PlatformDependencies.WorkspaceUser);
			const client = createCasPublisher(
				workspaceConfig.get(),
				workspaceUser.get().userDetails
			);
			return client.publishEvent(event);
		},
	};
}
