import {
	EventType,
	MandateSaasLaunchInCEBEventType,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'SAAS_APP_LAUNCH';
const preparePayload = (
	type: EventType | MandateSaasLaunchInCEBEventType,
	resourceId?: string
) => ({
	type,
	code,
	...(resourceId && { resourceId }),
});
const createSaasAppLaunchAnalyticsReporter = () => {
	return {
		getSaasLaunchPromptEvent: () =>
			preparePayload(MandateSaasLaunchInCEBEventType.PromptDisplayed),
		getSaasLaunchCebDownloadClickedEvent: () =>
			preparePayload(MandateSaasLaunchInCEBEventType.CebDownloadClicked),
		getSaasLaunchUserGuideOpenedEvent: () =>
			preparePayload(MandateSaasLaunchInCEBEventType.UserGuideOpened),
		getSaasLaunchOpenInNewTabEvent: () =>
			preparePayload(MandateSaasLaunchInCEBEventType.FallBackToOpenInNewTab),
		getSaasLaunchUsingCEBEvent: (resourceId?: string) =>
			preparePayload(MandateSaasLaunchInCEBEventType.LaunchUsingCEB, resourceId),
		getNativeLaunchEvent: (resourceId?: string) =>
			preparePayload(EventType.SaasLaunch_UsingNative, resourceId),
	};
};
export const saasAppLaunchAnalyticsReporter = createSaasAppLaunchAnalyticsReporter();
