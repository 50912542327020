import * as React from 'react';
import { IntegrationCapability } from '@citrite/workspace-ui-platform';
import { useIntegrations } from 'Integrations/useIntegrations';
import { ExecuteFeedCardActionEventSubscriber } from './ExecuteFeedCardActionEventSubscriber';

export function ExecuteFeedCardActionEventIntegrationAdapter() {
	return (
		<>
			{useIntegrations()
				.resolveByCapability(
					IntegrationCapability.feedCardExtensions.executeFeedCardAction
				)
				.map(integration => {
					const sourceOrigin =
						integration.registration.metadata &&
						'feedCardSourceOrigin' in integration.registration.metadata
							? integration.registration.metadata.feedCardSourceOrigin
							: undefined;
					if (!sourceOrigin) {
						return null;
					}
					return (
						<ExecuteFeedCardActionEventSubscriber
							key={sourceOrigin}
							execute={event =>
								integration.resolver.then(module =>
									module.executeFeedCardAction({ ...event.payload })
								)
							}
							sourceOrigin={sourceOrigin}
						/>
					);
				})}
		</>
	);
}
