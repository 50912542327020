import React from 'react';
import { t } from '@citrite/translate';
import { IntegrationCapability } from '@citrite/workspace-ui-platform';
import { ActionsWidget } from 'App/Actions/ActionsWidget';
import {
	ActionsIcon,
	AppsGridViewIcon,
	AppsListViewIcon,
	DesktopGridViewIcon,
	DesktopListViewIcon,
	NotificationsFeedIcon,
	OutlookIcon,
} from 'App/Widgets/icons';
import { InternalIntegrationContext } from 'Integrations/Context';

/**
 * Note: the current definition is specific to React widgets.
 * This will change when we support other widget types (e.g. adaptive cards)
 */
export interface WidgetDefinition {
	id: string;
	mfeName?: string;
	mfeRenderer?: string;
	inpIntegrationGlobalId?: string;
	title: () => string;
	description: () => string;
	icon: React.FC;
	provider: WidgetProvider;
	newUntil?: Date;
	Component?: React.ComponentType<any>;
	isEnabled?(integrations: InternalIntegrationContext): boolean;
}

export enum WidgetProvider {
	core = 'core',
}

/**
 * Human-readable, stable IDs for core widgets.
 * General format: '<vendor>/<some-id>'.
 * For '<some-id>' there could be a _loose_ naming convention, for instance, based on MFE names.
 * But the ID is not directly derived from those names because the two are intentionally decoupled.
 * Still it should give a good idea which specific widget the ID points to.
 * See also the widget registry below.
 *
 * Notes
 * - As far as the allowed characters, the IDs should follow the npm package name guidelines (https://docs.npmjs.com/cli/v7/configuring-npm/package-json)
 *   In particular the names should be URL safe since in the future they might be used in the URL path of the planned remote widget registry.
 */
export const appsLauncherWidgetId = 'citrix/apps-01';
export const desktopsLauncherWidgetId = 'citrix/desktops-01';
export const appsIwsWidgetId = 'citrix/apps-02';
export const desktopsIwsWidgetId = 'citrix/desktops-02';
export const actionsIwsWidgetId = 'citrix/actions-01';
const feedIwsWidgetId = 'citrix/feed-01';
export const feedInfiniteIwsWidgetId = 'citrix/feed-infinite-01';
const calendarWidgetId = 'citrix/calendar-01';

/**
 * Embedded widget registry - maps widget IDs to specific MFE renderers
 */
export const getRegistryWidgets = (): WidgetDefinition[] =>
	[
		{
			id: appsLauncherWidgetId,
			mfeName: 'apps',
			mfeRenderer: 'appsLarge',
			title: () => t('Workspace:widget_catalog.apps.title.large'),
			description: () => t('Workspace:widget_catalog.apps.description.large'),
			provider: WidgetProvider.core,
			icon: AppsGridViewIcon,
		},
		{
			id: appsIwsWidgetId,
			mfeName: 'apps',
			mfeRenderer: 'appsSmall',
			title: () => t('Workspace:widget_catalog.apps.title.small'),
			description: () => t('Workspace:widget_catalog.apps.description.small'),
			provider: WidgetProvider.core,
			icon: AppsListViewIcon,
		},
		{
			id: desktopsLauncherWidgetId,
			mfeName: 'desktops',
			mfeRenderer: 'desktopsLarge',
			title: () => t('Workspace:widget_catalog.desktops.title.large'),
			description: () => t('Workspace:widget_catalog.desktops.description.large'),
			provider: WidgetProvider.core,
			icon: DesktopGridViewIcon,
		},
		{
			id: desktopsIwsWidgetId,
			mfeName: 'desktops',
			mfeRenderer: 'desktopsSmall',
			title: () => t('Workspace:widget_catalog.desktops.title.small'),
			description: () => t('Workspace:widget_catalog.desktops.description.small'),
			provider: WidgetProvider.core,
			icon: DesktopListViewIcon,
		},
		{
			id: actionsIwsWidgetId,
			title: () => t('Workspace:widget_catalog.actions.title'),
			description: () => t('Workspace:widget_catalog.actions.description'),
			provider: WidgetProvider.core,
			icon: ActionsIcon,
			Component: ActionsWidget,
			isEnabled: (context: InternalIntegrationContext) =>
				context.hasEnabledIntegrationByCapability(IntegrationCapability.actionsProvider),
		},
		{
			id: feedIwsWidgetId,
			mfeName: 'notifications',
			mfeRenderer: 'notificationFeed',
			title: () => t('Workspace:widget_catalog.feed.title'),
			description: () => t('Workspace:widget_catalog.feed.description'),
			provider: WidgetProvider.core,
			icon: NotificationsFeedIcon,
		},
		{
			id: feedInfiniteIwsWidgetId,
			mfeName: 'notifications',
			mfeRenderer: 'notificationFeedInfinite',
			title: null, // feedInfiniteIwsWidgetId is not displayed in the widget catalog.
			description: null, //It is a special use case widget which will be removed once the ComposableHomepageEditing feature is fully rolled out
			provider: WidgetProvider.core,
			icon: null,
		},
		{
			id: calendarWidgetId,
			mfeName: 'calendarWidget',
			mfeRenderer: 'mainWidget',
			title: () => t('Workspace:widget_catalog.calendar_widget.title'),
			description: () => t('Workspace:widget_catalog.calendar_widget.description'),
			provider: WidgetProvider.core,
			icon: OutlookIcon,
			newUntil: new Date(2025, 1, 1),
			inpIntegrationGlobalId: '8efacc72-5bca-49d5-9035-5478a0d165fb',
		},
	].filter(Boolean);
