import * as React from 'react';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { DesktopAssignmentType } from 'Workspace/ResourceProvider/resourceTypes';
import { DesktopsUserEventPayload } from 'Workspace/TelemetryEvents/desktopsEvent/DesktopsUserEventPayload';
import { UserContext, useUserContext } from 'Workspace/UserContext';

export function useDesktopOnlyReportingEffect() {
	const sentReportRef = React.useRef<boolean>(false);
	const resourcesContext = useLoadableResourceContext();
	const userContext = useUserContext();

	const isValidated = resourcesContext.value?.isNetworkValidatedEnumeration;
	const readyToCheckForDesktopsOnly = canCheckForDesktops(isValidated, userContext);

	const desktopsOnlyListOrNull = React.useMemo(() => {
		const resources = resourcesContext.value?.resources || [];
		if (!readyToCheckForDesktopsOnly) {
			return null;
		}
		const desktops = resources.filter(resource => resource.isdesktop);
		if (desktops.length === 0 || resources.length !== desktops.length) {
			trackAnalyticsEvent(DesktopsUserEventPayload.doesnotexist());
			return null;
		}
		return desktops;
	}, [resourcesContext, readyToCheckForDesktopsOnly]);

	React.useEffect(() => {
		if (desktopsOnlyListOrNull === null || sentReportRef.current) {
			return;
		}

		const staticDesktops = desktopsOnlyListOrNull.filter(
			x =>
				x.desktopassignmenttype === DesktopAssignmentType.AssignOnFirstUse ||
				x.desktopassignmenttype === DesktopAssignmentType.Assigned
		);
		const sharedDesktops = desktopsOnlyListOrNull.filter(
			x => x.desktopassignmenttype === DesktopAssignmentType.Shared
		);
		trackEvent('DesktopOnlyUser', {
			desktopCount: desktopsOnlyListOrNull.length,
			staticDesktopCount: staticDesktops.length,
			sharedDesktopCount: sharedDesktops.length,
		});
		sentReportRef.current = true;
	}, [desktopsOnlyListOrNull]);
}

function canCheckForDesktops(
	isValidatedNetworkResponse: boolean,
	userContext: UserContext
) {
	const workspaceIntelligenceUserOrLoading =
		userContext.loading || userContext.isIWSEnabled;
	const passesGates = isValidatedNetworkResponse && !workspaceIntelligenceUserOrLoading;
	if (!passesGates) {
		return false;
	}
	return true;
}
