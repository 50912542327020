import { WorkspaceConfiguration } from '../workspaceConfiguration';

export function hasEndpointService(
	workspaceConfiguration: WorkspaceConfiguration,
	endpointsServiceName: string
) {
	return !!workspaceConfiguration?.endpointsServices?.endpointsService.some(
		({ name }) => name === endpointsServiceName
	);
}
