import React from 'react';
import { Theme } from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useFormFactor } from './useFormFactor';

interface Props {
	children: JSX.Element | JSX.Element[];
}

const StyledDiv = styled.div<{
	theme: Theme;
	isLargeFormFactor: boolean;
}>`
	display: flex;
	flex-direction: column;
	${props => `
		background-color: ${props.theme.branding.styles.HeaderBackgroundColor};
		color: ${props.theme.branding.styles.HeaderTextColor}; 
	`}
	${props => (props.isLargeFormFactor ? 'padding: 12px 32px;' : 'padding: 4px 14px;')}
`;

export const Banner: React.FC<Props> = props => {
	const theme = useTheme();
	const { isLargeFormFactor } = useFormFactor();

	return (
		<StyledDiv
			theme={theme}
			data-testid="application-banner"
			isLargeFormFactor={isLargeFormFactor}
		>
			{props.children}
		</StyledDiv>
	);
};
