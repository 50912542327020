import * as React from 'react';
import { useEffect } from 'react';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { isSimpleView } from 'App/Navigation/utils';
import { maximumResourcesForShortcut } from 'Components/FTU/constants';
import { CustomizeUserHomeContainer } from 'Components/FTU/CustomizeUserHomeContainer';
import { PersonalizationContainer } from 'Components/FTU/PersonalizationContainer';
import { ShortcutContainer } from 'Components/FTU/ShortcutContainer';
import { useFTUModals } from 'Components/FTU/useFTUModals';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

interface Props {
	setFTUScreenVisible?: (val: boolean) => void;
}
function _CustomizeUserHomeModal(props: Props) {
	const { resources } = useLoadableResourceContext().value;
	const { shouldDisplayFTUModal, closeModal } = useFTUModals();

	//This should be potentially removed.
	useEffect(() => {
		props?.setFTUScreenVisible && props.setFTUScreenVisible(shouldDisplayFTUModal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldDisplayFTUModal]);

	const getPersonalizationFTU = () =>
		!isSimpleView(resources) && (
			<PersonalizationContainer
				onCloseModal={() => {
					closeModal();
				}}
			/>
		);

	const getShortcutFTU = () =>
		resources?.length <= maximumResourcesForShortcut && (
			<ShortcutContainer
				onCloseModal={() => {
					closeModal();
				}}
			/>
		);

	const getFTU = () => getPersonalizationFTU() || getShortcutFTU();

	return (
		shouldDisplayFTUModal && (
			<CustomizeUserHomeContainer>{getFTU()}</CustomizeUserHomeContainer>
		)
	);
}

export const CustomizeUserHomeModal = (props: Props) => {
	return (
		<GenericErrorBoundary
			renderProp={() => <_CustomizeUserHomeModal {...props} />}
			name="CustomizeUserHomeModal"
		/>
	);
};
