import { StyleSheet } from 'aphrodite';
import { palette, styleUtils } from '@citrite/web-ui-component';

export const iconSize = 24;

export const styles = StyleSheet.create({
	row: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		height: '44px',
		paddingLeft: '5px',
		width: '100%',
		boxSizing: 'border-box',
		':hover': {
			backgroundColor: palette.grey200,
			textDecoration: 'underline',
		},
	},
	iconContainer: {
		minWidth: iconSize + 'px',
		minHeight: iconSize + 'px',
		marginRight: '12px',
	},
	icon: {
		height: iconSize + 'px',
		width: iconSize + 'px',
	},
	offlineMessage: {
		color: palette.grey600,
	},
	outerColumn: {
		display: 'flex',
		minWidth: '44px',
		flexShrink: 0,
	},
	innerColumn: {
		flexGrow: 1,
		minWidth: 0,
	},
	title: {
		...styleUtils.ellipsis,
	},
	moreActions: {
		padding: '0 20px',
		marginRight: '20px',
	},
	iconAndTitleContainer: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
	},
});
