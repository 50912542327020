import { FeedCardAction } from '@citrite/workspace-ui-platform';
import {
	FeedCard,
	FeedCardActionType,
	FeedCardAppIdentifier,
	FeedCardNotificationIdentifier,
	FeedCardSource,
} from 'userNotifications/FeedCard';
import {
	FeedCardDto,
	ResponseAppIdentifier,
	ResponseFeedCardAction,
	ResponseFeedCardSource,
	ResponseNotificationIdentifier,
} from './GetFeedResponse';

export function createFeedCard(data: FeedCardDto) {
	const notification: FeedCard<any> = {
		...data,
		source: createFeedCardSource(data.source),
		actions: data.actions.map(action => createFeedCardAction(action)),
		getTitle: () => '',
		getBody: () => '',
	};
	return notification;
}

function createFeedCardAction(data: ResponseFeedCardAction) {
	const notificationAction: FeedCardAction = {
		url: data.url,
		getLabel: () => '',
		getType: () => {
			switch (data.actionType) {
				case FeedCardActionType.OpenUrl:
					return 'OPEN_URL';
				case FeedCardActionType.PostCallback:
					return 'POST_CALLBACK';
				default:
					return 'MAS_CALLBACK';
			}
		},
		postPayload: data.postPayload,
	};
	return notificationAction;
}

function createNotificationIdentifier(data: ResponseNotificationIdentifier) {
	const notificationIdentifier: FeedCardNotificationIdentifier = {
		id: data.id,
		getLabel: () => '',
	};
	return notificationIdentifier;
}

function createAppIdentifier(data: ResponseAppIdentifier) {
	const appIdentifier: FeedCardAppIdentifier = {
		id: data.id,
		uuid: data.uuid,
		iconUrl: data.iconUrl,
		getTitle: () => '',
		getRawTitle: () => data.title,
	};
	return appIdentifier;
}

function createFeedCardSource(data: ResponseFeedCardSource) {
	const notificationSource: FeedCardSource = {
		origin: data.origin,
		integration: createAppIdentifier(data.integration),
		notification: createNotificationIdentifier(data.notification),
	};
	return notificationSource;
}
