import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';

export function getEndpointsService(
	workspaceConfiguration: WorkspaceConfiguration,
	endpointServiceName: string
) {
	return workspaceConfiguration?.endpointsServices?.endpointsService.find(
		({ name }) => name === endpointServiceName
	);
}
