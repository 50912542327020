import React, { useState } from 'react';
import { t } from '@citrite/translate';
import { LoadingOverlaySecondary, notifyError, palette } from '@citrite/web-ui-component';
import { UnencryptedCacheBucket } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { Action } from 'Components/FTU/Action';
import { cacheKey } from 'Components/FTU/constants';
import { Header } from 'Components/FTU/Header';
import { ShortcutContent } from 'Components/FTU/ShortcutContent';
import { subscribeSelectedItems, useFTUShownCache } from 'Components/FTU/Utils';
import { environment } from 'Environment';
import { refreshAllResources } from 'Environment/callbacks';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { CustomizeUserHomeModalEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const StyledShortcutModalBody = styled.div`
	background: ${palette.white};
	width: 525px;
	height: 525px;
	border-radius: 16px;
`;

const StyledShortcutContainer = styled.div`
	flex-direction: column;
	display: flex;
	width: 100%;
	height: 100%;
	gap: 8px;
	background: ${palette.white};
	border-radius: 16px;
	align-items: center;
	justify-content: space-around;
`;

interface Props {
	onCloseModal: () => void;
}
export const ShortcutContainer = (props: Props) => {
	const [displayProgressSpinner, setDisplayProgressSpinner] = useState(false);
	const resourceContext = useLoadableResourceContext();
	const { resources } = resourceContext.value;
	const ftuCache = useFTUShownCache();

	const handleFailure = () => {
		trackAnalyticsEvent({
			type: CustomizeUserHomeModalEventType.Shortcut_Failure,
		});
		notifyError(t('Workspace:ftu_ui.shortcut_error_message'));
	};

	const onAddShortcut = async () => {
		try {
			setDisplayProgressSpinner(true);
			await subscribeSelectedItems(resourceContext.value, resources);
			await environment.createShortcuts(resources);
			refreshAllResources();
			trackAnalyticsEvent({
				type: CustomizeUserHomeModalEventType.Shortcut_Success,
			});
		} catch (error) {
			handleFailure();
			logError(error, { tags: { feature: 'ftu-shortcut' } });
		} finally {
			setFTUState(ftuCache);
			setDisplayProgressSpinner(false);
		}
	};

	const navigateBack = () => {
		trackAnalyticsEvent({
			type: CustomizeUserHomeModalEventType.Skipped_Shortcut,
		});
		setFTUState(ftuCache);
	};

	const setFTUState = (cache: UnencryptedCacheBucket) => {
		props.onCloseModal();
		cache.setUnencrypted(cacheKey, true);
	};

	return (
		<StyledShortcutModalBody>
			<StyledShortcutContainer>
				<Header
					primaryText={t('Workspace:ftu_ui.primary_header_text_shortcut_screen')}
					secondaryText={t('Workspace:ftu_ui.secondary_header_text_shortcut_screen')}
				/>
				<ShortcutContent />
				<Action
					onPrimaryActionClick={onAddShortcut}
					onSecondaryActionClick={navigateBack}
					primaryActionId="add-shortcuts"
					secondaryActionId="skip-shortcuts"
					primaryButtonText={t('Workspace:ftu_ui.button_text_shortcut_screen')}
				/>
				{displayProgressSpinner && <LoadingOverlaySecondary size={60} />}
			</StyledShortcutContainer>
		</StyledShortcutModalBody>
	);
};
