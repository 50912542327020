import * as React from 'react';
import { css } from 'aphrodite';
import { Modal, ModalProps } from '@citrite/web-ui-component';
import { overflowMenuItemHeight } from 'App/BottomNav/dimensions';
import { OverflowMenuItem } from 'App/BottomNav/OverflowMenuItem';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { styles } from './OverflowMenu.styles';

export interface OverflowMenuProps extends ModalProps {
	links: LeftNavLink[];
}

export function OverflowMenu(props: OverflowMenuProps) {
	// calculate height of menu based on number of items and overall padding
	const menuHeight = props.links.length * (overflowMenuItemHeight + 8) + 24;
	const overFlowMenustyle = {
		height: menuHeight + 'px',
	};
	const overflowMenuElement = React.useRef(null);
	return (
		<Modal
			{...props}
			className={css(styles.modal)}
			onDismiss={() => {
				overflowMenuElement.current.className = css(
					styles.overflowMenu,
					styles.slideDown
				);
				props.onDismiss();
			}}
		>
			<div
				className={css(styles.overflowMenu)}
				style={overFlowMenustyle}
				ref={overflowMenuElement}
			>
				{props.links.map((link, index) => (
					<OverflowMenuItem
						key={`${index}${link.href}`}
						link={link}
						closeMenu={props.onDismiss}
					/>
				))}
			</div>
		</Modal>
	);
}
