import { Icon } from './Icon';
import { ListItem } from './ListItem';
import { PrimaryButton } from './PrimaryButton';
import { Title } from './Title';

/**
 * Composable collection of components for building
 * Native Mobile Workspace list items
 * @example <caption>Example usage of components</caption>
 * //<ListItem>
 * //  <PrimaryButton onClick={() => ...)}>
 * //    <Icon icon={<MyIconComponent />} />
 * //    <Title title={title} />
 * //  </PrimaryButton>
 * //</ListItem>
 */
export const NativeMobileViewItem = {
	Icon,
	Title,
	PrimaryButton,
	ListItem,
};
