import * as React from 'react';
import { css } from 'aphrodite';
import { Item } from './Item';
import { styles } from './styles';

export function FeedLoading() {
	return (
		<div className={css(styles.feedLoading)}>
			<Item className={css(styles.feedLoadingItem)} />
			<Item className={css(styles.feedLoadingItem)} />
			<Item className={css(styles.feedLoadingItem)} />
			<Item className={css(styles.feedLoadingItem)} />
		</div>
	);
}
