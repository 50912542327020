import { EndpointsService } from '@citrite/workspace-ui-platform';
import { sendMessageToBrowserExtension } from 'Environment/BrowserExtension/browserExtensionCommunicationAdapter';
import { sendMessageToBrowserExtensionWrapper } from 'Environment/BrowserExtension/browserExtensionMessageHelper';
import { saveLastLeaseLaunchTime } from 'Environment/BrowserExtension/browserExtensionTelemetryLaunch';
import {
	AADSSOAuthReqPayload,
	AuthenticationTimerOperationType,
	BrowserExtensionResponse,
	BrowserExtensionWrapperMessage,
} from 'Environment/BrowserExtension/types';

interface SendInitiateStoreRequest {
	isCloudOnline?: boolean;
	csrfToken?: string;
	userId?: string;
}

export interface SendLogoffMessage {
	isSessionExpired: boolean;
	isPasswordChanged: boolean;
}

interface SendIcaLaunchMessage {
	resourceId: string;
	icaFile: string;
	iconData?: string;
	resourceName?: string;
}

interface SendLeaseLaunchMessage {
	resourceId: string;
	resourceKey: string;
	telemetryLaunch?: {
		telemetryData: {
			telemetryHeadlessLaunchEnabled: boolean;
			telemetryLaunchShadowDelayMins: number;
			telemetryLaunchMinTimeIntervalMins: number;
		};
	};
	resourceType: string;
	resourceName: string;
	launchPreference: string;
	iconData?: string;
}

export function sendCustomerConfiguration(workload: {
	storeId?: string;
	customerId?: string;
	endpoints?: EndpointsService[];
	isPreviousLogoutExplicit?: boolean;
	hasLastUserDetails?: boolean;
	idpConfigId?: string;
	isHtml5Enabled?: boolean;
	isModernOfflineFlowEnabledForStore?: boolean;
}) {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'setCapabilities',
		timeout: 3000,
	};

	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export function initiateStore(workload: SendInitiateStoreRequest) {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'initiateStore',
		retries: 2,
		timeout: 3000,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export function logOff(workload: SendLogoffMessage) {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'logOff',
		timeout: 3000,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export function icaLaunch(workload: SendIcaLaunchMessage) {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'icaLaunch',
		timeout: 3000,
		retries: 2,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export function getLeasingProperties() {
	const message: BrowserExtensionWrapperMessage = {
		type: 'getLeasingProperties',
		timeout: 3000,
		retries: 2,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export function getStoreHealth() {
	const message: BrowserExtensionWrapperMessage = {
		type: 'getStoreHealth',
		timeout: 3000,
		retries: IS_ON_PREM ? 2 : null,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export async function leaseLaunch(workload: SendLeaseLaunchMessage) {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'leaseLaunch',
		timeout: 3000,
		retries: 2,
	};
	const result = await sendMessageToBrowserExtensionWrapper(
		sendMessageToBrowserExtension,
		message
	);
	saveLastLeaseLaunchTime();
	return result;
}

export function notifyAuthenticationTimer(workload: {
	storeId?: string;
	customerId?: string;
	timerOperation: AuthenticationTimerOperationType;
}) {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'notifyAuthenticationTimer',
		timeout: 3000,
	};

	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export async function getAADSSOInfo(): Promise<BrowserExtensionResponse> {
	const message: BrowserExtensionWrapperMessage = {
		type: 'getAADSSOInfo',
		timeout: 3000,
		retries: 2,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}

export async function sendAADSSOAccessToken(
	workload: AADSSOAuthReqPayload
): Promise<BrowserExtensionResponse> {
	const message: BrowserExtensionWrapperMessage = {
		workload,
		type: 'sendAADSSOAccessToken',
		timeout: 3000,
	};
	return sendMessageToBrowserExtensionWrapper(sendMessageToBrowserExtension, message);
}
