import * as React from 'react';
import { css } from 'aphrodite';
import {
	ArrowDownIcon,
	ArrowRightIcon,
	Button,
	palette,
} from '@citrite/web-ui-component';
import { t } from '../../utils/translate';
import { styles } from './styles';

export interface NativeMobileCollapsibleSectionProps {
	title: string;
	isCollapsible?: boolean;
	isCollapsedInitially?: boolean;
	children?: React.ReactNode;
}

interface State {
	isCollapsed: boolean;
}

export class NativeMobileCollapsibleSection extends React.Component<
	NativeMobileCollapsibleSectionProps,
	State
> {
	public static defaultProps = {
		isCollapsible: false,
		isCollapsedInitially: false,
	};

	public state: State = {
		isCollapsed: this.props.isCollapsedInitially,
	};

	private onChange() {
		this.setState(prevState => ({
			isCollapsed: !prevState.isCollapsed,
		}));
	}

	public render() {
		const { title, isCollapsible, children } = this.props;
		return (
			<>
				<div className={css(styles.sectionHeader)}>
					<div className={css(styles.sectionTitle)}>{title}</div>
					{isCollapsible && (
						<span>
							<Button.IconOnly
								icon={this.state.isCollapsed ? ArrowRightIcon : ArrowDownIcon}
								title={t('platform-react:collapsible_section_button')}
								size={16}
								color={palette.black}
								onClick={() => this.onChange()}
							/>
						</span>
					)}
				</div>
				{(!isCollapsible || !this.state.isCollapsed) && children}
			</>
		);
	}
}
