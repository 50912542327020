import { EventType, TelemetryEvent } from '@citrite/workspace-ui-platform';

export interface ActivityEventWithDetails extends TelemetryEvent {
	noun: string;
	verb: string;
	detail: {
		result: 'SUCCESS' | 'FAILURE' | 'TIMEOUT';
		/**  In milliseconds */
		duration: number;
		/** Activity response based on local cache */
		cached?: boolean;
		httpResponse?: number;
		transactionId?: string;
	};
}

export function createActivityEventWithDetails(
	verb: string,
	noun: string,
	result: 'SUCCESS' | 'FAILURE' | 'TIMEOUT',
	duration: number,
	httpResponse?: number,
	transactionId?: string,
	cached?: boolean
): ActivityEventWithDetails {
	return {
		type: EventType.Activity,
		verb,
		noun,
		detail: {
			result,
			duration,
			cached,
			httpResponse,
			transactionId,
		},
	};
}
