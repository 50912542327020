import type { default as SentryType } from '@sentry/browser';
import { v4 } from 'uuid';

export type ExtraContext = { [key: string]: any };
export type Tags = { [key: string]: string };

export interface LogErrorOptions {
	tags?: Tags;
	additionalContext?: ExtraContext;
	customMessage?: string;
}

export type LogError = (
	payload: any,
	options?: LogErrorOptions,
	silent?: boolean
) => void;
export type LogInfo = (message: string, additionalContext?: ExtraContext) => void;
export type AddBreadcrumb = (breadcrumb: SentryType.Breadcrumb) => void;
export type SetLoggingTags = (
	tags: { [key: string]: string },
	options?: { global?: boolean }
) => void;
export type SetUserId = (id: string) => void;

export type LoggingProvider = {
	addBreadcrumb: AddBreadcrumb;
	configureScope: (callback: (scope: any) => void) => void;
	logError: LogError;
	logInfo: LogInfo;
	setLoggingTags: SetLoggingTags;
	setUserId: SetUserId;
};

export enum ErrorLevel {
	FATAL = 'fatal',
	CRITICAL = 'critical',
	TRIVIAL = 'trivial',
	INFO = 'info',
}

export const WSUI_SESSION_ID = v4();
