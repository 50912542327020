import {
	InstalledResource,
	MAMPropertyKeys,
	Resource,
} from 'Workspace/ResourceProvider/resourceTypes';
const naturalSort = require('javascript-natural-sort');
naturalSort.insensitive = true;

export function upgradeAvailable(
	id: string,
	installedResources: InstalledResource[],
	resources: Resource[]
) {
	if (installedResources.some(resource => resource.appId === id)) {
		const matchingResource = resources.find(x => x.id === id);

		const serverVersionProperty = matchingResource.properties.find(
			property => property.name === MAMPropertyKeys.MamAppVersion
		);

		const serverPackageIdProperty = matchingResource.properties.find(
			property => property.name === MAMPropertyKeys.MamPackageID
		);

		const locallyInstalledApp = installedResources.find(
			resource => resource.appId === id
		);

		if (!locallyInstalledApp) {
			return false;
		}

		if (
			serverPackageIdProperty &&
			locallyInstalledApp.packageId &&
			serverPackageIdProperty.values[0] !== locallyInstalledApp.packageId
		) {
			return true;
		}
		if (serverVersionProperty && locallyInstalledApp.version) {
			return isRemoteVersionHigher(
				locallyInstalledApp.version,
				serverVersionProperty.values[0]
			);
		}
	}
	return false;
}

function isRemoteVersionHigher(localVersion: string, remoteVersion: string) {
	const localVersionSplit = localVersion.split('.');
	const remoteVersionSplit = remoteVersion.split('.');

	if (localVersionSplit.length !== remoteVersionSplit.length) {
		let difference = 0;
		let equaliseLocalVersion = false;

		if (localVersionSplit.length > remoteVersionSplit.length) {
			difference = localVersionSplit.length - remoteVersionSplit.length;
		} else if (localVersionSplit.length < remoteVersionSplit.length) {
			difference = remoteVersionSplit.length - localVersionSplit.length;
			equaliseLocalVersion = true;
		}

		while (difference > 0) {
			if (equaliseLocalVersion) {
				localVersionSplit.push('0');
			} else {
				remoteVersionSplit.push('0');
			}
			difference--;
		}
	}

	return localVersionSplit.some((octet, idx) => {
		if (octet === remoteVersionSplit[idx]) {
			return false;
		}

		const pair = [octet, remoteVersionSplit[idx]];
		pair.sort(naturalSort);
		return pair[0] === octet;
	});
}
