import * as React from 'react';
import { css } from 'aphrodite';
import { Button } from '@citrite/web-ui-component';
import { styles } from './ViewAllButton.styles';

export interface ViewAllButtonProps {
	onClick: () => void;
	title: string;
}

export const ViewAllButton: React.FC<ViewAllButtonProps> = ({ title, onClick }) => {
	return (
		<div className={css(styles.item)}>
			<Button.Wrapper
				onClick={onClick}
				type="button"
				className={css(styles.viewAllButton)}
			>
				{title}
			</Button.Wrapper>
		</div>
	);
};
