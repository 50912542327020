import * as React from 'react';
import { RouterBlueLink } from '../RouterLink';
import { ItemListSkeleton } from './';
import { EmptyState } from './EmptyState';
import { FeedSelector } from './FeedSelector';
import { ContextMenuItemBuilder, Item, ItemType } from './Item';
import {
	StyledFeedSelectorWrapper,
	StyledItemList,
	StyledViewAll,
} from './ItemList.styled';
import { NoAvailableItems } from './NoAvailableItems';

const itemsToShow = 4;
export type WidgetFeedIdentifier = number | string;

export type WidgetFeed = {
	id: WidgetFeedIdentifier;
	title: () => string;
};

export interface ViewAllLink {
	routeUrl: string;
	text: string;
}

export interface ItemListProps<T extends object> {
	getItems?: (selectedFeedIdentifier: WidgetFeedIdentifier) => ItemType<T>[];
	getViewAllLink: (selectedFeedIdentifier: WidgetFeedIdentifier) => ViewAllLink;
	notEnabledItemsMessage?: string;
	getEmptyStateMessage: (selectedFeedIdentifier: WidgetFeedIdentifier) => string;
	defaultFeedIdentifier: WidgetFeedIdentifier;
	feeds: WidgetFeed[];
	onFavoriteChange?: (data: ItemType<T>) => Promise<void>;
	buildContextMenu?: ContextMenuItemBuilder<T>;
	showLoadingSkeleton: boolean;
}

export function ItemList<T extends object>(props: ItemListProps<T>) {
	const [selectedFeed, setSelectedFeed] = React.useState(props.defaultFeedIdentifier);
	if (props.showLoadingSkeleton) {
		return <ItemListSkeleton />;
	}
	const { routeUrl, text } = props.getViewAllLink(selectedFeed) || {};
	return (
		<>
			<StyledFeedSelectorWrapper>
				<FeedSelector
					onFeedChange={setSelectedFeed}
					feeds={props.feeds}
					selectedFeed={selectedFeed}
					cannotChangeFeed={!!props.notEnabledItemsMessage}
				/>
			</StyledFeedSelectorWrapper>
			<Widget>
				{props
					.getItems(selectedFeed)
					.slice(0, itemsToShow)
					.map(item => (
						<div key={item.id} role="listitem">
							<Item<T>
								buildContextMenu={props.buildContextMenu}
								onFavoriteChange={
									props.onFavoriteChange ? () => props.onFavoriteChange(item) : null
								}
								{...item}
							/>
						</div>
					))}
				{props.notEnabledItemsMessage ? (
					<NoAvailableItems title={props.notEnabledItemsMessage} />
				) : (
					<>
						{!props.getItems(selectedFeed).length && (
							<EmptyState message={props.getEmptyStateMessage(selectedFeed)} />
						)}
						{routeUrl && text && (
							<StyledViewAll role="listitem">
								<RouterBlueLink to={routeUrl} children={text} />
							</StyledViewAll>
						)}
					</>
				)}
			</Widget>
		</>
	);
}

export function Widget({ children }: { children: React.ReactNode }) {
	return <StyledItemList role="list">{children}</StyledItemList>;
}
