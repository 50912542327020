import React from 'react';
import { Container } from 'App/Screen/Container';
import { ResourceList } from 'App/Screen/ResourceList';
import { ResourceFilter } from 'App/Screen/util';

export const Desktop = () => {
	return (
		<Container id="desktops-screen">
			<ResourceList filter={ResourceFilter.Desktops} />
		</Container>
	);
};
