import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { filterSessionsByType } from 'App/Activity/components/Utility';
import { getRemoteSessionsList } from 'App/Activity/Network/SessionList';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export async function fetchAndCategorizeRemoteSessions(
	workspaceConfiguration: WorkspaceConfiguration,
	resources: Resource[]
) {
	const response = await getRemoteSessionsList(workspaceConfiguration);
	return filterSessionsByType(response, resources);
}
