function xmlToJson(xml: any): any {
	let obj: any = {};

	if (xml.nodeType === Node.ELEMENT_NODE) {
		if (xml.attributes && xml.attributes.length > 0) {
			obj = {};
			for (let j = 0; j < xml.attributes.length; j++) {
				const attribute = xml.attributes.item(j);
				if (attribute) {
					obj[attribute.nodeName] = attribute.nodeValue;
				}
			}
		}
	} else if (xml.nodeType === Node.TEXT_NODE) {
		obj = xml.nodeValue?.trim() || '';
	}

	// parse children
	if (xml.hasChildNodes()) {
		for (let i = 0; i < xml.childNodes.length; i++) {
			const item = xml.childNodes.item(i);
			if (item) {
				const nodeName = item.nodeName;
				if (obj[nodeName] === undefined) {
					obj[nodeName] = xmlToJson(item);
				} else {
					if (!Array.isArray(obj[nodeName])) {
						obj[nodeName] = [obj[nodeName]];
					}
					obj[nodeName].push(xmlToJson(item));
				}
			}
		}
	}
	return obj;
}

export function parseXMLWithJQuery(configurationXML: string, $: JQueryStatic) {
	const xmlData = $.parseXML(configurationXML);
	return xmlToJson(xmlData);
}
