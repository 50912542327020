// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
import { workspaceConfiguration, workspaceUser } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { createCasPublisher } from 'Workspace/CAS/createCasPublisher';
import { EventType, TelemetryEvent } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import {
	addEventListener,
	addEventListenerByPendoGuideId,
	removeEventListener,
} from './PendoHelper';

const kSwitchToSfFeedback = 'event.pendo.switchToSfFeedback';
const WorkspaceToStorefrontFallbackCode = 'Workspace_To_Storefront_Fallback_Code';
const component = 'WSUI';
const workspaceToStoreFrontFallbackPendoScreenId = 'xlJfLqJC6pkNu4Z0Hd-8QCIlY4A';
const proceedWithSwitch = true;
const switchToWorkspaceOnNextAppLaunch = false;

interface WorkspaceToStoreFrontFallbackEvent extends TelemetryEvent {
	time: string;
	type: EventType;
	reason: string;
	code: string;
	component: string;
	isMigrated: string;
	switchToWSOnNextLaunch: string;
}

export function displayWorkspaceToStoreFrontFallbackPendoScreen() {
	if (
		window.pendo &&
		environment.isNativeFeatureFlagEnabled(
			'showWorkspaceToStoreFrontFeedbackPendoScreenEnabled'
		)
	) {
		addEventListenerByPendoGuideId(workspaceToStoreFrontFallbackPendoScreenId);
		addEventListener(kSwitchToSfFeedback, optionsInFeedbackScreenClicked, false);
	} else {
		userOptionsSelectedInThePendoScreen(
			proceedWithSwitch,
			switchToWorkspaceOnNextAppLaunch
		);
	}
}

function optionsInFeedbackScreenClicked(e: CustomEvent) {
	removeEventListener(kSwitchToSfFeedback, optionsInFeedbackScreenClicked, false);
	sendCasEventForFallback(e);
	userOptionsSelectedInThePendoScreen(
		e.detail.switchNowClicked,
		e.detail.checkBoxChecked
	);
}

function sendCasEventForFallback(e: CustomEvent) {
	const workspaceConfig = workspaceConfiguration.get();
	const userDetails = workspaceUser.get().userDetails;
	if (workspaceConfig && userDetails) {
		const telemetryClient = createCasPublisher(workspaceConfig, userDetails);
		const workspaceToStoreFrontFallbackEvent: WorkspaceToStoreFrontFallbackEvent = {
			time: new Date().toISOString(),
			type: EventType.WorkspaceToStorefrontFallback,
			reason: e.detail.reason,
			code: WorkspaceToStorefrontFallbackCode,
			component,
			isMigrated: e.detail.switchNowClicked,
			switchToWSOnNextLaunch: e.detail.checkBoxChecked,
		};
		telemetryClient.publishEvent(workspaceToStoreFrontFallbackEvent);
	}
}

function userOptionsSelectedInThePendoScreen(
	switchNowClicked: boolean,
	switchToWorkspaceOnNextAppLaunchChecked: boolean
) {
	environment.workspaceToStoreFrontFallbackFeedbackResponse(
		switchNowClicked,
		switchToWorkspaceOnNextAppLaunchChecked
	);
}
