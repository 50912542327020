import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Region, Tabs, TabsProps } from '@citrite/web-ui-component';

const styles = StyleSheet.create({
	tabContainer: {
		marginBottom: '20px',
		position: 'relative',
	},
	tabs: {
		marginLeft: '12px',
		marginBottom: '12px',
	},
});

export const MobileTabsContainer: React.FC<TabsProps> = props => {
	return (
		<Region className={css(styles.tabContainer)}>
			{() => <Tabs {...props} className={css(styles.tabs)} />}
		</Region>
	);
};
