import * as React from 'react';
import { ClientInstance } from '@citrite/http';
import { EndpointSupplier } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { UserPreferenceService } from 'userPersonalizationService/UserPreferences';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';

export const useUserPreferenceService = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { cacheFactory } = useCacheContext();

	const createUserPreferenceService = React.useCallback(
		endpointService => {
			const cacheBucket = cacheFactory.createEncryptedCacheBucket(
				BucketManifest.userPreferences
			);

			const endpointSupplier: EndpointSupplier = createEndpointSupplier(
				endpointService.discoveryUrl
			);
			const ssoProxyClient: ClientInstance = environment.createSSOProxyClient(
				workspaceConfiguration.authManager.proxyUrl
			);
			const customerId = workspaceConfiguration.storeIdentifiers.customerId;
			const storeId = workspaceConfiguration.storeIdentifiers.storeGuid;
			return new UserPreferenceService(
				endpointSupplier,
				ssoProxyClient,
				customerId,
				storeId,
				cacheBucket
			);
		},
		[workspaceConfiguration, cacheFactory]
	);

	return React.useMemo(() => {
		const endpointService = getEndpointsService(
			workspaceConfiguration,
			'userPersonalization'
		);
		if (!endpointService) {
			return null;
		}
		try {
			return createUserPreferenceService(endpointService);
		} catch (error) {
			logError(error, {
				customMessage: 'Suppressed App Crash: Failed to create UserPreferenceService',
			});
			return null;
		}
	}, [workspaceConfiguration, createUserPreferenceService]);
};
