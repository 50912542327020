import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledWorkspaceLogo = styled.div`
	display: flex;
	align-items: center;
	> span:nth-of-type(2) {
		margin-top: ${layout.tinySpace};
		margin-left: ${layout.tinySpace};
	}
`;
