import * as React from 'react';
import { t } from '@citrite/translate';
import {
	StyledActivityCardHeaderContainer,
	StyledTitle,
} from 'App/Activity/components/commonStyles';
import { useFormFactor } from 'App/Header/useFormFactor';

export const DesktopSectionHeader = () => {
	const { isSmallFormFactor } = useFormFactor();
	return (
		<StyledActivityCardHeaderContainer>
			<StyledTitle
				title={t('Workspace:desktops')}
				isSmallFormFactor={isSmallFormFactor}
			/>
		</StyledActivityCardHeaderContainer>
	);
};
