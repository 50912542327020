import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_SEARCH';
const preparePayload = (type: EventType) => ({
	type,
	code,
});
const createSearchCASReporter = () => {
	return {
		getSearchInitEvent: () => preparePayload(EventType.Search_Init),
		getSearchKeyInEvent: () => preparePayload(EventType.Search_KeyIn),
		getSearchSelectEvent: () => preparePayload(EventType.Search_Select),
		getSearchNoResultsEvent: () => preparePayload(EventType.Search_No_Results),
	};
};
export const searchCASReporter = createSearchCASReporter();
