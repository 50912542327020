import { StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	tabContainer: {
		marginBottom: '20px',
		position: 'relative',
		height: '90%',
	},
	widgetTabs: {
		paddingLeft: '18px',
		backgroundColor: palette.teal100,
	},
});
