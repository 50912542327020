import { createLogger } from 'remoteLogging';
import { InternalPlatformDependencies } from 'IntegrationDependencies/InternalDependencies';
import { PlatformContainer } from 'Workspace/DependencyManagement';

export function createScopedLogger(container: PlatformContainer) {
	const logger = createLogger();
	const integration = container.resolve(InternalPlatformDependencies.ScopedIntegration);
	const [user] = container.resolve(InternalPlatformDependencies.UserDetails).get();
	logger.configureScope(scope => {
		scope.setUser({ id: user?.userId });
		scope.setTags({
			feature: integration.id,
		});
	});
	return logger;
}
