import * as React from 'react';
import { css } from 'aphrodite';
import {
	AdaptiveDropdown,
	ContextMenuItemBuilder,
	NativeMobileViewItem as PlatformNativeMobileViewItem,
} from '@citrite/workspace-ui-platform-react';
import { environment } from 'Environment';
import { isNativeMobileOrTabletClient } from 'Environment/launchResource/device';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './styles';

const { ListItem, Icon, PrimaryButton, Title } = PlatformNativeMobileViewItem;

export interface NativeMobileResourceItemProps {
	id: string;
	title: string;
	icon: React.ReactNode;
	onPrimaryAction: () => Promise<void>;
	data: Resource;
	isLoading: (data: Resource) => boolean;
}

interface State {
	loading: boolean;
}

export interface NativeMobileResourceRowProps {
	item: NativeMobileResourceItemProps;
	contextMenuBuilder?: ContextMenuItemBuilder<Resource>;
}

export class NativeMobileResourceRow extends React.Component<
	NativeMobileResourceRowProps,
	State
> {
	public state: State = {
		loading: false,
	};

	private handleOnPrimaryAction = () => {
		if (this.state.loading) {
			return;
		}
		this.setState({ loading: true });
		this.props.item.onPrimaryAction().then(() => {
			this.setState({ loading: false });
		});
	};

	public render() {
		const { icon, title, data, isLoading } = this.props.item;

		const shouldBeLoading = isLoading(data);
		const disabled =
			!environment.citrixCloudConnected &&
			!this.props.item.data.clmetadata?.leasesupported;
		return (
			<ListItem>
				<div className={css(styles.flexContainer, disabled && styles.disabledRow)}>
					<PrimaryButton onClick={this.handleOnPrimaryAction}>
						<Icon icon={icon} loading={this.state.loading || shouldBeLoading}></Icon>
						<Title title={title} />
					</PrimaryButton>
				</div>
				<AdaptiveDropdown
					data={data}
					disabled={disabled}
					showMobileActionSheet={isNativeMobileOrTabletClient()}
					contextMenuBuilder={this.props.contextMenuBuilder}
					name={title}
				/>
			</ListItem>
		);
	}
}
