import { feedSort } from 'userNotifications/FeedCard';
import { SortOrderChangeEvent } from 'Workspace/TelemetryEvents/notificationFeed/events';
import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { SDKToSortOrderMap } from './sortOrderMap';

export function createSortOrderChangeEvent(newOrder: feedSort): SortOrderChangeEvent {
	return {
		newSortOrder: SDKToSortOrderMap.get(newOrder),
		type: EventType.NotificationFeed_SortOrderChange,
	};
}
