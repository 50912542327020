import React from 'react';
import {
	createIntegration,
	IntegrationCapability,
	// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
	workspaceConfiguration,
	workspaceUser,
} from '@citrite/workspace-ui-platform';
import { performance } from 'Components/performance';
import { createFeedPageLink, feedRoutePath } from './createFeedPageLink';

export const NotificationIntegrationId = 'notifications';
const endpointServiceName = 'notifications';
export const notificationIntegration = createIntegration({
	id: NotificationIntegrationId,
	requiredEndpointServiceName: endpointServiceName,
	capabilities: [
		IntegrationCapability.UNSTABLE_widgetComponent.register('notificationFeed', {
			performanceKey: performance.events.feed,
		}),
		IntegrationCapability.UNSTABLE_widgetComponent.register('notificationFeedInfinite', {
			performanceKey: performance.events.feed,
		}),
		IntegrationCapability.leftNavigation.primaryNavLink.register('feedPageLink'),
		IntegrationCapability.route.register('feedPageRoute', { path: feedRoutePath }),
	],
	modulesResolver: {
		service: ctx =>
			import(
				/* webpackChunkName: "UserNotifications" */ './service/NotificationService'
			).then(module => module.create(ctx)),
		notificationFeed: () =>
			import(
				/* webpackChunkName: "FeedWidget" */ 'App/NotificationHome/NotificationsWidget'
			).then(({ Feed }) => ({ Component: Feed })),
		notificationFeedInfinite: () =>
			import(
				/* webpackChunkName: "FeedWidget" */ 'App/NotificationHome/NotificationsWidget'
			).then(({ Feed }) => ({ Component: () => <Feed infiniteScrolling /> })),
		feedPageLink: () => createFeedPageLink(),
		feedPageRoute: () =>
			import(/* webpackChunkName: "FeedPage" */ './createFeedPageRoute').then(
				({ createFeedPageRoute }) => createFeedPageRoute()
			),
	},
	isEnabled: () => {
		const userInfo = workspaceUser.get();
		return (
			userInfo.isIWSEnabled &&
			workspaceConfiguration.hasEndpointService(endpointServiceName)
		);
	},
});
