import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';

const isPreviousLogoutExplicitKey = 'isPreviousLogoutExplicitKey';

export function setPreviousLogoutExplicit(isExplicit: boolean) {
	setInLocalStorage(isPreviousLogoutExplicitKey, isExplicit);
}

export function isPreviousLogoutExplicit() {
	return getFromLocalStorage<boolean>(isPreviousLogoutExplicitKey) ?? false;
}
