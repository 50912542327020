import { ConfiguredServiceEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_CONFIGURED_SERVICES';
const preparePayload = (type: ConfiguredServiceEventType) => ({
	type,
	code,
});
const createConfiguredServiceCASReporter = () => {
	return {
		getConfiguredServiceHomeTabEnabledEvent: () =>
			preparePayload(ConfiguredServiceEventType.ConfiguredService_HomeTabEnabled),
		getConfiguredServiceHomeTabDisabledEvent: () =>
			preparePayload(ConfiguredServiceEventType.ConfiguredService_HomeTabDisabled),
		getConfiguredServiceSimpleViewEnabledEvent: () =>
			preparePayload(ConfiguredServiceEventType.ConfiguredService_SimpleViewEnabled),
		getConfiguredServiceSimpleViewDisabledEvent: () =>
			preparePayload(ConfiguredServiceEventType.ConfiguredService_SimpleViewDisabled),
	};
};
export const configuredServiceCASReporter = createConfiguredServiceCASReporter();
