import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { InternalPlatformDependencies } from 'IntegrationDependencies/InternalDependencies';
import { PlatformContainer } from 'Workspace/DependencyManagement';

export function scopedEndpointSupplierFactory(container: PlatformContainer) {
	const workspaceConfiguration = container
		.resolve(PlatformDependencies.WorkspaceConfiguration)
		.get();

	const integration = container.resolve(InternalPlatformDependencies.ScopedIntegration);
	if (!integration.requiredEndpointServiceName) {
		console.warn(
			'You are attempting to create an endpoint supplier without specifying a required endpoint supplier.'
		);
		return null;
	}

	return createEndpointSupplier(
		getEndpointsService(workspaceConfiguration, integration.requiredEndpointServiceName)
			?.discoveryUrl
	);
}
