import { useLayoutEffect, useRef, useState } from 'react';

interface ReturnProps {
	elementRef: React.MutableRefObject<HTMLDivElement>;
	offsetTop: number;
	offsetHeight: number;
	scrollHeight: number;
}
/**
 * This hook is mainly to read properties of the html element at some scenarios
 * This hook would provide a ref to be attached to the html element and then property values we would need from the element
 */
export const useHTMLElementProperties = (): ReturnProps => {
	const [offsetTop, setOffsetTop] = useState<number>(0);
	const [offsetHeight, setOffsetHeight] = useState<number>(0);
	const [scrollHeight, setScrollHeight] = useState<number>(0);
	const elementRef = useRef(null);
	const handleOffsetChanges = () => {
		setOffsetTop(elementRef?.current?.offsetTop || 0);
		setOffsetHeight(elementRef?.current?.offsetHeight || 0);
		setScrollHeight(elementRef?.current?.scrollHeight || 0);
	};
	const documentResizeObserver = window.ResizeObserver
		? new ResizeObserver(handleOffsetChanges)
		: null;
	//eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => {
		documentResizeObserver?.observe(window.document.body);
		elementRef?.current && setOffsetTop(elementRef.current.offsetTop);
		elementRef?.current && setOffsetHeight(elementRef.current.offsetHeight);
		elementRef?.current && setScrollHeight(elementRef.current.scrollHeight);
		return () => {
			documentResizeObserver?.unobserve(window.document.body);
		};
	});

	return { elementRef, offsetTop, offsetHeight, scrollHeight };
};
