import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, notifySuccess } from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import {
	getLocalAppsFromStorage,
	removeLocalApp,
	updateResourceContextWithLocalApps,
} from 'App/LocalSystemApps';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { isLocalApp, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export interface Props extends WithResourceContextProps {
	resource: Resource;
}

class _RemoveAppButton extends React.Component<Props> {
	public render() {
		if (isLocalApp(this.props.resource) && !this.props.resource.whitelisted) {
			return (
				<Button.TextPrimary onClick={this.removeApp}>
					{t('Workspace:localapp_manage.remove_app')}
				</Button.TextPrimary>
			);
		}
		return null;
	}

	private removeApp = async () => {
		trackAnalyticsEvent(
			AppsUserEventPayload.remove(EventSite.MoreInfo, this.props.resource.type)
		);
		const targetApp = this.props.resource;
		await removeLocalApp(targetApp.id);
		const localAppsAfterRemove = await getLocalAppsFromStorage();
		updateResourceContextWithLocalApps(this.props.resourceContext, localAppsAfterRemove);
		notifySuccess(t('Workspace:localapp_manage.notify_success'));
	};
}

export const RemoveAppButton = withResourceContext(_RemoveAppButton);
