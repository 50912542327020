import {
	layout01,
	layout02,
	mobileDefaultOneColumnLayout,
} from 'App/ComposableHome/layouts';
import {
	actionsIwsWidgetId,
	appsIwsWidgetId,
	appsLauncherWidgetId,
	desktopsIwsWidgetId,
	desktopsLauncherWidgetId,
	feedInfiniteIwsWidgetId,
} from 'App/Widgets/widgetRegistry';
import { ComposablePage } from './ComposablePage';

export const launcherPage: ComposablePage = {
	layout: layout01,
	widgets: {
		rows: [
			{
				columns: [
					{
						widgets: [
							{
								widgetId: appsLauncherWidgetId,
							},
							{
								widgetId: desktopsLauncherWidgetId,
							},
						],
					},
				],
			},
		],
	},
};

export const infiniteFeedPage: ComposablePage = getFeedPageModel(feedInfiniteIwsWidgetId);

function getFeedPageModel(feedWidgetId: string): ComposablePage {
	return {
		layout: layout02,
		widgets: {
			rows: [
				{
					columns: [
						{
							widgets: [
								{
									widgetId: feedWidgetId,
								},
							],
						},
						{
							widgets: [
								{
									widgetId: actionsIwsWidgetId,
								},
								{
									widgetId: appsIwsWidgetId,
								},
								{
									widgetId: desktopsIwsWidgetId,
								},
							],
						},
					],
				},
			],
		},
	};
}

export const mobileInfiniteFeedPage = {
	layout: mobileDefaultOneColumnLayout,
	widgets: {
		rows: [
			{
				columns: [
					{
						widgets: [
							{
								widgetId: feedInfiniteIwsWidgetId,
							},
						],
					},
				],
			},
		],
	},
};

export const mobileHomePage = {
	layout: mobileDefaultOneColumnLayout,
	widgets: {
		rows: [
			{
				columns: [
					{
						widgets: [
							{
								widgetId: actionsIwsWidgetId,
							},
							{
								widgetId: appsIwsWidgetId,
							},
							{
								widgetId: desktopsIwsWidgetId,
							},
						],
					},
				],
			},
		],
	},
};

export const mobileLauncherPage = {
	layout: mobileDefaultOneColumnLayout,
	widgets: {
		rows: [
			{
				columns: [
					{
						widgets: [
							{
								widgetId: appsLauncherWidgetId,
							},
							{
								widgetId: desktopsLauncherWidgetId,
							},
						],
					},
				],
			},
		],
	},
};
