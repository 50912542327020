import { Event } from 'Workspace/EventBus';

export enum FeedCardEventType {
	EXECUTE_FEED_CARD_ACTION = 'action/iws/feedCard/action/execute',
	FEED_CARD_ACTION_EXECUTED = 'iws/feedCard/executed',
}

export interface ExecuteFeedCardActionEvent
	extends Event<{
		feedCardId: string;
		sourceOrigin: string;
		action: {
			url: string;
		};
	}> {
	type: FeedCardEventType.EXECUTE_FEED_CARD_ACTION;
}

export interface FeedCardActionExecutedEvent
	extends Event<{
		sourceEvent: ExecuteFeedCardActionEvent;
		error?: {
			code: number;
			message?: string;
			details?: any[];
		};
	}> {
	type: FeedCardEventType.FEED_CARD_ACTION_EXECUTED;
}
