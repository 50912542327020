import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const shouldDisplayDesktopSection = (
	resources: Resource[],
	workspaceConfiguration: WorkspaceConfiguration
) => {
	if (
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.NoEmptyAppsOrEmptyDesktopSection)
	) {
		return resources?.some(({ isdesktop }) => isdesktop);
	}
	return true;
};
