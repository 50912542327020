export function addEventListener(
	eventName: string,
	callBackFunction: (e: CustomEvent) => void,
	option: boolean
) {
	if (window.pendo) {
		window.addEventListener(eventName, callBackFunction, option);
	}
}

export function removeEventListener(
	eventName: string,
	callBackFunction: (e: CustomEvent) => void,
	option: boolean
) {
	if (window.pendo) {
		window.removeEventListener(eventName, callBackFunction, option);
	}
}

export function addEventListenerByPendoGuideId(guideId: string) {
	if (window.pendo) {
		window.pendo.showGuideById(guideId);
	}
}
