import { layout, palette, Text, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

interface StyleImageProp {
	height: number;
	width: number;
}

export const StyledBannerItem = styled.div`
	display: flex;
	align-items: center;
	text-align: left;
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.regular};
	height: ${typography.sizes.base};
	cursor: pointer;
	gap: ${layout.tinySpace};
	color: ${palette.grey900};
`;

export const StyledBannerItemAnchor = styled.a({
	color: 'inherit',
	cursor: 'pointer',
	textDecoration: 'none',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	flexShrink: 2,
	'&:hover': {
		textDecoration: 'underline',
	},
});

export const StyledImage = styled.img<StyleImageProp>`
	width: ${props => props.width}px;
	height: ${props => props.height}px;
`;

export const StyledDiv = styled.div`
	display: flex;
	margin-bottom: 50px;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
`;
export const StyledTile = styled.div`
	display: flex;
	height: 50px;
	padding: ${layout.smallSpace} ${layout.mediumSpace};
	align-items: center;
`;
export const StyledTileText = styled.a`
	display: flex;
	text-decoration: none;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: ${layout.tinySpace};
	padding: 0 ${layout.mediumSpace};
	width: 80%;
	height: 100%;
`;

export const StyledTitleText = styled(Text)`
	text-align: start;
	font-weight: ${typography.weights.semiBold};
	font-size: ${typography.sizes.sml};
	color: ${palette.black};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	height: 100%;
	max-height: ${layout.mediumSpace};
`;

export const StyledLightText = styled(Text)`
	text-align: left;
	font-size: ${typography.sizes.xsml2};
	color: ${palette.black};
	font-weight: ${typography.weights.light};
	line-height: ${typography.sizes.xsml2};
	min-width: fit-content;
`;
