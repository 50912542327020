import { t } from '@citrite/translate';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { onlyIcaAppsAllowedInVanityDomain } from 'Environment/vanityDomain';
import { isCitrixBrowserApp } from 'Workspace/AppContext/citrixBrowserResource';
import {
	ListApiResult,
	Resource,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

export function loadResources(
	workspaceConfiguration: WorkspaceConfiguration,
	listApiResult: ListApiResult
) {
	return enhanceResources(filterResources(workspaceConfiguration, listApiResult));
}

function filterResources(
	workspaceConfiguration: WorkspaceConfiguration,
	listApiResult: ListApiResult
) {
	const configAllowsApps =
		workspaceConfiguration.userInterface.uiViews.showAppsView === 'true';
	const configAllowsDesktops =
		workspaceConfiguration.userInterface.uiViews.showDesktopsView === 'true';

	const { resources } = listApiResult;
	const shouldFilterNonIcaApps = onlyIcaAppsAllowedInVanityDomain(workspaceConfiguration);
	return resources.filter(resource => {
		if (!environment.supportsResource(resource)) {
			return false;
		}

		if (isCitrixBrowserApp(resource)) {
			return environment.supportsCitrixWorkspaceBrowserApp;
		}

		if (
			resource.keywords &&
			resource.keywords.includes('ShareFileUI') &&
			!hasFeatureCanary(workspaceConfiguration, FeatureFlag.ShowSharefileApp)
		) {
			return false;
		}

		if (
			shouldFilterNonIcaApps &&
			!resource.clienttypes?.some(type => type.toLowerCase().includes('ica'))
		) {
			return false;
		}

		const isWorkflowAppThatCannotBeSubscribed =
			!listApiResult.isSubscriptionEnabled &&
			resource.requiresworkflow &&
			resource.subscriptionstatus !== subscriptionStatus.subscribed;
		const isForbiddenByConfig =
			(resource.isdesktop && !configAllowsDesktops) ||
			(!resource.isdesktop && !configAllowsApps);

		return (
			!resource.disabled &&
			!resource.notsupport &&
			!isWorkflowAppThatCannotBeSubscribed &&
			!isForbiddenByConfig
		);
	});
}

function enhanceResources(resources: Resource[]): Resource[] {
	return resources.map(resource => {
		return isCitrixBrowserApp(resource)
			? enhanceCitrixBrowserResource(resource)
			: resource;
	});
}

function enhanceCitrixBrowserResource(resource: Resource): Resource {
	// The CEB resource published by SPA contains placeholder values for name and description.
	// Hence populating proper values here.
	return {
		...resource,
		name: t('Workspace:citrix_enterprise_browser.title'),
		description: t('Workspace:citrix_enterprise_browser.description'),
		iconurl: require('./images/citrix-browser-logo.svg'),
	};
}
