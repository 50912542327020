import * as React from 'react';
import { Context, NotificationContext } from './Context';

export const useNotifications = () => React.useContext(NotificationContext);

export interface WithNotificationProps {
	notificationsContext: Context;
}

export function withNotifications<T extends WithNotificationProps>(
	Component: React.ComponentType<T>
): React.FC<Omit<T, keyof WithNotificationProps>> {
	return props => {
		const notificationContext = useNotifications();
		return <Component {...(props as T)} notificationsContext={notificationContext} />;
	};
}
