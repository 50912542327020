import { fromThemeColors, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledItemList = styled.div`
	border-radius: 8px;
	background: ${fromThemeColors('primaryContainerBackground')};
	padding: ${layout.smallSpace} 0;
	margin-top: ${layout.mediumSpace};
	color: ${fromThemeColors('primaryText')};
`;

export const StyledFeedSelectorWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	height: 40px; /* same as the height of the Selector in the notification feed header */
`;

export const StyledViewAll = styled.div`
	margin: ${layout.mediumSpace} ${layout.mediumSpace} ${layout.smallSpace};
	font-weight: ${typography.weights.medium};
`;
