import { ClientInstance } from '@citrite/http';
import {
	DataFormatPreferences,
	EncryptedCacheBucket,
	EndpointSupplier,
	ProfilePictureLimitations,
	ProfilePicturePayload,
	ProfilePictures,
	UserPreferences,
} from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { environment } from 'Environment';
import {
	getLocaleDateFormat,
	getLocaleDecimalSeparator,
	getLocaleThousandSeparator,
	getLocaleTimeFormat,
} from 'userPersonalizationService/getFormats';

export class UserPreferenceService {
	public constructor(
		private endpointSupplier: EndpointSupplier,
		private ssoProxyClient: ClientInstance,
		private customerId: string,
		private storeId: string,
		private cache: EncryptedCacheBucket
	) {}

	private cacheKey = 'userPreferences';
	private profilePictureLimitationsCacheKey = 'profilePictureLimitations';

	public getUserPreferences = async () => {
		const userPreferences: UserPreferences = await this.cache.fetchEncryptedCacheFirst(
			this.cacheKey,
			this.fetchUserPreferences
		);
		if (userPreferences.dataFormats?.dateFormat == null) {
			userPreferences.dataFormats =
				(await this.setFTUFormats()) || userPreferences.dataFormats;
		}
		return userPreferences;
	};

	public fetchUserPreferences = async () => {
		const endpoint = await this.endpointSupplier.getEndpoint('GetUserPreferences');
		const userPreferences = await this.ssoProxyClient.get<UserPreferences>(endpoint, {
			headers: {
				'Citrix-CustomerId': this.customerId,
				'Citrix-InstanceId': this.storeId,
			},
		});
		return userPreferences;
	};

	public setDataFormats = async (dataFormats: DataFormatPreferences) => {
		const putEndpoint = await this.endpointSupplier.getEndpoint('SetDataFormats');
		const dataFormatPreferences = await this.ssoProxyClient.put<DataFormatPreferences>(
			putEndpoint,
			dataFormats,
			{
				headers: {
					'Citrix-CustomerId': this.customerId,
					'Citrix-InstanceId': this.storeId,
				},
			}
		);
		const userPreferencesCache = await this.cache.getEncrypted<UserPreferences>(
			this.cacheKey
		);
		userPreferencesCache.dataFormats = dataFormatPreferences;
		this.cache.setEncrypted(this.cacheKey, userPreferencesCache);
		return dataFormatPreferences;
	};

	public setFTUFormats = async () => {
		try {
			const dataFormat = {
				dateFormat: getLocaleDateFormat(),
				timeFormat: getLocaleTimeFormat(),
				thousandSeparator: getLocaleThousandSeparator(),
				decimalSeparator: getLocaleDecimalSeparator(),
			};
			await this.setDataFormats(dataFormat);
			return dataFormat;
		} catch (error) {
			logError(error, { tags: { feature: 'userPreferences' } });
			return null;
		}
	};

	public setProfilePicture = async (blob: Blob) => {
		const profilePictures = await this.postProfilePicture(blob);
		const userPreferencesCache = await this.cache.getEncrypted<UserPreferences>(
			this.cacheKey
		);
		userPreferencesCache.profilePicture = profilePictures;
		this.cache.setEncrypted(this.cacheKey, userPreferencesCache);
		return profilePictures;
	};

	private postProfilePicture = async (blob: Blob) => {
		const requestConfig = {
			headers: {
				'Citrix-CustomerId': this.customerId,
				'Citrix-InstanceId': this.storeId,
			},
		};
		let endpointId: string;
		let requestData: FormData | ProfilePicturePayload;
		if (environment.isNative) {
			// Native must send the image as a data URL as the native bridge does not support posting blobs.
			endpointId = 'OwnProfilePictureAsRequestBody';
			requestConfig.headers['Content-Type'] = 'application/json';
			requestData = {
				dataUri: await getBlobAsDataUri(blob),
			};
		} else {
			// Browser must send the image as a blob as the WSP WAF will reject requests to the SSO Proxy with bodies larger than 128KB (which the data URL requests often are)
			endpointId = 'OwnProfilePicture';
			requestData = new FormData();
			requestData.append('file', blob);
		}
		const endpoint = await this.endpointSupplier.getEndpoint(endpointId);
		return await this.ssoProxyClient.post<ProfilePictures>(
			endpoint,
			requestData,
			requestConfig
		);
	};

	public deleteProfilePicture = async () => {
		const endpoint = await this.endpointSupplier.getEndpoint('OwnProfilePicture');
		await this.ssoProxyClient.delete(endpoint, {
			headers: {
				'Citrix-CustomerId': this.customerId,
				'Citrix-InstanceId': this.storeId,
			},
		});
		const userPreferencesCache = await this.cache.getEncrypted<UserPreferences>(
			this.cacheKey
		);
		userPreferencesCache.profilePicture.items = null;
		this.cache.setEncrypted(this.cacheKey, userPreferencesCache);
	};

	public getProfilePictureLimitations = async () => {
		const profilePictureLimitations: ProfilePictureLimitations =
			await this.cache.fetchEncryptedCacheFirst(
				this.profilePictureLimitationsCacheKey,
				this.fetchProfilePictureLimitations
			);
		return profilePictureLimitations;
	};

	public fetchProfilePictureLimitations = async () => {
		const endpoint = await this.endpointSupplier.getEndpoint(
			'OwnProfilePictureLimitations'
		);
		const profilePictureLimitations =
			await this.ssoProxyClient.get<ProfilePictureLimitations>(endpoint, {
				headers: {
					'Citrix-CustomerId': this.customerId,
					'Citrix-InstanceId': this.storeId,
				},
			});
		return profilePictureLimitations;
	};
}

export async function getBlobAsDataUri(blob: Blob) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = _progressEvent => resolve(reader.result as string);
		reader.onerror = _progressEvent => reject(reader.error);
		reader.onabort = _progressEvent => reject(new Error('Read aborted'));
		reader.readAsDataURL(blob);
	});
}
