import { environment } from 'Environment';
import { reconnect } from 'Environment/reconnect';

/* CWAs call updateUserName before we can mount any component
 * that maps updateUserName to a setState call.
 * This variable lets us store the result from CWA while we wait
 * for react to mount and take over the updateUserName function.
 */
export let cwaUsername = '';

export let updateUserName = (username: string) => {
	cwaUsername = username;
};

export function setUserNameFunction(fn: typeof updateUserName) {
	updateUserName = fn;
}

type NativeLogoutCallback = () => void;
export const nativeLogoutCallbacks: NativeLogoutCallback[] = [];
/**
 * Add a logout callback that is executed when a logout is triggered in native app
 * @param fn The function to execute
 * @returns A callback to cleanup the function
 */
export function addNativeLogoutCallback(fn: NativeLogoutCallback) {
	nativeLogoutCallbacks.push(fn);
	return function removeCallback() {
		const index = nativeLogoutCallbacks.indexOf(fn);
		nativeLogoutCallbacks.splice(index, 1);
	};
}

let refreshResources: (_automaticRefresh: boolean) => void = () => {};

export function setRefreshResourcesFunction(fn: typeof refreshResources) {
	refreshResources = fn;
}

let refreshFeed: () => void = () => {};

export function setRefreshFeedFunction(fn: typeof refreshFeed) {
	refreshFeed = fn;
}

export function refreshApp(automaticRefresh = false) {
	if (!environment.citrixCloudConnected && !automaticRefresh) {
		reconnect();
		return;
	}
	if (!automaticRefresh) {
		const eventName = {
			id: 'refresh',
			type: 'action/refresh/manual',
			payload: {},
		};

		environment.sendEventToNative(eventName);
	}
	refreshResources(automaticRefresh);
	refreshFeed();
}

export function refreshAllResources() {
	if (!environment.citrixCloudConnected) {
		reconnect();
		return;
	}
	refreshResources(true);
}
