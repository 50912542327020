import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

enum DevicePostureState {
	NON_COMPLIANT = 'NON-COMPLIANT',
}

export function useDevicePostureDetails() {
	const { devicePosture } = useLoadableResourceContext().value;
	const shouldShowDevicePostureBanner = !!(
		devicePosture &&
		devicePosture.state === DevicePostureState.NON_COMPLIANT &&
		devicePosture.scanTransactionId
	);
	return { devicePosture, shouldShowDevicePostureBanner };
}
