import { IntegrationManifest } from '@citrite/workspace-ui-platform';

/**
 * Bucket manifest entries should be of the form `<bucketId>-<version>`
 * where <bucketId> is the unique identifier for the cache bucket
 * and <version> is incremented manually in code for breaking changes in the cache
 */
export enum BucketManifest {
	actions = 'actions-1',
	endpoints = 'endpoints-1',
	notifications = 'notifications-2',
	resources = 'resources-1',
	userPreferences = 'userPreferences-1',
	policyBranding = 'policyBranding-1',
	featureRollout = 'feature-rollout-1',
	/**
	 * Both 'composablePage' & 'pageComposition' buckets are used by the Composable Homepage
	 * feature.
	 * 'pageComposition' is used in conjunction with the remote storage (Page Composition Service).
	 * It will supersede 'composablePage' which is used for composable homepages stored
	 * in local storage only .
	 * */
	composablePage = 'composable-page-1',
	pageComposition = 'page-composition-1',
	adminNotification = 'admin-notification-1',
	ftu = 'ftu-1',
	featureFlagsCache = 'feature-flags-cache-1',
}

export const globalBucketPrefix = 'cacheBucket-';

const bucketIdSeparator = '#';
export function buildBucketCacheKey(bucketId: string) {
	return `${globalBucketPrefix}${bucketId}${bucketIdSeparator}`;
}

export function buildIntegrationCacheBucketId(integration: IntegrationManifest) {
	return `${integration.id}-${integration.cacheVersion}`;
}
