import { StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	flexContainer: {
		margin: '0 16px 0 16px',
	},
	emptyState: {
		background: palette.white,
		padding: '10px',
	},
	divider: {
		borderBottom: `0.5px solid ${palette.grey500}`,
		display: 'flex',
	},
	tabContainer: {
		height: '100%',
	},
});
