import * as React from 'react';
import { css } from 'aphrodite';
import { Region, Tabs, TabsProps } from '@citrite/web-ui-component';
import { styles } from './styles';

export const WidgetsTabsContainer: React.FC<TabsProps> = props => {
	return (
		<Region className={css(styles.tabContainer)}>
			{() => <Tabs {...props} className={css(styles.widgetTabs)} />}
		</Region>
	);
};
