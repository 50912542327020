import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

export enum EventType {
	Activity = 'ACTIVITY',
}

export interface TelemetryEvent {
	type: EventType;
}

export interface TelemetryClient {
	/**
	 * Send an event to Citrix Analytics Service (CAS). This event is populated for you
	 * with base-level properties from the current environment, device, user, etc. Your
	 * event parameter is used as the payload.
	 */
	publishEvent<T extends TelemetryEvent>(eventPayload: T): Promise<void>;
}

/**
 * @category Platform Dependency
 */
export const getTelemetryClient = () =>
	resolveFromGlobal(PlatformDependencies.TelemetryClient);
