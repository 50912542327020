import * as React from 'react';
import { palette } from '@citrite/web-ui-component';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import {
	StyledIcon,
	StyledLink,
	StyledLinkBody,
	StyledLinkText,
	StyledSecondaryIcon,
} from 'App/LeftNavigation/PrimaryNavigation/ChildLink.styled';

export interface ChildLinkBodyProps extends LeftNavLink {
	active: boolean;
}

export function ChildLinkBody(props: ChildLinkBodyProps) {
	const PrimaryIcon = props.icon;
	const SecondaryIcon = props.secondaryIcon;

	return (
		<StyledLinkBody>
			<StyledIcon>
				{PrimaryIcon && (
					<PrimaryIcon
						size={16}
						color={props.active ? palette.grey900 : palette.grey500}
					/>
				)}
			</StyledIcon>
			<StyledLink>
				<StyledLinkText
					withIcon={!!props.secondaryIcon}
					data-testid={`link-text-${props.title
						?.toLocaleLowerCase()
						.replace(/\s/g, '-')}`}
				>
					{props.title}
				</StyledLinkText>
				{SecondaryIcon && (
					<StyledSecondaryIcon>
						<SecondaryIcon size={16} />
					</StyledSecondaryIcon>
				)}
			</StyledLink>
		</StyledLinkBody>
	);
}
