import * as React from 'react';
import {
	IntegrationCapability,
	IntegrationContext,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { History, Location } from 'history';
import {
	Redirect as ReactRedirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from 'react-router-dom';
import { logError } from 'remoteLogging';
import { allActionsRoutePath } from 'App/Actions/allActionsRoute';
import { allAppsRoute } from 'App/Apps/allAppsRoute';
import { appShellRoute } from 'App/appShellRoute';
import { getErrorPageUrlForLanguage } from 'App/Common/routing';
import { allDesktopsRoute } from 'App/Desktops/allDesktopsRoute';
import { nativeWidgetHomePath } from 'App/NativeWidget/NativeWidgetRoute';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { isNativeWidget } from 'Environment/launchResource/device';
import { DefaultErrorPage } from 'ErrorPage/DefaultErrorPage';
import { useIntegrations } from 'Integrations/useIntegrations';
import { installLazyGetter } from 'javascript/interactiveTools';
import { ErrorLevel } from 'Loggers/LoggingProvider';
import { workspaceLoginRoute } from 'Login';
import { logoutRoute } from 'Logout';
import { homePagePath } from 'Router/homePageRoute';
import { FullPageRoute } from './FullPageRoute';
import { useNativeNavigationEventHandler } from './useNativeNavigationEventHandler';

export interface Props {
	integrations: IntegrationContext;
	history: History;
	location: Location;
	configuration: WorkspaceConfiguration;
}

interface State {
	hasError?: boolean;
}

class _Routes extends React.Component<Props, State> {
	public state: State = {};

	public render() {
		if (this.state.hasError) {
			return <DefaultErrorPage />;
		}

		const integrationRoutes = this.props.integrations
			.resolveByCapability(IntegrationCapability.fullPageRoute)
			.filter(r => r.registration.metadata?.path);
		return (
			<Switch>
				{integrationRoutes.map(route => (
					<Route
						key={route.moduleKey}
						path={route.registration.metadata.path}
						render={() => (
							<FullPageRoute
								metadata={route.registration.metadata}
								integration={route.value}
							/>
						)}
					/>
				))}
				<Route
					path={workspaceLoginRoute.paths}
					component={workspaceLoginRoute.component}
				/>
				<Route path={logoutRoute.paths} component={logoutRoute.component} />
				<ReactRedirect exact from="/nativedeeplink/allApps" to={allAppsRoute.getUrl()} />
				<ReactRedirect
					exact
					from="/nativedeeplink/allDesktops"
					to={allDesktopsRoute.getUrl()}
				/>
				<ReactRedirect exact from="/nativedeeplink/allActions" to={allActionsRoutePath} />
				<ReactRedirect exact from={appShellRoute.getUrl()} to={homePagePath} />
				{isNativeWidget() && (
					<ReactRedirect exact from={homePagePath} to={nativeWidgetHomePath} />
				)}

				<ReactRedirect from="/native=*" to={homePagePath} />
				<ReactRedirect
					exact
					from="/logouterror/noidpurl"
					to={logoutRoute.getUrl() + '?userHint=errorIdpLogout'}
				/>
				<Route component={appShellRoute.component} />
			</Switch>
		);
	}

	public componentDidMount() {
		installLazyGetter('history', () => this.props.history);
	}

	public componentDidCatch(error: Error, errorInfo: object) {
		logError(error, { additionalContext: { errorInfo, level: ErrorLevel.FATAL } });
		if (this.state.hasError) {
			location.assign(getErrorPageUrlForLanguage('jserror'));
		} else {
			this.setState({ hasError: true });
		}
	}
}

export function Routes() {
	const integrations = useIntegrations();
	const history = useHistory();
	const location = useLocation();
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	useNativeNavigationEventHandler();
	return (
		<_Routes
			integrations={integrations}
			history={history}
			location={location}
			configuration={workspaceConfiguration}
		/>
	);
}
