import * as React from 'react';
import {
	fromThemePalette,
	palette,
	WorkspaceLogoIcon,
	WorkspaceTextIcon,
} from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import { Branch } from './Branch';
import { StyledBannerRegion, StyledLogoLink } from './MastheadBanner.styled';

const logoIconColor = fromThemePalette({
	citrixbrand: palette.teal900,
	neutral: palette.blue800,
});

const textIconColor = fromThemePalette({
	citrixbrand: palette.teal900,
	neutral: palette.black,
});

export interface Props {
	collapseOnMobile?: boolean;
	fullPageRoute?: boolean;
	children?: React.ReactNode;
}

export function MastheadBanner(props: Props) {
	const theme = useTheme();
	return (
		<StyledBannerRegion
			fullPageRoute={props.fullPageRoute}
			collapseOnMobile={props.collapseOnMobile}
		>
			{id => (
				<>
					<StyledLogoLink
						collapseOnMobile={props.collapseOnMobile}
						to="/"
						aria-labelledby={id}
					>
						<div data-testid="workspaceLogo">
							<WorkspaceLogoIcon size={38} color={logoIconColor({ theme })} />
							<WorkspaceTextIcon width={204} color={textIconColor({ theme })} />
						</div>
						<h1 id={id}>{'Workspace'}</h1>
					</StyledLogoLink>
					<Branch />
					{props.children}
				</>
			)}
		</StyledBannerRegion>
	);
}
