import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_CITRIX_ENTERPRISE_BROWSER';
const preparePayload = (type: EventType) => ({
	type,
	code,
});
const createCitrixBrowserAnalyticsReporter = () => {
	return {
		getLaunchEvent: () => preparePayload(EventType.CitrixEnterpriseBrowser_Launch),
		getResourceAvailableEvent: () =>
			preparePayload(EventType.CitrixEnterpriseBrowser_ResourceAvailable),
	};
};
export const citrixBrowserAnalyticsReporter = createCitrixBrowserAnalyticsReporter();
