import * as React from 'react';
import { hasFeatureCanary, NativePlatform } from '@citrite/workspace-ui-platform';
import {
	CacheStrategy,
	cacheStrategyKey,
	MessageTypes,
} from '@citrite/workspace-ui-serviceworker';
import { logError } from 'remoteLogging';
import URI from 'urijs';
import { Workbox, WorkboxMessageEvent } from 'workbox-window';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { shouldEnableServiceWorker } from 'Environment/Capability';
import { ChromeAppMessageIdKey } from 'Environment/ChromeApp/types';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isCitrixChromeApp } from 'Environment/launchResource/device';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { useServiceWorkerCacheValidation } from 'Workspace/useServiceWorkerCacheValidation';
import {
	isPerformanceOptimizationEnabled,
	staleWhileRevalidateStrategyFlagRecord,
} from 'Workspace/utils';
const reloadWorkspace = () => {
	window.location.reload();
};

const reloadWorkspaceOnHide = () => {
	document.onvisibilitychange = () => {
		if (document.visibilityState === 'hidden') {
			reloadWorkspace();
		}
	};
};

export const ServiceWorkerDetection: React.FC = () => {
	const serviceWorkerSupported = 'serviceWorker' in navigator;
	const chromeAppKey = getFromLocalStorage<string>(ChromeAppMessageIdKey);
	const checkChromeApp = isCitrixChromeApp() && chromeAppKey === null;
	if (checkChromeApp || !serviceWorkerSupported) {
		return null;
	}
	return <ServiceWorkerSupport />;
};

const ServiceWorkerSupport: React.FC = () => {
	const { workspaceConfiguration } = useConfigurationContext();

	const serviceWorkerEnabled =
		environment.nativePlatform === NativePlatform.iOS
			? hasFeatureCanary(workspaceConfiguration, FeatureFlag.ServiceWorkeriOS)
			: shouldEnableServiceWorker();
	const staleWhileRevalidateAllowed = isPerformanceOptimizationEnabled(
		workspaceConfiguration,
		staleWhileRevalidateStrategyFlagRecord
	);
	const serviceWorkerUri = new URI(SERVICEWORKER_PATH);
	if (staleWhileRevalidateAllowed) {
		serviceWorkerUri.setSearch(cacheStrategyKey, CacheStrategy.staleWhileRevalidate);
	}
	const serviceWorkerURL = serviceWorkerUri.toString();
	const serviceWorkerFullPath = serviceWorkerUri.absoluteTo(location.href).toString();

	const [workboxInstance, setWorkboxInstance] = React.useState<Workbox | void>(null);

	React.useEffect(() => {
		if (serviceWorkerEnabled) {
			registerWorkspaceServiceWorker(serviceWorkerURL).then(newWorkbox => {
				setWorkboxInstance(newWorkbox);
			});
			registerRootServiceWorker(ROOT_SERVICEWORKER_PATH);
		} else {
			unregisterWorkspaceServiceWorker(serviceWorkerURL).then(() =>
				setWorkboxInstance(null)
			);
			unregisterRootServiceWorker();
		}
	}, [serviceWorkerURL, serviceWorkerEnabled]);

	const hasCacheValidation =
		serviceWorkerEnabled &&
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.ServiceWorkerCacheValidation);

	const unregisterServiceWorkerToQueueCacheRepopulation = React.useCallback(() => {
		unregisterWorkspaceServiceWorker(serviceWorkerURL);
	}, [serviceWorkerURL]);

	useServiceWorkerCacheValidation(
		hasCacheValidation,
		serviceWorkerFullPath,
		unregisterServiceWorkerToQueueCacheRepopulation
	);

	React.useEffect(() => {
		let cleanUp = () => {};
		const onMessage = (event: WorkboxMessageEvent) => {
			const data = event?.data;
			if (data?.type === MessageTypes.ReceiverHtmlValidationResult && !data.valid) {
				if (document.visibilityState !== 'visible' || !document.visibilityState) {
					reloadWorkspace();
				} else {
					reloadWorkspaceOnHide();
				}
			}
		};
		if (workboxInstance) {
			workboxInstance.addEventListener('message', onMessage);
			cleanUp = () => {
				workboxInstance.removeEventListener('message', onMessage);
			};
			setTimeout(
				() =>
					workboxInstance.messageSW({ type: MessageTypes.ReceiverHtmlValidationRequest }),
				5000
			);
		}
		return cleanUp;
	}, [workboxInstance]);

	return null;
};

async function registerWorkspaceServiceWorker(
	scriptUri: string
): Promise<Workbox | void> {
	return import('workbox-window')
		.then(({ Workbox: WorkboxClass }) => {
			const workbox = new WorkboxClass(scriptUri);

			workbox.register();
			return workbox;
		})
		.catch(logError);
}

const rootScope = '/';

const avoidRootServiceWorker = () => IS_ON_PREM;

function registerRootServiceWorker(scriptUri: string) {
	if (avoidRootServiceWorker()) {
		return;
	}

	navigator.serviceWorker
		.register(scriptUri, {
			scope: rootScope,
		})
		.catch(logError);
}

async function unregisterWorkspaceServiceWorker(scriptUri: string) {
	return navigator.serviceWorker
		.getRegistration(scriptUri)
		.then(sw => {
			sw.unregister();
		})
		.catch(() => {});
}

function unregisterRootServiceWorker() {
	if (avoidRootServiceWorker()) {
		return;
	}

	navigator.serviceWorker
		.getRegistrations()
		.then(registrations => {
			const rootSw = registrations.find(reg => reg.scope === location.origin + rootScope);
			return rootSw?.unregister();
		})
		.catch(() => {});
}
