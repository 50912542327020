import * as React from 'react';
import { t } from '@citrite/translate';
import {
	LoadingOverlayPrimary,
	LoadingOverlaySecondary,
} from '@citrite/web-ui-component';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { createLeftNavRoute } from 'Router/ReactRoute';

const AllApps = makeAsync(() => {
	return import(/* webpackChunkName: "AppsAndDesktops" */ './AppsWithQuickFilter').then(
		m => m.AppsWithQuickFilter
	);
}, <FlagSwitchedLoadingOverlay />);

function FlagSwitchedLoadingOverlay() {
	const { workspaceConfiguration } = useConfigurationContext();
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav) ? (
		<LoadingOverlayPrimary />
	) : (
		<LoadingOverlaySecondary />
	);
}

export const allAppsRoute = createLeftNavRoute({
	component: AllApps,
	path: '/apps/all',
	getNavLinkProps: () => ({
		title: t('Workspace:all_apps'),
		performanceMark: 'AllAppsLink_Click',
	}),
});
