import { ClientInstance } from '@citrite/http';
import { Nullable } from '@citrite/utility-types';
import { EncryptedCacheBucket, EndpointSupplier } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { UserBranding } from 'Workspace/UserContext/Context';

export class PolicyBrandingService {
	public constructor(
		private endpointSupplier: EndpointSupplier,
		private ssoProxyClient: ClientInstance,
		private customerId: string,
		private storeId: string,
		private cache: EncryptedCacheBucket
	) {}

	private cacheKey = 'user-branding';

	public getUserBranding = () => {
		return this.cache.fetchEncryptedCacheFirst<Nullable<UserBranding>>(
			this.cacheKey,
			async cancelUpdate => {
				try {
					return await this.fetchUserBranding();
				} catch (e) {
					logError(e, { tags: { feature: 'policyBranding' } });
					return cancelUpdate();
				}
			}
		);
	};

	public fetchUserBranding = async () => {
		const endpoint = await this.endpointSupplier.getEndpoint('GetBrandingForUser');
		const branding = this.ssoProxyClient.get<UserBranding>(endpoint, {
			headers: {
				'Citrix-CustomerId': this.customerId,
				'Citrix-InstanceId': this.storeId,
			},
		});
		return branding;
	};
}
