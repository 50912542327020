import * as React from 'react';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	onSwipeLeft?(): void;
	onSwipeRight?(): void;
}

interface TouchCoordinates {
	x?: number;
	y?: number;
}

enum SwipeDirection {
	left,
	right,
}

export class Swipe extends React.Component<Props> {
	private touchStart: TouchCoordinates;

	public render() {
		const { onSwipeLeft, onSwipeRight, ...divProps } = this.props;
		return (
			<div
				{...divProps}
				onTouchStart={this.swipeStart}
				onTouchMove={this.swipe}
				onTouchEnd={this.swipeEnd}
			/>
		);
	}

	private swipeStart = (event: React.TouchEvent<HTMLDivElement>) => {
		this.touchStart = {
			x: event.touches[0].clientX,
			y: event.touches[0].clientY,
		};
	};

	private swipe = (event: React.TouchEvent<HTMLDivElement>) => {
		if (this.touchStart) {
			const swipe = this.handleSwipe(event);
			this.swipeEnd();
			switch (swipe) {
				case SwipeDirection.right:
					this.props.onSwipeRight();
					break;
				case SwipeDirection.left:
					this.props.onSwipeLeft();
					break;
			}
		}
	};

	private handleSwipe = (event: React.TouchEvent<HTMLDivElement>) => {
		const xDown = this.touchStart.x;
		const yDown = this.touchStart.y;
		const xUp = event.touches[0].clientX;
		const yUp = event.touches[0].clientY;
		const xDiff = xDown - xUp;
		const yDiff = yDown - yUp;
		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			return xDiff < 0 ? SwipeDirection.right : SwipeDirection.left;
		}

		return undefined;
	};

	private swipeEnd = () => {
		this.touchStart = null;
	};
}
