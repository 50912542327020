import * as React from 'react';
import { NativeAccount, NativeAccounts } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { EventType, UpdateAccountsEvent, useEventBus } from 'Components/EventBus';
import { environment } from 'Environment';
import { Event } from 'Workspace/EventBus';

export function useWorkspaceAccounts() {
	const eventBus = useEventBus();
	const [accounts, setAccounts] = React.useState<NativeAccount[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		async function getInitialAccounts() {
			try {
				const nativeAccounts: NativeAccounts = JSON.parse(
					await environment.getAccountsAsync()
				) as NativeAccounts;
				setAccounts(nativeAccounts.accounts);
			} catch (e) {
				logError(e);
			} finally {
				setLoading(false);
			}
		}
		getInitialAccounts();

		const unsubscribe = eventBus.subscribe((event: Event<any>) => {
			if (isUpdateAccountsEvent(event)) {
				setAccounts(event.payload.accounts);
			}
		});

		return unsubscribe;
	}, [eventBus]);
	return { loading, accounts };
}

function isUpdateAccountsEvent(event: Event<any>): event is UpdateAccountsEvent {
	return event.type === EventType.UPDATE_ACCOUNTS;
}
