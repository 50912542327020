export function getEncryptionParams(
	crypto: {
		getRandomValues: Crypto['getRandomValues'];
	},
	_iv?: ArrayBuffer
): AesGcmParams {
	const iv = _iv || crypto.getRandomValues(new Uint8Array(12));
	return {
		name: 'AES-GCM',
		iv,
		tagLength: 128,
	};
}

export function getEncryptionKeyData(encryptionKey: string) {
	const decodedKey = atob(encryptionKey);
	return hexToArrayBuffer(decodedKey);
}

function hexToArrayBuffer(hexString: string) {
	const buffer = new ArrayBuffer(hexString.length / 2);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < hexString.length; i += 2) {
		view[i / 2] = parseInt(hexString.substring(i, i + 2), 16);
	}
	return buffer;
}

export function stringToArrayBuffer(str: string) {
	const safeStr = str ?? '';
	const buf = new ArrayBuffer(safeStr.length * 2);
	const bufView = new Uint16Array(buf);
	for (let i = 0; i < safeStr.length; i++) {
		bufView[i] = safeStr.charCodeAt(i);
	}
	return buf;
}

export function base64StringToArrayBuffer(str: string) {
	const binaryString = atob(str);
	const buf = new ArrayBuffer(binaryString.length);
	const bufView = new Uint8Array(buf);
	for (let i = 0; i < binaryString.length; i++) {
		bufView[i] = binaryString.charCodeAt(i);
	}
	return buf;
}

export function arrayBufferToBase64String(buf: ArrayBuffer) {
	let binaryString = '';
	const bytes = new Uint8Array(buf);
	for (let i = 0; i < bytes.byteLength; i++) {
		binaryString += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binaryString);
}

export function arrayBufferToHexString(buf: ArrayBuffer) {
	let hexOutput = '';
	const bytes = new Uint8Array(buf);
	for (let i = 0; i < bytes.byteLength; i++) {
		hexOutput += (bytes[i] < 16 ? '0' : '') + bytes[i].toString(16);
	}
	return hexOutput;
}

export function arrayBufferToString(buffer: ArrayBuffer) {
	const bufView = new Uint16Array(buffer);
	const length = bufView.length;
	let result = '';
	let addition = Math.pow(2, 16) - 1;

	for (let i = 0; i < length; i += addition) {
		if (i + addition > length) {
			addition = length - i;
		}
		result += String.fromCharCode.apply(
			null,
			Array.from(bufView.subarray(i, i + addition))
		);
	}

	return result;
}
