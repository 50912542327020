import * as React from 'react';
import { trackEvent } from 'analytics';
import { setLoggingTags, setUserId } from 'remoteLogging';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { inLegacyOfflineAppcacheMode } from 'Environment/setPublicPath';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useUserContext } from 'Workspace/UserContext/Consumer';

export function useRemoteLoggingUserReporting() {
	const { userDetails, isIWSEnabled } = useUserContext();
	const { workspaceConfiguration } = useConfigurationContext();
	const canShareCustomerIdInSentry = useFeatureCanary(
		FeatureFlag.ShareCustomerIdInSentry
	);

	React.useEffect(() => {
		const tags = {
			'workspace-intelligence': isIWSEnabled ? 'enabled' : 'disabled',
		};

		if (canShareCustomerIdInSentry) {
			tags['customer-id'] = workspaceConfiguration?.storeIdentifiers?.customerId;
		}
		setUserId(userDetails?.userId);
		setLoggingTags(tags, {
			global: true,
		});
	}, [
		userDetails,
		isIWSEnabled,
		workspaceConfiguration.storeIdentifiers,
		canShareCustomerIdInSentry,
	]);

	React.useEffect(() => {
		if (inLegacyOfflineAppcacheMode()) {
			trackEvent('LoadedFromLegacyOfflineAppcache', {
				CWA: environment.nativePlatform,
			});
		}
	}, []);
}
