import * as React from 'react';
import { t } from '@citrite/translate';
import {
	ActivityManagerIcon,
	Button,
	ButtonProps,
	FlyoverBuilder,
	layout,
	palette,
	styleUtils,
} from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import { ActivityManager } from 'App/Activity/ActivityManager/ActivityManager';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useUserContext } from 'Workspace/UserContext';

const activityManagerPopOverStyle: React.CSSProperties = {
	display: 'flex',
	backgroundColor: palette.white,
	border: '0.5px solid',
	borderColor: palette.grey500,
	boxShadow: styleUtils.shadow2.boxShadow,
	borderRadius: layout.tinySpace,
	scrollbarWidth: 'thin',
};

const buttonStyle = { lineHeight: 0, display: 'inline-block' };

export const ActivityManagerLauncher = () => {
	const { isSmallFormFactor } = useFormFactor();
	const isActivityManagerSupported =
		useUserContext().isActivityManagerEnabled && !isSmallFormFactor;
	const theme = useTheme();
	return (
		isActivityManagerSupported && (
			<>
				<FlyoverBuilder
					closeOnFlyoverClick={false}
					toggleOnClick
					style={activityManagerPopOverStyle}
					flyover={<ActivityManager />}
				>
					{(props: ButtonProps) => (
						<Button.IconOnly
							{...props}
							style={buttonStyle}
							icon={ActivityManagerIcon}
							size={25}
							title={t('Workspace:activity_manager.activity')}
							color={theme.branding.styles.HeaderTextColor}
							aria-label={t('Workspace:activity_manager.activity')}
							data-testid="activityManager"
							id="activity-manager"
						/>
					)}
				</FlyoverBuilder>
			</>
		)
	);
};
