import { ScopedCacheFactory } from './CacheFactory';
import { ClientLogger } from './ClientLogger';
import { DependencySet, DependencyToken } from './container';
import { EndpointSupplier } from './EndpointSupplier';
import { SsoProxyClient } from './SsoProxyClient';

interface ScopedPlatformDependenciesTokens {
	/**
	 * An isolated HTTP client that is configured to make requests via the Workspace
	 * Platform SSO Proxy URL to support the CitrixAuth protocol to your backend
	 */
	SsoProxyClient: DependencyToken<SsoProxyClient>;
	/**
	 * Provides a {@link EndpointSupplier} that is configured specifically for your integration
	 * when a required endpoint is provided with your {@link Integration}.
	 */
	EndpointSupplier: DependencyToken<EndpointSupplier>;
	/**
	 * Provides a cache factory for creating encrypted and unencrypted cache buckets scoped
	 * to your integration.
	 */
	CacheFactory: DependencyToken<ScopedCacheFactory>;
	/**
	 * Provides a scoped logger with user id and integration tags pre-configured.
	 */
	Logger: DependencyToken<ClientLogger>;
}

export type ScopedPlatformDependencySet = DependencySet<ScopedPlatformDependenciesTokens>;

export const ScopedPlatformDependencies: ScopedPlatformDependenciesTokens = {
	SsoProxyClient: 'wsui_scoped_SsoProxyClient',
	EndpointSupplier: 'wsui_scoped_EndpointSupplier',
	CacheFactory: 'wsui_scoped_CacheFactory',
	Logger: 'wsui_scoped_ScopedLogger',
};
