import * as React from 'react';
import { getLanguage, t } from '@citrite/translate';
import * as badges from './icons';

export function getAppleBadge() {
	const altText = t('javascript:tools.apple_downlod');
	switch (getLanguage()) {
		case 'es':
			return <badges.AppleEsIcon alt={altText} />;
		case 'ja':
			return <badges.AppleJaIcon alt={altText} />;
		case 'de':
			return <badges.AppleDeIcon alt={altText} />;
		case 'fr':
			return <badges.AppleFrIcon alt={altText} />;
		case 'zh-cn':
			return <badges.AppleZhCnIcon alt={altText} />;
		default:
			return <badges.AppleEnIcon alt={altText} />;
	}
}
