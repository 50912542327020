//tslint:disable
export function AppSearchNamespace(preferredLanguage: string, addResourceBundle = require('@citrite/translate').addResourceBundle) {
	let language: Promise<object>;
	switch (preferredLanguage) {
		case 'de':
			language = import('./de.json');
			break;
		case 'es':
			language = import('./es.json');
			break;
		case 'fi':
			language = import('./fi.json');
			break;
		case 'fr':
			language = import('./fr.json');
			break;
		case 'it':
			language = import('./it.json');
			break;
		case 'ja':
			language = import('./ja.json');
			break;
		case 'ko':
			language = import('./ko.json');
			break;
		case 'nl':
			language = import('./nl.json');
			break;
		case 'pt-BR':
			language = import('./pt-BR.json');
			break;
		case 'ru':
			language = import('./ru.json');
			break;
		case 'zh-CN':
			language = import('./zh-CN.json');
			break;
		case 'zh-TW':
			language = import('./zh-TW.json');
			break;
		default:
			language = import('./en.json');
	}

	return language.then(json => {
		addResourceBundle(preferredLanguage, 'App/Search', json);
	})
}