export enum launchMethod {
	html5,
	icaFile,
	protocolHandler,
}

export enum platform {
	ios,
	ipadOS,
	android,
	androidTablet,
	windows,
	mac,
	linux,
	windowsMobile,
	chromeOS,
	nativeWidget,
	other,
}
