import { isVanityDomain } from '@citrite/workspace-ui-platform';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { clientType, getClientType } from 'Environment/getClientType';
import { shouldPromptUserToInstallBrowserExtension } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';

export const WorkspaceWebExtensionPromptValues = {
	always: 'Always',
	optional: 'Optional',
	never: 'Never',
};

export const useBrowserExtensionPrompt = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { workspaceWebExtensionPrompt } = workspaceConfiguration?.pluginAssistant?.html5; // Admin settings from CC Console: can be always, optional, never
	const shouldPromptModalBasedOnAdminSettings =
		workspaceWebExtensionPrompt === WorkspaceWebExtensionPromptValues.always ||
		workspaceWebExtensionPrompt === WorkspaceWebExtensionPromptValues.optional;

	const currentClientType = getClientType();
	const clientTypeIsHTML5 = currentClientType === clientType.html5;
	const clientTypeIsBrowserExtension = currentClientType === clientType.browserextension;

	return (
		!isVanityDomain() &&
		shouldPromptModalBasedOnAdminSettings &&
		shouldPromptUserToInstallBrowserExtension(workspaceConfiguration) &&
		!clientTypeIsHTML5 &&
		!clientTypeIsBrowserExtension
	);
};
