import { isFailedResponse } from '@citrite/http';
import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { container } from 'Workspace/DependencyManagement';
import { BaseLogger } from './BaseLogger';
import {
	AddBreadcrumb,
	LogError,
	LogInfo,
	SetLoggingTags,
	SetUserId,
} from './LoggingProvider';

export class MultiLogger extends BaseLogger {
	private loggingEngines: BaseLogger[];

	public constructor(...loggingEngines: BaseLogger[]) {
		super();

		if (!loggingEngines || loggingEngines.length === 0) {
			this.loggingEngines = [new BaseLogger()];
			return;
		}

		this.loggingEngines = loggingEngines;
	}

	public logInfo: LogInfo = (message, additionalContext) => {
		this.shouldLogInfo() &&
			this.loggingEngines.forEach(logger => logger.logInfo(message, additionalContext));
	};

	public logError: LogError = (payload, options = {}) => {
		this.shouldLogError(payload) &&
			this.loggingEngines.forEach((logger, idx) =>
				logger.logError(payload, options, idx !== 0)
			);
	};

	public addBreadcrumb: AddBreadcrumb = breadcrumb => {
		this.loggingEngines.forEach(logger => logger.addBreadcrumb(breadcrumb));
	};

	public configureScope = (callback: (scope: any) => void) => {
		this.loggingEngines.forEach(logger => logger.configureScope(callback));
	};

	public setLoggingTags: SetLoggingTags = (tags, options) => {
		this.loggingEngines.forEach(logger => logger.setLoggingTags(tags, options));
	};

	public setUserId: SetUserId = id => {
		this.loggingEngines.forEach(logger => logger.setUserId(id));
	};

	private shouldLogInfo = (): boolean => {
		return container
			.resolve(PlatformDependencies.WorkspaceConfiguration)
			.hasFeatureCanary(FeatureFlag.EnableInfoLogging);
	};

	private shouldLogError = (payload: any): boolean => {
		return !isFailedResponse(payload) || this.shouldLogInfo();
	};
}
