import { isFailedResponse } from '@citrite/http';
import { makeGuid } from 'javascript/sf/guid';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';
import { ExtraContext } from './LoggingProvider';

export function getErrorGroupId() {
	const key = 'errorgroup';
	let id = getFromSessionStorage<string>(key);
	if (!id) {
		id = makeGuid();
		setInSessionStorage(key, id);
	}
	return id;
}

function sanitizeErrors(message: any): any {
	// message is Error-like
	if (message && message.message) {
		return {
			...message,
			message: sanitizeErrors(message.message),
		};
	}

	if (typeof message !== 'string') {
		return message;
	}

	const replacement = message
		?.replace(
			/https?\:\/\/([^\.]*)\.cloud(burrito)?\.com\//gi,
			'https://{customer_subdomain}.cloud$2.com/'
		)
		.replace(/serviceWorker\..{10}\.js/gi, 'serviceWorker.js');

	return replacement;
}

export function parseErrorForLogging(
	unTypedError: any,
	customMessage?: string,
	sanitizeStrings = false
): {
	error: Error;
	extractedContext?: ExtraContext;
} {
	const createCustomLoggedMessage = (
		passedMessage: string,
		internalErrorMessage?: string
	) =>
		internalErrorMessage ? `${passedMessage} (${internalErrorMessage})` : passedMessage;

	if (customMessage && sanitizeStrings) {
		customMessage = sanitizeErrors(customMessage);
	}

	if (isFailedResponse(unTypedError)) {
		const failedResponse = unTypedError;
		const { error: axiosError, ...extractedContext } = failedResponse;
		extractedContext['Citrix-TransactionId'] =
			axiosError.config?.headers?.['Citrix-TransactionId'];

		const axiosErrorMessage = sanitizeStrings
			? sanitizeErrors(axiosError.message)
			: axiosError.message;

		const apiError = new Error(
			customMessage
				? createCustomLoggedMessage(customMessage, axiosErrorMessage)
				: axiosErrorMessage
		);
		apiError.name = 'ApiError';
		return {
			error: apiError,
			extractedContext,
		};
	}

	const bareError = sanitizeStrings ? sanitizeErrors(unTypedError) : unTypedError;

	return {
		error: customMessage
			? normalizeMessageFromError(
					createCustomLoggedMessage(customMessage, unTypedError?.message),
					bareError
			  )
			: bareError,
	};
}

export function normalizeMessageFromError(message: string, error: Error) {
	const created = new Error(message);
	created.stack = error?.stack;
	return created;
}
