import * as React from 'react';
import { t } from '@citrite/translate';
import {
	EmptyComputerIcon,
	EmptyState,
	LoadingIconPrimary,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import {
	ContextMenuItemBuilder,
	MobileTab,
	MobileTabsContainer,
} from '@citrite/workspace-ui-platform-react';
import {
	NativeMobileResourceItemProps,
	NativeMobileResourceRow,
} from 'Components/NativeMobileResourceRow';
import { performance } from 'Components/performance';
import { ResourceIcon } from 'Components/ResourceIcon';
import { useDesktopContextMenuFactory } from 'Components/ResourceTile/useDesktopContextMenuFactory';
import { launchResource } from 'Environment/launchResource';
import { getRecentResources } from 'Environment/recents';
import { RoutedComponentProps } from 'Router/ReactRoute';
import {
	ResourceContextProvider,
	useLoadableResourceContext,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export enum NativeMobileTabCategory {
	all,
	recents,
}

export type Props = RoutedComponentProps & {
	loadableResourceContext: ResourceContextProvider;
	contextMenuBuilder: ContextMenuItemBuilder<Resource>;
};

interface State {
	activeTab: NativeMobileTabCategory;
}

class _NativeMobileDesktops extends React.Component<Props, State> {
	public state: State = {
		activeTab: NativeMobileTabCategory.recents,
	};

	public render() {
		if (this.props.loadableResourceContext.loading) {
			return <LoadingIconPrimary />;
		}

		const desktops = this.props.resourceContext.resources.filter(
			resource => resource.isdesktop
		);
		const recentDesktops = getRecentResources(desktops);
		performance.mark(performance.events.MobileDesktopsPage_Rendered);
		performance.measure(
			performance.events.MobileDesktopsPage_Rendered,
			performance.events.MobileDesktopsLink_Click,
			performance.events.MobileDesktopsPage_Rendered
		);
		return (
			<MobileTabsContainer activeTab={this.state.activeTab} onChange={this.changeTab}>
				{[
					<MobileTab
						key={NativeMobileTabCategory.recents}
						value={NativeMobileTabCategory.recents}
						label={t('Workspace:recents')}
					>
						{recentDesktops.length > 0 ? (
							recentDesktops.map(desktop => (
								<NativeMobileResourceRow
									key={desktop.id}
									item={this.transformResourceToItem(desktop)}
									contextMenuBuilder={this.props.contextMenuBuilder}
								/>
							))
						) : (
							<EmptyState icon={EmptyComputerIcon}>
								{t('Workspace:empty_recent_desktops')}
							</EmptyState>
						)}
					</MobileTab>,
					<MobileTab
						key={NativeMobileTabCategory.all}
						value={NativeMobileTabCategory.all}
						label={t('Workspace:all_desktops')}
					>
						{desktops.length > 0 ? (
							desktops.map(desktop => (
								<NativeMobileResourceRow
									key={desktop.id}
									item={this.transformResourceToItem(desktop)}
									contextMenuBuilder={this.props.contextMenuBuilder}
								/>
							))
						) : (
							<EmptyState icon={EmptyComputerIcon}>
								{t('Workspace:no_desktops')}
							</EmptyState>
						)}
					</MobileTab>,
				]}
			</MobileTabsContainer>
		);
	}

	public componentDidMount() {
		this.props.setMobileMastheadTitle(t('Workspace:desktops'));
	}

	private changeTab = (activeTab: NativeMobileTabCategory) => {
		this.setState({ activeTab });
	};

	private onPrimaryAction = (desktop: Resource) => {
		return launchResource({
			resource: desktop,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
		});
	};

	private transformResourceToItem = (
		desktop: Resource
	): NativeMobileResourceItemProps => {
		return {
			title: desktop.name,
			id: desktop.id,
			icon: <ResourceIcon resource={desktop} size={36} />,
			onPrimaryAction: () => this.onPrimaryAction(desktop),
			data: desktop,
			isLoading: this.isLoading,
		};
	};

	private isLoading = (desktop: Resource): boolean => {
		const launchInProgress =
			this.props.resourceContext.isInProgress(desktop.id) ||
			this.props.resourceContext.desktopRestartInProgressIds.includes(desktop.id);
		return launchInProgress;
	};
}

export const NativeMobileDesktops = withModals(
	({ showModal, ...routedComponentProps }: WithModalsProps & RoutedComponentProps) => {
		const builder = useDesktopContextMenuFactory({ showModal });
		const loadableResourceContext = useLoadableResourceContext();
		return (
			<_NativeMobileDesktops
				{...routedComponentProps}
				contextMenuBuilder={builder}
				loadableResourceContext={loadableResourceContext}
			/>
		);
	}
);
