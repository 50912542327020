import React, { useEffect, useRef, useState } from 'react';

const targetMap = new Map<Element, React.Dispatch<React.SetStateAction<boolean>>>();
const observer = new IntersectionObserver(
	entries => {
		entries.forEach(entry => {
			if (entry.intersectionRatio > 0 && targetMap.has(entry.target)) {
				targetMap.get(entry.target)(true);
			}
		});
	},
	{ threshold: 0.01 }
);

export const useInScrolledView = () => {
	const [inScrolledView, setInScrolledView] = useState(false);
	const elementRef = useRef(null);

	useEffect(() => {
		let unObserver = () => {};

		if (elementRef.current && !inScrolledView) {
			if (!targetMap.has(elementRef.current)) {
				targetMap.set(elementRef.current, setInScrolledView);
			}
			observer.observe(elementRef.current);
			unObserver = () => {
				observer.unobserve(elementRef.current);
				targetMap.delete(elementRef.current);
			};
		}

		return unObserver;
	}, [elementRef, inScrolledView]);

	return { inScrolledView, elementRef };
};
