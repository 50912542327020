import * as React from 'react';
import { css } from 'aphrodite';
import {
	CitrixLogoIcon,
	fromThemePalette,
	palette,
	WorkspaceLogoIcon,
	WorkspaceTextIcon,
} from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import { StyledWorkspaceLogo } from 'Components/FullscreenCard/FullscreenCard.styled';
import { withBranding, WithBrandingProps } from 'Configuration/withBranding';
import { styles } from './FullscreenCard.styles';

const logoIconColor = fromThemePalette({
	citrixbrand: palette.teal900,
	neutral: palette.blue800,
});

const textIconColor = fromThemePalette({
	citrixbrand: palette.teal900,
	neutral: palette.black,
});

export interface Props extends React.HTMLAttributes<HTMLElement> {
	children?: React.ReactNode;
	centerContent?: boolean;
}

function _FullscreenCard(props: WithBrandingProps & Props & { forwardRef: any }) {
	const theme = useTheme();
	const { branding, children, forwardRef, centerContent, ...htmlProps } = props;
	return (
		<section {...htmlProps} ref={forwardRef}>
			<div
				className={css(styles.accentBar)}
				style={{ backgroundColor: branding.styles.AccentColor }}
			/>
			<div className={css(styles.page)}>
				<div className={css(styles.innerPage)}>
					<div className={css(styles.content)}>
						<div className={css(styles.container)}>
							<div
								className={css(styles.logoContainer)}
								style={{
									backgroundColor: branding.styles.HeaderBackgroundColor,
								}}
							>
								<div className={css(styles.logo)}>
									{branding.links.LoginLogoUrl ? (
										// The only meaningful alt text here would be the customer/company name, which we don't have
										<img src={branding.links.LoginLogoUrl} alt="" />
									) : (
										<StyledWorkspaceLogo>
											<WorkspaceLogoIcon size={26} color={logoIconColor({ theme })} />
											<WorkspaceTextIcon size={142} color={textIconColor({ theme })} />
										</StyledWorkspaceLogo>
									)}
								</div>
							</div>
							<div className={css(styles.whiteBox, centerContent && styles.centered)}>
								{children}
							</div>
						</div>
						<div className={css(styles.footerImage)}>
							<CitrixLogoIcon width={68} height={25} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

const BrandedCard = withBranding(_FullscreenCard);
export const FullscreenCard = React.forwardRef((props: Props, ref) => (
	<BrandedCard {...props} forwardRef={ref} />
));
