import * as React from 'react';
import { css } from 'aphrodite';
import { media, useBreakpoints } from '@citrite/web-ui-component';
import { styles } from './styles';

export enum BarHeight {
	Thin,
	Medium,
	Thick,
}

export enum BarSpacing {
	Small,
	Large,
}

export interface Props {
	width: number;
	spacing?: BarSpacing;
	height?: BarHeight;
	resizeOnSmallMedia?: boolean;
}

export function Bar(props: Props) {
	const breakpoints = useBreakpoints();
	const { spacing, height, resizeOnSmallMedia } = props;
	return (
		<div
			className={css(
				styles.bar,
				spacing === BarSpacing.Small && styles.barSmallSpacing,
				spacing === BarSpacing.Large && styles.barLargeSpacing,
				height === BarHeight.Thin && styles.barThin,
				height === BarHeight.Thick && styles.barThick
			)}
			style={{
				width:
					resizeOnSmallMedia && breakpoints.screenSize.media === media.small
						? '100%'
						: `${props.width}px`,
			}}
		/>
	);
}
