import * as React from 'react';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { clientType, getClientType } from 'Environment/getClientType';
import {
	defaultContext,
	ResiliencyChromeAppContext,
} from 'Workspace/ChromeApp/ChromeAppContext';
import {
	fallbackToPreviousClientType,
	isChromeAppCompatible,
} from 'Workspace/ChromeApp/ChromeAppHelperMethods';
import { InitialLoading } from 'Workspace/InitialLoading';
import { useUserContext } from 'Workspace/UserContext';

const EmptyProvider: React.FC = props => (
	<ResiliencyChromeAppContext.Provider value={defaultContext}>
		{props.children}
	</ResiliencyChromeAppContext.Provider>
);

const ChromeAppContextProvider = makeAsync(
	() =>
		import(
			/* webpackChunkName: "ChromeApp" */ 'Workspace/ChromeApp/ChromeAppContextProvider'
		).then(x => x.ChromeAppContextProvider),
	<InitialLoading />,
	EmptyProvider
);

export const ChromeAppSupport: React.FC = props => {
	const { workspaceConfiguration } = useConfigurationContext();
	const userContext = useUserContext();
	if (isChromeAppCompatible(workspaceConfiguration)) {
		return (
			<ChromeAppContextProvider
				userContext={userContext}
				workspaceConfiguration={workspaceConfiguration}
			>
				{props.children}
			</ChromeAppContextProvider>
		);
	}

	if (getClientType() === clientType.chromeapp) {
		fallbackToPreviousClientType();
	}

	return <EmptyProvider {...props} />;
};
