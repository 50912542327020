import { ArrowDownIcon } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';

// z-index is toggled to minimize the banner overlaying tooltips that are in the masthead
export const StyledMastheadInformationalBanner = styled.div<{
	enabledLineClamp: boolean;
	bannerHeight: string;
	expandBanner: boolean;
	textAlign: 'center' | 'left' | 'right';
	top?: number | string;
	bannerPosition?: BannerPosition;
}>`
	min-height: ${props => props.bannerHeight};
	position: absolute;
	text-align: ${props => props.textAlign};
	${props =>
		props.bannerPosition &&
		props.bannerPosition === BannerPosition.Top &&
		`top: ${props.top};`}
	${props =>
		props.bannerPosition &&
		props.bannerPosition === BannerPosition.Bottom &&
		'bottom: 0px;'}
	${props => !props.bannerPosition && 'top: 0px;'}
	width: 100%;
	display: block;
	> div:nth-of-type(1) {
		margin-bottom: 0px;
	}
	${props =>
		props.expandBanner && props.enabledLineClamp
			? 'z-index: 3'
			: props.bannerPosition === BannerPosition.Bottom
			? 'z-index: 3'
			: 'z-index: -1'};
`;

// This is an arbitrary value meant to offset various nested padding
// values that we get from the banner, its icon and the icon used to indicate
// that more can be shown.
export const paddingAndIconOffset = 105;

export const StyledLineClamp = styled.div<{
	enabledLineClamp: boolean;
	expandedBanner: boolean;
	innerWidth: number;
	bannerPosition: BannerPosition;
}>`
	${props => (props.enabledLineClamp ? 'cursor: pointer' : 'cursor: default')};
	max-width: ${props => props.innerWidth - paddingAndIconOffset}px;
	display: inline-block;
	overflow: ${props => (props.expandedBanner ? 'visible' : 'hidden')};
	text-overflow: ellipsis;
	white-space: ${props => (props.expandedBanner ? 'normal' : 'nowrap')};
`;

export const StyledContent = styled.div<{ bannerHeight: string }>`
	min-height: ${props => props.bannerHeight};
`;

export const StyledDropdownIcon = styled(ArrowDownIcon)<{
	expandedBanner: boolean;
	enabledLineClamp: boolean;
	bannerPosition: BannerPosition;
}>`
	cursor: pointer;
	display: ${props => (props.enabledLineClamp ? 'inline-block' : 'none')};
	position: absolute;
	right: 10px;
	top: 25px;
	${props =>
		props.bannerPosition !== BannerPosition.Bottom &&
		(props.expandedBanner ? 'transform: rotate(180deg)' : '')};
	${props =>
		props.bannerPosition === BannerPosition.Bottom &&
		(props.expandedBanner ? '' : 'transform: rotate(180deg)')};
`;
