import * as React from 'react';
import { t } from '@citrite/translate';
import { RouteContext } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { useUserContext } from 'Workspace/UserContext';

export const ComposableMobileMastheadTitle: React.FC<RouteContext> = ({
	setMobileMastheadTitle,
}) => {
	const { isIWSEnabled } = useUserContext();

	React.useEffect(() => {
		setMobileMastheadTitle(
			t(
				isIWSEnabled && environment.isNative
					? 'Workspace:notification_stream.activity_feed'
					: 'Workspace:home'
			)
		);

		return () => {
			setMobileMastheadTitle('');
		};
	}, [isIWSEnabled, setMobileMastheadTitle]);

	return null;
};
