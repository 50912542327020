import * as cookie from 'js-cookie';
import URI from 'urijs';

export function setExternalUserCookie() {
	const domain = new URI(window.location.origin).domain();
	cookie.set('user_type', 'external', { domain: `${domain}` });
}

export function setExternalUserCookieIfSpecified() {
	if (urlHasClientUserQuery()) {
		setExternalUserCookie();
	}
}

function urlHasClientUserQuery() {
	const uri = new URI(window.location.href.toLowerCase());
	if (
		uri.hasQuery('client_user', 'true') ||
		uri.fragment().includes('client_user=true')
	) {
		return true;
	}
	return false;
}
