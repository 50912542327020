import * as React from 'react';
import { EmptyState } from '@citrite/web-ui-component';
import {
	ContextMenuItemBuilder,
	MobileTab,
	MobileTabsContainer,
	NativeMobileCollapsibleSection,
	NativeMobileCollapsibleSectionProps,
} from '@citrite/workspace-ui-platform-react';
import {
	NativeMobileResourceItemProps,
	NativeMobileResourceRow,
} from 'Components/NativeMobileResourceRow';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

interface Props {
	tabs: NativeMobileTabProps[];
	contextMenuBuilder?: ContextMenuItemBuilder<Resource>;
}

export enum NativeMobileTabCategory {
	all,
	recents,
}
export interface NativeMobileTabProps {
	id: NativeMobileTabCategory;
	label: React.ReactNode;
	sections: (NativeMobileCollapsibleSectionProps & {
		id: string | number;
		items: NativeMobileResourceItemProps[];
	})[];
	emptyState: EmptyStateProps;
}
interface EmptyStateProps {
	icon: React.SFC<React.AllHTMLAttributes<HTMLSpanElement>>;
	title: string;
}

interface State {
	activeTab: NativeMobileTabCategory;
}

export class NativeMobileTabsRenderer extends React.Component<Props, State> {
	public state: State = {
		activeTab: this.props.tabs[0].id,
	};

	public render = () => {
		return (
			<MobileTabsContainer activeTab={this.state.activeTab} onChange={this.changeTab}>
				{this.props.tabs.map(tab => (
					<MobileTab value={tab.id} label={tab.label} key={tab.id}>
						{!this.isTabEmpty(tab) ? (
							tab.sections
								.filter(section => {
									return section.items.length > 0;
								})
								.map(section => (
									<NativeMobileCollapsibleSection
										key={section.id}
										title={section.title}
										isCollapsible={section.isCollapsible}
										isCollapsedInitially={section.isCollapsedInitially}
									>
										{section.items.map(item => (
											<NativeMobileResourceRow
												item={item}
												key={item.id}
												contextMenuBuilder={this.props.contextMenuBuilder}
											/>
										))}
									</NativeMobileCollapsibleSection>
								))
						) : (
							<EmptyState icon={tab.emptyState.icon}>{tab.emptyState.title}</EmptyState>
						)}
					</MobileTab>
				))}
			</MobileTabsContainer>
		);
	};

	private changeTab = (value: NativeMobileTabCategory) => {
		this.setState({ activeTab: value });
	};

	private isTabEmpty(tab: NativeMobileTabProps) {
		let returnValue = true;
		tab.sections.forEach(section => {
			if (section.items.length > 0) {
				returnValue = false;
			}
		});
		return returnValue;
	}
}
