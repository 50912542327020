import { AnalyticsEvent, Metadata } from 'analytics';
import { BrowserExtensionInstallPromptEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
const code = 'WSUI_BROWSER_EXTENSION_INSTALL_PROMPT';

const preparePayload = (
	type: BrowserExtensionInstallPromptEventType,
	metadata?: Metadata
): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});
const createBrowserExtensionInstallPromptAnalyticsReporter = () => {
	return {
		getBrowserExtensionInstallPromptInstallEvent: (
			doNotShowAgainIsSet: boolean
		): AnalyticsEvent =>
			preparePayload(BrowserExtensionInstallPromptEventType.InstallPromptInstalled, {
				doNotShowAgainIsSet,
			}),
		getBrowserExtensionInstallPromptCancelledEvent: (
			doNotShowAgainIsSet: boolean
		): AnalyticsEvent =>
			preparePayload(BrowserExtensionInstallPromptEventType.InstallPromptCancelled, {
				doNotShowAgainIsSet,
			}),
	};
};

export const browserExtensionInstallPromptAnalyticsReporter =
	createBrowserExtensionInstallPromptAnalyticsReporter();
