export const baseColumnCount = 12;

export interface Column {
	columnSpan: number;
	/**
	 * Support for nested grids is currently unimplemented
	 */
	grid?: Grid;
}

export interface Row {
	columns: Column[];
}

export interface Grid {
	rows: Row[];
}

/**
 * Model to define a layout based on a 12-column grid.
 * It supports a single breakpoint for mobile screen size.
 *
 * @example
 *
 * layout with columns
 * - A - spans the entire first row
 * - B - 2/3-span of the second row
 * - C - 1/3-span of the second row
 * +--+--+--+--+--+--+--+--+--+--+--+--+
 * |              A                    |
 * |                                   |
 * +--+--+--+--+--+--+--+--+--+--+--+--+
 * |                       |           |
 * |         B             |    C      |
 * |                       |           |
 * |                       |           |
 * +--+--+--+--+--+--+--+--+--+--+--+--+
 *
 * definition:
 * {
 *	grid: {
 *		rows: [
 *			{
 *              // A
 *				columns: [{ columnSpan: 12, ... }],
 *			},
 *			{
 *				columns: [
 *                  // B
 *					{ columnSpan: 8, ... },
 *                  // C
 *					{ columnSpan: 4, ... }
 *				],
 *			},
 *		],
 *	},
 * }
 */
export interface Layout {
	/**
	 * The ID must not change since it is persisted as part of the user homepage customization data.
	 */
	id: string;
	/**
	 * The grid has 12 columns.
	 */
	grid: Grid;
}
