import * as React from 'react';
import { palette } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { linearGradientLightImage, shimmer } from './styles';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	width: number;
	height: number;
}

const StyledRectangleDiv = styled.div<Props>`
	width: ${props => props.width + 'px'};
	height: ${props => props.height + 'px'};
	background-color: ${palette.grey300};
	border-radius: 4px;
	background-image: ${linearGradientLightImage};
	background-repeat: no-repeat;
	display: inline-block;
	position: relative;
	animation: ${shimmer} 2s infinite forwards;
	align-self: center;
`;

export function LoadingRectangle(props: Props) {
	return (
		<StyledRectangleDiv
			data-testid="loading-rectangle"
			width={props.width}
			height={props.height}
		/>
	);
}
