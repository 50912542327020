import { microAppIntegration } from '@citrite/workspace-ui-microapps';
import {
	IntegrationManifest,
	PlatformDependencies,
} from '@citrite/workspace-ui-platform';
import { apps } from 'appsAndDesktops/apps/manifest';
import { desktops } from 'appsAndDesktops/desktops/manifest';
import { notificationIntegration } from 'userNotifications/manifest';
import { container } from 'Workspace/DependencyManagement';

export interface LoadedIntegration extends IntegrationManifest {
	privateSymbol?: Symbol;
}

export function loadIntegrations() {
	const integrations: LoadedIntegration[] = [];
	container.registerSingleton(
		PlatformDependencies.RegisterIntegration,
		({ integrationManifest, privateSymbol }) => {
			integrations.push({ ...integrationManifest, privateSymbol });
		}
	);
	microAppIntegration.register();
	notificationIntegration.register();
	apps.register();
	desktops.register();

	if (CLI_MODE) {
		const cliIntegration = window['workspace-ui.cli-integration'];
		if (cliIntegration) {
			integrations.push(...cliIntegration);
		}
	}

	return integrations;
}
