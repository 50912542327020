import * as React from 'react';
import { useNotifications } from 'userNotifications';
import { NewMessagesIcon } from './NewMessagesIcon';

export function UserNotificationBadge() {
	const notificationsContext = useNotifications();
	if (!notificationsContext.hasNewFeedCards) {
		return null;
	}
	return <NewMessagesIcon pulse />;
}

export const UserNotificationBadgeOffIfFeedInNav: React.FC = () => {
	return null;
};
