import * as React from 'react';
import { breakpoints, useBreakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ResourceIcon } from 'Components/ResourceIcon';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const StyledIconContainer = styled.div`
	width: 32px;
	height: 32px;
	${breakpoints.onMediumScreen} {
		width: 26px;
		height: 26px;
	}
`;

interface Props {
	resource: Resource;
}

export function ResponsiveResourceIcon(props: Props) {
	const breakpoint = useBreakpoints();
	return (
		<StyledIconContainer data-testid={`ftu-resource-icon-${props.resource.id}`}>
			{breakpoint.screenSize.onMobile ? (
				<ResourceIcon resource={props.resource} size={26} />
			) : (
				<ResourceIcon resource={props.resource} size={32} />
			)}
		</StyledIconContainer>
	);
}
