import React from 'react';
import { WithModalsProps } from '@citrite/web-ui-component';
import { saasAppLaunchAnalyticsReporter } from 'Workspace/TelemetryEvents/saasLaunch/createSaasAppLaunchAnalyticsReporter';
import { trackNamAndCebAnalytics } from 'Workspace/trackNamAndCebAnalytics';
import { LaunchInCEBModal, LaunchInCEBModalProps } from './LaunchInCEBModal';

export interface SaaSAppLaunchInCEBParams
	extends WithModalsProps,
		LaunchInCEBModalProps {}

export const launchSaaSApp = (props: SaaSAppLaunchInCEBParams) => {
	const { showModal, ...modalProps } = props;
	showModal(<LaunchInCEBModal {...modalProps} />);
	trackNamAndCebAnalytics(saasAppLaunchAnalyticsReporter.getSaasLaunchPromptEvent());
};
