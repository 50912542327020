import { getLocale } from '../dependencies';
import { getAssetBundleUrlFromRelativePath } from './getAssetBundleUrlFromRelativePath';

/**
 * Returns a URL to a Workspace error page based on the provided http status code.
 * Only codes 400, 403, 404, and 500 are supported. 400 is used if the code does not match one of these.
 */
export function getErrorPageFromStatusCode(httpStatusCode: number) {
	if (process.env.NODE_ENV !== 'production') {
		throw new Error(
			`An error occurred that would trigger navigation to a ${httpStatusCode} error page in production.`
		);
	}
	let typeCheckedErrorPage = httpStatusCode;
	if (typeof typeCheckedErrorPage === 'number') {
		// use the 400 error page if the status code does not match an existing error page
		switch (typeCheckedErrorPage) {
			case 400:
			case 403:
			case 404:
			case 500:
				break;
			default:
				typeCheckedErrorPage = 400;
				break;
		}
	}
	return getAssetBundleUrlFromRelativePath(
		`html/${getLocale()}/${typeCheckedErrorPage}.html`
	);
}
