import * as React from 'react';
import { LaunchResourceOptions } from 'Environment';
import { SendLogoffMessage } from 'Environment/BrowserExtension/browserExtensionBridge';
import { AuthenticationTimerOperationType } from 'Environment/BrowserExtension/types';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export interface ExtensionConfigurationState {
	isActive: boolean;
	allowAccessToOfflineResources: boolean;
	useAuthenticationTimer: boolean;
	isModernOfflineFlowSupported?: boolean;
}

export interface BrowserExtensionContext {
	isSupportedCustomerRuntime?: boolean;
	getExtensionConfiguration(): Promise<ExtensionConfigurationState>;
	shouldBrowserExtensionLaunchResource(resource: Resource): Promise<boolean>;
	noteLogout(params: SendLogoffMessage): Promise<void>;
	launch(launchResourceOptions: LaunchResourceOptions): Promise<any>;
	notifyAuthenticationTimer(operation: AuthenticationTimerOperationType): Promise<void>;
}

export const defaultContext: BrowserExtensionContext = {
	isSupportedCustomerRuntime: false,
	getExtensionConfiguration: () =>
		Promise.resolve({
			isActive: false,
			allowAccessToOfflineResources: false,
			useAuthenticationTimer: false,
		}),
	shouldBrowserExtensionLaunchResource: () => Promise.resolve(false),
	launch: () =>
		Promise.reject(new Error('Use of browser extension context is improperly gated')),
	noteLogout: () => Promise.resolve(),
	notifyAuthenticationTimer: () => Promise.resolve(),
};
export const ResiliencyBrowserExtensionContext =
	React.createContext<BrowserExtensionContext>(defaultContext);
