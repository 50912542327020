import { t } from '@citrite/translate';
import { platform } from 'Environment/launchResource/constants';
import { getCurrentPlatform } from 'Environment/launchResource/device/currentPlatform';

export const platformSpecificClientDisplayNames: { platform: platform; name: string }[] =
	[
		{ platform: platform.windows, name: 'Windows' },
		{ platform: platform.mac, name: 'Mac' },
		{ platform: platform.linux, name: 'Linux' },
		{ platform: platform.chromeOS, name: 'Android' }, //On ChromeOS, the CWA Receiver app is the Android app to be installed from playstore so we show Android here
	];

export function getNativeClientName(): string {
	const currentPlatform = getCurrentPlatform();
	const nativeClientName =
		platformSpecificClientDisplayNames.find(item => item.platform === currentPlatform)
			?.name || 'Windows';
	return t('Workspace:workspace_for_native_client', { native_client: nativeClientName });
}
