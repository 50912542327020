import React from 'react';
import { AppWindowLinkIcon, Tooltip } from '@citrite/web-ui-component';
import { PinnedLink } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import {
	StyledBannerItem,
	StyledBannerItemAnchor,
	StyledImage,
} from 'App/CustomLinkBanner/CustomLink.styled';
import { PinnedLinksUserEventPayload } from 'Workspace/TelemetryEvents/pinnedLinks/createPinnedLinksCASReporter';

const handleAnalytics = () => {
	trackAnalyticsEvent(PinnedLinksUserEventPayload.pinnedLinkClicked());
};

export const CustomLinkItem: React.FC<PinnedLink> = (props: PinnedLink) => {
	const customLinkElement = (
		<StyledBannerItem>
			{!props?.iconUrl ? (
				<AppWindowLinkIcon size={16} />
			) : (
				<StyledImage src={props.iconUrl} width={16} height={16} alt="Icon" />
			)}
			<StyledBannerItemAnchor
				href={props.hyperlink}
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleAnalytics}
			>
				{props.displayText}
			</StyledBannerItemAnchor>
		</StyledBannerItem>
	);
	return props?.toolTipText?.trim() ? (
		<Tooltip hoverTrigger={customLinkElement}>{props.toolTipText}</Tooltip>
	) : (
		customLinkElement
	);
};
