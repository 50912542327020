import * as React from 'react';
import { searchResourcesByNameAndDescription } from 'Components/ItemSearch/searchResourcesByNameAndDescription';
import { WithResourceContextProps } from 'Workspace/ResourceProvider';
import { Criteria } from './Criteria/Criteria';
import { Results } from './Results/Results';

export enum SearchFilterOption {
	All = 'all',
	Applications = 'applications',
	Desktops = 'desktop',
	Feed = 'feed',
}

export interface Props extends WithResourceContextProps {
	search?: string;
	searchFilterOption?: SearchFilterOption | string;
}

export interface Query {
	SearchQuery?: string;
}

interface State {
	query?: Query;
	searching?: boolean;
	appIds?: string[];
	desktopIds?: string[];
	filterOption?: SearchFilterOption | string;
	lastSearchTerm?: string;
}

export class SearchContent extends React.Component<Props, State> {
	public state = this.buildInitialState();
	private supportsApps = this.props.resourceContext.resources.some(r => !r.isdesktop);
	private supportsDesktops = this.props.resourceContext.resources.some(r => r.isdesktop);

	private buildInitialState() {
		const query: Query = {
			SearchQuery: decodeURIComponent(this.props.search || ''),
		};

		const state: State = {
			appIds: [],
			desktopIds: [],
			query,
			lastSearchTerm: query.SearchQuery,
			filterOption: this.props.searchFilterOption || SearchFilterOption.All,
		};

		return state;
	}

	public componentDidMount() {
		const { query } = this.state;
		if (query.SearchQuery) {
			this.executeSearch(query);
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			prevProps.search !== this.props.search ||
			prevProps.searchFilterOption !== this.props.searchFilterOption
		) {
			const updatedQuery: Query = {
				SearchQuery: this.props.search,
			};
			this.setState(
				(state, props) => ({
					query: { ...state.query, ...updatedQuery },
					filterOption: props.searchFilterOption,
				}),
				() => {
					this.executeSearch(this.state.query);
				}
			);
		}
	}

	public render() {
		if (!this.state) {
			return null;
		}
		return (
			<>
				<Criteria
					executeSearch={this.applyQuery}
					query={this.state.query}
					resetQuery={this.resetQuery}
					searching={this.state.searching}
					updateQuery={this.updateQuery}
					supportsApps={this.supportsApps}
					supportsDesktops={this.supportsDesktops}
					filterOption={this.state.filterOption}
					updateFilterOption={this.updateFilterOption}
				/>
				<Results
					apps={this.props.resourceContext.resources.filter(resource =>
						this.state.appIds.includes(resource.id)
					)}
					desktops={this.props.resourceContext.resources.filter(resource =>
						this.state.desktopIds.includes(resource.id)
					)}
					filterOption={this.state.filterOption}
					isSearching={this.state.searching}
					lastSearchTerm={this.state.lastSearchTerm}
					supportsApps={this.supportsApps}
					supportsDesktops={this.supportsDesktops}
				/>
			</>
		);
	}

	private updateQuery = (properties: Query) => {
		this.setState(state => ({
			query: { ...state.query, ...properties },
		}));
	};

	private updateFilterOption = (option: SearchFilterOption | string) => {
		this.setState(
			{
				filterOption: option,
			},
			() => {
				if (this.state.query.SearchQuery) {
					this.executeSearch(this.state.query);
				}
			}
		);
	};

	private resetQuery = () => {
		this.setState(state => ({
			query: {
				...this.getDefaultQuery(),
				SearchQuery: state.query.SearchQuery,
			},
		}));
	};

	private getDefaultQuery() {
		const query: Query = {
			SearchQuery: null,
		};

		return query;
	}

	private applyQuery = () => {
		this.executeSearch(this.state.query);
	};

	private executeSearch(query: Query) {
		const resources = searchResourcesByNameAndDescription(
			this.props.resourceContext.resources,
			query.SearchQuery
		);

		const apps =
			this.state.filterOption === SearchFilterOption.All ||
			this.state.filterOption === SearchFilterOption.Applications
				? resources.filter(r => !r.isdesktop)
				: [];

		const desktops =
			this.state.filterOption === SearchFilterOption.All ||
			this.state.filterOption === SearchFilterOption.Desktops
				? resources.filter(r => r.isdesktop)
				: [];

		const appIds = apps.map(app => app.id);

		const desktopIds = desktops.map(desktop => desktop.id);

		this.setState({
			appIds,
			desktopIds,
			searching: false,
			lastSearchTerm: query.SearchQuery,
		});
	}
}
