import { versionInfo, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import platform from 'platform';
import { v4 as uuid } from 'uuid';
import { environment } from 'Environment';
import { detectLanguage } from 'javascript/sf/detectLanguage';
import { TelemetryEvent } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { UserDetails } from 'Workspace/UserContext';
import { CasEvent, DeviceInfo, NativePlatformCapabilities } from './CasEventTypes';

function getTimezoneOffset() {
	const offset = -(new Date().getTimezoneOffset() / 60);
	return `GMT${offset > 0 ? '+' : ''}${offset}`;
}

function getDeviceInfo(): DeviceInfo {
	return {
		os: {
			name: platform.os.family,
			version: platform.os.version,
			architecture: platform.os.architecture.toString(),
		},
		browser: {
			name: platform.name,
			version: platform.version,
		},
		timezone: getTimezoneOffset(),
		locale: detectLanguage(),
		userAgent: platform.ua,
	};
}

export function createCasEvent<T extends TelemetryEvent>(
	telemetryEvent: T,
	workspaceConfiguration: WorkspaceConfiguration,
	userDetails: UserDetails
) {
	const tenantId = workspaceConfiguration.storeIdentifiers?.customerId;
	const event: CasEvent<T> = {
		type: telemetryEvent.type,
		id: uuid(),
		$schema: 'https://cas.citrix.com/schemas/event.json',
		ver: 1,
		st: new Date(Date.now()).toISOString(),
		prod: 'IWS.WorkspaceUI',
		prodVer: versionInfo.get()?.revision || '',
		tenant: {
			id: tenantId,
		},
		payload: { ...telemetryEvent, deviceInfo: getDeviceInfo() },
		user: {
			userId: userDetails?.userId || '',
			userDisplayName: userDetails?.userDisplayName || '',
		},
		ip: '',
	};

	if (environment.isNative) {
		event.ip = environment.nativeCapabilities?.clientSettings?.clientAddress;

		const nativeClient: NativePlatformCapabilities = {
			appName: environment.nativeCapabilities?.platform?.appname,
			appVersion: environment.nativeCapabilities?.platform?.appversion,
			id: environment.nativeCapabilities?.platform?.id,
		};

		event.payload.nativePlatformCapabilities = nativeClient;
	}

	return event;
}
