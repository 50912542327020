import { t } from '@citrite/translate';
import { LeftNavPositions } from '@citrite/workspace-ui-platform';
import { ComposableHome } from 'App/ComposableHome/ComposableHome';
import {
	ComposableIcon,
	ComposableSecondaryIcon,
	ComposableSelectedIcon,
} from 'App/ComposableHome/ComposableIcons';
import { homePagePath } from 'Router/homePageRoute';
import { createLeftNavRoute } from 'Router/ReactRoute';
import { checkHomeAvailability } from '../Navigation/checkHomeAvailability';

export const composableHomeRoute = createLeftNavRoute({
	component: ComposableHome,
	path: homePagePath,
	isAvailable: props => checkHomeAvailability(props),
	isLoading: props => !props.userContext.isIWSEnabled && props.resources.loading,
	getNavLinkProps: () => ({
		title: t('Workspace:home'),
		icon: ComposableIcon,
		selectedIcon: ComposableSelectedIcon,
		secondaryIcon: ComposableSecondaryIcon,
		weight: LeftNavPositions.dashboard,
		analyticsID: 'home-nav-tab',
	}),
});
