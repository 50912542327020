import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import * as signalR from '@microsoft/signalr';
import { logError } from 'remoteLogging';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { SignalRClientOptions, SignalRHttpClient } from './Client';
import { DeviceRegistrationConfig, registerDevice } from './Device';
import { PushEvent } from './PushEvent';

let pushEventService: signalR.HubConnection;

let onStartListeners: (() => void)[] = [];
export function registerOnStart(callback: () => void) {
	if (pushEventService?.state === 'Connected') {
		callback();
	}
	onStartListeners.push(callback);
	return () => unregisterOnStart(callback);
}
function unregisterOnStart(callback: () => void) {
	onStartListeners = onStartListeners.filter(cb => cb !== callback);
}

let onCloseListeners: ((error?: Error) => void)[] = [];
export function registerOnClose(callback: (error?: Error) => void) {
	onCloseListeners.push(callback);
	return () => unregisterOnClose(callback);
}
function unregisterOnClose(callback: (error?: Error) => void) {
	onCloseListeners = onCloseListeners.filter(cb => cb !== callback);
}

const onPushListeners: { [key: string]: ((event: PushEvent) => void)[] } = {};
export function registerOnPush(
	serviceName: string,
	callback: (event: PushEvent) => void
) {
	onPushListeners[serviceName]
		? onPushListeners[serviceName].push(callback)
		: (onPushListeners[serviceName] = [callback]);
	return () => unregisterOnPush(serviceName, callback);
}
function unregisterOnPush(serviceName: string, callback: (event: PushEvent) => void) {
	onPushListeners[serviceName] = onPushListeners[serviceName].filter(
		cb => cb !== callback
	);
}

let userClosedConnection: boolean;
export async function startClient(config: WorkspaceConfiguration) {
	await stopClient();
	userClosedConnection = false;

	const endpointService = getEndpointsService(config, 'deviceRegistration');
	const supplier = createEndpointSupplier(endpointService.discoveryUrl);
	const registrationUrl = await supplier.getEndpoint('DeviceManagement');
	const signalRUrl = (await supplier.getEndpoint('SignalRHub')) + '/events';
	const clientConfig: SignalRClientOptions = {
		baseUrl: `${window.location.origin}/Citrix/StoreWeb/`,
		proxyUrl: config.authManager.proxyUrl,
		customerId: config.storeIdentifiers.customerId,
		storeId: config.storeIdentifiers.storeGuid,
	};
	const httpClient = new SignalRHttpClient(clientConfig, signalR.NullLogger.instance);
	pushEventService = new signalR.HubConnectionBuilder()
		.configureLogging(signalR.LogLevel.Error)
		.withUrl(signalRUrl, { httpClient })
		.build();
	pushEventService.onclose(error => onClose(error, config, registrationUrl));
	try {
		await startListening(config, registrationUrl);
	} catch (error) {
		logError(error, { additionalContext: { event } });
	}
}

export async function stopClient() {
	if (!pushEventService) {
		return;
	}
	clearTimeout(pendingReconnect);
	userClosedConnection = true;
	await pushEventService.stop();
	pushEventService = null;
}

function onClose(error: Error, config: WorkspaceConfiguration, registrationUrl: string) {
	if (error) {
		logError(error, { additionalContext: { event } });
	}
	if (!userClosedConnection) {
		reconnect(config, registrationUrl);
	}
	onCloseListeners.forEach(cb => cb(error));
}

let retryCount = 0;
async function startListening(config: WorkspaceConfiguration, registrationUrl: string) {
	try {
		await pushEventService.start();
		if (pushEventService.connectionId) {
			const deviceId = getFromLocalStorage<string>('ws-device-id');
			const deviceConfig: DeviceRegistrationConfig = {
				deviceId,
				connectionId: pushEventService.connectionId,
				registrationUrl,
				proxyUrl: config.authManager.proxyUrl,
				customerId: config.storeIdentifiers.customerId,
				storeId: config.storeIdentifiers.storeGuid,
			};
			await registerDevice(deviceConfig).then(registrationResponse => {
				if (!deviceId) {
					setInLocalStorage('ws-device-id', registrationResponse?.data?.id);
				}
			});
		}
		onStartListeners.forEach(cb => cb());
		pushEventService.on('push', (payload: string) => {
			try {
				const payloadJson = JSON.parse(payload) as { data: PushEvent };
				if (payloadJson?.data) {
					onPushListeners[payloadJson.data.serviceId]?.forEach(cb =>
						cb(payloadJson.data)
					);
				}
			} catch {}
		});
		retryCount = 0;
	} catch (error) {
		reconnect(config, registrationUrl);
	}
}

let pendingReconnect: number;
function reconnect(config: WorkspaceConfiguration, registrationUrl: string) {
	if (retryCount < 4) {
		retryCount++;
	}
	const delay = Math.pow(retryCount, 3) * 30 * 1000;
	pendingReconnect = window.setTimeout(async () => {
		try {
			await startListening(config, registrationUrl);
		} catch {}
	}, delay);
}
