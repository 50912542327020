import * as React from 'react';
import { css } from 'aphrodite';
import { styles } from './styles';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	width: number;
	height: number;
}

export function Rectangle(props: Props) {
	return (
		<div
			className={css(styles.rectangle)}
			style={{
				width: `${props.width}px`,
				height: `${props.height}px`,
			}}
		/>
	);
}
