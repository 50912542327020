import { getFeatureFlagValue } from '@citrite/feature-flags';
import { EncryptedCacheBucket } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';

const cacheKey = 'recents';
const numberOfActionsToStore = 6;

export async function addActionToRecents(
	actionId: string,
	cacheBucket: EncryptedCacheBucket
) {
	if (!recentActionsEnabled()) {
		return;
	}
	const recentActions = (await cacheBucket.getEncrypted<string[]>(cacheKey)) || [];
	const recentActionsUpdated = recentActions.filter(x => x !== actionId);
	recentActionsUpdated.unshift(actionId);
	const recentActionsSliced = recentActionsUpdated.slice(0, numberOfActionsToStore);
	await cacheBucket.setEncrypted(cacheKey, recentActionsSliced);
}

export async function getRecentActions(cacheBucket: EncryptedCacheBucket) {
	if (!recentActionsEnabled()) {
		return [];
	}
	const recentActions = (await cacheBucket.getEncrypted<string[]>(cacheKey)) || [];
	return recentActions;
}

export function recentActionsEnabled() {
	return getFeatureFlagValue(FeatureFlag.WspUserActionsRecent);
}
