import { StyleSheet } from 'aphrodite';
import { layout, palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	container: {
		background: palette.grey100,
		height: '100%',
	},
	title: {
		fontSize: typography.sizes.base,
		lineHeight: layout.mediumLargeSpace,
		textAlign: 'center',
		color: palette.grey900,
		fontWeight: typography.weights.semiBold,
	},
	modalTitle: {
		paddingLeft: 0,
	},
	modal: {
		backgroundColor: '#fff',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	body: {
		backgroundColor: '#fff',
		padding: 0,
		height: '100%',
	},
	loadingContainer: {
		height: '100%',
	},
	titleSuffix: {
		fontSize: typography.sizes.xl4,
		fontWeight: typography.weights.medium,
		marginLeft: '10px',
	},
});
