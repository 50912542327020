import {
	fromThemeColors,
	fromThemePalette,
	layout,
	palette,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const backgroundColor = fromThemePalette({
	citrixbrand: '#fff',
	neutral: palette.grey200,
});

const borderColor = fromThemePalette({
	citrixbrand: '#fff',
	neutral: palette.grey300,
});

const whiteColor = fromThemePalette({
	citrixbrand: '#fff',
	neutral: palette.white,
});

export const StyledSearchBarDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 320px;
	height: 40px;
	border-radius: 20px;
	background: ${backgroundColor};
`;

export const StyledSearchTextSpan = styled.span`
	margin-left: ${layout.smallSpace};
	color: ${palette.grey700};
	font-weight: ${typography.weights.light};
`;

export const StyledDaasSearchBarDiv = styled.div`
	width: 100%;
	height: 40px;
	margin-top: 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	border: 1px solid;
	border-color: ${borderColor};
	background: ${whiteColor};
	box-sizing: border-box;
`;

export const StyledSearchTextContainer = styled.div`
	margin-top: 12px;
	float: left;
`;

export const StyledDaasSearchTextSpan = styled.span`
	margin-left: ${layout.smallSpace};
	color: ${fromThemeColors('metadataText')};
	font-weight: ${typography.weights.light};
`;

export const StyledSearchIconContainer = styled.div`
	margin-left: ${layout.mediumSpace};
	width: 18px;
	height: 18px;
	margin-top: 11px;
	float: left;
`;
