import { CITRIX_TRANSACTIONID_HEADER, RequestInterceptors } from '@citrite/http';
import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { AxiosRequestConfig } from 'axios';
import * as Cookie from 'js-cookie';
import { v4 } from 'uuid';
import { environment } from 'Environment';
import { WSUI_SESSION_ID } from 'Loggers/LoggingProvider';
import { clientHasAppProtectionCapability } from 'Workspace/AppContext/appProtectionCapabilityCheck';
import { container } from 'Workspace/DependencyManagement';
import {
	CSRF_TOKEN,
	X_CITRIX_APPPROTECTION,
	X_CITRIX_ISUSINGHTTPS,
	X_CITRIX_WSUI_SESSION_ID,
	X_REQUESTED_WITH,
} from './Headers';

export function workspacePlatformHeaders() {
	return {
		[CSRF_TOKEN]: Cookie.get('CsrfToken'),
		[X_CITRIX_ISUSINGHTTPS]: window.location.protocol === 'https:' ? 'Yes' : 'No',
		[X_REQUESTED_WITH]: 'XMLHttpRequest',
		[CITRIX_TRANSACTIONID_HEADER]: v4(),
		[X_CITRIX_WSUI_SESSION_ID]: WSUI_SESSION_ID,
	};
}

// allow-unused-export
export const isResourceEnumerationCall = (config: AxiosRequestConfig) => {
	const workspaceConfig = container
		.resolve(PlatformDependencies.WorkspaceConfiguration)
		.get();
	if (
		config?.url?.toLowerCase() ===
		workspaceConfig?.storeProxy?.resourcesProxy?.listURL?.toLowerCase()
	) {
		return true;
	}
	return false;
};

const addAppProtectionHeaders = (isResourceEnumeration: boolean) => {
	const headers = {};

	const workspaceConfig = container
		.resolve(PlatformDependencies.WorkspaceConfiguration)
		.get();

	if (clientHasAppProtectionCapability(workspaceConfig, isResourceEnumeration)) {
		headers[X_CITRIX_APPPROTECTION] = true;
	}
	return headers;
};

export const interceptor: RequestInterceptors = {
	prepareRequest: config => {
		return {
			...config,
			adapter: environment.ajax.bind(environment),
			baseURL: environment.store.baseUri,
			headers: {
				...addAppProtectionHeaders(isResourceEnumerationCall(config)),
				...workspacePlatformHeaders(),
				...config.headers, //allow overwriting of default platform headers
			},
		};
	},
};
