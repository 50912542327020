import { notifyError } from '@citrite/web-ui-component';
import { t } from 'i18next';
import { bxtnIdHelper } from 'Environment/BrowserExtension/BrowserExtensionIDHelper';
import { clientType, setClientType } from 'Environment/getClientType';
import {
	extensionInitCompleteEvent,
	sendEventOnInstall,
} from './browerExtensionInstallEventHandlers';
import { isChromeRuntimeAvailable } from './browserExtensionHelperMethods';

export interface BrowserExtensionInstallationCheck {
	startCheck: () => Promise<boolean>;
	endCheck: () => void;
}

export const checkBrowserExtensionInstallation =
	(): BrowserExtensionInstallationCheck => {
		let pollingTimerID: NodeJS.Timeout;
		let intiCompleteEventListenter: EventListenerOrEventListenerObject;

		const createInitCompleteEventListener = (resolve: Function) => {
			if (!intiCompleteEventListenter) {
				intiCompleteEventListenter = (event: CustomEvent) => {
					if (event?.detail?.isActive) {
						setClientType(clientType.browserextension);
						resolve(true);
					} else {
						resolve(false);
					}
				};
			}
			return intiCompleteEventListenter;
		};

		const startCheck = () => {
			window.open(bxtnIdHelper.getStoreUrl(), '_blank'); // Opening the extension store url in a new tab
			let timeoutCounter = 0;
			return new Promise<boolean>(resolve => {
				pollingTimerID = setInterval(() => {
					if (isChromeRuntimeAvailable()) {
						if (intiCompleteEventListenter) {
							document.removeEventListener(
								extensionInitCompleteEvent,
								intiCompleteEventListenter
							);
						}
						document.addEventListener(
							extensionInitCompleteEvent,
							createInitCompleteEventListener(resolve)
						);
						sendEventOnInstall();
					}
					// If the extension is not available after 30 seconds, then we will notify the user with an error message
					timeoutCounter += 1;
					if (timeoutCounter >= 15) {
						notifyError(t('Workspace:browserExtensionInstallPrompt.installationError'));
						resolve(false);
						clearInterval(pollingTimerID);
					}
				}, 2000);
				// Check every 2 seconds if extension is available
			});
		};

		const endCheck = () => {
			clearInterval(pollingTimerID);
		};

		return { startCheck, endCheck };
	};
