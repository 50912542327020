import moment from 'moment';
import { detectLanguage } from 'javascript/sf/detectLanguage';

const hourInMilliseconds = 1 * 60 * 60 * 1000;
const t24HoursInMilliseconds = 24 * hourInMilliseconds;
const noonStartInMilliSeconds = t24HoursInMilliseconds / 2;
const eveningStartInMilliSeconds = noonStartInMilliSeconds + 5 * hourInMilliseconds;

const dateFormat = 'dddd, MMMM DD, YYYY';

export const getDayTime = () => {
	const currentHour = new Date().getHours();
	if (currentHour < 12) {
		return 'morning';
	} else if (currentHour < 17) {
		return 'afternoon';
	} else {
		return 'evening';
	}
};

export const getGreetingRefreshTimeInMilliseconds = () => {
	const nowInMilliSeconds =
		Date.now() - new Date(moment().format('MM/DD/yyyy')).getTime();

	if (nowInMilliSeconds < noonStartInMilliSeconds) {
		return noonStartInMilliSeconds - nowInMilliSeconds;
	} else if (nowInMilliSeconds < eveningStartInMilliSeconds) {
		return eveningStartInMilliSeconds - nowInMilliSeconds;
	} else {
		return t24HoursInMilliseconds - nowInMilliSeconds;
	}
};

export const getTodayString = () => {
	return moment().locale(detectLanguage()).format(dateFormat);
};
export const bannerContentHeight = 40;
