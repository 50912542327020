import { logError } from 'remoteLogging';
import { createChromeAppMessage } from 'Environment/ChromeApp/ChromeAppMessageHelper';
import { ShieldChromeAppMessageTransport } from 'Environment/ChromeApp/ShieldChromeAppMessageTransport';
import {
	ChromeAppErrorReason,
	ChromeAppMessage,
	ChromeAppMessageType,
	ChromeAppResponse,
} from 'Environment/ChromeApp/types';
import { postMessageToChromeApp } from 'Environment/launchResource/chromeApp';

class ChromeRuntimeTransport implements ShieldChromeAppMessageTransport {
	protected promiseMap = new Map();

	public sendMessageToChromeApp = (workload: any, type: ChromeAppMessageType) => {
		const that = this;
		const message: ChromeAppMessage = createChromeAppMessage(workload, type);
		return new Promise<ChromeAppResponse>((resolve, reject) => {
			try {
				that.promiseMap[message.payload.correlationId] = {
					currentResolve: resolve,
					currentReject: reject,
				};
				postMessageToChromeApp(message);
				window.addEventListener('message', that.getListener, false);
			} catch (error) {
				logError(error);
				const newError = new Error(`postMessage failed: ${error}`);
				newError.name = ChromeAppErrorReason.ChromeApp_PostMessage_Failure;
				that.promiseMap[message.payload.correlationId].currentReject(newError);
			}
		});
	};

	public getListener = async (event: any) => {
		try {
			const result = await handleChromeAppResponse(event.data);
			if (result !== false) {
				this.promiseMap[event.data.correlationId].currentResolve(result);
				this.promiseMap.delete(event.data.correlationId);
			}
		} catch (error) {
			this.promiseMap[event.data.correlationId].currentReject(error);
			this.promiseMap.delete(event.data.correlationId);
		}
	};
}

function handleChromeAppResponse(response: ChromeAppResponse | undefined | null) {
	if (!response) {
		const error = new Error('Invalid response provided.');
		error.name = ChromeAppErrorReason.Unavailable;
		return Promise.reject(error);
	} else if (!!!response.messageType) {
		return false;
	} else if (response.status !== 'successful') {
		const error = new Error('Response status was not successful');
		error.name = ChromeAppErrorReason.Unsuccessful;
		error['status'] = response.status;
		return Promise.reject(error);
	} else {
		return Promise.resolve(response);
	}
}

export const sendMessageToChromeApp = new ChromeRuntimeTransport().sendMessageToChromeApp;
