import {
	Endpoint,
	EndpointSupplier,
	UnencryptedCacheBucket,
} from '@citrite/workspace-ui-platform';
import { BucketManifest } from 'Workspace/Cache';
import { createCacheBucket } from 'Workspace/Cache/createCacheBucket';
import { fetchEndpoints } from './fetchEndpoints';

export function createEndpointSupplier<T extends string>(
	serviceEndpointsDiscoveryUrl: string
): EndpointSupplier<T> {
	return new _EndpointSupplier<T>(serviceEndpointsDiscoveryUrl);
}

const endpointCacheBucket: UnencryptedCacheBucket = createCacheBucket({
	bucketId: BucketManifest.endpoints,
});

class _EndpointSupplier<T extends string> {
	private serviceEndpointsDiscoveryUrl: string;
	private endpointsResolver: Promise<Endpoint[]>;

	public constructor(serviceEndpointsDiscoveryUrl: string) {
		this.serviceEndpointsDiscoveryUrl = serviceEndpointsDiscoveryUrl;
	}

	public getEndpoint(endpointId: T, { skipCache }: { skipCache?: boolean } = {}) {
		if (!skipCache) {
			const endpoints = endpointCacheBucket.getUnencrypted<Endpoint[]>(
				this.serviceEndpointsDiscoveryUrl
			);
			const endpoint = endpoints?.find(e => e.id === endpointId);
			if (endpoint) {
				this.getEndpoints();
				return Promise.resolve(endpoint.url as T);
			}
		}
		return this.getEndpointsFromNetwork().then(
			fetchedEndpoints => fetchedEndpoints.find(e => e.id === endpointId)?.url as T
		);
	}

	private getEndpointsFromNetwork() {
		this.endpointsResolver = undefined;
		return this.getEndpoints();
	}

	private getEndpoints() {
		this.endpointsResolver =
			this.endpointsResolver ||
			fetchEndpoints(this.serviceEndpointsDiscoveryUrl).then(fetchedEndpoints => {
				endpointCacheBucket.setUnencrypted(
					this.serviceEndpointsDiscoveryUrl,
					fetchedEndpoints
				);
				return fetchedEndpoints;
			});
		return this.endpointsResolver;
	}
}
