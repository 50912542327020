import * as React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { t } from '@citrite/translate';
import { ArrowLeftIcon } from '@citrite/web-ui-component';
import { matchPath } from 'react-router-dom';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { Menu } from 'App/LeftNavigation/MobileMenu';
import { PrimaryNavSkeleton } from 'App/LeftNavigation/PrimaryNavigation/Skeleton';
import {
	StyledLists,
	StyledNavTitle,
	StyledSecondaryNavigation,
	StyledTabHeader,
	StyledTabIndicator,
	StyledTabs,
} from 'App/LeftNavigation/SecondaryNavigation/SecondaryNavigation.styled';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { Link } from './Link';

export interface SecondaryNavigationProps {
	navLink: LeftNavLink;
	currentPath: string;
	mobileMenu: Menu;
	toggleSecondaryTab(): void;
	onFocus(): void;
	onNavigate(refreshPage: boolean, performanceKey: string): void;
}

interface InternalProps extends Omit<SecondaryNavigationProps, 'navLink'> {
	title: string;
	links: LeftNavLink[];
}

interface State {
	expandedSection?: string;
}

class _SecondaryNavigation extends React.Component<InternalProps, State> {
	public state: State = { expandedSection: this.findExpandedSection() };

	public render() {
		const isPrimaryTab = this.props.mobileMenu === Menu.primary;
		const isSecondaryTab = this.props.mobileMenu === Menu.secondary;
		return (
			<StyledSecondaryNavigation
				aria-label={t('javascript:left_nav.secondary')}
				onFocus={this.props.onFocus}
			>
				<StyledTabHeader
					mobileMenu={this.props.mobileMenu}
					secondary={Menu.secondary}
					onClick={this.props.toggleSecondaryTab}
				>
					<ArrowLeftIcon size={20} />
					<StyledNavTitle>{this.props.title}</StyledNavTitle>
				</StyledTabHeader>
				<StyledLists>
					{this.props.links.map((link, k) => {
						return (
							<Link
								key={`${k}${link.href}`}
								link={link}
								expanded={link.href === this.state.expandedSection}
								onLinkClick={this.onExpandableLinkClick}
								currentPath={this.props.currentPath}
							/>
						);
					})}
				</StyledLists>
				{!getFeatureFlagValue(FeatureFlag.SimplifiedNav) && (
					<StyledTabs>
						<StyledTabIndicator indicator={isPrimaryTab} menuValue={Menu.primary} />
						<StyledTabIndicator indicator={isSecondaryTab} menuValue={Menu.secondary} />
					</StyledTabs>
				)}
			</StyledSecondaryNavigation>
		);
	}

	public componentDidUpdate(prevProps: InternalProps) {
		if (prevProps.currentPath !== this.props.currentPath) {
			this.setState({ expandedSection: this.findExpandedSection() });
		}
	}

	private findExpandedSection() {
		for (let i = 0; i < this.props.links.length; i++) {
			const link = this.props.links[i];
			if (!!matchPath(this.props.currentPath, { path: link.href })) {
				return link.href;
			}
		}

		return undefined;
	}

	private onExpandableLinkClick = (e: React.MouseEvent, link: LeftNavLink) => {
		if (link.childLinks?.length) {
			e.preventDefault();
			this.expand(link.href);
			return;
		}
		this.onLinkClick(e, link);
	};

	private onLinkClick = (e: React.MouseEvent, link: LeftNavLink) => {
		if (!link.href) {
			e.preventDefault();
			link.onClick(e);
		}
		this.props.onNavigate(link.href === this.props.currentPath, link.performanceMark);
	};

	private expand = (href: string) => {
		this.setState(state => ({
			expandedSection: state.expandedSection === href ? '' : href,
		}));
	};
}

export const SecondaryNavigation = (props: SecondaryNavigationProps) => {
	if (!props.navLink || !props.navLink.childLinks.length) {
		return null;
	}

	if (props.navLink.isLoading) {
		return <PrimaryNavSkeleton />;
	}

	return (
		<_SecondaryNavigation
			{...props}
			title={props.navLink.title}
			links={props.navLink.childLinks}
		/>
	);
};
