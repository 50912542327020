import React from 'react';
import { v4 } from 'uuid';
import { ActionsProvider } from 'App/Actions/actionTypes';
import {
	ActionsHandlerStateEvent,
	ActionViewClosedEvent,
	EventType,
	useEventBus,
	ViewResourceActionEvent,
} from 'Components/EventBus';
import {
	isValidResourceURN,
	parseResourceURN,
} from 'Components/EventBus/resourceURN/parseResourceURN';
import { environment } from 'Environment';
import { Event } from 'Workspace/EventBus';

type ActionLaunch = ViewResourceActionEvent;
const ACTION_RESOURCE_NAME = 'action';

export interface Props {
	actionsContext: ActionsProvider;
}

export const NativeActionLaunchEventHandler = (props: Props): JSX.Element => {
	const eventBus = useEventBus();
	const { actionsContext } = props;
	const actionsList = actionsContext.value?.actions;
	const { loading } = actionsContext;

	React.useEffect(() => {
		syncActionsHandlerStateWithNative(loading);
	}, [loading]);

	React.useEffect(() => {
		const unsubscribe = eventBus.subscribe((event: Event<any>) => {
			if (isActionLaunchEvent(event)) {
				const { id } = parseResourceURN(event.payload.urn);
				const allowClose = event.meta?.allowClose;
				if (!actionsList) {
					return;
				}
				const actionApp = actionsList.find(action => action.id === id);
				if (actionApp) {
					actionApp.onClick({ allowClose, onActionOverlayClose });
				}
			}
		});
		return unsubscribe;
	}, [eventBus, actionsList]);
	return null;
};

const onActionOverlayClose = (): void => {
	const event: ActionViewClosedEvent = {
		id: v4(),
		type: EventType.ACTION_CLOSED,
		payload: {},
	};
	environment.sendEventToNative(event);
};

function isActionUrn(urn: string) {
	return (
		isValidResourceURN(urn) && parseResourceURN(urn).resourceName === ACTION_RESOURCE_NAME
	);
}

function isActionLaunchEvent(event: Event<any>): event is ActionLaunch {
	return (
		event.type === EventType.VIEW_ACTION &&
		event.payload &&
		event.payload.urn &&
		isActionUrn(event.payload.urn)
	);
}

function syncActionsHandlerStateWithNative(loading: boolean) {
	const event: ActionsHandlerStateEvent = {
		id: v4(),
		type: EventType.ACTIONS_HANDLER_STATE,
		payload: {
			loading,
		},
	};
	environment.sendEventToNative(event);
}
