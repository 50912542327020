import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { layout, LoadingIconPrimary } from '@citrite/web-ui-component';
import { ResourceIcon } from 'Components/ResourceIcon';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const styles = StyleSheet.create({
	icon: {
		marginRight: layout.mediumLargeSpace,
	},
});

export interface Props {
	loading?: boolean;
	resource: Resource;
}

export const WidgetResourceIcon: React.FC<Props> = ({ loading, resource }) => {
	if (loading) {
		return (
			<span className={css(styles.icon)}>
				<LoadingIconPrimary size={20} />
			</span>
		);
	} else {
		return (
			<span className={css(styles.icon)}>
				<ResourceIcon resource={resource} size={20} />
			</span>
		);
	}
};
