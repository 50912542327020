const nativeMessageDelimiter = String.fromCharCode(1);
const messageQueue: string[] = [];
let messageCounter = 0;
let iframe: HTMLIFrameElement;

export function queueNativeMessage(functionName: string, ...args: any[]) {
	if (!iframe) {
		iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
	}

	const safeArguments = args.map(arg => (arg == null ? '' : arg.toString()));
	const serializedFunctionCall =
		[functionName, ...safeArguments].join(nativeMessageDelimiter) +
		nativeMessageDelimiter;
	messageQueue.unshift(serializedFunctionCall);

	// receiver listens for navigation to a url like this to know that that a new message has been added,
	// and it should call ctxs_getNativeMessage to retrieve it
	iframe.src = 'fromjs://biff?' + messageCounter++;
}

(window as any).ctxs_getNativeMessage = function () {
	const message = messageQueue.pop() || '';
	return `${messageQueue.length}${nativeMessageDelimiter}${message}`;
};
