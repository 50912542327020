import React from 'react';
import { breakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledModal = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 5;
	overflow: auto;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
`;
const StyledBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: max(calc(100%), 640px);
	overflow-y: auto;
	overflow-x: hidden;
	${breakpoints.onMediumScreen} {
		height: 100%;
	}
`;

export const CustomizeUserHomeContainer: React.FC = props => {
	return (
		<StyledModal>
			<StyledBodyContainer>{props.children}</StyledBodyContainer>
		</StyledModal>
	);
};
