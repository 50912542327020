import { FeedCardAction } from '@citrite/workspace-ui-platform';
import { LocalizedStringObject } from './service/localizedStringObject';

export enum feedSort {
	createdDate = 'CREATED_AT',
	relevance = 'RELEVANCE_DESKTOP',
}

export interface BaseFeedCard<DetailType> {
	/** id generated by UNS while creating the card */
	id: string;
	/** id of the original microapp notification that was generated in MAS */
	messageUuid?: string;
	createdAt: string;
	expiresAt: string;
	iconUrl: string;
	detail?: DetailType;
	media: FeedCardMediaItem[];
	/** ID of Record to be used for pairing with customer database (for example programmatic expiration). */
	recordId: string;
}

export interface FeedCard<DetailType = any> extends BaseFeedCard<DetailType> {
	source: FeedCardSource;
	actions: FeedCardAction[];
	getTitle(): string;
	getBody(): string;
}

export enum FeedCardMediaType {
	Image = 'IMAGE',
}

export enum FeedCardActionType {
	OpenUrl = 'OPEN_URL',
	PostCallback = 'POST_CALLBACK',
}

export interface FeedCardMediaItem {
	type: FeedCardMediaType | string;
	url: string;
}

export interface FeedCardNotificationIdentifier {
	id: string;
	getLabel(): string;
}

export interface FeedCardAppIdentifier {
	id: string;
	uuid: string;
	iconUrl: string;
	getTitle(): string;
	getRawTitle(): LocalizedStringObject;
}

export interface FeedCardSource {
	origin: string;
	notification: FeedCardNotificationIdentifier;
	integration: FeedCardAppIdentifier;
}

export enum BulkDismissType {
	Notification,
	Integration,
	RecordId,
}
