import { StyleSheet } from 'aphrodite';
import { layout, palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	noItems: {
		margin: `${layout.smallSpace} ${layout.mediumSpace}`,
		padding: layout.mediumSpace,
		border: `1px dashed ${palette.grey300}`,
		textAlign: 'center',
		fontSize: typography.sizes.base,
		color: palette.grey600,
		borderRadius: '4px',
	},
	title: {
		fontWeight: typography.weights.medium,
		marginBottom: layout.smallSpace,
		color: palette.grey900,
	},
});
