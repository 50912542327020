import { t } from '@citrite/translate';
import { PlatformDependencies, workspaceLocales } from '@citrite/workspace-ui-platform';
import { performance } from 'Components/performance';
import { windowManager } from 'IntegrationDependencies/BrowserWindowManager';
import { deviceInfo } from 'IntegrationDependencies/DeviceInfo';
import { telemetryClientFactory } from 'IntegrationDependencies/TelemetryClient';
import { container } from 'Workspace/DependencyManagement';

export function addStartedAppPlatformDependencies(locale: workspaceLocales) {
	container.registerSingleton(PlatformDependencies.Locale, locale);
	container.registerSingleton(PlatformDependencies.WindowManager, windowManager);
	container.registerSingleton(PlatformDependencies.TranslationFunction, t);
	container.registerSingleton(PlatformDependencies.DeviceInfo, deviceInfo);
	container.registerSingleton(PlatformDependencies.Performance, performance);
	container.registerFactory(PlatformDependencies.TelemetryClient, telemetryClientFactory);
}
