import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import {
	EventType,
	TelemetryEvent,
	TelemetryEventPublisher,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const AdminNotificationBannerCode = 'AdminNotificationBannerCode';
const component = 'WSUI';

interface AdminNotificationBannerEvent extends TelemetryEvent {
	time: string;
	code: string;
	component: string;
	bannerPosition: BannerPosition;
}

export function sendAdminNotificationBannerEvent(
	telemetryEventPublisher: TelemetryEventPublisher,
	bannerPosition: BannerPosition
) {
	const adminNotificationBannerEvent: AdminNotificationBannerEvent = {
		time: new Date().toISOString(),
		type: EventType.AdminNotificationBanner,
		code: AdminNotificationBannerCode,
		component,
		bannerPosition,
	};
	telemetryEventPublisher.publishEvent(adminNotificationBannerEvent);
}
