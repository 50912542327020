import { StyleSheet } from 'aphrodite';
import { breakpoints, layout, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	criteriaContainer: {
		padding: '20px 0 30px 40px',
		[breakpoints.onMobile]: {
			padding: '20px 10px',
		},
	},
	searchTermContainer: {
		display: 'flex',
		[breakpoints.onSmallScreen]: {
			flexDirection: 'column',
		},
	},
	searchTerm: {
		flex: '1 auto', // needed by IE11 to stop flex items from overlapping on small screen
		maxWidth: '600px',
		[breakpoints.onSmallScreen]: {
			width: '100%',
		},
	},
	searchFilterBlock: {
		display: 'flex',
		[breakpoints.onSmallScreen]: {
			marginTop: '10px',
		},
	},
	searchFilter: {
		margin: '0 10px',
		width: '130px',
		[breakpoints.onSmallScreen]: {
			width: '100%',
			marginLeft: '0',
		},
	},
	searchButton: {
		[breakpoints.onDesktop]: {
			marginRight: '10px',
		},
		[breakpoints.onSmallScreen]: {
			width: '100%',
		},
	},
	filtersContainer: {
		marginTop: '30px',
	},
	filterResultsBy: {
		fontSize: '16px',
		fontWeight: typography.weights.medium,
		marginBottom: '20px',
	},
	searchItemTitlesOnly: {
		marginLeft: '-4px',
	},
	filters: {
		marginTop: '20px',
		display: 'flex',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
	},
	criteriaButtonsContainer: {
		height: '60px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	criteriaButtons: {
		display: 'flex',
		alignItems: 'center',
	},
	customDate: {
		width: '120px',
		marginTop: '10px',
	},
	folderLocation: {
		width: '215px',
	},
	criteriaItem: {
		marginRight: layout.smallSpace,
	},
});
