import React, { useState } from 'react';
import { t } from '@citrite/translate';
import { Button, CloseIcon } from '@citrite/web-ui-component';
import { ActionStatus } from 'App/Activity/components/ActionStatus';
import {
	ApplicationAction,
	ResourceDetails,
	Session,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { useFormFactor } from 'App/Header/useFormFactor';

export interface TerminateActionProps {
	application: ResourceDetails;
	session: Session;
}

const smallFormCloseIconSize = 16;
const largeFormCloseIconSize = 10;

const buttonStyle: React.CSSProperties = {
	lineHeight: 0,
	display: 'inline-block',
};

export const TerminateAction = ({ application, session }: TerminateActionProps) => {
	const { onApplicationAction } = useResourceManagerContext();
	const [initiated, setAsInitiated] = useState(false);
	const { isSmallFormFactor } = useFormFactor();

	const closeIconSize = isSmallFormFactor
		? smallFormCloseIconSize
		: largeFormCloseIconSize;

	const handleClick = () => {
		setAsInitiated(true);
		onApplicationAction(ApplicationAction.TERMINATE, application, session);
	};

	return initiated ? (
		<ActionStatus status={t('Workspace:activity_manager.terminating')} />
	) : (
		<Button.IconOnly
			size={closeIconSize}
			icon={CloseIcon}
			onClick={handleClick}
			title={t('Workspace:activity_manager.terminate')}
			style={buttonStyle}
		/>
	);
};
