import { DeviceInfo } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import {
	isMobileDevice,
	isMobileOrTabletDevice,
	isNativeClient,
	isNativeMobileClient,
	isNativeMobileOrTabletClient,
} from 'Environment/launchResource/device';

export const deviceInfo: DeviceInfo = {
	isMobileDevice,
	isMobileOrTabletDevice,
	isNativeClient,
	isNativeMobileClient,
	isNativeMobileOrTabletClient,
	nativePlatform: () => environment.nativePlatform,
};
