import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export function getRecentResources(resources: Resource[]) {
	return resources
		.filter(r => typeof r.recentsposition === 'number')
		.sort((a, b) => {
			const difference = a.recentsposition - b.recentsposition;
			if (difference !== 0) {
				return difference;
			}
			return a.name.localeCompare(b.name);
		});
}
