import { typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledH1 = styled.h1`
	font-size: 18px;
	font-weight: ${typography.weights.light};
`;

export const StyledBlock = styled.div`
	margin: 15px 0;
`;

export const StyledBlockTrailingMargin = styled(StyledBlock)`
	margin-bottom: 30px;
`;

export const StyledJustifiedBlock = styled(StyledBlock)`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;
