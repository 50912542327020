import { StyleSheet } from 'aphrodite';
import { layout, palette, styleUtils, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	nameWrapper: {
		overflow: 'hidden',
	},
	name: {
		...styleUtils.ellipsis,
		fontWeight: typography.weights.regular,
		fontSize: typography.sizes.sml,
		color: palette.black,
	},
	sectionTitle: {
		...styleUtils.ellipsis,
		fontWeight: typography.weights.semiBold,
		fontSize: typography.sizes.base,
		color: palette.black,
		marginTop: '14px',
		marginBottom: layout.mediumSpace,
	},
});
