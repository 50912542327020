import { installProvider } from '@citrite/feature-flags';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';

// There may not be further references to the feature-flags package in this repo, but that
// doesn't mean this can be removed for cleanup. Internal npm packages may depend on flags
// from this installed provider.
export function installFeatureFlagPackageProvider(
	getConfig: () => WorkspaceConfiguration
) {
	installProvider({
		getValue: flagName => hasFeatureCanary(getConfig(), flagName),
	});
}
