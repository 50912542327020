import {
	localAppResourceType,
	Resource,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';

const symbols = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export function getSortedFavorites(resources: Resource[]) {
	return resources.filter(isFavorite).sort((a, b) => {
		const aPosition = a.position || symbols[0];
		const bPosition = b.position || symbols[0];
		for (let i = 0; ; i++) {
			if (aPosition.length === i) {
				if (bPosition.length === i) {
					return 0;
				}
				return -1; // bPosition greater
			}

			if (bPosition.length === i) {
				return 1; // aPosition greater
			}

			const c =
				symbols.indexOf(aPosition.charAt(i)) - symbols.indexOf(bPosition.charAt(i));
			if (c !== 0) {
				return c;
			}
		}
	});
}

export function isFavorite(resource: Resource) {
	if (resource.type === localAppResourceType) {
		return resource.subscriptionstatus === subscriptionStatus.subscribed;
	}
	const canSubscribe = !!resource.subscriptionurl;
	const isAdded =
		!canSubscribe || resource.subscriptionstatus === subscriptionStatus.subscribed;
	return (
		isAdded ||
		resource.subscriptionstatus === subscriptionStatus.pending ||
		resource.subscriptionstatus === subscriptionStatus.approved
	);
}
