import { makeAsync } from '@citrite/workspace-ui-platform-react';

export const LocalAppOnboardingModal = makeAsync(
	() =>
		import(/* webpackChunkName: "LocalApps" */ './LocalAppOnboardingModal').then(
			m => m.LocalAppOnboardingModal
		),
	null,
	() => null
);

export const LocalAppManageModal = makeAsync(
	() =>
		import(
			/* webpackChunkName: "LocalApps" */ 'App/LocalSystemApps/LocalAppManageModal'
		).then(m => m.LocalAppManageModal),
	null
);

export * from './LocalAppSynchronize';
export * from './localAppStorage';
