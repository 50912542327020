import * as React from 'react';
import { palette, useBreakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { BarHeight } from '../Shapes/Bar';
import { LoadingBar } from '../Shapes/LoadingBar';
import { LoadingRectangle } from '../Shapes/LoadingRectangle';

const StyledLoadingDiv = styled.div`
	width: 160px;
	height: 168px;
	box-sizing: border-box;
	border: 1px solid ${palette.grey300};
	border-radius: 4px;
	background-color: ${palette.white};
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const StyledLoadingMobileDiv = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	background-color: #ffffff;
	margin-left: 15px;
`;

const StyledDiv = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	align-self: center;
`;

export function LoadingItem() {
	const breakpoints = useBreakpoints();
	return breakpoints.screenSize.onMobile ? (
		<StyledLoadingMobileDiv data-testid="loading-item-mobile">
			<LoadingRectangle width={40} height={40} />
			<StyledDiv data-testid="loading-mobile-bar-container">
				<LoadingBar width="80%" height={BarHeight.Medium} topMargin="0px" />
				<LoadingBar width="50%" height={BarHeight.Medium} topMargin="7px" />
			</StyledDiv>
		</StyledLoadingMobileDiv>
	) : (
		<StyledLoadingDiv data-testid="loading-item-web">
			<LoadingRectangle width={45} height={45} />
			<LoadingBar width="80%" height={BarHeight.Thin} topMargin="15px" />
			<LoadingBar width="65%" height={BarHeight.Thin} topMargin="7px" />
		</StyledLoadingDiv>
	);
}
