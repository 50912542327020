import { createCapability } from '../capability';

/**
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.UNSTABLE_widgetComponent}
 *
 * @category Integration Capability
 */
export interface WidgetComponentCapability {
	Component: React.ComponentType;
}

export interface WidgetComponentCapabilityMetadata {
	/**
	 * When performanceKey is defined the widget will be included in the time to render the dashboard metric.
	 * You should call performance.mark(<your widget performance key>) in your widget component
	 * when it has finished loading.
	 */
	performanceKey: string;
}

export const WidgetComponentCapabilityToken = createCapability<
	WidgetComponentCapability,
	WidgetComponentCapabilityMetadata
>('wsui_widget_item_list_capability');
