import * as React from 'react';
import { t } from '@citrite/translate';
import {
	BreakpointsProps,
	Button,
	CitrixBrandmarkXIcon,
	CitrixLogoIcon,
	CogIcon,
	Link as UILink,
	useBreakpoints,
	withModals,
	WithModalsProps,
	WorkspaceTextIcon,
} from '@citrite/web-ui-component';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { Menu } from 'App/LeftNavigation/MobileMenu';
import {
	StyledCitrixLogo,
	StyledCustomLogo,
	StyledExpandCollapseIcon,
	StyledLogoArea,
	StyledLogout,
	StyledMenuSettingsOptions,
	StyledSvgLogo,
	StyledTooltip,
	StyledUsername,
	StyledUserNameBlock,
	StyledUserSettings,
	StyledUserSettingsAll,
	StyledUserSettingsOptions,
} from 'App/LeftNavigation/PrimaryNavigation/PrimaryNavigation.styled';
import { PrimaryNavSkeleton } from 'App/LeftNavigation/PrimaryNavigation/Skeleton';
import { settingsRoute } from 'App/Settings/settingsRoute';
import { shouldShowAccountSettings } from 'App/Settings/SettingsUtils';
import { PrimaryNavigation as PrimaryNavLayout } from 'Components/AppLayout';
import { WorkspaceAvatar } from 'Components/Avatar/WorkspaceAvatar';
import { performance } from 'Components/performance';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useBranding, WithBrandingProps } from 'Configuration/withBranding';
import { environment } from 'Environment';
import { refreshApp } from 'Environment/callbacks';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isDesktop } from 'Environment/launchResource/device';
import { UserContext, useUserContext } from 'Workspace/UserContext';
import { AdaptiveButton } from './AdaptiveButton';
import { LinkList, Props as LinkListProps } from './LinkList';

export interface Props extends WithModalsProps {
	links: LinkListProps['links'];
	deprecatedCollapsed: LinkListProps['collapsed'];
	currentPath: LinkListProps['currentPath'];
	mobileMenu: Menu;
	hasTabs: boolean;
	onFocus(): void;
	onNavigate(refreshPage: boolean, performanceMark?: string): void;
	workspaceConfiguration?: WorkspaceConfiguration;
}

interface InternalProps extends WithBrandingProps, Props {
	userContext: UserContext;
	collapsed: boolean;
	setCollapsed: (collapse: boolean) => void;
	focused: boolean;
	breakpoints: BreakpointsProps;
}

function _PrimaryNavigation(props: InternalProps) {
	React.useEffect(() => {
		performance.mark(performance.events.leftNav);
	}, []);
	const imageLocation =
		props.userContext.userPreferences?.profilePicture?.items?.Default?.url;
	const username = props.userContext.userDetails?.userDisplayName;
	return (
		<>
			{!hasFeatureCanary(props.workspaceConfiguration, FeatureFlag.SimplifiedNav) && (
				<StyledLogoArea>
					{props.branding.links.HeaderLogoUrl ? (
						<StyledCustomLogo />
					) : (
						<StyledSvgLogo>
							<WorkspaceTextIcon width={170} />
						</StyledSvgLogo>
					)}
				</StyledLogoArea>
			)}
			<LinkList {...props} />
			{renderExpandCollapseChevron(
				props.workspaceConfiguration,
				props.focused,
				props.breakpoints,
				props.collapsed,
				props.setCollapsed
			)}
			<StyledCitrixLogo deprecatedCollapsed={props.deprecatedCollapsed}>
				{renderCitrixLogo(props.workspaceConfiguration, props.collapsed)}
			</StyledCitrixLogo>
			{!hasFeatureCanary(props.workspaceConfiguration, FeatureFlag.SimplifiedNav) && (
				<StyledUserSettingsAll>
					<StyledUserSettingsOptions>
						{environment.menuPreferences.about && (
							<StyledMenuSettingsOptions>
								<AdaptiveButton.TextOrSubtleButton
									onClick={(e: React.MouseEvent) => {
										props.onNavigate(false);
										environment.aboutBox(e.currentTarget, props.showModal);
									}}
								>
									{t('Workspace:about_citrix_workspace')}
								</AdaptiveButton.TextOrSubtleButton>
							</StyledMenuSettingsOptions>
						)}
						{environment.menuPreferences.refresh && (
							<StyledMenuSettingsOptions>
								<AdaptiveButton.TextOrSubtleButton
									onClick={() => {
										refreshApp();
										props.onNavigate(false);
									}}
								>
									{t('Workspace:refresh')}
								</AdaptiveButton.TextOrSubtleButton>
							</StyledMenuSettingsOptions>
						)}
						{environment.menuPreferences.accounts && (
							<StyledMenuSettingsOptions>
								<AdaptiveButton.TextOrSubtleButton onClick={environment.showAccounts}>
									{t('Workspace:accounts')}
								</AdaptiveButton.TextOrSubtleButton>
							</StyledMenuSettingsOptions>
						)}
					</StyledUserSettingsOptions>
					<StyledUserSettings hasTabs={props.hasTabs}>
						<StyledUserNameBlock>
							{username && (
								<WorkspaceAvatar
									username={username}
									size={32}
									imageLocation={imageLocation}
								/>
							)}
							{/* Span wrapper needed for Button.Wrapper to apply focus styling*/}
							<StyledLogout>
								<StyledUsername>{username}</StyledUsername>
								{environment.menuPreferences.logoff && (
									<UILink.Secondary onClick={() => props.userContext.logOff()}>
										{t('javascript:logout')}
									</UILink.Secondary>
								)}
							</StyledLogout>
							{shouldShowAccountSettings(props.workspaceConfiguration) && (
								<Button.TextSecondary
									aria-label={t('Workspace:account_settings')}
									onClick={showSettings}
								>
									<CogIcon size={24} />
								</Button.TextSecondary>
							)}
						</StyledUserNameBlock>
					</StyledUserSettings>
				</StyledUserSettingsAll>
			)}
		</>
	);
}

const renderExpandCollapseChevron = (
	workspaceConfiguration: WorkspaceConfiguration,
	focused: boolean,
	breakpoints: BreakpointsProps,
	collapsed: boolean,
	setCollapsed: (collapsed: boolean) => void
) => {
	if (hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav)) {
		return (
			focused &&
			!breakpoints.screenSize.onMobile && (
				<StyledTooltip
					placement="right"
					hoverTrigger={
						<StyledExpandCollapseIcon
							onClick={() => setCollapsed(!collapsed)}
							collapsed={collapsed}
						/>
					}
				>
					{collapsed ? t('Workspace:expand') : t('Workspace:collapse')}
				</StyledTooltip>
			)
		);
	}
	return null;
};

const renderCitrixLogo = (
	workspaceConfiguration: WorkspaceConfiguration,
	collapsed: boolean
) => {
	const hasSimplifiedNavFlag = hasFeatureCanary(
		workspaceConfiguration,
		FeatureFlag.SimplifiedNav
	);
	return hasSimplifiedNavFlag && collapsed ? (
		<CitrixBrandmarkXIcon role="presentation" height={24} />
	) : (
		<CitrixLogoIcon
			height={hasSimplifiedNavFlag ? 24 : 28}
			width={hasSimplifiedNavFlag ? 64 : 75}
			role="presentation"
		/>
	);
};

const showSettings = (e: React.MouseEvent<HTMLButtonElement>) => {
	//Small form scenario: As desktop apps does not implement native settings we will redirect it to web/large form settings screen
	if (isDesktop() || !environment.isNative) {
		location.href = '#' + settingsRoute.getUrl();
		return;
	}
	e.preventDefault();
	e.stopPropagation();
	const { left, right, top, bottom } = e.currentTarget.getBoundingClientRect();
	environment.showSettings(
		left,
		top,
		right,
		bottom,
		window.innerWidth,
		window.innerHeight
	);
};

function NavigationLoader(props: Props) {
	const userContext = useUserContext();
	const breakpoints = useBreakpoints();
	const branding = useBranding();
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	const [collapsed, setCollapsed] = React.useState(false);
	const [focused, setFocused] = React.useState(false);
	let navStyle: React.CSSProperties = {};
	const marginLeft = hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav)
		? '-240px'
		: '-300px';
	if (props.mobileMenu !== Menu.primary && breakpoints.screenSize.onMobile) {
		navStyle = {
			marginLeft,
		};
	}
	return (
		<PrimaryNavLayout
			aria-label={t('javascript:left_nav.primary')}
			hasTabs={props.hasTabs}
			style={navStyle}
			onFocus={props.onFocus}
			onMouseEnter={
				hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav)
					? () => setFocused(true)
					: props.onFocus
			}
			onMouseLeave={() => setFocused(false)}
		>
			{!props.links.length || props.links.every(i => i.isLoading) ? (
				<PrimaryNavSkeleton />
			) : (
				<_PrimaryNavigation
					{...props}
					userContext={userContext}
					branding={branding}
					workspaceConfiguration={workspaceConfiguration}
					collapsed={!breakpoints.screenSize.onMobile && collapsed}
					setCollapsed={setCollapsed}
					focused={focused}
					breakpoints={breakpoints}
				/>
			)}
		</PrimaryNavLayout>
	);
}

export const PrimaryNavigation = withModals(NavigationLoader);
