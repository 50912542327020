import * as React from 'react';
import { palette } from '@citrite/web-ui-component';
import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { matchPath } from 'react-router-dom';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import {
	activeClassName,
	StyledAnchor,
	StyledChildMenuList,
	StyledDivider,
	StyledExpandIcon,
	StyledListItem,
	StyledNavLink,
	StyledSubMenu,
} from 'App/LeftNavigation/PrimaryNavigation/Link.styled';
import { useBubbleUpChildLinks } from 'App/LeftNavigation/useLeftNav';
import { ComponentHeight } from 'Components/ComponentHeight';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { ChildLink } from './ChildLink';
import { LinkBody } from './LinkBody';

export interface Props {
	link: LeftNavLink;
	expanded?: boolean;
	collapsed?: boolean;
	mobileOnly?: boolean;
	currentPath?: string;
	onLinkClick(e: React.MouseEvent, link: LeftNavLink): void;
}

export const Link: React.FC<Props> = props => {
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	const link = useBubbleUpChildLinks(props.link);

	const active = !!matchPath(props.currentPath, { path: link.href });
	const hideLink = !link.icon && props.collapsed && !active;

	const onLinkClick = (e: React.MouseEvent) => {
		props.onLinkClick(e, link);
	};
	return (
		<StyledListItem
			collapsed={props.collapsed}
			mobileOnly={props.mobileOnly}
			data-testid={`list-item-${props.link.title
				?.toLocaleLowerCase()
				.replace(/\s/g, '-')}`}
			data-analytics-id={props.link.analyticsID ? `${props.link.analyticsID}` : null}
		>
			{link.isDivider ? (
				<StyledDivider />
			) : (
				<>
					{link.href ? (
						<StyledNavLink
							to={link.href}
							onClick={onLinkClick}
							aria-expanded={!!link.childLinks.length ? props.expanded : null}
							hideLink={hideLink}
							activeClassName={activeClassName}
						>
							{!hideLink && (
								<LinkBody {...link} active={active} collapsed={props.collapsed} />
							)}
						</StyledNavLink>
					) : (
						<StyledAnchor hideLink={hideLink} href="#" onClick={onLinkClick}>
							{!hideLink && <LinkBody {...link} collapsed={props.collapsed} />}
						</StyledAnchor>
					)}
				</>
			)}
			{!link.hasSecondaryNav &&
				!!link.childLinks.length &&
				!props.collapsed &&
				!hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNavNoSubNav) && (
					<ComponentHeight isOpen={props.expanded}>
						<StyledSubMenu>
							{!hasFeatureCanary(
								workspaceConfiguration,
								FeatureFlag.WsuiFilesUIModernization
							) && (
								<StyledExpandIcon
									onClick={onLinkClick}
									size={16}
									expanded={props.expanded}
									color={palette.grey800}
								/>
							)}
							<StyledChildMenuList
								data-testid={`child-menu-list-${link.isLoading ? 'loading' : 'loaded'}`}
								isLoading={link.isLoading}
							>
								{link.childLinks.map((childLink, k) => (
									<ChildLink
										key={`${k}${childLink.href}`}
										index={k}
										focusable={props.expanded}
										link={childLink}
										onLinkClick={props.onLinkClick}
										currentPath={props.currentPath}
									/>
								))}
							</StyledChildMenuList>
						</StyledSubMenu>
					</ComponentHeight>
				)}
		</StyledListItem>
	);
};
