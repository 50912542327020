import { hasFeatureCanary } from '@citrite/workspace-ui-platform';
import { isDesktopOnlyHomepage } from 'App/isDesktopOnlyHomepage';
import { checkDesktopsAvailabilityForDaasVision } from 'App/Navigation/checkDesktopsAvailabilityForDaasVision';
import { shouldDisplayDesktopSection } from 'App/Navigation/shouldDisplayDesktopSection';
import { isSimpleViewInDaasVision } from 'App/Navigation/utils';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { IsAvailable } from 'Router/ReactRoute';

export function checkDesktopsAvailability(props: IsAvailable<{}>): boolean {
	if (
		hasFeatureCanary(
			props.workspaceConfiguration,
			FeatureFlag.UseFixForDesktopAvailability
		)
	) {
		return checkDesktopsAvailabilityForDaasVision(props);
	}

	//Keep the previous code intact until will can test the code in production.
	//This code will be deleted once we are confident that the new code is working as expected.
	return (
		hasUIProperty(props.workspaceConfiguration, UIProperty.Desktops) &&
		!isDesktopOnlyHomepage(
			props.workspaceConfiguration,
			props.userContext.isIWSEnabled,
			props.resourceContext
		) &&
		shouldDisplayDesktopSection(
			props.resourceContext.resources,
			props.workspaceConfiguration
		) &&
		!isSimpleViewInDaasVision(props.resourceContext.resources, props.userContext)
	);
}
