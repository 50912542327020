import { StyleSheet } from 'aphrodite';
import { AvatarSize, layout, styleUtils } from '@citrite/web-ui-component';

export function avatarStyle(size: AvatarSize) {
	let spacing: string;
	switch (size) {
		case 24:
			spacing = '10px';
			break;
		case 32:
		case 40:
			spacing = '12px';
			break;
		case 48:
			spacing = '15px';
			break;
		case 64:
		case 80:
			spacing = layout.mediumSpace;
			break;
	}

	return StyleSheet.create({
		avatar: {
			display: 'flex',
			alignItems: 'center',
		},
		name: {
			flexGrow: 1,
			marginLeft: spacing,
			...styleUtils.ellipsis,
		},
	});
}
