import { fromThemeColors, layout, ThemeColor } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledSkipNavLink = styled.a`
	position: absolute;
	left: 0;
	top: 0;
	z-index: 4;
	clip: rect(1px, 1px, 1px, 1px);
	background-color: ${fromThemeColors(ThemeColor.secondaryAppBackground)};
	box-sizing: border-box;
	border: 1px solid ${fromThemeColors(ThemeColor.border)};
	padding: ${layout.smallSpace};
	:focus {
		clip: auto;
	}
`;
