import { environment } from 'Environment';
import { isNativeWidget } from 'Environment/launchResource/device';

export const subscribeToEventBus = () => {
	const subscribeEvent = {
		id: 'activity',
		type: 'action/subscribe',
		payload: {
			subscription: 'action/miniui/running/subscribe',
		},
	};
	environment.dispatchEventToNative2(subscribeEvent);
};

export const unsubscribeFromEventBus = () => {
	const unsubscribeEvent = {
		id: 'activities',
		type: 'action/unsubscribe',
		payload: {
			unsubscription: 'action/miniui/running/unsubscribe',
		},
	};
	environment.dispatchEventToNative2(unsubscribeEvent);
};

export const shouldShowTitle = (isSmallFormFactor: boolean): boolean => {
	let showTitle = false;
	if (!(isNativeWidget() || isSmallFormFactor)) {
		showTitle = true;
	}
	return showTitle;
};
