import React from 'react';
import { IntegrationCapability } from '@citrite/workspace-ui-platform';
import { ActionAggregator } from 'App/Actions/ActionAggregator';
import { useIntegrations } from 'Integrations/useIntegrations';
import { NativeActionLaunchEventHandler } from './NativeActionLaunchEventHandler';
import { SyncActionsToNative } from './SyncActionsToNative';

export const NativeActionSyncAndLaunchEventHandlerWrapper = () => {
	const integrations = useIntegrations();
	if (
		!integrations.hasEnabledIntegrationByCapability(IntegrationCapability.actionsProvider)
	) {
		return null;
	}

	return (
		<ActionAggregator>
			{props => (
				<>
					<NativeActionLaunchEventHandler
						actionsContext={props}
					></NativeActionLaunchEventHandler>
					<SyncActionsToNative actionsContext={props}></SyncActionsToNative>
				</>
			)}
		</ActionAggregator>
	);
};
