import { IntegrationCapability, LeftNavCapability } from '@citrite/workspace-ui-platform';
import { useLocation } from 'react-router-dom';
import { appRoutes, horizontalAppRoutes } from 'App/appRoutes';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useIntegrations } from 'Integrations/useIntegrations';
import { createLeftNavRoute, LeftNavRoute } from 'Router/ReactRoute';
import { sortByWeight } from 'Router/ReactRoute/sortByWeight';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

export interface LeftNav {
	modulesLoading: boolean;
	links: LeftNavLink[];
}

export function useLeftNav(): LeftNav {
	const loadableResourcesContext = useLoadableResourceContext();
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	const integrations = useIntegrations();
	const userContext = useUserContext();
	const isDaasVisionEnabled = userContext.isDaasVisionEnabled;
	const location = useLocation();
	const leftNavIntegrations = integrations.resolveByCapability(
		IntegrationCapability.leftNavigation.primaryNavLink
	);

	if (leftNavIntegrations.find(i => i.loading)) {
		return { modulesLoading: true, links: [] };
	}

	const integrationLinks = leftNavIntegrations
		.filter(i => !!i.value)
		.map(integration => mapIntegrationsToLeftNavLink(integration.value));

	let navLinks = [
		...(isDaasVisionEnabled ? horizontalAppRoutes : appRoutes),
		...integrationLinks,
	]
		.filter(navLink => isRouteInLeftNav(navLink))
		.map(route => buildNavLink(route));

	const visibleLinks = navLinks.filter(link => link.title);
	if (visibleLinks.length === 1 && visibleLinks[0].childLinks.length) {
		// handles files only case
		navLinks = [...visibleLinks[0].childLinks, ...navLinks.filter(link => !link.title)];
	}
	return { links: navLinks.sort(sortByWeight), modulesLoading: false };

	function isAvailable(route: LeftNavRoute) {
		return route.isAvailable({
			location,
			workspaceConfiguration,
			userContext,
			resourceContext: loadableResourcesContext.value,
		});
	}

	function isRouteInLeftNav(route: LeftNavRoute) {
		const linkProps = buildLinkProps(route);
		return (
			(linkProps.title || route.hasSecondaryNav || route.isDivider) && isAvailable(route)
		);
	}

	function buildNavLink(route: LeftNavRoute) {
		const routeLinkProps = buildLinkProps(route);
		const childRoutes = route.getChildRoutes({
			integrations,
		});
		if (
			route.isLoading({
				resources: loadableResourcesContext,
				integrations,
				userContext,
			})
		) {
			routeLinkProps.isLoading = true;
			routeLinkProps.childLinks = childRoutes
				.map(() => ({ isLoading: true }))
				.slice(0, 3);
			return routeLinkProps;
		}
		const childRoutesInNav = childRoutes.filter(childRoute =>
			isRouteInLeftNav(childRoute)
		);
		const childLinks = childRoutesInNav.map(childRoute => buildNavLink(childRoute));
		routeLinkProps.childLinks = childLinks;
		return routeLinkProps;
	}

	function buildLinkProps(route: LeftNavRoute) {
		let linkProps: LeftNavLink = {
			isDivider: route.isDivider,
			hasSecondaryNav: route.hasSecondaryNav,
			href: route.getUrl((route as LeftNavRoute<{}>).indexRouteParams),
		};
		if (!route.getNavLinkProps) {
			return linkProps;
		}

		const routeLinkProps = route.getNavLinkProps(workspaceConfiguration);
		linkProps = {
			...linkProps,
			...routeLinkProps,
		};
		return linkProps;
	}
}

function createIsAvailableFn(isVisible: LeftNavCapability['isVisible']) {
	if (isVisible == null) {
		return () => true;
	}
	return () => isVisible();
}

function mapIntegrationsToLeftNavLink(integration: LeftNavCapability) {
	return createLeftNavRoute<null, any>({
		isAvailable: createIsAvailableFn(integration.isVisible),
		path: integration.path,
		getNavLinkProps: () => ({
			icon: integration.icon,
			selectedIcon: integration.mobileNativeActiveIcon,
			title: integration.title(),
			weight: integration.weight,
			performanceMark: integration.performanceMark,
			secondaryIcon: integration.secondaryIcon,
		}),
		childRoutes: integration.childLinks?.map(childLink =>
			createLeftNavRoute({
				isAvailable: createIsAvailableFn(childLink.isVisible),
				path: childLink.path,
				getNavLinkProps: () => ({
					title: childLink.title(),
					performanceMark: childLink.performanceMark,
				}),
			})
		),
	});
}

export function useBubbleUpChildLinks(link: LeftNavLink) {
	const bubbledUpLink: LeftNavLink = { ...link };
	if (link.childLinks.length === 1) {
		bubbledUpLink.href = link.childLinks[0].href;
		bubbledUpLink.performanceMark = link.childLinks[0].performanceMark;
		bubbledUpLink.childLinks = [];
	}
	return bubbledUpLink;
}
