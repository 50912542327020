import { t } from '@citrite/translate';
import { NativePlatform, SettingsLeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { environment } from 'Environment';
import { canDownloadWorkspace } from 'Environment/launchResource/clientManager';
import { isCitrixChromeApp } from 'Environment/launchResource/device';
import { createLeftNavRoute } from 'Router/ReactRoute';

const workspaceComponent = makeAsync(() => import('./Tools').then(m => m.Tools));

export const toolsRoute = createLeftNavRoute({
	component: workspaceComponent,
	path: '/settings/tools',
	isAvailable: props => {
		if (
			(environment.isNative &&
				(environment.nativePlatform === NativePlatform.Windows ||
					environment.nativePlatform === NativePlatform.Mac ||
					environment.nativePlatform === NativePlatform.Linux)) ||
			isCitrixChromeApp() ||
			!canDownloadWorkspace(props.workspaceConfiguration)
		) {
			return false;
		}

		return true;
	},
	getNavLinkProps: () => ({
		title: t('Workspace:tools.title'),
		weight: SettingsLeftNavPositions.tools,
	}),
});
