import * as React from 'react';
import { EventSubscriber, WithEventBus } from 'Components/EventBus';
import { environment } from 'Environment';
import { Event, EventBus } from 'Workspace/EventBus';

const nativeEventBus: EventBus = {
	subscribe: listener => environment.subscribeToNativeEvent(listener),
	dispatch: event => environment.sendEventToNative(event),
};

function WithNativeEventBus(props: {
	children?: (eventBus: EventBus) => React.ReactNode;
}): React.ReactElement<any> {
	return props.children(nativeEventBus) as React.ReactElement<any>; // FIXME typings are weird;
}

interface SubscribeAndPipeEventsBetweenEventBusesProps extends Props {
	platformBus: EventBus;
}
class SubscribeAndPipeEventsBetweenEventBuses extends React.Component<SubscribeAndPipeEventsBetweenEventBusesProps> {
	private pipeNativeEventsIntoPlatformBus = (event: Event<any>) => {
		this.props.platformBus.dispatch({
			...event,
			meta: {
				...event.meta,
				isFromWsuiNativeBridge: true,
			},
		});
	};

	private pipePlatformEventsIntoNativeBus = (event: Event<any>) => {
		if (event.meta && event.meta.isFromWsuiNativeBridge) {
			return;
		}
		nativeEventBus.dispatch(event);
	};

	public render() {
		return (
			<>
				<EventSubscriber
					listener={this.pipeNativeEventsIntoPlatformBus}
					eventBusProvider={WithNativeEventBus}
				/>
				<EventSubscriber
					listener={this.pipePlatformEventsIntoNativeBus}
					eventBusProvider={WithEventBus}
				/>
			</>
		);
	}
}

interface Props {
	children?: React.ReactNode;
}
export function NativeEventBusPipe(props: Props) {
	return (
		<WithEventBus>
			{platformBus => (
				<SubscribeAndPipeEventsBetweenEventBuses {...props} platformBus={platformBus} />
			)}
		</WithEventBus>
	);
}
