import React from 'react';
import { css } from 'aphrodite';
import { EmptyState } from '@citrite/web-ui-component';
import { ViewAllButton } from 'App/NativeWidget/NativeWidgetComponents/ViewAllButton';
import { WidgetSectionTitle } from 'App/NativeWidget/NativeWidgetComponents/WidgetSectionTitle';
import { NativeWidgetResourceRow } from 'App/NativeWidget/NativeWidgetResourceRow/NativeWidgetResourceRow';
import { NativeMobileResourceItemProps } from 'Components/NativeMobileResourceRow';
import { ResourceIcon } from 'Components/ResourceIcon';
import { environment } from 'Environment';
import { launchResource } from 'Environment/launchResource';
import { RoutedComponentProps } from 'Router/ReactRoute';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './styles';

export interface WidgetContentProps {
	resources: Resource[];
	viewAllString: string;
	sectionTitle: string;
	emptyText: string;
	emptyIcon: React.FC<React.AllHTMLAttributes<HTMLSpanElement>>;
	viewAllActionRoute: string;
	launchSourceGroup: string;
}

export type Props = WidgetContentProps & RoutedComponentProps;

export class WidgetTabContent extends React.Component<Props> {
	public render() {
		const {
			resources,
			viewAllString,
			sectionTitle,
			emptyText,
			emptyIcon,
			viewAllActionRoute,
		} = this.props;
		return (
			<div className={css(styles.flexContainer)}>
				<WidgetSectionTitle title={sectionTitle} />
				{resources.length > 0 ? (
					resources.map(resource => (
						<NativeWidgetResourceRow
							key={resource.id}
							item={this.transformResourceToItem(resource)}
						/>
					))
				) : (
					<div className={css(styles.emptyState)}>
						<EmptyState icon={emptyIcon}>{emptyText}</EmptyState>
					</div>
				)}

				{resources.length > 0 && (
					<ViewAllButton
						title={viewAllString}
						onClick={() => environment.switchToRoute(viewAllActionRoute)}
					/>
				)}
			</div>
		);
	}

	private transformResourceToItem = (
		resource: Resource
	): NativeMobileResourceItemProps => {
		return {
			title: resource.name,
			id: resource.id,
			icon: <ResourceIcon resource={resource} size={24} />,
			onPrimaryAction: () => this.onPrimaryAction(resource),
			data: resource,
			isLoading: this.isLoading,
		};
	};

	private isLoading = (resource: Resource): boolean => {
		const launchInProgress =
			this.props.resourceContext.isInProgress(resource.id) ||
			this.props.resourceContext.desktopRestartInProgressIds.includes(resource.id);
		return launchInProgress;
	};

	private onPrimaryAction = (resource: Resource) => {
		return launchResource({
			resource,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
			params: { LaunchSourceGroup: this.props.launchSourceGroup },
		});
	};
}
