import * as React from 'react';
import { layout, useBreakpoints } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { LoadingItem } from './LoadingItem';

const numberOfLoadingElementsInMobile = 7;
const numberOfLoadingElementsInWeb = 11;

const StyledDiv = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	width: 1060px;
	gap: ${layout.mediumSpace};
	margin: 0 auto;
	background-color: inherit;
	padding-top: 44px;
	align-items: center;
`;

const StyledDivMobile = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background-color: #ffffff;
	padding-top: 15px;
	align-items: flex-start;
	box-sizing: border-box;
`;
export function ShimmerLoader() {
	const breakpoints = useBreakpoints();

	const loadingItems = () => {
		const items = [];
		let numberOfLoadingItems = breakpoints.screenSize.onMobile
			? numberOfLoadingElementsInMobile
			: numberOfLoadingElementsInWeb;
		while (numberOfLoadingItems--) {
			items.push(<LoadingItem key={numberOfLoadingItems + 'LoadingItem'} />);
		}
		return items;
	};

	return breakpoints.screenSize.onMobile ? (
		<StyledDivMobile data-testid="feed-loading-mobile-container">
			{loadingItems()}
		</StyledDivMobile>
	) : (
		<StyledDiv data-testid="feed-loading-container">{loadingItems()}</StyledDiv>
	);
}
