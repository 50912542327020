import * as React from 'react';
import { addResourceBundle } from '@citrite/translate';
import {
	Capability,
	CapabilityRegistration,
	EndpointsService,
	IntegrationContext,
	IntegrationManifest,
	LoadableCapability,
	LoadableIntegration,
	PlatformDependencies,
} from '@citrite/workspace-ui-platform';
import { logError, normalizeMessageFromError } from 'remoteLogging';
import { createPerformanceMeasure } from 'Components/PerformanceMeasure';
import { Configuration } from 'Configuration/Context';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { getEndpointsService } from 'Environment/configSelectors';
import { detectLanguage } from 'javascript/sf/detectLanguage';
import { container as platformContainer } from 'Workspace/DependencyManagement';
import { Context, InternalIntegrationContext } from './Context';
import { createScopedContainer } from './createScopedContainer';
import { LoadedIntegration, loadIntegrations } from './loadIntegrations';

interface IntegrationConditions {
	integration?: IntegrationManifest;
	endpointService: EndpointsService;
	enabled: boolean;
}
interface RequirementResult {
	valid: boolean;
	invalidMessage: string;
	reason: RejectReason;
}
enum RejectReason {
	Unregistered,
	MissingEndpoint,
	NotEnabled,
}
type ServiceRecord = LoadableIntegration<any>;
type ServiceState = Omit<
	ServiceRecord,
	'reload' | 'resolver' | 'moduleKey' | 'integrationId'
>;

type ServiceContainer = Record<string, ServiceRecord>;

type InternalProps = Props & {
	configurationContext: Configuration;
};

export type Props = {
	children: React.ReactNode;
};

type EnabledIntegrationsByCapabilityResult<TCapabilityMetadata> = {
	integrationId: string;
	registration: CapabilityRegistration<any, string, TCapabilityMetadata>;
};

class _ServicesProvider extends React.Component<InternalProps> {
	private readonly serviceContext: IntegrationContext;
	private readonly integrations: LoadedIntegration[];
	private container: ServiceContainer = {};
	private unmounted = false;

	public constructor(props: InternalProps) {
		super(props);
		this.serviceContext = {
			resolveById: (integrationId, moduleId) => this.resolveById(integrationId, moduleId),
			resolveByCapability: capability => this.resolveByCapability(capability),
			hasEnabledIntegrationByCapability: capability =>
				this.hasEnabledIntegrationByCapability(capability),
		};

		this.integrations = loadIntegrations();

		platformContainer.registerSingleton(
			PlatformDependencies.Integrations,
			this.serviceContext
		);

		platformContainer.registerSingleton(PlatformDependencies.ScopedContainer, {
			get: symbol => this.getScopedContainer(symbol),
		});
	}

	public render() {
		const internalServicesContext: InternalIntegrationContext = {
			...this.serviceContext,
			allIntegrations: this.integrations,
		};

		return (
			<Context.Provider value={{ ...internalServicesContext }}>
				{this.props.children}
			</Context.Provider>
		);
	}

	private hasEnabledIntegrationByCapability = (capability: Capability<any, any>) =>
		!!this.getEnabledIntegrationsByCapability(capability).length;

	private getEnabledIntegrationsByCapability = <TCapabilityMetadata extends any>(
		capability: Capability<any, TCapabilityMetadata>
	) => {
		const isCapabilityMatch = (
			registration: IntegrationManifest['capabilities'][number]
		): registration is CapabilityRegistration<any, string, TCapabilityMetadata> => {
			return registration.token === capability.token;
		};

		return this.integrations
			.filter(integration => {
				const hasCapability = integration.capabilities?.find(isCapabilityMatch);
				return hasCapability && this.isIntegrationEnabled(integration);
			})
			.reduce(
				(result: EnabledIntegrationsByCapabilityResult<TCapabilityMetadata>[], next) => [
					...result,
					...next.capabilities.filter(isCapabilityMatch).map(registration => ({
						integrationId: next.id,
						registration,
					})),
				],
				[]
			);
	};

	private reload = (integrationId: string, moduleId: string) => {
		this.props.configurationContext.refreshWorkspaceConfiguration().then(() => {
			this.resolveById(integrationId, moduleId, true);
		});
	};

	private getScopedContainer = (integrationSymbol: Symbol) => {
		if (!integrationSymbol) {
			return null;
		}

		const integration = this.integrations.find(
			x => x.privateSymbol === integrationSymbol
		);

		if (!integration) {
			return null;
		}

		const { privateSymbol, ...publicIntegration } = integration;
		return integration ? createScopedContainer(publicIntegration) : null;
	};

	private resolveById(
		integrationId: string,
		moduleId: string,
		isReload = false
	): ServiceRecord {
		const performanceMeasure = createPerformanceMeasure(
			`${integrationId}-${moduleId}-resolution`
		);
		performanceMeasure.start();

		const conditions = this.getConditions(integrationId);
		const cachedModule = this.container[this.getModuleKey(integrationId, moduleId)];
		if (cachedModule && !isReload && cachedModule.enabled === conditions.enabled) {
			return { ...cachedModule };
		}
		const { failed, failedChecks } = this.getRequirements(conditions);
		if (failed) {
			const errors = failedChecks.some(x => x.reason === RejectReason.Unregistered);
			const warnings = failedChecks.some(x => x.reason === RejectReason.MissingEndpoint);
			const notEnabled = failedChecks.some(x => x.reason === RejectReason.NotEnabled);
			const getFailureType = () => {
				if (errors) {
					return 'Failed';
				} else if (notEnabled) {
					return 'Not Enabled';
				} else {
					return 'Unavailable';
				}
			};
			const error = new Error(
				`Integration '${integrationId}:${moduleId}' ${getFailureType()}: ${failedChecks
					.map(r => `${r.invalidMessage}`)
					.join(', ')}`
			);
			if (errors) {
				logError(
					normalizeMessageFromError(
						`[ServiceProvider] Encountered an error while trying to meet requirements for WSUI service with id '${this.getModuleKey(
							integrationId,
							moduleId
						)}'`,
						error
					),
					{
						additionalContext: {
							moduleKey: this.getModuleKey(integrationId, moduleId),
							failedChecks,
						},
					}
				);
			} else if (warnings) {
				console.warn(
					`[ServiceProvider] Unable to meet requirements for WSUI service with id '${this.getModuleKey(
						integrationId,
						moduleId
					)}'. A feature trying to use this service may not be appropriately gated.`,
					error
				);
			}
			performanceMeasure.end();
			return this.setServiceState(integrationId, moduleId, {
				loading: false,
				value: null,
				error,
				enabled: conditions.enabled,
				resolver: Promise.reject(error),
			});
		}

		const { integration } = conditions;
		const intitialServiceState = {
			loading: true,
		};

		const resolveWith = integration?.modulesResolver[moduleId];

		const localesResolver = this.resolveLocales(integration);
		const moduleResolver = resolveWith({
			scopedContainer: createScopedContainer(integration),
			...this.serviceContext,
		});
		const resolver = Promise.all([moduleResolver, localesResolver])
			.then(([value]) => {
				performanceMeasure.end();
				this.setServiceState(integrationId, moduleId, {
					loading: false,
					value,
					enabled: true,
					error: null,
				});
				return value;
			})
			.catch(e => {
				logError(
					normalizeMessageFromError(
						`[ServiceProvider] Error resolving WSUI service with id '${this.getModuleKey(
							integrationId,
							moduleId
						)}'`,
						e
					),
					{
						additionalContext: {
							error: e,
							moduleKey: this.getModuleKey(integrationId, moduleId),
						},
					}
				);
				performanceMeasure.end();
				this.setServiceState(integrationId, moduleId, {
					loading: false,
					value: null,
					error: e,
					enabled: true,
				});
				throw e;
			});
		return this.setServiceState(integrationId, moduleId, {
			...intitialServiceState,
			resolver,
			enabled: true,
		});
	}

	private resolveLocales(integration: IntegrationManifest) {
		const translationModule =
			integration.translationModules && integration.translationModules[detectLanguage()];
		if (!translationModule) {
			return Promise.resolve();
		}
		return translationModule()
			.then(locales => {
				addResourceBundle(detectLanguage(), integration.id, locales);
			})
			.catch(e => {
				logError(
					normalizeMessageFromError(
						`[ServiceProvider] Error loading translations for '${integration.id}'`,
						e
					),
					{
						additionalContext: {
							error: e,
							integrationId: integration.id,
						},
					}
				);
			});
	}

	private resolveByCapability<T = any, TCapabilityMetadata = never>(
		capability: Capability<T, TCapabilityMetadata>
	): LoadableCapability<T, any, TCapabilityMetadata>[] {
		return this.getEnabledIntegrationsByCapability(capability).map(
			({ integrationId, registration }) => {
				const moduleRecord = this.resolveById(integrationId, registration.moduleKey);
				return {
					...moduleRecord,
					registration,
				};
			}
		);
	}

	private getConditions(id: string): IntegrationConditions {
		const { configurationContext } = this.props;
		const workspaceConfiguration = configurationContext.workspaceConfiguration;
		const integration = this.integrations.find(i => i.id === id);
		const endpointService = getEndpointsService(
			workspaceConfiguration,
			integration?.requiredEndpointServiceName
		);

		return {
			integration,
			endpointService,
			enabled: integration && this.isIntegrationEnabled(integration),
		};
	}

	private getRequirements(conditions: IntegrationConditions) {
		const { integration, endpointService, enabled } = conditions;
		const checks: RequirementResult[] = [
			{
				valid: !!integration,
				invalidMessage: 'Integration not registered with provider',
				reason: RejectReason.Unregistered,
			},
			,
		];

		if (integration && !enabled) {
			checks.push({
				valid: false,
				invalidMessage: 'Integration is not enabled',
				reason: RejectReason.NotEnabled,
			});
		} else if (integration?.requiredEndpointServiceName) {
			checks.push({
				valid: !!endpointService,
				invalidMessage: 'Missing required endpoint service',
				reason: RejectReason.MissingEndpoint,
			});
		}

		const failedChecks = checks.filter(check => !check.valid);
		return {
			failed: failedChecks.length > 0,
			failedChecks,
		};
	}

	private isIntegrationEnabled(integration: IntegrationManifest) {
		const isEnabled =
			integration.isEnabled == null ||
			integration.isEnabled({
				hasEnabledIntegrationByCapability: this.hasEnabledIntegrationByCapability,
			});

		return isEnabled;
	}

	private getModuleKey(integrationId: string, moduleId: string) {
		return `${integrationId}.${moduleId}`;
	}

	private setServiceState(
		integrationId: string,
		moduleId: string,
		serviceStateUpdate: ServiceState & { resolver?: Promise<any> }
	) {
		const moduleKey = this.getModuleKey(integrationId, moduleId);
		const currentResolver = this.container[moduleKey]?.resolver;
		const stateUpdate: ServiceRecord = {
			...serviceStateUpdate,
			moduleKey,
			integrationId,
			reload: () => this.reload(integrationId, moduleId),
			resolver:
				serviceStateUpdate.resolver ||
				currentResolver ||
				Promise.reject(
					new Error(
						'Expected to get a resolver from the serviceStateUpdate but none provided.'
					)
				),
		};
		this.container[moduleKey] = stateUpdate;

		// This needs to be deferred because service resolution
		// can be invoked from a function component or hook,
		// at which point we're already in a render phase. Calling
		// forceUpdate triggers another one, which in turn triggers
		// React's 'side effect in render' detection
		setImmediate(() => {
			!this.unmounted && this.forceUpdate();
		});

		// Quirk with testing that requires promise rejects
		// to be handled when a references is kept but we
		// don't want to mask errors from actual consumers.
		// Forking a single .catch off the promise node.
		stateUpdate.resolver.catch(_ => null);
		return stateUpdate;
	}

	public componentWillUnmount() {
		platformContainer.unregister(PlatformDependencies.Integrations);
		this.unmounted = true;
	}
}

export const IntegrationProvider = (props: Props) => {
	const configurationContext = useConfigurationContext();
	return <_ServicesProvider {...props} configurationContext={configurationContext} />;
};
