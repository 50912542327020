import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { platform } from 'Environment/launchResource/constants';
import {
	getCurrentPlatform,
	isCitrixChromeApp,
	isNativeClient,
} from 'Environment/launchResource/device';

export function isPerformanceOptimizationEnabled(
	workspaceConfiguration: WorkspaceConfiguration,
	flagMap: Record<string, string>
): boolean {
	const isGlobalFeatureFlagEnabled = hasFeatureCanary(
		workspaceConfiguration,
		flagMap.feature
	);
	return (
		IS_ON_PREM ||
		(isGlobalFeatureFlagEnabled &&
			(!isNativeClient() ||
				isFeatureEnabledOnNativePlatform(workspaceConfiguration, flagMap)))
	);
}

function isFeatureEnabledOnNativePlatform(
	workspaceConfiguration: WorkspaceConfiguration,
	flagMap: Record<string, string>
): boolean {
	const currentPlatformFlag = flagMap[getCurrentPlatform()];
	return currentPlatformFlag
		? hasFeatureCanary(workspaceConfiguration, currentPlatformFlag)
		: false;
}

export const staleWhileRevalidateStrategyFlagRecord: Record<string, string> = {
	feature: FeatureFlag.EnableStaleWhileRevalidate,
	[platform.windows]: FeatureFlag.EnableStaleWhileRevalidateForWindows,
	[platform.mac]: FeatureFlag.EnableStaleWhileRevalidateForMac,
};

export const getUserDetailsFromCacheFlagRecord: Record<string, string> = {
	feature: FeatureFlag.EnablePrefetchUserDetailsFromCache,
	[platform.windows]: FeatureFlag.EnablePrefetchUserDetailsFromCacheForWindows,
	[platform.mac]: FeatureFlag.EnablePrefetchUserDetailsFromCacheForMac,
};

export const shouldNativeAppBeMandated = (
	workspaceConfiguration: WorkspaceConfiguration
) => {
	return (
		!isNativeClient() &&
		!isCitrixChromeApp() &&
		workspaceConfiguration?.pluginAssistant?.requireNativeAppUse?.toLowerCase() === 'true'
	);
};
