import { LocalApp } from 'Environment/nativeFunctions';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { localAppResourceType, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { getLocalAppsFromStorage } from './localAppStorage';
import { localAppToResource } from './localAppToResource';

export const synchronizeLocalApps = async (localAppFromNative: LocalApp[]) => {
	const storedLocalApps = await getLocalAppsFromStorage();
	const availableResourceIds = localAppFromNative.map(app => {
		const resource = localAppToResource(app);
		return resource.id;
	});
	return storedLocalApps.filter(app => availableResourceIds.includes(app.id));
};

export const updateResourceContextWithLocalApps = (
	context: ResourceContext,
	localApps: Resource[]
) => {
	const currentResources = context.resources ?? [];
	const nonLocalApps = currentResources.filter(
		resource => resource.type !== localAppResourceType
	);
	context.updateSession({
		resources: [...nonLocalApps, ...localApps],
	});
};
