import React from 'react';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { CategoryTile } from 'App/Tile/CategoryTile';

interface Props {
	subCategory: string;
	subCategoryPath?: any[];
	setSubCategoryPath?: React.Dispatch<React.SetStateAction<any[]>>;
}

function _CategoryTileRenderer({
	subCategory,
	setSubCategoryPath,
	subCategoryPath,
}: Props) {
	return (
		<CategoryTile
			subCategory={subCategory}
			setSubCategoryPath={setSubCategoryPath}
			subCategoryPath={subCategoryPath}
		/>
	);
}

export const CategoryTileRenderer = (props: any) => {
	return (
		<GenericErrorBoundary
			renderProp={() => <_CategoryTileRenderer {...props} />}
			name="CategoryTileRenderer"
		/>
	);
};
