import * as React from 'react';
import { css } from 'aphrodite';
import { bottomNavItemIconSize } from 'App/BottomNav/dimensions';
import { styles } from './styles';

interface Props {
	icon: React.FC<React.AllHTMLAttributes<HTMLSpanElement>>;
	color?: string;
}

export function LinkIcon(props: Props) {
	return (
		<div className={css(styles.tabItem)}>
			<span className={css(styles.icon)}>
				<props.icon size={bottomNavItemIconSize} color={props.color} />
			</span>
		</div>
	);
}
