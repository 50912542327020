/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fi' | 'fr' | 'it' | 'ja' | 'ko' | 'nl' | 'pt-BR' | 'ru' | 'zh-CN' | 'zh-TW';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "add_favorite": "Den Favoriten hinzufügen",
      "all": "Alle",
      "apply": "Anwenden",
      "ascending": "A bis Z",
      "cancel": "Abbrechen",
      "close": "Schließen",
      "collapsible_section_button": "Abschnittsschaltfläche ein/aus",
      "descending": "Z bis A",
      "error_logged": "Ein Fehler ist aufgetreten und wurde protokolliert.",
      "filter": "Filter",
      "more_options": "Mehr Optionen",
      "more_options_aria_label": "Weitere Optionen für {{name}}",
      "no_resources_contact": "Versuchen Sie es später erneut oder wenden Sie sich an Ihren Administrator, um Zugriff zu erhalten.",
      "remove_favorite": "Aus Favoriten entfernen"
    }
  },
  {
    "key": "en",
    "content": {
      "add_favorite": "Add to favorites",
      "all": "All",
      "apply": "Apply",
      "ascending": "A to Z",
      "cancel": "Cancel",
      "close": "Close",
      "collapsible_section_button": "Toggle section button",
      "descending": "Z to A",
      "error_logged": "An error has occurred and has been logged.",
      "filter": "Filter",
      "more_options": "More options",
      "more_options_aria_label": "More Options for {{name}}",
      "no_resources_contact": "Check back later, or contact your administrator for access.",
      "remove_favorite": "Remove from favorites"
    }
  },
  {
    "key": "es",
    "content": {
      "add_favorite": "Agregar a favoritos",
      "all": "Todo",
      "apply": "Aplicar",
      "ascending": "A - Z",
      "cancel": "Cancelar",
      "close": "Cerrar",
      "collapsible_section_button": "Botón para alternar sección",
      "descending": "Z - A",
      "error_logged": "Se ha producido un error y se ha registrado.",
      "filter": "Filtrar",
      "more_options": "Más opciones",
      "more_options_aria_label": "Más opciones para {{name}}",
      "no_resources_contact": "Vuelva a comprobarlo más tarde o póngase en contacto con el administrador para obtener acceso.",
      "remove_favorite": "Quitar de favoritos"
    }
  },
  {
    "key": "fi",
    "content": {
      "add_favorite": "Lisää suosikkeihin",
      "all": "Kaikki",
      "apply": "Käytä",
      "ascending": "A–Z",
      "cancel": "Peruuta",
      "close": "Sulje",
      "collapsible_section_button": "Osionvaihtopainike",
      "descending": "Z–A",
      "error_logged": "Tapahtui virhe, ja se on kirjattu lokiin.",
      "filter": "Suodata",
      "more_options": "Lisää vaihtoehtoja",
      "more_options_aria_label": "Lisää vaihtoehtoja kohteelle {{name}}",
      "no_resources_contact": "Tarkista tilanne myöhemmin tai pyydä käyttöoikeutta järjestelmänvalvojaltasi.",
      "remove_favorite": "Poista suosikeista"
    }
  },
  {
    "key": "fr",
    "content": {
      "add_favorite": "Ajouter aux favoris",
      "all": "Tout",
      "apply": "Appliquer",
      "ascending": "A à Z",
      "cancel": "Annuler",
      "close": "Fermer",
      "collapsible_section_button": "Bouton pour changer de session",
      "descending": "Z à A",
      "error_logged": "Une erreur s'est produite et a été consignée.",
      "filter": "Filtrer",
      "more_options": "Plus d'options",
      "more_options_aria_label": "Plus d'options pour {{name}}",
      "no_resources_contact": "Revenez plus tard ou contactez votre administrateur pour obtenir l'accès.",
      "remove_favorite": "Supprimer des favoris"
    }
  },
  {
    "key": "it",
    "content": {
      "add_favorite": "Aggiungi a preferiti",
      "all": "Tutto",
      "apply": "Applica",
      "ascending": "Da A a Z",
      "cancel": "Annulla",
      "close": "Chiudi",
      "collapsible_section_button": "Pulsante di cambio sezione",
      "descending": "Da Z ad A",
      "error_logged": "Si è verificato un errore che è stato registrato.",
      "filter": "Filtro",
      "more_options": "Più opzioni",
      "more_options_aria_label": "Altre opzioni per {{name}}",
      "no_resources_contact": "Ricontrollare in un secondo momento o contattare l'amministratore per l'accesso.",
      "remove_favorite": "Rimuovi da preferiti"
    }
  },
  {
    "key": "ja",
    "content": {
      "add_favorite": "お気に入りに追加",
      "all": "すべて",
      "apply": "適用",
      "ascending": "昇順",
      "cancel": "キャンセル",
      "close": "閉じる",
      "collapsible_section_button": "セクション切り替えボタン",
      "descending": "降順",
      "error_logged": "エラーが発生し、ログに記録されました。",
      "filter": "フィルター",
      "more_options": "そのほかのオプション",
      "more_options_aria_label": "{{name}} のその他のオプション",
      "no_resources_contact": "後でもう一度確認するか、管理者に問い合わせてアクセスを要求してください。",
      "remove_favorite": "お気に入りから削除"
    }
  },
  {
    "key": "ko",
    "content": {
      "add_favorite": "즐겨찾기에 추가",
      "all": "모두",
      "apply": "적용",
      "ascending": "오름차순",
      "cancel": "취소",
      "close": "닫기",
      "collapsible_section_button": "섹션 전환 버튼",
      "descending": "내림차순",
      "error_logged": "오류가 발생하여 로그되었습니다.",
      "filter": "필터",
      "more_options": "추가 옵션",
      "more_options_aria_label": "{{name}}의 추가 옵션",
      "no_resources_contact": "나중에 다시 확인하거나 관리자에게 액세스 권한을 문의하십시오.",
      "remove_favorite": "즐겨찾기에서 제거"
    }
  },
  {
    "key": "nl",
    "content": {
      "add_favorite": "Toevoegen aan favorieten",
      "all": "Alle",
      "apply": "Toepassen",
      "ascending": "A tot Z",
      "cancel": "Annuleren",
      "close": "Sluiten",
      "collapsible_section_button": "Sectiewisselknop",
      "descending": "Z tot A",
      "error_logged": "Er is een fout opgetreden en deze is geregistreerd.",
      "filter": "Filteren",
      "more_options": "Meer opties",
      "more_options_aria_label": "Meer opties voor {{name}}",
      "no_resources_contact": "Controleer later opnieuw of neem contact op met uw beheerder voor toegang.",
      "remove_favorite": "Verwijderen uit favorieten"
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "add_favorite": "Adicionar aos favoritos",
      "all": "Todos",
      "apply": "Aplicar",
      "ascending": "A a Z",
      "cancel": "Cancelar",
      "close": "Fechar",
      "collapsible_section_button": "Botão de alternância de seção",
      "descending": "Z a A",
      "error_logged": "Ocorreu um erro, que foi registrado em log.",
      "filter": "Filtrar",
      "more_options": "Mais opções",
      "more_options_aria_label": "Mais opções para {{name}}",
      "no_resources_contact": "Verifique novamente mais tarde ou entre em contato com o administrador para ter acesso.",
      "remove_favorite": "Remover dos favoritos"
    }
  },
  {
    "key": "ru",
    "content": {
      "add_favorite": "Добавить в избранное",
      "all": "All",
      "apply": "Apply",
      "ascending": "От А до Я",
      "cancel": "Cancel",
      "close": "Закрыть",
      "collapsible_section_button": "Toggle section button",
      "descending": "От Я до А",
      "error_logged": "An error has occurred and has been logged.",
      "filter": "Filter",
      "more_options": "Дополнительные параметры",
      "more_options_aria_label": "More Options for {{name}}",
      "no_resources_contact": "Проверьте позже или обратитесь к администратору для получения доступа.",
      "remove_favorite": "Удалить из избранного"
    }
  },
  {
    "key": "zh-CN",
    "content": {
      "add_favorite": "添加到收藏夹",
      "all": "全部",
      "apply": "应用",
      "ascending": "升序",
      "cancel": "取消",
      "close": "关闭",
      "collapsible_section_button": "切换分区按钮",
      "descending": "降序",
      "error_logged": "发生错误并已记录。",
      "filter": "过滤器",
      "more_options": "更多选项",
      "more_options_aria_label": "{{name}} 的更多选项",
      "no_resources_contact": "请稍后返回查看，或者联系您的管理员以获取访问权限。",
      "remove_favorite": "从收藏夹中删除"
    }
  },
  {
    "key": "zh-TW",
    "content": {
      "add_favorite": "新增到我的最愛",
      "all": "全部",
      "apply": "套用",
      "ascending": "遞增",
      "cancel": "取消",
      "close": "關閉",
      "collapsible_section_button": "切換區段按鈕",
      "descending": "遞減",
      "error_logged": "發生錯誤並已記錄。",
      "filter": "篩選",
      "more_options": "更多選項",
      "more_options_aria_label": "{{name}} 的更多選項",
      "no_resources_contact": "請稍後返回查看，或連絡您的管理員以取得存取權。",
      "remove_favorite": "從我的最愛移除"
    }
  }
];