import { detectNativePlatform } from 'Environment/setup/nativeDetection';

const cdnURL = CTXSHTML_GLOBALS.BASE_CDN_URL;
const { isNative } = detectNativePlatform();
if (inLegacyOfflineAppcacheMode()) {
	// use relative paths for loading from the filesystem. this relates to a legacy
	// CWA appcache mechanism that predates serviceworker usage + shield
	__webpack_public_path__ = 'assets/workspace/';
} else if (cdnURL) {
	__webpack_public_path__ = cdnURL;
}

export function inLegacyOfflineAppcacheMode() {
	return (
		isNative &&
		location.protocol === 'file:' &&
		process.env.NODE_ENV === 'production' &&
		!IS_DEPLOYED_FEATURE_BRANCH
	);
}
