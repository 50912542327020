import * as signalR from '@microsoft/signalr';
import { workspacePlatformHeaders } from 'javascript/interceptors/workspacePlatform';

export interface SignalRClientOptions {
	customerId: string;
	storeId: string;
	proxyUrl: string;
	baseUrl: string;
}

export class SignalRHttpClient extends signalR.DefaultHttpClient {
	private readonly options: SignalRClientOptions;
	private hasResolvedAzureSignalRUrl?: boolean;
	public connectionId: string;
	public constructor(options: SignalRClientOptions, logger: signalR.ILogger) {
		super(logger);

		this.options = options;
	}

	public async send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		let response: signalR.HttpResponse;
		const { baseUrl, customerId, storeId, proxyUrl } = this.options;

		// First call is made through the SSO Proxy to get the SignalR URL that
		// includes an accessToken for the client to use during the connection negotiation
		if (!this.hasResolvedAzureSignalRUrl) {
			request = {
				...request,
				url: `${baseUrl}${proxyUrl}`,
				headers: {
					...request.headers,
					...workspacePlatformHeaders(),
					Accept: 'application/json',
					'Citrix-CustomerId': customerId,
					'Citrix-InstanceId': storeId,
					'Content-Type': 'application/json',
					'Citrix-WSP-Proxy-URL': request.url,
				},
			};
			response = await super.send(request);
			if (response.statusCode === 200) {
				this.hasResolvedAzureSignalRUrl = true;
			}
		} else {
			// Second call made returns the connectionId and available transports
			response = await super.send(request);
			if (!this.connectionId) {
				this.connectionId = JSON.parse(response?.content as string).connectionId;
			}
		}
		return response;
	}
}
