import { environment } from './';
import {
	bridgeInbound,
	bridgeOutbound,
	bridgeOverrides,
	bridgeWatchEnabled,
} from './bridgeTools';
import { generateCallbackId } from './generateCallbackId';
import { queueNativeMessage } from './nativeMessaging';
import { ProvidedNativeFunctions } from './providedNativeFunctions';

const callbacks = new Map<string, Function>();

type MapNativeResponse<Return> = (...argumentsFromNative: any[]) => Return;

export function callAsyncNativeFunction<
	Name extends keyof ProvidedNativeFunctions,
	Return
>(
	functionName: Name,
	execute: (bridge: Pick<ProvidedNativeFunctions, Name>, callbackId?: string) => any,
	mapResponse: MapNativeResponse<Return> = value => value
) {
	window[`ctxs_${functionName}_complete`] = continueNativeCallback;

	const bridge = getNativeBridge(functionName);
	const callbackId = generateCallbackId();
	const promise = new Promise<Return>((resolve, reject) => {
		callbacks.set(callbackId, (...argumentsFromNative: any[]) => {
			try {
				resolve(mapResponse(...argumentsFromNative));
			} catch (e) {
				reject(e);
			}
		});
	});
	if (!IS_RELEASE && bridgeOverrides.has(functionName)) {
		// eslint-disable-next-line no-console
		console.log(`🚧 Overriding ${functionName} with:`, bridgeOverrides.get(functionName));
		continueNativeCallback(callbackId, ...bridgeOverrides.get(functionName));
	} else if (!IS_RELEASE && bridgeWatchEnabled) {
		// May not be supported on all webview clients
		const bridgeWrap: any = {
			[functionName]: (...args: any[]) => {
				bridgeOutbound(functionName, args, callbackId);
				return bridge[functionName].apply(null, args);
			},
		};
		execute(bridgeWrap, callbackId);
	} else {
		execute(bridge, callbackId);
	}
	return promise;
}

export function callSyncNativeFunction<Name extends keyof ProvidedNativeFunctions>(
	functionName: Name,
	execute: (
		bridge: Pick<ProvidedNativeFunctions, Name>
	) => ReturnType<ProvidedNativeFunctions[Name]>
) {
	const bridge = getNativeBridge(functionName);
	if (!IS_RELEASE && bridgeOverrides.has(functionName)) {
		// eslint-disable-next-line no-console
		console.log(
			`🚧 Overriding ${functionName} with`,
			bridgeOverrides.get(functionName)[0]
		);
		return bridgeOverrides.get(functionName)[0];
	} else if (!IS_RELEASE && bridgeWatchEnabled) {
		// May not be supported on all webview clients
		const bridgeWrap: any = {
			[functionName]: (...args: any[]) => {
				bridgeOutbound(functionName, args);
				return bridge[functionName].apply(null, args);
			},
		};
		const response = execute(bridgeWrap);
		bridgeInbound(response);
		return response;
	} else {
		return execute(bridge);
	}
}

function getNativeBridge<Name extends keyof ProvidedNativeFunctions>(name: Name) {
	// some Receivers don't implement an object on the window with
	// callable functions, but rely on a messaging queue we notify
	// them to pull from
	const bridge =
		environment.nativeCommunicationMethod === 'bridge'
			? window.external
			: {
					[name]: (...args: any[]) => {
						queueNativeMessage(name, ...args);
					},
			  };

	return bridge as any as Pick<ProvidedNativeFunctions, Name>;
}

function continueNativeCallback(callbackId: string, ...params: any[]) {
	if (!callbacks.has(callbackId)) {
		return;
	}
	if (bridgeWatchEnabled) {
		bridgeInbound(params, callbackId);
	}

	const callback = callbacks.get(callbackId);
	callback(...params);
	callbacks.delete(callbackId);
}
