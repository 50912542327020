import React from 'react';
import styled from '@emotion/styled';
import { ActivityDisconnectedIcon } from 'App/Activity/ActivityDisconnectedIcon/ActivityDisconnectedIcon';
import {
	handleIconClick,
	isDisconnectedButNotSuspendedSession,
} from 'App/Activity/components/Utility';
import { Session } from 'App/Activity/ResourceManagerContext';

export const MoreInfo = ({ session }: { session: Session }) => {
	const StyledSpan = styled.span`
		cursor: default;
	`;
	return isDisconnectedButNotSuspendedSession(session) ? (
		<StyledSpan onClick={handleIconClick} data-testid="more-info">
			<ActivityDisconnectedIcon />
		</StyledSpan>
	) : null;
};
