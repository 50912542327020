import React, { useRef } from 'react';
import { AlertProps, ArrowDownIcon, InfoBanner } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { ContentProps } from 'Components/AppLayout/AdminNotificationBanner';
import { paddingAndIconOffset } from 'Components/AppLayout/MastheadInformationalBanner.styled';
import { bannerHeight, MastheadOffset } from 'Workspace/ThemeProvider';

type BannerTextAlignment = 'center' | 'left' | 'right';
interface Props {
	Content: React.ComponentType<ContentProps>;
	bannerPosition: BannerPosition;
	mastheadOffset?: MastheadOffset;
	textAlign?: BannerTextAlignment;
	isAdminBanner?: boolean;
	Banner?: React.ComponentType<AlertProps>;
}

const StyledLineClamp = styled.div<{
	expandedBanner: boolean;
	innerWidth: number;
	bannerPosition: BannerPosition;
}>`
	cursor: pointer;
	max-width: ${props => props.innerWidth - paddingAndIconOffset}px;
	display: inline-block;
	overflow: ${props => (props.expandedBanner ? 'visible' : 'hidden')};
	text-overflow: ellipsis;
	white-space: ${props => (props.expandedBanner ? 'normal' : 'nowrap')};
`;

const StyledDropdownIcon = styled(ArrowDownIcon)<{
	expandedBanner: boolean;
	bannerPosition: BannerPosition;
}>`
	cursor: pointer;
	display: inline-block;
	position: absolute;
	right: 10px;
	${props =>
		props.bannerPosition !== BannerPosition.Bottom &&
		props.expandedBanner &&
		'transform: rotate(180deg)'};
	${props =>
		props.bannerPosition === BannerPosition.Bottom &&
		!props.expandedBanner &&
		'transform: rotate(180deg)'};
`;

const StyledContainer = styled.div<{
	bannerPosition: BannerPosition;
	textAlign: BannerTextAlignment;
}>`
	${props =>
		props.bannerPosition === BannerPosition.Bottom && 'position: fixed; bottom: 0px;'};
	text-align: ${props => props.textAlign};
	width: 100vw;
	> div:nth-of-type(1) {
		margin-bottom: 0px;
	}
`;

export function DaasNotificationBanner(props: Props) {
	const { Content, bannerPosition, mastheadOffset, isAdminBanner } = props;
	const [isBannerExpanded, setBannerExpanded] = React.useState(false);
	const [displayArrowIcon, setDisplayArrowIcon] = React.useState(true);
	const [clientWidth, updateClientWidth] = React.useState(document.body.clientWidth);
	const contentRef = useRef(null);
	bannerPosition === BannerPosition.Bottom &&
		mastheadOffset?.setDaasBottomOffset(bannerHeight);
	React.useEffect(() => {
		window.addEventListener('click', onClickOutsideBanner);
		if (!isAdminBanner) {
			window.addEventListener('resize', onWindowResize);
			checkTruncation();
		}
		return () => {
			window.removeEventListener('click', onClickOutsideBanner);
			if (!isAdminBanner) {
				window.removeEventListener('resize', onWindowResize);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkTruncation = () => {
		if (contentRef?.current) {
			const allContent = contentRef?.current?.scrollWidth;
			const visibleContent = contentRef?.current?.clientWidth;
			setDisplayArrowIcon(allContent > visibleContent);
		}
	};
	React.useEffect(() => {
		if (contentRef?.current) {
			checkTruncation();
		}
	}, [clientWidth]);

	const onWindowResize = debounce(() => {
		setBannerExpanded(false);
		updateClientWidth(window.document.body.clientWidth);
	}, 300);

	const onClickOutsideBanner = () => {
		setBannerExpanded(false);
	};

	const onBannerClick = (e: React.MouseEvent) => {
		if (isAdminBanner || displayArrowIcon) {
			setBannerExpanded(!isBannerExpanded);
			e.stopPropagation();
		}
	};

	const BannerComponent = props.Banner || InfoBanner;

	return (
		<StyledContainer
			data-testid="daas-admin-banner-container"
			bannerPosition={bannerPosition}
			textAlign={props.textAlign || 'left'}
			onClick={onBannerClick}
		>
			<BannerComponent>
				<StyledLineClamp
					data-testid="daas-admin-banner-line-clamp"
					innerWidth={window.innerWidth}
					expandedBanner={isBannerExpanded}
					bannerPosition={bannerPosition}
					ref={contentRef}
				>
					<Content isExpanded={isBannerExpanded} />
				</StyledLineClamp>
				{(isAdminBanner || displayArrowIcon) && (
					<StyledDropdownIcon
						data-testid="daas-admin-banner-dropdown-icon"
						role="button"
						tabIndex={0}
						expandedBanner={isBannerExpanded}
						bannerPosition={bannerPosition}
						onClick={onBannerClick}
					/>
				)}
			</BannerComponent>
		</StyledContainer>
	);
}
