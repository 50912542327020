import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	fromThemeColors,
	Modal,
	ModalBody,
	ModalHeader,
	ModalProps,
	ModalTitle,
	Theme,
	typography,
} from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Results, SearchModule } from 'App/Masthead/SearchModule';
import { AppSearchNamespace } from 'App/Search/locales';
import { SearchResultsProvider } from 'App/Search/SearchResultsProvider';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { withLocalizationNamespaces } from 'Components/withLocalizationNamespaces';
import { useUserContext } from 'Workspace/UserContext';
import { styles } from './styles';

export const mobileSearchBarHeight = 75;

const StyledDiv = styled.div<{
	theme: Theme;
}>`
	height: ${mobileSearchBarHeight + 'px'};
	${props => `
		background-color: ${props.theme.branding.styles.HeaderBackgroundColor};
		color: ${props.theme.branding.styles.HeaderTextColor}; 
	`}
	border-bottom: 1px solid;
	border-color: ${fromThemeColors('inputBorder')};
	justify-content: center;
	align-items: center;
`;

const StyledSearchAreaDiv = styled.div`
	height: 40px;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 15px;
`;

const StyledSearchResultDiv = styled.div`
	height: 100%;
	width: 100%;
`;

const StyledSearchBoxDiv = styled.div`
	justify-content: left;
	align-items: flex-start;
	flex: 15;
`;

const StyledCancelArea = styled.div`
	align-items: right;
	margin-right: 10px;
	flex: 1;
`;

const StyledCancelButton = styled.button`
	border: none;
	background-color: inherit;
	font-family: 'PublicSans', Arial, sans-serif;
	font-weight: ${typography.weights.regular};
	font-size: ${typography.sizes.base};
	margin-top: 5px;
	align-self: flex-end;
	justify-self: flex-end;
`;

function _MobileSearchModal(props: ModalProps) {
	const [searchResults, setSearchResults] = React.useState<any>({});
	const theme = useTheme();

	const daasVisionEnabled = useUserContext().isDaasVisionEnabled;

	const onSearchResultsUpdation = (results: any) => {
		setSearchResults(results);
	};

	const onSelectItem = () => {
		props.onCloseSuccess();
	};

	/**
		 **WSUI-7463: In Android if the keyboard is dismissed by tapping the hardware down button, the view port does not resize. 
		 We need to re-render and set the search results again if the client viewport height changes.
		 useClientHeight is a hook that will re-trigger when the client height changes. This will trigger a re-render when the client height changes.
		 */
	useClientHeight();

	return (
		<div className={css(styles.container)}>
			<Modal isFullScreen className={css(styles.modal)} {...props}>
				{daasVisionEnabled ? (
					<StyledDiv
						theme={theme}
						data-testid="application-banner-search"
						data-analytics-id="search-module"
						aria-label={t('javascript:search.search_workspace')}
					>
						<StyledSearchAreaDiv>
							<StyledSearchBoxDiv>
								<SearchModule
									displayResults={false}
									onSearchResultsUpdation={onSearchResultsUpdation}
								/>
							</StyledSearchBoxDiv>
							<StyledCancelArea>
								<StyledCancelButton onClick={props.onCloseSuccess}>
									{t('Workspace:cancel')}
								</StyledCancelButton>
							</StyledCancelArea>
						</StyledSearchAreaDiv>
					</StyledDiv>
				) : (
					<ModalHeader onDismiss={props.onDismiss}>
						<ModalTitle className={css(styles.modalTitle)}>
							<div className={css(styles.title)}>{t('App/Search:search')}</div>
						</ModalTitle>
					</ModalHeader>
				)}
				<ModalBody className={css(styles.body)}>
					{daasVisionEnabled ? (
						<StyledSearchResultDiv data-testid="search-result-mobile">
							<Results searchResults={searchResults} onSelectItem={onSelectItem} />
						</StyledSearchResultDiv>
					) : (
						<SearchResultsProvider />
					)}
				</ModalBody>
			</Modal>
		</div>
	);
}

export const MobileSearchModal =
	withLocalizationNamespaces(AppSearchNamespace)(_MobileSearchModal);
