import * as React from 'react';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';

export interface Configuration {
	workspaceConfiguration: WorkspaceConfiguration;
	refreshWorkspaceConfiguration(): Promise<void>;
}

export const ConfigurationContext = React.createContext<Configuration>({
	workspaceConfiguration: {} as WorkspaceConfiguration,
	refreshWorkspaceConfiguration: () => Promise.resolve(),
});
