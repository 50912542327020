import { platform } from 'Environment/launchResource/constants';

const downloadUrls = {
	[platform.windows]:
		'https://www.citrix.com/downloads/workspace-app/citrix-enterprise-browser-for-windows/',
	[platform.mac]:
		'https://www.citrix.com/downloads/workspace-app/citrix-enterprise-browser-for-mac/',
};

const guideUrls = {
	[platform.windows]: '', // Not yet available from documentation team
	[platform.mac]: '', // Not yet available from documentation team
};

const openExternalLink = (url: string) => window.open(url, '_blank');

export const openCEBDownloadPage = (device?: platform) =>
	openExternalLink(downloadUrls[device || platform.windows]);

export const openCEBGuidePage = (device?: platform) =>
	openExternalLink(guideUrls[device || platform.windows]);
