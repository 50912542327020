import URI from 'urijs';
import { showPreventIcaDownloadsModal } from 'Environment/launchResource/PreventIcaDownloadsModal';

let iframe: HTMLIFrameElement;

export function downloadICAFile(
	url: string,
	handleLaunchStatus: (contents: string) => void,
	preventIcaDownloads: string,
	resourceName: string
) {
	const currentTime = Date.now();
	const launchURL = new URI(url).setSearch('launchId', currentTime.toString()).toString();

	if (preventIcaDownloads === 'true') {
		showPreventIcaDownloadsModal(resourceName);
		return Promise.resolve();
	}

	if (!iframe) {
		iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
	}

	iframe.src = launchURL;
	iframe.addEventListener('load', onLoad);

	function onLoad() {
		iframe.removeEventListener('load', onLoad);
		const contents = iframe.innerText;
		if (contents) {
			// The iframe contents are reported as empty when an ICA launch is triggered via file type association.
			return handleLaunchStatus(contents);
		}
	}

	return Promise.resolve();
}
