import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import {
	FavoriteLockedIcon,
	FavoriteOffIcon,
	FavoriteOnIcon,
	LoadingIconPrimary,
	notifyError,
	palette,
	Tooltip,
} from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import { tooltipDelay } from 'App/Screen/util';
import { addResourceAndUpdateStore } from 'Environment/addResource';
import { isFavorite } from 'Environment/favorites';
import { removeResourceAndUpdateStore } from 'Environment/removeResource';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { DesktopsUserEventPayload } from 'Workspace/TelemetryEvents/desktopsEvent/DesktopsUserEventPayload';
import { styles } from './Favorite.styles';

const iconSize = 16;
export interface Props extends Resource, WithResourceContextProps {
	isPending?: boolean;
	showLabel?: boolean;
	disabled?: boolean;
	hideIcon?: boolean;
	inputRef?: React.RefObject<HTMLInputElement>;
	iconSize?: number;
	styles?: any;
}

interface State {
	isPending?: boolean;
	isFavorited: boolean;
}

class _Favorite extends React.Component<Props, State> {
	public state: State = {
		isPending: this.props.isPending,
		// TransitionGroup cannot pass updated props into a child component once
		// it's transitioning out, preventing us from getting an updated this.props
		isFavorited: isFavorite(this.props),
	};

	public render() {
		return (
			<label className={css(styles.label)}>
				<div
					className={css(
						styles.button,
						(this.state.isPending || this.props.mandatory) && styles.statusOnly
					)}
				>
					{this.props.mandatory ? (
						<Tooltip hoverTrigger={this.getFavoriteLockedIcon()} delay={tooltipDelay}>
							<>{t('Workspace:required')}</>
						</Tooltip>
					) : this.state.isPending ? (
						<span className={css({ ...styles.loading, ...this.props.styles?.loading })}>
							<LoadingIconPrimary size={this.props.iconSize || iconSize} />
						</span>
					) : (
						<>
							<input
								type="checkbox"
								checked={this.state.isFavorited}
								onChange={this.onChange}
								aria-label={t('Workspace:favorite')}
								className={css(styles.input)}
								disabled={this.props.disabled}
								ref={this.props.inputRef}
							/>
							{!this.props.hideIcon && (
								<Tooltip hoverTrigger={this.getFavoriteIcon()} delay={tooltipDelay}>
									<>
										{this.state.isFavorited
											? t('Workspace:remove_favorite')
											: t('Workspace:add_favorite')}
									</>
								</Tooltip>
							)}
						</>
					)}
					{this.props.showLabel && (
						<>
							{this.props.mandatory
								? t('Workspace:mandatory_favorite')
								: this.state.isFavorited
								? t('Workspace:remove_favorite')
								: t('Workspace:add_favorite')}
						</>
					)}
				</div>
			</label>
		);
	}

	public componentDidUpdate(prevProps: Props) {
		const wasPending = !!prevProps.isPending;
		const isPending = !!this.props.isPending;

		if (wasPending !== isPending && isPending !== this.state.isPending) {
			this.setState({
				isPending,
			});
		}

		// Because we're tracking favorited state to accommodate TransitionGroup,
		// we need to update state on prop changes to support duplicate
		// renderings of the favorite icon.
		// (e.g. Details overlay with listed resources behind overlay)
		const wasFavorited = isFavorite(prevProps);
		const isFavorited = isFavorite(this.props);

		if (wasFavorited !== isFavorited && isFavorited !== this.state.isFavorited) {
			this.setState({
				isFavorited,
			});
		}
	}

	private getFavoriteLockedIcon = () => {
		return (
			<FavoriteLockedIcon
				className={css({ ...styles.icon, ...this.props.styles?.icon }, styles.statusOnly)}
				size={this.props.iconSize || iconSize}
				alt={t('Workspace:icon_titles.mandatory_favorite')}
			/>
		);
	};

	private getFavoriteIcon = () => {
		const Icon = this.state.isFavorited ? FavoriteOnIcon : FavoriteOffIcon;
		return (
			<Icon
				className={css(
					{ ...styles.icon, ...this.props.styles?.icon },
					styles.iconHover,
					this.props.disabled && styles.disabled
				)}
				hoverColor={!this.state.isFavorited && !this.props.disabled && palette.grey700}
				size={this.props.iconSize || iconSize}
				tabIndex={-1}
				alt={
					this.state.isFavorited
						? t('Workspace:icon_titles.favorite')
						: t('Workspace:icon_titles.not_favorite')
				}
				data-analytics-name="favorite-icon"
			/>
		);
	};

	private captureAnalytics = () => {
		const eventPayload = this.props.isdesktop
			? DesktopsUserEventPayload.favourite(!this.state.isFavorited, this.props.type)
			: AppsUserEventPayload.favourite(!this.state.isFavorited, this.props.type);
		trackAnalyticsEvent(eventPayload);
	};

	private onChange = () => {
		this.captureAnalytics();
		this.setState({
			isPending: true,
		});

		const currentResourceStatus = {
			...this.props,
			subscriptionstatus: this.state.isFavorited
				? subscriptionStatus.subscribed
				: subscriptionStatus.unsubscribed,
		};

		const { isFavorited } = this.state;
		const resolver = isFavorited
			? removeResourceAndUpdateStore(currentResourceStatus)
			: addResourceAndUpdateStore(currentResourceStatus);
		const updatedValueOnSuccess = !isFavorited;
		resolver
			.catch(() => {
				notifyError(t('Workspace:favorite_error_message'));
				return new Error(`Error updating favorite status to ${updatedValueOnSuccess}`);
			})
			.then(error => {
				this.setState(state => ({
					isPending: false,
					isFavorited: error ? state.isFavorited : updatedValueOnSuccess,
				}));
			});
	};
}

export const Favorite = withResourceContext(_Favorite);
