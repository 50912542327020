import { ActionsCapabilityToken } from './capabilities/ActionsCapability';
import { AppStartupCapabilityToken } from './capabilities/AppStartupCapability';
import {
	ExecuteFeedCardActionCapabilityToken,
	FeedCardDetailsCapabilityToken,
} from './capabilities/FeedCardExtensionCapabilities';
import { FullPageRouteCapabilityToken } from './capabilities/FullPageRouteCapability';
import { IntegrationDetailCapabilityToken } from './capabilities/IntegrationDetailCapability';
import { LeftNavCapabilityToken } from './capabilities/LeftNavCapability';
import { LogoutCapabilityToken } from './capabilities/LogoutCapability';
import { RouteCapabilityToken } from './capabilities/RouteCapability';
import { WidgetComponentCapabilityToken } from './capabilities/WidgetComponentCapability';

/** @category Integration Capability */
export const IntegrationCapability = {
	actionsProvider: ActionsCapabilityToken,
	integrationDetailProvider: IntegrationDetailCapabilityToken,
	logout: LogoutCapabilityToken,
	leftNavigation: {
		primaryNavLink: LeftNavCapabilityToken,
	},
	route: RouteCapabilityToken,
	UNSTABLE_widgetComponent: WidgetComponentCapabilityToken,
	feedCardExtensions: {
		viewDetails: FeedCardDetailsCapabilityToken,
		executeFeedCardAction: ExecuteFeedCardActionCapabilityToken,
	},
	appStartup: AppStartupCapabilityToken,
	fullPageRoute: FullPageRouteCapabilityToken,
};
