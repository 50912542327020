import * as React from 'react';
import { t } from '@citrite/translate';
import { numberOfTruncatedResultsToShow } from 'App/Search/Results/constants';
import { StyledViewButton } from 'App/Search/Results/ViewAllOrLessButton.styled';

export interface Props {
	showAllResult: boolean;
	resultsCount: number;
	onclickEvent(): void;
}

export class ViewAllOrLessButton extends React.Component<Props> {
	public render() {
		if (this.props.resultsCount <= numberOfTruncatedResultsToShow) {
			return null;
		}
		return (
			<StyledViewButton onClick={this.props.onclickEvent}>
				{this.getViewAllButtonText()}
			</StyledViewButton>
		);
	}

	private getViewAllButtonText() {
		let viewAllButtonText;
		if (this.props.showAllResult) {
			viewAllButtonText = t('App/Search:view_less');
		} else {
			viewAllButtonText = t('App/Search:view_all', {
				count: this.props.resultsCount,
			});
		}
		return viewAllButtonText;
	}
}
