// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
import { workspaceConfiguration } from '@citrite/workspace-ui-platform';
import { fuzzySearch } from 'Components/ItemSearch/fuzzySearch';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

function checkFuzzySearchFeatureFlag() {
	return workspaceConfiguration.hasFeatureCanary(FeatureFlag.FuzzySearch);
}

export const jaroWrinkerScoreThreshold = 0.7;

export function searchResourcesByNameAndDescription(
	resources: Resource[],
	query: string
) {
	if (!query) {
		return [];
	}

	return resources.filter(r =>
		query
			.toLowerCase()
			.split(' ')
			.every(
				lowerCaseTerm =>
					r.name.toLowerCase().includes(lowerCaseTerm) ||
					(r.description && r.description.toLowerCase().includes(lowerCaseTerm)) ||
					(checkFuzzySearchFeatureFlag() &&
						(fuzzySearch(r.name.toLowerCase(), lowerCaseTerm) >
							jaroWrinkerScoreThreshold ||
							fuzzySearch(r.description && r.description.toLowerCase(), lowerCaseTerm) >
								jaroWrinkerScoreThreshold))
			)
	);
}
