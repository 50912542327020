import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, Link, MainRegion } from '@citrite/web-ui-component';
import { StyledBlock, StyledH1 } from 'Components/Detection/Detection.styled';

export interface Props {
	showValidate(): void;
	getDownloadUrl(): string;
	useHtml5(): void;
	isHtml5Enabled: boolean;
}

export class Download extends React.Component<Props> {
	public render() {
		return (
			<MainRegion>
				<StyledH1>{t('Workspace:downloading_workspace')}</StyledH1>
				<StyledBlock>
					{t('Workspace:install_when_download_complete', {
						continue: <strong>{t('Workspace:continue')}</strong>,
					})}
				</StyledBlock>
				<Button.Primary onClick={this.props.showValidate} style={{ width: '100%' }}>
					{t('Workspace:continue')}
				</Button.Primary>
				<StyledBlock>
					<Link.Primary href={this.props.getDownloadUrl()} target="_blank">
						{t('Workspace:retry_download')}
					</Link.Primary>
					{this.props.isHtml5Enabled && (
						<Button.TextPrimary onClick={this.props.useHtml5}>
							{t('Workspace:use_browser')}
						</Button.TextPrimary>
					)}
				</StyledBlock>
			</MainRegion>
		);
	}

	public componentDidMount() {
		window.location.href = this.props.getDownloadUrl();
	}
}
