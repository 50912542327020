import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { isDesktopOnlyHomepageInNonDaasVision } from 'App/isDesktopOnlyHomepage';
import { isSimpleViewInDaasVision } from 'App/Navigation/utils';
import { HomePage, homePageRouteResolver } from 'Router/homePageRoute';
import { IsAvailable } from 'Router/ReactRoute';

export function checkHomeAvailability(props: IsAvailable<{}>): boolean {
	return (
		homePageRouteResolver(props.userContext, props.workspaceConfiguration) ===
			HomePage.Composable &&
		(shouldDisplayHomeTab(props.workspaceConfiguration) ||
			isSimpleViewInDaasVision(props.resourceContext.resources, props.userContext) ||
			isDesktopOnlyHomepageInNonDaasVision(
				props.workspaceConfiguration,
				props.userContext,
				props.resourceContext
			))
	);
}

function shouldDisplayHomeTab(workspaceConfiguration: WorkspaceConfiguration): boolean {
	const flagValue = workspaceConfiguration.userInterface.enableWorkspaceHome;
	return flagValue ? flagValue === 'true' : true;
}
