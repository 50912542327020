import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { t } from '@citrite/translate';
import { Button, Modal, ModalBody, ModalProps } from '@citrite/web-ui-component';

const { message } = StyleSheet.create({
	message: {
		margin: '0 5px 20px',
		maxWidth: '100%',
		textAlign: 'center',
	},
});

export class LogonExpired extends React.Component<ModalProps> {
	public render() {
		return (
			<Modal
				{...this.props}
				closeOnOverlayClick={false}
				closeOnEscape={false}
				onCloseSuccess={this.onClose}
				onDismiss={this.onClose}
			>
				<ModalBody>
					<div className={css(message)}>{t('Workspace:logon_expired')}</div>
					<Button.Primary style={{ width: '100%' }} onClick={this.onClose}>
						{t('Workspace:logon')}
					</Button.Primary>
				</ModalBody>
			</Modal>
		);
	}

	private onClose = () => {
		location.href = '#/login';
		this.props.onCloseSuccess();
	};
}
