import React from 'react';
import styled from '@emotion/styled';

export interface MessageProps {
	description: string;
	note: string;
}

const Description = styled.div`
	padding-bottom: 12px;
`;

const Note = styled.div`
	padding-top: 12px;
`;

export const MessageComponent: React.FC<MessageProps> = ({ description, note }) => {
	return (
		<>
			<Description>{description}</Description>
			<Note>{note}</Note>
		</>
	);
};
