import * as React from 'react';
import { css } from 'aphrodite';
import { styles } from './WidgetTitle.styles';

export const WidgetTitle: React.FC<{ title: string }> = ({ title, children }) => {
	return (
		<div className={css(styles.nameWrapper)}>
			<div className={css(styles.name)} title={title}>
				{title}
			</div>
			{children}
		</div>
	);
};
