import { StyleSheet } from 'aphrodite';
import { breakpoints, palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	body: {
		margin: '0 40px 40px 40px',
	},
	navBarWithTabs: {
		borderBottom: `1px solid ${palette.grey300}`,
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '10px',
		flexWrap: 'wrap-reverse',
	},
	navBar: {
		borderBottom: `1px solid ${palette.grey300}`,
		paddingBottom: '15px',
		paddingTop: '2px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		minHeight: '22px',
	},
	content: {
		height: '340px',
		overflow: 'auto',
		[breakpoints.onSmallScreen]: {
			height: 'calc(100vh - 180px)',
		},
	},
	contentWithTabs: {
		height: '320px',
		overflow: 'auto',
		[breakpoints.onSmallScreen]: {
			height: 'calc(100vh - 200px)',
		},
	},
	details: {
		marginTop: '20px',
	},
	divider: {
		borderRight: `1px solid ${palette.grey600}`,
		margin: '0 20px',
	},
	machineName: {
		marginBottom: '10px',
	},
});
