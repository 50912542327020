import * as React from 'react';
import { t } from '@citrite/translate';
import { AppsIcon, AppsSolidIcon } from '@citrite/web-ui-component';
import { LeftNavPositions } from '@citrite/workspace-ui-platform';
import { MainContent } from '@citrite/workspace-ui-platform-react';
import { NativeMobileApps } from 'App/Apps/NativeMobileApps';
import { checkAppsAvailability } from 'App/Navigation/checkAppsAvailability';
import { Apps as AppsScreen } from 'App/Screen';
import { performance } from 'Components/performance';
import { isNativeMobileClient } from 'Environment/launchResource/device';
import {
	createChildRoutes,
	createLeftNavRoute,
	LeftNavRoute,
	RoutedComponentProps,
} from 'Router/ReactRoute';
import { useUserContext } from 'Workspace/UserContext';
import { allAppsRoute } from './allAppsRoute';

type Props = RoutedComponentProps;
export const appsPagePath = '/apps';

function Apps(props: Props) {
	const daasVisionEnabled = useUserContext().isDaasVisionEnabled;
	return (
		<>
			{daasVisionEnabled ? (
				<AppsScreen />
			) : (
				<MainContent>
					{isNativeMobileClient() ? (
						<NativeMobileApps {...props} />
					) : (
						<ChildRoutes {...props} />
					)}
				</MainContent>
			)}
		</>
	);
}

/*
	We need to preserve the frontend route for apps -> /apps/all/static/all.
	If we remove these child routes completely, then all new routes point to /apps/static/all which will not be backward compatible.
	So in that case we need to use allAppsRoute for that we need to use childRoutes here. 
*/
const childRoutes: LeftNavRoute[] = [allAppsRoute];

const ChildRoutes = createChildRoutes(childRoutes);

export const appsRoute = createLeftNavRoute({
	component: Apps,
	path: appsPagePath,
	isAvailable: props => checkAppsAvailability(props),
	isLoading: props => props.resources.loading,
	getNavLinkProps: () => ({
		icon: AppsIcon,
		selectedIcon: AppsSolidIcon,
		title: t('Workspace:apps'),
		weight: LeftNavPositions.apps,
		performanceMark: performance.events.MobileAppsLink_Click,
		analyticsID: 'leftnav-apps-tab',
	}),
});
