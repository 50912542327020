import { palette, PaletteName } from '@citrite/web-ui-component';
import { StoreBranding, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { UserBrandingFormat } from '../Workspace/UserContext/Context';

export interface BrandingStyles {
	AccentColor: string;
	HeaderBackgroundColor: string;
	HeaderTextColor: string;
}

export interface WorkspaceLinks {
	HeaderLogoUrl?: string;
	LoginLogoUrl?: string;
}

export interface AccountBranding {
	styles: BrandingStyles;
	links: WorkspaceLinks;
	paletteName: PaletteName;
}

const legacyDefaultBranding: StoreBranding = {
	backgroundColor: '#ffffff',
	linkColor: '#2071c5',
	textColor: '#333333',
};

const defaultCitrixCloudBranding: StoreBranding = {
	backgroundColor: palette.white,
	linkColor: palette.blue800,
	textColor: palette.blue800,
};

// these are default colors from StorefrontUI
// allow-unused-export : need this export for unit test
export const defaultCitrixOnPremBranding: StoreBranding = {
	backgroundColor: palette.white,
	linkColor: palette.blue800,
	textColor: palette.blue800,
};

const defaultCitrixBranding: StoreBranding = IS_ON_PREM
	? defaultCitrixOnPremBranding
	: defaultCitrixCloudBranding;
const brandingPolicyCountKey = 'brandingPolicyCount';

function canUseCitrixBrandTheme(workspaceBranding: StoreBranding) {
	const workspaceBrandingKeys = Object.keys(workspaceBranding);
	if (!workspaceBrandingKeys.length) {
		return true;
	}

	const isNeutralDefault = workspaceBrandingKeys
		.filter(key => key !== brandingPolicyCountKey)
		.every(
			brandingKey => workspaceBranding[brandingKey] === legacyDefaultBranding[brandingKey]
		);

	//Customers with neutral default branding should be switched to rebranded default
	if (isNeutralDefault) {
		return true;
	}
	//Makes sure to set correct palette if customer has defaultCitrixBrand
	const isExactlyCitrixBrand = workspaceBrandingKeys
		.filter(key => key !== brandingPolicyCountKey)
		.every(
			brandingKey => workspaceBranding[brandingKey] === defaultCitrixBranding[brandingKey]
		);
	if (isExactlyCitrixBrand) {
		return true;
	}
	return false;
}

function fixWorkspaceBranding(workspaceBranding: StoreBranding) {
	const adjustedBranding = workspaceBranding;
	if (adjustedBranding.backgroundColor === legacyDefaultBranding.backgroundColor) {
		adjustedBranding.backgroundColor = palette.white;
	}
	if (adjustedBranding.linkColor === legacyDefaultBranding.linkColor) {
		adjustedBranding.linkColor = palette.blue800;
	}
	if (adjustedBranding.textColor === legacyDefaultBranding.textColor) {
		adjustedBranding.textColor = palette.grey900;
	}
	return adjustedBranding;
}

export function createWorkspaceBranding(
	userBranding: Partial<UserBrandingFormat> = {},
	workspaceConfiguration: WorkspaceConfiguration
): AccountBranding {
	const userPolicyBranding = userBranding || ({} as Partial<UserBrandingFormat>);
	const workspaceConfigBranding =
		(workspaceConfiguration &&
			workspaceConfiguration.userInterface &&
			workspaceConfiguration.userInterface.branding) ||
		{};
	const isCitrixBrandTheme = canUseCitrixBrandTheme(workspaceConfigBranding);
	const workspaceBranding = isCitrixBrandTheme
		? defaultCitrixBranding
		: fixWorkspaceBranding(workspaceConfigBranding);

	return {
		paletteName: isCitrixBrandTheme ? PaletteName.citrixbrand : PaletteName.neutral,
		styles: {
			AccentColor:
				userPolicyBranding.linkColor || workspaceBranding.linkColor || palette.blue800,
			HeaderBackgroundColor:
				userPolicyBranding.backgroundColor ||
				workspaceBranding.backgroundColor ||
				palette.white,
			HeaderTextColor:
				userPolicyBranding.textColor || workspaceBranding.textColor || palette.grey900,
		},
		links: {
			HeaderLogoUrl: userPolicyBranding.headerLogo || workspaceBranding.headerLogo,
			LoginLogoUrl: userPolicyBranding.logonLogo || workspaceBranding.logonLogo,
		},
	};
}
