import { StyleSheet } from 'aphrodite';
import { palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	item: {
		display: 'flex',
		alignItems: 'center',
		padding: '12px 16px 8px 4px',
		background: palette.white,
		':hover': {
			backgroundColor: palette.grey200,
		},
	},
});
