import { breakpoints, layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

// `position` and `z-index`: Fixes WSUI-4683 Dragged Widget preview cut-off
export const StyledMainContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
`;

export const StyledMaxWidthContainer = styled.div`
	display: flex;
	width: 100%;
	${breakpoints.onMobile} {
		flex-flow: column;
	}
`;

export const StyledColumn = styled.div<{
	widthPercentage: string;
	isLast: boolean;
}>`
	flex-basis: ${props => `${props.widthPercentage}%`};
	max-width: ${props => `${props.widthPercentage}%`};
	${props => (props.isLast ? '' : `margin-right: ${layout.largeSpace}`)};
	${breakpoints.onMobile} {
		flex-basis: 100%;
		max-width: 100%;
		margin-right: 0;
	}
`;
