import { FeedCard } from 'userNotifications/FeedCard';
import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { ActionRunEvent } from './events';

export function createActionRunEvent(
	actionName: string,
	startedAt: number,
	finishedAt: number,
	result: 'SUCCESS' | 'FAILURE',
	integration: FeedCard['source']['integration'],
	cardUuid: string,
	messageUuid: string,
	createdAt: string
): ActionRunEvent {
	return {
		type: EventType.Activity,
		verb: 'RUN',
		noun: 'ACTION',
		action: {
			name: actionName,
			timestamp: new Date(finishedAt).toISOString(),
			duration: finishedAt - startedAt,
			result,
		},
		integration: {
			iconUrl: integration.iconUrl,
			uuid: integration.uuid,
			title: integration.getRawTitle(),
		},
		card: {
			timestamp: createdAt,
			messageUuid,
			cardUuid,
		},
	};
}
