import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { Button, LoadingIconPrimary } from '@citrite/web-ui-component';
import { restartDesktop } from 'Components/ResourceTile/restartDesktop';
import {
	WithWorkspaceConfiguration,
	withWorkspaceConfiguration,
} from 'Configuration/withWorkspaceConfiguration';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { styles } from './RestartDesktopButton.styles';

export interface Props extends WithResourceContextProps, WithWorkspaceConfiguration {
	resource: Resource;
}

class _RestartDesktopButton extends React.Component<Props> {
	public render() {
		const { resourceContext, resource } = this.props;
		const restarting = resourceContext.desktopRestartInProgressIds.includes(resource.id);
		return restarting ? (
			<div className={css(styles.loadingContainer)}>
				<div className={css(styles.loadingIcon)}>
					<LoadingIconPrimary size={18} />
				</div>
				{t('Workspace:restart_in_progress')}
			</div>
		) : (
			<Button.TextPrimary onClick={this.restartDesktop}>
				{t('Workspace:restart_desktop_modal.restart')}
			</Button.TextPrimary>
		);
	}

	private restartDesktop = () => {
		restartDesktop(this.props.resource, this.props, this.props.workspaceConfiguration);
	};
}

export const RestartDesktopButton = withWorkspaceConfiguration(
	withResourceContext(_RestartDesktopButton)
);
