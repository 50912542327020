import * as React from 'react';
import { css } from 'aphrodite';
import { SkeletonLoaderShapes } from '../Shapes';
import { styles } from './styles';

const { Bar, Rectangle } = SkeletonLoaderShapes;

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function Item(props: Props) {
	return (
		<div {...props} className={`${props.className} ${css(styles.feedItem)}`}>
			<Rectangle width={40} height={40} />
			<div>
				<Bar width={120} />
				<Bar width={232} />
			</div>
		</div>
	);
}
