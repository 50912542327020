import { createCapability } from '../capability';

/**
 * Adds a new standalone page to Workspace.
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.fullPageRoute}
 *
 * @category Integration Capability
 */
export interface FullPageRouteCapability {
	component: React.ComponentType<any>;
}

export interface FullPageRouteCapabilityMetadata {
	/**
	 * Any valid URL path or array of paths.
	 *
	 * @example ['/my/integration/route', '/myshorturl']
	 */
	path: string | string[];
	showMasthead?: boolean;
}

export const FullPageRouteCapabilityToken = createCapability<
	FullPageRouteCapability,
	FullPageRouteCapabilityMetadata
>('wsui_full_page_route_capability');
