import { t } from '@citrite/translate';
import { compact, first, last, lowerCase, sortBy, take, without } from 'lodash';
import { ResourceType } from 'App/Tile/ResourceDetails';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';

export enum ResourceFilter {
	Applications,
	Desktops,
	QuickAccess,
}

export const tooltipDelay = {
	show: 1000,
	hide: 50,
};

export const numberOfRecentResources = 5;

const homeScreenHash = '#/home';

const isSubscribedOrMandatory = (resource: Resource) =>
	resource.subscriptionstatus === subscriptionStatus.subscribed || resource.mandatory;

export const sortedResources = (resources: Resource[]) => {
	return sortBy(resources, [
		resource => !isSubscribedOrMandatory(resource),
		resource => lowerCase(resource.name),
	]);
};

const filterApplicationResources = (
	resources: Resource[],
	category: string = t('Workspace:all'),
	subCategoryPath?: any[]
) => {
	const applicationResources = resources.filter(resource => !resource.isdesktop);
	if (category === t('Workspace:all')) {
		return applicationResources;
	} else {
		return applicationResources.filter(({ path }) => {
			if (!path) {
				return category === t('Workspace:others');
			}
			const splitResourcePath = compact(path.split('\\'));
			const prefixResourcePath = splitResourcePath.slice(0, subCategoryPath.length);
			const suffixResourcePath = splitResourcePath.slice(subCategoryPath.length);
			const resourceCategory = path && last(prefixResourcePath);
			if (
				subCategoryPath !== null &&
				!checkEquality(subCategoryPath, prefixResourcePath)
			) {
				return category === t('Workspace:others') && !resourceCategory;
			}
			return (
				(category === t('Workspace:others') && !resourceCategory) ||
				(resourceCategory === category && suffixResourcePath.length === 0)
			);
		});
	}
};

const filterDesktopResources = (resources: Resource[]) =>
	resources.filter(resource => resource.isdesktop);
/**
 * Here Favourites and Recent resources are considered to be quick access
 * resources and they appear in Quick Access section of home screen.
 * All the resources which are marked as subscribed or set as mandatory or
 * if they are recently accessed would be considered by this filter
 */
const filterQuickAccessResources = (resources: Resource[]) => {
	const favouriteResources = resources.filter(resource =>
		isSubscribedOrMandatory(resource)
	);
	const recentApplicationResources = take(
		sortBy(
			filterApplicationResources(
				resources.filter(resource => 'recentsposition' in resource)
			),
			'recentsposition'
		),
		numberOfRecentResources
	);
	const recentDesktopResources = take(
		sortBy(
			filterDesktopResources(resources.filter(resource => 'recentsposition' in resource)),
			'recentsposition'
		),
		numberOfRecentResources
	);
	return [
		...without(
			favouriteResources,
			...recentApplicationResources,
			...recentDesktopResources
		),
		...recentApplicationResources,
		...recentDesktopResources,
	];
};

export const filteredResources = (
	resources: Resource[],
	filter: ResourceFilter,
	category?: string,
	subCategoryPath?: any[]
) => {
	switch (filter) {
		case ResourceFilter.Applications:
			return filterApplicationResources(resources, category, subCategoryPath);
		case ResourceFilter.Desktops:
			return filterDesktopResources(resources);
		case ResourceFilter.QuickAccess:
			return filterQuickAccessResources(resources);
		default:
			return resources;
	}
};

export const getSubcategories = (
	resources: Resource[],
	category: string = t('Workspace:all'),
	subCategoryPath: any[] = null
) => {
	const applicationResources = resources.filter(resource => !resource.isdesktop);
	if (category === t('Workspace:all')) {
		return [];
	} else {
		return Array.from(
			new Set(
				compact(
					applicationResources.map(({ path }) => {
						if (!path) {
							return '';
						}
						const splitResourcePath = compact(path.split('\\'));
						const prefixResourcePath = splitResourcePath.slice(0, subCategoryPath.length);
						const suffixResourcePath = splitResourcePath.slice(subCategoryPath.length);
						const resourceCategory = path && last(prefixResourcePath);
						if (
							subCategoryPath !== null &&
							!checkEquality(subCategoryPath, prefixResourcePath)
						) {
							return '';
						}
						if (resourceCategory === category && suffixResourcePath.length > 0) {
							return first(suffixResourcePath);
						} else {
							return '';
						}
					})
				)
			)
		);
	}
};

const checkEquality = (listA: any[], listB: any[]) =>
	listA &&
	listB &&
	listA.length === listB.length &&
	listA.every((element: any, index: any) => element === listB[index]);

export const getCategories = (resources: Resource[]): string[] => {
	const categories = Array.from(
		resources.reduce((categoriesSet, resource) => {
			const category = resource.path && first(compact(resource.path.split('\\')));
			if (category) {
				categoriesSet.add(String(category));
			}
			return categoriesSet;
		}, new Set<string>())
	);
	categories.sort();
	if (categories.length) {
		return [t('Workspace:all'), ...categories, t('Workspace:others')];
	} else {
		return [];
	}
};

export const isHomeScreen = () => {
	return window.location.hash.startsWith(homeScreenHash);
};

export const getResourceSubText = (type: string) => {
	let subText = '';
	switch (type) {
		case ResourceType.WebApp:
			subText = t('Workspace:ftu_ui.web_app');
			break;
		case ResourceType.VirtualApp:
			subText = t('Workspace:ftu_ui.desktop_app');
			break;
		case ResourceType.Desktop:
			subText = t('Workspace:ftu_ui.desktop');
			break;
		default:
			break;
	}

	return subText;
};
