import { logError } from 'remoteLogging';
import { EventBus } from 'Components/EventBus';
import { Event } from 'Workspace/EventBus';
import { logEventSubscriberExceptionWithRaven } from 'Workspace/EventBus/logEventSubscriberExceptionWithRaven';
import { Environment } from '..';

export function buildNativeEventBus(env: Environment, global = window) {
	const nativeEventBus = new EventBus(logEventSubscriberExceptionWithRaven);
	if (process.env.NODE_ENV === 'development') {
		(global as any).ctxs_native_event_bus = nativeEventBus;
	}
	(global as any).ctxs_dispatch_event = <T>(eventJSON: string) => {
		if (!eventJSON) {
			logError(new Error('Received a missing eventJSON argument.'));
			return;
		}
		const event: Event<T> = JSON.parse(eventJSON);
		nativeEventBus.dispatch(event);
	};
	env.subscribeToNativeEvent = <T>(listener: (event: Event<T>) => void) => {
		return nativeEventBus.subscribe(listener);
	};
	return env;
}
