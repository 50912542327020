import * as React from 'react';
import { head } from '@citrite/http';
import { withModals, WithModalsProps } from '@citrite/web-ui-component';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { UserContext, useUserContext } from 'Workspace/UserContext';

const SessionModal = makeAsync(() => import('./SessionModal').then(m => m.SessionModal));

export type Props = WithModalsProps & {
	workspaceConfiguration?: WorkspaceConfiguration;
	userContext: UserContext;
};

const eventsToListenFor = ['mouseover', 'click', 'keydown', 'touchstart'];
const keepAliveIntervalSeconds = 120;

const timeoutPaused = false;

class _SessionActivity extends React.Component<Props> {
	private timeoutMinutes = this.getTimeoutMinutes();
	private timeoutSeconds = this.timeoutMinutes * 60;
	private checkFrequencyInSeconds = Math.min(300, this.timeoutSeconds / 6);
	private expiration = 0;
	private isInteractive = false;
	private checkActivityTimer: NodeJS.Timer;
	private isOnline = true;
	private lastKeepAlive: number;

	public render(): null {
		return null;
	}

	public componentDidMount() {
		window.addEventListener('offline', this.offlineEvent);
		eventsToListenFor.forEach(e => document.addEventListener(e, this.userActivityEvent));

		this.lastKeepAlive = Date.now();

		this.checkActivity();
	}

	private userActivityEvent = () => {
		if (this.isSessionActive()) {
			if (this.isInteractive) {
				if (this.remainingSeconds() > this.getWarningThresholdSeconds()) {
					this.checkActivity();
				}
			} else {
				if (this.lastKeepAlive + keepAliveIntervalSeconds * 1000 <= Date.now()) {
					this.keepAlive();
					this.updateExpiration();
				}
			}
		}
	};

	private keepAlive() {
		head(this.props.workspaceConfiguration.storeProxy.keepAliveURL).catch(() => {});
		this.lastKeepAlive = Date.now();
	}

	private updateExpiration() {
		this.expiration = Date.now() + this.timeoutSeconds * 1000;
	}

	private offlineEvent = () => {
		this.isOnline = false;
	};

	private onlineEvent = () => {
		this.logoff();
	};

	private checkActivity = () => {
		if (timeoutPaused) {
			this.updateExpiration();
		}
		if (this.isSessionActive()) {
			const remainingTimeSeconds = this.remainingSeconds();
			const warningThresholdSec = this.getWarningThresholdSeconds();
			let nextCheckSeconds: number;

			if (remainingTimeSeconds <= warningThresholdSec) {
				nextCheckSeconds = Math.min(remainingTimeSeconds, 60);
				this.showWarning();
			} else {
				nextCheckSeconds = Math.min(
					remainingTimeSeconds - warningThresholdSec,
					this.checkFrequencyInSeconds
				);
			}
			this.setNextCheck(nextCheckSeconds);
		} else {
			if (!this.isOnline) {
				window.addEventListener('online', this.onlineEvent);
				return;
			}
			this.logoff();
		}
	};

	private logoff() {
		this.props.userContext.logOff(true);
	}

	private remainingSeconds() {
		return (this.expiration - Date.now()) / 1000;
	}

	private setNextCheck(seconds: number) {
		clearTimeout(this.checkActivityTimer);
		this.checkActivityTimer = setTimeout(this.checkActivity, seconds * 1000);
	}

	private isSessionActive() {
		if (!this.expiration) {
			this.updateExpiration();
		}
		return Date.now() < this.expiration;
	}

	private getTimeoutMinutes() {
		// Try to end the client session before the server session ends
		// to ensure that we always clean up properly.
		return Math.max(0.5, this.props.workspaceConfiguration.session.timeout - 1);
	}

	private getWarningThresholdSeconds() {
		if (this.timeoutSeconds < 180) {
			return this.timeoutSeconds / 2;
		} else if (this.timeoutSeconds < 900) {
			return this.timeoutSeconds / 3;
		} else {
			return 330;
		}
	}

	private showWarning = () => {
		if (this.isInteractive) {
			return;
		}
		this.isInteractive = true;
		this.warning();
	};

	private warning() {
		const timeRemainingInMilliseconds = this.expiration - Date.now();
		this.props.showModal(
			<SessionModal
				timeRemainingInMilliseconds={timeRemainingInMilliseconds}
				onCloseSuccess={this.closeWarning}
				onDismiss={this.closeWarning}
			/>
		);
	}

	private closeWarning = () => {
		this.isInteractive = false;
		this.keepAlive();
		this.updateExpiration();
	};

	public componentWillUnmount() {
		clearTimeout(this.checkActivityTimer);
		eventsToListenFor.forEach(e =>
			document.removeEventListener(e, this.userActivityEvent)
		);
		window.removeEventListener('offline', this.offlineEvent);
		window.removeEventListener('online', this.onlineEvent);
	}
}

const _SessionActivityWithContext = (props: WithModalsProps) => {
	const { workspaceConfiguration } = useConfigurationContext();
	const userContext = useUserContext();
	return (
		<_SessionActivity
			{...props}
			userContext={userContext}
			workspaceConfiguration={workspaceConfiguration}
		/>
	);
};

export const SessionActivity = withModals(_SessionActivityWithContext);
