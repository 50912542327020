import * as React from 'react';
import {
	LoadingOverlayPrimary,
	LoadingOverlaySecondary,
} from '@citrite/web-ui-component';
import {
	hasFeatureCanary,
	LoadableIntegration,
	RouteCapability,
	RouteContext,
} from '@citrite/workspace-ui-platform';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { Error404 } from 'ErrorPage';

interface IntegrationRouteComponentProps extends RouteContext {
	integration: LoadableIntegration<RouteCapability>;
}

function ModuleLoaded(props: IntegrationRouteComponentProps) {
	const useIsAvailable = props.integration.value.useIsAvailable || (() => true);
	const isAvailable = useIsAvailable();

	if (!isAvailable) {
		return <Error404 />;
	}

	return (
		<props.integration.value.component
			setMobileMastheadTitle={props.setMobileMastheadTitle}
		/>
	);
}

export function IntegrationRouteComponent(props: IntegrationRouteComponentProps) {
	const { workspaceConfiguration } = useConfigurationContext();
	if (props.integration.loading) {
		return hasFeatureCanary(workspaceConfiguration, FeatureFlag.SimplifiedNav) ? (
			<LoadingOverlayPrimary />
		) : (
			<LoadingOverlaySecondary />
		);
	}

	if (!props.integration.value) {
		return <Error404 />;
	}

	return <ModuleLoaded {...props} />;
}
