import * as React from 'react';
import { javascriptNamespace } from 'javascript/locales';
import { detectLanguage } from 'javascript/sf/detectLanguage';

interface State {
	loaded: boolean;
}

export function withLocalizationNamespaces(
	...fetchNamespaces: ((language: string) => Promise<void>)[]
) {
	return function namespaceWrapper<T>(Component: React.ComponentType<T>) {
		class NamespaceWrapper extends React.Component<T, State> {
			public state: State = {
				loaded: false,
			};

			public componentDidMount() {
				this.loadNamespaces().then(() => {
					this.setState({
						loaded: true,
					});
				});
			}

			private loadNamespaces() {
				const language = detectLanguage();
				return Promise.all(
					[...fetchNamespaces, javascriptNamespace].map(load => load(language))
				);
			}

			public render() {
				if (!this.state.loaded) {
					return null;
				}
				return <Component {...this.props} />;
			}
		}

		return NamespaceWrapper as React.ComponentClass<T>;
	};
}
