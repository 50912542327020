import React from 'react';
import { palette } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { ShortcutTile } from 'Components/FTU/ShortcutTile';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

const StyledShortcutContent = styled.div`
	flex-direction: row;
	display: flex;
	width: 80%;
	min-height: 50%;
	padding: 15px;
	flex-wrap: wrap;
	background: ${palette.grey100};
	align-items: center;
	border-radius: 16px;
	justify-content: space-evenly;
`;

export const ShortcutContent = () => {
	const resourceContext = useLoadableResourceContext();
	const { resources } = resourceContext.value;
	return (
		<StyledShortcutContent>
			{resources.map(resource => (
				<ShortcutTile key={resource.id} resource={resource} />
			))}
		</StyledShortcutContent>
	);
};
