import { PlatformDependencies } from './platformDependencies';
import { resolveFromGlobal } from './resolveFromGlobal';

/**
 * @category Platform Dependency
 */
export type ShowBlade = (bladeToRender: JSX.Element) => void;

/**
 * @category Platform Dependency
 */
export const showBlade: ShowBlade = bladeToRender => {
	return resolveFromGlobal(PlatformDependencies.ShowBlade)(bladeToRender);
};
