import * as React from 'react';
import { t } from '@citrite/translate';
import { Tooltip } from '@citrite/web-ui-component';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { tooltipDelay } from 'App/Screen/util';
import {
	Container,
	InnerContainer,
	StyledLightText,
	StyledText,
	SubTextDiv,
} from 'App/Tile/ResourceDetails';
import { TileIcon } from 'App/Tile/TileIcon';
interface Props {
	isSmallFormFactor: boolean;
	subCategory?: string;
}

function _CategoryDetails({ isSmallFormFactor, subCategory }: Props) {
	return (
		<Container isSmallFormFactor={isSmallFormFactor}>
			<TileIcon isSubcategory />
			<InnerContainer isSmallFormFactor={isSmallFormFactor}>
				<StyledText isSmallFormFactor={isSmallFormFactor}>
					<Tooltip hoverTrigger={<>{subCategory}</>} delay={tooltipDelay}>
						{subCategory}
					</Tooltip>
				</StyledText>
				<SubTextDiv isSmallFormFactor={isSmallFormFactor}>
					<StyledLightText>{t('Workspace:folder')}</StyledLightText>
				</SubTextDiv>
			</InnerContainer>
		</Container>
	);
}

export const CategoryDetails = (props: any) => (
	<GenericErrorBoundary
		renderProp={() => <_CategoryDetails {...props} />}
		name="CategoryDetails"
	/>
);
