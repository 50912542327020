import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, CopyToClipboardIcon, WarningBanner } from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import { trackAnalyticsEvent } from 'analytics';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { useFormFactor } from 'App/Header/useFormFactor';
import { DaasNotificationBanner } from 'Components/AppLayout/DaasNotificationBanner';
import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { useUserContext } from 'Workspace/UserContext';
import {
	StyledBannerText,
	StyledBannerTitle,
	StyledInlinedBanner,
} from '../AdminNotificationBanner.styled';
import {
	getBannerPositionFromFormFactor,
	shouldDisplayBanner,
} from '../AdminNotificationBannerUtils';

interface State {
	bannerTitle: string;
	bannerDescription: string;
}

export interface DevicePostureBannerProps {
	transactionId: string;
	intendedPosition: BannerPosition;
}
export interface ContentProps {
	isExpanded?: boolean;
}

const DevicePostureBannerCode = 'DevicePostureBannerCode';

function sendDevicePostureBannerEvent(bannerPosition: BannerPosition) {
	const devicePostureBannerEvent = {
		type: EventType.DevicePostureBanner,
		code: DevicePostureBannerCode,
		bannerPosition,
	};
	trackAnalyticsEvent(devicePostureBannerEvent);
}

export function DevicePostureBanner({
	transactionId,
	intendedPosition,
}: DevicePostureBannerProps) {
	const [bannerData, setBannerData] = React.useState<State>({} as State);
	const { bannerTitle, bannerDescription } = bannerData;
	const isLargeFormFactor = useFormFactor().isLargeFormFactor;
	const mastheadOffset = useTheme().mastheadOffset;
	const isDaasVisionEnabled = useUserContext().isDaasVisionEnabled;
	const bannerPosition = BannerPosition.Top;

	const isBannerAvailable =
		transactionId &&
		isDaasVisionEnabled &&
		shouldDisplayBanner(intendedPosition, bannerPosition, isLargeFormFactor);

	React.useEffect(() => {
		const title = t('Workspace:banner.device_posture.title');
		const description = t('Workspace:banner.device_posture.description', {
			transactionId: <strong>{transactionId}</strong>,
		});
		setBannerData({
			bannerTitle: title,
			bannerDescription: description,
		});
		if (isBannerAvailable) {
			sendDevicePostureBannerEvent(bannerPosition);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const daasBannerContent = React.useCallback(
		(props: ContentProps) => (
			<StyledInlinedBanner showSingleLine={false}>
				<StyledBannerTitle
					data-testid="daas-device-posture-banner-title"
					isExpanded={props.isExpanded}
				>
					<b>{bannerTitle}</b>
				</StyledBannerTitle>

				{props.isExpanded && (
					<StyledBannerText padding={0}>
						{bannerDescription}
						<Button.Wrapper
							onClick={e => {
								e.stopPropagation();
								navigator?.clipboard?.writeText(transactionId);
							}}
						>
							<CopyToClipboardIcon />
						</Button.Wrapper>
					</StyledBannerText>
				)}
			</StyledInlinedBanner>
		),
		[bannerTitle, bannerDescription, transactionId]
	);

	if (!(isBannerAvailable && bannerTitle && bannerDescription)) {
		return null;
	}
	const position = getBannerPositionFromFormFactor(bannerPosition, isLargeFormFactor);

	return (
		<DaasNotificationBanner
			Content={daasBannerContent}
			bannerPosition={position}
			mastheadOffset={mastheadOffset}
			Banner={WarningBanner}
			isAdminBanner
		/>
	);
}
