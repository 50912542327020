import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { isNativeWidget } from 'Environment/launchResource/device';
import { UserContext } from 'Workspace/UserContext';

export const homePagePath = '/home';

export enum HomePage {
	Files = 'Files',
	Composable = 'Composable',
	FilesDashboard = 'FilesDashboard',
	NativeWidget = 'NativeWidget',
}

export function homePageRouteResolver(
	userContext: UserContext,
	workspaceConfiguration: WorkspaceConfiguration
): HomePage {
	if (isNativeWidget()) {
		return HomePage.NativeWidget;
	} else if (
		hasOnlyFiles(userContext, workspaceConfiguration) &&
		!hasFeatureCanary(workspaceConfiguration, FeatureFlag.WsuiFilesUIModernization)
	) {
		return HomePage.Files;
	} else if (
		hasOnlyFiles(userContext, workspaceConfiguration) &&
		hasFeatureCanary(workspaceConfiguration, FeatureFlag.WsuiFilesUIModernization)
	) {
		return HomePage.FilesDashboard;
	} else {
		return HomePage.Composable;
	}
}

function hasOnlyFiles(
	userContext: UserContext,
	workspaceConfiguration: WorkspaceConfiguration
) {
	return (
		hasUIProperty(workspaceConfiguration, UIProperty.Files) &&
		!hasUIProperty(workspaceConfiguration, UIProperty.Apps) &&
		!hasUIProperty(workspaceConfiguration, UIProperty.Desktops) &&
		!userContext.isIWSEnabled
	);
}
