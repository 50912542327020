import * as React from 'react';
import { css } from 'aphrodite';
import { SkeletonLoaderShapes } from '../Shapes';
import { styles } from './styles';

const { Bar, BarHeight, BarSpacing, Rectangle } = SkeletonLoaderShapes;

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function ThreeBarItem(props: Props) {
	return (
		<div {...props} className={`${props.className} ${css(styles.threeBarItem)}`}>
			<Rectangle width={24} height={24} />
			<div>
				<Bar width={108} height={BarHeight.Thin} spacing={BarSpacing.Small} />
				<Bar width={292} height={BarHeight.Thin} resizeOnSmallMedia />
				<Bar width={148} height={BarHeight.Thin} />
			</div>
		</div>
	);
}
