import * as React from 'react';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { useUserContext } from 'Workspace/UserContext';
import { StyledSecondaryContent } from './styled';

export const SecondaryContent: React.FC = props => {
	const userContext = useUserContext();
	const height = useClientHeight();
	return (
		<StyledSecondaryContent
			clientHeight={height}
			isDaasVisionEnabled={userContext.isDaasVisionEnabled}
		>
			{props.children}
		</StyledSecondaryContent>
	);
};
