import { t } from '@citrite/translate';
import {
	NativePlatform,
	SettingsLeftNavPositions,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { environment } from 'Environment';
import { createLeftNavRoute } from 'Router/ReactRoute';

const component = makeAsync(() =>
	import('./SecurityAndSignIn').then(m => m.SecurityAndSignIn)
);

export const securityAndSignInRoute = createLeftNavRoute({
	component,
	path: '/settings/securityAndSignIn',
	isAvailable: props => changePasswordEnabled(props.workspaceConfiguration),
	getNavLinkProps: () => ({
		title: t('javascript:security_and_sign_in.title'),
		weight: SettingsLeftNavPositions.securityAndSignIn,
	}),
});

/**
 * Return whether or not change password is enabled for the customer.
 * It will be enabled if using either AD or AD+OTP, not explicitly disabled by the admin and the device is supported.
 * @param workspaceConfiguration The workspace configuration
 */
function changePasswordEnabled(workspaceConfiguration: WorkspaceConfiguration) {
	const idpConfigId = workspaceConfiguration?.authManager?.idpConfigId?.toLowerCase();
	return (
		(idpConfigId === 'Ad'.toLowerCase() || idpConfigId === 'AdOtp'.toLowerCase()) &&
		featureEnabled(workspaceConfiguration) &&
		deviceSupported()
	);
}

function featureEnabled(workspaceConfiguration: WorkspaceConfiguration) {
	return (
		workspaceConfiguration?.userInterface?.changePassword?.enabled?.toLowerCase() !==
		'false'
	);
}

function deviceSupported() {
	// If native, then the device is only supported if it's Android, Mac or has the 'attemptLogoff' function.
	return (
		!environment.isNative ||
		environment.nativePlatform === NativePlatform.Android ||
		environment.nativePlatform === NativePlatform.Mac ||
		environment.nativeLogoffMethod === 'attemptLogoff'
	);
}
