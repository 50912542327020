import * as React from 'react';
import {
	WithBladeProps,
	withBlades,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { PlatformDependencies } from '@citrite/workspace-ui-platform';
import { container } from 'Workspace/DependencyManagement';

export type BladeAndModalHostProps = WithBladeProps & WithModalsProps;

class _BladeAndModalHost extends React.Component<BladeAndModalHostProps> {
	public constructor(props: BladeAndModalHostProps) {
		super(props);
		container.registerSingleton(PlatformDependencies.ShowModal, this.props.showModal);
		container.registerSingleton(PlatformDependencies.ShowBlade, this.props.showBlade);
	}

	public render(): null {
		return null;
	}

	public componentWillUnmount() {
		container.unregister(PlatformDependencies.ShowModal);
		container.unregister(PlatformDependencies.ShowBlade);
	}
}

export const BladeAndModalHost = withBlades(withModals(_BladeAndModalHost));
