/*
   This file handles building of the MSAL Config object and the MSAL Public client
   use in the hybrid AAD auth flow.    
*/
import * as MSAL from '@citrite/msal-browser';
import { versionInfo } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { aadSSOEventHandler } from 'Environment/msal/aadEventNotificationHandler';
import { AADSsoEventPayload } from 'Environment/msal/aadSSOEventPayload';

export type AADAuthCodeType = { authcode: string };
export type AuthInfoWithCnf = MSAL.AuthInfoType & { cnf: string; resourceId: string };

/*
   Store only those properties of authInfo which are constant across multiple auth requests.
   Pick only properties we explicitly use for now instead of the entire auth object
   to avoid accidentally using any other properties without review. 
*/
let authInfoFromWSUI: Pick<
	AuthInfoWithCnf,
	'redirectUri' | 'cnf' | 'deviceId' | 'resourceId'
>;

export function setAuthorizationCode(aadAuthCode: AADAuthCodeType): void {
	MSAL.setAuthorizationCode(aadAuthCode);
}
//allow-unused-export for unit testing
export const onAADResponse = (authResult: MSAL.AADAuthResult) =>
	aadSSOEventHandler.sendAccessTokenViaBrowserExtensionBridge(
		authInfoFromWSUI.resourceId,
		authInfoFromWSUI.deviceId,
		authResult
	);

//allow-unused-export for unit testing
export const onAADError = (error: MSAL.AADErrorResult) => {
	trackAnalyticsEvent(AADSsoEventPayload.tokenError());
	aadSSOEventHandler.sendTokenErrorViaBrowserExtensionBridge(
		authInfoFromWSUI.resourceId,
		authInfoFromWSUI.deviceId,
		error
	);
};

export function initMSALSdkForHybridMode(): void {
	const wsuiInfo = versionInfo.get();
	const initConfig: MSAL.InitConfig = {
		supportsInteractiveAuthOnly: false,
		interactionType: MSAL.ctxAADInteractionType.MS_AAD_HTTPS_INTERCEPTION,
		onPerformAuthorization: undefined,
		onAADResponse,
		onAADError,
		environment:
			wsuiInfo.environment === 'release' && wsuiInfo.release !== 'master'
				? MSAL.ENVIRONMENT.PROD
				: MSAL.ENVIRONMENT.NON_PROD,
	};
	MSAL.initMsalSdk(initConfig);
}

export function startHybridAADTokenFlow(authInfo: AuthInfoWithCnf): void {
	trackAnalyticsEvent(AADSsoEventPayload.tokenRequested());
	setAuthorizationCode({ authcode: '' });
	authInfoFromWSUI = authInfo;
	aadSSOEventHandler.clearEventForResource(authInfo.resourceId);
	MSAL.startAADTokenFlow(authInfo);
}
