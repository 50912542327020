import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { installLazyGetter } from 'javascript/interactiveTools';
import { isAdvancedWorkspaceResiliencyEnabled } from 'Workspace/advancedWorkspaceResiliency';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export function addResourceProviderInteractiveTools(
	getWorkspaceConfiguration: () => WorkspaceConfiguration,
	getResources: () => Resource[]
) {
	installLazyGetter('cvadResources', () => getResources());

	installLazyGetter('resiliencySupport', () => {
		const configuration = getWorkspaceConfiguration();
		if (!isAdvancedWorkspaceResiliencyEnabled(configuration)) {
			return '⛔ Feature disabled or unsupported environment';
		}

		const resources = getResources();

		if (!resources) {
			return '⛔ No resource enumeration result; enumeration may be in progress';
		}

		if (!resources.length) {
			return '⛔ Empty resource enumeration result';
		}

		if (!resources.some(r => r.clmetadata?.leasesupported)) {
			return '⛔ Resources are present, but none are leasable';
		}

		return '✅ Workspace resiliency may be functional, dependent on further broker and endpoint configuration';
	});
}
