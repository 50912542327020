import React, { useEffect, useState } from 'react';
import { trackEvent } from 'analytics';
import moment from 'moment';
import {
	AdminNotificationClient,
	logAdminNotificationError,
	Type,
	useAdminNotificationClient,
} from 'AdminNotificationService';
import { AdminNotificationContext } from 'AdminNotificationService/AdminNotificationContext';
import { sendAdminNotificationBannerEvent } from 'AdminNotificationService/adminNotificationUtils';
import { AdminNotification } from 'AdminNotificationService/createAdminNotificationClient';
import { useCasTelemetryAdapter } from 'Workspace/CAS/useCasTelemetryAdapter';
import { useUserContext } from 'Workspace/UserContext';

interface AdminNotificationProviderProps {
	children: React.ReactNode;
}

export const AdminNotificationProvider: React.FC<AdminNotificationProviderProps> = ({
	children,
}) => {
	const adminNotificationClient: AdminNotificationClient = useAdminNotificationClient();
	const casTelemetryAdapter = useCasTelemetryAdapter();
	const [adminNotification, setAdminNotification] = useState<AdminNotification | null>(
		null
	);
	const userContext = useUserContext();
	const fetchNotifications = async () => {
		try {
			const notifications = await adminNotificationClient.fetchNotificationsCacheFirst();
			const bannerNotification = notifications?.find(
				({ type, expireTime, startTime }) =>
					type.toLowerCase() === Type.Banner &&
					new Date(expireTime) > moment().toDate() &&
					new Date(startTime) < moment().toDate()
			);
			setAdminNotification(bannerNotification || null);

			if (bannerNotification) {
				trackEvent(`AdminNotificationBanner_${bannerNotification.position}`);
				sendAdminNotificationBannerEvent(
					casTelemetryAdapter,
					bannerNotification.position
				);
			}
		} catch (error) {
			logAdminNotificationError(error);
		}
	};

	useEffect(() => {
		// With this we will be restricting the admin banner to be shown only when the user is logged in
		const fetchData = async () => {
			if ((await userContext.hasLoggedIn()) && adminNotificationClient) {
				await fetchNotifications();
			}
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AdminNotificationContext.Provider value={{ adminNotification }}>
			{children}
		</AdminNotificationContext.Provider>
	);
};
