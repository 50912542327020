import React from 'react';
import { breakpoints, palette, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledHeaderContainer = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 60px;
	${breakpoints.onMediumScreen} {
		height: 12vh;
		min-height: 40px;
		width: 80%;
		gap: 5px;
	}
`;

const StyledHeaderPrimary = styled.div`
	font-family: 'PublicSans', Arial, sans-serif;
	font-style: normal;
	font-weight: ${typography.weights.semiBold};
	font-size: 24px;
	line-height: 29px;
	color: ${palette.black};
	text-align: center;
	${breakpoints.onMediumScreen} {
		font-size: 18px;
		line-height: 20px;
	}
`;
const StyledHeaderSecondary = styled.div`
	font-family: 'PublicSans', Arial, sans-serif;
	font-style: normal;
	font-weight: ${typography.weights.regular};
	font-size: 14px;
	line-height: 14px;
	color: ${palette.black};
	${breakpoints.onMediumScreen} {
		width: 80%;
		font-size: 13px;
		text-align: center;
	}
`;

interface Props {
	primaryText: string;
	secondaryText: string;
}

export const Header = (props: Props) => {
	return (
		<StyledHeaderContainer>
			<StyledHeaderPrimary>{props.primaryText}</StyledHeaderPrimary>
			<StyledHeaderSecondary>{props.secondaryText}</StyledHeaderSecondary>
		</StyledHeaderContainer>
	);
};
