import * as React from 'react';
import { t } from '@citrite/translate';
import { Button } from '@citrite/web-ui-component';
import { logError } from 'remoteLogging';
import { FullScreenError } from 'Components/FullScreenError';
import {
	isStoreFrontFallbackEnabled,
	StoreFrontFallbackErrorPage,
} from 'ErrorPage/StoreFrontFallback';
import { ErrorLevel } from 'Loggers/LoggingProvider';

export function DefaultErrorPage() {
	React.useEffect(() => {
		logError(new Error('Application completely crashed'), {
			additionalContext: {
				level: ErrorLevel.FATAL,
				isStoreFrontFallbackEnabled: isStoreFrontFallbackEnabled(),
			},
		});
	}, []);

	if (isStoreFrontFallbackEnabled()) {
		return <StoreFrontFallbackErrorPage />;
	}
	return (
		<FullScreenError
			title={t('Workspace:unexpected_error.generic_title')}
			subtitle={t('Workspace:unexpected_error.helper_text')}
		>
			<Button.Secondary onClick={backToSiteRoot}>
				{t('javascript:error_boundary.back_to_home')}
			</Button.Secondary>
		</FullScreenError>
	);
}

function backToSiteRoot() {
	location.assign('/');
}
