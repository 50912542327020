export function createWidgetReactKeyGenerator(): (widgetId: string) => string {
	const perWidgetIdCounter: { [widgetId: string]: number } = {};

	return (widgetId: string) => {
		perWidgetIdCounter[widgetId] = !!perWidgetIdCounter[widgetId]
			? (perWidgetIdCounter[widgetId] += 1)
			: 1;
		return `${widgetId}-${perWidgetIdCounter[widgetId]}`;
	};
}
