import * as React from 'react';
import { t } from '@citrite/translate';
import {
	EmptyAppsIcon,
	LoadingIconPrimary,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { ContextMenuItemBuilder } from '@citrite/workspace-ui-platform-react';
import { trackAnalyticsEvent } from 'analytics';
import { NativeMobileResourceItemProps } from 'Components/NativeMobileResourceRow';
import { performance } from 'Components/performance';
import { ResourceIcon } from 'Components/ResourceIcon';
import { useAppContextMenuFactory } from 'Components/ResourceTile/useAppContextMenuFactory';
import { isDesktopApp, isMobileApp, isWebApp } from 'Environment/deviceSupportsResource';
import { installResource } from 'Environment/installResource';
import { launchResource } from 'Environment/launchResource';
import { getRecentResources } from 'Environment/recents';
import { RoutedComponentProps } from 'Router/ReactRoute';
import {
	ResourceContextProvider,
	useLoadableResourceContext,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import {
	NativeMobileTabCategory,
	NativeMobileTabProps,
	NativeMobileTabsRenderer,
} from './NativeMobileTabsRenderer';

enum NativeMobileAppCategory {
	mobile,
	web,
	desktop,
}

export type Props = RoutedComponentProps & {
	loadableResourceContext: ResourceContextProvider;
	contextMenuBuilder: ContextMenuItemBuilder<Resource>;
};

class _NativeMobileApps extends React.Component<Props> {
	public componentDidMount() {
		this.props.setMobileMastheadTitle(t('Workspace:apps'));
	}

	private onPrimaryAction = (app: Resource) => {
		if (isMobileApp(app) && !this.props.resourceContext.isInstalled(app.id)) {
			trackAnalyticsEvent(AppsUserEventPayload.install(EventSite.Tile, app.type));
			return installResource({
				resource: app,
				updateSession: this.props.resourceContext.updateSession,
			});
		}
		trackAnalyticsEvent(AppsUserEventPayload.launch(EventSite.Tile, app.type));
		return launchResource({
			resource: app,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
		});
	};

	private createCategories() {
		const { resources } = this.props.loadableResourceContext.value;
		const apps = resources.filter(r => !r.isdesktop);
		const recentApps = getRecentResources(apps) || [];
		const tabs: NativeMobileTabProps[] = [
			{
				id: NativeMobileTabCategory.recents,
				label: t('Workspace:recents'),
				sections: this.getSectionsForApps(recentApps, 5, false),
				emptyState: getEmptyStateMessage(NativeMobileTabCategory.recents),
			},
			{
				id: NativeMobileTabCategory.all,
				label: t('Workspace:all_apps'),
				sections: this.getSectionsForApps(apps, apps.length, true),
				emptyState: getEmptyStateMessage(NativeMobileTabCategory.all),
			},
		];

		return tabs;
	}

	private getSectionsForApps(
		resources: Resource[],
		maxItems: number,
		isCollapsible: boolean
	) {
		return [
			{
				id: NativeMobileAppCategory.mobile,
				title: t('Workspace:native_mobile_list_view.mobile'),
				items: resources
					.filter(isMobileApp)
					.slice(0, maxItems)
					.map(this.transformResourcesToItems),
				isCollapsible,
			},
			{
				id: NativeMobileAppCategory.web,
				title: t('Workspace:native_mobile_list_view.web'),
				items: resources
					.filter(isWebApp)
					.slice(0, maxItems)
					.map(this.transformResourcesToItems),
				isCollapsible,
			},
			{
				id: NativeMobileAppCategory.desktop,
				title: t('Workspace:native_mobile_list_view.desktop'),
				items: resources
					.filter(isDesktopApp)
					.slice(0, maxItems)
					.map(this.transformResourcesToItems),
				isCollapsible,
				isCollapsedInitially: true,
			},
		];
	}

	private transformResourcesToItems = (app: Resource): NativeMobileResourceItemProps => {
		return {
			title: app.name,
			id: app.id,
			icon: <ResourceIcon resource={app} size={36} />,
			onPrimaryAction: () => this.onPrimaryAction(app),
			data: app,
			isLoading: this.isLoading,
		};
	};

	private isLoading = (app: Resource): boolean => {
		const launchInProgress = this.props.resourceContext.isInProgress(app.id);
		return launchInProgress;
	};

	public render() {
		if (this.props.loadableResourceContext.loading) {
			return <LoadingIconPrimary />;
		}
		performance.mark(performance.events.MobileAppsPage_Rendered);
		performance.measure(
			performance.events.MobileAppsPage_Rendered,
			performance.events.MobileAppsLink_Click,
			performance.events.MobileAppsPage_Rendered
		);
		return (
			<NativeMobileTabsRenderer
				tabs={this.createCategories()}
				contextMenuBuilder={this.props.contextMenuBuilder}
			/>
		);
	}
}

export const NativeMobileApps = withModals(
	({ showModal, ...routedComponentProps }: WithModalsProps & RoutedComponentProps) => {
		const loadableResourceContext = useLoadableResourceContext();
		const builder = useAppContextMenuFactory({ showModal });
		return (
			<_NativeMobileApps
				{...routedComponentProps}
				loadableResourceContext={loadableResourceContext}
				contextMenuBuilder={builder}
			/>
		);
	}
);

function getEmptyStateMessage(
	tab: NativeMobileTabCategory
): NativeMobileTabProps['emptyState'] {
	return tab === NativeMobileTabCategory.recents
		? { title: t('Workspace:empty_recent_apps'), icon: EmptyAppsIcon }
		: { title: t('Workspace:no_apps'), icon: EmptyAppsIcon };
}
