import * as React from 'react';
import { createCapability } from '@citrite/workspace-ui-platform';

export interface ReactContextCapability<ContextType> {
	/** A React Hook that supplies the context value. */
	useContextValueProvider: () => ContextType;
}

export interface ReactContextCapabilityMetadata {
	context: React.Context<any>;
	/**
	 * The platform will return this value while loading your integration bundle.
	 */
	defaultContextValue?: any;
}

export const ReactContextCapabilityToken = createCapability<
	ReactContextCapability<any>,
	ReactContextCapabilityMetadata
>('wsui_react_context_capability');
