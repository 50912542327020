import * as React from 'react';
import { IntegrationContext, IntegrationManifest } from '@citrite/workspace-ui-platform';

export interface InternalIntegrationContext extends IntegrationContext {
	allIntegrations: IntegrationManifest[];
}

const error = new Error('Consuming ServicesContext outside Services/Provider tree.');
const intialContext: InternalIntegrationContext = {
	resolveById: _ => ({
		loading: false,
		error,
		enabled: false,
		reload: () => null,
		moduleKey: '',
		integrationId: '',
		resolver: Promise.reject(error),
	}),
	resolveByCapability: () => [],
	hasEnabledIntegrationByCapability: () => false,
	allIntegrations: [],
};
export const Context = React.createContext<InternalIntegrationContext>(intialContext);
