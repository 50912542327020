import * as MSAL from '@citrite/msal-browser';
import { versionInfo } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { environment } from 'Environment/Environment';
import { isNativeClient } from 'Environment/launchResource/device';
import { AADSsoEventPayload } from 'Environment/msal/aadSSOEventPayload';

export const setAuthorizationCode = (aadAuthCode: MSAL.AADAuthCodeType) =>
	MSAL.setAuthorizationCode(aadAuthCode);

const onPerformAuthorization = async (
	authorizationProps: MSAL.MSALAuthorizationProps
) => {
	return await environment.performAuthorization(JSON.stringify(authorizationProps));
};

const onAADResponse = (authResult: MSAL.AADAuthResult) =>
	environment.setAADResponse(JSON.stringify(authResult));

const onAADError = (error: MSAL.AADErrorResult) => {
	trackAnalyticsEvent(AADSsoEventPayload.tokenError());
	environment.setAADError(JSON.stringify(error));
};

const getInteractionType = (): MSAL.ctxAADInteractionType => {
	if (!isNativeClient()) {
		return MSAL.ctxAADInteractionType.REDIRECT_TO_CTX_HTTPS;
	} else if (
		environment.nativeCapabilities?.functions?.includes('performAuthorizationV2')
	) {
		return MSAL.ctxAADInteractionType.REDIRECT_TO_CTX_CUSTOM_SCHEME;
	} else {
		return MSAL.ctxAADInteractionType.MS_AAD_HTTPS_INTERCEPTION;
	}
};

export const initMsalSdk = () => {
	const wsuiInfo = versionInfo.get();
	const initConfig: MSAL.InitConfig = {
		supportsInteractiveAuthOnly: environment.supportsInteractiveAuthOnly,
		interactionType: getInteractionType(),
		onPerformAuthorization,
		onAADResponse,
		onAADError,
		environment:
			wsuiInfo.environment === 'release' && wsuiInfo.release !== 'master'
				? MSAL.ENVIRONMENT.PROD
				: MSAL.ENVIRONMENT.NON_PROD,
	};
	MSAL.initMsalSdk(initConfig);
};

export const startAADTokenFlow = (authInfo: MSAL.AuthInfoWithCnf) => {
	trackAnalyticsEvent(AADSsoEventPayload.tokenRequested());
	MSAL.startAADTokenFlow(authInfo);
};
