import { performance } from 'Components/performance';

export interface PerformanceMeasure {
	start(): void;
	end(): void;
}

export function createPerformanceMeasure(measureName: string): PerformanceMeasure {
	const startMarkName = `${measureName}_start`;

	const start = () => performance.mark(startMarkName);

	const end = () => performance.measure(measureName, startMarkName);

	return { start, end };
}
