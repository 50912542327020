import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { Button, LoadingIconPrimary, notifyError } from '@citrite/web-ui-component';
import { trackAnalyticsEvent } from 'analytics';
import {
	WithWorkspaceConfiguration,
	withWorkspaceConfiguration,
} from 'Configuration/withWorkspaceConfiguration';
import { isMobileApp } from 'Environment/deviceSupportsResource';
import { installResource } from 'Environment/installResource';
import { launchResource } from 'Environment/launchResource';
import {
	withResourceContext,
	WithResourceContextProps,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { styles } from './OpenAppButton.styles';

export interface Props extends WithResourceContextProps, WithWorkspaceConfiguration {
	resource: Resource;
}

interface State {
	installInProgress?: boolean;
}

class _OpenAppButton extends React.Component<Props, State> {
	public state: State = {};

	public render() {
		const isInstalled = this.props.resourceContext.isInstalled(this.props.resource.id);
		const isInProgress = this.props.resourceContext.isInProgress(this.props.resource.id);

		if (isMobileApp(this.props.resource) && !isInstalled) {
			return (
				<>
					{this.state.installInProgress ? (
						t('Workspace:installing_app')
					) : (
						<Button.TextPrimary onClick={this.installApp}>
							{t('Workspace:install_app')}
						</Button.TextPrimary>
					)}
				</>
			);
		}

		return isInProgress ? (
			<div className={css(styles.loadingContainer)}>
				<div className={css(styles.loadingIcon)}>
					<LoadingIconPrimary size={18} />
				</div>
				{t('Workspace:open_in_progress')}
			</div>
		) : (
			<Button.TextPrimary onClick={this.openApp}>
				{t('Workspace:open_app')}
			</Button.TextPrimary>
		);
	}

	private installApp = () => {
		trackAnalyticsEvent(
			AppsUserEventPayload.install(EventSite.MoreInfo, this.props.resource.type)
		);
		this.setState({ installInProgress: true });
		installResource({
			resource: this.props.resource,
			updateSession: this.props.resourceContext.updateSession,
		})
			.catch(() => notifyError(t('Workspace:application_install_failed')))
			.then(() => {
				this.setState({ installInProgress: false });
			});
	};

	private openApp = () => {
		trackAnalyticsEvent(
			AppsUserEventPayload.launch(EventSite.MoreInfo, this.props.resource.type)
		);
		launchResource({
			resource: this.props.resource,
			resourceContext: this.props.resourceContext,
			workspaceConfiguration: this.props.workspaceConfiguration,
		});
	};
}

export const OpenAppButton = withWorkspaceConfiguration(
	withResourceContext(_OpenAppButton)
);
