import * as React from 'react';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';
import { PolicyBrandingService } from './PolicyBrandingService';

export const usePolicyBrandingService = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { cacheFactory } = useCacheContext();

	return React.useMemo(() => {
		const endpointService = getEndpointsService(
			workspaceConfiguration,
			'customerInterfacePersonalization'
		);
		if (!endpointService) {
			return null;
		}
		const cacheBucket = cacheFactory.createEncryptedCacheBucket(
			BucketManifest.policyBranding
		);

		const endpointSupplier = createEndpointSupplier(endpointService.discoveryUrl);
		const ssoProxyClient = environment.createSSOProxyClient(
			workspaceConfiguration.authManager.proxyUrl
		);
		const customerId = workspaceConfiguration.storeIdentifiers.customerId;
		const storeId = workspaceConfiguration.storeIdentifiers.storeGuid;
		return new PolicyBrandingService(
			endpointSupplier,
			ssoProxyClient,
			customerId,
			storeId,
			cacheBucket
		);
	}, [workspaceConfiguration, cacheFactory]);
};
