import { StyleSheet } from 'aphrodite';
import { layout, palette } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	widget: {
		marginBottom: layout.largeSpace,
	},
	titleBar: {
		marginTop: layout.mediumLargeSpace,
		marginBottom: layout.mediumLargeSpace,
		borderRadius: '4px',
		height: '8px',
		backgroundColor: palette.grey300,
		width: '64px',
	},
	row: {
		display: 'flex',
		padding: '10px',
		alignItems: 'center',
	},
	icon: {
		width: 32,
		height: 32,
		backgroundColor: palette.grey300,
		borderRadius: '4px',
		marginLeft: layout.mediumLargeSpace,
		marginRight: layout.mediumSpace,
	},
	name: {
		borderRadius: '4px',
		height: '8px',
		backgroundColor: palette.grey300,
	},
});
