import { StyleSheet } from 'aphrodite';
import { layout, palette, styleUtils, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	sectionHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		background: palette.white,
		padding: layout.smallSpace,
		marginTop: layout.smallSpace,
	},
	sectionTitle: {
		...styleUtils.ellipsis,
		fontWeight: typography.weights.medium,
		fontSize: typography.sizes.lg,
		color: palette.grey900,
		paddingTop: layout.tinySpace,
	},
});
