import { IntegrationContext } from '../integration';
import { AuthenticationHandler } from './AuthenticationHandler';
import { WindowManager } from './BrowserWindowManager';
import { DependencySet, DependencyToken } from './container';
import { ContentViewer } from './ContentViewer';
import { DeviceInfo } from './DeviceInfo';
import { Performance } from './Performance';
import { RegisterIntegration } from './RegisterIntegration';
import { ScopedContainerAccessor } from './ScopedContainer';
import { ShowBlade } from './ShowBlade';
import { ShowModal } from './ShowModal';
import { TelemetryClient } from './TelemetryClient';
import { TranslationFunction } from './TranslationFunction';
import { WorkspaceConfigurationAccessor } from './WorkspaceConfiguration';
import { workspaceLocales } from './workspaceLocales';
import { WorkspaceUserAccessor } from './WorkspaceUser';

interface PlatformDependenciesTokens {
	/**
	 * Provides a {@link workspaceLocales} for the current session.
	 */
	Locale: DependencyToken<workspaceLocales>;
	/**
	 * Provides a {@link WindowManager} for cross-client support of opening and
	 * managing new webview/browser windows.
	 */
	WindowManager: DependencyToken<WindowManager>;
	/**
	 * Provides a translation function for translating locale keys using
	 * @citrite/translate
	 */
	TranslationFunction: DependencyToken<TranslationFunction>;
	/**
	 * Provides a {@link DeviceInfo} for the current device.
	 */
	DeviceInfo: DependencyToken<DeviceInfo>;
	/**
	 * Provides a {@link WorkspaceConfigurationAccessor} that allows you to access
	 * the current customer's {@link WorkspaceConfiguration} via an executable
	 * method.
	 */
	WorkspaceConfiguration: DependencyToken<WorkspaceConfigurationAccessor>;
	/**
	 * Provides a {@link WorkspaceUserAccessor} that allows you to access
	 * the current user's {@link WorkspaceUser} via an executable
	 * method.
	 */
	WorkspaceUser: DependencyToken<WorkspaceUserAccessor>;
	/**
	 * Provides methods for using the browser performance API.
	 */
	Performance: DependencyToken<Performance>;
	/**
	 * Provides access to a function used to register an integration with the platform.
	 */
	RegisterIntegration: DependencyToken<RegisterIntegration>;
	/**
	 * Provides access to your integration's scoped container.
	 */
	ScopedContainer: DependencyToken<ScopedContainerAccessor>;
	/**
	 * Provides APIs to load integration modules
	 */
	Integrations: DependencyToken<IntegrationContext>;
	/**
	 * Provides a generic function for rendering modals with no access to react context.
	 * You should use the `withModals` HOC from citrix-ui to generate a local `showModals` function
	 * if your integration requires react context.
	 */
	ShowModal: DependencyToken<ShowModal>;
	/**
	 * Provides a generic function for rendering blades with no access to react context.
	 * You should use the `withBlades` HOC from citrix-ui to generate a local `showBlades` function
	 * if your integration requires react context.
	 */
	ShowBlade: DependencyToken<ShowBlade>;
	/**
	 * Provides a function for rendering a ContentViewerModal component.
	 * @internal
	 */
	_UNSTABLE_ContentViewer: DependencyToken<ContentViewer>;
	/**
	 * Provides a function for handling an auth challenge.
	 * @internal
	 */
	_UNSTABLE_AuthenticationHandler: DependencyToken<AuthenticationHandler>;
	TelemetryClient: DependencyToken<TelemetryClient>;
}

export type PlatformDependencySet = DependencySet<PlatformDependenciesTokens>;

export const PlatformDependencies: PlatformDependenciesTokens = {
	Locale: 'wsui_Locale',
	WindowManager: 'wsui_WindowManager',
	TranslationFunction: 'wsui_TranslationFunction',
	DeviceInfo: 'wsui_DeviceInfo',
	WorkspaceConfiguration: 'wsui_WorkspaceConfiguration',
	WorkspaceUser: 'wsui_WorkspaceUser',
	Performance: 'wsui_Performance',
	RegisterIntegration: 'wsui_RegisterIntegration',
	ScopedContainer: 'wsui_ScopedContainer',
	Integrations: 'wsui_Integrations',
	ShowModal: 'wsui_ShowModal',
	ShowBlade: 'wsui_ShowBlade',
	TelemetryClient: 'wsui_TelemetryClient',
	/** @internal */
	_UNSTABLE_ContentViewer: 'wsui_ContentViewer',
	/** @internal */
	_UNSTABLE_AuthenticationHandler: 'wsui_AuthenticationHandler',
};
