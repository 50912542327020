import React from 'react';
import { t } from '@citrite/translate';
import { isSimpleView } from 'App/Navigation/utils';
import { Container } from 'App/Screen/Container';
import { EmptyHomePage } from 'App/Screen/EmptyHomePage';
import { ResourceList } from 'App/Screen/ResourceList';
import { filteredResources, ResourceFilter } from 'App/Screen/util';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

export const Home = () => {
	const resourceContext = useLoadableResourceContext();
	const resources = resourceContext?.value?.resources || [];

	const filter = !isSimpleView(resources) ? ResourceFilter.QuickAccess : null;
	const title = !isSimpleView(resources) ? t('Workspace:favouritesAndSuggestions') : null;

	const resourcesLength: number = filteredResources(resources, filter)?.length || 0;
	return (
		<Container id="home-screen">
			{resourcesLength ? (
				<ResourceList filter={filter} title={title} />
			) : (
				<EmptyHomePage />
			)}
		</Container>
	);
};
