import { LocationDescriptorObject } from 'history';
import Cookies from 'js-cookie';

const pendingLoginsKey = 'WSUI-PendingLogins';

export interface PendingLogin {
	challenge: string;
	locationAfterLogin: LocationDescriptorObject;
}

export function setPendingLogin(newAttempt: PendingLogin) {
	/**
	 * We cannot use session storage here.
	 * When using IE11 with trusted sites, session storage gets cleared
	 * during the login flow when we redirect to the athena login pages
	 */
	const pendingLogins = [newAttempt, ...getPendingLogins()];
	const expires = getExpiration();
	let isSecured = true;
	if (location.protocol === 'http:' && IS_ON_PREM) {
		isSecured = false;
	}
	Cookies.set(pendingLoginsKey, pendingLogins, { secure: isSecured, expires, path: '/' });
}

export function getPendingLogins() {
	const pendingLogins = Cookies.getJSON(pendingLoginsKey) as PendingLogin[];
	return pendingLogins || [];
}

export function cleanupPendingLogins() {
	const pendingLogins = getPendingLogins();
	const lastPendingLogin = pendingLogins[0];
	removePendingLogins();
	if (lastPendingLogin) {
		setPendingLogin(lastPendingLogin);
	}
}

export function removePendingLogins() {
	Cookies.remove(pendingLoginsKey);
}

function getExpiration() {
	const date = new Date();
	const minutes = 20;
	date.setTime(date.getTime() + minutes * 60 * 1000);
	return date;
}
