export enum workspaceLocales {
	Default = 'en',
	English = 'en',
	German = 'de',
	Spanish = 'es',
	Finnish = 'fi',
	French = 'fr',
	Japanese = 'ja',
	MandarinChinese = 'zh-CN',
	PortugueseBrazilian = 'pt-BR',
	Italian = 'it',
	TraditionalChinese = 'zh-TW',
}
