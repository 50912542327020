import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { EmptySearchIcon, EmptyState } from '@citrite/web-ui-component';
import { ResourceResults } from 'App/Search/Results/ResourceResults';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { SearchFilterOption } from '../SearchFilterOption';
import { styles } from './Results.styles';

export interface Props {
	apps: Resource[];
	desktops: Resource[];
	filterOption: SearchFilterOption | string;
	lastSearchTerm: string;
	isSearching: boolean;
	supportsApps: boolean;
	supportsDesktops: boolean;
}

export interface State {}

export class Results extends React.Component<Props, State> {
	private supportedSearches = [
		this.props.supportsApps,
		this.props.supportsDesktops,
	].filter(a => a).length;

	public state: State = {};

	public render() {
		if (!this.props.lastSearchTerm) {
			return (
				<div className={css(styles.emptyState)}>
					<EmptyState icon={EmptySearchIcon}>
						<div className={css(styles.emptyStateTitle)}>
							{t('App/Search:search_screen_default_message')}
						</div>
					</EmptyState>
				</div>
			);
		}
		return (
			<div>
				{this.showApps() && (
					<ResourceResults
						title={t('App/Search:applications')}
						results={this.props.apps}
						noResultsText={t('javascript:search.no_applications_found')}
						showStatus={this.supportedSearches > 1}
						isUnderAllSection={this.props.filterOption === SearchFilterOption.All}
					/>
				)}
				{this.showDesktops() && (
					<ResourceResults
						title={t('App/Search:desktops')}
						results={this.props.desktops}
						noResultsText={t('javascript:search.no_desktops_found')}
						showStatus={this.supportedSearches > 1}
						isUnderAllSection={this.props.filterOption === SearchFilterOption.All}
					/>
				)}
				{this.showEmptyState() && (
					<div className={css(styles.emptyState)}>
						<EmptyState icon={EmptySearchIcon}>
							<div className={css(styles.emptyStateTitle)}>
								{t('App/Search:no_search_results_found')}
							</div>
						</EmptyState>
					</div>
				)}
			</div>
		);
	}

	private hasResults() {
		return (
			(this.showApps() && this.props.apps.length) ||
			(this.showDesktops() && this.props.desktops.length)
		);
	}

	private showApps() {
		return (
			this.props.supportsApps &&
			(this.props.filterOption === SearchFilterOption.All ||
				this.props.filterOption === SearchFilterOption.Applications)
		);
	}

	private showDesktops() {
		return (
			this.props.supportsDesktops &&
			(this.props.filterOption === SearchFilterOption.All ||
				this.props.filterOption === SearchFilterOption.Desktops)
		);
	}

	private showEmptyState() {
		return this.supportedSearches === 1 && !this.props.isSearching && !this.hasResults();
	}
}
