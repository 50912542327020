import React, { useEffect } from 'react';
import { t } from '@citrite/translate';
import {
	Button,
	fromThemeColors,
	layout,
	LoadingPrimary,
	palette,
	RefreshActionIcon,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { shouldShowTitle } from 'App/Activity/ActivityManager/ActivityManagerUtils';
import { SessionSectionList } from 'App/Activity/components/SessionSectionList';
import { useResourceManagerContext } from 'App/Activity/ResourceManagerContext';
import { bottomNavBarHeight } from 'App/BottomNav/dimensions';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { useHTMLElementProperties } from 'Components/AppLayout/useHTMLElementProperties';
import { isNativeWidget } from 'Environment/launchResource/device';

interface ActivityManagerProps {
	setMobileMastheadTitle?: (mastheadTitle: React.ReactNode) => void;
}

export const ActivityManager = (props: ActivityManagerProps) => {
	return <_ActivityManager {...props} />;
};

const StyledHorizontalLine = styled.hr`
	margin: ${layout.smallSpace} -4px ${layout.smallSpace} -${layout.mediumSpace};
	border-top: 1px solid ${palette.grey400};
	border-bottom: 0px;
`;

const StyledContainerDiv = styled.div<{
	isSmallFormFactor: boolean;
	offsetTop: number;
	clientHeight: string;
}>`
	display: flex;
	height: 446px;
	width: 336px;
	border-radius: ${layout.tinySpace};
	flex-direction: column;
	background-color: ${fromThemeColors('primaryContainerBackground')};
	line-height: 1;
	padding: ${layout.mediumSpace} ${layout.tinySpace} ${layout.tinySpace}
		${layout.mediumSpace};
	${({ isSmallFormFactor, offsetTop, clientHeight }) =>
		isSmallFormFactor &&
		`
		height: calc(${clientHeight} - ${offsetTop}px - ${layout.mediumSpace} - ${
			layout.tinySpace
		} - ${isNativeWidget() ? '0px' : bottomNavBarHeight});
		width: auto;
	`}
`;

const StyledLoadingPrimary = styled(LoadingPrimary)`
	margin-left: -6px;
`;

const StyledTitle = styled.div`
	font-size: ${typography.sizes.lg};
	font-weight: ${typography.weights.semiBold};
	color: ${fromThemeColors('primaryText')};
	padding-bottom: ${layout.smallSpace};
`;

const StyledRefreshButtonContainer = styled.div<{
	isSmallFormFactor: boolean;
}>`
	position: absolute;
	right: ${layout.mediumSpace};
	${({ isSmallFormFactor }) =>
		isSmallFormFactor &&
		`
		margin-top: ${layout.mediumSpace};
	`}
`;
// allow-unused-export
export const _ActivityManager = (props: ActivityManagerProps) => {
	const resourceManagerContext = useResourceManagerContext();

	useEffect(() => {
		props.setMobileMastheadTitle &&
			props.setMobileMastheadTitle(t('Workspace:activity_manager.activity'));
	}, [props]);

	const refresh = () => resourceManagerContext.refresh(false);

	const newLoader =
		resourceManagerContext.localSessions.loading ||
		resourceManagerContext.remoteSessions.loading ||
		resourceManagerContext.hibernatedSessions.loading;

	const { elementRef, offsetTop } = useHTMLElementProperties();
	const clientHeight = useClientHeight();
	const { isSmallFormFactor } = useFormFactor();

	useEffect(() => {
		resourceManagerContext.refresh(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledContainerDiv
			data-analytics-id="activity-manager"
			ref={elementRef}
			offsetTop={offsetTop}
			clientHeight={clientHeight}
			isSmallFormFactor={isSmallFormFactor}
		>
			<StyledRefreshButtonContainer isSmallFormFactor={isSmallFormFactor}>
				<Button.Wrapper onClick={refresh} disabled={newLoader}>
					<RefreshActionIcon size={16} />
				</Button.Wrapper>
			</StyledRefreshButtonContainer>

			{shouldShowTitle(isSmallFormFactor) && (
				<>
					<StyledTitle>{t('Workspace:activity_manager.activity')}</StyledTitle>
					<StyledHorizontalLine />
				</>
			)}
			{newLoader ? <StyledLoadingPrimary size={50} /> : <SessionSectionList />}
		</StyledContainerDiv>
	);
};
