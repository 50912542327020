import * as React from 'react';
import { t } from '@citrite/translate';
import { ShowModal } from '@citrite/web-ui-component';
import { ContextMenuItemBuilder } from '@citrite/workspace-ui-platform-react';
import { trackAnalyticsEvent } from 'analytics';
import { ViewAppDetails, ViewDesktopDetails } from 'Components/ResourceTile/ViewDetails';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { DesktopsUserEventPayload } from 'Workspace/TelemetryEvents/desktopsEvent/DesktopsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export interface AppContextMenuFactoryProps {
	showModal: ShowModal;
}

interface BuilderContext extends AppContextMenuFactoryProps {}

export const useAppContextMenuFactory = ({
	showModal,
}: AppContextMenuFactoryProps): ContextMenuItemBuilder<Resource> => {
	return app => getOptions(app, { showModal });
};

const getOptions = (app: Resource, context: BuilderContext) => {
	return [
		{
			children: t('Workspace:view_details'),
			onClick: () => {
				if (app.keywords && app.keywords.some(keyword => keyword === 'TreatAsApp')) {
					trackAnalyticsEvent(
						DesktopsUserEventPayload.moreInfo(EventSite.Recent, app.type)
					);
					context.showModal(<ViewDesktopDetails resource={app} />);
				} else {
					trackAnalyticsEvent(AppsUserEventPayload.moreInfo(EventSite.Recent, app.type));
					context.showModal(<ViewAppDetails resource={app} />);
				}
			},
		},
	];
};
