import { useCallback, useEffect } from 'react';
import {
	subscribeToEventBus,
	unsubscribeFromEventBus,
} from 'App/Activity/ActivityManager/ActivityManagerUtils';
import { ActivityResource, RemoveResourceEvent } from 'App/Activity/CommonResourceUtils';
import {
	isAddActivitiesEvent,
	isEventSupported,
	isRemoveActivitiesEvent,
	mergeAndSortSessions,
} from 'App/Activity/components/Utility';
import { updatedNativeSessions } from 'App/Activity/LocalSessionAdapter';
import { IResourceManagerContext } from 'App/Activity/ResourceManagerContext';
import { useEventBus } from 'Components/EventBus';

export function useEventBusSubscription(
	updateSessionManagerContext: (
		contextDispatchFunction: React.SetStateAction<IResourceManagerContext>
	) => void
) {
	const eventBus = useEventBus();

	const sessionsAddEventHandler = useCallback(
		(nativeActivitySessions: ActivityResource[]) => {
			updateSessionManagerContext(prevContext => {
				const nativeSessions = updatedNativeSessions(
					nativeActivitySessions,
					prevContext.localSessions?.sessions
				);
				return {
					...prevContext,
					localSessions: {
						sessions: mergeAndSortSessions(prevContext.localSessions, nativeSessions),
						loading: false,
					},
				};
			});
		},
		[updateSessionManagerContext]
	);

	const sessionsRemoveEventHandler = useCallback(
		(removedSessionIds: string[]) => {
			updateSessionManagerContext(prevContext => {
				const newSessions = prevContext.localSessions.sessions
					.map(session => ({
						...session,
						applications: session.applications?.filter(
							application => !removedSessionIds.includes(application.id)
						),
					}))
					.filter(
						session =>
							session.applications &&
							session.applications.length > 0 &&
							!removedSessionIds.includes(session.sessionId)
					);
				return {
					...prevContext,
					localSessions: {
						sessions: newSessions,
						loading: false,
					},
				};
			});
		},
		[updateSessionManagerContext]
	);

	useEffect(() => {
		const unsubscribe = eventBus.subscribe(event => {
			if (event.payload && isEventSupported(event.type)) {
				if (isAddActivitiesEvent(event.type)) {
					sessionsAddEventHandler(event.payload as ActivityResource[]);
				} else if (isRemoveActivitiesEvent(event.type)) {
					sessionsRemoveEventHandler((event.payload as RemoveResourceEvent).sessionid);
				}
			}
		});
		subscribeToEventBus();

		return () => {
			unsubscribeFromEventBus();
			unsubscribe && unsubscribe();
		};
	}, [eventBus, sessionsAddEventHandler, sessionsRemoveEventHandler]);
}
