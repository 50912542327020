import { telemetryLaunchWrapper } from 'Environment/BrowserExtension/browserExtensionLaunch';
import { BrowserExtensionResponse } from 'Environment/BrowserExtension/types';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { logBrowserExtensionError } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';
import { LeasingProperties } from 'Workspace/BrowserExtension/customerConfiguration';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const leaseLaunchKey = 'browser-extension-last-lease-launch-time';

const getDefaultLeasingProperties = (): LeasingProperties => {
	return {
		alwaysBypassAuthForCachedResources: false,
		bypassAuthForCachedResources: false,
		telemetryHeadlessLaunchEnabled: false,
		telemetryLaunchMinTimeIntervalMins: 720,
		telemetryLaunchShadowDelayMins: 1,
	};
};

export const parseLeasingPropertiesFromResponse = (
	response: BrowserExtensionResponse
) => {
	const leasingProperties = response?.result?.leasingProperties;
	return leasingProperties
		? { ...getDefaultLeasingProperties(), ...leasingProperties }
		: null;
};

export const startTelemetryLaunchTimer = (
	resource: Resource,
	leasingProperties: LeasingProperties,
	onLaunchTriggered: () => void
) => {
	const telemetryLaunchDelayMs =
		leasingProperties.telemetryLaunchShadowDelayMins * 60 * 1000;

	setTimeout(async () => {
		try {
			await telemetryLaunchWrapper(resource, leasingProperties);
		} catch (error) {
			logBrowserExtensionError(error);
		}
		onLaunchTriggered();
	}, telemetryLaunchDelayMs);
};

const getLastLeaseLaunchTime = () => {
	return getFromLocalStorage<string>(leaseLaunchKey);
};

export const saveLastLeaseLaunchTime = () => {
	setInLocalStorage(leaseLaunchKey, new Date());
};

export const isReadyForTelemetryLaunch = (leasingProperties: LeasingProperties) => {
	const lastLeaseLaunchTime = getLastLeaseLaunchTime();

	if (!lastLeaseLaunchTime) {
		return true;
	}

	const telemetryIntervalMs =
		leasingProperties.telemetryLaunchMinTimeIntervalMins * 60 * 1000;

	return (
		new Date().getTime() - new Date(lastLeaseLaunchTime).getTime() > telemetryIntervalMs
	);
};
