import * as React from 'react';
import { css } from 'aphrodite';
import { t } from '@citrite/translate';
import { IInCircleIcon, palette, XIcon } from '@citrite/web-ui-component';
import { styles } from './RefreshBanner.styles';

interface State {
	closed?: boolean;
}

export class RefreshBanner extends React.Component<{}, State> {
	public state: State = {};
	private timeout: number;

	public render() {
		if (this.state.closed) {
			return null;
		}

		return (
			<div className={css(styles.container)}>
				<div className={css(styles.banner)}>
					<IInCircleIcon
						size={24}
						className={css(styles.icon)}
						alt={t('Workspace:info_icon')}
						color={palette.white}
					/>
					<span className={css(styles.text)}>{t('Workspace:refreshing_resources')}</span>
					<XIcon
						size={12}
						className={css(styles.close)}
						onClick={this.close}
						color={palette.white}
					/>
				</div>
			</div>
		);
	}

	private close = () => this.setState({ closed: true });

	public componentDidMount() {
		this.timeout = window.setTimeout(this.close, 5000);
	}

	public componentWillUnmount() {
		clearTimeout(this.timeout);
	}
}
