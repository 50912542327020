import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType } from 'Environment/getClientType';
import { isChrome, isEdge, isFirefox } from 'Environment/launchResource/device';
import { callHomeService } from 'LeaseWorkflow/CallHomeService';
import { initConnectionLeaseManager } from 'LeaseWorkflow/ConnectionLeaseManagerService';
import { shieldCryptoModuleInstance } from 'LeaseWorkflow/ShieldCryptoModule';

let isHtml5ShieldEnabledAtInitialization = false;

export function isHTML5ShieldEnabled(wsuiConfig: WorkspaceConfiguration) {
	return (
		getClientType() === clientType.html5 &&
		browsersSupportHtml5Shield() &&
		hasFeatureCanary(wsuiConfig, FeatureFlag.WsuiEnableHTML5Shield) &&
		isCloudStore()
	);
}

export async function initializeWasmForShieldFlow() {
	await shieldCryptoModuleInstance.loadShieldCryptoWasm();
	await initConnectionLeaseManager();
	isHtml5ShieldEnabledAtInitialization = true;
}

export function callHomeForShield() {
	setTimeout(async () => {
		try {
			await shieldCryptoModuleInstance.generateKvlrCryptoKeypairForShield();
			await shieldCryptoModuleInstance.shieldInit();
			callHomeService.performCallHomeRequest();
		} catch (err) {
			logError(err, {
				tags: {
					feature: 'html5-shield',
				},
			});
		}
	}, 1000);
}

export function getHtml5ShieldStatus() {
	return isHtml5ShieldEnabledAtInitialization;
}

function browsersSupportHtml5Shield() {
	return isChrome() || isEdge() || isFirefox();
}

function isCloudStore() {
	const storeUrl = location.hostname.toLowerCase();
	const cloudDomains = [
		'cloud.com',
		'cloudburrito.com',
		'netscalergateway.net',
		'netscalergatewaystaging.net',
		'sharefile.com',
		'sharefilestaging.com',
	];
	return !!cloudDomains.find(value => {
		return storeUrl.includes(value);
	});
}
