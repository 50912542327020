import { parseErrorForLogging } from './ErrorHelpers';
import {
	AddBreadcrumb,
	LogError,
	LoggingProvider,
	LogInfo,
	SetLoggingTags,
	SetUserId,
} from './LoggingProvider';

export class BaseLogger implements LoggingProvider {
	public logInfo: LogInfo = (message, additionalContext) => {
		// eslint-disable-next-line no-console
		console.log(message, additionalContext);
	};

	public logError: LogError = (payload, options = {}) => {
		if (!payload) {
			payload = new Error('An undefined or empty error payload was provided');
			payload.name = 'UnsupportedErrorPayloadException';
		}
		const { error, extractedContext } = parseErrorForLogging(
			payload,
			options.customMessage
		);

		console.error(error, {
			extractedContext,
			additionalContext: options.additionalContext,
		});
	};

	public addBreadcrumb: AddBreadcrumb = _breadcrumb => {
		// noop
	};

	public configureScope = (_callback: (scope: any) => void) => {
		// noop
	};

	public setLoggingTags: SetLoggingTags = _tags => {
		// noop
	};

	public setUserId: SetUserId = _id => {
		// noop
	};
}
