import * as React from 'react';
import { debounce } from 'lodash';

export function useClientHeight() {
	const [height, setHeight] = React.useState<string>(measureHeight);

	function measureHeight() {
		return !!document.documentElement.clientHeight
			? document.documentElement.clientHeight + 'px'
			: '100vh';
	}

	React.useEffect(() => {
		const setMeasuredHeight = debounce(() => {
			const measuredHeight = measureHeight();
			setHeight(measuredHeight);
		}, 300);
		window.addEventListener('orientationchange', setMeasuredHeight);
		window.addEventListener('resize', setMeasuredHeight);
		return () => {
			window.removeEventListener('orientationchange', setMeasuredHeight);
			window.removeEventListener('resize', setMeasuredHeight);
		};
	});

	return height;
}
