import * as React from 'react';
import { css } from 'aphrodite';
import { styles } from './styles';

export interface Props {
	diameter: number;
}

export function Circle(props: Props) {
	return (
		<div
			className={css(styles.circle)}
			style={{
				width: `${props.diameter}px`,
				height: `${props.diameter}px`,
			}}
		/>
	);
}
