import React from 'react';
import { layout } from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import { BaseDetails } from 'App/Activity/components/BaseDetails';
import { TerminateAction } from 'App/Activity/components/TerminateAction';
import { canTerminate } from 'App/Activity/ResourceActionHandler';
import { ResourceDetails, Session } from 'App/Activity/ResourceManagerContext';

export interface ApplicationDetailsProps {
	application: ResourceDetails;
	session: Session;
}

const desktopStyle = css`
	padding: ${layout.smallSpace} 0 ${layout.smallSpace} ${layout.smallSpace};
`;

export const ApplicationDetails = ({ application, session }: ApplicationDetailsProps) => {
	return (
		application && (
			<BaseDetails
				resourceDetails={application}
				session={session}
				styleProps={desktopStyle}
			>
				{canTerminate(session, application) && (
					<TerminateAction application={application} session={session} />
				)}
			</BaseDetails>
		)
	);
};
