import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { t } from '@citrite/translate';
import { Button, palette, XIcon } from '@citrite/web-ui-component';
import { bottomNavBarHeight } from 'App/BottomNav/dimensions';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';
import { styles } from './styles';

interface State {
	shouldShowMobileBanner: boolean;
}

const daasVisionStyleOverride = StyleSheet.create({
	smartbanner: {
		bottom: bottomNavBarHeight,
		boxShadow: 'none',
	},
});

interface Props {
	daasVisionEnabled?: boolean;
}

export class AndroidBrowserAppBanner extends React.Component<Props, State> {
	private androidBannerDismissedKey = 'androidBannerDismissedKey';

	public state: State = {
		shouldShowMobileBanner: !getFromSessionStorage(this.androidBannerDismissedKey),
	};

	public render() {
		const smartBannerHref =
			'intent://scan/#Intent;scheme=ctxworkspace;package=com.citrix.Receiver;S.browser_fallback_url=http://play.google.com/store/apps/details?id=com.citrix.Receiver;end';
		return (
			this.state.shouldShowMobileBanner && (
				<div
					className={css(
						styles.smartbanner,
						this.props.daasVisionEnabled && daasVisionStyleOverride.smartbanner
					)}
				>
					<span className={css(styles.smartbannerClose)}>
						<Button.IconOnly
							icon={XIcon}
							color={palette.white}
							title={t('Workspace:mobile_smart_banner.banner_close')}
							size={16}
							onClick={() => {
								this.setState({ shouldShowMobileBanner: false });
								setInSessionStorage(this.androidBannerDismissedKey, true);
							}}
						/>
					</span>
					<span className={css(styles.smartbannerText)}>
						{t('Workspace:mobile_smart_banner.banner_text')}
					</span>
					<a className={css(styles.smartbannerAction)} href={smartBannerHref}>
						{t('Workspace:mobile_smart_banner.banner_action')}
					</a>
				</div>
			)
		);
	}
}
