import { StyleSheet } from 'aphrodite';
import { layout, palette, styleUtils, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	resultsHeading: {
		backgroundColor: palette.grey200,
		padding: '5px 0 5px 20px',
		color: palette.grey700,
	},
	nonResult: {
		backgroundColor: 'white',
		padding: '10px 0 10px 20px',
		fontWeight: typography.weights.light,
		color: palette.grey700,
		listStyleType: 'none',
	},
	listItem: {
		padding: '6px 10px 6px 20px',
		color: palette.grey700,
		listStyleType: 'none',
		cursor: 'pointer',
	},
	listItemDisabled: {
		cursor: 'not-allowed',
		backgroundColor: palette.grey100,
		opacity: '.6',
		padding: '6px 10px 6px 20px',
		color: palette.grey700,
		listStyleType: 'none',
	},
	listItemHighlight: {
		backgroundColor: palette.grey100,
	},
	itemTitle: {
		display: 'flex',
	},
	itemName: {
		display: 'inline-block',
		verticalAlign: 'top',
		height: layout.mediumLargeSpace,
		lineHeight: layout.mediumLargeSpace,
		fontSize: '16px',
		marginLeft: '10px',
		...styleUtils.ellipsis,
	},
});
