import React, { useState } from 'react';
import { History } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { activityPagePath } from 'App/Activity';
import { appsPagePath } from 'App/Apps';
import { customLinksPagePath } from 'App/CustomLinkBanner';
import { desktopsPagePath } from 'App/Desktops';
import { isNativeClient } from 'Environment/launchResource/device';
import { Error404 } from 'ErrorPage';
import { homePagePath } from 'Router/homePageRoute';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

/**
 * This change is needed for the features we recently introduced in workspace
 *  1. WSUI-6925: Admin controlled Show/Hide "Home" tab
 *  2. WSUI-6395: Adapting UI tabs
 *
 *  These changes introduced scenarios where some of the routes (/home, /apps, /desktops)
 *  to be available only if the certain conditions are met.
 *
 *  This ended up wsui rendering Page not found(404 page) for these routes
 *  and these routes might be bookmarked by users or at situations wsui
 *  would auto land on these routes.
 *
 *  We wanted to solve this be taking the user to valid available routes for 404
 *  on these routes.
 *
 *  By default user is always routed to /home(Routes.tsx:97) if there is no specific route.
 *  With the introduction of Admin controlled home tab feature(WSUI-6925), there are
 *  scenarios /home is not available. So we need to take the user to any
 *  other available route. All other routes are also conditioned to be available.
 *
 *  The two other routes which are significant for workspace and logically can be landed when home not
 *  available are /apps and /desktops. With the Adapting UI tabs feature(WSUI-6395), these routes are
 *  also scenario based(FeatureFlag.ts:30 - NoEmptyAppsOrEmptyDesktopSection).
 *
 *  We had couple of options to implement this feature,
 *
 *  Option 1: Land on available route directly when application loads.
 *    This we could achieve by deciding the right route at Routes.tsx:97. As this is
 *    at the very top of the application and we would not know which route is available
 *    at this point(Resources can load late), we could not implement this approach
 *
 *  Option 2: Land on available route when the current route is not available.
 *    This we could achieve by specifying a default component when the route is not
 *    available at RouteLoader.tsx:101. Implementing this would cause issues during the
 *    native widget and integrations scenarios, which are made specific for those routes
 *    and re-routing is not appropriate.
 *
 * 	Option 3: Give the control to Error404 component to re-route to other options.
 *    With this we simplified the scenarios and made /home to /desktops when /home not available
 *    and /desktops to /apps when apps not available in any scenario.
 *    With this, we expect any of /home, /apps or /desktops to be always available when auto routed or
 *    directly accessed. And all other unavailable or wrong routes still land on Error404
 *    as earlier.
 *
 * 	Ex. Scenarios
 *
 * 	  Landing on home with home and desktops not available: home -> desktops -> apps
 *    Landing on apps with desktops and home not available: apps -> home -> desktops
 *    Landing on desktops with desktops and apps not available: desktops -> apps -> home
 *    If all three routes are not available, then it would finally go to 404
 */

/*  
	When changing screen size from smallform  to largeform, 
	some paths are not available on the large screens. 
	Showing a 404 page would appear like a bug to the user so 
	we route the user to homePagePath
*/
const handleNonExistingRouteOnLargeScreen = (route: string, history: History) => {
	const smallScreenRoutes = [activityPagePath, customLinksPagePath];
	if (smallScreenRoutes.includes(route)) {
		history.push(homePagePath);
		return true;
	}

	return false;
};

export function RerouteHandler() {
	const location = useLocation();
	const history = useHistory();
	const { loading: resourceContextLoading } = useLoadableResourceContext();
	const [routedPaths, setRoutedPaths] = useState([]);
	const [isRerouting, setIsRerouting] = useState<boolean>(true);

	React.useLayoutEffect(() => {
		if (!resourceContextLoading) {
			if (location.pathname === homePagePath && !routedPaths.includes(desktopsPagePath)) {
				setRoutedPaths([...routedPaths, homePagePath, desktopsPagePath]);
				history.push(desktopsPagePath);
			} else if (
				location.pathname.startsWith(desktopsPagePath) &&
				!routedPaths.includes(appsPagePath)
			) {
				setRoutedPaths([...routedPaths, desktopsPagePath, appsPagePath]);
				history.push(appsPagePath);
			} else if (
				location.pathname.startsWith(appsPagePath) &&
				!routedPaths.includes(homePagePath)
			) {
				setRoutedPaths([...routedPaths, homePagePath, appsPagePath]);
				history.push(homePagePath);
			} else if (!handleNonExistingRouteOnLargeScreen(location.pathname, history)) {
				reRouteWithSystematicFallback(setIsRerouting, history);
			}
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, resourceContextLoading]);

	return !isRerouting && <Error404 />;
}

//allow-unused-export: this export is needed for unit test
export function reRouteWithSystematicFallback(
	setIsRerouting: React.Dispatch<React.SetStateAction<boolean>>,
	history: History<any>
): void {
	if (IS_ON_PREM && isNativeClient()) {
		history.push(homePagePath);
		return;
	}

	setIsRerouting(false);
}
