import { Event, EventBus as IEventBus } from 'Workspace/EventBus';

export class EventBus implements IEventBus {
	private listeners: ((data: Event<any>) => void)[] = [];
	public constructor(
		private handleSubscriberException?: (exception: any, event: Event<any>) => void
	) {}

	public subscribe<T>(listener: (event: Event<T>) => void) {
		if (typeof listener !== 'function') {
			throw new Error('An EventBus listener MUST be a function');
		}
		this.listeners.push(listener);
		const unsubscribe = () => {
			const index = this.listeners.indexOf(listener);
			if (index !== -1) {
				this.listeners.splice(index, 1);
			}
		};
		return unsubscribe;
	}

	public dispatch<T>(event: Event<T>) {
		this.listeners.forEach(listener => {
			try {
				listener(event);
			} catch (e) {
				if (this.handleSubscriberException) {
					this.handleSubscriberException(e, event);
					return;
				}
				throw e;
			}
		});
	}
}
