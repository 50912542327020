import * as React from 'react';
import { css } from 'aphrodite';
import { Button } from '@citrite/web-ui-component';
import { styles } from './EmptyState.styles';

export enum CallbackPlacement {
	descriptionLink,
	bottomPrimaryButton,
	bottomSubtleButton,
	bottomBabySecondaryButton,
}

const bottomButtons = {
	[CallbackPlacement.bottomPrimaryButton]: Button.Primary,
	[CallbackPlacement.bottomSubtleButton]: Button.SubtlePrimary,
	[CallbackPlacement.bottomBabySecondaryButton]: Button.BabySecondary,
};

export interface Props {
	source: string;
	imageUrl: string;
	title?: string;
	callback?(): void;
	callbackLabel?: string;
	callbackPlacement?: CallbackPlacement;
	description?: React.ReactNode;
	isLineArtImage?: boolean;
}

export function EmptyState(props: Props) {
	const titleIDRef = `${props.source}-title`;
	const isLineArtImage = props.isLineArtImage;
	const descriptionIDRef = `${props.source}-description`;
	const callbackPlacement = props.callbackPlacement || CallbackPlacement.descriptionLink;
	const descriptionButton = getDescriptionButton(props, callbackPlacement);
	const bottomButton = getBottomButton(props, callbackPlacement);

	const descriptionAndDescriptionButton = isLineArtImage ? (
		<span id={descriptionIDRef} className={css(styles.stateDescriptionLineArt)}>
			{props.description}

			<div className={css(styles.lineArtLink)}>{descriptionButton}</div>
		</span>
	) : (
		<span id={descriptionIDRef} className={css(styles.stateDescription)}>
			{descriptionButton}
			{props.description}
		</span>
	);

	return (
		<div
			className={css(
				isLineArtImage ? styles.stateContainerLineArt : styles.stateContainer
			)}
			aria-describedby={descriptionIDRef}
			aria-labelledby={titleIDRef}
		>
			<img className={css(styles.stateImage)} src={props.imageUrl} alt="" />
			{!!props.title && (
				<span
					id={titleIDRef}
					className={css(
						isLineArtImage ? styles.stateHeadingLineArt : styles.stateHeading
					)}
				>
					{props.title}
				</span>
			)}

			{descriptionAndDescriptionButton}
			{bottomButton}
		</div>
	);
}

const getDescriptionButton = (props: Props, callbackPlacement: CallbackPlacement) => {
	if (!props.callback) {
		return null;
	}
	if (callbackPlacement === CallbackPlacement.descriptionLink) {
		return (
			<Button.TextPrimary onClick={props.callback}>
				<strong>{props.callbackLabel}</strong>
			</Button.TextPrimary>
		);
	} else {
		return null;
	}
};

const getBottomButton = (props: Props, callbackPlacement: CallbackPlacement) => {
	if (!props.callback || !callbackPlacement) {
		return null;
	}
	const BottomButton = bottomButtons[callbackPlacement];
	return (
		<div className={css(styles.bottomButton)}>
			<BottomButton onClick={props.callback}>
				<strong>{props.callbackLabel}</strong>
			</BottomButton>
		</div>
	);
};
