import * as React from 'react';
import { Action } from '@citrite/workspace-ui-platform';
import { v4 } from 'uuid';
import { ActionsProvider } from 'App/Actions/actionTypes';
import { EventType, UpdateActionsEvent } from 'Components/EventBus/EventType';
import { Action as ActionApp, environment } from 'Environment';

export type Props = {
	actionsContext: ActionsProvider;
};

export const SyncActionsToNative = (props: Props): JSX.Element => {
	const { actionsContext } = props;
	const actions = actionsContext.value?.actions;
	const loading = actionsContext.loading;

	React.useEffect(() => {
		if (loading) {
			return;
		}
		sendActionsToNative(actions);
	}, [actions, loading]);
	return null;
};

const sendActionsToNative = (actions: Action[]) => {
	const actionsList = actions.map<ActionApp>(({ id, title, iconUrl, serviceName }) => ({
		id,
		name: title,
		iconUrl,
		serviceName,
	}));

	const event: UpdateActionsEvent = {
		id: v4(),
		type: EventType.UPDATE_ACTIONS,
		payload: { actions: actionsList },
	};
	environment.sendEventToNative(event);
};
