import { RequestInterceptors } from '@citrite/http';
import { interceptor as remoteInterceptor } from './remote';
import { interceptor as workspacePlatformInterceptor } from './workspacePlatform';

export const interceptor: RequestInterceptors = {
	prepareRequest: config => {
		const isRemoteApiRequest = config.url.startsWith('http');
		if (isRemoteApiRequest) {
			return remoteInterceptor.prepareRequest(config);
		}
		return workspacePlatformInterceptor.prepareRequest(config);
	},
};
