import { StyleSheet } from 'aphrodite';
import { layout, palette } from '@citrite/web-ui-component';
import { keyframes } from '@emotion/react';
import { BarHeight } from './Bar';

export const shimmer = keyframes`
	from, 0%, to {
		background-position: -110px, 0px;
	}
	
	100% {
		background-position: 110px, 0px;
	}
`;

export const linearGradientLightImage =
	'linear-gradient(to right,' +
	palette.grey300 +
	' 0%,' +
	palette.grey200 +
	' 20%,' +
	palette.grey200 +
	' 40%,' +
	palette.grey300 +
	' 80%)';

export function getBarHeight(barType: BarHeight) {
	switch (barType) {
		case BarHeight.Thick:
			return toPixel(2 * heightBase);
		case BarHeight.Medium:
			return toPixel(heightBase);
		case BarHeight.Thin:
			return toPixel(0.75 * heightBase);
		default:
			return toPixel(heightBase);
	}
}

const heightBase = 8;
const toPixel = (number: number) => `${number}px`;

export const styles = StyleSheet.create({
	circle: {
		borderRadius: '50%',
		backgroundColor: palette.grey300,
	},
	rectangle: {
		borderRadius: toPixel(heightBase / 2),
		backgroundColor: palette.grey300,
	},
	bar: {
		borderRadius: toPixel(heightBase / 2),
		height: toPixel(heightBase),
		margin: layout.mediumSpace,
		backgroundColor: palette.grey300,
	},
	barSmallSpacing: {
		margin: `${layout.smallSpace} ${layout.mediumSpace}`,
	},
	barLargeSpacing: {
		margin: layout.largeSpace,
	},
	barThin: {
		borderRadius: toPixel(0.75 * 0.5 * heightBase),
		height: toPixel(0.75 * heightBase),
	},
	barThick: {
		borderRadius: toPixel(heightBase),
		height: toPixel(2 * heightBase),
	},
});
