import { palette } from '@citrite/web-ui-component';
import {
	getFromInteractiveStorage,
	installLazyGetter,
	installToolFunction,
	setInInteractiveStorage,
} from 'javascript/interactiveTools';
import { ProvidedNativeFunctions } from './providedNativeFunctions';

const bridgeWatchEnabledKey = 'interactive_bridge_watch_enabled';
export let bridgeWatchEnabled = getFromInteractiveStorage(bridgeWatchEnabledKey) || false;
const bridgeWatch = new Map<
	string,
	{
		functionName: keyof ProvidedNativeFunctions;
		args: any[];
	}
>();
export function bridgeOutbound(
	functionName: keyof ProvidedNativeFunctions,
	args: any[],
	callbackId?: string
) {
	// eslint-disable-next-line no-console
	console.info(
		`${callbackId ? '📫' : '➡️'} [WSUI] %c${functionName}%c:`,
		`color: ${palette.red800}`,
		'color: #000;',
		args
	);
	if (callbackId) {
		// eslint-disable-next-line no-console
		console.time(`${functionName} [${callbackId}] timer`);
		bridgeWatch.set(callbackId, {
			functionName,
			args,
		});
	}
}

export function bridgeInbound(responseArguments: any[], callbackId?: string) {
	const callInfo = bridgeWatch.get(callbackId);
	if (callInfo) {
		let argsAsString = callInfo.args.join(', ');
		argsAsString =
			argsAsString.length > 64 ? argsAsString.slice(0, 64) + '...' : argsAsString;
		// eslint-disable-next-line no-console
		console.info(
			`📩 [CWA] %c${callInfo.functionName}(${argsAsString})%c:`,
			`color: ${palette.red800}`,
			'color: #000;',
			responseArguments
		);
		// eslint-disable-next-line no-console
		console.timeEnd(`${callInfo.functionName} [${callbackId}] timer`);
		bridgeWatch.delete(callbackId);
	} else {
		// eslint-disable-next-line no-console
		console.info('⬅️ [CWA] Returned: ', responseArguments);
	}
}

installLazyGetter('bridgeWatchEnabled', () => bridgeWatchEnabled);
installToolFunction('watchBridge', () => {
	bridgeWatchEnabled = true;
	setInInteractiveStorage(bridgeWatchEnabledKey, bridgeWatchEnabled);
	return 'Call "interactive.ignoreBridge();" to cancel';
});
installToolFunction('ignoreBridge', () => {
	bridgeWatchEnabled = false;
	setInInteractiveStorage(bridgeWatchEnabledKey, bridgeWatchEnabled);
	return 'Ignoring bridge methods';
});

const interceptKey = 'interactive_bridge_intercepts';
export const bridgeOverrides: Map<keyof ProvidedNativeFunctions, any[]> = new Map(
	getFromInteractiveStorage(interceptKey) || []
);

installLazyGetter('activeBridgeIntercepts', () => {
	return bridgeOverrides;
});
installToolFunction(
	'overrideBridgeMethod',
	(name: keyof ProvidedNativeFunctions, ...replaceWith: any[]) => {
		bridgeOverrides.set(name, replaceWith);
		setInInteractiveStorage(interceptKey, serializeOverrides());
		return `Call "interactive.restoreBridgeMethod('${name}');" to remove bridge override`;
	}
);
installToolFunction('restoreBridgeMethod', (name: keyof ProvidedNativeFunctions) => {
	bridgeOverrides.delete(name);
	setInInteractiveStorage(interceptKey, serializeOverrides());
	return `Bridge intercept for "${name}" removed`;
});

function serializeOverrides() {
	const entries = [];
	for (const entry of bridgeOverrides.entries()) {
		entries.push(entry);
	}
	return entries;
}
