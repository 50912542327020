import {
	createIntegration,
	IntegrationCapability,
	// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
	workspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { appsManifestPrivateSymbol } from 'appsAndDesktops/apps/privateScope';
import { performance } from 'Components/performance';
import { UIProperty } from 'Environment/hasUIProperty';

export const apps = createIntegration({
	id: 'apps',
	cacheVersion: '1',
	privateSymbol: appsManifestPrivateSymbol,
	capabilities: [
		IntegrationCapability.UNSTABLE_widgetComponent.register('appsSmall', {
			performanceKey: performance.events.resourcesWidget,
		}),
		IntegrationCapability.UNSTABLE_widgetComponent.register('appsLarge', {
			performanceKey: performance.events.resourcesWidget,
		}),
	],
	modulesResolver: {
		appsSmall: () =>
			import(
				/* webpackChunkName: "AppsWidget" */ 'App/NotificationHome/ResourcesWidgets'
			).then(({ AppsWidget }) => ({ Component: AppsWidget })),
		appsLarge: () =>
			import(/* webpackChunkName: "AppsWidget" */ 'App/Home/Widgets/Apps/Apps').then(
				({ AppsWidget }) => ({ Component: AppsWidget })
			),
	},
	isEnabled: () => workspaceConfiguration.hasUIProperty(UIProperty.Apps),
});
