import * as React from 'react';
import { css } from 'aphrodite';
import { Button } from '@citrite/web-ui-component';
import { NavLink } from 'react-router-dom';
import { bottomNavItemIconSize } from 'App/BottomNav/dimensions';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { performance } from 'Components/performance';
import { styles } from './OverflowMenu.styles';

export interface Props {
	link: LeftNavLink;
	closeMenu(): void;
}

export class OverflowMenuItem extends React.Component<Props> {
	public render() {
		return (
			<>
				{this.props.link.href ? (
					<NavLink
						to={this.props.link.href}
						className={css(styles.linkActive)}
						onClick={this.menuItemClick}
					>
						{this.renderTabItem()}
					</NavLink>
				) : (
					<Button.Wrapper className={css(styles.linkActive)} onClick={this.onClick}>
						{this.renderTabItem()}
					</Button.Wrapper>
				)}
			</>
		);
	}

	private renderTabItem() {
		const title = this.props.link.title;
		const Icon = this.props.link.icon;
		return (
			<div className={css(styles.overflowMenuItem)}>
				{Icon && (
					<div className={css(styles.icon)}>
						<Icon size={bottomNavItemIconSize} />
					</div>
				)}
				<div className={css(styles.title)}>{title}</div>
			</div>
		);
	}

	private onClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		this.props.closeMenu();
		this.props.link.onClick(e);
	};

	private menuItemClick = () => {
		if (this.props.link.performanceMark) {
			performance.mark(this.props.link.performanceMark);
		}
		this.props.closeMenu();
	};
}
