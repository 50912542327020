import {
	ArrowRightIcon,
	breakpoints,
	fromThemeColors,
	palette,
	ThemeColor,
	typography,
} from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const StyledListItem = styled.li`
	padding: 0 0 20px;
	list-style: none;
	position: relative;
	width: 220px;
	${breakpoints.onMobile} {
		width: 300px;
	}
`;

export const StyledSubMenu = styled.div`
	height: auto;
	overflow: hidden;
`;

export const StyledChildMenuList = styled.ul`
	background-color: #f9f9f9;
	margin: 10px 0 0 0;
	padding: 10px 0 10px 15px;
	list-style-type: none;
`;

interface StyledExpandIconProps {
	expanded: boolean;
}

export const StyledExpandIcon = styled(ArrowRightIcon, {
	shouldForwardProp: isPropValid,
})<StyledExpandIconProps>`
	opacity: 1;
	transition: transform 0.2s;
	transition-delay: opacity 0.25s;
	right: 10px;
	position: absolute;
	top: 0;
	cursor: pointer;

	${props => props.expanded && 'transform: rotate(90deg);'};
`;

export const activeClassName = 'active';

const linkCss = (props: { theme: Theme }) => css`
	text-decoration: none;
	display: inline-block;
	font-size: ${typography.sizes.sml};
	white-space: normal;
	box-sizing: border-box;
	padding: 0 20px;
	width: 100%;
	color: ${fromThemeColors(ThemeColor.primaryText)(props)};
	${breakpoints.onDesktop} {
		:hover {
			font-weight: ${typography.weights.semiBold};
			color: ${fromThemeColors(ThemeColor.secondaryText)(props)};
		}
	}
	${breakpoints.onMobile} {
		font-size: ${typography.sizes.base};
	}
	&.${activeClassName} {
		font-weight: ${typography.weights.semiBold};
		border-left-color: ${palette.grey900};
	}
`;

export const StyledNavLink = styled(NavLink)`
	${linkCss};
`;

export const StyledAnchor = styled.a`
	${linkCss};
`;
