import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	ArrowRightIcon,
	breakpoints,
	fromThemeColors,
	fromThemePalette,
	layout,
	palette,
	styleUtils,
	ThemeColor,
	typography,
} from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { FeatureFlag } from 'Environment/FeatureFlag';

const shimmer = {
	'0%': {
		left: '-150px',
	},
	'100%': {
		left: '100%',
	},
};

const shimmerStyle = css`
	position: relative;
	overflow: hidden;
	${breakpoints.onSmallScreen} {
		width: 100%;
	}
	::after {
		background: linear-gradient(
			to right,
			rgba(249, 249, 249, 0) 25%,
			#f9f9f9 50%,
			rgba(249, 249, 249, 0) 75%
		);
		background-repeat: no-repeat;
		animation-name: ${shimmer} as any;
		animation-duration: 3s;
		animation-iteration-count: infinite;
		content: '';
		position: absolute;
		width: 125px;
		height: 150%;
		top: -50px;
		opacity: 0.5;
		transform: rotate(25deg);
	}
`;

export const StyledChildMenuList = styled.ul<{ isLoading: boolean }>`
	background-color: #f9f9f9;
	padding: 0;
	margin: 0;
	list-style-type: none;
	${props => props.isLoading && shimmerStyle};
`;

const mobileLinkStyle = css`
	display: none;
	${breakpoints.onMobile} {
		display: list-item;
	}
`;

const listItemStyles = () => css`
	position: relative;
	transition: 0.5s ease-in-out;
	${getFeatureFlagValue(FeatureFlag.SimplifiedNav)
		? {
				marginLeft: layout.mediumSpace,
				marginRight: layout.mediumSpace,
				width: '208px',
		  }
		: {
				width: '239px',
		  }}
	${breakpoints.onMobile} {
		${!getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
			width: '300px',
		}}
	}
`;

export const StyledSkeletonListItem = styled.div`
	${listItemStyles};
`;

export const StyledListItem = styled.li<{ collapsed: boolean; mobileOnly: boolean }>`
	${listItemStyles};
	${props => props.collapsed && 'width: 58px; margin-right: 4px'};
	${props => (props.mobileOnly ? mobileLinkStyle : '')};
`;

export const StyledDivider = styled.div`
	margin: 10px 45px 5px 57px;
	background: ${palette.grey300};
	height: 1px;
`;

export const StyledSubMenu = styled.div`
	height: auto;
	overflow: hidden;
`;

interface StyledExpandIconProps {
	expanded: boolean;
}

export const StyledExpandIcon = styled(ArrowRightIcon, {
	shouldForwardProp: isPropValid,
})<StyledExpandIconProps>`
	opacity: 1;
	transition: opacity 0.5s, transform 0.2s;
	transition-delay: opacity 0.25s;
	right: 20px;
	position: absolute;
	top: 20px;
	cursor: pointer;
	pointer-events: none;

	${props => props.expanded && 'transform: rotate(90deg);'};
`;

export const StyledLinkBody = styled.span`
	display: flex;
	align-items: center;
`;

export const StyledLinkIcon = styled.span`
	flex: 0 0 ${layout.mediumLargeSpace};
	height: ${layout.mediumLargeSpace};
	margin-right: ${layout.mediumSpace};
`;

export const StyledLinkTextAndActions = styled.span<{ collapsed: boolean }>`
	transition: opacity 0.5s;
	padding-right: 5px;
	display: flex;
	align-items: center;
	width: 100%;

	${props => props.collapsed && 'opacity: 0'};
`;

export const StyledLinkText = styled.span`
	flex-grow: 1;
	${styleUtils.ellipsisCss};
`;

export const activeClassName = 'active';
interface LinkProps {
	hideLink: boolean;
}

const activeLinkColor = fromThemePalette({
	citrixbrand: palette.teal900,
	neutral: palette.blue900,
});

const linkCss = (props: LinkProps & { theme: Theme }) => css`
	text-decoration: none;
	display: block;
	font-size: ${typography.sizes.sml};
	transition: padding 0.2s;
	white-space: normal;
	line-height: 24px;
	padding: ${props.hideLink ? '0px' : layout.mediumSpace};
	${getFeatureFlagValue(FeatureFlag.SimplifiedNav)
		? {
				borderRadius: '8px',
		  }
		: {
				borderLeft: '4px solid transparent',
		  }}
	color: ${fromThemeColors(ThemeColor.primaryText)(props)};
	${breakpoints.onDesktop} {
		:hover {
			border-color: ${fromThemeColors(ThemeColor.listItemHoverBackground)(props)};
			background-color: ${fromThemeColors(ThemeColor.listItemHoverBackground)(props)};
		}
	}
	${breakpoints.onMobile} {
		font-size: ${typography.sizes.base};
	}
	&.${activeClassName} {
		font-weight: ${typography.weights.semiBold};
		${getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? {
					backgroundColor: `${fromThemeColors('listItemSelectedBackground')(props)}`,
					color: `${activeLinkColor({ theme: props.theme })}`,
			  }
			: {
					borderLeftColor: props.theme.branding.styles.AccentColor,
			  }}
	}
`;

export const StyledNavLink = styled(NavLink, {
	shouldForwardProp: isPropValid,
})<LinkProps>`
	${linkCss};
`;

export const StyledAnchor = styled.a<LinkProps>`
	${linkCss};
`;
