import {
	hasFeatureCanary,
	PlatformDependencies,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { urlSafeBase64Encode } from 'Environment/launchResource/launchUtil';
import { ErrorLevel } from 'Loggers/LoggingProvider';
import { container } from 'Workspace/DependencyManagement';
import { getStoreUrl } from 'Workspace/SchemeCallHandler/schemeCallHandler';
import { GlobalAppConfigV2Payload } from 'Workspace/TelemetryEvents/globalAppConfigV2/createGlobalAppConfigCASReporter';

export interface GlobalAppConfigResponse {
	access: string;
	accessExpiresIn: number;
	maximumAllowedRefresh: number;
	metadata: string;
	refresh: string;
	refreshExpiresIn: number;
}

class GacsTicketFetcher {
	private static instance: GacsTicketFetcher;
	private GACS_ENDPOINT_SERVICE = 'GlobalAppConfigService';
	private GACS_TOKEN_ENDPOINT_SERVICE = 'TokenService';
	private MAX_GACS_TIMEOUT_MS = 5000;
	private GACS_TIMEOUT_ERROR = new Error('GACS_TIMEOUT');

	private constructor() {
		// Private constructor to prevent direct instantiation with 'new' keyword
	}

	public static getInstance(): GacsTicketFetcher {
		if (!GacsTicketFetcher.instance) {
			GacsTicketFetcher.instance = new GacsTicketFetcher();
		}
		return GacsTicketFetcher.instance;
	}

	public async getGacsParams(): Promise<string> {
		const workspaceConfig = container
			.resolve(PlatformDependencies.WorkspaceConfiguration)
			.get();

		if (!this.isGacsV2Enabled(workspaceConfig)) {
			return null;
		}

		const gacsTicket = await this.getGacsTicket(workspaceConfig);
		if (!gacsTicket?.access || !gacsTicket?.metadata) {
			return null;
		}
		trackAnalyticsEvent(GlobalAppConfigV2Payload.isRequestSuccessful(true));
		return `&gacsToken=${gacsTicket.access}&gacsMeta=${gacsTicket.metadata}`;
	}

	private async getGacsTicket(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse> {
		try {
			const startTime = Date.now();
			//We will return a in-memory cached Ticket in a later PR from here to avoid extra network calls.

			// Return the result of the network fetch or null if it takes longer than max timeout
			const timeoutPromise = new Promise<GlobalAppConfigResponse>((_, reject) => {
				setTimeout(() => {
					reject(this.GACS_TIMEOUT_ERROR);
				}, this.MAX_GACS_TIMEOUT_MS);
			});

			const gacsTicket = await Promise.race([
				timeoutPromise,
				this.fetchGacsTicketFromNetwork(workspaceConfiguration),
			]);

			const endTime = Date.now();

			const latency = endTime - startTime;
			trackAnalyticsEvent(GlobalAppConfigV2Payload.getLatency(latency));
			return gacsTicket;
		} catch (error) {
			if (error === this.GACS_TIMEOUT_ERROR) {
				trackAnalyticsEvent(GlobalAppConfigV2Payload.isTimedOut(true));
			} else {
				trackAnalyticsEvent(GlobalAppConfigV2Payload.isRequestSuccessful(false));
				logError(error, { additionalContext: { level: ErrorLevel.CRITICAL } });
			}
			return null;
		}
	}

	private async fetchGacsTicketFromNetwork(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse> {
		const tokenEndpointUrl = await this.getGacsTokenEndpointUrl(workspaceConfiguration);
		if (!tokenEndpointUrl) {
			return null;
		}

		const citrixAuthDomain = workspaceConfiguration?.authManager?.authDomain;

		const ssoProxyClient = environment.createSSOProxyClient(
			workspaceConfiguration.authManager.proxyUrl
		);

		return await ssoProxyClient.post<GlobalAppConfigResponse>(tokenEndpointUrl, null, {
			headers: {
				'Citrix-Client-Url': this.getEncodedStoreUrl(),
				'Citrix-AuthDomain': citrixAuthDomain,
			},
			responseType: 'text',
		});
	}

	private async getGacsTokenEndpointUrl(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<string> {
		const endpointService = getEndpointsService(
			workspaceConfiguration,
			this.GACS_ENDPOINT_SERVICE
		);

		if (!endpointService) {
			return null;
		}

		const endpointSupplier = createEndpointSupplier(endpointService.discoveryUrl);
		const gacsTokenEndpointUrl = await endpointSupplier.getEndpoint(
			this.GACS_TOKEN_ENDPOINT_SERVICE
		);

		if (!gacsTokenEndpointUrl) {
			return null;
		}
		return gacsTokenEndpointUrl;
	}

	public isGacsV2Enabled(workspaceConfig: WorkspaceConfiguration): boolean {
		/* Check if GACS V2 is enabled and GACS endpoint (GACS_ENDPOINT_SERVICE) is available in the workspace configuration,
		 * if endpoint is available then we will always have token endpoint (GACS_TOKEN_ENDPOINT_SERVICE) as well.
		 */

		const isGacsEndpointAvailable = !!getEndpointsService(
			workspaceConfig,
			this.GACS_ENDPOINT_SERVICE
		);
		return (
			!!workspaceConfig &&
			hasFeatureCanary(workspaceConfig, FeatureFlag.EnableGlobalAppConfigV2) &&
			isGacsEndpointAvailable
		);
	}

	private getEncodedStoreUrl(): string {
		const storeUrl = getStoreUrl();
		return urlSafeBase64Encode(JSON.stringify({ workspaceURL: storeUrl }));
	}
}

export const gacsTicketFetcher = GacsTicketFetcher.getInstance();
