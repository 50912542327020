import * as React from 'react';
import { trackEvent } from 'analytics';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { isStoreFrontFallbackEnabled } from 'ErrorPage/StoreFrontFallback';

export function useStorefrontFallbackReporting() {
	const { workspaceConfiguration } = useConfigurationContext();
	const sent = React.useRef(false);

	React.useEffect(() => {
		if (sent.current) {
			return;
		}

		const isEnabled = isStoreFrontFallbackEnabled(workspaceConfiguration);
		if (!isEnabled) {
			return;
		}

		const { fallbackConfiguration } = workspaceConfiguration.userInterface;
		const count = fallbackConfiguration.altStore
			? fallbackConfiguration.altStore.length + 1
			: 1;

		trackEvent('ConfiguredStorefrontFallback', {
			fallbackStoreCount: count,
		});

		sent.current = true;
	}, [workspaceConfiguration]);
}
