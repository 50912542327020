import { t } from '@citrite/translate';
import { CustomLinkIcon, CustomLinkSolidIcon } from '@citrite/web-ui-component';
import { hasFeatureCanary, LeftNavPositions } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import { isSmallForm } from 'App/Header/useFormFactor';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { createLeftNavRoute } from 'Router/ReactRoute';

export const customLinksPagePath = '/customLinks';
const CustomLinksRouteLauncher = makeAsync(() =>
	import(
		/* webpackChunkName: "CustomLinksRouteLauncher" */ './CustomLinksRouteLauncher'
	).then(m => m.CustomLinksRouteLauncher)
);

export const customLinkBannerRoute = createLeftNavRoute({
	component: CustomLinksRouteLauncher,
	path: customLinksPagePath,
	childRoutes: null,
	isAvailable: props =>
		props.userContext.isDaasVisionEnabled &&
		hasFeatureCanary(props.workspaceConfiguration, FeatureFlag.EnableCustomLinks) &&
		isSmallForm() &&
		!!props.workspaceConfiguration?.pinnedLinks?.pinnedLink?.length,
	isLoading: () => false,
	getNavLinkProps: () => ({
		icon: CustomLinkIcon,
		selectedIcon: CustomLinkSolidIcon,
		title: t('Workspace:pinned_links.pinned_links'),
		weight: LeftNavPositions.customLinks,
		performanceMark: null,
		analyticsID: 'pinned-link-nav-tab',
	}),
});
