import * as React from 'react';
import { getDeviceName } from 'Environment/launchResource/device';
import { monitoring } from 'MonitoringAnalytics';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext/Consumer';

export function useMonitoringUserReporting() {
	const loadableUserContext = useUserContext();

	const { value: resourceContext } = useLoadableResourceContext();
	const numberOfResources = resourceContext?.resources?.length;

	const { isIWSEnabled, isValidatedReturnUser } = loadableUserContext;

	const currentPlatform = getDeviceName();

	React.useMemo(() => {
		monitoring.setSessionAttribute('workspaceIntelligenceEnabled', !!isIWSEnabled);
	}, [isIWSEnabled]);

	React.useMemo(() => {
		monitoring.setSessionAttribute('UserLoggedInBefore', isValidatedReturnUser);
	}, [isValidatedReturnUser]);

	React.useMemo(() => {
		monitoring.setSessionAttribute('UserAgentPlatform', currentPlatform);
	}, [currentPlatform]);

	React.useMemo(() => {
		if (numberOfResources) {
			const numberOfDesktops = resourceContext.resources.filter(
				({ isdesktop }) => !!isdesktop
			).length;
			const numberOfApps = numberOfResources - numberOfDesktops;
			monitoring.setSessionAttribute('DesktopResourceCount', numberOfDesktops);
			monitoring.setSessionAttribute('AppResourceCount', numberOfApps);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberOfResources]);
}
