import { post } from '@citrite/http';
import { first } from 'lodash';
import {
	contentTypeJson,
	MachineOperationResponse,
	PowerState,
} from 'App/Activity/Network/ActionsUtil';
import { SessionsListResponse } from 'App/Activity/Network/SessionList';
import { captureSessionResource } from 'App/Activity/ResourceActionHandler';
import {
	Session,
	SessionConnectionState,
	Sessions,
	SessionType,
} from 'App/Activity/ResourceManagerContext';
import { EventType } from 'Components/EventBus/EventType';
import { environment } from 'Environment';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const NOT_AVAILABLE = 'not-available';

const supportedActions: Record<string, boolean> = {
	[EventType.ACTIVITY_ADD_RESOURCES]: true,
	[EventType.ACTIVITY_REMOVE_RESOURCES]: true,
};

export const isEventSupported = (eventType: string): boolean => {
	return !!supportedActions[eventType];
};

export const isAddActivitiesEvent = (eventType: string): boolean => {
	return eventType === EventType.ACTIVITY_ADD_RESOURCES;
};

export const isRemoveActivitiesEvent = (eventType: string): boolean => {
	return eventType === EventType.ACTIVITY_REMOVE_RESOURCES;
};

export const isDisconnectedButNotSuspendedSession = (session: Session) => {
	return (
		session.connectionState === SessionConnectionState.DISCONNECTED &&
		session.machineData?.powerState !== PowerState.SUSPENDED
	);
};

const isOfLocalDevice = (session: Session, localDeviceId: string) => {
	return session.deviceId?.toLowerCase() === localDeviceId?.toLowerCase();
};

const isLocalConnectedSession = (session: Session, deviceId: string) => {
	return !isDisconnectedSession(session) && isOfLocalDevice(session, deviceId);
};

const isRemoteSession = (session: Session, deviceId: string) => {
	return (
		(!isOfLocalDevice(session, deviceId) || isDisconnectedSession(session)) &&
		session.machineData?.powerState !== PowerState.SUSPENDED
	);
};

const isHibernatedSession = (session: Session) => {
	return (
		isDisconnectedSession(session) &&
		session.machineData?.powerState === PowerState.SUSPENDED
	);
};

const isDisconnectedSession = (session: Session) => {
	return session.connectionState === SessionConnectionState.DISCONNECTED;
};

export function isInEnum<T>(value: string, enumeration: T) {
	return Object.values(enumeration).includes(value);
}

export const isRemoteAndNotDisconnected = (session: Session, deviceId: string) => {
	return !isOfLocalDevice(session, deviceId) && !isDisconnectedSession(session);
};

export const filterSessionsByType = (
	response: SessionsListResponse,
	resources: Resource[]
) => {
	const { deviceId } = response;
	const sessions = response.sessions as Session[];
	const localSessions: Session[] = [];
	const remoteSessions: Session[] = [];
	const hibernateSessions: Session[] = [];
	const sortedSessions = sortSessionsByTypeAndTimestamp(sessions);
	const clientDeviceId = environment.nativeCapabilities?.platform?.deviceId;
	sortedSessions.forEach(session => {
		captureSessionResource(session, resources); // TODO: need to handle the FTU vs RTU cases
		if (isLocalConnectedSession(session, clientDeviceId || deviceId)) {
			localSessions.push(session);
		} else if (isRemoteSession(session, clientDeviceId || deviceId)) {
			remoteSessions.push(session);
		} else if (isHibernatedSession(session)) {
			hibernateSessions.push(session);
		}
	});
	return { deviceId, localSessions, remoteSessions, hibernateSessions };
};

export const checkMachineOperationStatus = async (
	statusEndpoint: string,
	continuationToken: string
): Promise<MachineOperationResponse> => {
	let response: MachineOperationResponse;
	if (statusEndpoint !== NOT_AVAILABLE) {
		response = await post<MachineOperationResponse>(
			statusEndpoint,
			JSON.stringify({ continuationToken }),
			contentTypeJson
		);
	}
	return Promise.resolve(response);
};

function mergeSessions(localSessions: Sessions, nativeSessions: Session[]): Session[] {
	const uniqueMap = new Map<string, Session>();
	localSessions.sessions.forEach(session => {
		uniqueMap.set(session.sessionId, session);
	});

	nativeSessions.forEach(session => {
		uniqueMap.set(session.sessionId, session);
	});
	return Array.from(uniqueMap.values());
}

export function mergeAndSortSessions(localSessions: Sessions, nativeSessions: Session[]) {
	const mergedSessions = mergeSessions(localSessions, nativeSessions);
	return sortSessionsByTypeAndTimestamp(mergedSessions);
}

export const sortSessionsByTypeAndTimestamp = (sessions: Session[]): Session[] => {
	return sessions.sort((firstSession, secondSession) => {
		if (firstSession.userSessionType !== secondSession.userSessionType) {
			return firstSession.userSessionType === SessionType.DESKTOP ? -1 : 1;
		}
		const firstDate: Date = new Date(firstSession.sessionStartTime);
		const secondDate: Date = new Date(secondSession.sessionStartTime);
		return firstDate.getTime() - secondDate.getTime();
	});
};

export function mergeNativeAndLocalSessions(
	nativeSessions: Session[],
	localSessions: Session[]
) {
	return nativeSessions.length > 0
		? nativeSessions.map(nativeSession => {
				if (nativeSession.userSessionType === SessionType.DESKTOP) {
					const session = localSessions.find(localSession => {
						const firstApplication = first(localSession.applications);
						return firstApplication && firstApplication.id === nativeSession.sessionId;
					});
					if (session) {
						return { ...session, localSession: nativeSession.localSession };
					}
				}
				return nativeSession;
		  })
		: localSessions;
}

export const handleIconClick = (event: React.MouseEvent) => {
	event.stopPropagation();
};
