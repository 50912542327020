import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { camelCase, head } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { horizontalAppRoutes } from 'App/appRoutes';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { LeftNavRoute } from 'Router/ReactRoute';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { GeneralUserEventPayload } from 'Workspace/TelemetryEvents/generalEvent/GeneralUserEventPayload';
import { useUserContext } from 'Workspace/UserContext';
import { isHorizontalTabRoute } from './checkHorizontalTabRoute';

const StyledTabs = styled(Tabs)`
	padding: 32px 64px 0 64px;
	background-color: inherit;
`;

export const Navigation = () => {
	const location = useLocation();
	const { workspaceConfiguration } = useConfigurationContext();
	const history = useHistory();
	const userContext = useUserContext();
	const resources = useLoadableResourceContext();
	const [activeTab, setActiveTab] = useState('');
	const changeActiveTab = (tab: string) => {
		trackAnalyticsEvent(
			GeneralUserEventPayload.screenSwitch(camelCase(activeTab), camelCase(tab))
		);
		history.push(tab);
		setActiveTab(tab);
	};
	useEffect(() => {
		setActiveTab(`/${head(history.location.pathname.slice(1).split('/'))}`);
	}, [history.location]);

	function isRouteAvailable(route: LeftNavRoute): boolean {
		return (
			route.isAvailable({
				location,
				workspaceConfiguration,
				userContext,
				resourceContext: resources.value,
			}) && !!route.component
		);
	}

	function transformRouteToTab(route: LeftNavRoute) {
		const navigationLink = route.getNavLinkProps(workspaceConfiguration);
		return (
			<Tab
				label={navigationLink.title}
				value={head(route.paths)}
				key={navigationLink.title}
				id={navigationLink.analyticsID}
			/>
		);
	}

	const availableAppRoutes = horizontalAppRoutes.filter(route => isRouteAvailable(route));

	const navigationNeeded =
		availableAppRoutes.length > 1 && isHorizontalTabRoute(location);

	return (
		navigationNeeded && (
			<StyledTabs activeTab={activeTab} onChange={changeActiveTab}>
				{availableAppRoutes.map(route => transformRouteToTab(route))}
			</StyledTabs>
		)
	);
};
