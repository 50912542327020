import * as React from 'react';
import { IntegrationCapability, RouteContext } from '@citrite/workspace-ui-platform';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { appRoutes } from 'App/appRoutes';
import { IntegrationRouteComponent } from 'App/IntegrationRouteComponent';
import { RerouteHandler } from 'App/RerouteHandler';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useIntegrations } from 'Integrations/useIntegrations';
import { LeftNavCallbacks, LeftNavRoute } from 'Router/ReactRoute';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';
import { AppLoader } from './AppLoader';

export type RouteLoaderProps = LeftNavCallbacks & RouteContext;

interface RouteComponentProps extends RouteLoaderProps {
	route: LeftNavRoute;
}

function RouteComponent(props: RouteComponentProps) {
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();
	const resources = useLoadableResourceContext();
	const { workspaceConfiguration } = useConfigurationContext();
	const userContext = useUserContext();
	const isLoading = resources.loading;

	if (isLoading) {
		return <AppLoader />;
	}

	return (
		<props.route.component
			{...props}
			history={history}
			location={location}
			match={match}
			workspaceConfiguration={workspaceConfiguration}
			userContext={userContext}
			resourceContext={resources.value}
			setMobileMastheadTitle={props.setMobileMastheadTitle}
			setRefreshPageFn={props.setRefreshPageFn}
			clearRefreshPageFn={props.clearRefreshPageFn}
		/>
	);
}

export function RouteLoader(props: RouteLoaderProps) {
	const location = useLocation();
	const integrations = useIntegrations();
	const { workspaceConfiguration } = useConfigurationContext();
	const userContext = useUserContext();
	const resources = useLoadableResourceContext();

	const availableRoutes = appRoutes.filter(
		route =>
			route.isAvailable({
				location,
				workspaceConfiguration,
				userContext,
				resourceContext: resources.value,
			}) && route.component
	);

	return (
		<Switch>
			{availableRoutes.map(route => (
				<Route
					key={route.key}
					path={route.paths}
					render={() => <RouteComponent key={route.key} route={route} {...props} />}
				/>
			))}
			{integrations
				.resolveByCapability(IntegrationCapability.route)
				.filter(r => r.registration?.metadata?.path)
				.map(integrationRoute => (
					<Route
						key={integrationRoute.moduleKey}
						path={integrationRoute.registration.metadata.path}
						render={() => (
							<IntegrationRouteComponent
								integration={integrationRoute}
								setMobileMastheadTitle={props.setMobileMastheadTitle}
							/>
						)}
					/>
				))}
			<Route component={RerouteHandler} />
		</Switch>
	);
}
