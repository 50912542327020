import { StyleSheet } from 'aphrodite';
import { layout, palette, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	stateContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		width: '100%',
		margin: 'auto',
	},
	stateContainerLineArt: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		width: '100%',
		paddingTop: '64px',
	},
	stateImage: {
		maxHeight: '500px',
		maxWidth: '500px',
		width: '100%',
	},
	stateHeading: {
		fontSize: '24px',
		fontWeight: typography.weights.semiBold,
		lineHeight: '40px',
		margin: '0px 20px',
		wordBreak: 'break-word',
		maxWidth: '100%',
	},

	stateHeadingLineArt: {
		fontSize: '16px',
		fontWeight: typography.weights.semiBold,
		lineHeight: '24px',
		margin: '0px 20px',
		wordBreak: 'break-word',
		maxWidth: '100%',
	},
	stateDescription: {
		color: palette.grey700,
		fontSize: '16px',
		wordBreak: 'break-word',
		maxWidth: '100%',
		margin: layout.mediumSpace,
	},
	stateDescriptionLineArt: {
		color: palette.grey700,
		fontSize: '14px',
		lineHeight: '24px',
		fontWeight: typography.weights.regular,
		fontStyle: 'normal',
	},
	bottomButton: {
		marginTop: layout.smallSpace,
	},
	lineArtLink: {
		lineHeight: '16px',
	},
});
