import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

export const createResourceSessionCasReporter = (
	transactionId: string,
	resourceId: Resource['id'],
	userId: string
) => {
	const preparePayload = (type: EventType, message: string, code: string) => ({
		sessionLaunch: 'yes',
		component: 'WSUI',
		time: new Date().toISOString(),
		destinationComponent: 'WSP',
		transactionId,
		userId,
		resourceId,
		type,
		message,
		code,
	});
	return {
		getStartEventPayload: () => ({
			sessionLaunch: 'yes',
			transactionId,
			component: 'WSUI',
			userId,
			time: new Date().toISOString(),
			type: EventType.Start,
			message: 'Resource launch started',
			code: 'WSUI_Resource_Launch_Started',
			destinationComponent: 'WSP',
			resourceId,
		}),
		getEndEventPayload: () => ({
			sessionLaunch: 'yes',
			transactionId,
			component: 'WSUI',
			userId,
			time: new Date().toISOString(),
			type: EventType.End,
			message: 'Resource launch ended',
			code: 'WSUI_Resource_Launch_Ended',
			destinationComponent: 'WSP',
			resourceId,
		}),
		getErrorEventPayload: () => ({
			sessionLaunch: 'yes',
			transactionId,
			component: 'WSUI',
			userId,
			time: new Date().toISOString(),
			type: EventType.Error,
			message: 'Resource launch failed',
			code: 'WSUI_Resource_Launch_Failed',
			destinationComponent: 'WSP',
			resourceId,
		}),
		getCancelEventPayload: () =>
			preparePayload(
				EventType.Cancel,
				'Resource launch cancelled',
				'WSUI_Resource_Launch_Cancelled'
			),
	};
};
