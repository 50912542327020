import { createScopedInstance, SUPPORTED_LANGUAGES } from '@citrite/translate';
import { languages } from '../locales';

const traditionalChineseLanguageCode = 'zh-TW';
const finnishLanguageCode = 'fi';

export const { t } = createScopedInstance({
	resources: languages,
	namespace: 'platform-react',
	// had to add Traditional Chinese(zh-TW) explicitly, as it does not have default support by @citrite/translate
	supportedLngs: [
		...SUPPORTED_LANGUAGES,
		finnishLanguageCode,
		traditionalChineseLanguageCode,
	],
});
