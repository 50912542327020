import * as React from 'react';
import { css } from 'aphrodite';
import {
	Button,
	FavoriteLockedIcon,
	FavoriteOffIcon,
	FavoriteOnIcon,
	LoadingIconPrimary,
} from '@citrite/web-ui-component';
import { t } from '../../utils/translate';
import { styles } from './FavoriteAction.styles';

export interface FavoriteActionProps {
	onChange?: () => Promise<void>;
	isFavorited: boolean;
	disabled?: boolean;
	visible: boolean;
}

interface State {
	isPending: boolean;
}

export class FavoriteAction extends React.Component<FavoriteActionProps, State> {
	public state = {
		isPending: false,
	};

	private toggleFavorite = async () => {
		if (this.props.onChange) {
			this.setState({ isPending: true });
			await this.props.onChange();
			this.setState({ isPending: false });
		}
	};

	public render() {
		const Icon = this.props.isFavorited ? FavoriteOnIcon : FavoriteOffIcon;

		if (this.props.disabled && this.props.isFavorited) {
			return <FavoriteLockedIcon />;
		}
		if (this.state.isPending) {
			return <LoadingIconPrimary size={16} />;
		}

		return (
			<div className={css(styles.container)}>
				{this.props.visible && (
					<Button.Wrapper
						onClick={!this.props.disabled ? this.toggleFavorite : null}
						title={
							this.props.isFavorited
								? t('platform-react:remove_favorite')
								: t('platform-react:add_favorite')
						}
					>
						<Icon />
					</Button.Wrapper>
				)}
			</div>
		);
	}
}
