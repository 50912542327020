import * as React from 'react';
import { ButtonProps, notifyError } from '@citrite/web-ui-component';
import { Action } from './types';

export interface ActionButtonProps<T = any, R = any> {
	action: Action<T, R>;
	ButtonType: React.ComponentType<ButtonProps>;
	appTitle: string;
	handleButtonInProgress: (isActionStillInProgress?: boolean) => void;
	inProgress: boolean;
	disabled: boolean;
	handleResults?: (action: Action<T, R>, result: R) => void;
	formatErrorMessage: (appTitle: string, response: any) => React.ReactElement;
}

export function ActionButton<T, R>(props: ActionButtonProps<T, R>) {
	const dismissFailureToast = React.useRef(null);
	const {
		action,
		appTitle,
		handleButtonInProgress,
		ButtonType,
		inProgress,
		disabled,
		handleResults,
		formatErrorMessage,
	} = props;
	function performAction(e: React.MouseEvent): Promise<void> {
		e.stopPropagation();
		handleButtonInProgress();
		return action
			.execute()
			.then(result => {
				handleButtonInProgress(false);
				handleResults?.(action, result);
			})
			.catch(response => {
				handleButtonInProgress(false);
				if (dismissFailureToast.current) {
					dismissFailureToast.current();
				}
				dismissFailureToast.current = notifyError(formatErrorMessage(appTitle, response));
			});
	}

	return (
		<ButtonType active={inProgress} onClick={performAction} disabled={disabled}>
			{action.label}
		</ButtonType>
	);
}
