interface Weight {
	weight?: number;
}

export const sortByWeight = (a?: Weight, b?: Weight) => {
	const aWeight = a?.weight == null ? Number.POSITIVE_INFINITY : a.weight;
	const bWeight = b?.weight == null ? Number.POSITIVE_INFINITY : b.weight;
	if (aWeight === bWeight) {
		return 0;
	}
	return aWeight < bWeight ? -1 : 1;
};
