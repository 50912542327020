import {
	IntegrationManifest,
	ScopedContainer,
	ScopedPlatformDependencies,
} from '@citrite/workspace-ui-platform';
import { scopedCacheFactory } from 'IntegrationDependencies/CacheFactory';
import { createScopedLogger } from 'IntegrationDependencies/ClientLoggerFactory';
import { scopedEndpointSupplierFactory } from 'IntegrationDependencies/EndpointSupplierFactory';
import { InternalPlatformDependencies } from 'IntegrationDependencies/InternalDependencies';
import { ssoProxyClientFactory } from 'IntegrationDependencies/SsoProxyClientFactory';
import { container } from 'Workspace/DependencyManagement';

export function createScopedContainer(integration: IntegrationManifest) {
	const scoped: ScopedContainer = container
		.createChildContainer()
		.registerSingleton(InternalPlatformDependencies.ScopedIntegration, integration)
		.registerSingletonWithBuilder(
			ScopedPlatformDependencies.SsoProxyClient,
			ssoProxyClientFactory
		)
		.registerSingletonWithBuilder(
			ScopedPlatformDependencies.EndpointSupplier,
			scopedEndpointSupplierFactory
		)
		.registerSingletonWithBuilder(ScopedPlatformDependencies.Logger, createScopedLogger)
		.registerSingletonWithBuilder(
			ScopedPlatformDependencies.CacheFactory,
			scopedCacheFactory
		);

	return scoped;
}
