import * as React from 'react';
import { IconProps } from '@citrite/web-ui-component';
import { createCapability } from '../capability';

/**
 * Adds links to the left nav menu.
 *
 * Registration: {@link IntegrationCapability | IntegrationCapability.leftNavigation.primaryNavLink}
 *
 * @example
 * {
 *   title: () => t('some.link.title.key'),
 *   path: '/some/route',
 *   isVisible: () => workspaceUser.get().isIWSEnabled,
 * }
 * @category Integration Capability
 */
export interface LeftNavCapability extends BaseLink {
	/** Controls where in the left nav the link will be inserted. */
	weight?: number;
	icon?: React.FC<IconProps>;
	secondaryIcon?: React.FC<React.AllHTMLAttributes<HTMLSpanElement>>;
	mobileNativeActiveIcon?: React.FC<React.AllHTMLAttributes<HTMLSpanElement>>;
	/** Adds a submenu to your link in the left nav.  */
	childLinks?: BaseLink[];
}

interface BaseLink {
	title: () => string;
	/**
	 * Any valid URL path.
	 * @example '/my/integration/route'
	 */
	path: string;
	/**
	 * Show or hide your link.
	 * @default true
	 */
	isVisible?: () => boolean;
	/** Used to mark a link click event in the browser's performance entry buffer. */
	performanceMark?: string;
}

export const LeftNavCapabilityToken = createCapability<LeftNavCapability>(
	'wsui_left_nav_capability'
);
