import { platform } from 'Environment/launchResource/constants';
import { getFromLocalStorage } from 'javascript/sf/Storage';

const mobileDevices = [platform.android, platform.ios, platform.windowsMobile];
const tabletDevices = [platform.ipadOS, platform.androidTablet];

const urlParams = window.location.search;

let currentPlatform = platform.other;

if (urlParams && urlParams.indexOf('ios') >= 0) {
	if (navigator.userAgent.match(/(iPhone|iPod)/i)) {
		currentPlatform = platform.ios;
	} else {
		currentPlatform = platform.ipadOS;
	}
} else if (urlParams && urlParams.indexOf('android') >= 0) {
	if (navigator.userAgent.match(/(Mobile)/i)) {
		currentPlatform = platform.android;
	} else {
		currentPlatform = platform.androidTablet;
	}
} else {
	// User agent sniffing to identify the platform
	// Note that the order of these checks is significant - for example, Android must come before Linux because
	// Android devices also identify themselves as Linux
	// Android devices also identify themselves as Linux,
	if (navigator.userAgent.match(/(widgetUI)/i)) {
		// Widget from native devices will have "widgetUI" in the user agent.
		// Irrespective of device we should load widget UI if user agent has this string,
		currentPlatform = platform.nativeWidget;
	} else if (navigator.userAgent.match(/(Android)\s+([\d.]+)/)) {
		if (navigator.userAgent.match(/(Mobile)/i)) {
			currentPlatform = platform.android;
		} else {
			currentPlatform = platform.androidTablet;
		}
	} else if (navigator.userAgent.match(/(iPhone|iPod)/i)) {
		// iOS devices do not contain the OS name in the user agent, so we must check specifically for device names
		currentPlatform = platform.ios;
	} else if (
		navigator.userAgent.match(/(iPad)/i) ||
		!!getFromLocalStorage('userIsUsingAnIpad')
	) {
		// iOS devices do not contain the OS name in the user agent, so we must check specifically for device names
		currentPlatform = platform.ipadOS;
	} else if (navigator.userAgent.match(/(Windows Phone)/i)) {
		currentPlatform = platform.windowsMobile;
	} else if (navigator.userAgent.match(/(windows)/i)) {
		currentPlatform = platform.windows;
	} else if (navigator.userAgent.match(/(mac os x)/i)) {
		currentPlatform = platform.mac;
	} else if (navigator.userAgent.match(/(CrOS)/i)) {
		currentPlatform = platform.chromeOS;
	} else if (navigator.userAgent.match(/(linux)/i)) {
		currentPlatform = platform.linux;
	}
}

function isMobileOrTabletDevice() {
	const desktopOverrideFlag = urlParams && urlParams.indexOf('-desktop') !== -1;
	return (
		!desktopOverrideFlag &&
		(mobileDevices.includes(currentPlatform) || tabletDevices.includes(currentPlatform))
	);
}

function isDesktop() {
	const desktopOverrideFlag = urlParams && urlParams.indexOf('-desktop') !== -1;
	return (
		desktopOverrideFlag ||
		(!mobileDevices.includes(currentPlatform) && !tabletDevices.includes(currentPlatform))
	);
}

function isMobileDevice() {
	const desktopOverrideFlag = urlParams && urlParams.indexOf('-desktop') !== -1;
	return !desktopOverrideFlag && mobileDevices.includes(currentPlatform);
}

function getCurrentPlatform(): platform {
	return currentPlatform;
}

function isSafari() {
	return (
		navigator.userAgent.match(/Safari/i) &&
		!navigator.userAgent.match(/Chrome/i) &&
		!navigator.userAgent.match(/Chromium/i)
	);
}

function isCitrixChromeApp() {
	return !!navigator.userAgent.match(/(CitrixChromeApp)/i);
}

function isChrome() {
	return !!navigator.userAgent.match(/(Chrome)/i);
}

function isChromeOS() {
	return currentPlatform === platform.chromeOS;
}

function isChromeAppOrChromeOS(): boolean {
	return isCitrixChromeApp() || isChromeOS();
}

function isWindowsPlatform() {
	return currentPlatform === platform.windows;
}

function isLinux() {
	return currentPlatform === platform.linux;
}

function isMac() {
	return currentPlatform === platform.mac;
}

export {
	currentPlatform,
	urlParams,
	mobileDevices,
	tabletDevices,
	isMobileOrTabletDevice,
	isMobileDevice,
	isDesktop,
	getCurrentPlatform,
	isCitrixChromeApp,
	isChrome,
	isChromeOS,
	isChromeAppOrChromeOS,
	isWindowsPlatform,
	isLinux,
	isMac,
	isSafari,
};
