import * as React from 'react';
import styled from '@emotion/styled';
import { ApplicationCard } from 'App/Activity/components/ApplicationCard';
import { DesktopCard } from 'App/Activity/components/DesktopCard';
import { Session, SessionType } from 'App/Activity/ResourceManagerContext';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
`;

export interface SessionCardProps {
	session: Session;
}

export const SessionCard = ({ session }: SessionCardProps) => {
	return (
		<StyledDiv>
			{session.userSessionType === SessionType.DESKTOP ? (
				<DesktopCard session={session} />
			) : (
				session.applications.length > 0 && <ApplicationCard session={session} />
			)}
		</StyledDiv>
	);
};
