import { StyleSheet } from 'aphrodite';
import { layout, palette, styleUtils, typography } from '@citrite/web-ui-component';
import { bottomNavItemIconSize, overflowMenuItemHeight } from 'App/BottomNav/dimensions';

const slideUpAnimation = {
	'0%': {
		transform: 'translateY(100%)',
	},
	'100%': {
		transform: 'translateY(0px)',
	},
};

const slideDownAnimation = {
	'0%': {
		transform: 'translateY(0px)',
	},
	'100%': {
		transform: 'translateY(100%)',
	},
};

export const styles = StyleSheet.create({
	modal: {
		minHeight: 'unset',
		maxWidth: 'unset',
		width: '100%',
	},
	overflowMenu: {
		width: '100%',
		position: 'fixed',
		backgroundColor: 'white',
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		overflow: 'hidden',
		paddingTop: layout.smallSpace,
		bottom: 0,
		animationName: slideUpAnimation as any,
		animationDuration: '500ms',
		animationIterationCount: '1',
	},
	slideDown: {
		animationName: slideDownAnimation as any,
	},
	overflowMenuItem: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		marginTop: layout.smallSpace,
		width: '100vw',
		flex: 1,
		alignItems: 'center',
		backgroundColor: 'white',
		height: overflowMenuItemHeight + 'px',
	},
	icon: {
		marginLeft: layout.mediumLargeSpace,
		width: bottomNavItemIconSize + 'px',
		height: bottomNavItemIconSize + 'px',
	},
	title: {
		...styleUtils.ellipsis,
		marginLeft: layout.mediumLargeSpace,
		color: palette.grey900,
		fontWeight: typography.weights.regular,
		fontSize: typography.sizes.base,
	},
	linkActive: {
		textDecoration: 'none',
	},
});
