import React from 'react';
import {
	IntegrationCapability,
	LoadableCapability,
	WidgetComponentCapability,
	WidgetComponentCapabilityMetadata,
} from '@citrite/workspace-ui-platform';
import {
	getRegistryWidgets,
	WidgetDefinition,
	WidgetProvider,
} from 'App/Widgets/widgetRegistry';
import { useIntegrations } from 'Integrations/useIntegrations';

export const useWidgetCatalog: () => WidgetCatalog = () => {
	const integrations = useIntegrations();
	const widgets = integrations.resolveByCapability(
		IntegrationCapability.UNSTABLE_widgetComponent
	);

	const getEnabledWidgets = (): WidgetCatalogItem[] => {
		return getRegistryWidgets()
			.filter(definition => !definition.isEnabled || definition.isEnabled(integrations))
			.map(definition => mapCatalogEntryToItem(definition, widgets))
			.filter(w => !!w.loadableWidget || !!w.Component);
	};

	return {
		getWidget: (widgetId: string) => {
			const widgetInRegistry = getRegistryWidgets().find(
				widget => widget.id === widgetId
			);
			if (
				!widgetInRegistry ||
				(widgetInRegistry.isEnabled && !widgetInRegistry.isEnabled(integrations))
			) {
				return undefined;
			}

			const widgetItem = mapCatalogEntryToItem(widgetInRegistry, widgets);
			if (!widgetItem.loadableWidget && !widgetItem.Component) {
				return undefined;
			}

			return widgetItem;
		},

		getAllWidgets: getEnabledWidgets,
	};
};

export type LoadableWidget = LoadableCapability<
	WidgetComponentCapability,
	any,
	WidgetComponentCapabilityMetadata
>;

export interface WidgetCatalogItem {
	id: string;
	loadableWidget?: LoadableWidget;
	title: () => string;
	description: () => string;
	icon: React.FC;
	source: string;
	isNew: boolean;
	Component?: React.ComponentType;
}

export type WidgetCatalog = {
	getWidget: (widgetId: string) => WidgetCatalogItem;
	getAllWidgets: () => WidgetCatalogItem[];
};

const getWidgetSourceFromProvider = (provider: WidgetProvider) => {
	if (provider === WidgetProvider.core) {
		return 'Citrix';
	}
	return null;
};

const getIsNew = (newUntil?: Date) => {
	if (!newUntil) {
		return false;
	}
	if (newUntil.getTime() > Date.now()) {
		return true;
	} else {
		return false;
	}
};

function mapCatalogEntryToItem(
	definition: WidgetDefinition,
	ctxMfeWidgets: LoadableWidget[]
): WidgetCatalogItem {
	return {
		id: definition.id,
		title: definition.title,
		description: definition.description,
		icon: definition.icon,
		isNew: getIsNew(definition.newUntil),
		source: getWidgetSourceFromProvider(definition.provider),
		loadableWidget: ctxMfeWidgets.find(
			widget =>
				widget.integrationId === definition.mfeName &&
				widget.registration.moduleKey === definition.mfeRenderer
		),
		Component: definition.Component,
	};
}
