import { createForBrowser as createBrowserSSOProxyClient } from 'ssoProxyClient';
import { Environment } from '../';

export function configureSimulatedNativeEnvironment(
	env: Environment,
	global: any = window
) {
	global.external.dispatchEventToNative = (eventJSON: string) => {
		// eslint-disable-next-line no-console
		console.log(
			'Event dispatched through Simulated Native Bridge',
			JSON.parse(eventJSON)
		);
	};
	global.external.getStoreDetails = (callbackId: string) => {
		global.ctxs_getStoreDetails_complete(callbackId, {});
	};
	global.external.sync_getCapabilities = () =>
		JSON.stringify({
			functions: ['dispatchEventToNative'],
			platform: {},
		});

	env.createSSOProxyClient = createBrowserSSOProxyClient;
	env.initializeSync = true;
}
