import { RequestInterceptors } from '@citrite/http';

export const interceptor: RequestInterceptors = {
	prepareRequest: config => {
		return {
			...config,
			requireNativeXHR: true,
		};
	},
};
