import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { clientType, getClientType, getClientVersion } from 'Environment/getClientType';
import { platform } from 'Environment/launchResource/constants';
import { getCurrentPlatform, isNativeClient } from 'Environment/launchResource/device';

//allow-unused-export: for unit testing
export const appProtectionMinClientVersion = new Map<platform, string>([
	[platform.windows, '19.12.0.119'],
	[platform.mac, '20.01.0.14'],
	[platform.linux, '21.08.0.40'],
]);

//allow-unused-export: for unit testing
export function isVersionOlderOrEqualTo(
	minVersion: string,
	currVersion: string
): boolean {
	if (!minVersion || !currVersion) {
		return false;
	}

	//Make this function more robust by catching any exceptions
	try {
		const minVersionParts = minVersion.split('.').map(Number);
		const currVersionParts = currVersion.split('.').map(Number);

		for (let i = 0; i < Math.max(minVersionParts.length, currVersionParts.length); i++) {
			const minPart = minVersionParts[i] || 0;
			const currPart = currVersionParts[i] || 0;

			//if any part of the current version is less than the minimum version, return false
			if (currPart < minPart) {
				return false;
			}
			if (currPart > minPart) {
				return true;
			}
		}

		return true;
	} catch (e) {
		return false;
	}
}

//allow-unused-export: for unit testing
export function isAppProtectEnabledByOnPremAdmin(
	workspaceConfiguration: WorkspaceConfiguration
) {
	return workspaceConfiguration?.appProtection?.enabled?.toLowerCase() === 'on';
}

//allow-unused-export: for unit testing
export function clientSupportsMinVersionForAppProtection() {
	const minClientVersionForAppProtection = appProtectionMinClientVersion.get(
		getCurrentPlatform()
	);
	const currentClientVersion = getClientVersion();
	return isVersionOlderOrEqualTo(minClientVersionForAppProtection, currentClientVersion);
}

//This code is direct port of the code from PurpleUI
export function shouldSupportAppProtectionOnPrem(
	workspaceConfiguration: WorkspaceConfiguration
) {
	//Native apps will add the X-Citrix-AppProtection-Capable them-self
	if (isNativeClient() || !isAppProtectEnabledByOnPremAdmin(workspaceConfiguration)) {
		return false;
	}

	// Return true if the client is a browser extension without version check.
	// This is because the browser extension was supported by clients after App Protection.
	// feature was introduced. + getting version client from browser extension needs an extra getStoreHealth call
	// which can slow down the wsui startup time.
	const currentClientType = getClientType();
	if (currentClientType === clientType.browserextension) {
		//This is only available on desktop platforms so need to check current platform
		return true;
	}

	return (
		currentClientType === clientType.native && clientSupportsMinVersionForAppProtection()
	);
}
