import { IUserCommandManager } from 'UserCommand/types';

class UserCommandManager implements IUserCommandManager {
	private keyHandlerMap: Map<string, (() => void)[]>;
	private static _userCommandManager: UserCommandManager;

	private constructor() {
		this.keyHandlerMap = new Map();
		this.initializeUserCommands();
	}

	public static getInstance = () => {
		if (!UserCommandManager._userCommandManager) {
			UserCommandManager._userCommandManager = new UserCommandManager();
		}

		return UserCommandManager._userCommandManager;
	};

	public register = (key: string, handler: () => void) => {
		if (!this.keyHandlerMap.has(key)) {
			this.keyHandlerMap.set(key, []);
		}

		this.keyHandlerMap.get(key).push(handler);
	};

	public unRegister = (key: string, handler: () => void) => {
		if (this.keyHandlerMap.has(key) && this.keyHandlerMap.get(key).includes(handler)) {
			const handlerIndex = this.keyHandlerMap.get(key).indexOf(handler);
			this.keyHandlerMap.get(key).splice(handlerIndex, 1);
		}
	};

	private initializeUserCommands = () => {
		document.addEventListener('keydown', (event: KeyboardEvent) => {
			if (event.ctrlKey && event.shiftKey && this.keyHandlerMap.has(event.key)) {
				const handlers = this.keyHandlerMap.get(event.key);
				handlers.forEach(handler => handler());
			}
		});
	};
}

export const userCommandManager = UserCommandManager.getInstance();
