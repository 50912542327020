import { StyleSheet } from 'aphrodite';
import { breakpoints, typography } from '@citrite/web-ui-component';

export const styles = StyleSheet.create({
	title: {
		fontSize: typography.sizes.xl4,
		fontWeight: typography.weights.light,
		margin: '10px 0',
		[breakpoints.onMobile]: {
			display: 'none',
		},
	},
});
