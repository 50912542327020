import { feedSort } from 'userNotifications/FeedCard';
import { LoadNewFeedCardsEvent } from 'Workspace/TelemetryEvents/notificationFeed/events';
import { EventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { SDKToSortOrderMap } from './sortOrderMap';

export function createLoadNewFeedCardsEvent(sort: feedSort): LoadNewFeedCardsEvent {
	return {
		type: EventType.NotificationFeed_LoadNewFeedCards,
		sortOrder: SDKToSortOrderMap.get(sort),
	};
}
