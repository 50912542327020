import {
	createIntegration,
	IntegrationCapability,
	// eslint-disable-next-line @citrite/workspace-ui/no-platform-dependency-access-under-src
	workspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { desktopsManifestPrivateSymbol } from 'appsAndDesktops/desktops/privateScope';
import { performance } from 'Components/performance';
import { UIProperty } from 'Environment/hasUIProperty';

export const desktops = createIntegration({
	id: 'desktops',
	cacheVersion: '1',
	privateSymbol: desktopsManifestPrivateSymbol,
	capabilities: [
		IntegrationCapability.UNSTABLE_widgetComponent.register('desktopsSmall', {
			performanceKey: performance.events.resourcesWidget,
		}),
		IntegrationCapability.UNSTABLE_widgetComponent.register('desktopsLarge', {
			performanceKey: performance.events.resourcesWidget,
		}),
	],
	modulesResolver: {
		desktopsSmall: () =>
			import(
				/* webpackChunkName: "DesktopsWidget" */ 'App/NotificationHome/ResourcesWidgets'
			).then(({ DesktopsWidget }) => ({ Component: DesktopsWidget })),
		desktopsLarge: () =>
			import(
				/* webpackChunkName: "DesktopsWidget" */ 'App/Home/Widgets/Desktops/Desktops'
			).then(({ DesktopsWidget }) => ({ Component: DesktopsWidget })),
	},
	isEnabled: () => workspaceConfiguration.hasUIProperty(UIProperty.Desktops),
});
