import { EndpointsService, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	getFromInteractiveStorage,
	installLazyGetter,
	installToolFunction,
	setInInteractiveStorage,
} from 'javascript/interactiveTools';

type ConfigurationOverrides = {
	addedCanaries: { name: string }[];
	removedCanaries: string[];
	addedUIProperties: { name: string; value: string }[];
	removedUIProperties: string[];
	addedEndpointServices: EndpointsService[];
	removedEndpointServices: string[];
};

const configurationOverridesKey = 'interactiveConfigurationOverrides';

function getInteractiveConfigurationOverrides(): ConfigurationOverrides {
	return (
		getFromInteractiveStorage<ConfigurationOverrides>(configurationOverridesKey) ?? {
			addedCanaries: [],
			removedCanaries: [],
			addedUIProperties: [],
			removedUIProperties: [],
			addedEndpointServices: [],
			removedEndpointServices: [],
		}
	);
}

export function installInteractiveConfigurationTooling(
	onUpdate: () => void,
	getConfiguration: () => WorkspaceConfiguration
) {
	installToolFunction('addFeatureCanary', (...names: string[]) => {
		updateConfigurationOverrides(config =>
			config.addedCanaries.push(...names.map(name => ({ name })))
		);
		onUpdate();
	});
	installToolFunction('removeFeatureCanary', (...names: string[]) => {
		updateConfigurationOverrides(config => config.removedCanaries.push(...names));
		onUpdate();
	});
	installLazyGetter('featureCanaryNames', () => getCanariesEnum());

	installToolFunction('addUIProperty', (name: string, value = 'true') => {
		updateConfigurationOverrides(config =>
			config.addedUIProperties.push({ name, value })
		);
		onUpdate();
	});
	installToolFunction('removeUIProperty', (...names: string[]) => {
		updateConfigurationOverrides(config => config.removedUIProperties.push(...names));
		onUpdate();
	});
	installLazyGetter('UIPropertyNames', () =>
		asEnum(getConfiguration().uiProperties.property.map(prop => prop.name))
	);

	installToolFunction(
		'addEndpointService',
		(name: string, discoveryUrl = 'https://doesnotexist.fakeurl/endpoints') => {
			updateConfigurationOverrides(config =>
				config.addedEndpointServices.push({ name, discoveryUrl })
			);
			onUpdate();
		}
	);
	installToolFunction('removeEndpointService', (...names: string[]) => {
		updateConfigurationOverrides(config => config.removedEndpointServices.push(...names));
		onUpdate();
	});
	installLazyGetter('endpointServiceNames', () =>
		asEnum(getConfiguration().endpointsServices.endpointsService.map(prop => prop.name))
	);

	installToolFunction('resetCustomerConfiguration', () => {
		setInInteractiveStorage(configurationOverridesKey, null);
		onUpdate();
	});

	const registeredCanaries = Object.entries(FeatureFlag);
	// Note: the purpose of this function is to supply completions for dev tools consoles,
	// which Chrome has a very short timeout for. This is therefore optimized for performance;
	// babel's object spread syntax polyfill, for example, is too slow and kills completions
	// for a lazy accessor
	function getCanariesEnum() {
		const lookup: Record<string, string> = Object.create(null);
		const activeCanaries = getConfiguration().featureCanaries.canary;
		for (let index = 0; index < activeCanaries.length; index++) {
			const canary = activeCanaries[index].name;
			lookup[canary] = canary;
		}

		for (let index = 0; index < registeredCanaries.length; index++) {
			const canary = registeredCanaries[index];
			lookup[canary[0]] = canary[1];
		}

		return lookup;
	}
}

function asEnum(names: string[]) {
	const result = {};
	for (const name of names) {
		result[name] = name;
	}

	return result;
}

function updateConfigurationOverrides(
	updater: (overrides: ConfigurationOverrides) => void
) {
	const overrides = getInteractiveConfigurationOverrides();
	updater(overrides);
	setInInteractiveStorage(configurationOverridesKey, overrides);
}

export function applyInteractiveConfigurationOverrides(
	serverConfiguration: WorkspaceConfiguration
): WorkspaceConfiguration {
	const overrides = getInteractiveConfigurationOverrides();

	const effectiveCanaries = [
		...(serverConfiguration.featureCanaries?.canary || []),
		...overrides.addedCanaries,
	].filter(canary => !overrides.removedCanaries.some(removed => canary.name === removed));

	const effectiveUIProperties = [
		...(serverConfiguration.uiProperties?.property || []),
		...overrides.addedUIProperties,
	].filter(
		property => !overrides.removedUIProperties.some(removed => property.name === removed)
	);

	const effectiveEndpointServices = [
		...(serverConfiguration.endpointsServices?.endpointsService || []),
		...overrides.addedEndpointServices,
	].filter(
		endpoint =>
			!overrides.removedEndpointServices.some(removed => endpoint.name === removed)
	);

	return {
		...serverConfiguration,
		featureCanaries: { canary: effectiveCanaries },
		uiProperties: { property: effectiveUIProperties },
		endpointsServices: { endpointsService: effectiveEndpointServices },
	};
}
