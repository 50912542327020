import {
	CacheBucketSettings,
	CacheFactory,
	EncryptedCacheBucket,
	EnhancedCacheBucketSettings,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { withEnhancedCaching } from 'Workspace/Cache/withEnhancedCaching';
import { UserDetails, UserDetailsLoader } from 'Workspace/UserContext';
import { createCacheBucket } from './createCacheBucket';

export const createCacheFactory = (
	config: WorkspaceConfiguration,
	getUserDetails: () => [UserDetails, UserDetailsLoader]
): CacheFactory => {
	return {
		createEncryptedCacheBucket: (
			bucketId: string,
			settings: EnhancedCacheBucketSettings = {}
		) => {
			let bucket: EncryptedCacheBucket = createCacheBucket({
				bucketId,
				getUserDetails,
				perUser: true,
				perSession: settings.perSession,
			});

			if (settings.requiresAdminConsentToCache) {
				bucket = withEnhancedCaching(bucket, config);
			}

			return bucket;
		},
		createUnencryptedCacheBucket: (
			bucketId: string,
			settings: CacheBucketSettings = {}
		) =>
			createCacheBucket({
				bucketId,
				getUserDetails,
				perUser: settings.perUser,
				perSession: settings.perSession,
			}),
	};
};
