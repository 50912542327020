import * as React from 'react';
import { Context, UserContext } from './Context';

export const useUserContext = () => React.useContext(Context);

export interface WithUserContextProps {
	userContext: UserContext;
}

export function withUserContext<T extends WithUserContextProps>(
	Component: React.ComponentType<T>
): React.FC<Omit<T, keyof WithUserContextProps>> {
	return props => {
		const userContext = useUserContext();
		return <Component {...(props as T)} userContext={userContext} />;
	};
}
