import { get } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { MachineData } from 'App/Activity/ResourceManagerContext';

export enum UserSessionType {
	DESKTOP = 'Desktop',
	APPLICATION = 'Application',
}

export interface Application {
	id: string;
	name: string;
	iconUrl: string;
}

export interface Session {
	applications?: Application[];
	clientName: string;
	clientType: string;
	connectionState: string;
	deviceId: string;
	deviceType: string;
	machineData: MachineData;
	sessionId: string;
	sessionStartTime: string;
	userSessionType?: string;
}

export interface SessionsListResponse {
	deviceId: string;
	sessions?: Session[];
}

export const getRemoteSessionsList = async (
	workspaceConfig: WorkspaceConfiguration
): Promise<SessionsListResponse> => {
	const listSessionsUrl: string =
		workspaceConfig.storeProxy.sessionControlProxy?.listSessionsURL;
	return await get<SessionsListResponse>(listSessionsUrl);
};
