import * as React from 'react';
import { css } from 'aphrodite';
import { EmptyErrorIcon } from '@citrite/web-ui-component';
import { styles } from './FullScreenError.styles';

interface Props {
	title?: string;
	subtitle?: string;
	icon?: JSX.Element;
}

export const FullScreenError: React.FC<Props> = props => {
	return (
		<div className={css(styles.page)}>
			{props.icon || <EmptyErrorIcon />}
			<div className={css(styles.errorTitle)}>{props.title}</div>
			<div>{props.subtitle}</div>
			<div className={css(styles.extraMessaging)}>{props.children}</div>
		</div>
	);
};
