import * as React from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import { logError } from 'remoteLogging';
import { getEmbeddedConfiguration, lastCachedWSPConfig } from 'Configuration/Provider';
import { DefaultErrorPage } from 'ErrorPage/DefaultErrorPage';
import { MonitorHistory } from 'MonitoringAnalytics';
import { Router } from 'Router';
import { getHistory } from 'Router/BrowserHistory';
import { isAdvancedWorkspaceResiliencyEnabled } from 'Workspace/advancedWorkspaceResiliency';
import { ApplicationProviders } from './ApplicationProviders';
import { EmotionCache } from './EmotionCache';

export function Workspace() {
	const history = getHistory();
	const embeddedConfiguration = lastCachedWSPConfig || getEmbeddedConfiguration();
	const hasResilientWorkspace =
		isAdvancedWorkspaceResiliencyEnabled(embeddedConfiguration);

	return (
		<EmotionCache>
			{recoveredWithServiceWorkerCache() && !hasResilientWorkspace ? (
				<DefaultErrorPage />
			) : (
				<ReactRouter history={history}>
					<ApplicationProviders>
						<Router />
					</ApplicationProviders>
					<MonitorHistory />
				</ReactRouter>
			)}
		</EmotionCache>
	);
}

function recoveredWithServiceWorkerCache() {
	const metaTags = document.getElementsByTagName('meta');
	if (metaTags.length < 1) {
		logError('no meta tags found');
		return false;
	}
	const recoveredWithServiceWorkerCacheTag = Array.from(metaTags).find(
		tag => tag.getAttribute('name') === 'recovered-with-service-worker-cache'
	);

	return (
		recoveredWithServiceWorkerCacheTag?.getAttribute('content')?.toLocaleLowerCase() ===
		'true'
	);
}
