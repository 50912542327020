import React, { useEffect } from 'react';
import { t } from '@citrite/translate';
import { Button, EmptyErrorIcon, EmptyState } from '@citrite/web-ui-component';
import { logError } from 'remoteLogging';
import { ErrorLevel } from 'Loggers/LoggingProvider';

export const DefaultErrorTab = ({ backToDashboard }: { backToDashboard: () => void }) => {
	useEffect(() => {
		logError(new Error('Application tab crashed'), {
			additionalContext: {
				level: ErrorLevel.FATAL,
			},
		});
	}, []);
	return (
		<EmptyState
			title={t('javascript:error_boundary.title')}
			icon={EmptyErrorIcon}
			size={200}
		>
			<p>{t('javascript:error_boundary.message')}</p>
			<Button.Secondary onClick={backToDashboard}>
				{t('javascript:error_boundary.back_to_home')}
			</Button.Secondary>
		</EmptyState>
	);
};
