import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { clientType, getClientType, getClientVersion } from 'Environment/getClientType';
import { isNativeClient } from 'Environment/launchResource/device';
import { isAppProtectEnabledByOnPremAdmin } from 'Workspace/AppContext/onpremAppProtectionUtils';

const launchTypeIsNativeWithoutVersion = () => {
	const clientTypeVal = getClientType();
	//We need to check null since when the DetectionComponent gets rendered it sets the clientType to null
	return (
		(clientTypeVal === clientType.native || clientTypeVal === null) && !getClientVersion()
	);
};

//https://issues.citrite.net/browse/WSUI-9963
export function canHaveEmptyResources(workspaceConfiguration: WorkspaceConfiguration) {
	return (
		IS_ON_PREM &&
		!isNativeClient() &&
		isAppProtectEnabledByOnPremAdmin(workspaceConfiguration) &&
		launchTypeIsNativeWithoutVersion()
	);
}
