import * as React from 'react';
import { t } from '@citrite/translate';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { trackEvent } from 'analytics';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';
import {
	LoadingMessageAnimation,
	LoadingMessageText,
	TransitionState,
} from 'Workspace/InitialLoading.styled';

const fadingDuration = 500; // Time (ms), specified in CSS, spent fading between messages being shown

const shownLoadingMessages = 'shown-initial-loading-messages';

// The two functions below are inlined into workspace.html (from spinner.js)
const startSpinner = () => window['startSpinner']?.();
const stopSpinner = () => window['stopSpinner']?.();

function shouldShowLoadingMessages(workspaceConfiguration: WorkspaceConfiguration) {
	return hasFeatureCanary(workspaceConfiguration, FeatureFlag.LoadingMessages);
}

export function InitialLoading(props: { showLoadingMessages?: boolean }) {
	const [messageIndex, setMessageIndex] = React.useState<null | number>(null);
	const [messageAnimationState, setMessageAnimationState] =
		React.useState<TransitionState>('exiting');
	const trackedTimeouts = React.useRef<NodeJS.Timeout[]>([]);
	const spinnerRef = React.useRef<HTMLDivElement>();

	const orderedLoadingMessages = [
		t('Workspace:loading_progress.loading_workspace'),
		t('Workspace:loading_progress.getting_information'),
		t('Workspace:loading_progress.syncing_preferences'),
		t('Workspace:loading_progress.fetching_services'),
		t('Workspace:loading_progress.almost_there'),
		t('Workspace:loading_progress.try_again'),
	];
	const numberOfMessages = orderedLoadingMessages.length;
	const { workspaceConfiguration } = useConfigurationContext();
	const hasLoadingMessagesCanary = shouldShowLoadingMessages(workspaceConfiguration);
	const showLoadingMessages =
		hasLoadingMessagesCanary &&
		props.showLoadingMessages &&
		!getFromSessionStorage<boolean>(shownLoadingMessages);

	React.useEffect(
		function initializeMessageLoop() {
			if (showLoadingMessages && trackedTimeouts.current.length === 0) {
				const initialMessageWaitDuration = 3000;
				trackedTimeouts.current.push(
					setTimeout(() => {
						setMessageAnimationState('entering');
						setInSessionStorage(shownLoadingMessages, true);
					}, initialMessageWaitDuration)
				);
				trackedTimeouts.current.push(
					setTimeout(() => {
						setMessageIndex(0);
					}, initialMessageWaitDuration - fadingDuration)
				);
			}
		},
		[showLoadingMessages]
	);

	React.useEffect(
		function queueNextMessage() {
			if (messageIndex === null) {
				return;
			}

			const messageDuration = 8000;
			trackedTimeouts.current.push(
				setTimeout(() => {
					setMessageIndex(current => (current + 1) % numberOfMessages);
					setMessageAnimationState('entering');
				}, messageDuration)
			);

			trackedTimeouts.current.push(
				setTimeout(() => {
					setMessageAnimationState('exiting');
				}, messageDuration - fadingDuration)
			);
		},
		[messageIndex, numberOfMessages]
	);

	React.useEffect(function spinnerAnimationSmoothing() {
		if (hasLoadingMessagesCanary) {
			startSpinner(); // When blocking caller changes, the spinner must be restarted
		}
		if (spinnerRef.current) {
			const animationDuration = hasLoadingMessagesCanary ? 2800 : 700;
			const startingMark = performance
				.getEntriesByName('first_spin_animation', 'mark')
				?.shift();
			if (startingMark && !spinnerRef.current.style.animationDelay) {
				const cycleRemainder =
					Math.round(performance.now() - startingMark.startTime) % animationDuration;
				spinnerRef.current.style.animationDelay = `${0 - cycleRemainder}ms`;
			}
		}
	});

	React.useEffect(
		function messageLoopCleanup() {
			return () => {
				if (hasLoadingMessagesCanary) {
					stopSpinner();
				}
				if (trackedTimeouts.current.length > 0) {
					const shownMessages = (trackedTimeouts.current.length - 2) / 2;
					trackEvent('InitialLoading - messages shown', {
						messageCount: shownMessages,
					});
					// eslint-disable-next-line react-hooks/exhaustive-deps
					trackedTimeouts.current.forEach(timeoutId => clearTimeout(timeoutId));
				}
			};
		},
		[hasLoadingMessagesCanary]
	);

	const currentMessage =
		messageIndex != null ? orderedLoadingMessages[messageIndex] : null;

	return (
		<div className="loading-in-progress">
			{hasLoadingMessagesCanary ? (
				<>
					<div
						className="loading-spinner-animated spinner-workspace-animated"
						ref={spinnerRef}
					>
						<svg
							width="56px"
							height="56px"
							viewBox="0 0 66 66"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle
								className="loading-spinner-path"
								fill="none"
								strokeLinecap="round"
								cx="33"
								cy="33"
								r="28"
							></circle>
						</svg>
					</div>
					<LoadingMessageAnimation animationState={messageAnimationState}>
						<LoadingMessageText>{currentMessage}</LoadingMessageText>
					</LoadingMessageAnimation>
				</>
			) : (
				<>
					<div className="workspace-logo"></div>
					<div className="loading-spinner spinner-workspace" ref={spinnerRef}></div>
				</>
			)}
		</div>
	);
}
